import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'sdTypeAndNoOfMachine',
    type: 'text',
    label: 'Type & No. of Machine',
  },
  {
    colSpan: 1,
    name: 'sdCountryOrManufacturer',
    type: 'text',
    label: 'Country/ Manufacturer',
  },
  {
    colSpan: 1,
    name: 'sdYearOfManufacture',
    type: 'text',
    label: 'Year of Manufacture',
  },
  {
    fieldType: 'radio',
    colSpan: 1,
    name: 'sdOption',
    defaultValue: 'New',
    choices: [
      { name: 'New', value: 'New' },
      { name: 'Re-conditioned', value: 'Re-conditioned' },
    ],
  },
];

export const SizingAndDesizingFieldsValidation = {
  [fields[0].name]: yup.string(),
  [fields[1].name]: yup.string(),
  [fields[2].name]: yup.string(),
  [fields[3].name]: yup.string(),
};

const SizingAndDesizing = (props) => {
  return (
    <FormSection sectionName="Sizing & Desizing" sectionColumn={4} formFields={fields} {...props} />
  );
};

export default SizingAndDesizing;
