import React from 'react';
import * as yup from 'yup';
import { Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import AuthForm from './AuthForm';
import AuthWrapper from './AuthWrapper';

import { triggerPasswordReset } from '../../api/auth';
import { PHONE_NO_REGEX } from '../../constants/regex';
import { notify } from '../../store/actions/globalActions';
import { LOG_IN_URL, PASSWORD_RESET_VERIFICATION_URL } from '../../constants/routerUrl';

const PasswordResetTrigger = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const sendData = {
      username: data.username,
      phoneNo: `+880${data.phone.slice(-10)}`,
    };
    try {
      const res = await triggerPasswordReset(sendData);
      if (res.data?.status === 200) {
        dispatch(
          notify({
            title: 'Success',
            description: res.data.msg,
            status: 'success',
            duration: 5000,
          }),
        );
        history.replace({
          pathname: PASSWORD_RESET_VERIFICATION_URL,
          state: {
            username: data.username,
            phoneNo: data.phone,
            minPassLength: res.data.minPassLength,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formFields = [
    {
      id: 'username',
      name: 'username',
      isRequired: true,
      type: 'text',
      label: 'Username',
      placeholder: 'Enter your username',
    },
    {
      id: 'phone',
      name: 'phone',
      isRequired: true,
      type: 'tel',
      label: 'Phone Number',
      placeholder: 'Enter your phone number',
    },
  ];

  const ResetPasswordValidationSchema = yup.object().shape({
    username: yup.string().required().label('Username'),
    phone: yup
      .string()
      .matches(PHONE_NO_REGEX, 'Not a valid phone no.')
      .required()
      .label('Phone Number'),
  });

  return (
    <AuthWrapper>
      <AuthForm
        columns={1}
        onSubmit={onSubmit}
        formSubmitName="NEXT"
        formFields={formFields}
        formName="Reset Password"
        validationSchema={ResetPasswordValidationSchema}
        formText="Please enter your username & phone number to receive OTP for resetting your password."
        extraField={
          <Link to={LOG_IN_URL} style={{ maxWidth: 'fit-content' }}>
            <Text
              fontSize="sm"
              opacity={0.45}
              transition="0.5s"
              color="primary.700"
              _hover={{ opacity: 1 }}>
              Back to Login?
            </Text>
          </Link>
        }
      />
    </AuthWrapper>
  );
};

export default PasswordResetTrigger;
