import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/membershipFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'texYarnExpoCap',
    type: 'text',
    label: 'Yarn (kg)',
  },
];

export const yarnExpoCapFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label).nullable(),
};

const YarnExporCap = (props) => {
  return (
    <FormSection
      sectionName="Yarn Exportable Capacity"
      sectionColumn={1}
      formFields={fields}
      {...props}
    />
  );
};

export default YarnExporCap;
