import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, HStack, Box, Text, Grid, GridItem, useDisclosure } from '@chakra-ui/react';

//common components
import RenderModal from '../../RenderModal';
import Loader from '../../../snippets/Loader';
import InfoTiles from '../../../common/InfoTiles';
import FormButton from '../../../common/FormButton';
import DataTable from '../../../common/MinimalTable';
import InfoSection from '../../../common/InfoSection';
import MembershipFormWrapper from '../../../common/membershipFormComponents/MembershipFormWrapper';

//form sections
import TextileOtherInfoSection, {
  textileOtherInfoFieldsValidation,
} from './TextileOtherInfoSection';
import DocumentGallery from '../../../common/membershipFormComponents/GallerySection';
import IssueDateSection, { issueDateValidation } from '../../commonSections/IssueDateSection';
import FabricOtherInfoSection, { fabricOtherInfoFieldsValidation } from './FabricOtherInfoSection';

//actions & api
import {
  getAllApplications,
  getApplicationInfo,
  patchApplicationInfo,
  updateApplicationStatus,
} from '../../../../api/certificates';
import {
  FETCH_APPLICATION_INFO,
  FETCH_PROCESSING_APPLICATIONS,
} from '../../../../store/actions/actionTypes';
import { notify } from '../../../../store/actions/globalActions';

//utils & constatnts
import _ from 'lodash';
import { formatDate, renderDate } from '../../../../util/formatUtils';
import { useYupValidationResolver } from '../../../../util/yupUtils';
import { PROCESSING_CERT_URL } from '../../../../constants/routerUrl';

const ProcessingVer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [modal, setModal] = useState();
  const [formData, setFormData] = useState();
  const [loader, setLoader] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const data = useSelector((state) => state.certificate.currentData);
  const processing = data?.processingTextile || data?.processingFabric;
  const { applicationId } = history.location.state;

  useEffect(() => {
    (async () => {
      const res = await getApplicationInfo({ type: 'processing', id: applicationId });
      if (res.data?.status === 200) {
        dispatch({ type: FETCH_APPLICATION_INFO, payload: res.data.certificate });
      }
    })();
  }, []);

  const millInfo = [
    { header: 'Mill Name', value: data?.millInfo?.millName },
    { header: 'Membership No', value: data?.millInfo?.membershipNo },
    { header: 'Membership Type', value: data?.millInfo?.membershipType },
    { header: 'Contact', value: data?.millInfo?.phoneNo },
  ];

  const gspDocumentsInfo = [
    {
      header: 'Amendments',
      link: processing?.amendments,
    },
    {
      header: 'Proforma Invoice',
      link: processing?.proformaInvoice,
    },
    {
      header: 'Commercial Invoice',
      link: processing?.commercialInvoice,
    },
    {
      header: 'L/C Bank Documents',
      link: processing?.lcBankDocuments,
    },
  ];

  const generalInfoFields = [
    { header: 'Master L/C', value: processing?.masterLc },
    { header: 'Expiry Date', value: renderDate(processing?.expiryDateOfLc) },
    { header: 'Total Quantity', value: processing?.totalQuantity },
    { header: 'Date of Contract', value: renderDate(processing?.dateOfLc) },
    { header: 'Vat Challan No', value: processing?.exciseGatePassNum },
    { header: 'Vat Challan Date', value: renderDate(processing?.exciseGatePassDate) },
  ];

  const docColInfoFields = [
    { header: 'BTB L/C', value: processing?.btbLc },
    { header: 'Value', value: `$${processing?.valueInUsd}` },
    { header: 'L/C Opening Bank', value: `${processing?.bank}` },
    { header: 'Date of Collection', value: renderDate(processing?.btbLcDate) },
  ];

  const otherInfoFields = [
    { header: 'Produced By', value: processing?.producedBy },
    { header: 'Processed By', value: processing?.processedBy },
    { header: 'Vide Certificate No', value: processing?.videCertificateNo },
    { header: 'Vide Certificate Date', value: processing?.videCertificateDate },
    { header: 'Supplied To', value: processing?.suppliedTo },
  ];

  const productTableColumns = [
    {
      header: 'Sl. No',
      increments: true,
    },
    { header: 'Description', accessor: 'product' },
    { header: 'Quantity', accessor: 'productQuantity' },
    { header: 'Com. Invoice No', accessor: 'invoice' },
    { header: 'Com. Invoice Date', accessor: 'invoiceDate', isDate: true },
    { header: 'Delivery Date', accessor: 'deliveryDate', isDate: true },
  ];

  const getValidationSchema = () => {
    if (data?.processingFabric) {
      return yup.object().shape({
        ...issueDateValidation,
        ...fabricOtherInfoFieldsValidation,
      });
    } else if (data?.processingTextile) {
      return yup.object().shape({
        ...issueDateValidation,
        ...textileOtherInfoFieldsValidation,
      });
    } else {
      return yup.object().shape({
        ...issueDateValidation,
      });
    }
  };

  const resolver = useYupValidationResolver(getValidationSchema());

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: { issueDate: new Date() },
  });

  const onSubmit = (formdata) => {
    let normalizedFormData = _.mapKeys(formdata, (value, key) => {
      if (value) {
        return key;
      }
    });
    formdata?.yarnCount?.length > 0
      ? setFormData({
          ...normalizedFormData,
          issueDate: formatDate(normalizedFormData?.issueDate),
          yarnCount: JSON.stringify(formdata?.yarnCount),
        })
      : setFormData({
          ...normalizedFormData,
          issueDate: formatDate(normalizedFormData?.issueDate),
          yarnCount: null,
        });
    setModal('SUBMIT');
    onOpen();
  };

  const confirmSubmit = async () => {
    const res = await patchApplicationInfo({ type: 'processing', id: data?.id, formData });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      const response = await getAllApplications({
        type: 'processing',
        params: { page: 1, pageSize: 10 },
      });
      if (response.data?.status === 200) {
        dispatch({ type: FETCH_PROCESSING_APPLICATIONS, payload: response.data });
      }
      history.push(PROCESSING_CERT_URL);
    }
  };

  const handleRework = async (reworkData) => {
    setLoader(true);
    const res = await updateApplicationStatus({
      id: data?.id,
      formData: { status: 'REWORK', remarks: reworkData?.reason },
    });
    if (res.data?.status === 200) {
      const res = await getAllApplications({
        type: 'processing',
        params: { page: 1, pageSize: 10 },
      });
      if (res.data?.status === 200) {
        dispatch({ type: FETCH_PROCESSING_APPLICATIONS, payload: res.data });
      }
      history.push(PROCESSING_CERT_URL);
    }
    setLoader(false);
  };

  const confirmReject = async () => {
    setLoader(true);
    const res = await updateApplicationStatus({
      id: data?.id,
      formData: { status: 'REJECTED', remarks: 'This application has been rejected!' },
    });
    if (res.data?.status === 200) {
      const res = await getAllApplications({
        type: 'processing',
        params: { page: 1, pageSize: 10 },
      });
      if (res.data?.status === 200) {
        dispatch({ type: FETCH_PROCESSING_APPLICATIONS, payload: res.data });
      }
      history.push(PROCESSING_CERT_URL);
    }
    setLoader(false);
  };

  const getBadgeColorScheme = () => {
    switch (data?.applicationStatus) {
      case 'APPLIED':
        return 'gray';
      case 'IN REVIEW':
        return 'yellow';
      case 'REWORK':
        return 'blue';
      case 'APPROVED':
        return 'green';
      case 'REJECTED':
        return 'red';
      default:
        return null;
    }
  };

  return data && !loader ? (
    <Box>
      <Box p={4}>
        <InfoTiles sectionName="Mill Information" sectionColumn={4} infoFields={millInfo} />
      </Box>

      <Box bg="white" m={4} p={4} borderRadius="4px" border="1px solid #3e1cfd">
        <HStack>
          <Text fontSize="20px" fontWeight="bold" color="textSecondary">
            Certificate No:
          </Text>
          <Text fontSize="16px" fontWeight="bold">
            {data?.certificateNum || 'N/A'}
          </Text>
        </HStack>
        <HStack>
          <Text fontSize="20px" fontWeight="bold" color="textSecondary">
            Application Status:
          </Text>
          <Badge fontSize="16px" colorScheme={getBadgeColorScheme()}>
            {data?.applicationStatus}
          </Badge>
        </HStack>
        <HStack>
          <Text fontSize="20px" fontWeight="bold" color="textSecondary">
            Remarks:
          </Text>
          <Text fontSize="16px">{data?.remarks || 'N/A'}</Text>
        </HStack>
      </Box>

      <MembershipFormWrapper
        formName={
          data?.processingTextile ? 'Yarn Product Processing' : 'Fabric Product Processing'
        }>
        <Grid templateColumns="repeat(2, 1fr)" gap={3}>
          <GridItem bg="white" borderRadius="4px">
            <InfoSection sectionName="General Information" infoFields={generalInfoFields} />
          </GridItem>
          <GridItem bg="white" borderRadius="4px">
            <InfoSection
              sectionName="Documentary Collectors Information"
              infoFields={docColInfoFields}
            />
          </GridItem>
          <GridItem bg="white" borderRadius="4px">
            <InfoSection sectionName="Other Information" infoFields={otherInfoFields} />
          </GridItem>
          <GridItem bg="white" borderRadius="4px">
            <DocumentGallery galleryFields={gspDocumentsInfo} sectionName="Additional Documents" />
          </GridItem>
          <GridItem colSpan={2} bg="white" borderRadius="4px">
            <Text px={4} pt={4} fontSize="20px" fontWeight="bold">
              Product Info
            </Text>
            <Box p={4}>
              <DataTable
                scrollable
                columns={productTableColumns}
                data={processing?.productDetails}
              />
            </Box>
          </GridItem>
          <GridItem colSpan={2} bg="white" borderRadius="4px">
            <Box p={1}>
              <IssueDateSection register={register} control={control} errors={errors} />
              {data?.processingTextile ? (
                <TextileOtherInfoSection
                  sectionColumn={3}
                  register={register}
                  control={control}
                  errors={errors}
                />
              ) : (
                <FabricOtherInfoSection
                  sectionColumn={3}
                  register={register}
                  control={control}
                  errors={errors}
                />
              )}
            </Box>
          </GridItem>
        </Grid>
        <HStack justifyContent="space-between" alignItems="center" mt={4}>
          <FormButton
            mt={0}
            bg="red.500"
            color="white"
            variant="rework"
            isLoading={isSubmitting}
            _hover={{ bg: 'red.400' }}
            isDisabled={
              data?.applicationStatus === 'REWORK' || data?.applicationStatus === 'REJECTED'
            }
            onClick={() => {
              setModal('REJECT');
              onOpen();
            }}>
            REJECT
          </FormButton>
          <HStack>
            <FormButton
              mt={0}
              variant="rework"
              isLoading={isSubmitting}
              isDisabled={data?.applicationStatus === 'REWORK'}
              onClick={() => {
                setModal('REWORK');
                onOpen();
              }}>
              REWORK
            </FormButton>
            <FormButton
              mt={0}
              isLoading={isSubmitting}
              onClick={(event) => {
                event.preventDefault();
                handleSubmit(onSubmit)();
              }}>
              SUBMIT
            </FormButton>
          </HStack>
        </HStack>
      </MembershipFormWrapper>
      <RenderModal
        modal={modal}
        isOpen={isOpen}
        onClose={onClose}
        handleRework={handleRework}
        confirmSubmit={confirmSubmit}
        confirmReject={confirmReject}
      />
    </Box>
  ) : (
    <Loader />
  );
};

export default ProcessingVer;
