import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import * as yup from 'yup';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useYupValidationResolver } from '../../../util/yupUtils';
import CertNumber, { certNumberFieldsValidation } from './CertNumber';
import DialogueBox from '../../common/DialogueBox';

const AssignCertificate = forwardRef(({ mill, certType, submitting, handleQuery }, ref) => {
  const [formData, setFormData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const resolver = useYupValidationResolver(
    yup.object().shape({
      ...certNumberFieldsValidation,
    }),
  );

  const {
    control,
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({ resolver });

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit(onSubmit)();
    },
  }));

  const onSubmit = (data) => {
    setFormData(data);
    onOpen();
  };

  useEffect(() => {
    submitting(isSubmitting);
  }, [isSubmitting]);

  return (
    <Box>
      <CertNumber register={register} control={control} errors={errors} />
      <DialogueBox
        close={onClose}
        alertTitle="Confirm"
        mountControl={isOpen}
        performAction={() => handleQuery(formData)}
        alertMessage={`Are you sure you want to assign ${certType} certificate no. ${formData?.certificateNum} to ${mill?.millName}?`}
      />
    </Box>
  );
});

export default AssignCertificate;
