import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    type: 'text',
    name: 'receivedAmount',
    label: 'Paid Amount',
    isRequired: true,
  },
  {
    colSpan: 1,
    fieldType: 'select',
    name: 'paymentMethod',
    label: 'Payment Method',
    isRequired: true,
    choices: [
      { name: 'Cash', value: 'CASH' },
      { name: 'Cheque', value: 'CHEQUE' },
      { name: 'EFT', value: 'EFT' },
      { name: 'MFS', value: 'MFS' },
    ],
  },
  {
    colSpan: 2,
    type: 'text',
    name: 'narration',
    label: 'Narration',
    isRequired: false,
  },
];

export const receiveDuesFieldsValidation = {
  [fields[0].name]: yup
    .number()
    .typeError(`${fields[0].label} must be a number`)
    .required()
    .positive()
    .integer()
    .label(fields[0].label),
  [fields[1].name]: yup.string().required().label(fields[1].label),
};

const ReceiveDues = (props) => {
  return <FormSection secondary sectionColumn={2} formFields={fields} {...props} />;
};

export default ReceiveDues;
