import React from 'react';
import * as yup from 'yup';
import FieldArrayFormSection from '../../../common/hookFormComponents/FieldArrayFormSection';

const fields = [
  {
    colSpan: 1,
    name: 'name',
    type: 'text',
    label: 'Name',
  },
  {
    colSpan: 1,
    name: 'quantity',
    type: 'text',
    label: 'Quantity',
  },
];

export const defaultAdditionalInfoArrayFieldValues = fields.reduce(
  (obj, item) => [{ ...obj, [item['name']]: '' }],
  [{}],
);

export const additionalInfoFieldsValidation = {
  additionalInfo: yup.array().of(
    yup.object().shape({
      [fields[0].name]: yup.string().nullable().label(fields[0].label),
      [fields[1].name]: yup
        .number()
        .typeError(`${fields[1].label} must be a number`)
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        }),
    }),
  ),
};

const AdditionalInfoSection = (props) => {
  return (
    <FieldArrayFormSection
      sectionName="Additional Information"
      sectionColumn={5}
      name="additionalInfo"
      formFields={fields}
      {...props}
    />
  );
};

export default AdditionalInfoSection;
