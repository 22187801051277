import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'numberOfOfficers',
    type: 'text',
    label: 'Number of Officers',
  },
  {
    colSpan: 1,
    name: 'numberOfStaff',
    type: 'text',
    label: 'Number of Staff',
  },
  {
    colSpan: 1,
    name: 'noOfMale',
    type: 'text',
    label: 'Male',
  },
  {
    colSpan: 1,
    name: 'noOfFemale',
    type: 'text',
    label: 'Female',
  },
];

export const HumanResourcesFieldsValidation = {
  [fields[0].name]: yup.string(),
  [fields[1].name]: yup.string(),
  [fields[2].name]: yup.string(),
  [fields[3].name]: yup.string(),
};

const HumanResources = (props) => {
  return (
    <FormSection sectionName="Human Resources" sectionColumn={4} formFields={fields} {...props} />
  );
};

export default HumanResources;
