import React, { useState } from 'react';
import { Box, Button, Text, CloseButton, HStack } from '@chakra-ui/react';
import SearchSection from '../../common/SearchSection';

const MillNameForm = ({ executeAction, cancel, current }) => {
  const [mill, setMill] = useState('');

  return (
    <Box>
      <Box
        p={4}
        mt={6}
        mb={12}
        minW="500px"
        borderRadius="md"
        border="1px solid #130080"
        borderStyle="dashed">
        <SearchSection minimal setSelectedMill={setMill} />
        <HStack>
          <Text mt={1} color="primary.400" mb={1.5}>
            {mill?.millName}
          </Text>
          {mill?.id ? (
            <CloseButton
              size="sm"
              _focus={{ boxShadow: '0 0 0 3px #9280ff' }}
              onClick={() => {
                setMill();
              }}
            />
          ) : null}
        </HStack>
        {mill?.id === current ? (
          <Text color="invalid" mb={1.5} fontSize="xs">
            Currently assigned to this member!
          </Text>
        ) : null}
        <HStack mt={4} justifyContent="right">
          <Button variant="modalAction" onClick={cancel}>
            CANCEL
          </Button>
          <Button
            variant="modalAction"
            isDisabled={!mill?.id || mill?.id === current}
            onClick={() => {
              executeAction(mill);
            }}>
            CONFIRM
          </Button>
        </HStack>
      </Box>
    </Box>
  );
};

export default MillNameForm;
