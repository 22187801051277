import React from 'react';
import { Box, VStack, Text, HStack } from '@chakra-ui/react';
import logo from '../../assets/BTMA-Logo.webp';

const PrintReceiptHeader = () => {
  return (
    <HStack spacing={0} mb={4} w="100%">
      <Box>
        <img src={logo} alt="btma-logo" height="80px" width="80px" />
      </Box>
      <VStack spacing="0" pl={4} alignItems="flex-start">
        <Text fontSize="lg" fontWeight="bold" color="#402B79" lineHeight="1" mb={0.5}>
          BANGLADESH TEXTILE MILLS ASSOCIATION
        </Text>
        <Text fontSize="lg" fontWeight="bold" color="#EEAE5C" lineHeight="1">
          বাংলাদেশ টেক্সটাইল মিলস্ এসোসিয়েশন
        </Text>
        <Text fontSize="sm" fontWeight="bold" lineHeight="1" mb={0.5}>
          Unique Trade Centre (Level 8), 8, Panthapath, Kawran Bazar, Dhaka-1215, Bangladesh.
        </Text>
        <Text fontSize="sm" lineHeight="1" mb={0.5}>
          Phone: +880222248778, +880258156619, +880248116358
        </Text>
        <Text fontSize="sm" lineHeight="1">
          E-mail: btmasg@gmail.com, btma2@yahoo.com Web: www.btmadhaka.com
        </Text>
      </VStack>
    </HStack>
  );
};

export default PrintReceiptHeader;
