import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Box, Button, HStack, Text, Icon, VStack, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { CgChevronRightO } from 'react-icons/cg';

import UserInfoForm from './UserInfoForm';
import UserInfoModal from './UserInfoModal';

import { getAllMillUsers } from '../../../../api/millUsers';
import { FETCH_MILL_USERS } from '../../../../store/actions/actionTypes';
import FormButton from '../../../common/FormButton';
import AddUserForm from './AddUserForm';

const UsersTab = ({ backToTable }) => {
  const { uid } = useParams();
  const dispatch = useDispatch();
  const [title, setTitle] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalComponenet, setModalComponent] = useState();
  const data = useSelector((state) => state.membership.millUsers);
  const user = data?.users?.[0];

  const handleQuery = async () => {
    const res = await getAllMillUsers(uid);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_MILL_USERS, payload: res.data });
    } else {
      dispatch({ type: FETCH_MILL_USERS, payload: { users: [] } });
    }
  };

  useEffect(() => {
    handleQuery();
  }, []);

  const handleView = () => {
    setTitle('User Info');
    setModalComponent(<UserInfoForm data={user} closeModal={onClose} refresh={handleQuery} />);
    onOpen();
  };

  const handleCreate = () => {
    setTitle('Create User');
    setModalComponent(<AddUserForm millUid={uid} closeModal={onClose} refresh={handleQuery} />);
    onOpen();
  };

  return (
    <Box>
      <Box p={4}>
        {user ? (
          <Box w="400px" p={6} bg="white" shadow="lg" borderRadius="md">
            <HStack alignItems="flex-end">
              <Text fontSize="2xl" fontWeight="bold" lineHeight="1">
                {user?.username}{' '}
              </Text>
              <Badge colorScheme="green" variant="outline">
                {user?.isVerified ? 'verified' : ''}
              </Badge>
            </HStack>
            <Text fontSize="sm" color="textSecondary" mb={4}>
              {user?.uid}
            </Text>
            <HStack alignItems="flex-end" justifyContent="space-between">
              <Box>
                <Text>Role: {user?.userType}</Text>
                <Text>Status: {user?.userStatus}</Text>
                <Text>Contact: {user?.phoneNo}</Text>
                <Text>Email: {user?.email}</Text>
              </Box>
              <Icon
                w={8}
                h={8}
                color="green"
                cursor="pointer"
                borderRadius="50%"
                onClick={handleView}
                as={CgChevronRightO}
              />
            </HStack>
          </Box>
        ) : (
          <FormButton mt={0} onClick={handleCreate}>
            Create User
          </FormButton>
        )}
        <VStack w="100%" alignItems="flex-end" mt={4}>
          <Button
            minH="35px"
            maxH="45px"
            height="6vh"
            minW="120px"
            bg="#ECDBD9"
            color="#9B4236"
            borderRadius="0px"
            onClick={backToTable}
            _focus={{ boxShadow: 'none' }}
            _hover={{ borderBottom: '3px solid #9B4236' }}>
            Quit
          </Button>
        </VStack>
        <UserInfoModal modalTitle={title} mountControl={isOpen} close={onClose}>
          {modalComponenet}
        </UserInfoModal>
      </Box>
    </Box>
  );
};

export default UsersTab;
