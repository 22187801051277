import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  Text,
  VStack,
  HStack,
  Divider,
  GridItem,
  useDisclosure,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CloseIcon } from '@chakra-ui/icons';
import { useForm, useWatch } from 'react-hook-form';

//common components
import FormButton from '../../../common/FormButton';
import DialogueBox from '../../../common/DialogueBox';
import SearchSection from '../../../common/SearchSection';
import AssignCertificate from '../../commonSections/AssignCertificate';
import MembershipFormWrapper from '../../../common/membershipFormComponents/MembershipFormWrapper';

//form sections
import DocumentSection, {
  procDocumentInfoFieldsValidation,
} from '../../commonSections/DocumentSection';
import FabricOtherInfoSection, {
  fabricOtherInfoFieldsValidation,
} from '../Verification/FabricOtherInfoSection';
import GeneralInfoSection, {
  gspGenInfoFieldsValidation,
} from '../../commonSections/GeneralInfoSection';
import ProductTypeSection, {
  processingProductTypeFieldsValidation,
} from '../../commonSections/ProductTypeSection';
import ProductInformation, {
  productInfoArrayFieldsValidation,
} from '../../commonSections/ProductInformation';
import TextileOtherInfoSection, {
  textileOtherInfoFieldsValidation,
} from '../Verification/TextileOtherInfoSection';
import SelectCertificateSection from '../../commonSections/SelectCertificateSection';
import MillInformationSection, { millInfoFieldsValidation } from './MillInformationSection';
import IssueDateSection, { issueDateValidation } from '../../commonSections/IssueDateSection';

//actions and api
import {
  getAllApplications,
  applyForCertificate,
  getUnusedCertificates,
  assignPreSoldCertificate,
} from '../../../../api/certificates';
import { notify } from '../../../../store/actions/globalActions';
import { FETCH_PROCESSING_APPLICATIONS } from '../../../../store/actions/actionTypes';

//utis & constants
import _ from 'lodash';
import { formatDate } from '../../../../util/formatUtils';
import { useYupValidationResolver } from '../../../../util/yupUtils';

const ApplyForProcessing = () => {
  const millErrorRef = useRef();
  const dispatch = useDispatch();
  const [modal, setModal] = useState();
  const assignCertificateRef = useRef();
  const [product, setProduct] = useState();
  const [formData, setFormData] = useState();
  const [certificate, setCertificate] = useState();
  const [selectedMill, setSelectedMill] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [certErrorMsg, setCertErrorMsg] = useState('');
  const [millUnusedCerts, setMillUnusedCerts] = useState();
  const [certificateToggle, setCertificateToggle] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const fetchUnusedCertificates = async () => {
    setMillUnusedCerts();
    const res = await getUnusedCertificates({ type: 'processing', millId: selectedMill?.id });
    if (res.data?.status === 200) {
      setMillUnusedCerts(res.data?.certificates);
    }
  };

  useEffect(() => {
    if (selectedMill?.id) {
      fetchUnusedCertificates();
      setValue('processedBy', `${selectedMill?.millName}, ${selectedMill?.millAddress}`);
    }
    setCertificate();
    setValue('productType', 'TEXTILE');
  }, [selectedMill?.id, certificateToggle]);

  useEffect(() => {
    if (certificate?.certNum) {
      setCertErrorMsg('');
      setValue('productType', 'TEXTILE');
      if (selectedMill?.categoryId === 1) {
        setValue('productType', 'YARN');
      } else {
        setValue('productType', 'FABRIC');
      }
    } else {
      setCertErrorMsg('Please Select a Certificate');
    }
  }, [certificate]);

  const commonValidationSchema = {
    ...issueDateValidation,
    ...millInfoFieldsValidation,
    ...gspGenInfoFieldsValidation,
    ...procDocumentInfoFieldsValidation,
    ...productInfoArrayFieldsValidation,
    ...processingProductTypeFieldsValidation,
  };

  const getValidationSchema = () => {
    switch (product) {
      case 'FABRIC':
        return yup.object().shape({
          ...commonValidationSchema,
          ...fabricOtherInfoFieldsValidation,
        });
      case 'TEXTILE':
        return yup.object().shape({
          ...commonValidationSchema,
          ...textileOtherInfoFieldsValidation,
        });
      default:
        return yup.object().shape({
          ...commonValidationSchema,
        });
    }
  };

  const resolver = useYupValidationResolver(getValidationSchema());

  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: { issueDate: new Date() },
  });

  const productType = useWatch({ control, name: 'productType' });

  useEffect(() => {
    setProduct(productType);
  }, [productType]);

  const formatProductDetails = (products) => {
    return products?.map((item) => ({
      ...item,
      invoiceDate: formatDate(item?.invoiceDate),
      deliveryDate: formatDate(item?.deliveryDate),
    }));
  };

  const onSubmit = (data) => {
    let formdata = {
      ...data,
      millId: selectedMill?.id,
      certId: certificate?.certID,
      dateOfLc: formatDate(data?.dateOfLc),
      btbLcDate: formatDate(data?.btbLcDate),
      issueDate: formatDate(data?.issueDate),
      bank: data?.bank + ',' + data?.bankBranch,
      exciseGatePassDate: formatDate(data?.exciseGatePassDate),
      videCertificateDate: formatDate(data?.videCertificateDate),
      productDetails: JSON.stringify(formatProductDetails(data?.productDetails)),
    };

    let normalizedFormData = _.mapKeys(formdata, (value, key) => {
      if (value) {
        return key;
      }
    });

    setFormData(normalizedFormData);
    setModal('CONFIRM_APPLICATION');
    onOpen();
  };

  const confirmSubmit = async () => {
    const res = await applyForCertificate({ type: 'processing', formData });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      let resetObj = {};
      _.mapKeys(formData, (value, key) => (resetObj[key] = ''));
      reset({
        ...resetObj,
        productDetails: [],
        issueDate: new Date(),
        processedBy: formData?.processedBy,
      });
      const response = await getAllApplications({
        type: 'processing',
        params: { page: 1, pageSize: 10 },
      });
      if (response.data?.status === 200) {
        dispatch({ type: FETCH_PROCESSING_APPLICATIONS, payload: response.data });
      }
      setCertificateToggle(!certificateToggle);
    }
  };

  const handlePreSoldCert = () => {
    setModal('CERT_NUMBER_FORM');
    onOpen();
  };

  const handleAssignCert = async (data) => {
    const res = await assignPreSoldCertificate({
      millId: selectedMill?.id,
      certificateNum: data?.certificateNum,
      type: 'PROCESSING',
    });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      fetchUnusedCertificates();
      onClose();
    }
  };

  const renderModal = () => {
    switch (modal) {
      case 'CONFIRM_APPLICATION':
        return (
          <DialogueBox
            close={onClose}
            alertTitle="Confirm"
            mountControl={isOpen}
            performAction={confirmSubmit}
            alertMessage="Are you sure you want to submit these information and verify this application?"
          />
        );
      case 'CERT_NUMBER_FORM':
        return (
          <DialogueBox
            close={onClose}
            onActionKeepOpen
            mountControl={isOpen}
            actionButtonText="Submit"
            cancelButtonText="Cancel"
            alertTitle="Assign Pre-sold Certificate"
            actionButtonLoading={modalButtonLoading}
            performAction={() => assignCertificateRef.current.submit()}>
            <AssignCertificate
              mill={selectedMill}
              certType="Processing"
              ref={assignCertificateRef}
              handleQuery={handleAssignCert}
              submitting={setModalButtonLoading}
            />
          </DialogueBox>
        );
    }
  };

  return (
    <Box>
      <SearchSection setSelectedMill={setSelectedMill} showProvisionalsAlso={true} />

      <Box mb={2} mt={5} px={4}>
        <Text fontSize="20px" fontWeight="bold" mb={2}>
          Select Mill & Certificate
        </Text>
        <Divider />
      </Box>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap={3}
        p={4}
        mt={5}
        mx={4}
        bg="white"
        borderRadius="4px">
        <GridItem colSpan={2}>
          <Box
            bg="secondary"
            p={4}
            borderRadius="4px"
            position="relative"
            zIndex={1}
            border="1px"
            borderColor="primary.300"
            borderStyle="dashed">
            {selectedMill?.id ? (
              <CloseIcon
                position="absolute"
                right={3}
                cursor="pointer"
                onClick={() => {
                  setSelectedMill();
                  setCertificate();
                }}
              />
            ) : null}
            <Text fontSize="md" color="textSecondary">
              Selected Mill
            </Text>
            <Text fontSize="xl" mt={1.5}>
              {selectedMill?.millName || 'None'}
            </Text>
          </Box>
          {selectedMill ? null : (
            <Text fontSize="sm" color="invalid" ref={millErrorRef} mt={2}>
              Please Select a Mill
            </Text>
          )}
        </GridItem>
        {selectedMill && millUnusedCerts?.length > 0 ? (
          <GridItem colSpan={3}>
            <SelectCertificateSection
              error={certErrorMsg}
              certificates={millUnusedCerts}
              setCertificate={setCertificate}
              defaultValue={certificate?.certID}
            />
          </GridItem>
        ) : selectedMill ? (
          <GridItem
            p={4}
            colSpan={3}
            border="1px"
            bg="secondary"
            borderRadius="4px"
            borderColor="invalid">
            <VStack justifyContent="center" h="100%">
              <Text fontSize="md" color="invalid">
                No Unused Certificate Found for This Mill! The member needs to buy blank certificate
                or admin may assign unused blank certificate previously bought by the member.
              </Text>
            </VStack>
          </GridItem>
        ) : null}
      </Grid>

      {user.adminRole === 'SUPERADMIN' && (
        <>
          {selectedMill && !certificate ? (
            <FormButton ml={4} onClick={handlePreSoldCert}>
              Assign Pre-sold Certificate
            </FormButton>
          ) : null}
        </>
      )}

      {selectedMill && certificate ? (
        <MembershipFormWrapper formName="Processing Application" mt={4}>
          <Grid templateColumns="repeat(2, 1fr)" gap={3}>
            <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
              <ProductTypeSection
                errors={errors}
                type="PROCESSING"
                control={control}
                register={register}
              />
            </GridItem>
            {productType ? (
              <>
                <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
                  <IssueDateSection control={control} register={register} errors={errors} />
                  <GeneralInfoSection
                    errors={errors}
                    type="PROCESSING"
                    control={control}
                    register={register}
                  />
                </GridItem>
                <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
                  <DocumentSection
                    errors={errors}
                    type="PROCESSING"
                    control={control}
                    register={register}
                  />
                </GridItem>
                <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
                  <ProductInformation control={control} register={register} errors={errors} />
                </GridItem>
                <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
                  <MillInformationSection control={control} register={register} errors={errors} />
                </GridItem>
                <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
                  {productType === 'TEXTILE' ? (
                    <TextileOtherInfoSection
                      errors={errors}
                      control={control}
                      register={register}
                    />
                  ) : (
                    <FabricOtherInfoSection register={register} control={control} errors={errors} />
                  )}
                </GridItem>
              </>
            ) : null}
          </Grid>
          {productType ? (
            <HStack justifyContent="end" alignItems="center" mt={4}>
              <FormButton
                mt={0}
                onClick={(event) => {
                  event.preventDefault();
                  handleSubmit(onSubmit)();
                }}
                isLoading={isSubmitting}>
                SUBMIT
              </FormButton>
            </HStack>
          ) : null}
        </MembershipFormWrapper>
      ) : null}
      {renderModal()}
    </Box>
  );
};

export default ApplyForProcessing;
