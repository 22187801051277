import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../../common/hookFormComponents/FormSection';
import { websiteValidation, mobileNoValidation } from '../../../../../../util/yupUtils';

const fields = [
  {
    colSpan: 1,
    name: 'hoName',
    type: 'text',
    isRequired: true,
    label: 'Address',
  },
  {
    colSpan: 1,
    name: 'millEmail',
    type: 'text',
    label: 'Email',
  },
  {
    colSpan: 1,
    name: 'millPhoneNo',
    type: 'text',
    label: 'Phone Number',
  },
  {
    colSpan: 1,
    name: 'website',
    type: 'text',
    label: 'Web Address',
  },
];

export const headInfoFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup.string().email().label(fields[1].label),
  [fields[2].name]: mobileNoValidation.label(fields[2].label),
  [fields[3].name]: websiteValidation.label(fields[3].label),
};

const HeadOfficeInfo = (props) => {
  return (
    <FormSection
      sectionName="Head Office Information"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default HeadOfficeInfo;
