import React from 'react';
import { Box } from '@chakra-ui/react';
import NewCertificateForm from '../accounts/certificate/NewCertificate/NewCertificateForm';

const FeeCollection = () => {
  return (
    <Box p={4} bg="secondary">
      <NewCertificateForm isFeeCollectionView />
    </Box>
  );
};

export default FeeCollection;
