import React from 'react';
import { Input } from '@chakra-ui/react';
import ReactDatePicker from 'react-datepicker';

const BillDate = ({ setDate, date }) => {
  return (
    <div className="customDatePickerWidth">
      <ReactDatePicker
        customInput={
          <Input
            pr={20}
            fontSize="md"
            bg="formInputBG"
            variant="outline"
            borderRadius="sm"
            focusBorderColor="primary.300"
            h={['40px', '40px', '40px', '45px', '50px']}
          />
        }
        isClearable
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
        showPopperArrow={false}
        dateFormat="dd/MM/yyyy"
        onChange={(e) => setDate(e)}
        selected={date ? new Date(date) : undefined}
      />
    </div>
  );
};

export default BillDate;
