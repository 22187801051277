import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Grid,
  Icon,
  VStack,
  HStack,
  Tooltip,
  Spinner,
  Divider,
  GridItem,
  IconButton,
  CloseButton,
} from '@chakra-ui/react';
import Loader from '../../snippets/Loader';
import FormButton from '../../common/FormButton';
import { renderDate } from '../../../util/formatUtils';
import SearchSection from '../../common/SearchSection';
import { getMillInfoReport } from '../../../api/reports';
import SelectReport from '../commonSections/SelectReport';
import FileExporter from '../commonSections/FileExporter';
import { getMillCategories } from '../../../api/membership';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineFileSearch } from 'react-icons/ai';

const MillInformationReportWizard = () => {
  const [mill, setMill] = useState();
  const [table, setTable] = useState();
  const [results, setResults] = useState();
  const [loader, setLoader] = useState(false);
  const [categoryId, setCategoryId] = useState();
  const [membershipType, setMembershipType] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState();

  useEffect(() => {
    document.title = 'BTMA - Reports';
  }, []);

  useEffect(() => {
    (async () => {
      const { categories } = await getMillCategories();
      if (categories) {
        const options = categories.map(({ id, category }) => ({ name: category, value: id }));
        setCategoryOptions(options);
      }
    })();
  }, []);

  useEffect(() => {
    setSelectAll(false);
    setSelectedFields([]);
  }, [table]);

  useEffect(() => {
    setResults();
  }, [selectedFields]);

  const dateFields = ['membership date', 'membership expiry date', 'date of establishment'];

  const basicFields = [
    { name: 'Mill Name', key: 'millName as mill name' },
    { name: 'Category', key: 'categoryInfo.category as category' },
    { name: 'Membership No.', key: 'membershipNo as membership no.' },
    { name: 'Membership Type', key: 'membershipType as membership type' },
    {
      name: 'Membership Date',
      key: 'membershipDate as membership date',
      displayName: 'membership date',
    },
    {
      name: 'Membership Expiry Date',
      key: 'membershipExpiryDate as membership expiry date',
      displayName: 'membership expiry date',
    },
    {
      name: 'Date of Establisment',
      key: 'dateOfEstablishment as date of establishment',
      displayName: 'date of establishment',
    },
    { name: 'Year of Operation', key: 'yearOfOperation as year of OPERATION' },
    { name: 'Operation Status', key: 'operationStatus as Operation Status' },
    {
      name: 'Head Office Address',
      key: 'hoInfo:hoName!head office address',
    },
    { name: 'Head Office Mail', key: 'hoInfo:millEmail!head office mail' },
    { name: 'Head Office Phone', key: 'hoInfo:millPhoneNo!head office phone' },
    { name: 'Website', key: 'website' },
    {
      name: 'Mill Site Address',
      key: 'msInfo:msAddress! mill site address',
    },
    { name: 'Mill Site Mail', key: 'msInfo:msEmail!mill site mail' },
    { name: 'Mill Site Phone', key: 'msInfo:msPhoneNo!mill site phone' },
    { name: 'Name of Chairman', key: 'chairmanInfo:chairmanName!name of chairman' },
    { name: 'Chairman phone', key: 'chairmanInfo:chairmanOfficeTelephone!chairman phone' },
    {
      name: 'Name of Managing Director',
      key: 'mdInfo:mdName!name of managing director',
    },
    {
      name: 'Managing Director Phone',
      key: 'mdInfo:mdOfficeTelephone!managing director phone',
    },
    {
      name: 'Cost of Project',
      key: 'sourceOfCapital:totalCostOfProject!cost of project',
    },
    {
      name: 'Equity',
      key: 'sourceOfCapital:sponsors!equity',
    },
    {
      name: 'Nature of Company',
      key: 'otherInfo:natureOfCompany!nature of company',
    },
    {
      name: 'Public Issue',
      key: 'sourceOfCapital:publicIssue!public issue',
    },
    {
      name: 'Bank Finance',
      key: 'sourceOfCapital:bankFinance!bank finance',
    },
    {
      name: 'License',
      key: 'bondedWarehouseLicenseNo:licenseNo!license',
    },
  ];

  const operationFields = [
    { name: 'Mill Name', key: 'millName as mill name' },
    { name: 'Membership No.', key: 'membershipNo as membership no.' },
    { name: 'Category', key: 'categoryInfo.category as category' },
    {
      name: 'Spindle Ins Cap',
      key: 'operationInfo.yarnManufacturerSummary:spindlesInsCap!spindle ins cap',
    },
    {
      name: 'Rotor Ins Cap',
      key: 'operationInfo.yarnManufacturerSummary:rotorsInsCap!rotor ins cap',
    },
    {
      name: 'Autocone Ins Cap',
      key: 'operationInfo.yarnManufacturerSummary:autoConeInsCap!autocone ins cap',
    },
    {
      name: 'Others Ins Cap (yarn)',
      key: 'operationInfo.yarnManufacturerSummary:otherInsCap!others ins cap (yarn)',
    },
    {
      name: 'Spindle In Op',
      key: 'operationInfo.yarnManufacturerSummary:spindlesInsCap!spindle in op',
    },
    {
      name: 'Rotor In Op',
      key: 'operationInfo.yarnManufacturerSummary:rotorsInsCap!rotor in op',
    },
    {
      name: 'Autocone In Op',
      key: 'operationInfo.yarnManufacturerSummary:autoConeInsCap!autocone in op',
    },
    {
      name: 'Others In Op (yarn)',
      key: 'operationInfo.yarnManufacturerSummary:otherInsCap!others in op (yarn)',
    },
    {
      name: 'A-Production Capacity (Yarn)',
      key: 'operationInfo.yarnManufacturerSummary:yarnProdCap!a-production capacity (yarn)',
    },
    {
      name: 'A-Exportable Capacity (Yarn)',
      key: 'operationInfo.yarnManufacturerSummary:yarnExpoCap!a-exportable capacity (yarn)',
    },
    {
      name: 'Airjet Ins Cap',
      key: 'operationInfo.fabricsManufacturerSummary:airjetInsCap!airjet ins cap',
    },
    {
      name: 'Shuttle Ins Cap',
      key: 'operationInfo.fabricsManufacturerSummary:shuttleInsCap!shuttle ins cap',
    },
    {
      name: 'Shuttle Less Ins Cap',
      key: 'operationInfo.fabricsManufacturerSummary:shuttleLessInsCap!shuttle less ins cap',
    },
    {
      name: 'Rapiers Ins Cap',
      key: 'operationInfo.fabricsManufacturerSummary:repairInsCap!rapiers ins cap',
    },
    {
      name: 'Kniting Machine Ins Cap',
      key: 'operationInfo.fabricsManufacturerSummary:knittingInsCap!knitting machine ins cap',
    },
    {
      name: 'Others Ins Cap (fabric)',
      key: 'operationInfo.fabricsManufacturerSummary:othersInsCap!others (fabric) ins cap',
    },
    {
      name: 'B-Production Capacity (Woven Fabric)',
      key: 'operationInfo.fabricsManufacturerSummary:fabricWovenProdCap!b-production capacity (woven fabric)',
    },
    {
      name: 'B-Exportable Capacity (Woven Fabric)',
      key: 'operationInfo.fabricsManufacturerSummary:fabricWovenExpoCap!b-exportable capacity (woven fabric)',
    },
    {
      name: 'B-Production Capacity (Knit Fabric)',
      key: 'operationInfo.fabricsManufacturerSummary:fabricKnitProdCap!b-production capacity (knit fabric)',
    },
    {
      name: 'B-Exportable Capacity (Knit Fabric)',
      key: 'operationInfo.fabricsManufacturerSummary:fabricKnitExpoCap!b-exportable capacity (knit fabric)',
    },
    {
      name: 'C-Processing Capacity (Woven Fabric)',
      key: 'operationInfo.textileProductProcessorSummary:texFabricWovenProcCap!c-processing capacity (woven fabric)',
    },
    {
      name: 'C-Exportable Capacity (Woven Fabric)',
      key: 'operationInfo.textileProductProcessorSummary:texFabricWovenExpoCap!c-exportable capacity (woven fabric)',
    },
    {
      name: 'C-Processing Capacity (Knit Fabric)',
      key: 'operationInfo.textileProductProcessorSummary:texFabricKnitProcCap!c-processing capacity (knit fabric)',
    },
    {
      name: 'C-Exportable Capacity (Knit Fabric)',
      key: 'operationInfo.textileProductProcessorSummary:texFabricKnitExpoCap!c-exportable capacity (knit fabric)',
    },
    {
      name: 'C-Processing Capacity (Yarn)',
      key: 'operationInfo.textileProductProcessorSummary:texYarnProcCap!c-processing capacity (yarn)',
    },
    {
      name: 'C-Exportable Capacity (Yarn)',
      key: 'operationInfo.textileProductProcessorSummary:texYarnExpoCap!c-exportable capacity (yarn)',
    },
    {
      name: 'A-Raw Mat Req (psf)',
      key: 'operationInfo.yarnManufacturerSummary:psfReq!a-raw mat req (psf)',
    },
    {
      name: 'A-Raw Mat Req (viscose)',
      key: 'operationInfo.yarnManufacturerSummary:viscoseReq!a-raw mat req (viscose)',
    },
    {
      name: 'A-Raw Mat Req (cotton waste)',
      key: 'operationInfo.yarnManufacturerSummary:cotWasteReq!a-raw mat req (cotton waste)',
    },
    {
      name: 'A-Raw Mat Req (acrylic)',
      key: 'operationInfo.yarnManufacturerSummary:acrylicReq!a-raw mat req (acrylic)',
    },
    {
      name: 'A-Raw Mat Req (chips)',
      key: 'operationInfo.yarnManufacturerSummary:chipsReq!a-raw mat req (chips)',
    },
    {
      name: 'A-Raw Mat Req (pet-chips)',
      key: 'operationInfo.yarnManufacturerSummary:petChipsReq!a-raw mat req (pet-chips)',
    },
    {
      name: 'A-Raw Mat Req (raw cotton)',
      key: 'operationInfo.yarnManufacturerSummary:rawCottonReq!a-raw mat req (raw cotton)',
    },
    {
      name: 'A-Raw Mat Cons (psf)',
      key: 'operationInfo.yarnManufacturerSummary:psfCon!a-raw mat cons (psf)',
    },
    {
      name: 'A-Raw Mat Cons (viscose)',
      key: 'operationInfo.yarnManufacturerSummary:viscoseCon!a-raw mat cons (viscose)',
    },
    {
      name: 'A-Raw Mat Cons (cotton waste)',
      key: 'operationInfo.yarnManufacturerSummary:cotWasteCon!a-raw mat cons (cotton waste)',
    },
    {
      name: 'A-Raw Mat Cons (acrylic)',
      key: 'operationInfo.yarnManufacturerSummary:acrylicCon!a-raw mat cons (acrylic)',
    },
    {
      name: 'A-Raw Mat Cons (chips)',
      key: 'operationInfo.yarnManufacturerSummary:chipsCon!a-raw mat cons (chips)',
    },
    {
      name: 'A-Raw Mat Cons (pet-chips)',
      key: 'operationInfo.yarnManufacturerSummary:petChipsCon!a-raw mat cons (pet-chips)',
    },
    {
      name: 'A-Raw Mat Cons (raw cotton)',
      key: 'operationInfo.yarnManufacturerSummary:rawCottonCon!a-raw mat cons (raw cotton)',
    },
    {
      name: 'B-Raw Mat Req (cotton yarn)',
      key: 'operationInfo.fabricsManufacturerSummary:cotYarn!b-raw mat req (cotton yarn)',
    },
    {
      name: 'B-Raw Mat Req (synthetic yarn)',
      key: 'operationInfo.fabricsManufacturerSummary:synthYarn!b-raw mat req (synthetic yarn)',
    },
    {
      name: 'B-Raw Mat Req (other yarn)',
      key: 'operationInfo.fabricsManufacturerSummary:otherYarn!b-raw mat req (other yarn)',
    },
    {
      name: 'C-Raw Mat Req (woven fabric)',
      key: 'operationInfo.textileProductProcessorSummary:fabWoven!c-raw mat req (woven fabric)',
    },
    {
      name: 'C-Raw Mat Req (knit fabric)',
      key: 'operationInfo.textileProductProcessorSummary:fabKnit!c-raw mat req (knit fabric)',
    },
    {
      name: 'C-Raw Mat Req (yarn)',
      key: 'operationInfo.textileProductProcessorSummary:yarns!c-raw mat req (yarn)',
    },
    {
      name: 'C-Raw Mat Req (dice)',
      key: 'operationInfo.textileProductProcessorSummary:dice!c-raw mat req (dice)',
    },
    {
      name: 'C-Raw Mat Req (chemical)',
      key: 'operationInfo.textileProductProcessorSummary:chemical!c-raw mat req (chemical)',
    },

    { name: 'Pm-No. of Gas', key: 'operationInfo.powerManagementSummary:numberGas!pm-no of gas' },
    {
      name: 'Pm-Gas Country',
      key: 'operationInfo.powerManagementSummary:countryOfOriginGas!pm-gas country',
    },
    {
      name: 'Pm-Gas Capacity',
      key: 'operationInfo.powerManagementSummary:capacityGas!pm-gas capacity',
    },
    {
      name: 'Pm-No. of Diesel',
      key: 'operationInfo.powerManagementSummary:numberDiesel!pm-no of diesel',
    },
    {
      name: 'Pm-Diesel Country',
      key: 'operationInfo.powerManagementSummary:countryOfOriginDiesel!pm-diesel country',
    },
    {
      name: 'Pm-Diesel Capacity',
      key: 'operationInfo.powerManagementSummary:capacityDiesel!pm-diesel capacity',
    },
    {
      name: 'Pm-Other Fuel Country',
      key: 'operationInfo.powerManagementSummary:countryOfOriginOtherFuel!pm-other fuel country',
    },
    {
      name: 'Pm-Other Fuel Capacity',
      key: 'operationInfo.powerManagementSummary:capacityOtherFuel!pm-other fuel capacity',
    },
    {
      name: 'Pm-Sanc Electricity',
      key: 'operationInfo.powerManagementSummary:sancElectricity!pm-sanc electricity',
    },
    { name: 'Pm-Sanc Gas', key: 'operationInfo.powerManagementSummary:sancGas!pm-sanc gas' },

    {
      name: 'No. of Officers',
      key: 'operationInfo.humanResources:numberOfOfficers!no of officers',
    },
    { name: 'No. of Staff', key: 'operationInfo.humanResources:numberOfStaff!no of staff' },
    { name: 'Male', key: 'operationInfo.humanResources:noOfMale!male' },
    { name: 'Female', key: 'operationInfo.humanResources:noOfFemale!female' },
    {
      name: 'Working Days In a Year',
      key: 'operationInfo.humanResources:numberOfWorkingDaysInAYear!working days in a year',
    },
    {
      name: 'Shifts Per Day',
      key: 'operationInfo.humanResources:numberOfShiftsPerWorkingDay!shifts per day',
    },
    { name: 'Hours Per Shift', key: 'operationInfo.humanResources:hoursPerShift!hours per shift' },
  ];

  const installedCapacityfields = [
    { name: 'Spindle', key: 'operationInfo.yarnManufacturerSummary:spindlesInsCap!spindle' },
    { name: 'Rotor', key: 'operationInfo.yarnManufacturerSummary:rotorsInsCap!rotor' },
    { name: 'Autocone', key: 'operationInfo.yarnManufacturerSummary:autoConeInsCap!autocone' },
    {
      name: 'Others (yarn)',
      key: 'operationInfo.yarnManufacturerSummary:otherInsCap!others (yarn)',
    },
    { name: 'Airjet', key: 'operationInfo.fabricsManufacturerSummary:airjetInsCap!airjet' },
    { name: 'Shuttle', key: 'operationInfo.fabricsManufacturerSummary:shuttleInsCap!shuttle' },
    {
      name: 'Shuttle Less',
      key: 'operationInfo.fabricsManufacturerSummary:shuttleLessInsCap!shuttle less',
    },
    { name: 'Rapiers', key: 'operationInfo.fabricsManufacturerSummary:repairInsCap!rapiers' },
    {
      name: 'Kniting Machine',
      key: 'operationInfo.fabricsManufacturerSummary:knittingInsCap!knitting machine',
    },
    {
      name: 'Others (fabric)',
      key: 'operationInfo.fabricsManufacturerSummary:othersInsCap!others (fabric)',
    },
    {
      name: 'A-Production Capacity (Yarn)',
      key: 'operationInfo.yarnManufacturerSummary:yarnProdCap!a-production capacity (yarn)',
    },
    {
      name: 'B-Production Capacity (Woven Fabric)',
      key: 'operationInfo.fabricsManufacturerSummary:fabricWovenProdCap!b-production capacity (woven fabric)',
    },
    {
      name: 'B-Production Capacity (Knit Fabric)',
      key: 'operationInfo.fabricsManufacturerSummary:fabricKnitProdCap!b-production capacity (knit fabric)',
    },
    {
      name: 'Processing Capacity (Woven Fabric)',
      key: 'operationInfo.textileProductProcessorSummary:texFabricWovenProcCap!processing capacity (woven fabric)',
    },
    {
      name: 'Processing Capacity (Knit Fabric)',
      key: 'operationInfo.textileProductProcessorSummary:texFabricKnitProcCap!processing capacity (knit fabric)',
    },
    {
      name: 'Processing Capacity (Yarn)',
      key: 'operationInfo.textileProductProcessorSummary:texYarnProcCap!processing capacity (yarn)',
    },
  ];

  const fieldsForTable = {
    BASIC: basicFields,
    OPERATION: operationFields,
    INSCAP: installedCapacityfields,
  };

  const getUnselectedFields = (fields) => {
    return fields?.filter((item) => !selectedFields?.some((obj) => item.key === obj.key));
  };

  const getKeys = () => {
    let keys = [];

    if (selectedFields?.length > 0) {
      for (let item of selectedFields) {
        keys.push(item.key);
      }
    } else {
      for (let item of fieldsForTable[table]) {
        keys.push(item.key);
      }
    }

    return keys;
  };

  const handleSubmit = async () => {
    setLoader(true);
    let formData = {
      categoryId,
      millId: mill?.id,
      fields: getKeys(),
      insCapSum: table === 'INSCAP',
      membershipType,
    };
    const res = await getMillInfoReport(formData);

    if (res.data?.status === 200) {
      const resultArray = res.data?.result;

      let availableDateFields =
        selectedFields?.length > 0
          ? dateFields?.filter((date) => selectedFields?.some((obj) => date === obj.displayName))
          : dateFields?.filter((date) =>
              fieldsForTable[table]?.some((obj) => date === obj.displayName),
            );

      let convertedArray = resultArray?.map((item) => {
        function convertFalseyValues() {
          let currItem = item;
          for (let key in currItem) {
            if (!currItem[key]) currItem[key] = 'n/a';
          }
          return currItem;
        }

        function convertDates() {
          let currItem = item;
          availableDateFields?.map((date) =>
            currItem[date] && currItem[date] !== 'n/a'
              ? (currItem[date] = renderDate(item[date], 'numeric'))
              : currItem[date],
          );
          return currItem;
        }

        return { ...convertFalseyValues(), ...convertDates() };
      });

      setResults(convertedArray);
    }
    setLoader(false);
  };

  return categoryOptions ? (
    <Box p={4}>
      <Text fontSize="2xl" fontWeight="bold">
        Mill Information Report Wizard
      </Text>

      <Divider mb={3} />
      <Grid templateColumns="repeat(2, 1fr)" gap={3}>
        <GridItem colSpan={1}>
          <Box p={4} bg="white" borderRadius="4px">
            <Box position="relative" p={4} border="1px solid #6B7982" borderRadius="4px">
              <Text
                px={1}
                bg="white"
                left="10px"
                top="-12px"
                fontSize="sm"
                position="absolute"
                color="textSecondary">
                Select Options
              </Text>
              <SelectReport
                label="Select Report"
                placeholder="Select an option"
                setValue={setTable}
                choices={[
                  { name: 'Basic Information', value: 'BASIC' },
                  { name: 'Operational Information', value: 'OPERATION' },
                  { name: 'Installed Capacity', value: 'INSCAP' },
                ]}
              />
              {table ? (
                <>
                  <Text color="textSecondary" mt={4} mb={1.5}>
                    Select Columns
                  </Text>
                  <HStack>
                    <Box
                      p={4}
                      w="45%"
                      h="450px"
                      bg="secondary"
                      overflow="auto"
                      boxShadow="inset 0px 0px 5px rgba(0,0,0,0.2)">
                      {selectAll
                        ? null
                        : getUnselectedFields(fieldsForTable[table])?.map((item, index) => (
                            <Text
                              key={index}
                              cursor="pointer"
                              whiteSpace="nowrap"
                              onClick={() => setSelectedFields([...selectedFields, item])}>
                              {item?.name}
                            </Text>
                          ))}
                    </Box>
                    <VStack w="10%" h="450px" justifyContent="center">
                      <Tooltip label="Select all" aria-label="A tooltip">
                        <IconButton
                          icon={<AiOutlineDoubleRight />}
                          onClick={() => {
                            setSelectedFields(fieldsForTable[table]);
                            setSelectAll(true);
                          }}
                        />
                      </Tooltip>
                      <Tooltip label="Deselect all" aria-label="A tooltip">
                        <IconButton
                          icon={<AiOutlineDoubleLeft />}
                          onClick={() => {
                            setSelectedFields([]);
                            setSelectAll(false);
                          }}
                        />
                      </Tooltip>
                    </VStack>
                    <Box
                      p={4}
                      w="45%"
                      h="450px"
                      bg="secondary"
                      overflow="auto"
                      boxShadow="inset 0px 0px 5px rgba(0,0,0,0.2)">
                      {selectedFields?.map((item, index) => (
                        <Text
                          key={index}
                          cursor="pointer"
                          whiteSpace="nowrap"
                          onClick={() => {
                            setSelectAll(false);
                            setSelectedFields(
                              selectedFields?.filter((obj) => obj.key !== item.key),
                            );
                          }}>
                          {item?.name}
                        </Text>
                      ))}
                    </Box>
                  </HStack>
                </>
              ) : null}
            </Box>
          </Box>
        </GridItem>

        <GridItem colSpan={1}>
          <Box p={4} bg="white" borderRadius="4px">
            <Box position="relative" p={4} border="1px solid #6B7982" borderRadius="4px">
              <Text
                px={1}
                bg="white"
                top="-12px"
                left="10px"
                fontSize="sm"
                position="absolute"
                color="textSecondary">
                Search Information
              </Text>
              <SearchSection forceSearch minimal setSelectedMill={setMill} />
              <HStack>
                <Text mt={1} color="primary.400" mb={1.5}>
                  {mill?.millName}
                </Text>
                {mill?.id ? (
                  <CloseButton
                    size="sm"
                    _focus={{ boxShadow: '0 0 0 3px #9280ff' }}
                    onClick={() => {
                      setMill();
                    }}
                  />
                ) : null}
              </HStack>
              <HStack mt={4}>
                <SelectReport
                  label="Select Category"
                  setValue={setCategoryId}
                  choices={categoryOptions}
                  placeholder="Select an option"
                />
                <SelectReport
                  label="Select Membership Type"
                  setValue={setMembershipType}
                  choices={[
                    { name: 'General', value: 'GENERAL' },
                    { name: 'Associate', value: 'ASSOCIATE' },
                  ]}
                  placeholder="Select an option"
                />
              </HStack>

              <HStack w="100%" mt={4} spacing={4}>
                <FormButton mt={0} isLoading={loader} isDisabled={!table} onClick={handleSubmit}>
                  GENERATE
                </FormButton>
                {loader ? (
                  <HStack
                    flex={1}
                    h="45px"
                    bg="secondary"
                    borderRadius="4px"
                    justifyContent="center">
                    <Spinner
                      size="md"
                      thickness="2px"
                      color="primary.700"
                      emptyColor="primary.50"
                    />
                    <Text color="primary.700">Generating Report...</Text>
                  </HStack>
                ) : results ? (
                  results?.length > 0 ? (
                    <FileExporter
                      title={`Mill-Information${
                        table === 'BASIC'
                          ? '-(Basic)'
                          : table === 'OPERATION'
                          ? '-(Operation)'
                          : table === 'INSCAP'
                          ? '-(Installed Capacity)'
                          : ''
                      }`}
                      data={results}
                    />
                  ) : (
                    <Text color="textSecondary" fontSize="xl" w="100%" textAlign="center">
                      <Icon as={AiOutlineFileSearch} w={10} h={10} /> No entries found!
                    </Text>
                  )
                ) : null}
              </HStack>
            </Box>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  ) : (
    <Loader />
  );
};

export default MillInformationReportWizard;
