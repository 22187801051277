import React from 'react';
import PasswordForm from './PasswordForm';
import { ModalHeader, ModalBody, Text, Divider } from '@chakra-ui/react';

const MembershipRework = ({ executeAction, clearSelection }) => {
  return (
    <>
      <ModalHeader>
        <Text fontSize="22px" width="100%">
          Confirm Your Password
        </Text>
        <Divider />
      </ModalHeader>
      <ModalBody mt={-8}>
        <PasswordForm executeAction={executeAction} clearSelection={clearSelection} />
      </ModalBody>
    </>
  );
};

export default MembershipRework;
