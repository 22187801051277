import {
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  HStack,
  Icon,
  Stack,
  Text,
  VStack,
  useDisclosure,
  StackDivider,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { MdDelete } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import * as yup from 'yup';

//common components
import DialogueBox from '../../../common/DialogueBox';
import FormButton from '../../../common/FormButton';
import InfoTiles from '../../../common/InfoTiles';
import MinimalTable from '../../../common/MinimalTable';
import Loader from '../../../snippets/Loader';
import BillDate from '../../BillDate';
import CertificateBill from '../CertificateBill';
import CostBreakdown from '../CostBreakdown';
import DutyCostBreakdown from '../DutyCostBreakdown';
import MoneyReceipt from '../MoneyReceipt';

//form sections
import ChequeInfoSection, { chequeInfoFieldsValidation } from '../NewCertificate/ChequeInfoSection';
import CertNumber, { certNumberFieldsValidation } from './CertNumber';
import CertQuantity, { certQuantityFieldsValidation } from './CertQuantity';
import CertRange, { certRangeFieldsValidation } from './CertRange';
import { paymentMethodFieldsValidation } from './PaymentMethod';
import ReceiveDues, { receiveDuesFieldsValidation } from './ReceiveDues';

//actions & api
import {
  deleteCertPurchaseOrder,
  deleteCertificateBatch,
  getCertificateInvoices,
  getLastAssignedCertificates,
  patchCertificateInvoices,
  updateDutyCertificateReceipt,
} from '../../../../api/accounts';
import { FETCH_CERT_PURCHASE_INVOICE } from '../../../../store/actions/actionTypes';
import { notify } from '../../../../store/actions/globalActions';

//utils & constants
import { ACCOUNTS_CERTIFICATE_URL } from '../../../../constants/routerUrl';
import { formatDate, renderDate } from '../../../../util/formatUtils';
import { useYupValidationResolver } from '../../../../util/yupUtils';
import DataTable from '../../../common/dataTable/DataTable';
import EditReceipt from './EditReceipt';
import PaymentMethodCert from './PaymentMethodCert';
import commaNumber from 'comma-number';

const CertRequestAcc = () => {
  const user = useSelector((state) => state.auth.user);

  const history = useHistory();
  const dispatch = useDispatch();
  const rangeErrorRef = useRef();
  const certificateBillRef = useRef();
  const certMoneyReceiptRef = useRef();
  const [rowData, setRowData] = useState();
  const [print, setPrint] = useState(false);
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState();
  const [method, setMethod] = useState('CASH');
  const [newDiscount, setNewDiscount] = useState(0);
  const [certificates, setCertificates] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [billDate, setBillDate] = useState(new Date());
  const [rangeErrorMsg, setRangeErrorMsg] = useState();
  const [chequePayment, setChequePayment] = useState(false);
  const [paymentHistoryData, setPaymentHistoryData] = useState([]);
  const [isDutyCertNumAssigned, setIsDutyCertNumAssigned] = useState(false);
  const data = useSelector((state) => state.accounts.currentData);
  const { orderId, certFor } = history?.location?.state;

  let moneyReceiptData = {
    slNo: data?.orderId,
    mrDate: data?.dateSold,
    receivedFrom: data?.millName,
    amount: certFor === 'duty' ? data?.paidAmount : data?.total,
    paymentMethod: data?.paymentMethod,
    certFor,
    paymentFor:
      data?.type === 'CASH'
        ? 'Cash-Incentive Certificate'
        : data?.type === 'GSP'
        ? 'GSP Certificate'
        : certFor === 'processing'
        ? 'Processing Certificate'
        : certFor === 'duty'
        ? 'Duty Exemption Certificate'
        : null,
    chequeNo: data?.chequeNo,
    chequeDated: data?.chequeDated,
    quantity: data?.noOfCopies,
    range: data?.certRange,
    certNum: data?.certificateDetails?.[0]?.certificateNum,
    total: certFor === 'duty' ? data?.paidAmount : data?.total,
    narration: data?.narration ? data?.narration : null,
  };

  const dutyMoneyReceiptData = {
    slNo: rowData?.uid,
    mrDate: rowData?.date,
    receivedFrom: data?.millName,
    amount: rowData?.paidAmount,
    paymentMethod: rowData?.paymentMethod,
    certFor,
    paymentFor: 'Duty Exemption Certificate',
    chequeNo: rowData?.chequeNo,
    chequeDated: rowData?.chequeDated,
    range: data?.certRange,
    certNum: data?.certificateDetails?.[0]?.certificateNum,
    total: rowData?.paidAmount,
    narration: rowData?.narration,
  };

  const millInfo = [
    { header: 'Mill Name', value: data?.millName },
    { header: 'Membership No', value: data?.membershipNo },
    { header: 'Email', value: data?.hoInfo?.millEmail },
    { header: 'Contact', value: data?.hoInfo?.millPhoneNo },
  ];

  const dutyExemptionPaymentInfo = [
    {
      header: 'Total Cost',
      value: data?.dutyCertCost?.total ? `৳ ${commaNumber(data?.dutyCertCost?.total)}` : '৳ 0',
    },
    { header: 'Discount', value: data?.discount ? `৳ ${commaNumber(data?.discount)}` : '৳ 0' },
    {
      header: 'Adjustments',
      value: data?.adjustments ? `৳ ${commaNumber(data?.adjustments)}` : '৳ 0',
    },
    {
      header: 'Paid Amount',
      value: data?.paidAmount ? `৳ ${commaNumber(data?.paidAmount)}` : '৳ 0',
    },
    { header: 'Due', value: data?.dues ? `৳ ${commaNumber(data?.dues)}` : '৳ 0' },
    {
      header: 'Extra Collection',
      value: data?.surplusInfo?.amount ? `৳ ${commaNumber(data?.surplusInfo?.amount)}` : '৳ 0',
    },
    {
      header: 'Remarks',
      colSpan: 4,
      value: data?.remarks === 'Certificate is ready for collection!' ? 'N/A' : data?.remarks,
    },
  ];

  const columns = [
    { header: 'Serial', accessor: 'certificateNum' },
    { header: 'Type', accessor: 'type' },
    { header: 'Status', accessor: 'status' },
    { header: 'Used On', accessor: 'usedOn', isDate: true },
    { header: 'Application Status', accessor: 'applicationStatus' },
  ];

  const paymentHistoryColumns = [
    { header: 'Serial', accessor: 'uid' },
    { isDate: true, header: 'Payment Date', accessor: 'date' },
    { header: 'Amount', accessor: 'paidAmount', currency: '৳' },
    {
      header: 'Payment Method',
      accessor: 'paymentMethod',
      hoverInfo: ['chequeNo as ID', 'chequeDated as Date'],
    },
    // {
    //   header: 'Discount',
    //   accessor: 'discount',
    // },
    // {
    //   header: 'Extra Collection',
    //   accessor: 'surplusAmount',
    //   currency: '৳',
    // },
    {
      header: 'Extra Collection',
      accessor: 'surplusAmount',
      currency: '৳',
    },
    {
      header: 'Narration',
      accessor: 'narration',
    },
    {
      isButton: true,
      header: 'Receipt',
      accessor: 'id',
      actionScheme: [{ group: 'boolean', trueVariant: 'print', trueAction: 'PRINT' }],
    },
    {
      isButton: true,
      header: 'Action',
      accessor: 'id',
      actionScheme: [{ group: 'boolean', trueVariant: 'write', trueAction: 'EDIT' }],
    },
  ];

  useEffect(() => {
    dispatch({ type: FETCH_CERT_PURCHASE_INVOICE, payload: null });
    (async () => {
      const res = await getCertificateInvoices({ type: certFor, uid: orderId });
      if (res.data?.status === 200) {
        dispatch({ type: FETCH_CERT_PURCHASE_INVOICE, payload: res.data });
        setValue('numOfCert', res?.data?.noOfCopies);
        setPaymentHistoryData(res?.data?.paymentHistory || []);
      }
    })();
    (async () => {
      const res = await getLastAssignedCertificates();
      if (res.data?.status === 200) {
        setCertificates(res.data);
      }
    })();
    return () => {
      setCertificates();
      dispatch({ type: FETCH_CERT_PURCHASE_INVOICE, payload: null });
    };
  }, [isConfirming, isDeleting]);

  useEffect(() => {
    if (certFor === 'duty') {
      if (parseInt(data?.certificateDetails?.[0]?.certificateNum) > 0) {
        setIsDutyCertNumAssigned(true);
        setValue('dutyCertNum', data?.certificateDetails?.[0]?.certificateNum);
      } else {
        setIsDutyCertNumAssigned(false);
        setValue('dutyCertNum', '');
      }
    }
  }, [data]);

  const getValidationSchema = () => {
    if (data?.paymentStatus === 'UNPAID') {
      if (certFor === 'duty') {
        if (chequePayment) {
          return yup.object().shape({
            ...certNumberFieldsValidation,
            ...paymentMethodFieldsValidation,
            ...chequeInfoFieldsValidation(method),
          });
        } else {
          return yup.object().shape({
            ...certNumberFieldsValidation,
            ...paymentMethodFieldsValidation,
          });
        }
      } else {
        if (chequePayment) {
          return yup.object().shape({
            ...certRangeFieldsValidation,
            ...chequeInfoFieldsValidation(method),
            ...certQuantityFieldsValidation,
            ...paymentMethodFieldsValidation,
          });
        } else {
          return yup.object().shape({
            ...certRangeFieldsValidation,
            ...certQuantityFieldsValidation,
            ...paymentMethodFieldsValidation,
          });
        }
      }
    } else {
      if (chequePayment) {
        return yup.object().shape({
          ...chequeInfoFieldsValidation(method),
          ...receiveDuesFieldsValidation,
        });
      } else {
        return yup.object().shape({
          ...receiveDuesFieldsValidation,
        });
      }
    }
  };

  const resolver = useYupValidationResolver(getValidationSchema());

  const {
    reset,
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver, defaultValues: { discount: 0, dateSold: new Date() } });

  const discount = useWatch({ control, name: 'discount' });
  const endRange = useWatch({ control, name: 'endRange' });
  const numOfCert = useWatch({ control, name: 'numOfCert' });
  const startRange = useWatch({ control, name: 'startRange' });
  const paymentMethod = useWatch({ control, name: 'paymentMethod' });
  const receivedAmount = useWatch({ control, name: 'receivedAmount' });
  const narration = useWatch({ control, name: 'narration' });

  useEffect(() => {
    setMethod(paymentMethod);

    paymentMethod === 'CASH'
      ? setChequePayment(false)
      : paymentMethod
      ? setChequePayment(true)
      : setChequePayment(false);
    discount > 0 ? setNewDiscount(discount) : setNewDiscount(0);

    const start = parseInt(startRange);
    const end = parseInt(endRange);
    const qty = parseInt(numOfCert || 1);

    if (start && end) {
      if (end < 0) {
        setRangeErrorMsg('End Range must be a valid number or equal to 0');
      } else if (end > 0) {
        if (end < start) {
          setRangeErrorMsg('End Range can not be smaller than Start Range');
        } else {
          end - start + 1 === qty
            ? setRangeErrorMsg()
            : setRangeErrorMsg('Range does not match requested quantity!');
        }
      } else {
        qty === 1
          ? setRangeErrorMsg()
          : setRangeErrorMsg('Range does not match requested quantity!');
      }
    } else if (start && !end) {
      qty === 1 ? setRangeErrorMsg() : setRangeErrorMsg('Range does not match requested quantity!');
    } else if (!start && end) {
      setRangeErrorMsg();
    } else {
      setRangeErrorMsg();
    }
  }, [paymentMethod, discount, startRange, endRange, numOfCert]);

  useEffect(() => {
    const start = parseInt(startRange);
    const qty = parseInt(numOfCert || 1);
    if (startRange > 0) {
      qty > 1 ? setValue('endRange', start + qty - 1) : setValue('endRange', 0);
    }
  }, [startRange, numOfCert]);

  const onSubmit = (formdata) => {
    setFormData({
      ...formdata,
      unitCost: data?.unitCost,
      preAssigned: isDutyCertNumAssigned,
      productionCertificateType: data?.type,
      dateSold: formatDate(formdata?.dateSold),
      calculatedTotal: data?.dutyCertCost?.total,
      chequeDated: formatDate(formdata?.chequeDated),
    });
    if (rangeErrorMsg) {
      rangeErrorRef.current.scrollIntoView();
    } else {
      setModal('PAYMENT');
      onOpen();
    }
  };

  const confirmSubmit = async () => {
    setIsConfirming(true);
    const res = await patchCertificateInvoices({ type: certFor, uid: orderId, formData });
    if (res.data?.status === 200) {
      reset({ paymentMethod: '', chequeNo: '', chequeDated: '', discount: 0 });
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      (async () => {
        const res = await getCertificateInvoices({ type: certFor, uid: orderId });
        if (res.data?.status === 200) {
          dispatch({ type: FETCH_CERT_PURCHASE_INVOICE, payload: res.data });
          setPaymentHistoryData(res?.data?.paymentHistory || []);
        }
      })();
      (async () => {
        const res = await getLastAssignedCertificates();
        if (res.data?.status === 200) {
          setCertificates(res.data);
        }
      })();
      setPrint(true);
      setIsConfirming(false);
    }
    setIsConfirming(false);
  };

  const confirmDuePayment = async () => {
    console.log(narration);

    if (!formData?.narration && formData?.narration == '') {
      formData.narration = null;
    }

    const res = await patchCertificateInvoices({
      type: certFor,
      flag: 'due',
      uid: orderId,
      formData,
    });
    if (res.data?.status === 200) {
      reset({ receivedAmount: 0, narration: '' });
      dispatch(
        notify({
          title: 'Success',
          description: 'Payment Successful!',
          status: 'success',
          duration: 5000,
        }),
      );
      (async () => {
        const res = await getCertificateInvoices({ type: certFor, uid: orderId });
        if (res.data?.status === 200) {
          dispatch({ type: FETCH_CERT_PURCHASE_INVOICE, payload: res.data });
          setPaymentHistoryData(res?.data?.paymentHistory || []);
        }
      })();
      (async () => {
        const res = await getLastAssignedCertificates();
        if (res.data?.status === 200) {
          setCertificates(res.data);
        }
      })();
    }
  };

  console.log(certFor);
  const confirmDelete = async () => {
    const batchData = {
      certType: certFor,
      batchId: data?.orderId,
    };

    setIsDeleting(true);
    const res = await deleteCertificateBatch(batchData);
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      setIsDeleting(false);
      onClose();
      history.push(ACCOUNTS_CERTIFICATE_URL);
    }
    setIsDeleting(false);
    onClose();
  };

  const handleDelete = () => {
    setModal('DELETE');
    onOpen();
  };

  const handleBillPrint = () => {
    setBillDate(data?.createdAt || new Date());
    setModal('BILL_DATE');
    onOpen();
  };

  const printCertificateBill = useReactToPrint({
    documentTitle: `BTMA-Bill-${
      data?.type === 'CASH'
        ? 'Cash-Incentive'
        : data?.type === 'GSP'
        ? 'GSP'
        : certFor === 'processing'
        ? 'Processing'
        : certFor === 'duty'
        ? 'Duty-Exemption'
        : null
    }-Certificate-${data?.orderId}-${renderDate(billDate)}`,
    content: () => certificateBillRef.current,
  });

  const printDutyCertReceipt = useReactToPrint({
    documentTitle: `BTMA-Money-Receipt--Certificate-${rowData?.uid}-${renderDate(
      rowData?.dateSold,
    )}`,
    content: () => certMoneyReceiptRef.current,
  });

  const handlePrint = ({ rowObject }) => {
    setRowData(rowObject);

    setTimeout(() => {
      printDutyCertReceipt();
    }, 500);
  };

  const handleDeleteOrder = () => {
    setModal('DELETEORDER');
    onOpen();
  };

  const confirmDeleteOrder = async () => {
    const res = await deleteCertPurchaseOrder(certFor, orderId);
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
    }
    history.push(ACCOUNTS_CERTIFICATE_URL);
  };

  const handleReceiptEdit = ({ rowObject }) => {
    setRowData(rowObject);
    setModal('EDIT_RECEIPT_FORM');
    onOpen();
  };

  const onReceiptEdit = (data) => {
    setFormData({ ...data, rowUid: rowData?.uid });
    setModal('EDIT_RECEIPT_ALERT');
    onOpen();
  };

  const confirmReceiptUpdate = async () => {
    const response = await updateDutyCertificateReceipt(orderId, formData);
    if (response.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: response.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      (async () => {
        const res = await getCertificateInvoices({ type: certFor, uid: orderId });
        if (res.data?.status === 200) {
          dispatch({ type: FETCH_CERT_PURCHASE_INVOICE, payload: res.data });
          setPaymentHistoryData(res?.data?.paymentHistory || []);
        }
      })();
      (async () => {
        const res = await getLastAssignedCertificates();
        if (res.data?.status === 200) {
          setCertificates(res.data);
        }
      })();
    }
  };

  const renderModal = () => {
    switch (modal) {
      case 'PAYMENT':
        return (
          <DialogueBox
            close={onClose}
            alertTitle="Confirm"
            mountControl={isOpen}
            alertMessage="Are you sure you want to submit these information?"
            performAction={
              certFor === 'duty' && data?.paymentStatus === 'PAID'
                ? confirmDuePayment
                : confirmSubmit
            }>
            {certFor === 'duty' ? (
              data?.paymentStatus === 'UNPAID' ? (
                <Box bg="secondary" border="2px solid #6B7982" borderStyle="dotted" p={4} mt={4}>
                  <VStack
                    divider={<StackDivider borderColor="gray.200" />}
                    spacing={2}
                    align="stretch">
                    <Flex>
                      <Text>Total Bill: </Text>
                      <Spacer />
                      <Text>৳{commaNumber(parseFloat(data?.dutyCertCost?.total || 0))}</Text>
                    </Flex>
                    <Flex>
                      <Text>Adjustments: </Text>
                      <Spacer />
                      <Text> - ৳{commaNumber(parseFloat(data?.adjustments || 0))}</Text>
                    </Flex>
                    <Flex>
                      <Text>Discount: </Text>
                      <Spacer />
                      <Text> - ৳{commaNumber(parseFloat(formData?.discount) || 0)}</Text>
                    </Flex>
                    <Flex style={{ fontWeight: 600 }}>
                      <Text>Total Payable: </Text>
                      <Spacer />
                      <Text>
                        ৳
                        {commaNumber(
                          parseFloat(data?.dutyCertCost?.total) -
                            parseFloat(data?.adjustments) -
                            parseFloat(formData?.discount) || 0,
                        )}
                      </Text>
                    </Flex>
                    <Flex style={{ fontWeight: 600, color: 'green' }}>
                      <Text>Amount Received: </Text>
                      <Spacer />
                      <Text>৳{commaNumber(parseFloat(formData?.paidAmount) || 0)}</Text>
                    </Flex>
                    <Flex style={{ color: 'red' }}>
                      <Text>Due: </Text>
                      <Spacer />
                      <Text>
                        ৳
                        {parseFloat(data?.dutyCertCost?.total) -
                          parseFloat(formData?.discount) -
                          parseFloat(data?.adjustments) -
                          parseFloat(formData?.paidAmount) <
                        0
                          ? 0
                          : commaNumber(
                              parseFloat(data?.dutyCertCost?.total) -
                                parseFloat(formData?.discount) -
                                parseFloat(data?.adjustments) -
                                parseFloat(formData?.paidAmount) || 0,
                            )}
                      </Text>
                    </Flex>
                    <Flex style={{ color: 'red' }}>
                      <Text>Extra Collection: </Text>
                      <Spacer />
                      <Text>
                        ৳
                        {parseFloat(data?.dutyCertCost?.total) -
                          parseFloat(formData?.discount) -
                          parseFloat(data?.adjustments) -
                          parseFloat(formData?.paidAmount) <
                        0
                          ? commaNumber(
                              parseFloat(formData?.paidAmount) -
                                (parseFloat(data?.dutyCertCost?.total) -
                                  parseFloat(formData?.discount) -
                                  parseFloat(data?.adjustments)) || 0,
                            )
                          : 0}
                      </Text>
                    </Flex>
                  </VStack>
                </Box>
              ) : (
                <Box bg="secondary" border="2px solid #6B7982" borderStyle="dotted" p={4} mt={4}>
                  <VStack
                    divider={<StackDivider borderColor="gray.200" />}
                    spacing={2}
                    align="stretch">
                    <Flex>
                      <Text>Total Bill: </Text>
                      <Spacer />
                      <Text>৳{commaNumber(parseFloat(data?.dutyCertCost?.total || 0))}</Text>
                    </Flex>
                    <Flex>
                      <Text>Adjustments: </Text>
                      <Spacer />
                      <Text> - ৳{commaNumber(parseFloat(data?.adjustments || 0))}</Text>
                    </Flex>
                    <Flex>
                      <Text>Discount: </Text>
                      <Spacer />
                      <Text> - ৳{commaNumber(parseFloat(data?.discount) || 0)}</Text>
                    </Flex>
                    <Flex>
                      <Text>Paid Amount: </Text>
                      <Spacer />
                      <Text> ৳{commaNumber(parseFloat(data?.paidAmount) || 0)}</Text>
                    </Flex>
                    <Flex style={{ fontWeight: 600 }}>
                      <Text>Total Due: </Text>
                      <Spacer />
                      <Text> ৳{commaNumber(parseFloat(data?.dues) || 0)}</Text>
                    </Flex>

                    <Flex style={{ fontWeight: 600, color: 'green' }}>
                      <Text>Received Amount: </Text>
                      <Spacer />
                      <Text> ৳{commaNumber(parseFloat(formData?.receivedAmount) || 0)}</Text>
                    </Flex>

                    <Flex style={{ color: 'red' }}>
                      <Text>Remaining Due: </Text>
                      <Spacer />
                      <Text>
                        ৳
                        {parseFloat(data?.dues || 0) - parseFloat(formData?.receivedAmount || 0) > 0
                          ? commaNumber(
                              parseFloat(data?.dues) - parseFloat(formData?.receivedAmount) || 0,
                            )
                          : 0}
                      </Text>
                    </Flex>

                    {parseFloat(data?.dues || 0) - parseFloat(formData?.receivedAmount || 0) <
                      0 && (
                      <Flex style={{ color: 'red' }}>
                        <Text>Extra Collection: </Text>
                        <Spacer />
                        <Text>
                          ৳
                          {commaNumber(
                            parseFloat(formData?.receivedAmount) - parseFloat(data?.dues),
                          )}
                        </Text>
                      </Flex>
                    )}
                  </VStack>
                </Box>
              )
            ) : null}
          </DialogueBox>
        );

      case 'DELETE':
        return (
          <DialogueBox
            close={onClose}
            onActionKeepOpen
            alertTitle="Confirm"
            mountControl={isOpen}
            performAction={confirmDelete}
            actionButtonLoading={isDeleting}
            alertMessage="Are you sure you want to delete this batch of certificate?"
          />
        );

      case 'DELETEORDER':
        return (
          <DialogueBox
            close={onClose}
            onActionKeepOpen
            alertTitle="Confirm"
            mountControl={isOpen}
            actionButtonLoading={isDeleting}
            performAction={confirmDeleteOrder}
            alertMessage="Are you sure you want to delete this order?"
          />
        );

      case 'BILL_DATE':
        return (
          <DialogueBox
            noCancel
            close={onClose}
            mountControl={isOpen}
            actionButtonText="Okay"
            alertTitle="Issue Date"
            performAction={printCertificateBill}>
            <BillDate setDate={setBillDate} date={billDate} />
          </DialogueBox>
        );

      case 'EDIT_RECEIPT_FORM':
        return (
          <DialogueBox
            noAction
            noCancel
            close={onClose}
            mountControl={isOpen}
            actionButtonText="Update"
            alertTitle="Update Receipt">
            <EditReceipt data={rowData} onSubmitCallback={onReceiptEdit} />
          </DialogueBox>
        );

      case 'EDIT_RECEIPT_ALERT':
        return (
          <DialogueBox
            close={onClose}
            alertTitle="Confirm"
            mountControl={isOpen}
            performAction={confirmReceiptUpdate}
            alertMessage="Are you sure you want to update this payment information?"
          />
        );
      default:
        return null;
    }
  };

  return data && certFor ? (
    <Box p={4}>
      <InfoTiles sectionName="Mill Information" sectionColumn={4} infoFields={millInfo} />

      <Grid templateColumns="repeat(4, 1fr)" gap={3} p={4} mt={4} bg="white">
        <GridItem colSpan={4}>
          <Text fontSize="20px" fontWeight="bold" mb={1}>
            Last Used Certificate Number
          </Text>
        </GridItem>

        <GridItem
          p={4}
          colSpan={1}
          border="1px"
          bg="secondary"
          borderRadius="4px"
          borderColor="primary.300">
          {certificates ? (
            <>
              <Text fontSize="md" color="textSecondary">
                Production-GSP
              </Text>
              <Text fontSize="xl" mt={1.5}>
                {certificates?.prodGsp || 'N/A'}
              </Text>
            </>
          ) : (
            <Loader />
          )}
        </GridItem>

        <GridItem
          p={4}
          colSpan={1}
          border="1px"
          bg="secondary"
          borderRadius="4px"
          borderColor="primary.300">
          {certificates ? (
            <>
              <Text fontSize="md" color="textSecondary">
                Production-Cash
              </Text>
              <Text fontSize="xl" mt={1.5}>
                {certificates?.prodCash || 'N/A'}
              </Text>
            </>
          ) : (
            <Loader />
          )}
        </GridItem>

        <GridItem
          p={4}
          colSpan={1}
          border="1px"
          bg="secondary"
          borderRadius="4px"
          borderColor="primary.300">
          {certificates ? (
            <>
              <Text fontSize="md" color="textSecondary">
                Processing
              </Text>
              <Text fontSize="xl" mt={1.5}>
                {certificates?.proc || 'N/A'}
              </Text>
            </>
          ) : (
            <Loader />
          )}
        </GridItem>

        <GridItem
          p={4}
          colSpan={1}
          border="1px"
          bg="secondary"
          borderRadius="4px"
          borderColor="primary.300">
          {certificates ? (
            <>
              <Text fontSize="md" color="textSecondary">
                Duty-Exemption
              </Text>
              <Text fontSize="xl" mt={1.5}>
                {certificates?.duty || 'N/A'}
              </Text>
            </>
          ) : (
            <Loader />
          )}
        </GridItem>
      </Grid>

      <HStack mt={4}>
        <Text fontSize="xl" fontWeight="bold">
          Invoice:
        </Text>
        <Text fontSize="xl" fontWeight="bold" color="red">
          <b>{data?.orderId}</b>
        </Text>
      </HStack>

      <Box mt={4}>
        <Text mb={2} fontSize="xl" fontWeight="bold">
          {data?.paymentStatus === 'PAID' ? 'Certificate Information' : 'Assign Certificate'}
        </Text>
        <Divider mb={2} />
        <Grid templateColumns="repeat(5, 1fr)" gap={3}>
          {certFor === 'duty' && data?.paymentStatus === 'PAID' ? (
            <GridItem colSpan={5}>
              <InfoTiles
                sectionName="Payment Information"
                sectionColumn={5}
                infoFields={dutyExemptionPaymentInfo}
                highlightAmountBorder={true}
              />
            </GridItem>
          ) : null}

          {data?.dues || data?.surplusInfo?.amount ? (
            <GridItem
              p={4}
              bg="white"
              colSpan={5}
              border="1px"
              borderColor={data?.dues ? 'red' : 'blue'}
              borderRadius="md"
              borderStyle="dashed">
              <Text
                fontSize="lg"
                fontWeight={600}
                textAlign="center"
                color={data?.dues ? 'red' : 'blue'}>
                {data?.dues ? 'Remaining Due' : 'Extra Collection'} : ৳{' '}
                {data?.dues
                  ? commaNumber(data?.dues)
                  : data?.surplusInfo?.amount
                  ? commaNumber(data?.surplusInfo?.amount)
                  : 'N/A'}
              </Text>
            </GridItem>
          ) : null}
          {data?.paymentStatus === 'UNPAID' ? (
            <GridItem
              p={4}
              as="form"
              bg="white"
              colSpan={2}
              borderRadius="4px"
              onSubmit={handleSubmit(onSubmit)}>
              {certFor !== 'duty' && (
                <CertQuantity register={register} errors={errors} control={control} />
              )}
              <PaymentMethodCert register={register} errors={errors} control={control} />

              {chequePayment && (
                <ChequeInfoSection
                  errors={errors}
                  control={control}
                  register={register}
                  method={paymentMethod}
                />
              )}
              {certFor === 'duty' ? (
                <CertNumber
                  disabledFields={
                    isDutyCertNumAssigned
                      ? user?.adminRole !== 'SUPERADMIN'
                        ? ['discount', 'dutyCertNum']
                        : ['dutyCertNum']
                      : []
                  }
                  register={register}
                  errors={errors}
                  control={control}
                />
              ) : (
                <>
                  <CertRange register={register} errors={errors} control={control} />
                  <Text px={3} mb={3} mt={-4} fontSize="xs" color="invalid" ref={rangeErrorRef}>
                    {rangeErrorMsg}
                  </Text>
                </>
              )}

              <HStack px={3} pb={3} spacing={2} justifyContent="right" alignItems="center">
                {certFor !== 'duty' && user?.adminRole === 'SUPERADMIN' && (
                  <Button
                    bg="red.500"
                    onClick={handleDeleteOrder}
                    _hover={{ bg: 'red.400' }}
                    _focus={{ boxShadow: 'none' }}
                    h={['45px', '45px', '45px', '50px', '55px']}
                    rightIcon={<Icon as={MdDelete} w={5} h={5} />}>
                    DELETE ORDER
                  </Button>
                )}
                <FormButton type="submit" isLoading={isSubmitting}>
                  CONFIRM
                </FormButton>
              </HStack>
            </GridItem>
          ) : (
            <GridItem
              p={4}
              as="form"
              bg="white"
              colSpan={2}
              overflowX="auto"
              borderRadius="4px"
              onSubmit={handleSubmit(onSubmit)}>
              {data?.dues > 0 ? (
                <>
                  <Text fontSize="1xl" mb={4} fontWeight="bold">
                    Receive Dues
                  </Text>
                  <ReceiveDues register={register} errors={errors} control={control} />
                  {chequePayment && (
                    <ChequeInfoSection
                      errors={errors}
                      control={control}
                      register={register}
                      method={paymentMethod}
                    />
                  )}
                  <HStack alignItems="flex-start" justifyContent="end">
                    <FormButton
                      mt={0}
                      mr={3}
                      type="submit"
                      isLoading={isSubmitting}
                      isDisabled={receivedAmount <= 0}>
                      CONFIRM
                    </FormButton>
                  </HStack>
                </>
              ) : null}
              <Text fontSize="1xl" ml={3} mt={3} fontWeight="bold">
                Certificates
              </Text>
              <Box p={3}>
                <MinimalTable scrollable columns={columns} data={data?.certificateDetails} />
              </Box>
              <VStack alignItems="flex-end" pr={3}>
                {user?.adminRole === 'SUPERADMIN' && (
                  <Button
                    bg="red.500"
                    onClick={handleDelete}
                    _hover={{ bg: 'red.400' }}
                    _focus={{ boxShadow: 'none' }}
                    rightIcon={<Icon as={MdDelete} w={5} h={5} />}
                    isDisabled={
                      data?.certificateDetails?.filter((item) => item?.status === 'USED')?.length >
                      0
                        ? true
                        : false
                    }>
                    DELETE
                  </Button>
                )}
                {data?.certificateDetails?.filter((item) => item?.status === 'USED')?.length > 0 ? (
                  <Text fontSize="xs" color="invalid" textAlign="right">
                    *** You cannot delete this entry because some of the certificates of this batch
                    are already used!
                  </Text>
                ) : null}
              </VStack>
            </GridItem>
          )}

          <GridItem colSpan={3} p={4} bg="white" borderRadius="4px">
            <Box p={3} maxW="45vw" overflowX="auto">
              {certFor?.toUpperCase() === 'DUTY' ? (
                <DutyCostBreakdown
                  data={{
                    ...data,
                    certFor,
                    lcValue: data?.value,
                    currency: data?.currency,
                    dutyCertCost: data?.dutyCertCost,
                    serial: data?.certificateDetails?.[0]?.certificateNum,
                    discount: newDiscount > 0 ? newDiscount : data?.discount,
                  }}
                />
              ) : (
                <CostBreakdown
                  data={{
                    ...data,
                    certFor,
                    range: data?.certRange,
                    noOfCopies: parseInt(numOfCert) || 0,
                    total: parseInt(data.unitCost * numOfCert) || 0,
                  }}
                />
              )}
            </Box>
            <Stack flexDir="column" alignItems="flex-end" mt={1} p={3}>
              <HStack>
                <FormButton mt={0} onClick={handleBillPrint}>
                  PRINT BILL
                </FormButton>
                <Box display="none">
                  <CertificateBill
                    title="Certificate"
                    ref={certificateBillRef}
                    data={{
                      billDate,
                      billNo: data?.orderId,
                      millName: data?.millName,
                      millAddress: data?.hoInfo?.hoName,
                    }}
                    costs={{
                      ...data,
                      certFor,
                      lcValue: data?.value,
                      range: data?.certRange,
                      currency: data?.currency,
                      adjustments: data?.adjustments,
                      dutyCertCost: data?.dutyCertCost,
                      noOfCopies: parseInt(numOfCert) || 0,
                      total: parseInt(data.unitCost * numOfCert) || 0,
                      serial: data?.certificateDetails?.[0]?.certificateNum,
                      discount: newDiscount > 0 ? newDiscount : data?.discount,
                    }}
                  />
                </Box>

                {(certFor !== 'duty' ||
                  !data?.paymentHistory ||
                  data?.paymentHistory?.length < 1) && (
                  <ReactToPrint
                    documentTitle={`BTMA-Money-Receipt-${
                      data?.type === 'CASH'
                        ? 'Cash-Incentive'
                        : data?.type === 'GSP'
                        ? 'GSP'
                        : certFor === 'processing'
                        ? 'Processing'
                        : certFor === 'duty'
                        ? 'Duty-Exemption'
                        : ''
                    }-Certificate-${data?.orderId}-${renderDate(data?.dateSold)}`}
                    trigger={() => (
                      <FormButton isDisabled={data?.paymentStatus === 'PAID' ? false : !print}>
                        PRINT RECEIPT
                      </FormButton>
                    )}
                    content={() => certMoneyReceiptRef.current}
                  />
                )}

                <Box display="none">
                  <MoneyReceipt ref={certMoneyReceiptRef} data={moneyReceiptData} />
                </Box>
              </HStack>
            </Stack>
          </GridItem>

          {certFor === 'duty' &&
            data?.paymentStatus === 'PAID' &&
            data?.paymentHistory?.length > 0 && (
              <GridItem colSpan={5} p={4} bg="white" borderRadius="4px">
                <DataTable
                  caption="Payment History"
                  data={paymentHistoryData}
                  columns={
                    user?.adminRole !== 'SUPERADMIN'
                      ? paymentHistoryColumns.filter((c) => c.header != 'Action')
                      : paymentHistoryColumns
                  }
                  currentPageSize={data?.pageSize}
                  actions={{ PRINT: handlePrint, EDIT: handleReceiptEdit }}
                />
                <Box display="none">
                  <MoneyReceipt ref={certMoneyReceiptRef} data={dutyMoneyReceiptData} />
                </Box>
              </GridItem>
            )}
        </Grid>
      </Box>
      {renderModal()}
    </Box>
  ) : (
    <Loader />
  );
};

export default CertRequestAcc;
