const Button = {
  baseStyle: {
    borderRadius: '2px',
    fontWeight: 'semibold',
    _focus: { boxShadow: '0 0 0 3px #9280ff' },
  },
  sizes: {
    xl: {
      h: '56px',
      px: '32px',
      fontSize: 'lg',
    },
  },
  variants: {
    formNext: () => ({
      size: 'lg',
      minW: '200px',
      color: '#fff',
      fontSize: 'md',
      variant: 'solid',
      bg: 'primary.700',
      borderRadius: '2px',
      _hover: { bg: 'primary.400' },
      height: ['45px', '45px', '45px', '50px', '55px'],
    }),
    formRed: () => ({
      size: 'lg',
      minW: '200px',
      color: '#fff',
      fontSize: 'md',
      variant: 'solid',
      bg: '#9b4236',
      borderRadius: '2px',
      _hover: { bg: '#c23725' },
      height: ['45px', '45px', '45px', '50px', '55px'],
    }),
    tabBarTab: () => ({
      bg: 'white',
      lineHeight: 1,
      fontSize: 'md',
      isFullWidth: true,
      borderRadius: '0px',
      whiteSpace: 'normal',
      color: 'primary.700',
      _focus: {
        boxShadow: 'none',
      },
      _active: {
        color: 'primary.700',
        bg: 'primary.50',
        boxShadow: '2px 2px 2px rgba(0,0,0,0.2)',
      },
    }),
    sideNavTab: () => ({
      width: '100%',
      textAlign: 'left',
      isFullWidth: true,
      whiteSpace: 'normal',
      height: 'fit-content',
      color: 'textSecondary',
      justifyContent: 'flex-start',
      _focus: {
        boxShadow: 'none',
      },
      _active: {
        color: 'primary.700',
        bgColor: 'primary.50',
      },
      _hover: {
        color: 'primary.700',
        bgColor: 'rgba(233, 228, 255, 0.4)',
      },
    }),
    logOut: () => ({
      bg: 'red.400',
      color: 'white',
      _hover: {
        bg: '#E53E3E',
      },
      _active: {
        bg: 'red.200',
      },
      _focus: {
        boxShadow: 'none',
      },
    }),
    ghost: () => ({
      color: 'textSecondary',
      _active: {
        color: 'primary.700',
        bg: 'secondary',
        borderColor: 'primary.200',
      },
      _focus: {
        borderColor: 'primary.200',
      },
    }),
    solid: () => ({
      color: '#fff',
      bg: 'primary.700',
      _hover: { bg: 'primary.400' },
    }),
    modalAction: () => ({
      color: '#fff',
      minW: '100px',
      bg: 'primary.700',
      _hover: { bg: 'primary.400' },
    }),
    tableAction: () => ({
      size: 'lg',
      bg: 'white',
      borderRadius: '2px',
      color: 'primary.300',
      border: '2px solid #674dfe',
      _focus: { boxShadow: 'none' },
      _hover: { bg: 'primary.300', color: 'white' },
    }),
    subtle: () => ({
      mt: 2,
      color: '#fff',
      height: '40px',
      maxW: 'fit-content',
      bgColor: 'textSecondary',
      _focus: { boxShadow: 'none' },
      _hover: {
        color: '#fff',
        bg: 'rgba(107, 121, 130, 0.7)',
      },
      justifyContent: 'flex-start',
    }),
    rework: () => ({
      size: 'lg',
      minH: '45px',
      minW: '200px',
      bg: '#E8E5E2',
      fontSize: 'md',
      variant: 'solid',
      color: '#94702B',
      borderRadius: '2px',
      _focus: { boxShadow: 'none' },
      height: ['45px', '45px', '45px', '50px', '55px'],
      _hover: {
        bg: '#94702B',
        color: '#E8E5E2',
      },
    }),
    footerUpdate: () => ({
      minH: '35px',
      maxH: '45px',
      bg: '#CFCCE6',
      minW: '120px',
      height: '70%',
      color: '#150381',
      borderRadius: '0px',
      _hover: {
        bg: '#CFCCE6',
        borderBottom: '3px solid #150381',
      },
    }),
  },
};

export default Button;
