import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/membershipFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'rawCottonReq',
    type: 'text',
    label: 'Raw Cotton(bales)',
    placeholder: '0',
  },
  {
    colSpan: 1,
    name: 'psfReq',
    type: 'text',
    label: 'PSF(kg)',
    placeholder: '0',
  },
  {
    colSpan: 1,
    name: 'viscoseReq',
    type: 'text',
    label: 'Viscose(kg)',
    placeholder: '0',
  },
  {
    colSpan: 1,
    name: 'cotWasteReq',
    type: 'text',
    label: 'Cotton Waste(kg)',
    placeholder: '0',
  },
  {
    colSpan: 1,
    name: 'acrylicReq',
    type: 'text',
    label: 'Acrylic(kg)',
    placeholder: '0',
  },
  {
    colSpan: 1,
    name: 'chipsReq',
    type: 'text',
    label: 'Chips',
    placeholder: '0',
  },
  {
    colSpan: 1,
    name: 'petChipsReq',
    type: 'text',
    label: 'Pet-chips(kg)',
    placeholder: '0',
  },
];

export const yarnRawMatReqFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label).nullable(),
  [fields[1].name]: yup.string().label(fields[1].label).nullable(),
  [fields[2].name]: yup.string().label(fields[2].label).nullable(),
  [fields[3].name]: yup.string().label(fields[3].label).nullable(),
  [fields[4].name]: yup.string().label(fields[4].label).nullable(),
  [fields[5].name]: yup.string().label(fields[5].label).nullable(),
  [fields[6].name]: yup.string().label(fields[6].label).nullable(),
};

const YarnRawMatReq = (props) => {
  return <FormSection sectionName="Requirement" sectionColumn={1} formFields={fields} {...props} />;
};

export default YarnRawMatReq;
