import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'authorizedCapitalInTK',
    type: 'text',
    label: 'Authorized Capital (In Tk)',
  },
  {
    colSpan: 1,
    name: 'paidupCapitalInTK',
    type: 'text',
    label: 'Paid-up Capital (In Tk)',
  },
  {
    colSpan: 1,
    name: 'authorizedCapitalInUS',
    type: 'text',
    label: 'Authorized Capital (In US$)',
  },
  {
    colSpan: 1,
    name: 'paidupCapitalInUS',
    type: 'text',
    label: 'Paid-up Capital (In US$)',
  },
];

export const capitalStructureFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label),
  [fields[1].name]: yup.string().label(fields[1].label),
  [fields[2].name]: yup.string().label(fields[2].label),
  [fields[3].name]: yup.string().label(fields[3].label),
};

const CapitalStructureSection = (props) => {
  return (
    <FormSection sectionName="Capital Structure" sectionColumn={4} formFields={fields} {...props} />
  );
};

export default CapitalStructureSection;
