import React from 'react';
import * as yup from 'yup';
import FieldArrayFormSection from '../../../../common/hookFormComponents/FieldArrayFormSection';

const fields = [
  {
    colSpan: 1,
    name: 'materialName',
    type: 'text',
    label: 'Material Name',
  },
  {
    colSpan: 1,
    name: 'materialQuantity',
    type: 'text',
    label: 'Requirement (In Tons)',
  },
];

export const defaultKnitOtherRawMatFieldValues = fields.reduce(
  (obj, item) => [{ ...obj, [item['name']]: '' }],
  [{}],
);

export const KnitOtherRawMatFieldsValidation = {
  knitOtherRawMaterials: yup.array().of(
    yup.object().shape({
      [fields[0].name]: yup.string().label(fields[0].label),
      [fields[1].name]: yup.string().label(fields[1].label),
    }),
  ),
};

const KnitOtherRawMatSection = (props) => {
  return (
    <FieldArrayFormSection
      sectionColumn={3}
      sectionName="Other Raw Materials"
      name="knitOtherRawMaterials"
      formFields={fields}
      {...props}
    />
  );
};

export default KnitOtherRawMatSection;
