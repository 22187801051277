import React from 'react';
import * as yup from 'yup';
import FieldArrayFormSection from '../../../../common/hookFormComponents/FieldArrayFormSection';

const fields = [
  {
    colSpan: 1,
    name: 'noOfGenerators',
    type: 'text',
    label: 'No. of Generators',
  },
  {
    colSpan: 1,
    name: 'manufacturer',
    type: 'text',
    label: 'Manufacturer',
  },
  {
    colSpan: 1,
    name: 'capacity',
    type: 'text',
    label: 'Capacity (KW/Hour)',
  },
];

export const defaultGasFieldValues = fields.reduce(
  (obj, item) => [{ ...obj, [item['name']]: '' }],
  [{}],
);

export const gasFieldsValidation = {
  gas: yup.array().of(
    yup.object().shape({
      [fields[0].name]: yup.string().label(fields[0].label),
      [fields[1].name]: yup.string().label(fields[1].label),
      [fields[2].name]: yup.string().label(fields[2].label),
    }),
  ),
};

const GasSection = (props) => {
  return (
    <FieldArrayFormSection
      sectionName="Gas"
      sectionColumn={3}
      name="gas"
      formFields={fields}
      {...props}
    />
  );
};

export default GasSection;
