import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import PrintReceiptHeader from '../../../../snippets/PrintReceiptHeader';

const styles = {
  tableStyles: { border: '1px solid black', width: '100%' },
  cellStyles: {
    border: '1px solid black',
    fontSize: '14px',
    padding: '5px 5px',
    textAlign: 'center',
  },
};

const SubscriptionCostReport = React.forwardRef(({ data }, ref) => {
  const prepareTotals = () => {
    const {
      generalYarn,
      generalFabric,
      generalTextile,
      associateYarn,
      associateFabric,
      associateTextile,
    } = data;

    let generalYarnTotal = 0;
    generalYarn?.forEach((item) => {
      generalYarnTotal += parseInt(item.subCost);
    });

    let generalFabricTotal = 0;
    generalFabric?.forEach((item) => {
      generalFabricTotal += parseInt(item.subCost);
    });

    let generalTextileTotal = 0;
    generalTextile?.forEach((item) => {
      generalTextileTotal += parseInt(item.subCost);
    });

    let associateYarnTotal = 0;
    associateYarn?.forEach((item) => {
      associateYarnTotal += parseInt(item.subCost);
    });

    let associateFabricTotal = 0;
    associateFabric?.forEach((item) => {
      associateFabricTotal += parseInt(item.subCost);
    });

    let associateTextileTotal = 0;
    associateTextile?.forEach((item) => {
      associateTextileTotal += parseInt(item.subCost);
    });

    let generalTotal = generalYarnTotal + generalFabricTotal + generalTextileTotal;
    let associateTotal = associateYarnTotal + associateFabricTotal + associateTextileTotal;
    let yarnTotal = generalYarnTotal + associateYarnTotal;
    let fabricTotal = generalFabricTotal + associateFabricTotal;
    let textileTotal = generalTextileTotal + associateTextileTotal;
    let grandTotal = generalTotal + associateTotal;

    return {
      generalTotal,
      associateTotal,
      yarnTotal,
      fabricTotal,
      textileTotal,
      grandTotal,
      generalYarnTotal,
      generalFabricTotal,
      generalTextileTotal,
      associateYarnTotal,
      associateFabricTotal,
      associateTextileTotal,
    };
  };

  const totals = prepareTotals();

  return (
    <Box p={2} ref={ref} data={data}>
      <Box>
        <PrintReceiptHeader />
      </Box>
      <Text w="100%" textAlign="center" fontSize="2xl" fontWeight="bold" pb={4}>
        Subscription costs of Member Mills
      </Text>

      {data?.associateYarn?.length > 0 ||
      data?.associateFabric?.length > 0 ||
      data?.associateTextile?.length > 0 ? (
        <Text fontWeight="bold">ASSOCIATE MEMBERS</Text>
      ) : null}

      {/* ASSOCIATE YARN */}
      {data?.associateYarn?.length > 0 ? (
        <>
          <Box>
            <Text>Category: A-Yarn Manufacture</Text>
            <Box my={2}>
              <table style={styles.tableStyles}>
                <thead>
                  <tr>
                    <th style={{ ...styles.cellStyles, width: '7%' }}>sl no.</th>
                    <th style={{ ...styles.cellStyles, width: '70%' }}>Mill Name</th>
                    <th style={{ ...styles.cellStyles, width: '30%' }}>Suscription Cost (৳)</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.associateYarn?.map((rowItem, index) => (
                    <tr key={index}>
                      <td style={styles.cellStyles}>{index + 1}</td>
                      <td style={{ ...styles.cellStyles, textAlign: 'left' }}>{rowItem?.mill}</td>
                      <td style={styles.cellStyles}>{rowItem?.subCost}</td>
                    </tr>
                  ))}
                  <tr>
                    <td style={{ ...styles.cellStyles, fontWeight: '800' }}>#</td>
                    <td
                      style={{
                        ...styles.cellStyles,
                        borderRight: 'none',
                        fontWeight: '800',
                        textAlign: 'left',
                      }}>
                      Total(Associate, A-Yarn Manufacturer)
                    </td>
                    <td style={{ ...styles.cellStyles, fontWeight: '800' }}>
                      Tk. {totals?.associateYarnTotal}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Box>
          <div className="page-break" />
        </>
      ) : null}

      {/* ASSOCIATE FABRIC */}
      {data?.associateFabric?.length > 0 ? (
        <>
          <Box>
            <Text>Category: B-Fabric Manufacture</Text>
            <Box my={2}>
              <table style={styles.tableStyles}>
                <thead>
                  <tr>
                    <th style={{ ...styles.cellStyles, width: '7%' }}>sl no.</th>
                    <th style={{ ...styles.cellStyles, width: '70%' }}>Mill Name</th>
                    <th style={{ ...styles.cellStyles, width: '30%' }}>Suscription Cost (৳)</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.associateFabric?.map((rowItem, index) => (
                    <tr key={index}>
                      <td style={styles.cellStyles}>{index + 1}</td>
                      <td style={{ ...styles.cellStyles, textAlign: 'left' }}>{rowItem?.mill}</td>
                      <td style={styles.cellStyles}>{rowItem?.subCost}</td>
                    </tr>
                  ))}
                  <tr>
                    <td style={{ ...styles.cellStyles, fontWeight: '800' }}>#</td>
                    <td
                      style={{
                        ...styles.cellStyles,
                        borderRight: 'none',
                        fontWeight: '800',
                        textAlign: 'left',
                      }}>
                      Total(Associate, B-Fabric Manufacturer)
                    </td>
                    <td style={{ ...styles.cellStyles, fontWeight: '800' }}>
                      Tk. {totals?.associateFabricTotal}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Box>
          <div className="page-break" />
        </>
      ) : null}

      {/* ASSOCIATE TEXTILE */}
      {data?.associateTextile?.length > 0 ? (
        <Box>
          <Text>Category: C-Textile Product Processor</Text>
          <Box my={2}>
            <table style={styles.tableStyles}>
              <thead>
                <tr>
                  <th style={{ ...styles.cellStyles, width: '7%' }}>sl no.</th>
                  <th style={{ ...styles.cellStyles, width: '70%' }}>Mill Name</th>
                  <th style={{ ...styles.cellStyles, width: '30%' }}>Suscription Cost (৳)</th>
                </tr>
              </thead>
              <tbody>
                {data?.associateTextile?.map((rowItem, index) => (
                  <tr key={index}>
                    <td style={styles.cellStyles}>{index + 1}</td>
                    <td style={{ ...styles.cellStyles, textAlign: 'left' }}>{rowItem?.mill}</td>
                    <td style={styles.cellStyles}>{rowItem?.subCost}</td>
                  </tr>
                ))}
                <tr>
                  <td style={{ ...styles.cellStyles, fontWeight: '800' }}>#</td>
                  <td
                    style={{
                      ...styles.cellStyles,
                      borderRight: 'none',
                      fontWeight: '800',
                      textAlign: 'left',
                    }}>
                    Total(Associate, C-Textile Product Processor)
                  </td>
                  <td style={{ ...styles.cellStyles, fontWeight: '800' }}>
                    Tk. {totals?.associateTextileTotal}
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        </Box>
      ) : null}

      {totals?.associateTotal > 0 ? (
        <>
          <Text fontSize="md" fontWeight="bold">
            # Total Cost (for ASSOCIATE MEMBERS) = Tk. {totals?.associateTotal}
          </Text>{' '}
          <div className="page-break" />
        </>
      ) : null}

      {data?.generalYarn?.length > 0 ||
      data?.generalFabric?.length > 0 ||
      data?.generalTextile?.length > 0 ? (
        <Text
          fontWeight="bold"
          mt={
            data?.associateYarn?.length > 0 ||
            data?.associateFabric?.length > 0 ||
            data?.associateTextile?.length > 0
              ? 20
              : 0
          }>
          GENERAL MEMBERS
        </Text>
      ) : null}

      {/* GENERAL YARN */}
      {data?.generalYarn?.length > 0 ? (
        <>
          <Box>
            <Text>Category: A-Yarn Manufacture</Text>
            <Box my={2}>
              <table style={styles.tableStyles}>
                <thead>
                  <tr>
                    <th style={{ ...styles.cellStyles, width: '7%' }}>sl no.</th>
                    <th style={{ ...styles.cellStyles, width: '70%' }}>Mill Name</th>
                    <th style={{ ...styles.cellStyles, width: '30%' }}>Suscription Cost (৳)</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.generalYarn?.map((rowItem, index) => (
                    <tr key={index}>
                      <td style={styles.cellStyles}>{index + 1}</td>
                      <td style={{ ...styles.cellStyles, textAlign: 'left' }}>{rowItem?.mill}</td>
                      <td style={styles.cellStyles}>{rowItem?.subCost}</td>
                    </tr>
                  ))}
                  <tr>
                    <td style={{ ...styles.cellStyles, fontWeight: '800' }}>#</td>
                    <td
                      style={{
                        ...styles.cellStyles,
                        borderRight: 'none',
                        fontWeight: '800',
                        textAlign: 'left',
                      }}>
                      Total(General, A-Yarn Manufacturer)
                    </td>
                    <td style={{ ...styles.cellStyles, fontWeight: '800' }}>
                      Tk. {totals?.generalYarnTotal}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Box>
          <div className="page-break" />
        </>
      ) : null}

      {/* GENERAL FABRIC */}
      {data?.generalFabric?.length > 0 ? (
        <>
          <Box>
            <Text>Category: B-Fabric Manufacture</Text>
            <Box my={2}>
              <table style={styles.tableStyles}>
                <thead>
                  <tr>
                    <th style={{ ...styles.cellStyles, width: '7%' }}>sl no.</th>
                    <th style={{ ...styles.cellStyles, width: '70%' }}>Mill Name</th>
                    <th style={{ ...styles.cellStyles, width: '30%' }}>Suscription Cost (৳)</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.generalFabric?.map((rowItem, index) => (
                    <tr key={index}>
                      <td style={styles.cellStyles}>{index + 1}</td>
                      <td style={{ ...styles.cellStyles, textAlign: 'left' }}>{rowItem?.mill}</td>
                      <td style={styles.cellStyles}>{rowItem?.subCost}</td>
                    </tr>
                  ))}
                  <tr>
                    <td style={{ ...styles.cellStyles, fontWeight: '800' }}>#</td>
                    <td
                      style={{
                        ...styles.cellStyles,
                        borderRight: 'none',
                        fontWeight: '800',
                        textAlign: 'left',
                      }}>
                      Total(General, B-Fabric Manufacturer)
                    </td>
                    <td style={{ ...styles.cellStyles, fontWeight: '800' }}>
                      Tk. {totals?.generalFabricTotal}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Box>
          <div className="page-break" />
        </>
      ) : null}

      {/* GENERAL TEXTILE */}
      {data?.generalTextile?.length > 0 ? (
        <Box>
          <Text>Category: C-Textile Product Processor</Text>
          <Box my={2}>
            <table style={styles.tableStyles}>
              <thead>
                <tr>
                  <th style={{ ...styles.cellStyles, width: '7%' }}>sl no.</th>
                  <th style={{ ...styles.cellStyles, width: '70%' }}>Mill Name</th>
                  <th style={{ ...styles.cellStyles, width: '30%' }}>Suscription Cost (৳)</th>
                </tr>
              </thead>
              <tbody>
                {data?.generalTextile?.map((rowItem, index) => (
                  <tr key={index}>
                    <td style={styles.cellStyles}>{index + 1}</td>
                    <td style={{ ...styles.cellStyles, textAlign: 'left' }}>{rowItem?.mill}</td>
                    <td style={styles.cellStyles}>{rowItem?.subCost}</td>
                  </tr>
                ))}
                <tr>
                  <td style={{ ...styles.cellStyles, fontWeight: '800' }}>#</td>
                  <td
                    style={{
                      ...styles.cellStyles,
                      borderRight: 'none',
                      fontWeight: '800',
                      textAlign: 'left',
                    }}>
                    Total(General, C-Textile Product Processor)
                  </td>
                  <td style={{ ...styles.cellStyles, fontWeight: '800' }}>
                    Tk. {totals?.generalTextileTotal}
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        </Box>
      ) : null}

      {totals?.generalTotal > 0 ? (
        <>
          <Text fontSize="md" fontWeight="bold">
            # Total Costs (for GENERAL MEMBERS) = Tk. {totals?.generalTotal}
          </Text>
          <div className="page-break" />
        </>
      ) : null}

      <Text mt={20} fontSize="lg" fontWeight="bold">
        SUMMARY:
      </Text>
      <Text fontSize="md" fontWeight="bold" color="textSecondary">
        # Total Costs (Category: A-Yarn Manufacture) = Tk. {totals?.yarnTotal}
      </Text>
      <Text fontSize="md" fontWeight="bold" color="textSecondary">
        # Total Costs (Category: B-Fabric Manufacture) = Tk. {totals?.fabricTotal}
      </Text>
      <Text fontSize="md" fontWeight="bold" color="textSecondary">
        # Total Costs (Category: C-Textile Product Processor) = Tk. {totals?.textileTotal}
      </Text>
      <Text fontSize="md" fontWeight="bold" color="textSecondary">
        # Total Costs (for ASSOCIATE MEMBERS) = Tk. {totals?.associateTotal}
      </Text>
      <Text fontSize="md" fontWeight="bold" color="textSecondary">
        # Total Costs (for GENERAL MEMBERS) = Tk. {totals?.generalTotal}
      </Text>
      <Text fontSize="md" fontWeight="bold">
        # Grand Total (ASSOCIATE + GENERAL) = Tk. {totals?.grandTotal}
      </Text>
    </Box>
  );
});

SubscriptionCostReport.displayName = 'SubscriptionCostReport';

export default SubscriptionCostReport;
