import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import React from 'react';
import { isArrayAndHasContent } from '../../../../util/formatUtils';

const AdditionalInformationBox = ({ additionalInfos }) => {
  return (
    <>
      {isArrayAndHasContent(additionalInfos) ? (
        <Box minH="100%" border="1px solid #e1e1e1" p="1em">
          <Grid templateColumns="repeat(2, 1fr)" gap={2}>
            {additionalInfos?.map((info, index) => (
              <GridItem key={index}>
                <Flex style={{ width: '100%', gap: '10px' }}>
                  <Text fontSize="xs" fontWeight={600}>
                    {info?.label}
                  </Text>
                  <Text fontSize="xs" fontWeight={600}>
                    {info?.value}
                  </Text>
                </Flex>
              </GridItem>
            ))}
          </Grid>
        </Box>
      ) : (
        <Box
          minH="100%"
          border="1px solid #e1e1e1"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Text fontWeight={600}>No Additional Information Found</Text>
        </Box>
      )}
    </>
  );
};

export default AdditionalInformationBox;
