import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    type: 'text',
    name: 'discount',
    label: 'Cash Discount',
    isRequired: true,
  },
  {
    colSpan: 1,
    type: 'text',
    name: 'paidAmount',
    label: 'Paid Amount',
    isRequired: true,
  },
  {
    colSpan: 1,
    type: 'text',
    name: 'remarks',
    label: 'Remarks',
    isRequired: true,
  },
  {
    colSpan: 1,
    type: 'text',
    name: 'dutyCertNum',
    label: 'Certificate No',
    isRequired: true,
  },
  {
    colSpan: 2,
    type: 'text',
    name: 'narration',
    label: 'Narration',
    isRequired: false,
  },
];

export const certNumberFieldsValidation = {
  [fields[0].name]: yup
    .number()
    .typeError(`${fields[0].label} must be a number`)
    .required()
    .label(fields[0].label),
  [fields[1].name]: yup
    .number()
    .typeError(`${fields[1].label} must be a number`)
    .required()
    .label(fields[1].label),
  [fields[2].name]: yup.string().required().label(fields[2].label),
  [fields[3].name]: yup
    .number()
    .typeError(`${fields[2].label} must be a number`)
    .required()
    .label(fields[2].label),
};

const CertNumber = (props) => {
  return (
    <FormSection
      secondary
      sectionColumn={2}
      formFields={fields.map((field) => ({
        ...field,
        isDisabled: props?.disabledFields?.includes(field.name) ? true : false,
      }))}
      {...props}
    />
  );
};

export default CertNumber;
