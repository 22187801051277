import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ROOT_URL } from '../constants/routerUrl';
import { getCurrentUserData } from '../util/localStorageUtils';
import Layout from '../components/snippets/DashboardComponent/Layout';
import { ACCOUNTS_MEM_COMP, ACCOUNTS_CERT_COMP, SETTINGS_COMP } from '../constants/navConstants';
import { getUserDashboard } from '../api/auth';
import { useDispatch } from 'react-redux';
import { logIn } from '../store/actions/authActions';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const dispatch = useDispatch();
  const { user, accessToken } = getCurrentUserData();
  const isLoggedIn = user && accessToken;

  useEffect(() => {
    (async () => {
      if (isLoggedIn && Component?.name !== 'Dashboard') {
        const res = await getUserDashboard({ userOnly: true });
        if (res.data?.status === 200) {
          const { fullName, uid, role, adminRole, phoneNo, email } = res.data?.userInfo;
          let loginTime = new Date();
          const user = {
            fullName,
            userId: uid,
            role,
            adminRole,
            phoneNo,
            email,
            lastLogin: loginTime,
          };
          dispatch(logIn(user));
        }
      }
    })();
  }, [isLoggedIn]);

  useEffect(() => {
    if (!ACCOUNTS_MEM_COMP.includes(Component?.name)) {
      localStorage.removeItem('ACC_MEM_TAB');
    }
    if (!ACCOUNTS_CERT_COMP.includes(Component?.name)) {
      localStorage.removeItem('ACC_CERT_TAB');
    }
    if (!SETTINGS_COMP.includes(Component?.name)) {
      localStorage.removeItem('SETTINGS_TAB');
    }
  }, [Component]);

  if (isLoggedIn) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        }}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect
            to={{
              pathname: ROOT_URL,
              state: {
                redirectUrl: path,
              },
            }}
          />
        )}
      />
    );
  }
};

export default PrivateRoute;
