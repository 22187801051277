import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import Statistics from './Statistics';
import { useDispatch, useSelector } from 'react-redux';
import { setStats } from '../../store/actions/authActions';
import { getUserDashboard } from '../../api/auth';

const Dashboard = () => {
  const dispatch = useDispatch();

  const stats = useSelector((state) => state.auth.stats);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    document.title = 'BTMA - Dashboard';
  }, []);

  useEffect(() => {
    (async () => {
      const res = await getUserDashboard();
      if (res.data?.status === 200) {
        // eslint-disable-next-line no-unused-vars
        const { status, userInfo, ...rest } = res.data;
        dispatch(setStats(rest));
      }
    })();
  }, []);

  return (
    <Box p={6}>
      <Statistics data={{ ...stats, user }} />
    </Box>
  );
};

export default Dashboard;
