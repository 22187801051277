import React, { useEffect, useState } from 'react';
import {
  Box,
  Icon,
  Text,
  Input,
  Badge,
  HStack,
  VStack,
  Spinner,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { useHistory } from 'react-router';
import { MdSearch } from 'react-icons/md';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { searchMill } from '../../../../../api/membership';
import { FETCH_MEMBERS } from '../../../../../store/actions/actionTypes';
import { ACCOUNTS_MEMBERSHIP_MILL_URL } from '../../../../../constants/routerUrl';
import { BADGE_STATE_COLORS } from '../../../../../constants/dataTableConstants';
import debounce from '../../../../../util/debounce';

const SearchSection = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [millSearchKey, setMillSearchKey] = useState();
  const members = useSelector((state) => state.membership.members);

  useEffect(() => {
    if (millSearchKey?.length) {
      (async () => {
        setSpinner(true);
        debounce(async () => {
          const res = await searchMill({ millSearchKey, global: true });
          if (res.data?.status === 200) {
            setSpinner(false);
            dispatch({ type: FETCH_MEMBERS, payload: res.data.members });
          } else {
            setSpinner(false);
            dispatch({ type: FETCH_MEMBERS, payload: null });
          }
        })();
      })();
    } else {
      dispatch({ type: FETCH_MEMBERS, payload: null });
    }
  }, [millSearchKey]);

  const getColorScheme = (status) => {
    return BADGE_STATE_COLORS.filter((item) => item.group === status)?.[0]?.color;
  };

  const handleRowClick = (uid) => {
    history.push(`${ACCOUNTS_MEMBERSHIP_MILL_URL}/${uid}`);
  };

  const handleKeyDown = (e) => {
    let limit = members?.length < 10 ? members?.length : 10;
    if (e.key == 'ArrowDown') {
      selected < limit ? setSelected(selected + 1) : setSelected(0);
    } else if (e.key == 'ArrowUp') {
      selected > 0 ? setSelected(selected - 1) : setSelected(limit);
    } else if (e.key == 'Enter') {
      selected > 0 && selected <= limit
        ? history.push(`${ACCOUNTS_MEMBERSHIP_MILL_URL}/${members?.[selected - 1]?.uid}`)
        : null;
    }
  };

  return (
    <VStack mt={5}>
      <Box w="55%" position="relative" zIndex={97}>
        <Text
          mb={2}
          w="100%"
          fontSize="lg"
          letterSpacing={2}
          textAlign="center"
          color="textSecondary">
          SEARCH MILL
        </Text>
        <InputGroup>
          <InputLeftElement>
            <Icon
              h={8}
              w={8}
              pt={2.5}
              as={MdSearch}
              opacity="0.6"
              alignSelf="center"
              color="textSeconday"
            />
          </InputLeftElement>
          <InputRightElement pt={2.5}>
            {spinner ? (
              <Spinner thickness="2px" emptyColor="primary.50" color="primary.700" size="sm" />
            ) : null}
          </InputRightElement>
          <Input
            h="50px"
            bg="white"
            fontSize="lg"
            variant="outline"
            borderRadius="4px"
            onKeyDown={handleKeyDown}
            focusBorderColor="primary.300"
            onChange={(e) => setMillSearchKey(e.target.value)}
          />
        </InputGroup>

        {members?.length > 0 ? (
          <Box
            p={4}
            w="99%"
            left="50%"
            top="100%"
            bg="white"
            maxH="45vh"
            overflow="auto"
            position="absolute"
            transform="translateX(-50%)"
            boxShadow="0px 5px 5px rgba(0,0,0,0.3)">
            {members.map((item, index) =>
              index < 10 ? (
                <HStack
                  onMouseEnter={() => setSelected(0)}
                  key={index}
                  p={2}
                  bg={selected == index + 1 ? 'primary.50' : 'unset'}
                  _hover={{ bg: 'primary.50', cursor: 'pointer' }}
                  onClick={() => handleRowClick(item.uid)}>
                  <Text fontSize="md" fontWeight="bold" textTransform="uppercase">
                    {item.millName}
                  </Text>

                  <Text fontSize="xs">
                    ({item.membershipNo || 'N/A'} - {item.category})
                  </Text>

                  <Badge colorScheme={getColorScheme(item.membershipStatus)}>
                    {item.membershipStatus}
                  </Badge>
                </HStack>
              ) : null,
            )}
          </Box>
        ) : members ? (
          <Box
            p={4}
            w="99%"
            left="50%"
            top="100%"
            bg="white"
            maxH="45vh"
            overflow="auto"
            position="absolute"
            transform="translateX(-50%)"
            boxShadow="0px 5px 5px rgba(0,0,0,0.3)">
            <Text width="100%" textAlign="center" color="textSecondary" fontSize="lg">
              <Icon as={AiOutlineFileSearch} w={5} h={5} /> No entries found!
            </Text>
          </Box>
        ) : null}
      </Box>
    </VStack>
  );
};

export default SearchSection;
