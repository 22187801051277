import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'otherPaymentReason',
    type: 'text',
    label: 'Describe Payment Reason',
    isRequired: true,
  },
];

export const otherPaymentFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
};

const OtherPaymentSection = (props) => {
  return <FormSection secondary sectionColumn={1} formFields={fields} {...props} />;
};

export default OtherPaymentSection;
