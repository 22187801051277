import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'rawCottonReq',
    type: 'text',
    label: 'Raw Cotton(bales)',
  },
  {
    colSpan: 1,
    name: 'psfReq',
    type: 'text',
    label: 'PSF(kg)',
  },
  {
    colSpan: 1,
    name: 'viscoseReq',
    type: 'text',
    label: 'Viscose(kg)',
  },
  {
    colSpan: 1,
    name: 'cotWasteReq',
    type: 'text',
    label: 'Cotton Waste(kg)',
  },
  {
    colSpan: 1,
    name: 'acrylicReq',
    type: 'text',
    label: 'Acrylic(kg)',
  },
  {
    colSpan: 1,
    name: 'chipsReq',
    type: 'text',
    label: 'Chips',
  },
  {
    colSpan: 1,
    name: 'petChipsReq',
    type: 'text',
    label: 'Pet-chips(kg)',
  },
];

export const rawMatReqAFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label),
  [fields[1].name]: yup.string().label(fields[1].label),
  [fields[2].name]: yup.string().label(fields[2].label),
  [fields[3].name]: yup.string().label(fields[3].label),
  [fields[4].name]: yup.string().label(fields[4].label),
  [fields[5].name]: yup.string().label(fields[5].label),
  [fields[6].name]: yup.string().label(fields[6].label),
};

const RawMatReqA = (props) => {
  return (
    <FormSection
      sectionName="Raw Material Requirement"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default RawMatReqA;
