import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Text, Stack, HStack, Button, PinInput, PinInputField } from '@chakra-ui/react';

import AuthWrapper from './AuthWrapper';

import { useDispatch } from 'react-redux';
import { verifyLogin } from '../../api/auth';
import { logIn } from '../../store/actions/authActions';
import { notify } from '../../store/actions/globalActions';
import { DASHBOARD_URL, LOG_IN_URL } from '../../constants/routerUrl';
import { OTP_VALIDITY_TIME_IN_SECONDS } from '../../constants/authConstant';
import { getCurrentUserData, setCurrentUserData } from '../../util/localStorageUtils';

const AccountVerficationOTP = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const { user, accessToken } = getCurrentUserData();
    if (user && accessToken) {
      dispatch(logIn(user));
      history.replace(DASHBOARD_URL);
    }
  }, []);

  const handleComplete = async (data) => {
    const { username, phoneNo, sessionId } = location.state;
    const loginData = { otpToken: data, username, phoneNo, sessionId };
    try {
      const res = await verifyLogin(loginData);
      if (res.status === 200) {
        dispatch(
          notify({
            title: 'Success',
            description: 'Login Successful',
            status: 'success',
            duration: 1500,
          }),
        );
        let loginTime = new Date();
        const { accessToken, fullName, userId, role, adminRole, phoneNo, email } = res.data;
        const user = { fullName, userId, role, adminRole, phoneNo, email, lastLogin: loginTime };
        // setting the accessToken and user to localStorage
        setCurrentUserData(user, accessToken);
        dispatch(logIn(user));
        history.replace(DASHBOARD_URL);
      }
    } catch (error) {
      dispatch(
        notify({
          title: 'Error',
          description: 'Invalid OTP!',
          status: 'error',
          duration: 2000,
        }),
      );
      history.replace(LOG_IN_URL);
    }
  };

  // timer stuff start
  const [[minutes, seconds], setTime] = useState([
    parseInt(OTP_VALIDITY_TIME_IN_SECONDS / 60),
    OTP_VALIDITY_TIME_IN_SECONDS % 60,
  ]);
  const [otpValidity, setOtpValidity] = useState(true);

  const tickTimer = () => {
    if (minutes === 0 && seconds === 0) {
      setOtpValidity(false);
    } else if (seconds === 0) {
      setTime([minutes - 1, 59]);
    } else {
      setTime([minutes, seconds - 1]);
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => tickTimer(), 1000);
    return () => clearInterval(timerId);
  });
  // timer stuff end

  const handleRetryLogin = () => {
    history.replace(LOG_IN_URL);
  };

  return (
    <AuthWrapper>
      <Stack spacing={4} p={10} backgroundColor="white" boxShadow="md" borderRadius="md">
        {otpValidity && (
          <Box>
            <Text fontSize="xl" color="textSecondary" textAlign="center">
              Enter the OTP sent to {location?.state?.phoneNo}
            </Text>
            <HStack mt={10} justify="center">
              <PinInput
                otp
                autoFocus
                onComplete={handleComplete}
                focusBorderColor="primary.300"
                type="alphanumeric"
                size="lg">
                <PinInputField bg="secondary" />
                <PinInputField bg="secondary" />
                <PinInputField bg="secondary" />
                <PinInputField bg="secondary" />
              </PinInput>
            </HStack>
          </Box>
        )}
        <HStack mt={18} mb={6} justify="center">
          {otpValidity ? (
            <Stack mt={1}>
              <Text fontSize="2xl">
                {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
              </Text>
            </Stack>
          ) : (
            <Stack spacing={4} px={16} py={6}>
              <Text fontSize="xl" color="invalid" textAlign="center">
                OTP Expired!
              </Text>
              <Button borderRadius="2px" onClick={handleRetryLogin}>
                Retry Login
              </Button>
            </Stack>
          )}
        </HStack>
      </Stack>
    </AuthWrapper>
  );
};

export default AccountVerficationOTP;
