import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, HStack, Box, Text, Grid, GridItem, useDisclosure } from '@chakra-ui/react';

//common components
import RenderModal from '../../RenderModal';
import Loader from '../../../snippets/Loader';
import InfoTiles from '../../../common/InfoTiles';
import FormButton from '../../../common/FormButton';
import DataTable from '../../../common/MinimalTable';
import InfoSection from '../../../common/InfoSection';
import DocumentGallery from '../../../common/membershipFormComponents/GallerySection';
import MembershipFormWrapper from '../../../common/membershipFormComponents/MembershipFormWrapper';

//form sections
import YarnCountSection, { yarnsCountsFieldsValidation } from './YarnCountSection';
import YarnOtherInfoSection, { yarnOtherInfoFieldsValidation } from './YarnOtherInfoSection';
import IssueDateSection, { issueDateValidation } from '../../commonSections/IssueDateSection';
import AdditionalInfoSection, { additionalInfoFieldsValidation } from './AdditionalInfoSection';
import FabricOtherInfoSection, { fabricOtherInfoFieldsValidation } from './FabricOtherInfoSection';

//actions & api
import {
  getAllApplications,
  getApplicationInfo,
  patchApplicationInfo,
  updateApplicationStatus,
} from '../../../../api/certificates';
import {
  FETCH_GSP_APPLICATIONS,
  FETCH_APPLICATION_INFO,
} from '../../../../store/actions/actionTypes';
import { notify } from '../../../../store/actions/globalActions';

//utils & constants
import _ from 'lodash';
import { GSP_CERT_URL } from '../../../../constants/routerUrl';
import { useYupValidationResolver } from '../../../../util/yupUtils';
import { formatDate, renderDate } from '../../../../util/formatUtils';
import { BADGE_STATE_COLORS } from '../../../../constants/dataTableConstants';

const GspVer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [modal, setModal] = useState();
  const [formData, setFormData] = useState();
  const [loader, setLoader] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const data = useSelector((state) => state.certificate.currentData);
  const gsp = data?.gspYarnExport || data?.gspFabricExport;
  const { applicationId } = history.location.state;

  useEffect(() => {
    (async () => {
      const res = await getApplicationInfo({ type: 'production', id: applicationId });
      if (res.data?.status === 200) {
        dispatch({ type: FETCH_APPLICATION_INFO, payload: res.data.certificate });
      }
    })();
  }, []);

  const millInfo = [
    { header: 'Mill Name', value: data?.millInfo?.millName },
    { header: 'Membership No', value: data?.millInfo?.membershipNo },
    { header: 'Membership Type', value: data?.millInfo?.membershipType },
    { header: 'Contact', value: data?.millInfo?.phoneNo },
  ];

  const gspDocumentsInfo = [
    {
      header: 'Vat Challan',
      link: gsp?.vatChallan,
    },
    {
      header: 'L/C Certificate',
      link: gsp?.lcCertificate,
    },
    {
      header: 'Purchase Order',
      link: gsp?.purchaseOrder,
    },
    {
      header: 'Delivery Challan',
      link: gsp?.deliveryChallan,
    },
    {
      header: 'Proforma Invoice',
      link: gsp?.proformaInvoice,
    },
    {
      header: 'Commercial Invoice',
      link: gsp?.commercialInvoice,
    },
    {
      header: 'L/C Bank Documents',
      link: gsp?.lcBankDocuments,
    },
  ];

  const generalInfoFields = [
    { header: 'Master L/C', value: gsp?.masterLc },
    { header: 'Total Quantity', value: gsp?.totalQuantity },
    { header: 'Date of Contract', value: renderDate(gsp?.dateOfLc) },
    { header: 'Vat Challan No', value: gsp?.exciseGatePassNum },
    { header: 'Vat Challan Date', value: renderDate(gsp?.exciseGatePassDate) },
  ];

  const docColInfoFields = [
    { header: 'BTB L/C', value: gsp?.btbLc },
    { header: 'Date of Collection', value: renderDate(gsp?.btbLcDate) },
    { header: 'Expiry Date', value: renderDate(gsp?.expiryDateOfLc) },
    { header: 'Value', value: `$${gsp?.valueInUsd}` },
    { header: 'L/C Opening Bank', value: `${gsp?.bank}` },
  ];

  const otherInfoFields = [
    { header: 'Name of Mill & Address', value: gsp?.nameOfMillAndAddress },
    { header: 'Annual Capacity', value: gsp?.annualProdCapacity },
    { header: 'Supplied To', value: gsp?.suppliedTo },
  ];

  const productTableColumns = [
    {
      header: 'Sl. No',
      increments: true,
    },
    { header: 'Description', accessor: 'product' },
    { header: 'Quantity', accessor: 'productQuantity' },
    { header: 'Com. Invoice No', accessor: 'invoice' },
    { header: 'Com. Invoice Date', accessor: 'invoiceDate', isDate: true },
    { header: 'Delivery Date', accessor: 'deliveryDate', isDate: true },
  ];

  const getValidationSchema = () => {
    if (data?.gspYarnExport) {
      return yup.object().shape({
        ...issueDateValidation,
        ...yarnsCountsFieldsValidation,
        ...yarnOtherInfoFieldsValidation,
        ...additionalInfoFieldsValidation,
      });
    } else if (data?.gspFabricExport) {
      return yup.object().shape({
        ...issueDateValidation,
        ...fabricOtherInfoFieldsValidation,
        ...additionalInfoFieldsValidation,
      });
    } else {
      return yup.object().shape({
        ...issueDateValidation,
        ...additionalInfoFieldsValidation,
      });
    }
  };

  const resolver = useYupValidationResolver(getValidationSchema());
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: { issueDate: new Date() },
  });

  const onSubmit = (formdata) => {
    let normalizedFormData = _.mapKeys(formdata, (value, key) => {
      if (value) {
        return key;
      }
    });
    setFormData({
      ...normalizedFormData,
      issueDate: formatDate(normalizedFormData?.issueDate),
      yarnCount: formdata?.yarnCount?.length > 0 ? JSON.stringify(formdata?.yarnCount) : null,
      additionalInfo:
        formdata?.additionalInfo?.length > 0 ? JSON.stringify(formdata?.additionalInfo) : null,
    });
    setModal('SUBMIT');
    onOpen();
  };

  const confirmSubmit = async () => {
    const res = await patchApplicationInfo({ type: 'production', id: data?.id, formData });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      const response = await getAllApplications({
        type: 'production',
        params: { page: 1, pageSize: 10, type: 'GSP' },
      });
      if (response.data?.status === 200) {
        dispatch({ type: FETCH_GSP_APPLICATIONS, payload: response.data });
      }
      history.push(GSP_CERT_URL);
    }
  };

  const handleRework = async (reworkData) => {
    setLoader(true);
    const res = await updateApplicationStatus({
      id: data?.id,
      formData: { status: 'REWORK', remarks: reworkData?.reason },
    });
    if (res.data?.status === 200) {
      const res = await getAllApplications({
        type: 'production',
        params: { page: 1, pageSize: 10, type: 'GSP' },
      });
      if (res.data?.status === 200) {
        dispatch({ type: FETCH_GSP_APPLICATIONS, payload: res.data });
      }
      history.push(GSP_CERT_URL);
    }
    setLoader(false);
  };

  const confirmReject = async () => {
    setLoader(true);
    const res = await updateApplicationStatus({
      id: data?.id,
      formData: { status: 'REJECTED', remarks: 'This application has been rejected!' },
    });
    if (res.data?.status === 200) {
      const res = await getAllApplications({
        type: 'production',
        params: { page: 1, pageSize: 10, type: 'GSP' },
      });
      if (res.data?.status === 200) {
        dispatch({ type: FETCH_GSP_APPLICATIONS, payload: res.data });
      }
      history.push(GSP_CERT_URL);
    }
    setLoader(false);
  };

  const getBadgeColorScheme = () => {
    return BADGE_STATE_COLORS.filter((item) => item.group === data?.applicationStatus)?.[0]?.color;
  };

  return data && !loader ? (
    <Box>
      <Box p={4}>
        <InfoTiles sectionName="Mill Information" sectionColumn={4} infoFields={millInfo} />
      </Box>

      <Box bg="white" m={4} p={4} borderRadius="4px" border="1px solid #3e1cfd">
        <HStack>
          <Text fontSize="20px" fontWeight="bold" color="textSecondary">
            Certificate No:
          </Text>
          <Text fontSize="16px">{data?.certificateNum || 'N/A'}</Text>
        </HStack>
        <HStack>
          <Text fontSize="20px" fontWeight="bold" color="textSecondary">
            Application Status:
          </Text>
          <Badge fontSize="16px" colorScheme={getBadgeColorScheme()}>
            {data?.applicationStatus}
          </Badge>
        </HStack>
        <HStack>
          <Text fontSize="20px" fontWeight="bold" color="textSecondary">
            Remarks:
          </Text>
          <Text fontSize="16px">{data?.remarks || 'N/A'}</Text>
        </HStack>
      </Box>

      <MembershipFormWrapper
        formName={
          data?.gspYarnExport ? 'Yarn Export Management (GSP)' : 'Fabric Export Management (GSP)'
        }>
        <Grid templateColumns="repeat(2, 1fr)" gap={3}>
          <GridItem bg="white" borderRadius="4px">
            <InfoSection sectionName="General Information" infoFields={generalInfoFields} />
          </GridItem>
          <GridItem bg="white" borderRadius="4px">
            <InfoSection
              sectionName="Documentary Collectors Information"
              infoFields={docColInfoFields}
            />
          </GridItem>
          <GridItem bg="white" borderRadius="4px">
            <InfoSection sectionName="Other Information" infoFields={otherInfoFields} />
          </GridItem>
          <GridItem bg="white" borderRadius="4px">
            <DocumentGallery galleryFields={gspDocumentsInfo} sectionName="Additional Documents" />
          </GridItem>
          <GridItem colSpan={2} bg="white" borderRadius="4px">
            <Text px={4} pt={4} fontSize="20px" fontWeight="bold">
              Product Info
            </Text>
            <Box p={4}>
              <DataTable scrollable columns={productTableColumns} data={gsp?.productDetails} />
            </Box>
          </GridItem>
          <GridItem colSpan={2} bg="white" borderRadius="4px">
            <Box p={1}>
              <IssueDateSection control={control} register={register} errors={errors} />
              {data?.gspYarnExport ? (
                <>
                  <YarnOtherInfoSection register={register} control={control} errors={errors} />
                  <AdditionalInfoSection register={register} control={control} errors={errors} />
                  <YarnCountSection register={register} control={control} errors={errors} />
                </>
              ) : (
                <>
                  <FabricOtherInfoSection register={register} control={control} errors={errors} />
                  <AdditionalInfoSection register={register} control={control} errors={errors} />
                </>
              )}
            </Box>
          </GridItem>
        </Grid>
        <HStack justifyContent="space-between" alignItems="center" mt={4}>
          <FormButton
            mt={0}
            bg="red.500"
            color="white"
            variant="rework"
            isLoading={isSubmitting}
            _hover={{ bg: 'red.400' }}
            isDisabled={
              data?.applicationStatus === 'REWORK' || data?.applicationStatus === 'REJECTED'
            }
            onClick={() => {
              setModal('REJECT');
              onOpen();
            }}>
            REJECT
          </FormButton>
          <HStack>
            <FormButton
              mt={0}
              variant="rework"
              isLoading={isSubmitting}
              isDisabled={data?.applicationStatus === 'REWORK'}
              onClick={() => {
                setModal('REWORK');
                onOpen();
              }}>
              REWORK
            </FormButton>
            <FormButton
              mt={0}
              isLoading={isSubmitting}
              onClick={(event) => {
                event.preventDefault();
                handleSubmit(onSubmit)();
              }}>
              SUBMIT
            </FormButton>
          </HStack>
        </HStack>
      </MembershipFormWrapper>
      <RenderModal
        modal={modal}
        isOpen={isOpen}
        onClose={onClose}
        handleRework={handleRework}
        confirmSubmit={confirmSubmit}
        confirmReject={confirmReject}
      />
    </Box>
  ) : (
    <Loader />
  );
};

export default GspVer;
