import React from 'react';
import * as yup from 'yup';
import { zipCodeValidation } from '../../../../../util/yupUtils';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 2,
    name: 'hoName',
    type: 'text',
    isRequired: true,
    label: 'Head Office',
  },
  {
    colSpan: 1,
    name: 'hoHouseNo',
    type: 'text',
    label: 'House No.',
  },
  {
    colSpan: 1,
    name: 'hoRoadNo',
    type: 'text',
    label: 'Road No.',
  },
  {
    colSpan: 1,
    name: 'hoPoliceStation',
    type: 'text',
    label: 'Police Station',
  },
  {
    colSpan: 1,
    name: 'hoPostOffice',
    type: 'text',
    label: 'Post Code',
  },
  {
    colSpan: 1,
    name: 'hoDistrict',
    type: 'text',
    label: 'District',
  },
];

export const addressFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup.string().label(fields[1].label),
  [fields[2].name]: yup.string().label(fields[2].label),
  [fields[3].name]: yup.string().label(fields[3].label),
  [fields[4].name]: zipCodeValidation.label(fields[4].label),
  [fields[5].name]: yup.string().label(fields[5].label),
};

const AddressSection = (props) => {
  return <FormSection sectionName="Head Office" sectionColumn={2} formFields={fields} {...props} />;
};

export default AddressSection;
