import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Box, HStack, Grid, GridItem, Text, Button, useDisclosure } from '@chakra-ui/react';

//common components
import RenderModal from '../../RenderModal';
import InfoTiles from '../../../common/InfoTiles';
import FormButton from '../../../common/FormButton';
import FileUploader from '../../../common/FileUploader';
import DocumentGallery from '../../../common/membershipFormComponents/GallerySection';
import MembershipFormWrapper from '../../../common/membershipFormComponents/MembershipFormWrapper';

//form sections
import GeneralInfoSection, {
  generalInfoFieldsValidation,
} from '../NewApplication/GeneralInfoSection';
import RequiredInfoSection from '../Verification/RequiredInfoSection';

//actions & api
import {
  patchDutyCertDocs,
  updateCertificate,
  deleteCertificate,
} from '../../../../api/certificates';
import { getConversionRate } from '../../../../api/currency';
import { notify } from '../../../../store/actions/globalActions';

//utils & constants
import _ from 'lodash';
import {
  dateValidation,
  fileValidation,
  useYupValidationResolver,
} from '../../../../util/yupUtils';
import { formatDate } from '../../../../util/formatUtils';
import { DUTY_CERT_URL } from '../../../../constants/routerUrl';

const DutyRecord = ({
  refresh,
  millData,
  defaultDocs,
  conversions,
  defaultFiles,
  defaultFields,
}) => {
  console.log(millData);
  const history = useHistory();
  const dispatch = useDispatch();
  const fileUploaderRef = useRef();
  const gallerySectionRef = useRef();
  const [modal, setModal] = useState();
  const [formData, setFormData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [initialMount, setInitialMount] = useState(true);
  const [docSubmitting, setDocSubmitting] = useState(false);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    setInitialMount(false);
  }, []);

  const millInfo = [
    { header: 'Mill Name', value: millData?.millName },
    { header: 'Membership No', value: millData?.membershipNo },
    { header: 'Membership Type', value: millData?.membershipType },
    { header: 'Contact', value: millData?.phoneNo },
  ];

  const resolver = useYupValidationResolver(
    yup.object().shape({
      ...generalInfoFieldsValidation,
      expiryDate: dateValidation.label('Expiry Date'),
    }),
  );

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: { ...defaultFields },
  });

  const value = useWatch({ control, name: 'value' });
  const currency = useWatch({ control, name: 'currency' });
  const conversionRate = useWatch({ control, name: 'conversionRate' });
  const selectedCertCategory = useWatch({ control, name: 'category' });
  const selectedHsCodesInComma = useWatch({ control, name: 'hsCode' });

  useEffect(() => {
    initialMount ? null : setValue('conversionRate', getConversionRate(currency, conversions));
  }, [currency]);

  useEffect(() => {
    if (!initialMount) {
      if (value && currency && conversionRate) {
        setValue(
          'total',
          Math.ceil(parseFloat(parseFloat(value || 0) * parseFloat(conversionRate)).toFixed(2)),
        );
      } else {
        setValue('total', defaultFields?.total || 0);
      }
    }
  }, [conversionRate, value]);

  const onSubmit = (data) => {
    const { expiryDate, blDate, dateOfLc, invoiceDate } = defaultFields;
    const cleanDefaultFields = {
      ...defaultFields,
      blDate: new Date(blDate),
      dateOfLc: new Date(dateOfLc),
      expiryDate: new Date(expiryDate),
      invoiceDate: new Date(invoiceDate),
    };

    if (_.isEqual(data, cleanDefaultFields) === false) {
      let formdata = {
        ...data,
        blDate: formatDate(data?.blDate),
        dateOfLc: formatDate(data?.dateOfLc),
        expiryDate: formatDate(data?.expiryDate),
        invoiceDate: formatDate(data?.invoiceDate),
      };

      let normalizedFormData = _.mapKeys(formdata, (value, key) => {
        if (value) {
          return key;
        }
      });
      setFormData(normalizedFormData);
      setModal('UpdateAlert');
      onOpen();
    } else {
      setModal('NoUpdate');
      onOpen();
    }
  };

  const confirmUpdate = async () => {
    const res = await updateCertificate({
      type: 'duty',
      id: millData?.certId,
      formdata: formData,
    });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      refresh();
    }
  };

  const handleDelete = () => {
    setModal('DELETE');
    onOpen();
  };

  const confirmDelete = () => {
    setModal('PASSWORD');
    onOpen();
  };

  const confirmPassword = async (password) => {
    setIsLoading(true);
    const res = await deleteCertificate({
      userId: user?.userId,
      password: password,
      certType: 'DUTY',
      certNum: millData?.certificateNum,
    });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      history.push(DUTY_CERT_URL);
    }
    setIsLoading(false);
  };

  /********** Doc Uploader **********/
  const fields = [
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'additionalDocuments',
      type: 'file',
      label: 'Additional Documents (PDF/JPG/JPEG-Max 5mb)',
    },
  ];

  const fieldsValidation = {
    [fields[0].name]: fileValidation.optional,
  };

  const handleFileQuery = async (docData) => {
    let formData = new FormData();

    const isFileUpdated = () => {
      let fileUpdateFlag = false;
      for (const key in docData) {
        if (docData?.[key]?.length > 0) {
          fileUpdateFlag = true;
        }
      }
      return fileUpdateFlag;
    };

    if (isFileUpdated()) {
      for (const key in docData) {
        if (docData?.[key]?.length > 0) {
          formData.append(key, docData[key][0]);
        }
      }

      const queryData = {
        docData: formData,
        millId: millData?.millId,
        certId: millData?.certId,
      };

      const res = await patchDutyCertDocs(queryData);
      if (res.data?.status === 200) {
        gallerySectionRef.current.closeUploader();
        dispatch(
          notify({
            title: 'Success',
            description: res.data.msg,
            status: 'success',
            duration: 5000,
          }),
        );
        refresh();
      }
    } else {
      gallerySectionRef.current.closeUploader();
    }
  };
  /********** Doc Uploader **********/

  return (
    <Box>
      <Box p={4}>
        <InfoTiles sectionName="Mill Information" sectionColumn={4} infoFields={millInfo} />
      </Box>
      <MembershipFormWrapper formName="Duty Exemption Certificate">
        <Box bg="white" p={4} borderRadius="4px" border="1px solid #3e1cfd">
          <HStack spacing={0}>
            <Text fontSize="20px" pr={1} fontWeight="bold" color="textSecondary">
              Certificate No:
            </Text>
            <Text fontSize="20px" color="primary.400">
              {millData?.certificateNum}
            </Text>
            <Text fontSize="20px" pr={1} pl={5} fontWeight="bold" color="textSecondary">
              Issue Date:
            </Text>
            <Text fontSize="20px" color="primary.400">
              {millData?.issueDate}
            </Text>
          </HStack>
        </Box>
        <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2}>
          <GridItem colSpan={1} bg="white" borderRadius="4px" p={4}>
            <GeneralInfoSection
              control={control}
              register={register}
              errors={errors}
              selectedMill={millData}
            />
          </GridItem>
          <GridItem colSpan={1} bg="white" borderRadius="4px" p={4}>
            <RequiredInfoSection
              control={control}
              register={register}
              errors={errors}
              selectedCertCategory={selectedCertCategory}
              selectedHsCodesInComma={selectedHsCodesInComma}
              setValue={setValue}
            />
          </GridItem>
          <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
            <DocumentGallery
              update
              sectionName="Documents"
              ref={gallerySectionRef}
              isLoading={docSubmitting}
              galleryFields={defaultFiles}
              handleDocUpdate={() => fileUploaderRef.current.submit()}
              uploaderComponent={
                <FileUploader
                  fields={fields}
                  ref={fileUploaderRef}
                  documents={defaultDocs}
                  handleQuery={handleFileQuery}
                  submitting={setDocSubmitting}
                  fieldsValidation={fieldsValidation}
                />
              }
            />
          </GridItem>
        </Grid>
        <HStack justifyContent="end" alignItems="center" mt={4}>
          <Button
            bg="red.500"
            minW="200px"
            onClick={handleDelete}
            _hover={{ bg: 'red.400' }}
            _focus={{ boxShadow: 'none' }}
            h={['45px', '50px', '50px', '55px', '55px']}>
            DELETE
          </Button>
          <FormButton
            mt={0}
            isLoading={isSubmitting}
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onSubmit)();
            }}>
            UPDATE
          </FormButton>
        </HStack>
      </MembershipFormWrapper>
      <RenderModal
        modal={modal}
        isOpen={isOpen}
        onClose={onClose}
        isSubmitting={isLoading}
        confirmDelete={confirmDelete}
        confirmUpdate={confirmUpdate}
        confirmPassword={confirmPassword}
      />
    </Box>
  );
};

export default DutyRecord;
