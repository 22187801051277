import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'spindlesInsCap',
    type: 'text',
    isRequired: true,
    label: 'Spindles',
  },
  {
    colSpan: 1,
    name: 'rotorsInsCap',
    type: 'text',
    isRequired: true,
    label: 'Rotors',
  },
  {
    colSpan: 1,
    name: 'autoConeInsCap',
    type: 'text',
    label: 'AutoCone',
  },
  {
    colSpan: 1,
    name: 'otherInsCap',
    type: 'text',
    label: 'Other',
  },
];

export const yarnInsCapFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup.string().required().label(fields[1].label),
  [fields[2].name]: yup.string().label(fields[2].label),
  [fields[3].name]: yup.string().label(fields[3].label),
};

const YarnInsCap = (props) => {
  return (
    <FormSection
      sectionName="Installed Capacity"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default YarnInsCap;
