import React, { useEffect } from 'react';
import StepsView from '../../new-request/StepsBar/StepsView';

const MemberInfo = () => {
  useEffect(() => {
    document.title = 'BTMA - Membership';
  }, []);
  return <StepsView infoView />;
};

export default MemberInfo;
