import {
  FETCH_MEMBERSHIP_REQUESTS_ACC,
  FETCH_MEMBERSHIP_REQUEST_ACC,
  FETCH_PROD_CERT_PURCHASES,
  FETCH_PROC_CERT_PURCHASES,
  FETCH_DUTY_CERT_PURCHASES,
  FETCH_DUTY_CERT_PURCHASE_REQUESTS,
  FETCH_RENEWAL_REQUESTS_ACC,
  FETCH_RENEWAL_REQUEST_ACC,
  FETCH_TRANSACTION_HISTORY,
  FETCH_CERT_PURCHASE_INVOICE,
  FETCH_NEW_MARKET_EXPANSION_PURCHASE_REQUESTS,
} from '../actions/actionTypes';

const initialState = {
  membershipRequests: null,
  renewalRequests: null,
  prodCertRequests: null,
  procCertRequests: null,
  dutyCertRequests: null,
  dutyCertPurchaseRequests: null,
  newMarketExpansionRequests: null,
  currentData: null,
  transactions: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_MEMBERSHIP_REQUESTS_ACC:
      return {
        ...state,
        membershipRequests: action.payload,
      };
    case FETCH_MEMBERSHIP_REQUEST_ACC:
      return {
        ...state,
        currentData: action.payload,
      };
    case FETCH_RENEWAL_REQUESTS_ACC:
      return {
        ...state,
        renewalRequests: action.payload,
      };
    case FETCH_RENEWAL_REQUEST_ACC:
      return {
        ...state,
        currentData: action.payload,
      };
    case FETCH_TRANSACTION_HISTORY:
      return {
        ...state,
        transactions: action.payload,
      };
    case FETCH_PROD_CERT_PURCHASES:
      return {
        ...state,
        prodCertRequests: action.payload,
      };
    case FETCH_PROC_CERT_PURCHASES:
      return {
        ...state,
        procCertRequests: action.payload,
      };
    case FETCH_DUTY_CERT_PURCHASES:
      return {
        ...state,
        dutyCertRequests: action.payload,
      };
    case FETCH_DUTY_CERT_PURCHASE_REQUESTS:
      return {
        ...state,
        dutyCertPurchaseRequests: action.payload,
      };
    case FETCH_CERT_PURCHASE_INVOICE:
      return {
        ...state,
        currentData: action.payload,
      };
    case FETCH_NEW_MARKET_EXPANSION_PURCHASE_REQUESTS:
      return {
        ...state,
        newMarketExpansionRequests: action.payload,
      };
    default:
      return state;
  }
}
