import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import PrintReceiptHeader from '../../../snippets/PrintReceiptHeader';

const styles = {
  tableStyles: { border: '1px solid black', width: '100%' },
  cellStyles: {
    border: '1px solid black',
    fontSize: '14px',
    padding: '5px 5px',
    textAlign: 'center',
  },
};

const InactiveMembersReport = React.forwardRef(({ columns, data }, ref) => (
  <Box ref={ref} data={data} p={6}>
    <Box>
      <PrintReceiptHeader />
    </Box>
    <Text w="100%" textAlign="center" fontSize="2xl" fontWeight="bold" pb={4}>
      List of Inactive Member Mills
    </Text>
    <Box>
      <Box>
        <table style={styles.tableStyles}>
          <thead>
            <tr>
              <th style={{ ...styles.cellStyles }}>sl no.</th>
              {columns?.map((item, index) => (
                <th key={index} style={{ ...styles.cellStyles }}>
                  {item.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((rowItem, rowIndex) => (
              <tr key={rowIndex}>
                <td style={styles.cellStyles}>{rowIndex + 1}</td>
                {columns?.map((colItem, colIndex) => (
                  <td key={colIndex} style={{ ...styles.cellStyles }}>
                    {rowItem?.[colItem?.accessor] || 'N/A'}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Box>
  </Box>
));

InactiveMembersReport.displayName = 'InactiveMembersReport';

export default InactiveMembersReport;
