import { Badge, Box, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import ActionDetailsBox from './ActionDetailsBox';
import AdditionalInformationBox from './AdditionalInformationBox';
import { formatDateSimple } from '../../../../util/formatUtils';

const createBlankCertificateInfos = (additionalInformation) => {
  let values = additionalInformation.split('*@*');
  let list = [
    {
      label: 'Invoice ID:',
      value: values[0],
    },
    {
      label: 'order ID:',
      value: values[1],
    },
    {
      label: 'Mill:',
      value: values[3],
    },
    {
      label: 'Certificate Type:',
      value: values[4],
    },
    {
      label: 'Start Range:',
      value: values[5],
    },
    {
      label: 'End Range:',
      value: values[6],
    },
    {
      label: 'Copies:',
      value: values[7],
    },
    {
      label: 'Total:',
      value: values[8] ? `${values[8]} BDT` : '0 BDT',
    },
    {
      label: 'Unit Cost:',
      value: values[9] ? `${values[9]} BDT` : '0 BDT',
    },
    {
      label: 'Payment Method:',
      value: values[10],
    },
    {
      label: 'Cheque No:',
      value: values[11],
    },
    {
      label: 'Payment Status:',
      value: values[13],
    },
  ];

  return list;
};

const createCertificateBatchDeleteInfos = (additionalInformation) => {
  let values = additionalInformation.split('*@*');

  let list = [
    {
      label: 'Batch ID:',
      value: values[0],
    },
    {
      label: 'Sold Date:',
      value: values[1],
    },
    {
      label: 'Mill:',
      value: values[2],
    },
    {
      label: 'Certificate Type:',
      value: values[3],
    },
    {
      label: 'Start Range:',
      value: values[4],
    },
    {
      label: 'End Range:',
      value: values[5],
    },
    {
      label: 'Copies:',
      value: values[6],
    },
    {
      label: 'Total:',
      value: `${values[7] || 0} BDT`,
    },
    {
      label: 'Unit Cost:',
      value: `${values[8] || 0} BDT`,
    },
    {
      label: 'Payment Method:',
      value: values[9],
    },
    {
      label: 'Cheque No:',
      value: values[10],
    },
    {
      label: 'Payment Status:',
      value: values[11],
    },
  ];

  return list;
};

const createCertificateStatusChangeInfos = (additionalInformation) => {
  let values = additionalInformation.split('*@*');

  let list = [
    {
      label: 'Certificate ID:',
      value: values[0],
    },
    {
      label: 'Status:',
      value: values[1],
    },
    {
      label: 'Certificate Type:',
      value: values[2],
    },
    {
      label: 'Mill:',
      value: values[3],
    },
  ];

  return list;
};

const createPreSoldCertificateInfos = (additionalInformation) => {
  let values = additionalInformation.split('*@*');

  let list = [
    {
      label: 'Certificate No:',
      value: values[0],
    },
    {
      label: 'Mill:',
      value: values[1],
    },
    {
      label: 'Issuer:',
      value: values[2],
    },
    {
      label: 'Type:',
      value: values[3],
    },
  ];

  return list;
};

const createDeleteCertificateDelete = (additionalInformation) => {
  let values = additionalInformation.split('*@*');

  let list = [
    {
      label: 'Certificate No:',
      value: values[0],
    },
    {
      label: 'Mill:',
      value: values[1],
    },
    {
      label: 'Status:',
      value: values[2],
    },
  ];

  return list;
};

const createUpdateCertificateInfos = (additionalInformation) => {
  let values = additionalInformation.split('*@*');

  let list = [
    {
      label: 'Certificate No:',
      value: values[0],
    },
    {
      label: 'Type:',
      value: values[1],
    },
    {
      label: 'Status:',
      value: values[2],
    },
    {
      label: 'Mill:',
      value: values[3],
    },
    {
      label: 'Product Type:',
      value: values[4],
    },
  ];

  return list;
};

const createAddNewMemberInfos = (additionalInformation) => {
  let values = additionalInformation.split('*@*');

  let list = [
    {
      label: 'Mill:',
      value: values[0],
    },
    {
      label: 'Issuer:',
      value: values[1],
    },
  ];

  return list;
};

const createProvideMembershipInfos = (additionalInformation) => {
  let values = additionalInformation.split('*@*');

  let list = [
    {
      label: 'Transaction ID:',
      value: values[0],
    },
    {
      label: 'Mill:',
      value: values[1],
    },
    {
      label: 'Payment Method:',
      value: values[2],
    },
    {
      label: 'Cheque No:',
      value: values[3],
    },
    {
      label: 'Current Amount:',
      value: values[4] ? `${values[4]} BDT` : 'N/A',
    },
    {
      label: 'Paid Amount:',
      value: values[5] ? `${values[5]} BDT` : 'N/A',
    },
    {
      label: 'Current Due:',
      value: values[6] && values[6] !== 'N/A' ? `${values[6]} BDT` : '0 BDT',
    },
    {
      label: 'Period:',
      value: values[7],
    },
    {
      label: 'Issue Date:',
      value: values[8] ? formatDateSimple(values[8]) : 'N/A',
    },
    {
      label: 'Membership Type:',
      value: values[9],
    },
  ];

  return list;
};

const createCertificateIssueInfos = (additionalInformation) => {
  let values = additionalInformation.split('*@*');

  let list = [
    {
      label: 'Certificate No:',
      value: values[0],
    },
    {
      label: 'Certificate Type:',
      value: values[1],
    },
    {
      label: 'Mill:',
      value: values[2],
    },
    {
      label: 'Issuer:',
      value: values[3],
    },
  ];

  return list;
};

const createCertificateIssueInfosForDutyExemption = (additionalInformation) => {
  let values = additionalInformation.split('*@*');

  let list = [
    {
      label: 'Account UID:',
      value: values[0],
    },
    {
      label: 'Certificate Type:',
      value: values[1],
    },
    {
      label: 'Mill:',
      value: values[2],
    },
    {
      label: 'Issuer:',
      value: values[3],
    },
  ];

  return list;
};

const createDutyExemptionCertificateAssingInfos = (additionalInformation) => {
  let values = additionalInformation.split('*@*');

  let list = [
    {
      label: 'UID:',
      value: values[0],
    },
    {
      label: 'Certificate Type:',
      value: values[1],
    },
    {
      label: 'Certificate Number:',
      value: values[2],
    },
    {
      label: 'Mill:',
      value: values[3],
    },
    {
      label: 'Issuer:',
      value: values[4],
    },
    {
      label: 'Date Sold:',
      value: values[5],
    },
    {
      label: 'Payment Method:',
      value: values[6],
    },
    {
      label: 'Paid Amount:',
      value: values[7] ? `${values[7]} BDT` : '0 BDT',
    },
    {
      label: 'Total:',
      value: values[8] ? `${values[8]} BDT` : '0 BDT',
    },
    {
      label: 'Discount:',
      value: values[9] ? `${values[9]} BDT` : '0 BDT',
    },
    {
      label: 'Cheque No:',
      value: values[10],
    },
    {
      label: 'Status:',
      value: values[11],
    },
  ];

  return list;
};

const createDutyExemptionCertificateSoldInfos = (additionalInformation) => {
  let values = additionalInformation.split('*@*');

  let list = [
    {
      label: 'ID:',
      value: values[0],
    },
    {
      label: 'Order Id:',
      value: values[1],
    },
    {
      label: 'Date Sold:',
      value: values[2],
    },
    {
      label: 'Mill:',
      value: values[3],
    },
    {
      label: 'Certificate Type:',
      value: values[4],
    },
    {
      label: 'Start Range:',
      value: values[5],
    },
    {
      label: 'End Range:',
      value: values[6],
    },
    {
      label: 'No Of Copies:',
      value: values[7] ? values[7] : 0,
    },
    {
      label: 'Total:',
      value: values[8] ? `${values[8]} BDT` : '0 BDT',
    },
    {
      label: 'Unit Cost:',
      value: values[9] ? `${values[9]} BDT` : '0 BDT',
    },
    {
      label: 'Payment Method:',
      value: values[10] || 'N/A',
    },
    {
      label: 'Cheque No:',
      value: values[11],
    },
    {
      label: 'Status:',
      value: values[13],
    },
    {
      label: 'Remarks:',
      value: values[14] || 'N/A',
    },
  ];

  return list;
};

const createAdjustReceiptInfos = (additionalInformation) => {
  let values = additionalInformation.split('*@*');

  let list = [
    {
      label: 'Payment:',
      value: values[0],
    },
    {
      label: 'Cheque No:',
      value: values[1],
    },
    {
      label: 'Previous Due:',
      value: values[2] || values[2] !== 'N/A' ? `${values[2]} BDT` : 'N/A',
    },
    {
      label: 'Current Amount:',
      value: values[3] ? `${values[3]} BDT` : '0 BDT',
    },
    {
      label: 'Paid Amount:',
      value: values[4] ? `${values[4]} BDT` : '0 BDT',
    },
    {
      label: 'Period:',
      value: values[5],
    },
    {
      label: 'Issue Date:',
      value: values[6] ? formatDateSimple(values[6]) : 'N/A',
    },
  ];

  return list;
};

const createMemberPaymentUpdateInfosForMembershipSubscription = (additionalInformation) => {
  let values = additionalInformation.split('*@*');

  let list = [
    {
      label: 'Payment For:',
      value: values[0],
    },
    {
      label: 'Membership Payment Type:',
      value: values[1],
    },
    {
      label: 'Paid Amount:',
      value: values[2] || values[2] !== 'N/A' ? `${values[2]} BDT` : 'N/A',
    },
    {
      label: 'Previous Due:',
      value: values[3] || values[3] !== 'N/A' ? `${values[3]} BDT` : 'N/A',
    },
    {
      label: 'Payment Method:',
      value: values[4] || 'N/A',
    },
    {
      label: 'Other Payment Reason:',
      value: values[5] || 'N/A',
    },
    {
      label: 'Extension Cost:',
      value: values[6] || values[6] !== 'N/A' ? `${values[6]} BDT` : 'N/A',
    },
    {
      label: 'Extension Year:',
      value: values[7] || 'N/A',
    },
    {
      label: 'Upto (Year):',
      value: values[8] || 'N/A',
    },
    {
      label: 'Cheque No:',
      value: values[9] || 'N/A',
    },
    {
      label: 'Current Amount:',
      value: values[10] || values[10] !== 'N/A' ? `${values[10]} BDT` : 'N/A',
    },
    {
      label: 'Issue Date:',
      value: values[11] ? formatDateSimple(values[11]) : 'N/A',
    },
  ];

  return list;
};

const createMemberInfoUpdateForMembershipSubscriptionInfos = (additionalInformation) => {
  let values = additionalInformation.split('*@*');

  let list = [
    {
      label: 'Expiry Year:',
      value: values[0],
    },
    {
      label: 'Membership Status:',
      value: values[1],
    },
    {
      label: 'Membership Type:',
      value: values[2] || 'N/A',
    },
    {
      label: 'Previous Due:',
      value: values[3] || values[3] !== 'N/A' ? `${values[3]} BDT` : 'N/A',
    },
  ];

  return list;
};

const createArrearAdjustmentInfos = (additionalInformation) => {
  let values = additionalInformation.split('*@*');

  let list = [
    {
      label: 'Mill Name:',
      value: values[0],
    },
    {
      label: 'Amount:',
      value: values[1] ? `${values[1]} BDT` : '0 BDT',
    },
  ];

  return list;
};

const convertAdditionalInfoIntoList = (
  section,
  module,
  context,
  additionalInformation,
  service,
  remarks,
) => {
  if (!additionalInformation || additionalInformation === '') {
    return [];
  }

  if (
    section == 'accounts' &&
    module == 'accounts-certificate' &&
    (context == 'blank-certificate' ||
      context == 'blank-certificate-sold' ||
      context == 'production-certificate-sold' ||
      context == 'processing-certificate-sold')
  ) {
    const list = createBlankCertificateInfos(additionalInformation);
    return list;
  }

  if (
    section == 'accounts' &&
    module == 'certificate' &&
    service == 'deleteCertificateBatch' &&
    (context == 'processing' || context == 'production')
  ) {
    const list = createCertificateBatchDeleteInfos(additionalInformation);
    return list;
  }

  if (section == 'certificates' && context == 'status_change') {
    const list = createCertificateStatusChangeInfos(additionalInformation);
    return list;
  }

  if (section == 'certificates' && context == 'pre-sold') {
    const list = createPreSoldCertificateInfos(additionalInformation);
    return list;
  }

  if (section == 'certificates' && context == 'delete-certificate') {
    const list = createDeleteCertificateDelete(additionalInformation);
    return list;
  }

  if (section == 'certificates' && context == 'update-certificate') {
    const list = createUpdateCertificateInfos(additionalInformation);
    return list;
  }

  if (section == 'membership' && module == 'add-new-member' && context == 'add-new-member') {
    const list = createAddNewMemberInfos(additionalInformation);
    return list;
  }

  /**************certificate section details boxes****************/
  if (section == 'certificates' && context == 'certificate_issued') {
    if (service == 'postNewDutyCertificate' || service == 'patchDutyCert') {
      const list = createCertificateIssueInfosForDutyExemption(additionalInformation);
      return list;
    } else {
      const list = createCertificateIssueInfos(additionalInformation);
      return list;
    }
  }
  /**************accounts section details boxes****************/

  if (section == 'accounts' && module == 'accounts-membership' && context == 'new-request') {
    const list = createProvideMembershipInfos(additionalInformation);
    return list;
  }

  if (
    section == 'accounts' &&
    module == 'accounts-certificate' &&
    context == 'duty-exemption-certificate-assign'
  ) {
    const list = createDutyExemptionCertificateAssingInfos(additionalInformation);
    return list;
  }

  if (
    section == 'accounts' &&
    module == 'accounts-certificate' &&
    context == 'duty-exemption-certificate-sold'
  ) {
    const list = createDutyExemptionCertificateSoldInfos(additionalInformation);
    return list;
  }

  if (
    section == 'accounts' &&
    module == 'accounts-membership' &&
    context == 'transactions' &&
    service == 'adjustReceipt'
  ) {
    const list = createAdjustReceiptInfos(additionalInformation);
    return list;
  }

  if (
    section == 'accounts' &&
    module == 'accounts-membership' &&
    context == 'transactions' &&
    service == 'adjustArrear'
  ) {
    const list = createArrearAdjustmentInfos(additionalInformation);
    return list;
  }

  if (
    section == 'accounts' &&
    module == 'accounts-membership' &&
    context == 'transactions' &&
    service == 'patchMembership'
  ) {
    let infoList = [];
    if (remarks == 'membership info updated (auto)') {
      infoList = createMemberInfoUpdateForMembershipSubscriptionInfos(additionalInformation);
      return infoList;
    } else {
      infoList = createMemberPaymentUpdateInfosForMembershipSubscription(additionalInformation);
      return infoList;
    }
  }

  return [];
};

const LogDetailsTemplate = ({ logDetails }) => {
  const additionalInfoList = convertAdditionalInfoIntoList(
    logDetails?.section,
    logDetails?.module,
    logDetails?.context,
    logDetails?.additionalInformation,
    logDetails?.service,
    logDetails?.remarks,
  );

  return (
    <Box shadow>
      <SimpleGrid columns={{ sm: 1, md: 2, lg: 2 }} spacing="20px">
        <div>
          <ActionDetailsBox logDetails={logDetails} />
        </div>
        <div>
          {logDetails?.certificate_no && <Badge>{logDetails?.certificate_no}</Badge>}
          <AdditionalInformationBox additionalInfos={additionalInfoList} />
        </div>
      </SimpleGrid>
    </Box>
  );
};

export default LogDetailsTemplate;
