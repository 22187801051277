import React, { useState } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Box, useDisclosure, VStack } from '@chakra-ui/react';

import FormButton from '../../../common/FormButton';
import DialogueBox from '../../../common/DialogueBox';
import NewUserInfoSection, { fieldsValidation } from './NewUserInfoSection';

import { notify } from '../../../../store/actions/globalActions';
import { useYupValidationResolver } from '../../../../util/yupUtils';
import { createMillUser } from '../../../../api/millUsers';

const AddUserForm = ({ millUid, closeModal, refresh }) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const resolver = useYupValidationResolver(
    yup.object().shape({
      ...fieldsValidation,
    }),
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
  });

  const onSubmit = (data) => {
    setUserData(data);
    onOpen();
  };

  const confirmUpdate = async () => {
    const res = await createMillUser({ uid: millUid, userData });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 3000,
        }),
      );
      refresh();
      closeModal();
    }
  };

  return (
    <Box as="form" w="600px" onSubmit={handleSubmit(onSubmit)}>
      <NewUserInfoSection register={register} control={control} errors={errors} />
      <VStack w="100%" mt={6}>
        <FormButton mt={0} type="submit" isLoading={isSubmitting}>
          SUBMIT
        </FormButton>
      </VStack>
      <DialogueBox
        close={onClose}
        alertTitle="Confirm"
        mountControl={isOpen}
        performAction={confirmUpdate}
        alertMessage="Are you sure you want to create a user with these information?"
      />
    </Box>
  );
};

export default AddUserForm;
