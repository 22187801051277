export const SAMPLE_CSV_DOWNLOAD_URL =
  'https://btma-stage.s3.ap-southeast-1.amazonaws.com/public-assets/Sample-Labour-Data+2022+-+Sample-Labour-Data+2022.csv';

export const labourKeys = {
  ID: { name: 'Employee-ID', isRequired: false, column: 0, dbKey: 'millEmployeeId' },
  NAME: { name: 'Name', isRequired: true, column: 1, dbKey: 'name' },
  NID: { name: 'NID', isRequired: false, column: 2, dbKey: 'nid' },
  BIRTH_CERT: { name: 'Birth-Certificate', isRequired: false, column: 3, dbKey: 'birthCertNum' },
  DOB: { name: 'Date-of-Birth', isRequired: true, column: 4, dbKey: 'dateOfBirth', isDate: true },
  GENDER: { name: 'Gender', isRequired: true, column: 5, dbKey: 'gender' },
  FATHER: { name: 'Father-Name', isRequired: false, column: 6, dbKey: 'fatherName' },
  MOTHER: { name: 'Mother-Name', isRequired: false, column: 7, dbKey: 'motherName' },
  DEPARTMENT: { name: 'Department', isRequired: false, column: 8, dbKey: 'department' },
  DESIGNATION: { name: 'Designation', isRequired: false, column: 9, dbKey: 'designation' },
  JOINING: {
    name: 'Joining-Date',
    isRequired: false,
    column: 10,
    dbKey: 'joiningDate',
    isDate: true,
  },
  DEPARTURE: {
    name: 'Departure-Date',
    isRequired: false,
    column: 11,
    dbKey: 'departureDate',
    isDate: true,
  },
  CONTACT: { name: 'Contact-Number', isRequired: true, column: 12, dbKey: 'contactNo' },
  ADDRESS: { name: 'Present-Address', isRequired: false, column: 13, dbKey: 'presentAddress' },
  INSURANCE: {
    name: 'Insurance-Coverage',
    isRequired: false,
    column: 14,
    dbKey: 'insuranceCoverage',
  },
  NOMINEE: { name: 'Name-of-Nominee', isRequired: false, column: 15, dbKey: 'nominee' },
  RELATION: {
    name: 'Relationship-With-Employee',
    isRequired: false,
    column: 16,
    dbKey: 'relation',
  },
  STATUS: { name: 'Status', isRequired: false, column: 16, dbKey: 'status' },
};

export const labourKeyNames = () => {
  let keyList = [];
  for (let key in labourKeys) {
    keyList.push(labourKeys[key].name);
  }
  return keyList;
};

export const labourKeyList = () => {
  let keyList = [];
  for (let key in labourKeys) {
    keyList.push(key);
  }
  return keyList;
};

export const LABOURER_GENDER_TYPES = {
  name: 'Gender',
  choices: [
    { name: 'Male', value: 'MALE' },
    { name: 'Female', value: 'FEMALE' },
    { name: 'Other', value: 'OTHER' },
  ],
};
