import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Icon,
  Grid,
  VStack,
  HStack,
  Divider,
  Spinner,
  Tooltip,
  GridItem,
  IconButton,
  CloseButton,
} from '@chakra-ui/react';
import FormButton from '../../common/FormButton';
import SearchSection from '../../common/SearchSection';
import { getAllCertReports } from '../../../api/reports';
import FileExporter from '../commonSections/FileExporter';
import CertificateNum from '../commonSections/CertificateNum';
import { formatDate, renderDate } from '../../../util/formatUtils';
import DateRangePicker from '../../common/dataTable/DateRangePicker';
import { REPORT_DATE_RANGE } from '../../../constants/reportConstant';
import { AiOutlineDoubleLeft, AiOutlineFileSearch, AiOutlineDoubleRight } from 'react-icons/ai';
import SelectCategory from '../commonSections/SelectReport';

const DutyReportWizard = () => {
  const [mill, setMill] = useState();
  const [certNum, setCertNum] = useState();
  const [results, setResults] = useState();
  const [category, setCategory] = useState();
  const [endDate, setEndDate] = useState('');
  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [dateErrorMsg, setDateErrorMsg] = useState('');
  const [selectedFields, setSelectedFields] = useState([]);
  const [clearDateSelect, setClearDateSelect] = useState(false);
  const [dateRangeValidation, setDateRangeValidation] = useState(true);

  useEffect(() => {
    document.title = 'BTMA - Reports';
  }, []);

  useEffect(() => {
    setResults();
  }, [selectedFields]);

  const handleDateRange = (data) => {
    setStartDate(formatDate(data.startDate));
    setEndDate(formatDate(data.endDate));
  };

  const checkDateRangeLength = () => {
    if (
      (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24) >
      REPORT_DATE_RANGE
    ) {
      setDateRangeValidation(false);
      setDateErrorMsg('Maximum one year range is recommended for date');
    } else {
      setDateRangeValidation(true);
      setDateErrorMsg('');
    }
  };

  useEffect(() => {
    checkDateRangeLength();
  }, [startDate, endDate]);

  useEffect(() => {
    handleDateRange({
      startDate: new Date(`${new Date().getFullYear()}-01-01`),
      endDate: new Date(),
    });
  }, []);

  const dateFields = ['issue date', 'lc date', 'invoice date', 'bl date'];

  const fields = [
    { name: 'Mill Name', key: 'millInfo.millName as mill name' },
    { name: 'Membership No.', key: 'millInfo.membershipNo as membership no' },
    { name: 'Certificate Number', key: 'certificateInfo.certificateNum as certificate number' },
    { name: 'IssueDate', key: 'issueDate as issue date', displayName: 'issue date' },
    { name: 'L/C No.', key: 'masterLc as lc no' },
    { name: 'L/C Date', key: 'dateOfLc as lc date', displayName: 'lc date' },
    { name: 'Invoice', key: 'invoice as invoice' },
    { name: 'Invoice Date', key: 'invoiceDate as invoice date', displayName: 'invoice date' },
    { name: 'BL No.', key: 'blNo as bl no' },
    { name: 'BL Date', key: 'blDate as bl date', displayName: 'bl date' },
    { name: 'Category', key: 'category' },
    { name: 'Item Name', key: 'itemName as item name' },
    { name: 'Invoice Value', key: 'value as invoice value' },
    { name: 'Currency', key: 'currency' },
    { name: 'Conversion Rate', key: 'conversionRate' },
    { name: 'Total (in Tk)', key: 'total as total (in Tk)' },
    { name: 'Quantity (in KG)', key: 'quantity as quantity (in KG)' },
    { name: 'HS Code', key: 'hsCode as hs code' },
    { name: 'Import Duty', key: 'importDuty as import duty' },
    { name: 'Vat%', key: 'vat as vat%' },
    { name: 'Duty', key: 'duty' },
    { name: 'Remarks', key: 'dutyExemptionCertificates.remarks as remarks' },
  ];

  const getUnselectedFields = (fields) => {
    return fields?.filter((item) => !selectedFields?.some((obj) => item.key === obj.key));
  };

  const getKeys = () => {
    let keys = [];

    if (selectedFields?.length > 0) {
      for (let item of selectedFields) {
        keys.push(item.key);
      }
    } else {
      for (let item of fields) {
        keys.push(item.key);
      }
    }

    return keys;
  };

  const handleSubmit = async () => {
    setLoader(true);
    let formData = { millId: mill?.id, certNum, category, startDate, endDate, fields: getKeys() };
    const res = await getAllCertReports({ type: 'duty', formData });

    if (res.data?.status === 200) {
      const resultArray = res.data?.result;

      let availableDateFields =
        selectedFields?.length > 0
          ? dateFields?.filter((date) => selectedFields?.some((obj) => date === obj.displayName))
          : dateFields;

      let convertedArray = resultArray?.map((item) => {
        function convertFalseyValues() {
          let currItem = item;
          for (let key in currItem) {
            if (!currItem[key]) currItem[key] = 'n/a';
          }
          return currItem;
        }

        function convertDates() {
          let currItem = item;
          availableDateFields?.map((date) =>
            currItem[date] && currItem[date] !== 'n/a'
              ? (currItem[date] = renderDate(item[date], 'numeric'))
              : currItem[date],
          );
          return currItem;
        }

        return { ...convertFalseyValues(), ...convertDates() };
      });

      setResults(convertedArray);
    }
    setLoader(false);
  };

  return (
    <Box p={4}>
      <Text fontSize="2xl" fontWeight="bold">
        Duty-Exemption Report Wizard
      </Text>

      <Divider mb={3} />
      <Grid templateColumns="repeat(2, 1fr)" gap={3}>
        <GridItem colSpan={1}>
          <Box p={4} bg="white" borderRadius="4px">
            <Box position="relative" p={4} border="1px solid #6B7982" borderRadius="4px">
              <Text
                px={1}
                bg="white"
                left="10px"
                top="-12px"
                fontSize="sm"
                position="absolute"
                color="textSecondary">
                Select Options
              </Text>

              <Text color="textSecondary" mb={1.5}>
                Select Columns
              </Text>
              <HStack>
                <Box
                  p={4}
                  w="45%"
                  h="450px"
                  bg="secondary"
                  overflow="auto"
                  boxShadow="inset 0px 0px 5px rgba(0,0,0,0.2)">
                  {selectAll
                    ? null
                    : getUnselectedFields(fields)?.map((item, index) => (
                        <Text
                          key={index}
                          cursor="pointer"
                          whiteSpace="nowrap"
                          onClick={() => setSelectedFields([...selectedFields, item])}>
                          {item?.name}
                        </Text>
                      ))}
                </Box>
                <VStack w="10%" h="450px" justifyContent="center">
                  <Tooltip label="Select all" aria-label="A tooltip">
                    <IconButton
                      icon={<AiOutlineDoubleRight />}
                      onClick={() => {
                        setSelectedFields(fields);
                        setSelectAll(true);
                      }}
                    />
                  </Tooltip>
                  <Tooltip label="Deselect all" aria-label="A tooltip">
                    <IconButton
                      icon={<AiOutlineDoubleLeft />}
                      onClick={() => {
                        setSelectedFields([]);
                        setSelectAll(false);
                      }}
                    />
                  </Tooltip>
                </VStack>
                <Box
                  p={4}
                  w="45%"
                  h="450px"
                  bg="secondary"
                  overflow="auto"
                  boxShadow="inset 0px 0px 5px rgba(0,0,0,0.2)">
                  {selectedFields?.map((item, index) => (
                    <Text
                      key={index}
                      cursor="pointer"
                      whiteSpace="nowrap"
                      onClick={() => {
                        setSelectAll(false);
                        setSelectedFields(selectedFields?.filter((obj) => obj.key !== item.key));
                      }}>
                      {item?.name}
                    </Text>
                  ))}
                </Box>
              </HStack>
            </Box>
          </Box>
        </GridItem>

        <GridItem colSpan={1}>
          <Box p={4} bg="white" borderRadius="4px">
            <Box position="relative" p={4} border="1px solid #6B7982" borderRadius="4px">
              <Text
                px={1}
                bg="white"
                left="10px"
                top="-12px"
                fontSize="sm"
                position="absolute"
                color="textSecondary">
                Search Information
              </Text>
              <SearchSection forceSearch minimal setSelectedMill={setMill} />
              <HStack>
                <Text mt={1} color="primary.400" mb={1.5}>
                  {mill?.millName}
                </Text>
                {mill?.id ? (
                  <CloseButton
                    size="sm"
                    _focus={{ boxShadow: '0 0 0 3px #9280ff' }}
                    onClick={() => {
                      setMill();
                    }}
                  />
                ) : null}
              </HStack>
              <Box mt={4}>
                <SelectCategory
                  setValue={setCategory}
                  label="Category"
                  placeholder="Select an option"
                  choices={[
                    { name: 'Textile Spare Parts', value: 'Textile Spare Parts' },
                    { name: 'Dyes Chemicals', value: 'Dyes Chemicals' },
                    { name: 'Polyester Staple Fiber', value: 'Polyester Staple Fiber' },
                    { name: 'Viscose Staple Fiber', value: 'Viscose Staple Fiber' },
                    { name: 'Tencel & Flax Fiber', value: 'Tencel & Flax Fiber' },
                    { name: 'Others Fiber', value: 'Others Fiber' },
                  ]}
                />
              </Box>
              <HStack mt={4} alignItems="flex-start">
                <CertificateNum label="Certificate No." setValue={setCertNum} />

                <Box mt={4}>
                  <Text color="textSecondary" mb={1.5}>
                    Date Range
                  </Text>
                  <DateRangePicker
                    setDefaultDate
                    data={handleDateRange}
                    error={setDateErrorMsg}
                    clearSelection={clearDateSelect}
                    validation={setDateRangeValidation}
                    cleared={() => setClearDateSelect(false)}
                  />
                  {!dateErrorMsg ? (
                    <HStack>
                      <Text mt={1} color="primary.400" mb={1.5}>
                        {startDate === endDate
                          ? renderDate(startDate)
                          : `from ${renderDate(startDate)} to ${renderDate(endDate)}`}
                      </Text>
                      {startDate || endDate ? (
                        <CloseButton
                          size="sm"
                          _focus={{ boxShadow: '0 0 0 3px #9280ff' }}
                          onClick={() => {
                            setStartDate('');
                            setEndDate('');
                            setClearDateSelect(true);
                          }}
                        />
                      ) : null}
                    </HStack>
                  ) : null}
                  <Text fontSize="xs" color="invalid">
                    {dateErrorMsg}
                  </Text>
                </Box>
              </HStack>
              <HStack w="100%" mt={4} spacing={4}>
                <FormButton
                  mt={0}
                  isLoading={loader}
                  onClick={handleSubmit}
                  isDisabled={!dateRangeValidation}>
                  GENERATE
                </FormButton>
                {loader ? (
                  <HStack
                    flex={1}
                    h="45px"
                    bg="secondary"
                    borderRadius="4px"
                    justifyContent="center">
                    <Spinner
                      size="md"
                      thickness="2px"
                      color="primary.700"
                      emptyColor="primary.50"
                    />
                    <Text color="primary.700">Generating Report...</Text>
                  </HStack>
                ) : results ? (
                  results?.length > 0 ? (
                    <FileExporter title="Duty-Exemption" data={results} />
                  ) : (
                    <Text color="textSecondary" fontSize="xl" w="100%" textAlign="center">
                      <Icon as={AiOutlineFileSearch} w={10} h={10} /> No entries found!
                    </Text>
                  )
                ) : null}
              </HStack>
            </Box>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default DutyReportWizard;
