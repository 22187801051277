import React from 'react';
import PublicNavbar from '../snippets/PublicNavbar';
import { Flex, Text, Stack, Box, VStack } from '@chakra-ui/react';

const AuthWrapper = ({ children }) => {
  return (
    <VStack minH="100vh" spacing={0}>
      <PublicNavbar />
      <Flex
        flex={1}
        w="100%"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        backgroundColor="secondary">
        <Stack flexDir="column" my={10} justifyContent="center" alignItems="center">
          <Text fontSize="26px" fontWeight="bold">
            BTMA ADMIN PORTAL
          </Text>
          <Box>{children}</Box>
        </Stack>
      </Flex>
      <Box w="100%" h="7vh" maxH="80px" minH="60px" bg="white" />
    </VStack>
  );
};

export default AuthWrapper;
