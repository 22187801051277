import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useYupValidationResolver } from '../../../../../util/yupUtils';
import FormSection from '../../../../common/hookFormComponents/FormSection';
import { VStack } from '@chakra-ui/react';
import FormButton from '../../../../common/FormButton';
import DialogueBox from '../../../../common/DialogueBox';
import { adjustArrearAmount } from '../../../../../api/accounts';
import { useDispatch } from 'react-redux';
import { notify } from '../../../../../store/actions/globalActions';

const ArrearValue = ({ uid, onClose, currentVal }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fields = [
    {
      colSpan: 1,
      name: 'arrear',
      type: 'number',
      label: 'Arrear',
      isRequired: true,
    },
  ];

  const validatonSchema = yup.object().shape({
    [fields[0].name]: yup
      .number()
      .typeError(`${fields[0].label} must be a number`)
      .integer()
      .min(0)
      .required()
      .label(fields[0].label),
  });

  const resolver = useYupValidationResolver(validatonSchema);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver, defaultValues: { arrear: currentVal } });

  const onsubmit = (data) => {
    setFormData(data);
    setIsOpen(true);
  };

  const confirmSubmit = async () => {
    setIsOpen(false);
    setIsSubmitting(true);
    const res = await adjustArrearAmount(uid, formData);
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
    }
    setIsSubmitting(false);
    onClose();
  };

  return (
    <VStack width="sm">
      <DialogueBox
        alertTitle="Confirm"
        mountControl={isOpen}
        performAction={confirmSubmit}
        close={() => setIsOpen(false)}
        alertMessage="Are you sure you want submit this information?"
      />
      <FormSection secondary formFields={fields} errors={errors} register={register} />;
      <FormButton onClick={() => handleSubmit(onsubmit)()} isLoading={isSubmitting}>
        Submit
      </FormButton>
    </VStack>
  );
};

export default ArrearValue;
