import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../../common/membershipFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'shuttleInsCap',
    type: 'text',
    isRequired: true,
    label: 'Shuttle',
  },
  {
    colSpan: 1,
    name: 'shuttleLessInsCap',
    type: 'text',
    isRequired: true,
    label: 'Shuttle Less',
  },
  {
    colSpan: 1,
    name: 'airjetInsCap',
    type: 'text',
    label: 'Airjet',
  },
  {
    colSpan: 1,
    name: 'repairInsCap',
    type: 'text',
    label: 'Rapier',
  },
  {
    colSpan: 1,
    name: 'knittingInsCap',
    type: 'text',
    label: 'Knitting',
  },
  {
    colSpan: 1,
    name: 'othersInsCap',
    type: 'text',
    label: 'Others',
  },
];

export const fabricInsCapFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup.string().required().label(fields[1].label),
  [fields[2].name]: yup.string().label(fields[2].label),
  [fields[3].name]: yup.string().label(fields[3].label),
  [fields[4].name]: yup.string().label(fields[4].label),
  [fields[5].name]: yup.string().label(fields[5].label),
};

const FabricInsCap = (props) => {
  return (
    <FormSection
      sectionName="Fabric Installed Capacity"
      sectionColumn={3}
      formFields={fields}
      {...props}
    />
  );
};

export default FabricInsCap;
