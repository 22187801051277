import React from 'react';
import { RadioGroup, Radio, Text } from '@chakra-ui/react';
import { HStack } from '@chakra-ui/layout';
import { Controller } from 'react-hook-form';
const RadioField = ({ control, name, label, choices, errorMessage, defaultValue }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, name, ref } }) => (
        <RadioGroup
          mt={1}
          ref={ref}
          onChange={onChange}
          value={value}
          name={name}
          defaultValue={defaultValue}>
          <Text fontSize="md" mb={1.5} color="textSecondary">
            {label}
          </Text>
          <HStack h="50px" alignItems="center">
            {choices.map((c, index) => (
              <Radio
                colorScheme="primary"
                key={index}
                value={c.value}
                isChecked={c.value === defaultValue}>
                {c.name}
              </Radio>
            ))}
          </HStack>
          <Text textAlign="center" color="invalid">
            {errorMessage}
          </Text>
        </RadioGroup>
      )}
    />
  );
};
export default RadioField;
