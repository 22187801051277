import React, { useEffect, useState } from 'react';
import { read, utils, writeFileXLSX } from 'xlsx';
import Dropzone from 'react-dropzone';
import { readString } from 'react-papaparse';
import { validateCsv } from '../../../../util/csvUtils';
import { FaFile, FaFileCsv, FaFileExcel } from 'react-icons/fa';
import { MdClose, MdCloudDownload, MdCloudUpload } from 'react-icons/md';
import {
  Box,
  Text,
  Icon,
  Menu,
  HStack,
  Spacer,
  Button,
  MenuList,
  MenuItem,
  MenuButton,
  IconButton,
} from '@chakra-ui/react';
import { labourKeys } from '../../../../constants/labourDatabaseConstant';

const dropzoneStyles = {
  container: {
    width: '100%',
    display: 'flex',
    padding: '20px',
    cursor: 'pointer',
    borderRadius: '4px',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '2px dashed #ccc',
  },
  title: {
    color: '#333',
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  helperText: {
    color: '#a2a2a2',
    fontSize: '0.9rem',
  },
  fileName: {
    fontSize: '1rem',
    marginTop: '10px',
    fontWeight: 'bold',
  },
  error: {
    color: '#E53E3E',
    fontSize: '0.9rem',
  },
};

const UploadData = ({ reUpload, setData, setTempData, setView, setPreview, children }) => {
  const [error, setError] = useState(false);
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    return function cleanup() {
      setSelectedFile();
      setPreview();
      setData();
    };
  }, []);

  const handleCsv = (file) => {
    readString(file, {
      header: true,
      skipEmptyLines: true,
      complete: (rows) => {
        if (rows) {
          const result = validateCsv(rows.data);
          if (result?.valid) {
            setData(result?.validData);
            setPreview('PREVIEW');
          } else {
            setData(result?.errors);
            setTempData(
              rows?.data?.filter((row) => {
                for (let key in row) {
                  if (row[key]) {
                    return row;
                  }
                }
              }),
            );
            setPreview('ERROR');
          }
        }
      },
    });
  };

  const handleXlsx = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const rows = utils.sheet_to_json(sheet);
      if (rows) {
        const result = validateCsv(rows);
        if (result?.valid) {
          setData(result?.validData);
          setPreview('PREVIEW');
        } else {
          setData(result?.errors);
          setTempData(
            rows?.filter((row) => {
              for (let key in row) {
                if (row[key]) {
                  return row;
                }
              }
            }),
          );
          setPreview('ERROR');
        }
      }
    };
    reader.readAsBinaryString(file);
  };

  const handleOnDrop = (files) => {
    setError(false);
    const file = files[0];
    setSelectedFile(file);

    const extension = file.name.split('.').pop();
    switch (extension) {
      case 'csv':
        handleCsv(file);
        break;
      case 'xls':
      case 'xlsx':
        handleXlsx(file);
        break;
      default:
        setError(true);
        break;
    }
  };

  const handleOnRemoveFile = () => {
    setSelectedFile();
    setPreview();
    setData();
  };

  const renderFileIcon = () => {
    const extension = selectedFile?.name.split('.').pop();
    switch (extension) {
      case 'csv':
        return <Icon color="textSecondary" as={FaFileCsv} w={12} h={12} />;
      case 'xls':
      case 'xlsx':
        return <Icon color="#34A853" as={FaFileExcel} w={12} h={12} />;
      default:
        return <Icon color="invalid" as={FaFile} w={12} h={12} />;
    }
  };

  const handleSampleDownload = async (format) => {
    const data = [
      {
        'Employee-ID': 'EMP-0001',
        Name: 'Abir Rahman',
        NID: '0123456789',
        'Birth-Certificate': '',
        'Date-of-Birth': '1/1/1980',
        Gender: 'MALE',
        'Father-Name': '',
        'Mother-Name': '',
        Department: 'Electrical',
        Designation: 'Electrical Engineer',
        'Joining-Date': '1/1/2020',
        'Departure-Date': '',
        'Contact-Number': '0123456789',
        'Present-Address': 'House-1, Road-1, Sector-1, Uttara, Dhaka',
        'Insurance-Coverage': '',
        'Name-of-Nominee': '',
        'Relationship-With-Employee': '',
        Status: 'ACTIVE',
      },
      {
        'Employee-ID': 'EMP-0002',
        Name: 'Touhid Tushar',
        NID: '0123456789',
        'Birth-Certificate': '',
        'Date-of-Birth': '1/1/1980',
        Gender: 'MALE',
        'Father-Name': '',
        'Mother-Name': '',
        Department: 'Electrical',
        Designation: 'Electrical Engineer',
        'Joining-Date': '1/1/2020',
        'Departure-Date': '',
        'Contact-Number': '0123456789',
        'Present-Address': 'House-1, Road-1, Block-A, Mohakhali, Dhaka',
        'Insurance-Coverage': '',
        'Name-of-Nominee': '',
        'Relationship-With-Employee': '',
        Status: 'ACTIVE',
      },
    ];
    const cols = Object.values(labourKeys).map((key) => ({ id: key.name, title: key.name }));
    if (data) {
      // generate worksheet using data with the order specified in the columns array
      const ws = utils.json_to_sheet(data, {
        header: cols.map((c) => c.id ?? c.title),
        raw: true,
      });
      // rewrite header row with titles
      utils.sheet_add_aoa(ws, [cols.map((c) => c.title ?? c.id)], { origin: 'A1' });
      // create workbook
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Sheet1'); // replace with sheet name
      // download file
      writeFileXLSX(wb, `BTMA-Employee-Data-Sample.${format.toLowerCase()}`);
    }
  };

  return (
    <Box>
      <Box mt={4} p={4} bg="white">
        <HStack mb={4} alignItems="end" justifyContent="space-between">
          <Box>
            <Text fontSize="2xl" fontWeight="semibold">
              {reUpload ? null : 'Labour Database'}
            </Text>
            {reUpload ? null : (
              <Text fontSize="xl" color="textSecondary" lineHeight={1}>
                No entries found in labour database for this mill
              </Text>
            )}
          </Box>
          <HStack>
            {selectedFile?.name && (
              <IconButton
                h="50px"
                size="lg"
                bgColor="invalid"
                borderRadius="2px"
                icon={<MdClose />}
                onClick={handleOnRemoveFile}
                sx={{ ':hover': { bgColor: 'invalid' } }}
              />
            )}
            {/* <a href={SAMPLE_CSV_DOWNLOAD_URL} target="_blank" rel="noopener noreferrer" download>
              <Button
                h="50px"
                size="lg"
                as="label"
                minWidth="180px"
                borderRadius="2px"
                leftIcon={<Icon as={MdCloudDownload} w={7} h={7} />}>
                Sample CSV
              </Button>
            </a> */}
            <Menu>
              <MenuButton as={Button} leftIcon={<Icon as={MdCloudDownload} w={7} h={7} />}>
                Sample File
              </MenuButton>
              <MenuList p={1} borderRadius="2px">
                <MenuItem onClick={() => handleSampleDownload('XLS')}>.xls</MenuItem>
                <MenuItem onClick={() => handleSampleDownload('XLSX')}>.xlsx</MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </HStack>
        <Dropzone
          onDrop={handleOnDrop}
          acceptedFiles={[
            '.csv, .xlsx, .xls, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
          ]}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} style={dropzoneStyles.container}>
              <input {...getInputProps()} />
              {selectedFile?.name ? (
                <>
                  {renderFileIcon()}
                  <p style={{ ...dropzoneStyles.fileName, color: error ? '#E53E3E' : '#555' }}>
                    {selectedFile.name}
                  </p>
                  {error && (
                    <p style={dropzoneStyles.error}>
                      Invalid file format. Supported file formats: csv, xls, xlsx
                    </p>
                  )}
                </>
              ) : (
                <>
                  <Icon color="textSecondary" as={MdCloudUpload} w={12} h={12} />
                  <p style={dropzoneStyles.title}>Drop your file here or click to upload</p>
                  <p style={dropzoneStyles.helperText}>Supported file formats: csv, xls, xlsx</p>
                </>
              )}
            </div>
          )}
        </Dropzone>
        <Spacer />
        {reUpload ? (
          <Button
            mb={3}
            mt={2}
            h="40px"
            size="lg"
            as="label"
            minWidth="180px"
            borderRadius="4px"
            onClick={() => setView('TABLE')}>
            Cancel
          </Button>
        ) : null}
      </Box>
      {children}
    </Box>
  );
};

export default UploadData;
