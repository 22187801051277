import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import { MdDelete } from 'react-icons/md';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import {
  Box,
  Text,
  Grid,
  Icon,
  Stack,
  VStack,
  Button,
  HStack,
  Divider,
  GridItem,
  useDisclosure,
} from '@chakra-ui/react';

//common components
import BillDate from '../../BillDate';
import MoneyReceipt from '../MoneyReceipt';
import CostBreakdown from '../CostBreakdown';
import Loader from '../../../snippets/Loader';
import CertificateBill from '../CertificateBill';
import InfoTiles from '../../../common/InfoTiles';
import FormButton from '../../../common/FormButton';
import DialogueBox from '../../../common/DialogueBox';
import MinimalTable from '../../../common/MinimalTable';

//form sections
import PaymentMethod, { paymentMethodFieldsValidation } from './PaymentMethod';
import ChequeInfoSection, { chequeInfoFieldsValidation } from '../NewCertificate/ChequeInfoSection';

//actions & api
import {
  deleteCertificateBatch,
  getCertificateInvoices,
  patchCertificateInvoices,
  getLastAssignedCertificates,
} from '../../../../api/accounts';
import { notify } from '../../../../store/actions/globalActions';
import { FETCH_CERT_PURCHASE_INVOICE } from '../../../../store/actions/actionTypes';

//utils & constants
import { useYupValidationResolver } from '../../../../util/yupUtils';
import { formatDate, renderDate } from '../../../../util/formatUtils';
import { ACCOUNTS_CERTIFICATE_URL } from '../../../../constants/routerUrl';

const CertRequestForDuty = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const rangeErrorRef = useRef();
  const certificateBillRef = useRef();
  const certMoneyReceiptRef = useRef();
  const [print, setPrint] = useState(false);
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState();
  const [method, setMethod] = useState('CASH');
  const [certificates, setCertificates] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [billDate, setBillDate] = useState(new Date());
  const [rangeErrorMsg, setRangeErrorMsg] = useState();
  const [chequePayment, setChequePayment] = useState(false);
  const data = useSelector((state) => state.accounts.currentData);
  const { orderId, certFor } = history?.location?.state;

  const moneyReceiptData = {
    slNo: data?.orderId,
    mrDate: data?.dateSold,
    receivedFrom: data?.millName,
    amount: data?.total,
    paymentMethod: data?.paymentMethod,
    certFor,
    paymentFor: 'Duty-Exemption (Application Fee)',
    chequeNo: data?.chequeNo,
    chequeDated: data?.chequeDated,
    quantity: data?.noOfCopies,
    range: data?.certificateDetails?.[0]?.certificateNum || data?.certRange,
    certNum: data?.certificateDetails?.[0]?.certificateNum,
    total: data?.total,
    narration: data?.narration ? data?.narration : null,
  };

  const millInfo = [
    { header: 'Mill Name', value: data?.millName },
    { header: 'Membership No', value: data?.membershipNo },
    { header: 'Email', value: data?.hoInfo?.millEmail },
    { header: 'Contact', value: data?.hoInfo?.millPhoneNo },
  ];

  const columns = [
    { header: 'Serial', accessor: 'certificateNum' },
    { header: 'Type', accessor: 'type' },
    { header: 'Status', accessor: 'status' },
    { header: 'Used On', accessor: 'usedOn', isDate: true },
    { header: 'Application Status', accessor: 'applicationStatus' },
  ];

  const fetchData = async () => {
    dispatch({ type: FETCH_CERT_PURCHASE_INVOICE, payload: null });
    const res1 = await getCertificateInvoices({ type: certFor, uid: orderId });
    if (res1.data?.status === 200) {
      dispatch({ type: FETCH_CERT_PURCHASE_INVOICE, payload: res1.data });
      setValue('numOfCert', res1?.data?.noOfCopies);
    }

    const res2 = await getLastAssignedCertificates();
    if (res2.data?.status === 200) {
      setCertificates(res2.data);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();

    return () => {
      setCertificates();
      dispatch({ type: FETCH_CERT_PURCHASE_INVOICE, payload: null });
    };
  }, []);

  const getValidationSchema = () => {
    if (data?.paymentStatus === 'UNPAID') {
      if (chequePayment) {
        return yup.object().shape({
          ...paymentMethodFieldsValidation,
          ...chequeInfoFieldsValidation(method),
        });
      } else {
        return yup.object().shape({
          ...paymentMethodFieldsValidation,
        });
      }
    }
  };

  const resolver = useYupValidationResolver(getValidationSchema());

  const {
    reset,
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver, defaultValues: { discount: 0, dateSold: new Date() } });

  const discount = useWatch({ control, name: 'discount' });
  const endRange = useWatch({ control, name: 'endRange' });
  const numOfCert = useWatch({ control, name: 'numOfCert' });
  const startRange = useWatch({ control, name: 'startRange' });
  const paymentMethod = useWatch({ control, name: 'paymentMethod' });

  useEffect(() => {
    setMethod(paymentMethod);

    paymentMethod === 'CASH'
      ? setChequePayment(false)
      : paymentMethod
      ? setChequePayment(true)
      : setChequePayment(false);

    const start = parseInt(startRange);
    const end = parseInt(endRange);
    const qty = parseInt(numOfCert || 1);

    if (start && end) {
      if (end < 0) {
        setRangeErrorMsg('End Range must be a valid number or equal to 0');
      } else if (end > 0) {
        if (end < start) {
          setRangeErrorMsg('End Range can not be smaller than Start Range');
        } else {
          end - start + 1 === qty
            ? setRangeErrorMsg()
            : setRangeErrorMsg('Range does not match requested quantity!');
        }
      } else {
        qty === 1
          ? setRangeErrorMsg()
          : setRangeErrorMsg('Range does not match requested quantity!');
      }
    } else if (start && !end) {
      qty === 1 ? setRangeErrorMsg() : setRangeErrorMsg('Range does not match requested quantity!');
    } else if (!start && end) {
      setRangeErrorMsg();
    } else {
      setRangeErrorMsg();
    }
  }, [paymentMethod, discount, startRange, endRange, numOfCert]);

  useEffect(() => {
    const start = parseInt(startRange);
    const qty = parseInt(numOfCert || 1);
    if (startRange > 0) {
      qty > 1 ? setValue('endRange', start + qty - 1) : setValue('endRange', 0);
    }
  }, [startRange, numOfCert]);

  const onSubmit = (formdata) => {
    setFormData({
      ...formdata,
      unitCost: data?.unitCost,
      productionCertificateType: data?.type,
      dateSold: formatDate(formdata?.dateSold),
      calculatedTotal: data?.dutyCertCost?.total,
      chequeDated: formatDate(formdata?.chequeDated),
    });
    if (rangeErrorMsg) {
      rangeErrorRef.current.scrollIntoView();
    } else {
      setModal('PAYMENT');
      onOpen();
    }
  };

  const confirmSubmit = async () => {
    setIsConfirming(true);
    const res = await patchCertificateInvoices({ type: certFor, uid: orderId, formData });
    if (res.data?.status === 200) {
      reset({ paymentMethod: '', chequeNo: '', chequeDated: '', discount: 0 });
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      (async () => {
        const res = await getCertificateInvoices({ type: certFor, uid: orderId });

        if (res.data?.status === 200) {
          dispatch({ type: FETCH_CERT_PURCHASE_INVOICE, payload: res.data });
        }
      })();
      (async () => {
        const res = await getLastAssignedCertificates();
        if (res.data?.status === 200) {
          setCertificates(res.data);
        }
      })();
      setPrint(true);
      setIsConfirming(false);
      await fetchData();
    }
    setIsConfirming(false);
  };

  const confirmDelete = async () => {
    const batchData = {
      certType: certFor,
      batchId: data?.orderId,
    };

    setIsDeleting(true);
    const res = await deleteCertificateBatch(batchData);
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      setIsDeleting(false);
      onClose();
      history.push(ACCOUNTS_CERTIFICATE_URL);
    }
    setIsDeleting(false);
    onClose();
  };

  const handleDelete = () => {
    setModal('DELETE');
    onOpen();
  };

  const handleBillPrint = () => {
    setBillDate(data?.createdAt || new Date());
    setModal('BILL_DATE');
    onOpen();
  };

  const printCertificateBill = useReactToPrint({
    documentTitle: `BTMA-Bill-${
      data?.type === 'CASH'
        ? 'Cash-Incentive'
        : data?.type === 'GSP'
        ? 'GSP'
        : certFor === 'processing'
        ? 'Processing'
        : certFor === 'duty' || certFor === 'duty_fee'
        ? 'Duty-Exemption'
        : null
    }-Certificate-${data?.orderId}-${renderDate(billDate)}`,
    content: () => certificateBillRef.current,
  });

  const renderModal = () => {
    switch (modal) {
      case 'PAYMENT':
        return (
          <DialogueBox
            close={onClose}
            alertTitle="Confirm"
            mountControl={isOpen}
            performAction={confirmSubmit}
            actionButtonLoading={isConfirming}
            alertMessage="Are you sure you want to submit these information?"
          />
        );

      case 'DELETE':
        return (
          <DialogueBox
            close={onClose}
            onActionKeepOpen
            alertTitle="Confirm"
            mountControl={isOpen}
            performAction={confirmDelete}
            actionButtonLoading={isDeleting}
            alertMessage="Are you sure you want to delete this batch of certificate?"
          />
        );

      case 'BILL_DATE':
        return (
          <DialogueBox
            noCancel
            close={onClose}
            mountControl={isOpen}
            actionButtonText="Okay"
            alertTitle="Issue Date"
            performAction={printCertificateBill}>
            <BillDate setDate={setBillDate} date={billDate} />
          </DialogueBox>
        );

      default:
        return null;
    }
  };

  return data && certFor ? (
    <Box p={4}>
      <InfoTiles sectionName="Mill Information" sectionColumn={4} infoFields={millInfo} />

      <Grid templateColumns="repeat(4, 1fr)" gap={3} p={4} mt={4} bg="white">
        <GridItem colSpan={4}>
          <Text fontSize="20px" fontWeight="bold" mb={1}>
            Last Used Certificate Number
          </Text>
        </GridItem>

        <GridItem
          p={4}
          colSpan={1}
          border="1px"
          bg="secondary"
          borderRadius="4px"
          borderColor="primary.300">
          {certificates ? (
            <>
              <Text fontSize="md" color="textSecondary">
                Production-GSP
              </Text>
              <Text fontSize="xl" mt={1.5}>
                {certificates?.prodGsp || 'N/A'}
              </Text>
            </>
          ) : (
            <Loader />
          )}
        </GridItem>

        <GridItem
          p={4}
          colSpan={1}
          border="1px"
          bg="secondary"
          borderRadius="4px"
          borderColor="primary.300">
          {certificates ? (
            <>
              <Text fontSize="md" color="textSecondary">
                Production-Cash
              </Text>
              <Text fontSize="xl" mt={1.5}>
                {certificates?.prodCash || 'N/A'}
              </Text>
            </>
          ) : (
            <Loader />
          )}
        </GridItem>

        <GridItem
          p={4}
          colSpan={1}
          border="1px"
          bg="secondary"
          borderRadius="4px"
          borderColor="primary.300">
          {certificates ? (
            <>
              <Text fontSize="md" color="textSecondary">
                Processing
              </Text>
              <Text fontSize="xl" mt={1.5}>
                {certificates?.proc || 'N/A'}
              </Text>
            </>
          ) : (
            <Loader />
          )}
        </GridItem>

        <GridItem
          p={4}
          colSpan={1}
          border="1px"
          bg="secondary"
          borderRadius="4px"
          borderColor="primary.300">
          {certificates ? (
            <>
              <Text fontSize="md" color="textSecondary">
                Duty-Exemption
              </Text>
              <Text fontSize="xl" mt={1.5}>
                {certificates?.duty || 'N/A'}
              </Text>
            </>
          ) : (
            <Loader />
          )}
        </GridItem>
      </Grid>

      <HStack mt={4}>
        <Text fontSize="xl" fontWeight="bold">
          Invoice:
        </Text>
        <Text fontSize="xl" fontWeight="bold" color="red">
          <b>{data?.orderId}</b>
        </Text>
      </HStack>

      <Box mt={4}>
        <Text mb={2} fontSize="xl" fontWeight="bold">
          {data?.paymentStatus === 'PAID' ? 'Certificate Information' : 'Assign Certificate'}
        </Text>
        <Divider mb={2} />
        <Grid templateColumns="repeat(5, 1fr)" gap={3}>
          {data?.paymentStatus === 'UNPAID' ? (
            <GridItem
              p={4}
              as="form"
              bg="white"
              colSpan={2}
              borderRadius="4px"
              onSubmit={handleSubmit(onSubmit)}>
              <PaymentMethod register={register} errors={errors} control={control} />
              {chequePayment && (
                <ChequeInfoSection
                  errors={errors}
                  control={control}
                  register={register}
                  method={paymentMethod}
                />
              )}
              <HStack px={3} pb={3} spacing={2} justifyContent="right" alignItems="center">
                <FormButton type="submit" isLoading={isSubmitting}>
                  CONFIRM
                </FormButton>
              </HStack>
            </GridItem>
          ) : (
            <GridItem
              p={4}
              as="form"
              bg="white"
              colSpan={2}
              overflowX="auto"
              borderRadius="4px"
              onSubmit={handleSubmit(onSubmit)}>
              <Text fontSize="1xl" ml={3} mt={3} fontWeight="bold">
                Certificates
              </Text>
              <Box p={3}>
                <MinimalTable scrollable columns={columns} data={data?.certificateDetails} />
              </Box>
              {!data?.user?.adminRole === 'FEE_COLLECTOR' && (
                <VStack alignItems="flex-end" pr={3}>
                  <Button
                    bg="red.500"
                    onClick={handleDelete}
                    _hover={{ bg: 'red.400' }}
                    _focus={{ boxShadow: 'none' }}
                    rightIcon={<Icon as={MdDelete} w={5} h={5} />}
                    isDisabled={
                      data?.certificateDetails?.filter((item) => item?.status === 'USED')?.length >
                      0
                        ? true
                        : false
                    }>
                    DELETE
                  </Button>
                  {data?.certificateDetails?.filter((item) => item?.status === 'USED')?.length >
                  0 ? (
                    <Text fontSize="xs" color="invalid" textAlign="right">
                      *** You cannot delete this entry because some of the certificates of this
                      batch are already used!
                    </Text>
                  ) : null}
                </VStack>
              )}
            </GridItem>
          )}

          <GridItem colSpan={3} p={4} bg="white" borderRadius="4px">
            <Box p={3} maxW="45vw" overflowX="auto">
              <CostBreakdown
                data={{
                  ...data,
                  noOfCopies: 1,
                  total: data.unitCost,
                  certFor: 'Duty-Exemption (Application Fee)',
                  range: data?.certificateDetails?.[0]?.certificateNum || 'N/A',
                }}
              />
            </Box>
            <Stack flexDir="column" alignItems="flex-end" mt={1} p={3}>
              <HStack>
                <FormButton mt={0} onClick={handleBillPrint}>
                  PRINT BILL
                </FormButton>
                <Box display="none">
                  <CertificateBill
                    title="Certificate"
                    ref={certificateBillRef}
                    data={{
                      billDate,
                      billNo: data?.orderId,
                      millName: data?.millName,
                      millAddress: data?.hoInfo?.hoName,
                    }}
                    costs={{
                      ...data,
                      noOfCopies: 1,
                      total: data.unitCost,
                      certFor: 'DUTY EXEMPTION (APPLICATION FEE)',
                      range: data?.certificateDetails?.[0]?.certificateNum || 'N/A',
                      serial: data?.certificateDetails?.[0]?.certificateNum || 'N/A',
                    }}
                  />
                </Box>

                <ReactToPrint
                  documentTitle={`BTMA-Money-Receipt-Duty-Exemption (Application Fee)-${
                    data?.orderId
                  }-${renderDate(data?.dateSold)}`}
                  trigger={() => (
                    <FormButton isDisabled={data?.paymentStatus === 'PAID' ? false : !print}>
                      PRINT RECEIPT
                    </FormButton>
                  )}
                  content={() => certMoneyReceiptRef.current}
                />

                <Box display="none">
                  <MoneyReceipt ref={certMoneyReceiptRef} data={moneyReceiptData} />
                </Box>
              </HStack>
            </Stack>
          </GridItem>
        </Grid>
      </Box>
      {renderModal()}
    </Box>
  ) : (
    <Loader />
  );
};

export default CertRequestForDuty;
