//modules
import React, { useState } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Grid, GridItem, useDisclosure, Box } from '@chakra-ui/react';

//common
import DialogueBox from '../../../../common/DialogueBox';
import Footer from '../../../../common/membershipFormComponents/Footer';
import MembershipFormWrapper from '../../../../common/membershipFormComponents/MembershipFormWrapper';

//form sections
import GasSection, { gasFieldsValidation } from './sections/GasSection';
import OtherFuel, { otherFuelFieldsValidation } from './sections/OtherFuel';
import DieselSection, { dieselFieldsValidation } from './sections/DieselSection';
import SanctionLoan, { sanctionFieldsValidation } from './sections/SanctionLoan';

//api and actions
import { addMember } from '../../../../../api/membership';
import { notify } from '../../../../../store/actions/globalActions';

//utils
import _ from 'lodash';
import { useYupValidationResolver } from '../../../../../util/yupUtils';
import { getFormData, removeFormData, setFormData } from '../../../../../util/localStorageUtils';

const formValidationSchema = yup.object().shape({
  ...gasFieldsValidation,
  ...dieselFieldsValidation,
  ...sanctionFieldsValidation,
  ...otherFuelFieldsValidation,
});

const PowerMang = ({ backToTable, prevStep, currStep, stepList, isDataStored }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formStepA = getFormData('addMemberStepA');
  const formStepB = getFormData('addMemberStepB');
  const formStepC = getFormData('addMemberStepC');
  const formStepD = getFormData('addMemberStepD');

  const resolver = useYupValidationResolver(formValidationSchema);

  const defaultFields = { ...formStepD?.powerManagementSummary };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: { ...defaultFields },
  });

  const handleBackToTable = () => {
    if (isDataStored()) {
      setModal('BackToTableAlert');
      onOpen();
    } else {
      backToTable();
    }
  };

  const prepareData = () => {
    const sendData = {
      ...formStepA,
      yarnManufacturerSummary: {
        ...formStepB?.yarnManu,
        ...formStepC?.yarnRawReq,
        ...formStepC?.yarnRawCon,
      },
      fabricsManufacturerSummary: {
        ...formStepB?.fabricManu,
        ...formStepC?.fabricRaw,
      },
      textileProductProcessorSummary: {
        ...formStepB?.textileManu,
        ...formStepC?.textileRaw,
      },
      humanResources: { ...formStepC?.humanResources },
      ...formStepD,
    };

    return sendData;
  };

  const onForwardSubmit = (data) => {
    setFormData('addMemberStepD', { powerManagementSummary: data });
    setModal('SubmitAlert');
    onOpen();
  };

  const onBackwardSubmit = (data) => {
    if (_.isEqual(defaultFields, data) === false) {
      setFormData('addMemberStepD', { powerManagementSummary: data });
      prevStep();
    } else {
      prevStep();
    }
  };

  const confirmSubmit = async () => {
    const res = await addMember(prepareData());
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      removeFormData('addMemberStepA');
      removeFormData('addMemberStepB');
      removeFormData('addMemberStepC');
      removeFormData('addMemberStepD');
      backToTable();
    }
  };

  return (
    <Box>
      <MembershipFormWrapper formName="General Information">
        <Grid templateColumns="repeat(2, 1fr)" gap={3}>
          <GridItem p={2} bg="white" borderRadius="4px" colSpan={2}>
            <GasSection register={register} errors={errors} control={control} />
          </GridItem>
          <GridItem p={2} bg="white" borderRadius="4px" colSpan={2}>
            <DieselSection register={register} errors={errors} control={control} />
          </GridItem>
          <GridItem p={2} bg="white" borderRadius="4px" colSpan={1}>
            <OtherFuel register={register} errors={errors} control={control} />
          </GridItem>
          <GridItem p={2} bg="white" borderRadius="4px" colSpan={1}>
            <SanctionLoan register={register} errors={errors} control={control} />
          </GridItem>
        </Grid>
      </MembershipFormWrapper>

      <Footer
        addNew
        currStep={currStep}
        stepList={stepList}
        back={handleBackToTable}
        buttonLoading={isSubmitting}
        next={(event) => {
          event.preventDefault();
          handleSubmit(onForwardSubmit)();
        }}
        prev={(event) => {
          event.preventDefault();
          handleSubmit(onBackwardSubmit)();
        }}
      />

      <DialogueBox
        close={onClose}
        mountControl={isOpen}
        alertTitle={modal === 'SubmitAlert' ? 'Confirm' : 'Unsaved Changes'}
        alertMessage={
          modal === 'SubmitAlert'
            ? 'Are you sure you want to register and verify information for this member?'
            : 'You have unsaved changes! Are you sure you want to leave this page?'
        }
        performAction={
          modal === 'SubmitAlert'
            ? confirmSubmit
            : () => {
                removeFormData('addMemberStepA');
                removeFormData('addMemberStepB');
                removeFormData('addMemberStepC');
                removeFormData('addMemberStepD');
                backToTable();
              }
        }
      />
    </Box>
  );
};

export default PowerMang;
