import React from 'react';
import * as yup from 'yup';
import FormSection from '../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'dutyAThreshold',
    type: 'text',
    label: 'Spare Parts Threshold',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'dutyAX',
    type: 'text',
    label: 'Spare Parts Cost (below threshold)',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'dutyAY',
    type: 'text',
    label: 'Spare Parts Cost (above threshold)',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'dutyAFactor',
    type: 'text',
    label: 'Spare Parts Factor (per ? unit)',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'dutyBThreshold',
    type: 'text',
    label: 'Chemicals Threshold',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'dutyBX',
    type: 'text',
    label: 'Chemicals Cost (below threshold)',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'dutyBY',
    type: 'text',
    label: 'Chemicals Cost (above threshold)',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'dutyBFactor',
    type: 'text',
    label: 'Chemicals Factor (per ? unit)',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'dutyCThreshold',
    type: 'text',
    label: 'PSF Threshold',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'dutyCY',
    type: 'text',
    label: 'PSF Cost (below threshold)',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'dutyCX',
    type: 'text',
    label: 'PSF Cost (above threshold)',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'dutyCFactor',
    type: 'text',
    label: 'PSF Factor (per ? unit)',
    isRequired: true,
  },
];

export const dutyCertfieldsValidation = {
  [fields[0].name]: yup.number().required().label(fields[0].label),
  [fields[1].name]: yup.number().required().label(fields[1].label),
  [fields[2].name]: yup.number().required().label(fields[2].label),
  [fields[3].name]: yup.number().required().label(fields[3].label),
  [fields[4].name]: yup.number().required().label(fields[4].label),
  [fields[5].name]: yup.number().required().label(fields[5].label),
  [fields[6].name]: yup.number().required().label(fields[6].label),
  [fields[7].name]: yup.number().required().label(fields[7].label),
  [fields[8].name]: yup.number().required().label(fields[8].label),
  [fields[9].name]: yup.number().required().label(fields[9].label),
  [fields[10].name]: yup.number().required().label(fields[10].label),
  [fields[11].name]: yup.number().required().label(fields[11].label),
};

const DutyCertSection = (props) => {
  return (
    <FormSection sectionName="Duty-Exemption" sectionColumn={4} formFields={fields} {...props} />
  );
};

export default DutyCertSection;
