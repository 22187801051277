import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { getAllNotification, retryNow, retrySchedule } from '../../../api/notifications';
import {
  LOGS_TABLE_DISPLAY_SIZE,
  NOTIFICATION_CONTEXT,
  NOTIFICATION_STATUS,
  NOTIFICATION_TYPE,
} from '../../../constants/dataTableConstants';
import DataTable from '../../common/dataTable/DataTable';
import { useDispatch } from 'react-redux';
import { notify } from '../../../store/actions/globalActions';
import FormButton from '../../common/FormButton';

const PAGE_SIZE = 10;
const Notifications = () => {
  const [data, setData] = useState();
  // const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');

  const dispatch = useDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedObject, setSelectedObject] = useState(null);

  const columns = [
    {
      isCheckBox: true,
      accessor: 'id',
    },
    {
      header: 'Created Date',
      accessor: 'createdAt',
      isDate: true,
    },
    {
      header: 'Sent Date',
      accessor: 'sentat',
      isDate: true,
      includeTime: true,
    },
    {
      header: 'Representative',
      accessor: 'representative',
      isHover: true,
      row: function OrderItems(rowData) {
        return (
          <VStack>
            <Text>{rowData.requiredData?.millName}</Text>
            <Text>Invoice: {rowData.requiredData?.invoice}</Text>
            <Text>Certificate No: {rowData.requiredData?.certificateNum}</Text>
            <Text>{rowData.phone}</Text>
            <Text>{rowData.email}</Text>
          </VStack>
        );
      },
    },

    {
      header: 'Type',
      accessor: 'type',
    },
    {
      header: 'Context',
      accessor: 'context',
    },
    {
      header: 'Status',
      accessor: 'status',
    },

    {
      header: 'Action',
      isButton: true,
      actionScheme: [{ variant: 'read', action: 'VIEW' }],
    },
  ];

  const fetchData = async (
    localPage,
    localSearchKey = searchKey,
    localPageSize = PAGE_SIZE,
    type = null,
    status = null,
    context = null,
    startDate = null,
    endDate = null,
  ) => {
    const res = await getAllNotification({
      params: {
        page: localPage,
        pageSize: localPageSize,
        pageLess: false,
        searchKey: localSearchKey,
        type: type,
        status: status,
        context: context,
        sentAtStart: startDate ? new Date(startDate).toISOString() : null,
        sentAtEnd: endDate ? new Date(endDate).toISOString() : null,
      },
    });
    if (res.data?.status === 200) {
      setData(res.data);
    }
  };

  const retryNowFunc = async (retryAll) => {
    const res = await retryNow({
      ids: [selectedObject?.id],
      retryAll: retryAll,
    });
    if (res.data.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );

      setSelectedObject(null);
      fetchData(1);
      onClose();
    }
  };

  const retryScheduleFunc = async (ids, clearSelection) => {
    const res = await retrySchedule({
      ids: ids,
    });
    if (res.data.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );

      setSelectedObject(null);
      fetchData(1);
      if (clearSelection) {
        clearSelection();
      }
      onClose();
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const handleSearch = async ({ searchKey, selected, startDate, endDate }) => {
    setSearchKey(searchKey);
    fetchData(
      data?.page,
      searchKey,
      data?.pageSize,
      selected?.Type,
      selected?.Status,
      selected?.Context,
      startDate,
      endDate,
    );
  };

  const handlePagination = async ({ page, searchKey, pageSize }) => {
    // setPage(page);
    fetchData(page, searchKey, pageSize);
  };

  const handleView = async ({ rowObject }) => {
    setSelectedObject(rowObject);
    onOpen();
  };

  const handleSelectedAction = async (ids, clearSelection) => {
    retryScheduleFunc(ids, clearSelection);
  };

  return (
    <Box p={4}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedObject?.representative}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="start">
              <Text>{selectedObject?.requiredData?.millName}</Text>
              <Text>Invoice: {selectedObject?.requiredData?.invoice}</Text>
              <Text>Certificate No: {selectedObject?.requiredData?.certificateNum}</Text>
              {selectedObject?.smsparts && <Text>Sms parts: {selectedObject?.smsparts}</Text>}

              {selectedObject?.requiredData?.smsText && (
                <Box borderWidth="1px" borderRadius="lg" p={4}>
                  <div style={{ whiteSpace: 'pre-wrap' }}>
                    {selectedObject?.requiredData?.smsText}
                  </div>
                </Box>
              )}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="green"
              mr={3}
              onClick={() => retryNowFunc(false)}
              disabled={selectedObject?.status !== 'FAILED'}>
              Retry now
            </Button>
            <Button
              colorScheme="purple"
              mr={3}
              onClick={() => retryScheduleFunc([selectedObject.id])}
              disabled={selectedObject?.status !== 'FAILED'}>
              Schedule retry
            </Button>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <HStack mb={4} p={0} justify="space-between">
        <HStack>
          <FormButton mt={0} onClick={() => retryNowFunc(true)}>
            Retry all now
          </FormButton>
          <FormButton mt={0} onClick={() => retryScheduleFunc([])}>
            Reschedule all now
          </FormButton>
        </HStack>
        <Box>
          <Text fontSize="2xl">Balance: {data?.data?.balance || 0} Tk</Text>
        </Box>
      </HStack>
      <DataTable
        topPanel
        keySearch
        isPaginated
        dateRange
        columns={columns}
        data={data?.data?.notifications}
        search={handleSearch}
        actions={{ VIEW: handleView }}
        caption="Notifications"
        currentPage={data?.page}
        paginate={handlePagination}
        entries={data?.data?.total}
        currentPageSize={data?.pageSize}
        rowSelectScheme={['CUSTOM', 'Retry Selected']}
        onActionClick={handleSelectedAction}
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
        selectOptions={[NOTIFICATION_TYPE, NOTIFICATION_CONTEXT, NOTIFICATION_STATUS]}
      />
    </Box>
  );
};

export default Notifications;
