import { Box, HStack, Text } from '@chakra-ui/react';
import commaNumber from 'comma-number';
import React from 'react';
import PrintReceiptHeader from '../snippets/PrintReceiptHeader';
import './printStyle.css';

const styles = {
  headerStyle: {
    fontSize: '14px',
    fontWeight: 'bold',
    padding: '0px 5px',
    textAlign: 'center',
    border: '1px solid black',
    borderCollapse: 'collapse',
  },
  cellStyle: {
    fontSize: '14px',
    textAlign: 'center',
    padding: '0px 5px',
    border: '1px solid black',
    borderCollapse: 'collapse',
  },
  cellStyleForName: {
    fontSize: '14px',
    textAlign: 'center',
    padding: '0px 5px',
    border: '1px solid black',
    borderCollapse: 'collapse',
    maxWidth: '100px',
  },
};

const EodLogs = React.forwardRef(({ data, date, selections }, ref) => (
  <Box w="100%" ref={ref}>
    <PrintReceiptHeader data={data} />
    {/* <pre>{JSON.stringify(data, null, 2).replace(/"/g, '')}</pre> */}
    <HStack spacing={0} w="100%" justifyContent="center">
      <Text fontSize="2xl" fontWeight="bold" pb={4}>
        BTMA Logs
      </Text>
    </HStack>
    <HStack spacing={0} w="100%" justifyContent="right">
      <Text fontWeight="bold">Date:</Text>
      <Text
        px={2}
        minW="140px"
        fontSize="lg"
        fontWeight="bold"
        textAlign="center"
        borderStyle="dotted"
        borderBottom="2px solid black">
        {date}
      </Text>
    </HStack>
    {selections?.issueCheckedItems?.length > 0 && (
      <Text fontSize="16px" fontWeight="bold" my={2}>
        #Certificate Issuance Summary:
      </Text>
    )}
    {data?.map(
      (item, index) =>
        selections?.issueCheckedItems?.includes(item.title) && (
          <>
            <Box key={index} p={4}>
              <HStack w="100%" justifyContent="space-between" borderBottom="2px solid black" mb={1}>
                <Text fontSize="16px" fontWeight="bold">
                  {item?.title}
                </Text>
                <Text fontSize="16px">
                  <b>Total Issued:</b> {item?.issued}
                </Text>
              </HStack>

              {item?.issueDetails?.length > 0 ? (
                <table
                  style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
                  <thead>
                    <tr>
                      <th style={styles.headerStyle}>Sl. No.</th>
                      <th style={styles.headerStyle}>Issuer</th>
                      <th style={styles.headerStyle}>Role</th>
                      <th style={styles.headerStyle}>Copies</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.issueDetails.map((issuer, rowIndex) => (
                      <tr key={rowIndex}>
                        <td style={styles.cellStyle}>{rowIndex + 1}</td>
                        <td style={{ ...styles.cellStyleForName, textAlign: 'left' }}>
                          {issuer?.fullName}
                        </td>
                        <td style={styles.cellStyle}>{issuer?.adminRole?.replace(/_/g, ' ')}</td>
                        <td style={styles.cellStyle}>{issuer?.count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </Box>
          </>
        ),
    )}
    {/* {selections?.issueCheckedItems?.length > 0 ? (
      <Text fontSize="16px" fontWeight="bold" mb={4} textAlign="right">
        Total no. of certificates issued: {getGrandTotalIssue(data)}
      </Text>
    ) : null} */}
    {selections?.salesCheckedItems?.length > 0 ? (
      <>
        {selections?.issueCheckedItems?.length > 0 ? <div className="page-break" /> : null}
        <Text fontSize="16px" fontWeight="bold" my={6} mb={2}>
          #Certificate Sales Summary:
        </Text>
      </>
    ) : null}
    {data?.map((item, index) =>
      selections?.salesCheckedItems?.includes(item.title) ? (
        <>
          <Box key={index} p={4}>
            <HStack w="100%" justifyContent="space-between" borderBottom="2px solid black" mb={1}>
              <Text fontSize="16px" fontWeight="bold">
                {item?.title}
              </Text>
              <Text fontSize="16px">
                <b>Total Sold: </b> {item?.sold || 0}
                {item.title === 'Duty-Exemption' ? ' transactions' : ' copies'}
              </Text>
            </HStack>

            {item?.saleDetails?.length > 0 ? (
              <table
                style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                  <tr>
                    <th style={styles.headerStyle}>Sl. No.</th>
                    <th style={styles.headerStyle}>Issuer</th>
                    <th style={styles.headerStyle}>Role</th>
                    <th style={styles.headerStyle}>Copies</th>
                    <th style={styles.headerStyle}>Sub-total (৳)</th>
                  </tr>
                </thead>
                <tbody>
                  {item.saleDetails.map((sale, rowIndex) => (
                    <tr key={rowIndex}>
                      <td style={styles.cellStyle}>{rowIndex + 1}</td>
                      <td style={{ ...styles.cellStyleForName, textAlign: 'left' }}>
                        {sale?.fullName}
                      </td>
                      <td style={styles.cellStyle}>{sale?.adminRole?.replace(/_/g, ' ')}</td>
                      <td style={styles.cellStyle}>{sale?.count}</td>
                      <td style={styles.cellStyle}>{sale?.subtotal}</td>
                    </tr>
                  ))}
                  <tr>
                    <td
                      style={{
                        ...styles.cellStyle,
                        textAlign: 'center',
                        fontWeight: '600',
                        fontSize: '20px',
                      }}>
                      Total:
                    </td>
                    <td style={styles.cellStyle} />
                    <td style={styles.cellStyle} />
                    <td style={styles.cellStyle} />
                    <td
                      style={{
                        ...styles.cellStyle,
                        textAlign: 'center',
                        fontWeight: '600',
                        fontSize: '20px',
                      }}>
                      ৳{commaNumber(item?.revenue)}
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : null}
          </Box>
        </>
      ) : null,
    )}
    {/* {selections?.salesCheckedItems?.length > 0 ? (
      <>
        <Text fontSize="16px" fontWeight="bold" textAlign="right">
          Total no. of certificates sold: {getGrandTotalSold(data)}
        </Text>
        <Text fontSize="16px" fontWeight="bold" textAlign="right">
          Total revenue from certificates sold: ৳{getGrandTotalRevenue(data)}
        </Text>
      </>
    ) : null} */}

    <style type="text/css" media="print">
      {'\
          @page { size: landscape; }\
      '}
    </style>
  </Box>
));

EodLogs.displayName = 'EodLogs';

export default EodLogs;
