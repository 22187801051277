import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../../common/hookFormComponents/FormSection';
import { mobileNoValidation } from '../../../../../../util/yupUtils';

const fields = [
  {
    colSpan: 2,
    name: 'msAddress',
    type: 'text',
    isRequired: true,
    label: 'Factory Location',
  },
  {
    colSpan: 1,
    name: 'msTotalArea',
    type: 'text',
    placeholder: 'Area in katha',
    label: 'Total Factory Area',
  },
  {
    colSpan: 1,
    name: 'msTotalBuilding',
    type: 'text',
    placeholder: 'Area in sq. ft',
    label: 'Total Factory Buliding',
  },
  {
    colSpan: 1,
    name: 'msEmail',
    type: 'email',
    label: 'Email',
  },
  {
    colSpan: 1,
    name: 'msPhoneNo',
    type: 'text',
    label: 'Phone',
  },
];

export const millSiteInfoFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup.string().label(fields[1].label),
  [fields[2].name]: yup.string().label(fields[2].label),
  [fields[3].name]: yup.string().email().label(fields[3].label),
  [fields[4].name]: mobileNoValidation.label(fields[4].label),
};

const MillSiteInfo = (props) => {
  return (
    <FormSection
      autoFill
      autoFillMsg="Same as Head Office Information"
      sectionName="Mill Site Information"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default MillSiteInfo;
