import React from 'react';
import { FormControl, Text, Input, Tooltip } from '@chakra-ui/react';

const ResetPassword = ({ label, name, isRequired, placeholder, register, errors, customError }) => {
  return (
    <FormControl
      id={name}
      isRequired={isRequired}
      isInvalid={(errors && errors[name]) || customError}>
      <Text
        mb={1.5}
        fontSize="sm"
        overflow="hidden"
        whiteSpace="nowrap"
        color="textSecondary"
        textOverflow="ellipsis">
        {isRequired ? <b style={{ color: '#E53E3E' }}>* </b> : null}
        {label}
      </Text>
      <Input
        h="50px"
        name={name}
        bg="secondary"
        type="password"
        variant="outline"
        borderRadius="4px"
        placeholder={placeholder}
        focusBorderColor="primary.300"
        {...register(name)}
      />
      <Tooltip
        label={
          errors[name]?.message?.length > 32 || customError?.length > 32
            ? errors[name]?.message || customError
            : ''
        }
        area-label="A Tooltip"
        placement="bottom-start">
        <Text mb={1.5} fontSize="xs" color="invalid">
          {errors[name]?.message?.substring(0, 32) || customError?.substring(0, 32)}
          {errors[name]?.message?.length > 32 || customError?.length > 32 ? '...' : ''}
        </Text>
      </Tooltip>
    </FormControl>
  );
};

export default ResetPassword;
