import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'fabWoven',
    type: 'text',
    label: 'Fabric-Woven(mtr)',
  },
  {
    colSpan: 1,
    name: 'fabKnit',
    type: 'text',
    label: 'Fabric-knit(kg)',
  },
  {
    colSpan: 1,
    name: 'yarns',
    type: 'text',
    label: 'Yarn',
  },
  {
    colSpan: 1,
    name: 'dice',
    type: 'text',
    label: 'Dice(kg)',
  },
  {
    colSpan: 1,
    name: 'chemical',
    type: 'text',
    label: 'Chemical(kg)',
  },
];

export const rawMatReqCFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label),
  [fields[1].name]: yup.string().label(fields[1].label),
  [fields[2].name]: yup.string().label(fields[2].label),
  [fields[3].name]: yup.string().label(fields[3].label),
  [fields[4].name]: yup.string().label(fields[4].label),
};

const RawMatReqC = (props) => {
  return (
    <FormSection
      sectionName="Raw Material Requirement"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default RawMatReqC;
