import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    type: 'text',
    name: 'startRange',
    label: 'Start Range',
    isRequired: true,
  },
  {
    colSpan: 1,
    type: 'text',
    name: 'endRange',
    label: 'End Range',
  },
];

export const certRangeFieldsValidation = {
  [fields[0].name]: yup
    .number()
    .typeError(`${fields[0].label} must be a number`)
    .required()
    .positive()
    .integer()
    .label(fields[0].label),
  [fields[1].name]: yup
    .number()
    .typeError(`${fields[1].label} must be a number`)
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    })
    .integer()
    .label(fields[1].label),
};

const CertRange = (props) => {
  return (
    <FormSection sectionName="Certificate Range" sectionColumn={2} formFields={fields} {...props} />
  );
};

export default CertRange;
