import React from 'react';
import * as yup from 'yup';
import FieldArrayFormSection from '../../../common/hookFormComponents/FieldArrayFormSection';

const fields = [
  {
    colSpan: 2,
    name: 'name',
    type: 'text',
    label: 'Name',
  },
  {
    colSpan: 1,
    name: 'quantity',
    type: 'text',
    label: 'Quantity',
  },
  {
    colSpan: 1,
    name: 'unitPrice',
    type: 'text',
    label: 'Unit Price',
  },
];

export const defaultYarnArrayFieldValues = fields.reduce(
  (obj, item) => [{ ...obj, [item['name']]: '' }],
  [{}],
);

export const yarnsCountsFieldsValidation = {
  yarnCount: yup.array().of(
    yup.object().shape({
      [fields[0].name]: yup.string().label(fields[0].label),
      [fields[1].name]: yup.string().label(fields[1].label),
      [fields[2].name]: yup.string().label(fields[2].label),
    }),
  ),
};

const YarnCountSection = (props) => {
  return (
    <FieldArrayFormSection
      sectionName="Yarn Count"
      sectionColumn={4}
      name="yarnCount"
      formFields={fields}
      {...props}
    />
  );
};

export default YarnCountSection;
