import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'autoconed',
    type: 'text',
    label: 'Auto Cone (Quantity)',
  },
  {
    colSpan: 1,
    name: 'nonAutoconed',
    type: 'text',
    label: 'Non-Auto Cone (Quantity)',
  },
  {
    colSpan: 1,
    name: 'towelsOthers',
    type: 'text',
    label: 'Towels and Others (In KGS)',
  },
  {
    colSpan: 1,
    name: 'openEnd',
    type: 'text',
    label: 'Open End (In KGS)',
  },
  {
    colSpan: 1,
    name: 'fabric',
    type: 'text',
    label: 'Fabrics (In Meter/Yard)',
  },
  {
    colSpan: 1,
    name: 'polyester',
    type: 'text',
    label: 'Polyester (In KGS)',
  },
  {
    colSpan: 1,
    name: 'synthetic',
    type: 'text',
    label: 'Synthetic (In KGS)',
  },
];

export const yarnOtherInfoFieldsValidation = {
  [fields[0].name]: yup
    .number()
    .typeError(`${fields[0].label} must be a number`)
    .transform((cv, ov) => {
      return !ov ? undefined : cv;
    }),
  [fields[1].name]: yup
    .number()
    .typeError(`${fields[1].label} must be a number`)
    .transform((cv, ov) => {
      return !ov ? undefined : cv;
    }),
  [fields[2].name]: yup
    .number()
    .typeError(`${fields[2].label} must be a number`)
    .transform((cv, ov) => {
      return !ov ? undefined : cv;
    }),
  [fields[3].name]: yup
    .number()
    .typeError(`${fields[3].label} must be a number`)
    .transform((cv, ov) => {
      return !ov ? undefined : cv;
    }),
  [fields[4].name]: yup
    .number()
    .typeError(`${fields[4].label} must be a number`)
    .transform((cv, ov) => {
      return !ov ? undefined : cv;
    }),
  [fields[5].name]: yup
    .number()
    .typeError(`${fields[5].label} must be a number`)
    .transform((cv, ov) => {
      return !ov ? undefined : cv;
    }),
  [fields[6].name]: yup
    .number()
    .typeError(`${fields[6].label} must be a number`)
    .transform((cv, ov) => {
      return !ov ? undefined : cv;
    }),
};

const YarnOtherInfoSection = (props) => {
  return (
    <FormSection
      sectionName="Required Information"
      sectionColumn={4}
      formFields={fields}
      {...props}
    />
  );
};

export default YarnOtherInfoSection;
