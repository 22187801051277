import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'apparel',
    type: 'text',
    label: 'Apparel (In KGS)',
  },
  {
    colSpan: 1,
    name: 'homeFurnishing',
    type: 'text',
    label: 'Home Furnishing (In KGS)',
  },
  {
    colSpan: 1,
    name: 'towelsOthers',
    type: 'text',
    label: 'Towels and Others (In KGS)',
  },
  {
    colSpan: 1,
    name: 'fabric',
    type: 'text',
    label: 'Fabrics (In Meter/Yard)',
  },
  {
    colSpan: 1,
    name: 'quantityOthers',
    type: 'text',
    label: 'Others (Quantity)',
  },
];

export const fabricOtherInfoFieldsValidation = {
  [fields[0].name]: yup
    .number()
    .typeError(`${fields[0].label} must be a number`)
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    }),
  [fields[1].name]: yup
    .number()
    .typeError(`${fields[1].label} must be a number`)
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    }),
  [fields[2].name]: yup
    .number()
    .typeError(`${fields[2].label} must be a number`)
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    }),
  [fields[3].name]: yup
    .number()
    .typeError(`${fields[3].label} must be a number`)
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    }),
  [fields[4].name]: yup
    .number()
    .typeError(`${fields[4].label} must be a number`)
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    }),
};

const FabricOtherInfoSection = (props) => {
  return (
    <FormSection
      sectionName="Required Information"
      sectionColumn={5}
      formFields={fields}
      {...props}
    />
  );
};

export default FabricOtherInfoSection;
