import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { useDispatch } from 'react-redux';
import PrintPreview from './PrintPreview';
import { CgPrinter } from 'react-icons/cg';
import { useHistory } from 'react-router-dom';
import { GrAttachment } from 'react-icons/gr';
import { renderDate } from '../../../util/formatUtils';
import { notify } from '../../../store/actions/globalActions';
import { MEMBER_INFO_URL } from '../../../constants/routerUrl';
import { resolveUpdateRequest } from '../../../api/membership';
import { Box, Text, Button, HStack, Icon } from '@chakra-ui/react';

const UpdateRequestDetails = ({ data, refresh, closeModal }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const updateRequestRef = useRef();

  const handleReject = async () => {
    const res = await resolveUpdateRequest({ requestId: data?.id, status: 'REJECTED' });
    if (res.data?.status === 200) {
      refresh();
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      closeModal();
    }
  };

  return (
    <Box my={2}>
      <HStack w="60vw" minW="800px">
        <Button
          minW="120px"
          bg="#CFCCE6"
          height="40px"
          color="#150381"
          borderRadius="0px"
          _hover={{
            bg: '#CFCCE6',
            color: '#150381',
            borderBottom: '3px solid #150381',
          }}
          onClick={() => history.push(`${MEMBER_INFO_URL}/${data.millUid}`)}>
          Go to Mill Details Page
        </Button>
        {data?.status === 'PENDING' ? (
          <Button
            minW="120px"
            bg="#ECDBD9"
            height="40px"
            color="#9B4236"
            borderRadius="0px"
            onClick={handleReject}
            _focus={{ boxShadow: 'none' }}
            _hover={{ borderBottom: '3px solid #9B4236' }}>
            Reject Request
          </Button>
        ) : null}
        <ReactToPrint
          documentTitle={`BTMA-Money-Receipt-Duty-Exemption-Certificate-${
            data?.orderId
          }-${renderDate(data?.dateSold)}`}
          trigger={() => <Button rightIcon={<Icon as={CgPrinter} w={5} h={5} />}>Print</Button>}
          content={() => updateRequestRef.current}
        />
        <Box display="none">
          <PrintPreview ref={updateRequestRef} data={data} />
        </Box>
      </HStack>
      <Box bg="secondary" mt={2} p={2} shadow="xs" minH="20vh" maxH="55vh" overflow="auto">
        <Text>{data?.message}</Text>
      </Box>
      {data?.attachment ? (
        <Text color="primary.700" textAlign="right" mt={2}>
          <Icon as={GrAttachment} />
          <a href={data?.attachment} target="_blank" rel="noreferrer" draggable>
            attachment
          </a>
        </Text>
      ) : null}
    </Box>
  );
};

export default UpdateRequestDetails;
