export const getConversionRates = async () => {
  let axios = require('axios').default;
  let options = {
    method: 'GET',
    headers: {
      'x-rapidapi-host': process.env.REACT_APP_RAPID_API_HOST,
      'x-rapidapi-key': process.env.REACT_APP_RAPID_API_KEY,
    },
  };
  let paramList = ['USD', 'EUR', 'GBP', 'INR', 'JPY', 'CNY', 'CHF'];
  let exchangeRates = { BDT: 1 };

  try {
    await Promise.all(
      paramList.map(async (item) => {
        try {
          const response = await axios.request({
            ...options,
            url: `${process.env.REACT_APP_RAPID_API_URL}/${item}`,
          });
          exchangeRates = {
            ...exchangeRates,
            [item]: response.data?.rates?.BDT,
          };
        } catch (err) {
          console.error(err);
        }
      }),
    );

    return { data: exchangeRates, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export const getConversionRate = (curr, conversions) => {
  switch (curr) {
    case 'USD':
      return conversions?.USD;
    case 'EURO':
      return conversions?.EUR;
    case 'POUND':
      return conversions?.GBP;
    case 'RUPEE':
      return conversions?.INR;
    case 'YEN':
      return conversions?.JPY;
    case 'YAUN':
      return conversions?.CNY;
    case 'CHF':
      return conversions?.CHF;
    case 'BDT':
      return conversions?.BDT;
    default:
      return 1;
  }
};
