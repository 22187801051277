import React, { useState } from 'react';
import { Box, HStack, RadioGroup, Radio, Text, CloseButton, Divider } from '@chakra-ui/react';
import SearchSection from '../../common/SearchSection';
import UploadData from './csvUploader/UploadData';
import PreviewTable from './csvUploader/PreviewTable';
import ErrorView from './csvUploader/ErrorView';
import GuideLinesTable from './csvUploader/GuideLinesTable';
import AddLabourerForm from './singleUploader/AddLabourerForm';

const AddNewData = () => {
  const [mill, setMill] = useState();
  const [addType, setAddType] = useState();
  const [data, setData] = useState();
  const [tempData, setTempData] = useState();
  const [preview, setPreview] = useState();

  return (
    <Box p={4}>
      <Box w="50%">
        <SearchSection minimal setSelectedMill={setMill} />
        {mill?.id ? (
          <>
            <HStack>
              <Text mt={1} color="primary.400" mb={1.5}>
                {mill?.millName}
              </Text>
              <CloseButton
                size="sm"
                _focus={{ boxShadow: '0 0 0 3px #9280ff' }}
                onClick={() => {
                  setMill();
                }}
              />
            </HStack>
            <RadioGroup onChange={setAddType} value={addType}>
              <Box display="flex" flexDir="column" alignItems="flex-start" alignSelf="flex-start">
                <Text bg="whiteAlpha.400" fontSize="lg" color="textSecondary" mt={4}>
                  Choose a method to add new labour data
                </Text>
                <HStack h="50px">
                  {[
                    { name: 'Single', value: 'A' },
                    { name: 'Bulk', value: 'B' },
                  ].map((c, index) => (
                    <Radio key={index} colorScheme="primary" value={c.value}>
                      {c.name}
                    </Radio>
                  ))}
                </HStack>
              </Box>
            </RadioGroup>
          </>
        ) : null}
      </Box>
      <Divider mt={2} />

      {mill?.id ? (
        addType === 'A' ? (
          <AddLabourerForm millId={mill?.id} />
        ) : addType === 'B' ? (
          <>
            <UploadData setData={setData} setTempData={setTempData} setPreview={setPreview}>
              {preview === 'PREVIEW' ? null : <GuideLinesTable />}
            </UploadData>
            {preview === 'PREVIEW' ? (
              <PreviewTable data={data} mill={mill} />
            ) : preview === 'ERROR' ? (
              <ErrorView errors={data} data={tempData} />
            ) : null}
          </>
        ) : null
      ) : null}
    </Box>
  );
};

export default AddNewData;
