import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'numberDiesel',
    type: 'text',
    label: 'Number',
  },
  {
    colSpan: 1,
    name: 'countryOfOriginDiesel',
    type: 'text',
    label: 'Country of Origin',
  },
  {
    colSpan: 1,
    name: 'capacityDiesel',
    type: 'text',
    label: 'Capacity (KW/Hour)',
  },
];

export const dieselFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label),
  [fields[1].name]: yup.string().label(fields[1].label),
  [fields[2].name]: yup.string().label(fields[2].label),
};

const DieselSection = (props) => {
  return <FormSection sectionName="Diesel" sectionColumn={3} formFields={fields} {...props} />;
};

export default DieselSection;
