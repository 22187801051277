import React from 'react';
import * as yup from 'yup';
import { dateValidation } from '../../../../../util/yupUtils';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    fieldType: 'date',
    name: 'issueDate',
    label: 'Issue Date',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'paidAmount',
    type: 'text',
    label: 'Amount Received',
    isRequired: true,
  },
  {
    fieldType: 'select',
    colSpan: 1,
    label: 'Payment Method',
    isRequired: true,
    name: 'paymentMethod',
    choices: [
      { name: 'Cash', value: 'CASH' },
      { name: 'Cheque', value: 'CHEQUE' },
      { name: 'EFT', value: 'EFT' },
      { name: 'MFS', value: 'MFS' },
    ],
  },
];

export const paymentFieldsValidation = (maxVal, testVal) => {
  if (testVal) {
    return {
      [fields[0].name]: dateValidation.required().label(fields[0].label),
      [fields[1].name]: yup
        .number()
        .typeError(`${fields[1].label} must be a number`)
        .test(
          'Paid-Amount',
          `${fields[1].label} must be equal to total payable amount`,
          (val) => val === testVal,
        )
        .required()
        .label(fields[1].label),
      [fields[2].name]: yup.string().required().label(fields[2].label),
    };
  } else {
    return {
      [fields[0].name]: dateValidation.required().label(fields[0].label),
      [fields[1].name]: yup
        .number()
        .typeError(`${fields[1].label} must be a number`)
        .integer()
        .positive()
        .max(maxVal)
        .required()
        .label(fields[1].label),
      [fields[2].name]: yup.string().required().label(fields[2].label),
    };
  }
};

const PaymentSection = (props) => {
  return <FormSection secondary sectionColumn={3} formFields={fields} {...props} />;
};

export default PaymentSection;
