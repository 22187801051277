import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'yarn',
    type: 'text',
    label: 'Yarn (Tons)',
  },
  {
    colSpan: 1,
    name: 'fabrics',
    type: 'text',
    label: 'Fabrics (Yard/Meter)',
  },
  {
    colSpan: 1,
    name: 'dyes',
    type: 'text',
    label: 'Dyes (Tons)',
  },
  {
    colSpan: 1,
    name: 'chemicals',
    type: 'text',
    label: 'Chemicals (Tons)',
  },
];

export const RawMaterialRequirementFieldsValidation = {
  [fields[0].name]: yup.string(),
  [fields[1].name]: yup.string(),
  [fields[2].name]: yup.string(),
  [fields[3].name]: yup.string(),
};

const RawMaterialRequirementSection = (props) => {
  return (
    <FormSection
      sectionName="Raw Material Requirement"
      sectionColumn={4}
      formFields={fields}
      {...props}
    />
  );
};

export default RawMaterialRequirementSection;
