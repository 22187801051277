import React from 'react';
import * as yup from 'yup';
import FieldArrayFormSection from '../../../../common/hookFormComponents/FieldArrayFormSection';

const fields = [
  {
    colSpan: 1,
    name: 'typeOfFabric',
    type: 'text',
    label: 'Fabric Type',
  },
  {
    colSpan: 1,
    name: 'gsm',
    type: 'text',
    label: 'GSM',
  },
];

export const defaultTypesOfFabricFieldValues = fields.reduce(
  (obj, item) => [{ ...obj, [item['name']]: '' }],
  [{}],
);

export const TypesOfFabricFieldsValidation = {
  typesOfFabric: yup.array().of(
    yup.object().shape({
      [fields[0].name]: yup.string().label(fields[0].label),
      [fields[1].name]: yup.string().label(fields[1].label),
    }),
  ),
};

const TypesOfFabricSection = (props) => {
  return (
    <FieldArrayFormSection
      sectionColumn={3}
      sectionName="Types of Fabric"
      name="typesOfFabric"
      formFields={fields}
      {...props}
    />
  );
};

export default TypesOfFabricSection;
