import React from 'react';
import { dateValidation } from '../../../util/yupUtils';
import FormSection from '../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    fieldType: 'date',
    name: 'issueDate',
  },
];

export const issueDateValidation = {
  [fields[0].name]: dateValidation.required().label('Issue Date'),
};

const IssueDateSection = (props) => {
  return <FormSection sectionName="Issue Date" sectionColumn={3} formFields={fields} {...props} />;
};

export default IssueDateSection;
