import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'sponsors',
    type: 'text',
    label: 'Sponsors (%)',
  },
  {
    colSpan: 1,
    name: 'bankFinance',
    type: 'text',
    label: 'Bank Finance (Name of Bank with %)',
  },
  {
    colSpan: 1,
    name: 'publicIssue',
    type: 'text',
    label: 'Public Issue (%)',
  },
  {
    colSpan: 1,
    name: 'participationShareMarket',
    type: 'text',
    label: 'Participation in Share Market',
  },
  {
    colSpan: 1,
    name: 'workingCapital',
    type: 'text',
    label: 'Working Capital Provided By',
  },
  {
    colSpan: 1,
    name: 'etp',
    type: 'text',
    label: 'ETP',
  },
  {
    colSpan: 1,
    name: 'other',
    type: 'text',
    label: 'Other',
  },
  {
    colSpan: 1,
    name: 'totalCostOfProject',
    type: 'text',
    label: 'Total Cost of the Project',
  },
];

export const sourceOfCapitalFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label),
  [fields[1].name]: yup.string().label(fields[1].label),
  [fields[2].name]: yup.string().label(fields[2].label),
  [fields[3].name]: yup.string().label(fields[3].label),
  [fields[4].name]: yup.string().label(fields[4].label),
  [fields[5].name]: yup.string().label(fields[5].label),
  [fields[6].name]: yup.string().label(fields[6].label),
  [fields[7].name]: yup.string().label(fields[7].label),
};

const SourceOfCapitalSection = (props) => {
  return (
    <FormSection sectionName="Source of Capital" sectionColumn={4} formFields={fields} {...props} />
  );
};

export default SourceOfCapitalSection;
