import React from 'react';
import * as yup from 'yup';
import FormSection from '../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'yarnThreshold',
    type: 'text',
    label: 'Yarn Threshold',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'unitPriceYarnX',
    type: 'text',
    label: 'Yarn Cost (below threshold)',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'unitPriceYarnY',
    type: 'text',
    label: 'Yarn Cost (above threshold)',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'unitFactorYarn',
    type: 'text',
    label: 'Yarn Factor (per ? unit)',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'wovenThreshold',
    type: 'text',
    label: 'Woven Threshold',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'unitPriceWovenX',
    type: 'text',
    label: 'Woven Cost (below threshold)',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'unitPriceWovenY',
    type: 'text',
    label: 'Woven Cost (above threshold)',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'unitFactorWoven',
    type: 'text',
    label: 'Woven Factor (per ? unit)',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'tKnitThreshold',
    type: 'text',
    label: 'Knit Threshold',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'unitPriceTKnitX',
    type: 'text',
    label: 'Knit Cost (below threshold)',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'unitPriceTKnitY',
    type: 'text',
    label: 'Knit Cost (above threshold)',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'unitFactorTKnit',
    type: 'text',
    label: 'Knit Factor (per ? unit)',
    isRequired: true,
  },
];

export const factorsCfieldsValidation = {
  [fields[0].name]: yup.number().required().label(fields[0].label),
  [fields[1].name]: yup.number().required().label(fields[1].label),
  [fields[2].name]: yup.number().required().label(fields[2].label),
  [fields[3].name]: yup.number().required().label(fields[3].label),
  [fields[4].name]: yup.number().required().label(fields[4].label),
  [fields[5].name]: yup.number().required().label(fields[5].label),
  [fields[6].name]: yup.number().required().label(fields[6].label),
  [fields[7].name]: yup.number().required().label(fields[7].label),
  [fields[8].name]: yup.number().required().label(fields[8].label),
  [fields[9].name]: yup.number().required().label(fields[9].label),
  [fields[10].name]: yup.number().required().label(fields[10].label),
  [fields[11].name]: yup.number().required().label(fields[11].label),
};

const FactorsCSection = (props) => {
  return <FormSection sectionName="Category-C" sectionColumn={4} formFields={fields} {...props} />;
};

export default FactorsCSection;
