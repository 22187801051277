export const USERNAME_MIN_LENGTH = 8;
export const PASSWORD_MIN_LENGTH = 6;
export const OTP_VALIDITY_TIME_IN_SECONDS = 180;
export const INACTIVITY_TIMEOUT = 1200000; // 20 minutes

export const SYS_ADMIN_USER_ID = 'manush@456';
export const ACCOUNTS_HEAD_USER_ID = 'BTMA@ACC1';
export const ACCOUNTS_NAHAR_USER_ID = 'BTMA-7598';

export const adminRoles = {
  SUPERADMIN: 'SUPERADMIN',
  ADMIN: 'ADMIN',
  ACCOUNTS: 'ACCOUNTS',
  OPERATIONS: 'OPERATIONS',
};

export const minPassLengthMap = {
  [adminRoles.SUPERADMIN]: 12,
  [adminRoles.ADMIN]: 10,
  [adminRoles.ACCOUNTS]: 10,
  [adminRoles.OPERATIONS]: 10,
};
