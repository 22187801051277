import React from 'react';
import { Box, Button, HStack } from '@chakra-ui/react';

const Footer = ({
  addNew,
  reject,
  back,
  rework,
  next,
  prev,
  currStep,
  stepList,
  buttonLoading,
}) => {
  return (
    <Box
      px={2}
      h="6.5vh"
      bg="white"
      maxH="80px"
      minH="50px"
      zIndex={99}
      bottom="0px"
      position="sticky"
      id="footerWrapper"
      boxShadow="0px -2px 3px rgba(0,0,0,0.2)">
      <HStack h="100%" justifyContent={addNew ? 'flex-end' : 'space-between'} alignItems="center">
        {!addNew && (
          <Button
            minH="35px"
            maxH="45px"
            bg="#ECDBD9"
            minW="120px"
            height="70%"
            onClick={reject}
            color="#9B4236"
            borderRadius="0px"
            isLoading={buttonLoading}
            _focus={{ boxShadow: 'none' }}
            _hover={{ borderBottom: '3px solid #9B4236' }}>
            Reject
          </Button>
        )}
        <HStack>
          <Button
            minH="35px"
            maxH="45px"
            bg="#ECDBD9"
            minW="120px"
            height="70%"
            onClick={back}
            color="#9B4236"
            borderRadius="0px"
            isLoading={buttonLoading}
            _focus={{ boxShadow: 'none' }}
            _hover={{ borderBottom: '3px solid #9B4236' }}>
            Quit
          </Button>
          {rework ? (
            <Button
              minH="35px"
              maxH="45px"
              bg="#E8E5E2"
              minW="120px"
              height="70%"
              color="#94702B"
              onClick={rework}
              borderRadius="0px"
              isLoading={buttonLoading}
              _focus={{ boxShadow: 'none' }}
              _hover={{ borderBottom: '3px solid #94702B' }}>
              Rework
            </Button>
          ) : null}
          <Button
            minH="35px"
            maxH="45px"
            bg="#CFCCE6"
            minW="120px"
            height="70%"
            onClick={prev}
            color="#150381"
            borderRadius="0px"
            isLoading={buttonLoading}
            isDisabled={currStep == 0}
            _hover={{
              bg: '#CFCCE6',
              borderBottom: '3px solid #150381',
            }}>
            Previous
          </Button>
          <Button
            minH="35px"
            maxH="45px"
            height="70%"
            minW="120px"
            type="submit"
            onClick={next}
            borderRadius="0px"
            isLoading={buttonLoading}
            bg={currStep == stepList?.length - 1 ? '#C1ECD2' : '#CFCCE6'}
            color={currStep == stepList?.length - 1 ? '#276749' : '#150381'}
            _hover={{
              borderBottom:
                currStep == stepList?.length - 1 ? '3px solid #276749' : '3px solid #150381',
            }}>
            {currStep == stepList?.length - 1 ? 'Verify' : 'Next'}
          </Button>
        </HStack>
      </HStack>
    </Box>
  );
};

export default Footer;
