import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'annualProductionCapacity',
    type: 'text',
    label: 'Capacity (In Tons)',
  },
  {
    colSpan: 1,
    name: 'annualRequirementOfYarn',
    type: 'text',
    label: 'Annual Requirement of Yarn (In Tons)',
  },
  {
    colSpan: 1,
    name: 'mainItemsManufactured',
    type: 'text',
    label: 'Main Items Manufactured',
  },
];

export const HosAnnualCapFieldsValidation = {
  [fields[0].name]: yup.string(),
  [fields[1].name]: yup.string(),
  [fields[2].name]: yup.string(),
};

const HosAnnualCapSection = (props) => {
  return (
    <FormSection
      sectionName="Annual Production Capacity"
      sectionColumn={3}
      formFields={fields}
      {...props}
    />
  );
};

export default HosAnnualCapSection;
