import { CloseIcon, Icon } from '@chakra-ui/icons';
import { Badge, Box, Grid, GridItem, HStack, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import SearchSection from '../../common/SearchSection';
import FormButton from '../../common/FormButton';
import { getMillRepresentative } from '../../../api/millUsers';
import { FETCH_MILL_REPRESENTATIVE } from '../../../store/actions/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import MillRepresentativeCreateModal from './MillRepresentativeCreateModal';
import AddMillRepresentative from './AddMillRepresentative';
import { CgChevronRightO } from 'react-icons/cg';
import RepresentativeInfoForm from './RepresentativeInfoForm';

const MillRepresentative = () => {
  const [selectedMill, setSelectedMill] = useState();
  const dispatch = useDispatch();
  const [title, setTitle] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalComponenet, setModalComponent] = useState();
  const data = useSelector((state) => state.membership.millRepresentatives);

  const handleQuery = async () => {
    const res = await getMillRepresentative(selectedMill?.uid);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_MILL_REPRESENTATIVE, payload: res.data });
    } else {
      dispatch({ type: FETCH_MILL_REPRESENTATIVE, payload: { representatives: [] } });
    }
  };

  useEffect(() => {
    if (selectedMill) {
      handleQuery();
    }
  }, [selectedMill]);

  const handleCreate = () => {
    setTitle('Create Mill Representative');
    setModalComponent(
      <AddMillRepresentative
        millUid={selectedMill.uid}
        closeModal={onClose}
        refresh={handleQuery}
      />,
    );
    onOpen();
  };

  const handleView = (rep) => {
    setTitle('Representative Info');
    setModalComponent(
      <RepresentativeInfoForm data={rep} closeModal={onClose} refresh={handleQuery} />,
    );
    onOpen();
  };

  return (
    <Box>
      <SearchSection setSelectedMill={setSelectedMill} />
      <Grid gap={3} p={4} m={5} bg="white" borderRadius="4px">
        <GridItem colSpan={12}>
          <Box
            p={4}
            zIndex={1}
            border="1px"
            bg="secondary"
            borderRadius="4px"
            position="relative"
            borderStyle="dashed"
            borderColor="primary.300">
            {selectedMill?.id ? (
              <CloseIcon
                right={3}
                cursor="pointer"
                position="absolute"
                onClick={() => setSelectedMill()}
              />
            ) : null}
            <Text fontSize="md" color="textSecondary">
              Selected Mill
            </Text>
            <Text fontSize="xl" mt={1.5}>
              {selectedMill?.millName || 'None'}
            </Text>
          </Box>
        </GridItem>
        {selectedMill ? null : (
          <GridItem colSpan={4}>
            <Text fontSize="sm" color="invalid">
              Please Select a Mill
            </Text>
          </GridItem>
        )}
      </Grid>
      {data &&
        data.representatives.length > 0 &&
        data.representatives.map((rep, index) => (
          <Box key={index} w="400px" p={6} bg="white" shadow="lg" borderRadius="md" m={5}>
            <HStack alignItems="flex-end">
              <Text fontSize="2xl" fontWeight="bold" lineHeight="1">
                {rep?.name}{' '}
              </Text>
              <Badge colorScheme={rep?.status === 'ACTIVE' ? 'green' : 'red'} variant="outline">
                {rep?.status}
              </Badge>
            </HStack>
            <HStack alignItems="flex-end" justifyContent="space-between" mt={5}>
              <Box>
                <Text>Email: {rep?.email}</Text>

                <Text>Contact: {rep?.phone}</Text>
              </Box>
              <Tooltip label="View User" aria-label="View User" placement="bottom-start">
                <Icon
                  w={8}
                  h={8}
                  color="green"
                  cursor="pointer"
                  borderRadius="50%"
                  onClick={() => handleView(rep)}
                  as={CgChevronRightO}
                />
              </Tooltip>
            </HStack>
          </Box>
        ))}

      {selectedMill && data && data.representatives.length === 0 && (
        <Box m={5} bg="white" p={4}>
          <Text fontSize="md" color="textSecondary" mb={2}>
            There is no representative for this mill
          </Text>
          <FormButton mt={0} onClick={handleCreate}>
            Create Represantative
          </FormButton>
        </Box>
      )}
      <MillRepresentativeCreateModal modalTitle={title} mountControl={isOpen} close={onClose}>
        {modalComponenet}
      </MillRepresentativeCreateModal>
    </Box>
  );
};

export default MillRepresentative;
