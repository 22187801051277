import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'cottonYarnKnit',
    type: 'text',
    label: 'Cotton Yarn (Tons)',
  },
  {
    colSpan: 1,
    name: 'syntheticYarnKnit',
    type: 'text',
    label: 'Synthetic Yarn (Tons)',
  },
  {
    colSpan: 1,
    name: 'polyesterYarnKnit',
    type: 'text',
    label: 'Polyester Yarn (Tons)',
  },
  {
    colSpan: 1,
    name: 'viscousYarnKnit',
    type: 'text',
    label: 'Viscous Yarn (Tons)',
  },
  {
    colSpan: 1,
    name: 'tencelYarnKnit',
    type: 'text',
    label: 'Tencel Yarn (Tons)',
  },
  {
    colSpan: 1,
    name: 'flaxYarnKnit',
    type: 'text',
    label: 'Flax Yarn (Tons)',
  },
];

export const KnitRawMatFieldsValidation = {
  [fields[0].name]: yup.string(),
  [fields[1].name]: yup.string(),
  [fields[3].name]: yup.string(),
  [fields[4].name]: yup.string(),
  [fields[5].name]: yup.string(),
};

const KnitRawMatSection = (props) => {
  return (
    <FormSection
      sectionName="Raw Material Requirement"
      sectionColumn={3}
      formFields={fields}
      {...props}
    />
  );
};

export default KnitRawMatSection;
