import React from 'react';
import * as yup from 'yup';
import { mobileNoValidation } from '../../../../util/yupUtils';
import FormSection from '../../../common/hookFormComponents/FormSection';
import { FULL_NAME_REGEX, USERNAME_REGEX } from '../../../../constants/regex';

const fields = [
  {
    colSpan: 1,
    name: 'fullName',
    type: 'text',
    label: 'Full Name',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'username',
    type: 'text',
    label: 'Username',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'phoneNo',
    type: 'text',
    label: 'Phone No',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'email',
    type: 'text',
    label: 'Email',
    isRequired: true,
  },
  {
    colSpan: 2,
    fieldType: 'radio',
    name: 'userStatus',
    label: 'User Status',
    isRequired: true,
    choices: [
      { name: 'Active', value: 'ACTIVE' },
      { name: 'Disabled', value: 'DISABLED' },
    ],
  },
];

export const fieldsValidation = {
  [fields[0].name]: yup
    .string()
    .matches(FULL_NAME_REGEX, 'Not a valid name')
    .required()
    .label(fields[0].label),
  [fields[1].name]: yup
    .string()
    .matches(USERNAME_REGEX, 'Not a valid username')
    .required()
    .label(fields[1].label),
  [fields[2].name]: mobileNoValidation.required().label(fields[2].label),
  [fields[3].name]: yup.string().email().required().label(fields[3].label),
  [fields[4].name]: yup.string().required().label(fields[4].label),
};

const UserInfoSection = (props) => {
  return <FormSection sectionColumn={2} formFields={fields} {...props} />;
};

export default UserInfoSection;
