import * as yup from 'yup';
import {
  NID_NO_REGEX,
  PHONE_NO_REGEX,
  BIRTH_CERTIFICATE_REGEX,
} from '../constants/formValidationRegex';
import { formatDate } from './formatUtils';
import { jsonToCSV } from 'react-papaparse';
import { labourKeyNames, labourKeys } from '../constants/labourDatabaseConstant';

export const nidNoValidation = yup
  .string()
  .matches(NID_NO_REGEX, 'Not a valid NID, must be a valid number');

export const birthCertificateValidation = yup
  .string()
  .matches(BIRTH_CERTIFICATE_REGEX, 'Not a valid birth certificate, must be a valid number');

export const validateCsv = (parsedData) => {
  let errors = [];
  let validData = [];

  /* checks if the keys in the parsed csv file are valid */
  const checkKeys = (data) => {
    let areKeysValid = true;
    let invalidKeys = [];
    const keys = Object.keys(data)?.filter((key) => key);
    for (let key of keys) {
      if (!labourKeyNames().includes(key)) {
        invalidKeys.push(key);
        areKeysValid = false;
      }
    }
    return { areKeysValid, invalidKeys };
  };

  /* checks if the required fields are available in the parsed csv file */
  const requiredValidity = (data) => {
    let validity = true;
    for (let key in labourKeys) {
      if (labourKeys[key].isRequired && !data[labourKeys[key].name]) {
        validity = false;
      }
    }
    if (!data[labourKeys.NID.name] && !data[labourKeys.BIRTH_CERT.name]) {
      validity = false;
    }
    return validity;
  };

  /* validates gender type values */
  const validateGender = (gender) => {
    if (
      gender.toUpperCase() === 'MALE' ||
      gender.toUpperCase() === 'FEMALE' ||
      gender.toUpperCase() === 'OTHER'
    ) {
      return true;
    } else {
      return false;
    }
  };

  /* validates date in any given format */
  const validateDate = (dateString) => {
    if (
      !dateString ||
      dateString === '' ||
      typeof dateString !== 'string' ||
      dateString?.toUpperCase() === 'NA' ||
      dateString?.toUpperCase() === 'N/A'
    ) {
      if (typeof dateString === 'number') {
        // convert excel date sequence to date string
        // 1/1/1900 is the first day of the excel date sequence
        // 1/1/1900 is the 2nd day of the javascript date sequence
        // 1/1/1970 is the 1st day of the javascript date object
        // 25569 is the number of days between 1/1/1900 and 1/1/1970
        return new Date((dateString - 25569) * 86400 * 1000).toISOString().slice(0, 10);
      }
      return 'N/A';
    }
    let delimiter = '';

    for (let char of dateString) {
      try {
        if (isNaN(parseInt(char))) {
          delimiter = char;
          break;
        }
      } catch (e) {
        delimiter = char;
        break;
      }
    }

    if (delimiter === '') {
      return false;
    }

    if (delimiter !== '-') {
      RegExp.quote = function (str) {
        return str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
      };
      const re = new RegExp(RegExp.quote(delimiter), 'g');
      dateString = dateString.replace(re, '-');
    }

    const dateParts = dateString.split('-');
    if (dateParts[dateParts.length - 1]?.length !== 4) {
      const currentYear = new Date().getFullYear();
      const tempYear = parseInt(
        `${currentYear.toString().substring(0, 2)}${dateParts[dateParts.length - 1]}`,
      );
      dateParts[dateParts.length - 1] = tempYear > currentYear ? tempYear - 100 : tempYear;
    }
    dateString = dateParts[1] + '/' + dateParts[0] + '/' + dateParts[2];

    return isNaN(Date.parse(dateString)) ? false : formatDate(new Date(dateString));
  };

  /* validates employee status values */
  const validateStatus = (status) => {
    if (status) {
      if (status.toUpperCase() === 'ACTIVE' || status.toUpperCase() === 'INACTIVE') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  /* checks for duplicate entries and keeps the first one */
  const doesExist = (data) => {
    if (data) {
      validData?.map((item) => {
        if (data === item.birthCertNum || data === item.nid) {
          return true;
        } else {
          return false;
        }
      });
    }
  };

  if (checkKeys(parsedData?.[0])?.areKeysValid) {
    const nonEmptyRows = parsedData?.filter((row) => {
      for (let key in row) {
        if (row?.[key]) {
          return row;
        }
      }
    });

    nonEmptyRows?.map((data, idx) => {
      if (data) {
        if (requiredValidity(data)) {
          //required fields available
          if (
            data[labourKeys.NAME.name].length > 2 &&
            (data[labourKeys.NID.name]?.toString()?.match(NID_NO_REGEX) ||
              data[labourKeys.BIRTH_CERT.name]?.toString()?.match(BIRTH_CERTIFICATE_REGEX)) &&
            validateDate(data[labourKeys.DOB.name]) &&
            validateGender(data[labourKeys.GENDER.name]) &&
            validateDate(data[labourKeys.JOINING.name]) &&
            validateDate(data[labourKeys.DEPARTURE.name]) &&
            data[labourKeys.CONTACT.name]?.toString()?.match(PHONE_NO_REGEX) &&
            validateStatus(data[labourKeys.STATUS.name])
          ) {
            if (!doesExist(data[labourKeys.NID] || data[labourKeys.BIRTH_CERT])) {
              let sendData = {};
              for (let key in labourKeys) {
                if (labourKeys[key].isDate) {
                  try {
                    sendData[labourKeys[key].dbKey] =
                      validateDate(data[labourKeys[key].name]) === 'N/A'
                        ? null
                        : validateDate(data[labourKeys[key].name]);
                  } catch (error) {
                    sendData[labourKeys[key].dbKey] = null;
                  }
                } else {
                  sendData[labourKeys[key].dbKey] = data[labourKeys[key].name];
                }
              }
              validData.push(sendData);
            }
          } else {
            //field(s) invalid
            if (!data[labourKeys.NAME.name]?.length > 2) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.NAME.column,
                msg: `Name is too short at row ${idx + 1}, must be at least 3 characters long`,
              };
              errors.push(errorObject);
            }
            if (
              data[labourKeys.NID.name] &&
              !data[labourKeys.NID.name]?.toString()?.match(NID_NO_REGEX)
            ) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.NID.column,
                msg: `NID is invalid at row ${idx + 1}, must be a valid number`,
              };
              errors.push(errorObject);
            }
            if (
              data[labourKeys.BIRTH_CERT.name] &&
              !data[labourKeys.BIRTH_CERT.name]?.toString()?.match(BIRTH_CERTIFICATE_REGEX)
            ) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.BIRTH_CERT.column,
                msg: `Birth Certificate is invalid at row ${idx + 1}, must be a valid number`,
              };
              errors.push(errorObject);
            }
            if (!validateDate(data[labourKeys.DOB.name])) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.DOB.column,
                msg: `Date of Birth is invalid at row ${
                  idx + 1
                }, must be in DD/MM/YYYY or DD-MM-YYYY or DD.MM.YYYY format`,
              };
              errors.push(errorObject);
            }
            if (!validateGender(data[labourKeys.GENDER.name])) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.GENDER.column,
                msg: `Gender is invalid at row ${idx + 1}, must be male, female or other`,
              };
              errors.push(errorObject);
            }
            if (!validateDate(data[labourKeys.JOINING.name])) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.JOINING.column,
                msg: `Joining Date is invalid at row ${
                  idx + 1
                }, must be in DD/MM/YYYY or DD-MM-YYYY or DD.MM.YYYY format`,
              };
              errors.push(errorObject);
            }
            if (!validateDate(data[labourKeys.DEPARTURE.name])) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.DEPARTURE.column,
                msg: `Departaure Date is invalid at row ${
                  idx + 1
                }, must be in DD/MM/YYYY or DD-MM-YYYY or DD.MM.YYYY format`,
              };
              errors.push(errorObject);
            }
            if (!data[labourKeys.CONTACT.name]?.toString()?.match(PHONE_NO_REGEX)) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.CONTACT.column,
                msg: `Contact No is invalid at row ${idx + 1}, enter a valid phone number`,
              };
              errors.push(errorObject);
            }
            if (!validateStatus(data[labourKeys.STATUS.name])) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys.STATUS.column,
                msg: `Status is invalid at row ${idx + 1}, must be active or inactive`,
              };
              errors.push(errorObject);
            }
          }
        } else {
          //required field(s) missing
          if (!data[labourKeys.NID.name] && !data[labourKeys.BIRTH_CERT.name]) {
            let errorObject = {
              index: idx + 1,
              colIndex: [labourKeys.NID.column, labourKeys.BIRTH_CERT.column],
              msg: `NID and Birth Certificate No is missing at row ${
                idx + 1
              }, provide at least one`,
            };
            errors.push(errorObject);
          }

          for (let key in labourKeys) {
            if (labourKeys[key].isRequired && !data[labourKeys[key].name]) {
              let errorObject = {
                index: idx + 1,
                colIndex: labourKeys[key].column,
                msg: `${labourKeys[key].name} is missing at row ${idx + 1}`,
              };
              errors.push(errorObject);
            }
          }
        }
      }
    });
  } else {
    let errorObject = {
      index: null,
      colIndex: null,
      msg: `File With Invalid Column(s) Uploaded\nInvalid columns: ${checkKeys(
        parsedData?.[0],
      )?.invalidKeys?.toString()}`,
    };
    errors.push(errorObject);
  }

  return { valid: errors?.length > 0 ? false : true, errors, validData };
};

export const convertToCSV = (data) => {
  try {
    const csv = jsonToCSV(data);
    const blob = new Blob([csv]);
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob, { type: 'text/csv;charset=utf-8;' });
    a.download = 'Employee-Export-File.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    return 'File downloaded!';
  } catch (error) {
    return error;
  }
};
