import React from 'react';
import * as yup from 'yup';
import FormSection from '../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'certType',
    fieldType: 'select',
    label: 'Certificate type',
    choices: [
      { name: 'GSP', value: 'PRODUCTION_GSP' },
      { name: 'Processing', value: 'PROCESSING' },
      { name: 'Cash-Incentive', value: 'PRODUCTION_CASH' },
      { name: 'Duty-Exemption', value: 'DUTY' },
    ],
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'certificateNum',
    type: 'text',
    label: 'Certificate Number',
    isRequired: true,
  },
];

export const rejectFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup
    .number()
    .typeError(`${fields[1].label} must be a number`)
    .positive()
    .integer()
    .required()
    .label(fields[1].label),
};

const RejectInfoSection = (props) => {
  return (
    <FormSection
      sectionName="Select Certificate"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default RejectInfoSection;
