import React, { useEffect, useState } from 'react';
import { TiExport } from 'react-icons/ti';
import { useHistory } from 'react-router';
import { utils, writeFileXLSX } from 'xlsx';
import FormButton from '../common/FormButton';
import DialogueBox from '../common/DialogueBox';
import { AiOutlineFileDone } from 'react-icons/ai';
import SearchSection from '../common/SearchSection';
import DataTable from '../common/dataTable/DataTable';
import { MdAdd, MdFileDownload } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from '../../store/actions/globalActions';
import { FETCH_LABOUR_DATA } from '../../store/actions/actionTypes';
import { generateRandomString, renderDate } from '../../util/formatUtils';
import { ADD_LABOUR_URL, LABOUR_DETAILS_URL } from '../../constants/routerUrl';
import { deleteLabourData, getLabourData, downloadLabourData } from '../../api/labour';
import {
  Box,
  Icon,
  Text,
  Menu,
  HStack,
  Button,
  Spinner,
  MenuList,
  MenuItem,
  MenuButton,
  CloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { labourKeys, LABOURER_GENDER_TYPES } from '../../constants/labourDatabaseConstant';
import { BADGE_STATE_COLORS, LOGS_TABLE_DISPLAY_SIZE } from '../../constants/dataTableConstants';

const LabourTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [mill, setMill] = useState();
  const [modal, setModal] = useState();
  const [alertType, setAlertType] = useState();
  const [deleteList, setDeleteList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [exportFormat, setExportFormat] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const data = useSelector((state) => state.labour.allLabour);

  useEffect(() => {
    setMill();
  }, [isOpen]);

  //configure columns
  const columns = [
    {
      isCheckBox: true,
      accessor: 'id',
    },
    {
      isFile: true,
      header: 'Image',
      accessor: 'employeeImage',
    },
    {
      header: 'Mill Name',
      accessor: 'millName',
    },
    { header: 'Employee-ID', accessor: 'millEmployeeId' },
    { header: 'Name', accessor: 'name' },
    { header: 'NID', accessor: 'nid' },
    { header: 'Birth-Certificate', accessor: 'birthCertNum' },
    { isDate: true, header: 'Date-of-Birth', accessor: 'dateOfBirth' },
    { header: 'Age', accessor: 'age' },
    { header: 'Gender', accessor: 'gender' },
    { header: 'Father-Name', accessor: 'fatherName' },
    { header: 'Mother-Name', accessor: 'motherName' },
    { header: 'Department', accessor: 'department' },
    { header: 'Designation', accessor: 'designation' },
    { isDate: true, header: 'Joining-Date', accessor: 'joiningDate' },
    { isDate: true, header: 'Departure-Date', accessor: 'departureDate' },
    { header: 'Contact-Number', accessor: 'contactNo' },
    { header: 'Present-Address', accessor: 'presentAddress' },
    { header: 'Insurance-Coverage', accessor: 'insuranceCoverage' },
    { header: 'Name-of-Nominee', accessor: 'nominee' },
    { header: 'Relationship-With-Employee', accessor: 'relation' },
    { isBadge: true, header: 'Status', accessor: 'status', colorScheme: BADGE_STATE_COLORS },
    {
      isButton: true,
      header: 'Action',
      accessor: 'status',
      actionScheme: [
        { group: 'ACTIVE', variant: 'read', action: 'VIEW' },
        { group: 'INACTIVE', variant: 'read', action: 'VIEW' },
      ],
    },
  ];

  const handlePagination = async ({
    page,
    status,
    endDate,
    pageSize,
    selected,
    searchKey,
    startDate,
  }) => {
    dispatch({ type: FETCH_LABOUR_DATA, payload: null });
    const params = {
      page,
      status,
      endDate,
      pageSize,
      searchKey,
      startDate,
      gender: selected?.['Gender'],
    };
    const res = await getLabourData(params);
    if (res.data) {
      dispatch({ type: FETCH_LABOUR_DATA, payload: res.data });
    } else {
      dispatch({ type: FETCH_LABOUR_DATA, payload: { labourData: [] } });
    }
  };

  const handleSearch = async ({ searchKey, selected }) => {
    dispatch({ type: FETCH_LABOUR_DATA, payload: null });
    const params = { searchKey, gender: selected?.['Gender'] };
    const res = await getLabourData(params);
    if (res.data) {
      dispatch({ type: FETCH_LABOUR_DATA, payload: res.data });
    } else {
      dispatch({ type: FETCH_LABOUR_DATA, payload: { labourData: [] } });
    }
  };

  const handleView = ({ rowObject }) => {
    history.push(`${LABOUR_DETAILS_URL}/${rowObject?.id}${generateRandomString(7)}`);
  };

  const prepareData = (selectedIds, allData, allFlag) => {
    let dataArray = [];
    for (let item of allData) {
      let entry = new Object();
      entry[labourKeys.ID.name] = item[labourKeys.ID.dbKey];
      entry[labourKeys.NAME.name] = item[labourKeys.NAME.dbKey];
      entry[labourKeys.NID.name] = item[labourKeys.NID.dbKey];
      entry[labourKeys.BIRTH_CERT.name] = item[labourKeys.BIRTH_CERT.dbKey];
      entry[labourKeys.DOB.name] = renderDate(item[labourKeys.DOB.dbKey], 'numeric', '/');
      entry[labourKeys.FATHER.name] = item[labourKeys.FATHER.dbKey];
      entry[labourKeys.MOTHER.name] = item[labourKeys.MOTHER.dbKey];
      entry[labourKeys.GENDER.name] = item[labourKeys.GENDER.dbKey];
      entry[labourKeys.DEPARTMENT.name] = item[labourKeys.DEPARTMENT.dbKey];
      entry[labourKeys.DESIGNATION.name] = item[labourKeys.DESIGNATION.dbKey];
      entry[labourKeys.JOINING.name] = renderDate(item[labourKeys.JOINING.dbKey], 'numeric', '/');
      entry[labourKeys.DEPARTURE.name] = renderDate(
        item[labourKeys.DEPARTURE.dbKey],
        'numeric',
        '/',
      );
      entry[labourKeys.CONTACT.name] = item[labourKeys.CONTACT.dbKey];
      entry[labourKeys.ADDRESS.name] = item[labourKeys.ADDRESS.dbKey];
      entry[labourKeys.INSURANCE.name] = item[labourKeys.INSURANCE.dbKey];
      entry[labourKeys.NOMINEE.name] = item[labourKeys.NOMINEE.dbKey];
      entry[labourKeys.RELATION.name] = item[labourKeys.RELATION.dbKey];
      entry[labourKeys.STATUS.name] = item[labourKeys.STATUS.dbKey];
      if (!allFlag && selectedIds.includes(item.id)) {
        dataArray.push(entry);
      } else if (allFlag) {
        dataArray.push(entry);
      }
    }
    return dataArray;
  };

  const handleDelete = (items, clearSelection) => {
    setDeleteList(items);
    setModal('RELEASE');
    onOpen();
    clearSelection([]);
  };

  const confirmDelete = async () => {
    const res = await deleteLabourData({ list: deleteList });
    if (res.data?.status == 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      handleSearch({ page: 1, pageSize: 10 });
    }
    onClose();
  };

  const handleSelectiveExport = (items, clearSelection) => {
    const selection = prepareData(items, data?.labourData);
    const cols = Object.values(labourKeys).map((key) => ({ id: key.name, title: key.name }));
    if (selection) {
      // generate worksheet using data with the order specified in the columns array
      const ws = utils.json_to_sheet(selection, {
        header: cols.map((c) => c.id ?? c.title),
        raw: true,
      });
      // rewrite header row with titles
      utils.sheet_add_aoa(ws, [cols.map((c) => c.title ?? c.id)], { origin: 'A1' });
      // create workbook
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Sheet1'); // replace with sheet name
      // download file
      writeFileXLSX(wb, `BTMA-Employee-Data-Export-${renderDate(new Date())}.xlsx`);
      clearSelection([]);
    }
  };

  const handleTableActions = (items, clearSelection, action) => {
    switch (action) {
      case 'RELEASE':
        handleDelete(items, clearSelection);
        break;
      case 'EXPORT':
        handleSelectiveExport(items, clearSelection);
        break;
      default:
        null;
    }
  };

  const calculateAgeFromDob = (date) => {
    let today = new Date();
    let birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const formattedLabourData = () => {
    return data?.labourData?.map((labour) => {
      let age = calculateAgeFromDob(labour.dateOfBirth);
      return { ...labour, age };
    });
  };

  const handleDownloadClick = () => {
    setModal('DOWNLOAD');
    onOpen();
  };

  const downloadData = async () => {
    setIsLoading(true);
    const res = await downloadLabourData(mill?.uid);
    if (res.data?.status === 200) {
      setIsLoading(false);
      onClose();
      return prepareData([], res.data?.labourData, true);
    }
    setIsLoading(false);
  };

  const handleExport = async () => {
    const data = await downloadData();
    const cols = Object.values(labourKeys).map((key) => ({ id: key.name, title: key.name }));
    if (data) {
      // generate worksheet using data with the order specified in the columns array
      const ws = utils.json_to_sheet(data, { header: cols.map((c) => c.id ?? c.title), raw: true });
      // rewrite header row with titles
      utils.sheet_add_aoa(ws, [cols.map((c) => c.title ?? c.id)], { origin: 'A1' });
      // create workbook
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Sheet1'); // replace with sheet name
      // download file
      writeFileXLSX(
        wb,
        `BTMA-Employee-Data-Export-${renderDate(new Date())}.${exportFormat.toLowerCase()}`,
      );
    }
  };

  const renderModal = () => {
    switch (modal) {
      case 'RELEASE':
        return (
          <DialogueBox
            close={onClose}
            alertTitle="Confirm"
            mountControl={isOpen}
            performAction={confirmDelete}
            alertMessage="Are you sure you want to release this employee?"
          />
        );
      case 'DOWNLOAD':
        return (
          <DialogueBox
            noAction
            noCancel
            close={onClose}
            mountControl={isOpen}
            alertTitle="Select Mill">
            <Box minW="500px">
              <SearchSection
                forceSearch
                minimal
                defaultValue={mill?.millName}
                setSelectedMill={setMill}
              />
              <HStack>
                <Text mt={1} color="primary.400" mb={1.5}>
                  {mill?.millName}
                </Text>
                {mill?.id ? (
                  <CloseButton
                    size="sm"
                    _focus={{ boxShadow: '0 0 0 3px #9280ff' }}
                    onClick={() => {
                      setMill();
                    }}
                  />
                ) : null}
              </HStack>
              <HStack flex={1} mt={4} justifyContent="flex-end">
                <Icon as={AiOutlineFileDone} w={10} h={10} color="#276749" />
                <Menu>
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <MenuButton as={Button} rightIcon={<Icon as={TiExport} w={5} h={5} />}>
                      EXPORT as
                    </MenuButton>
                  )}
                  <MenuList p={1} borderRadius="2px">
                    <MenuItem
                      onClick={() => {
                        setAlertType('EXPORT');
                        setExportFormat('XLS');
                        onAlertOpen();
                      }}>
                      .xls
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setAlertType('EXPORT');
                        setExportFormat('XLSX');
                        onAlertOpen();
                      }}>
                      .xlsx
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            </Box>
          </DialogueBox>
        );
    }
  };

  const renderAlert = () => {
    switch (alertType) {
      case 'EXPORT':
        return (
          <DialogueBox
            close={onAlertClose}
            mountControl={isAlertOpen}
            alertTitle="Confirm Export"
            alertMessage={`Are you sure you want to export the data? Currently exporting data for ${
              mill?.id ? mill?.millName : 'all mills'
            }!`}
            performAction={handleExport}
          />
        );
    }
  };

  return (
    <Box>
      <HStack mb={4}>
        <FormButton
          mt={0}
          rightIcon={<Icon as={MdAdd} w={5} h={5} />}
          onClick={() => history.push(ADD_LABOUR_URL)}>
          Add New Data
        </FormButton>
        <FormButton
          mt={0}
          rightIcon={<Icon as={MdFileDownload} w={5} h={5} />}
          onClick={handleDownloadClick}>
          Download Data
        </FormButton>
      </HStack>
      <DataTable
        keySearch
        isPaginated
        topPanel={true}
        columns={columns}
        search={handleSearch}
        currentPage={data?.page}
        paginate={handlePagination}
        data={formattedLabourData()}
        entries={data?.totalEntries}
        actions={{ VIEW: handleView }}
        currentPageSize={data?.pageSize}
        onActionClick={handleTableActions}
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
        rowSelectScheme={['EXPORT', 'RELEASE']}
        selectOptions={[LABOURER_GENDER_TYPES]}
        caption=" Labour Database - All Employees"
      />
      {renderModal()}
      {renderAlert()}
    </Box>
  );
};

export default LabourTable;
