//modules
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

//common components
import Rework from '../Modals/Rework';
import RenderModal from '../RenderModal';
import Loader from '../../../snippets/Loader';
import Footer from '../../../common/membershipFormComponents/Footer';
import SelectMillTypeSection from './sections/SelectMillTypeSection';
import NewRequestAcc from '../../../accounts/membership/NewRequests/NewRequest';
import MembershipFormWrapper from '../../../common/membershipFormComponents/MembershipFormWrapper';

//api and actions
import { notify } from '../../../../store/actions/globalActions';
import { getMillCategories, patchMembershipData } from '../../../../api/membership';

//utils
import { useYupValidationResolver } from '../../../../util/yupUtils';
import { membershipRequestStatus } from '../../../../constants/membershipConstants';

const Summary = ({ backToTable, prevStep, currStep, stepList }) => {
  const { uid } = useParams();
  const dispatch = useDispatch();
  const [modal, setModal] = useState();
  const [millType, setMillType] = useState();
  const [modalComponent, setModalComponent] = useState();
  const [categoryOptions, setCategoryOptions] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure(); //control hook for modal
  const data = useSelector((state) => state.accounts.currentData);

  useEffect(() => {
    (async () => {
      const { categories } = await getMillCategories();
      if (categories) {
        const options = categories.map(({ id, category }) => ({ name: category, value: id }));
        setCategoryOptions(options);
      }
    })();
  }, []);

  const millTypeFields = [
    {
      fieldType: 'select',
      label: 'Mill Type',
      name: 'categoryId',
      choices: categoryOptions,
    },
  ];

  const resolver = useYupValidationResolver(
    yup.object().shape({
      categoryId: yup.string().required().label('Mill Type'),
    }),
  );

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
  });

  useEffect(() => {
    if (data) {
      const { costA, costB, costC } = data?.costs;
      const num1 = parseFloat(costA);
      const num2 = parseFloat(costB);
      const num3 = parseFloat(costC);

      if (num1 >= num2 && num1 >= num3) {
        setValue('categoryId', 1);
      } else if (num2 >= num1 && num2 >= num3) {
        setValue('categoryId', 2);
      } else {
        setValue('categoryId', 3);
      }
    }
  }, [data]);

  const onSubmit = (formdata) => {
    setMillType(formdata?.categoryId);
    setModal('SubmitAlert');
    onOpen();
  };

  const confirmSubmit = async () => {
    const formData = {
      param: uid,
      categoryId: millType,
      status: membershipRequestStatus.PAYMENTPENDING,
    };
    const res = await patchMembershipData(formData);
    if (res.data?.status == 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      backToTable();
    }
  };

  const handleBackToTable = () => {
    setModal('BackToTableAlert');
    onOpen();
  };

  const handleprev = () => {
    prevStep();
  };

  const handleRework = () => {
    setModal('ReworkModal');
    setModalComponent(<Rework close={onClose} backToTable={backToTable} />);
    onOpen();
  };

  const handleReject = () => {
    setModal('RejectModal');
    onOpen();
  };

  const rejectMembershipRequest = async () => {
    const res = await patchMembershipData({ param: uid, reject: true });
    if (res.data?.status == 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      onClose();
      backToTable();
    }
  };

  return categoryOptions ? (
    <Box>
      <MembershipFormWrapper onSubmit={handleSubmit(onSubmit)}>
        <Box m={2}>
          <Box w="40%">
            <SelectMillTypeSection
              formFields={millTypeFields}
              control={control}
              register={register}
              errors={errors}
            />
          </Box>
        </Box>
        <Box>
          <NewRequestAcc summary />
        </Box>
      </MembershipFormWrapper>
      <Footer
        prev={handleprev}
        currStep={currStep}
        stepList={stepList}
        rework={handleRework}
        reject={handleReject}
        back={handleBackToTable}
        buttonLoading={isSubmitting}
        next={(event) => {
          event.preventDefault();
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      />
      <RenderModal
        modal={modal}
        step={currStep}
        isOpen={isOpen}
        onClose={onClose}
        backToTable={backToTable}
        confirmSubmit={confirmSubmit}
        modalComponent={modalComponent}
        confirmReject={rejectMembershipRequest}
      />
    </Box>
  ) : (
    <Loader />
  );
};

export default Summary;
