import { Box, useDisclosure, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import DialogueBox from '../../common/DialogueBox';
import FormButton from '../../common/FormButton';
import { fieldsValidation } from '../millRepresentative/NewRepresentativeInfoSection';

import { createMillRepresentative } from '../../../api/millUsers';
import { notify } from '../../../store/actions/globalActions';
import { useYupValidationResolver } from '../../../util/yupUtils';
import NewRepresentativeInfoSection from './NewRepresentativeInfoSection';

const AddMillRepresentative = ({ millUid, closeModal, refresh }) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const resolver = useYupValidationResolver(
    yup.object().shape({
      ...fieldsValidation,
    }),
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
  });

  const onSubmit = (data) => {
    console.log(data);
    setUserData(data);
    onOpen();
  };

  const confirmUpdate = async () => {
    const res = await createMillRepresentative({ millUid, userData });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 3000,
        }),
      );
      refresh();
      closeModal();
    }
  };

  return (
    <Box as="form" w="600px" onSubmit={handleSubmit(onSubmit)}>
      <NewRepresentativeInfoSection register={register} control={control} errors={errors} />
      <VStack w="100%" mt={6}>
        <FormButton mt={0} type="submit" isLoading={isSubmitting}>
          SUBMIT
        </FormButton>
      </VStack>
      <DialogueBox
        close={onClose}
        alertTitle="Confirm"
        mountControl={isOpen}
        performAction={confirmUpdate}
        alertMessage="Are you sure you want to create a representative with these information?"
      />
    </Box>
  );
};

export default AddMillRepresentative;
