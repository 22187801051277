import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  Box,
  Text,
  Divider,
  Stack,
  Button,
} from '@chakra-ui/react';
import { useYupValidationResolver } from '../../util/yupUtils';
import InputTextArea from './hookFormComponents/InputTextArea';

const ReworkMessageModal = ({ mountControl, close, performAction }) => {
  useEffect(() => {
    if (mountControl) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [mountControl]);

  const isOpen = mountControl;
  const cancelRef = useRef();

  const resolver = useYupValidationResolver(
    yup.object().shape({
      ['reason']: yup.string().required().label('Reason for Rework'),
    }),
  );

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
  });

  const onSubmit = (data) => {
    performAction(data);
    close();
  };

  return (
    <Modal isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={close}>
      <ModalOverlay>
        <Box position="absolute" w="100vw" h="100vh" onClick={close} />
        <Box
          w="480px"
          pb={2}
          overflow="auto"
          position="absolute"
          top="50%"
          left="50%"
          borderRadius={10}
          transform="translate(-50%, -50%)"
          bg="white"
          boxShadow="0px 5px 10px rgba(0,0,0,0.3)">
          <ModalCloseButton top="10px" right="10px" zIndex="1000" />
          <ModalHeader mb={-6}>
            <Text fontSize="22px" width="100%">
              Reason For Rework
            </Text>
            <Divider />
          </ModalHeader>
          <ModalBody>
            <Box as="form" onSubmit={handleSubmit(onSubmit)}>
              <InputTextArea
                type="text"
                name="reason"
                register={register}
                isInvalid={errors['reason']}
                errorMessage={errors['reason']?.message}
              />

              <Stack flexDir="column" alignItems="flex-end" mt={2}>
                <Button
                  bg="#E8E5E2"
                  type="submit"
                  color="#94702B"
                  _hover={{
                    bg: '#94702B',
                    color: '#E8E5E2',
                  }}
                  isLoading={isSubmitting}>
                  SUBMIT
                </Button>
              </Stack>
            </Box>
          </ModalBody>
        </Box>
      </ModalOverlay>
    </Modal>
  );
};

export default ReworkMessageModal;
