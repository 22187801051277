export const hsCodes = [
  {
    name: '3701.30.90-Photosensitive rotary screen',
    value: '3701.30.90-Photosensitive rotary screen',
  },
  { name: '3917.31.90-Duct', value: '3917.31.90-Duct' },
  { name: '3923.40.10-Plastic Bobbin', value: '3923.40.10-Plastic Bobbin' },
  { name: '3923.40.20-Autoconer Spare Parts', value: '3923.40.20-Autoconer Spare Parts' },
  { name: '3926.90.60-Cot/Apron', value: '3926.90.60-Cot/Apron' },
  { name: '3926.90.99-Spindle Tape', value: '3926.90.99-Spindle Tape' },
  { name: '4010.12.00-Rubber Conveyor Belt', value: '4010.12.00-Rubber Conveyor Belt' },
  { name: '4010.12.00-Tooth Belt', value: '4010.12.00-Tooth Belt' },
  { name: '4010.31.00-Endless Belt', value: '4010.31.00-Endless Belt' },
  { name: '4010.33.00-V Belt', value: '4010.33.00-V Belt' },
  { name: '4010.39.10-Round Belt', value: '4010.39.10-Round Belt' },
  { name: '4010.39.90-Tangential belt', value: '4010.39.90-Tangential belt' },
  { name: '4016.93.00-Seal, Washar, Bush', value: '4016.93.00-Seal, Washar, Bush' },
  { name: '4016.99.10-Rubber Cot/Apron', value: '4016.99.10-Rubber Cot/Apron' },
  { name: '5602.21.00-Felt', value: '5602.21.00-Felt' },
  { name: '5602.21.00-Felt Sealing', value: '5602.21.00-Felt Sealing' },
  { name: '5602.90.00-Other Felt', value: '5602.90.00-Other Felt' },
  { name: '5603.13.90-Filter Mat', value: '5603.13.90-Filter Mat' },
  { name: '5903.90.90-Spindle Tape', value: '5903.90.90-Spindle Tape' },
  {
    name: '5911.90.00-Endless Synthetic Polyester Needle Felt',
    value: '5911.90.00-Endless Synthetic Polyester Needle Felt',
  },
  {
    name: '6815.99.00-Diagram Seal For Steam Valve',
    value: '6815.99.00-Diagram Seal For Steam Valve',
  },
  { name: '7307.22.00-Elbow, bend & sleeves', value: '7307.22.00-Elbow, bend & sleeves' },
  { name: '7307.99.90-Clamps', value: '7307.99.90-Clamps' },
  {
    name: '7307.99.90-Coupling Joint, Clamp, Half Joint Male, Half Joint Female',
    value: '7307.99.90-Coupling Joint, Clamp, Half Joint Male, Half Joint Female',
  },
  { name: '7307.99.90-Planted bends', value: '7307.99.90-Planted bends' },
  { name: '7307.99.90-Thread coupling', value: '7307.99.90-Thread coupling' },
  { name: '7315.90.00-Clip & chain link', value: '7315.90.00-Clip & chain link' },
  { name: '7318.15.00-Screw', value: '7318.15.00-Screw' },
  { name: '7318.16.00-Nut', value: '7318.16.00-Nut' },
  {
    name: '7318.22.00-Washer, Compensatory Washer',
    value: '7318.22.00-Washer, Compensatory Washer',
  },
  { name: '7318.22.00-Washer', value: '7318.22.00-Washer' },
  { name: '7318.29.00-Horse Shoe Washer', value: '7318.29.00-Horse Shoe Washer' },
  { name: '7318.29.90-Nipple', value: '7318.29.90-Nipple' },
  { name: '7318.29.90-Pin', value: '7318.29.90-Pin' },
  { name: '7318.29.90-Pneumatic spring', value: '7318.29.90-Pneumatic spring' },
  { name: '7320.90.10-Helical Spring', value: '7320.90.10-Helical Spring' },
  {
    name: '7320.90.90-Torsion Spring, Ring Spring, Cup,Spring, Spring Holder',
    value: '7320.90.90-Torsion Spring, Ring Spring, Cup,Spring, Spring Holder',
  },
  { name: '7326.90.10-Steel Bobbins', value: '7326.90.10-Steel Bobbins' },
  { name: '7326.90.90-Ball Socket', value: '7326.90.90-Ball Socket' },
  { name: '7326.90.90-Steel Ring', value: '7326.90.90-Steel Ring' },
  { name: '7326.90.90-Yarn finger atlas', value: '7326.90.90-Yarn finger atlas' },
  { name: '7508.10.00-Cloth, Grill and Netting', value: '7508.10.00-Cloth, Grill and Netting' },
  { name: '7616.99.00-Bottom Scraper', value: '7616.99.00-Bottom Scraper' },
  { name: '7616.99.00-Fork', value: '7616.99.00-Fork' },
  { name: '8208.20.00-Knives and cutting baldes', value: '8208.20.00-Knives and cutting baldes' },
  { name: '8208.90.00-Selvage Cutter', value: '8208.90.00-Selvage Cutter' },
  { name: '8301.50.00-Bracket', value: '8301.50.00-Bracket' },
  { name: '8402.90.00-Parts of Boiler', value: '8402.90.00-Parts of Boiler' },
  { name: '8403.90.00-Parts for Boiler', value: '8403.90.00-Parts for Boiler' },
  { name: '8409.91.90-Actuator', value: '8409.91.90-Actuator' },
  { name: '8409.99.90-Nozzle', value: '8409.99.90-Nozzle' },
  { name: '8411.99.00-Parts for Gas Turbine', value: '8411.99.00-Parts for Gas Turbine' },
  { name: '8414.90.90-Compressor Spares', value: '8414.90.90-Compressor Spares' },
  {
    name: '8421.23.00-Oil Catcher, Filter, Filter Element',
    value: '8421.23.00-Oil Catcher, Filter, Filter Element',
  },
  {
    name: '8421.39.20-Overhead Travelling Cleaner',
    value: '8421.39.20-Overhead Travelling Cleaner',
  },
  { name: '8421.39.99-Fibre Depositing Plant', value: '8421.39.99-Fibre Depositing Plant' },
  { name: '8421.99.00-Bag Filter', value: '8421.99.00-Bag Filter' },
  {
    name: '8424.20.10-Textile Cleaning Gun & Tagging Gun',
    value: '8424.20.10-Textile Cleaning Gun & Tagging Gun',
  },
  { name: '8424.90.00-Lubricating nipple', value: '8424.90.00-Lubricating nipple' },
  { name: '8442.50.20-Printing plates', value: '8442.50.20-Printing plates' },
  { name: '8443.99.90-Adaptor plate', value: '8443.99.90-Adaptor plate' },
  { name: '8443.99.90-Clamping Hose', value: '8443.99.90-Clamping Hose' },
  { name: '8443.99.90-End Ring', value: '8443.99.90-End Ring' },
  { name: '8443.99.90-Rotary Screen', value: '8443.99.90-Rotary Screen' },
  { name: '8448.11.00-Card Reducing Machine', value: '8448.11.00-Card Reducing Machine' },
  { name: '8448.11.00-Copying Machine', value: '8448.11.00-Copying Machine' },
  { name: '8448.11.00-Dobbies and Jacquards', value: '8448.11.00-Dobbies and Jacquards' },
  { name: '8448.11.00-Punching Machine', value: '8448.11.00-Punching Machine' },
  { name: '8448.20.90-Chain Wheel', value: '8448.20.90-Chain Wheel' },
  {
    name: '8448.20.90-Differential Pressure Guard',
    value: '8448.20.90-Differential Pressure Guard',
  },
  { name: '8448.20.90-Digital Bar', value: '8448.20.90-Digital Bar' },
  { name: '8448.20.90-Flyer Complet', value: '8448.20.90-Flyer Complet' },
  { name: '8448.31.00-Card Clothing', value: '8448.31.00-Card Clothing' },
  {
    name: '8448.32.00-Combing Roller Head with Bearing',
    value: '8448.32.00-Combing Roller Head with Bearing',
  },
  { name: '8448.32.00-Lickerin Interlinked', value: '8448.32.00-Lickerin Interlinked' },
  { name: '8448.33.00-Bracket ring plate', value: '8448.33.00-Bracket ring plate' },
  {
    name: '8448.33.00-Drafting Zone for ring frames',
    value: '8448.33.00-Drafting Zone for ring frames',
  },
  { name: '8448.33.00-Flyer Pressure Finger', value: '8448.33.00-Flyer Pressure Finger' },
  { name: '8448.33.00-Lappet Hook', value: '8448.33.00-Lappet Hook' },
  { name: '8448.33.00-Latice for Blow Room', value: '8448.33.00-Latice for Blow Room' },
  { name: '8448.33.00-O-ring', value: '8448.33.00-O-ring' },
  { name: '8448.33.00-Resilient Mounting', value: '8448.33.00-Resilient Mounting' },
  { name: '8448.33.00-Ring Travellers', value: '8448.33.00-Ring Travellers' },
  { name: '8448.33.00-Rotor Spindle', value: '8448.33.00-Rotor Spindle' },
  { name: '8448.33.00-Spindles Flyers', value: '8448.33.00-Spindles Flyers' },
  { name: '8448.33.00-Spindles/Bloser', value: '8448.33.00-Spindles/Bloser' },
  { name: '8448.33.00-Spinning ring', value: '8448.33.00-Spinning ring' },
  { name: '8448.39.00-Belt Shipter Complete', value: '8448.39.00-Belt Shipter Complete' },
  { name: '8448.39.00-Blow Tube', value: '8448.39.00-Blow Tube' },
  { name: '8448.39.00-Bolester', value: '8448.39.00-Bolester' },
  { name: '8448.39.00-Bottom Part', value: '8448.39.00-Bottom Part' },
  { name: '8448.39.00-Builder Motion', value: '8448.39.00-Builder Motion' },
  { name: '8448.39.00-Bush', value: '8448.39.00-Bush' },
  { name: '8448.39.00-Ceramic Cutter', value: '8448.39.00-Ceramic Cutter' },
  { name: '8448.39.00-Chute Guide', value: '8448.39.00-Chute Guide' },
  { name: '8448.39.00-Clearer Cloth', value: '8448.39.00-Clearer Cloth' },
  { name: '8448.39.00-Clearing Roller', value: '8448.39.00-Clearing Roller' },
  { name: '8448.39.00-Comb', value: '8448.39.00-Comb' },
  { name: '8448.39.00-Counter Texrol', value: '8448.39.00-Counter Texrol' },
  { name: '8448.39.00-Cutter', value: '8448.39.00-Cutter' },
  { name: '8448.39.00-Cutter Set', value: '8448.39.00-Cutter Set' },
  { name: '8448.39.00-Cutting Blade', value: '8448.39.00-Cutting Blade' },
  { name: '8448.39.00-Delay unit', value: '8448.39.00-Delay unit' },
  { name: '8448.39.00-Double Control', value: '8448.39.00-Double Control' },
  { name: '8448.39.00-Ejector bobbin', value: '8448.39.00-Ejector bobbin' },
  { name: '8448.39.00-Emersion roller', value: '8448.39.00-Emersion roller' },
  { name: '8448.39.00-Encoder Clamp', value: '8448.39.00-Encoder Clamp' },
  { name: '8448.39.00-Fluted Roller', value: '8448.39.00-Fluted Roller' },
  { name: '8448.39.00-Frame', value: '8448.39.00-Frame' },
  { name: '8448.39.00-Hams Spring', value: '8448.39.00-Hams Spring' },
  { name: '8448.39.00-Handle Stud', value: '8448.39.00-Handle Stud' },
  { name: '8448.39.00-Hot Cutter', value: '8448.39.00-Hot Cutter' },
  { name: '8448.39.00-Lap roller', value: '8448.39.00-Lap roller' },
  { name: '8448.39.00-Magnet Holder', value: '8448.39.00-Magnet Holder' },
  { name: '8448.39.00-Measuring Trumpet', value: '8448.39.00-Measuring Trumpet' },
  { name: '8448.39.00-Module Control', value: '8448.39.00-Module Control' },
  { name: '8448.39.00-Needle Bearing', value: '8448.39.00-Needle Bearing' },
  { name: '8448.39.00-Nipper Roller', value: '8448.39.00-Nipper Roller' },
  { name: '8448.39.00-Rapier Head', value: '8448.39.00-Rapier Head' },
  { name: '8448.39.00-Releaser', value: '8448.39.00-Releaser' },
  { name: '8448.39.00-Ring Cup', value: '8448.39.00-Ring Cup' },
  { name: '8448.39.00-Rotor', value: '8448.39.00-Rotor' },
  { name: '8448.39.00-Schmersal', value: '8448.39.00-Schmersal' },
  { name: '8448.39.00-Scissors Housing', value: '8448.39.00-Scissors Housing' },
  { name: '8448.39.00-Slide', value: '8448.39.00-Slide' },
  { name: '8448.39.00-Small bevel Wheel', value: '8448.39.00-Small bevel Wheel' },
  { name: '8448.39.00-Splash Guard', value: '8448.39.00-Splash Guard' },
  { name: '8448.39.00-Spring Cover', value: '8448.39.00-Spring Cover' },
  { name: '8448.39.00-Steel Ring Cup', value: '8448.39.00-Steel Ring Cup' },
  { name: '8448.39.00-Stop Pawl', value: '8448.39.00-Stop Pawl' },
  { name: '8448.39.00-Stopper Peg Tray', value: '8448.39.00-Stopper Peg Tray' },
  { name: '8448.39.00-Support', value: '8448.39.00-Support' },
  { name: '8448.39.00-Toothed Disk', value: '8448.39.00-Toothed Disk' },
  { name: '8448.39.00-Top Roller', value: '8448.39.00-Top Roller' },
  { name: '8448.39.00-Weft Selector', value: '8448.39.00-Weft Selector' },
  { name: '8448.39.00-Yarn Guide', value: '8448.39.00-Yarn Guide' },
  { name: '8448.39.00-Yarn Tape', value: '8448.39.00-Yarn Tape' },
  { name: '8448.39.00-Yarn Tape bush', value: '8448.39.00-Yarn Tape bush' },
  { name: '8448.42.10-Reeds and Wire Healds', value: '8448.42.10-Reeds and Wire Healds' },
  { name: '8448.42.10-Wire Healds', value: '8448.42.10-Wire Healds' },
  { name: '8448.42.90-Healds/Heald Frame', value: '8448.42.90-Healds/Heald Frame' },
  { name: '8448.49.00-Bnap Backle', value: '8448.49.00-Bnap Backle' },
  { name: '8448.49.00-Bobbin Holder', value: '8448.49.00-Bobbin Holder' },
  { name: '8448.49.00-Circular Comb and Top Comb', value: '8448.49.00-Circular Comb and Top Comb' },
  { name: '8448.49.00-Clamping Bushing', value: '8448.49.00-Clamping Bushing' },
  { name: '8448.49.00-Clamping Hook', value: '8448.49.00-Clamping Hook' },
  { name: '8448.49.00-Double Teeth Wheel', value: '8448.49.00-Double Teeth Wheel' },
  { name: '8448.49.00-Gripper', value: '8448.49.00-Gripper' },
  { name: '8448.49.00-Guide Roller/Rubber Roller', value: '8448.49.00-Guide Roller/Rubber Roller' },
  { name: '8448.49.00-Guide Teeth', value: '8448.49.00-Guide Teeth' },
  { name: '8448.49.00-Magnet P.C.B', value: '8448.49.00-Magnet P.C.B' },
  { name: '8448.49.00-Projectile Body', value: '8448.49.00-Projectile Body' },
  { name: '8448.49.00-Projectile Gripper', value: '8448.49.00-Projectile Gripper' },
  { name: '8448.49.00-Roller Guide', value: '8448.49.00-Roller Guide' },
  { name: '8448.49.00-Rubber Ruller', value: '8448.49.00-Rubber Ruller' },
  { name: '8448.49.00-Sensing Head', value: '8448.49.00-Sensing Head' },
  { name: '8448.49.00-Shuttles', value: '8448.49.00-Shuttles' },
  { name: '8448.49.00-Slitting PCB', value: '8448.49.00-Slitting PCB' },
  { name: '8448.49.00-Top Roller', value: '8448.49.00-Top Roller' },
  { name: '8448.49.00-Tucking Needle', value: '8448.49.00-Tucking Needle' },
  { name: '8448.49.00-V-Belt Wheel', value: '8448.49.00-V-Belt Wheel' },
];
