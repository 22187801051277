import React from 'react';
import * as yup from 'yup';
import { dateValidation } from '../../../../util/yupUtils';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    fieldType: 'date',
    name: 'dateSold',
    label: 'Date Sold',
    isRequired: true,
  },
  {
    fieldType: 'select',
    colSpan: 1,
    label: 'Payment Method',
    name: 'paymentMethod',
    isRequired: true,
    choices: [
      { name: 'Cash', value: 'CASH' },
      { name: 'Cheque', value: 'CHEQUE' },
      { name: 'EFT', value: 'EFT' },
      { name: 'MFS', value: 'MFS' },
    ],
  },
];

export const purchaseInfoFieldsValidation = {
  [fields[0].name]: dateValidation.required().label(fields[0].label),
  [fields[1].name]: yup.string().required().label(fields[1].label),
};

const PurchaseInfoSection = (props) => {
  return (
    <FormSection
      secondary
      sectionColumn={2}
      formFields={fields.map((field) => ({
        ...field,
        isDisabled: props?.disabledFields?.includes(field.name) ? true : false,
      }))}
      {...props}
    />
  );
};

export default PurchaseInfoSection;
