import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    fieldType: 'radio',
    colSpan: 1,
    label: 'Certificate Type',
    name: 'productionCertificateType',
    isRequired: true,
    choices: [
      { name: 'GSP', value: 'GSP' },
      { name: 'Cash Incentive', value: 'CASH' },
    ],
  },
];

export const certificateTypeFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
};

const CertificateType = (props) => {
  return <FormSection secondary sectionColumn={1} formFields={fields} {...props} />;
};

export default CertificateType;
