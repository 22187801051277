import React, { useEffect, useRef } from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Box,
} from '@chakra-ui/react';

const DialogueBox = ({
  close,
  variant,
  noAction,
  noCancel,
  scrollable,
  alertTitle,
  mountControl,
  alertMessage,
  performAction,
  actionDisabled,
  actionButtonText,
  cancelButtonText,
  onActionKeepOpen,
  actionButtonLoading,
  children,
}) => {
  useEffect(() => {
    if (mountControl) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [mountControl]);

  const isOpen = mountControl;

  const handleAction = () => {
    onActionKeepOpen ? null : close();
    performAction();
  };

  const cancelRef = useRef();

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={close}>
      <AlertDialogOverlay>
        <Box position="absolute" w="100vw" h="100vh" onClick={close} />
        <Box
          top="50%"
          left="50%"
          maxH="90%"
          minW="450px"
          bgColor="white"
          borderRadius={10}
          position="absolute"
          transform="translate(-50%, -50%)"
          boxShadow="0px 5px 10px rgba(0,0,0,0.3)"
          overflow={scrollable ? 'auto' : 'unset'}
          maxW={variant === 'lg' ? 'unset' : '600px'}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold" textTransform="uppercase">
            {alertTitle ? alertTitle : 'alert title'}
          </AlertDialogHeader>

          <AlertDialogCloseButton />

          <AlertDialogBody pt="20px" pb="20px">
            {alertMessage}
            {children}
          </AlertDialogBody>

          <AlertDialogFooter>
            {!noAction ? (
              <Button
                variant="modalAction"
                onClick={handleAction}
                textTransform="uppercase"
                isDisabled={actionDisabled}
                isLoading={actionButtonLoading}>
                {actionButtonText ? actionButtonText : 'Yes'}
              </Button>
            ) : null}
            {!noCancel ? (
              <Button
                variant="modalAction"
                textTransform="uppercase"
                ref={cancelRef}
                onClick={close}
                ml={3}>
                {cancelButtonText ? cancelButtonText : 'No'}
              </Button>
            ) : null}
          </AlertDialogFooter>
        </Box>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DialogueBox;
