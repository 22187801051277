import React from 'react';
import commaNumber from 'comma-number';
import { Box, HStack, VStack, Text } from '@chakra-ui/react';
import PrintReceiptHeader from '../../snippets/PrintReceiptHeader';
import { convertNumberToWords, renderDate } from '../../../util/formatUtils';

const styles = {
  headerCells: {
    pl: 1,
    fontSize: 'md',
    fontWeight: 'bold',
    textAlign: 'center',
    borderBottom: '2px solid black',
  },
  bodyCells: {
    fontSize: 'md',
    fontWeight: 'bold',
    textAlign: 'center',
    borderBottom: '2px solid black',
  },
  footerCells: {
    fontSize: 'lg',
    fontWeight: 'bold',
    textAlign: 'center',
  },
};

const MoneyReceipt = React.forwardRef(({ data }, ref) => (
  <Box p={6} ref={ref}>
    <PrintReceiptHeader />

    <HStack mt={8} w="100%" justifyContent="space-between" alignItems="flex-end">
      <HStack spacing={0} alignItems="flex-end">
        <Text fontWeight="bold">No:</Text>
        <Text {...styles.headerCells} borderStyle="dotted">
          {data?.slNo}
        </Text>
      </HStack>
      <HStack spacing={0}>
        <Text fontSize="xl" fontWeight="bold" pb={4}>
          Money Receipt
        </Text>
      </HStack>
      <HStack spacing={0}>
        <Text fontWeight="bold">Date:</Text>
        <Text {...styles.headerCells} borderStyle="dotted">
          {renderDate(data?.mrDate)}
        </Text>
      </HStack>
    </HStack>

    <VStack spacing={3} mt={8}>
      <HStack w="100%" alignItems="flex-end">
        <Text fontWeight="bold">Received from:</Text>
        <Text {...styles.bodyCells} flex={1} borderStyle="dotted">
          {data?.receivedFrom}
        </Text>
      </HStack>
      <HStack w="100%" alignItems="flex-end">
        <Text fontWeight="bold">Amount in words:</Text>
        <Text {...styles.bodyCells} flex={1} borderStyle="dotted">
          {convertNumberToWords(data?.amount || 0)} Taka Only
        </Text>
      </HStack>
      <HStack w="100%" alignItems="flex-end">
        <Text fontWeight="bold">Payment method:</Text>
        <Text {...styles.bodyCells} px={4} borderStyle="dotted">
          {data?.paymentMethod || 'N/A'}
        </Text>
        <Text fontWeight="bold">
          {data?.paymentMethod === 'EFT'
            ? 'Trx Id:'
            : data?.paymentMethod === 'MFS'
            ? 'Trx Id/Mobile no:'
            : 'Cheque no:'}
        </Text>
        <Text {...styles.bodyCells} flex={1} borderStyle="dotted">
          {data?.chequeNo || 'N/A'}
        </Text>
        <Text fontWeight="bold">
          {data?.paymentMethod === 'EFT'
            ? 'Trx Date:'
            : data?.paymentMethod === 'MFS'
            ? 'Trx Date:'
            : 'Cheque Dated:'}
        </Text>
        <Text {...styles.bodyCells} px={4} borderStyle="dotted">
          {data?.chequeDated ? renderDate(data?.chequeDated) : 'N/A'}
        </Text>
      </HStack>
      <HStack w="100%" alignItems="flex-end">
        <Text fontWeight="bold">Payment for:</Text>
        <Text {...styles.bodyCells} flex={1} borderStyle="dotted">
          {data?.paymentFor}
        </Text>
      </HStack>
      <HStack w="100%" alignItems="flex-end">
        <Text fontWeight="bold">For the period:</Text>
        <Text {...styles.bodyCells} flex={1} borderStyle="dotted">
          {data?.paymentChoices?.includes(data?.paymentFor) ? `${data?.period || 'N/A'}` : 'N/A'}
        </Text>
      </HStack>
      {data?.notes && (
        <HStack w="100%" alignItems="flex-end">
          <Text fontWeight="bold" maxW="100%">
            Narration: {data?.notes}
          </Text>
        </HStack>
      )}
    </VStack>

    <HStack w="100%" justifyContent="space-between" alignItems="flex-end" mt={12}>
      <HStack alignItems="flex-end">
        <Text fontWeight="bold">Tk.</Text>
        <Text {...styles.footerCells} p={2} width="200px" border="2px solid black">
          {commaNumber(data?.total || 0)}
        </Text>
      </HStack>

      <Text {...styles.footerCells} pt={1} mt={5} width="300px" borderTop="2px solid black">
        Authorized Signature
      </Text>
    </HStack>

    <Text mt={5} opacity={0.7} fontSize="sm" fontWeight="bold">
      ***This is an electronically generated receipt.
    </Text>
  </Box>
));

MoneyReceipt.displayName = 'MoneyReceipt';

export default MoneyReceipt;
