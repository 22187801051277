import React, { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { CgPrinter } from 'react-icons/cg';
import FormButton from '../../../common/FormButton';
import { AiOutlineFileSearch } from 'react-icons/ai';
import SearchSection from '../../../common/SearchSection';
import { renderDate } from '../../../../util/formatUtils';
import { getAllDutyCertDues } from '../../../../api/accounts';
import DutyExemptionDuesReport from './DutyExemptionDuesReport';
import { Box, Button, CloseButton, Divider, HStack, Icon, Spinner, Text } from '@chakra-ui/react';

const DutyExemptionDues = () => {
  const [mill, setMill] = useState();
  const dutyExemptionDuesRef = useRef();
  const [results, setResults] = useState();
  const [loader, setLoader] = useState(false);

  const handleSubmit = async () => {
    setLoader(true);
    const res = await getAllDutyCertDues(mill?.id);
    if (res.data?.status === 200) {
      setResults(res.data?.dueList);
      setLoader(false);
    }
    setLoader(false);
  };

  const columns = [{ accessor: 'millName' }, { accessor: 'membershipNo' }, { accessor: 'phoneNo' }];

  const certColumns = [
    { accessor: 'orderId' },
    { accessor: 'certificateNum' },
    { accessor: 'total' },
    { accessor: 'discount' },
    { accessor: 'paidAmount' },
    { accessor: 'dues' },
  ];

  return (
    <Box mt={10} mb="2vh">
      <Text fontSize="2xl" fontWeight="bold" borderBottom="1px solid #E9EDF4" mb={4}>
        Duty Exemption Dues
      </Text>
      <Box bg="white" p={4} borderRadius="4px" w="50%">
        <SearchSection forceSearch minimal setSelectedMill={setMill} />
        <HStack>
          <Text mt={1} color="primary.400" mb={1.5}>
            {mill?.millName}
          </Text>
          {mill?.id ? (
            <CloseButton
              size="sm"
              _focus={{ boxShadow: '0 0 0 3px #9280ff' }}
              onClick={() => {
                setMill();
              }}
            />
          ) : null}
        </HStack>
        <HStack w="100%" mt={2} spacing={4}>
          <FormButton mt={0} isLoading={loader} onClick={handleSubmit}>
            GENERATE REPORT
          </FormButton>
          {loader ? (
            <HStack h="45px" bg="secondary" flex={1} justifyContent="center" borderRadius="4px">
              <Spinner thickness="2px" emptyColor="primary.50" color="primary.700" size="md" />
              <Text color="primary.700">Generating Report...</Text>
            </HStack>
          ) : null}
        </HStack>
      </Box>

      {results ? (
        <>
          <HStack w="100%" justifyContent="space-between">
            <Text fontSize="lg" fontWeight="bold" mb={-4}>
              Report preview
            </Text>
            <ReactToPrint
              documentTitle={`Duty-Exemption-Dues-${renderDate(new Date())}`}
              trigger={() => (
                <Button
                  rightIcon={<Icon as={CgPrinter} w={5} h={5} />}
                  isDisabled={results?.length < 1}>
                  PRINT
                </Button>
              )}
              content={() => dutyExemptionDuesRef.current}
            />
          </HStack>
          <Divider mt={1} />
        </>
      ) : null}

      {results ? (
        results?.length > 0 ? (
          <Box
            maxH="60vh"
            bg="white"
            overflow="auto"
            borderRadius="4px"
            boxShadow="inset 0px 0px 5px rgba(0,0,0,0.2)">
            <DutyExemptionDuesReport
              data={results}
              columns={columns}
              columns2={certColumns}
              ref={dutyExemptionDuesRef}
            />
          </Box>
        ) : (
          <Box
            bg="white"
            overflowX="auto"
            borderRadius="4px"
            boxShadow="inset 0px 0px 5px rgba(0,0,0,0.2)">
            <Text color="textSecondary" fontSize="2xl" w="100%" textAlign="center" py={50}>
              <Icon as={AiOutlineFileSearch} w={10} h={10} /> No entries found!
            </Text>
          </Box>
        )
      ) : null}
    </Box>
  );
};

export default DutyExemptionDues;
