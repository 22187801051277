import colors from '../colors';
const Input = {
  parts: ['field'],
  baseStyle: {
    field: {
      _focus: {
        bg: 'formFocusBG',
      },
    },
  },
  variants: {
    formInput: () => ({
      bg: colors.formInputBG,
      h: '60px',
      focusBorderColor: 'primary.300',
      borderRadius: '4px',
    }),
    outline: () => ({
      h: '50px',
      bg: colors.formInputBG,
      focusBorderColor: 'primary.300',
    }),
  },
};

export default Input;
