import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'approvedGasLoad',
    type: 'text',
    label: 'Approved Gas Load (Cubic Meters)',
  },
  {
    colSpan: 1,
    name: 'approvedElectricityLoad',
    type: 'text',
    label: 'Approved Electricity Load (KW/Hour)',
  },
];

export const powerManagementLeftOverFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label),
  [fields[1].name]: yup.string().label(fields[1].label),
};

const PowerManagementLeftOver = (props) => {
  return <FormSection sectionColumn={2} formFields={fields} {...props} />;
};

export default PowerManagementLeftOver;
