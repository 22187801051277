//modules
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Grid, GridItem, Divider, Checkbox, useDisclosure, Box } from '@chakra-ui/react';

//common
import DialogueBox from '../../../../common/DialogueBox';
import Footer from '../../../../common/membershipFormComponents/Footer';
import MembershipFormWrapper from '../../../../common/membershipFormComponents/MembershipFormWrapper';

//form sections
import RawMatReqA, { rawMatReqAFieldsValidation } from './sections/RawMatReqA';
import RawMatConA, { rawMatConAFieldsValidation } from './sections/RawMatConA';
import RawMatReqB, { rawMatReqBFieldsValidation } from './sections/RawMatReqB';
import RawMatReqC, { rawMatReqCFieldsValidation } from './sections/RawMatReqC';
import HumanResources, { humanResFieldsValidation } from './sections/HumanResources';

//utils
import _ from 'lodash';
import { useYupValidationResolver } from '../../../../../util/yupUtils';
import { getFormData, removeFormData, setFormData } from '../../../../../util/localStorageUtils';

//validation schema
const rawAValidatonSchema = yup.object().shape({
  ...humanResFieldsValidation,
  ...rawMatReqAFieldsValidation,
});
const rawBValidatonSchema = yup.object().shape({
  ...humanResFieldsValidation,
  ...rawMatReqBFieldsValidation,
});
const rawCValidatonSchema = yup.object().shape({
  ...humanResFieldsValidation,
  ...rawMatReqCFieldsValidation,
});
const rawAConValidatonSchema = yup.object().shape({
  ...humanResFieldsValidation,
  ...rawMatConAFieldsValidation,
});
const rawAandConValidatonSchema = yup.object().shape({
  ...humanResFieldsValidation,
  ...rawMatConAFieldsValidation,
  ...rawMatReqAFieldsValidation,
});
const rawABValidatonSchema = yup.object().shape({
  ...humanResFieldsValidation,
  ...rawMatReqAFieldsValidation,
  ...rawMatReqBFieldsValidation,
});
const rawBCValidatonSchema = yup.object().shape({
  ...humanResFieldsValidation,
  ...rawMatReqBFieldsValidation,
  ...rawMatReqCFieldsValidation,
});
const rawCAValidatonSchema = yup.object().shape({
  ...humanResFieldsValidation,
  ...rawMatReqAFieldsValidation,
  ...rawMatReqCFieldsValidation,
});
const rawBandConValidatonSchema = yup.object().shape({
  ...humanResFieldsValidation,
  ...rawMatConAFieldsValidation,
  ...rawMatReqBFieldsValidation,
});
const rawCandConValidatonSchema = yup.object().shape({
  ...humanResFieldsValidation,
  ...rawMatConAFieldsValidation,
  ...rawMatReqCFieldsValidation,
});
const rawABandConValidatonSchema = yup.object().shape({
  ...humanResFieldsValidation,
  ...rawMatConAFieldsValidation,
  ...rawMatReqAFieldsValidation,
  ...rawMatReqBFieldsValidation,
});
const rawBCandConValidatonSchema = yup.object().shape({
  ...humanResFieldsValidation,
  ...rawMatConAFieldsValidation,
  ...rawMatReqBFieldsValidation,
  ...rawMatReqCFieldsValidation,
});
const rawCAandConValidatonSchema = yup.object().shape({
  ...humanResFieldsValidation,
  ...rawMatConAFieldsValidation,
  ...rawMatReqAFieldsValidation,
  ...rawMatReqCFieldsValidation,
});
const rawABCValidatonSchema = yup.object().shape({
  ...humanResFieldsValidation,
  ...rawMatReqAFieldsValidation,
  ...rawMatReqBFieldsValidation,
  ...rawMatReqCFieldsValidation,
});
const rawABCandConValidatonSchema = yup.object().shape({
  ...humanResFieldsValidation,
  ...rawMatConAFieldsValidation,
  ...rawMatReqAFieldsValidation,
  ...rawMatReqBFieldsValidation,
  ...rawMatReqCFieldsValidation,
});

const OrganizInfo = ({ backToTable, prevStep, nextStep, currStep, stepList, isDataStored }) => {
  const [isRawReqA, setIsRawReqA] = useState(false);
  const [isRawConA, setIsRawConA] = useState(false);
  const [isRawReqB, setIsRawReqB] = useState(false);
  const [isRawReqC, setIsRawReqC] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formData = getFormData('addMemberStepC');

  const getValidationSchema = () => {
    if (isRawReqA && !isRawReqB && !isRawReqC && !isRawConA) {
      return rawAValidatonSchema;
    } else if (!isRawReqA && isRawReqB && !isRawReqC && !isRawConA) {
      return rawBValidatonSchema;
    } else if (!isRawReqA && !isRawReqB && isRawReqC && !isRawConA) {
      return rawCValidatonSchema;
    } else if (!isRawReqA && !isRawReqB && !isRawReqC && isRawConA) {
      return rawAConValidatonSchema;
    } else if (isRawReqA && !isRawReqB && !isRawReqC && isRawConA) {
      return rawAandConValidatonSchema;
    } else if (isRawReqA && isRawReqB && !isRawReqC && !isRawConA) {
      return rawABValidatonSchema;
    } else if (!isRawReqA && isRawReqB && isRawReqC && !isRawConA) {
      return rawBCValidatonSchema;
    } else if (isRawReqA && !isRawReqB && isRawReqC && !isRawConA) {
      return rawCAValidatonSchema;
    } else if (!isRawReqA && !isRawReqB && isRawReqC && isRawConA) {
      return rawCandConValidatonSchema;
    } else if (!isRawReqA && isRawReqB && !isRawReqC && isRawConA) {
      return rawBandConValidatonSchema;
    } else if (isRawReqA && isRawReqB && !isRawReqC && isRawConA) {
      return rawABandConValidatonSchema;
    } else if (!isRawReqA && isRawReqB && isRawReqC && isRawConA) {
      return rawBCandConValidatonSchema;
    } else if (isRawReqA && !isRawReqB && isRawReqC && isRawConA) {
      return rawCAandConValidatonSchema;
    } else if (isRawReqA && isRawReqB && isRawReqC && !isRawConA) {
      return rawABCValidatonSchema;
    } else if (isRawReqA && isRawReqB && isRawReqC && isRawConA) {
      return rawABCandConValidatonSchema;
    } else {
      return yup.object().shape({
        ...humanResFieldsValidation,
      });
    }
  };

  const defaultFields = {
    ...formData?.yarnRawReq,
    ...formData?.yarnRawCon,
    ...formData?.fabricRaw,
    ...formData?.textileRaw,
    ...formData?.humanResources,
  };

  const resolver = useYupValidationResolver(getValidationSchema());

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: { ...defaultFields },
  });

  useEffect(() => {
    for (let key in formData?.yarnRawReq) {
      if (defaultFields?.[key]) {
        setIsRawReqA(true);
      }
    }
    for (let key in formData?.yarnRawCon) {
      if (defaultFields?.[key]) {
        setIsRawConA(true);
      }
    }
    for (let key in formData?.fabricRaw) {
      if (defaultFields?.[key]) {
        setIsRawReqB(true);
      }
    }
    for (let key in formData?.textileRaw) {
      if (defaultFields?.[key]) {
        setIsRawReqC(true);
      }
    }
  }, []);

  const handleBackToTable = () => {
    if (isDataStored()) {
      onOpen();
    } else {
      backToTable();
    }
  };

  const prepareData = (data) => {
    const {
      psfReq,
      chipsReq,
      acrylicReq,
      viscoseReq,
      cotWasteReq,
      petChipsReq,
      rawCottonReq,

      psfCon,
      chipsCon,
      acrylicCon,
      viscoseCon,
      cotWasteCon,
      petChipsCon,
      rawCottonCon,

      cotYarn,
      otherYarn,
      synthYarn,

      dice,
      yarns,
      fabKnit,
      chemical,
      fabWoven,

      noOfMale,
      noOfFemale,
      numberOfStaff,
      numberOfOfficers,
    } = data;

    const saveData = {
      yarnRawReq: {
        psfReq,
        chipsReq,
        acrylicReq,
        viscoseReq,
        cotWasteReq,
        petChipsReq,
        rawCottonReq,
      },
      yarnRawCon: {
        psfCon,
        chipsCon,
        acrylicCon,
        viscoseCon,
        cotWasteCon,
        petChipsCon,
        rawCottonCon,
      },
      fabricRaw: {
        cotYarn,
        otherYarn,
        synthYarn,
      },
      textileRaw: {
        dice,
        yarns,
        fabKnit,
        chemical,
        fabWoven,
      },
      humanResources: {
        noOfMale,
        noOfFemale,
        numberOfStaff,
        numberOfOfficers,
      },
    };

    return saveData;
  };

  const onForwardSubmit = (data) => {
    if (_.isEqual(defaultFields, data) === false) {
      setFormData('addMemberStepC', prepareData(data));
      nextStep();
    } else {
      nextStep();
    }
  };

  const onBackwardSubmit = (data) => {
    if (_.isEqual(defaultFields, data) === false) {
      setFormData('addMemberStepC', prepareData(data));
      prevStep();
    } else {
      prevStep();
    }
  };

  return (
    <Box>
      <MembershipFormWrapper formName="Raw Material Information">
        <Grid templateColumns="repeat(2, 1fr)" gap={3}>
          <GridItem colSpan={1}>
            <Checkbox
              isChecked={isRawReqA}
              name="CatAReq"
              onChange={() => setIsRawReqA(!isRawReqA)}>
              Raw Material Requirement (Category ‘A’)
            </Checkbox>
          </GridItem>
          <GridItem colSpan={1}>
            <Checkbox
              isChecked={isRawConA}
              name="CatACons"
              onChange={() => setIsRawConA(!isRawConA)}>
              Raw Material Consumption Year End (Category ‘A’)
            </Checkbox>
          </GridItem>

          <GridItem
            p={2}
            bg="white"
            borderRadius="4px"
            pointerEvents={isRawReqA ? 'auto' : 'none'}
            opacity={isRawReqA ? null : '0.3'}
            colSpan={1}>
            <RawMatReqA register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem
            p={2}
            bg="white"
            borderRadius="4px"
            colSpan={1}
            pointerEvents={isRawConA ? 'auto' : 'none'}
            opacity={isRawConA ? null : '0.3'}>
            <RawMatConA register={register} control={control} errors={errors} />
          </GridItem>

          <GridItem colSpan={2} mt={5}>
            <Divider />
          </GridItem>
          <GridItem colSpan={1}>
            <Checkbox
              isChecked={isRawReqB}
              name="CatBReq"
              onChange={() => setIsRawReqB(!isRawReqB)}>
              Raw Material Requirement (Category ‘B’)
            </Checkbox>
          </GridItem>
          <GridItem colSpan={1}>
            <Checkbox
              isChecked={isRawReqC}
              name="CatCReq"
              onChange={() => setIsRawReqC(!isRawReqC)}>
              Raw Material Requirement (Category ‘C’)
            </Checkbox>
          </GridItem>

          <GridItem
            p={2}
            bg="white"
            borderRadius="4px"
            colSpan={1}
            pointerEvents={isRawReqB ? 'auto' : 'none'}
            opacity={isRawReqB ? null : '0.3'}>
            <RawMatReqB register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem
            p={2}
            bg="white"
            borderRadius="4px"
            colSpan={1}
            pointerEvents={isRawReqC ? 'auto' : 'none'}
            opacity={isRawReqC ? null : '0.3'}>
            <RawMatReqC register={register} control={control} errors={errors} />
          </GridItem>

          <GridItem p={2} bg="white" borderRadius="4px" colSpan={2}>
            <HumanResources register={register} control={control} errors={errors} />
          </GridItem>
        </Grid>
      </MembershipFormWrapper>
      <Footer
        addNew
        currStep={currStep}
        stepList={stepList}
        back={handleBackToTable}
        buttonLoading={isSubmitting}
        next={(event) => {
          event.preventDefault();
          handleSubmit(onForwardSubmit)();
        }}
        prev={(event) => {
          event.preventDefault();
          handleSubmit(onBackwardSubmit)();
        }}
      />
      <DialogueBox
        close={onClose}
        mountControl={isOpen}
        alertTitle="Unsaved Changes"
        alertMessage="You have unsaved changes! Are you sure you want to leave this page?"
        performAction={() => {
          removeFormData('addMemberStepA');
          removeFormData('addMemberStepB');
          removeFormData('addMemberStepC');
          removeFormData('addMemberStepD');
          backToTable();
        }}
      />
    </Box>
  );
};

export default OrganizInfo;
