import React from 'react';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    label: 'Narration',
    type: 'text',
    name: 'narration',
    isRequired: false,
  },
];

const NotationSection = (props) => {
  return <FormSection secondary sectionColumn={1} formFields={fields} {...props} />;
};

export default NotationSection;
