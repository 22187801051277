import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import PrintReceiptHeader from '../../snippets/PrintReceiptHeader';

const styles = {
  tableStyles: { border: '1px solid black', width: '100%' },
  cellStyles: {
    fontSize: '14px',
    padding: '5px 5px',
    textAlign: 'center',
    border: '1px solid black',
  },
};

const AdminList = React.forwardRef(({ data, columns }, ref) => (
  <Box ref={ref} data={data}>
    <Box>
      <PrintReceiptHeader />
    </Box>
    <Text w="100%" textAlign="center" fontSize="2xl" fontWeight="bold" pb={4}>
      List of Admins
    </Text>

    {data?.length > 0 ? (
      <Box my={2}>
        <table style={styles.tableStyles}>
          <thead>
            <tr>
              <th style={styles.cellStyles}>Sl. No.</th>
              {columns?.map((columnItem, colIndex) => (
                <th key={colIndex} style={styles.cellStyles}>
                  {columnItem?.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((rowItem, rowIndex) => (
              <tr key={rowIndex}>
                <td style={styles.cellStyles}>{rowIndex + 1}</td>
                {columns?.map((columnItem, colIndex) => (
                  <td key={colIndex} style={styles.cellStyles}>
                    {rowItem?.[columnItem?.accessor]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    ) : null}
  </Box>
));

AdminList.displayName = 'AdminList';

export default AdminList;
