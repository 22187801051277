import React from 'react';
import { FormControl, FormLabel, FormErrorMessage, Select } from '@chakra-ui/react';

import { Controller } from 'react-hook-form';

const SelectField = ({ control, name, label, choices, isInvalid, errorMessage }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name } }) => (
        <FormControl isInvalid={isInvalid}>
          <FormLabel mb={1.5} fontSize="md">
            {label}
          </FormLabel>
          <Select
            name={name}
            colorScheme="primary"
            focusBorderColor="primary.300"
            h="50px"
            borderRadius="4px"
            bg="formInputBG"
            fontSize="lg"
            onChange={onChange}
            placeholder="Select an option"
            value={value}>
            {choices.map((c, index) => (
              <option
                style={{ backgroundColor: '#e9e4ff', fontSize: '18px' }}
                key={index}
                value={c.value}>
                {c.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};
export default SelectField;
