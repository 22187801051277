import React from 'react';
import LogoutAndSettings from './LogoutAndSettings';
import { useHistory, Link } from 'react-router-dom';
import { Flex, HStack, Text } from '@chakra-ui/react';

const Header = () => {
  const history = useHistory();
  const state = history?.location?.state;

  const getUrlParts = () => {
    return history.location.pathname.split('/').filter((part) => part !== '');
  };

  return (
    <Flex
      px="20px"
      h="100%"
      w="100%"
      className="header"
      alignItems="center"
      justifyContent="space-between">
      <HStack>
        <Text color="textSecondary">{getUrlParts()?.[0]}</Text>
        {getUrlParts()?.[1] ? (
          <>
            <Text>{'>'}</Text>
            <Link to={{ pathname: `/${getUrlParts()?.[0]}/${getUrlParts()?.[1]}`, state }}>
              {getUrlParts()?.[1]}
            </Link>
          </>
        ) : null}
        {getUrlParts()?.[2] ? (
          <>
            <Text>{'>'}</Text>
            <Link to={{ pathname: history.location.pathname, state }}>{getUrlParts()?.[2]}</Link>
          </>
        ) : null}
      </HStack>

      <HStack h="100%">
        <LogoutAndSettings />
      </HStack>
    </Flex>
  );
};

export default Header;
