import axios from './axios';

export const getAllAdminUsers = async (data) => {
  try {
    const res = await axios.get('/users/', { params: data });
    if (res.status === 200)
      return {
        data: {
          ...res.data,
          page: data?.page || 1,
          pageSize: data?.pageSize || 10,
        },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const patchAdminUser = async (data) => {
  try {
    const res = await axios.patch(`/users/${data?.uid}`, data?.userData);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const createAdminUser = async (data) => {
  try {
    const res = await axios.post('/users/', data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const verifyAdminUser = async (data) => {
  try {
    const res = await axios.post('/users/verify', data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const generateOtp = async (uid) => {
  try {
    const res = await axios.post(`/users/otp/${uid}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};
