import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'pWNoOfMachine',
    type: 'text',
    label: 'No. of Pirn Winder Machine',
  },
  {
    colSpan: 1,
    name: 'pWCountryOrManufacturer',
    type: 'text',
    label: 'Country/ Manufacturer',
  },
  {
    colSpan: 1,
    name: 'pWYearOfManufacture',
    type: 'text',
    label: 'Year of Manufacture',
  },
  {
    fieldType: 'radio',
    colSpan: 1,
    name: 'pWOption',
    defaultValue: 'New',
    choices: [
      { name: 'New', value: 'New' },
      { name: 'Re-conditioned', value: 'Re-conditioned' },
    ],
  },

  {
    colSpan: 1,
    name: 'wrNoOfMachine',
    type: 'text',
    label: 'No. of Warping Machine',
  },
  {
    colSpan: 1,
    name: 'wrCountryOrManufacturer',
    type: 'text',
    label: 'Country/ Manufacturer',
  },
  {
    colSpan: 1,
    name: 'wrYearOfManufacture',
    type: 'text',
    label: 'Year of Manufacture',
  },
  {
    fieldType: 'radio',
    colSpan: 1,
    name: 'wrOption',
    defaultValue: 'New',
    choices: [
      { name: 'New', value: 'New' },
      { name: 'Re-conditioned', value: 'Re-conditioned' },
    ],
  },

  {
    colSpan: 1,
    name: 'sMNoOfMachine',
    type: 'text',
    label: 'No. of Sizing Machine',
  },
  {
    colSpan: 1,
    name: 'sMCountryOrManufacturer',
    type: 'text',
    label: 'Country/ Manufacturer',
  },
  {
    colSpan: 1,
    name: 'sMYearOfManufacture',
    type: 'text',
    label: 'Year of Manufacture',
  },
  {
    fieldType: 'radio',
    colSpan: 1,
    name: 'sMOption',
    defaultValue: 'New',
    choices: [
      { name: 'New', value: 'New' },
      { name: 'Re-conditioned', value: 'Re-conditioned' },
    ],
  },

  {
    colSpan: 1,
    name: 'wLNoOfMachine',
    type: 'text',
    label: 'No. of Weaving Loom Machine',
  },
  {
    colSpan: 1,
    name: 'wLCountryOrManufacturer',
    type: 'text',
    label: 'Country/ Manufacturer',
  },
  {
    colSpan: 1,
    name: 'wLYearOfManufacture',
    type: 'text',
    label: 'Year of Manufacture',
  },
  {
    fieldType: 'radio',
    colSpan: 1,
    name: 'wLOption',
    defaultValue: 'New',
    choices: [
      { name: 'New', value: 'New' },
      { name: 'Re-conditioned', value: 'Re-conditioned' },
    ],
  },

  {
    colSpan: 1,
    name: 'sNoOfMachine',
    type: 'text',
    label: 'No. of Shuttleless Machine',
  },
  {
    colSpan: 1,
    name: 'sCountryOrManufacturer',
    type: 'text',
    label: 'Country/ Manufacturer',
  },
  {
    colSpan: 1,
    name: 'sYearOfManufacture',
    type: 'text',
    label: 'Year of Manufacture',
  },
  {
    fieldType: 'radio',
    colSpan: 1,
    name: 'sOption',
    defaultValue: 'New',
    choices: [
      { name: 'New', value: 'New' },
      { name: 'Re-conditioned', value: 'Re-conditioned' },
    ],
  },

  {
    colSpan: 1,
    name: 'rMNoOfMachine',
    type: 'text',
    label: 'No. of Repair Machine',
  },
  {
    colSpan: 1,
    name: 'rMCountryOrManufacturer',
    type: 'text',
    label: 'Country/ Manufacturer',
  },
  {
    colSpan: 1,
    name: 'rMYearOfManufacture',
    type: 'text',
    label: 'Year of Manufacture',
  },
  {
    fieldType: 'radio',
    colSpan: 1,
    name: 'rMOption',
    defaultValue: 'New',
    choices: [
      { name: 'New', value: 'New' },
      { name: 'Re-conditioned', value: 'Re-conditioned' },
    ],
  },

  {
    colSpan: 1,
    name: 'wtNoOfMachine',
    type: 'text',
    label: 'No. of Waterjet Machine',
  },
  {
    colSpan: 1,
    name: 'wtCountryOrManufacturer',
    type: 'text',
    label: 'Country/ Manufacturer',
  },
  {
    colSpan: 1,
    name: 'wtYearOfManufacture',
    type: 'text',
    label: 'Year of Manufacture',
  },
  {
    fieldType: 'radio',
    colSpan: 1,
    name: 'wtOption',
    defaultValue: 'New',
    choices: [
      { name: 'New', value: 'New' },
      { name: 'Re-conditioned', value: 'Re-conditioned' },
    ],
  },

  {
    colSpan: 1,
    name: 'aMNoOfMachine',
    type: 'text',
    label: 'No. of Airjet Machine',
  },
  {
    colSpan: 1,
    name: 'aMCountryOrManufacturer',
    type: 'text',
    label: 'Country/ Manufacturer',
  },
  {
    colSpan: 1,
    name: 'aMYearOfManufacture',
    type: 'text',
    label: 'Year of Manufacture',
  },
  {
    fieldType: 'radio',
    colSpan: 1,
    name: 'aMOption',
    defaultValue: 'New',
    choices: [
      { name: 'New', value: 'New' },
      { name: 'Re-conditioned', value: 'Re-conditioned' },
    ],
  },

  {
    colSpan: 1,
    name: 'nWNoOfMachine',
    type: 'text',
    label: 'No. of Non-woven Machine',
  },
  {
    colSpan: 1,
    name: 'nWCountryOrManufacturer',
    type: 'text',
    label: 'Country/ Manufacturer',
  },
  {
    colSpan: 1,
    name: 'nWYearOfManufacture',
    type: 'text',
    label: 'Year of Manufacture',
  },
  {
    fieldType: 'radio',
    colSpan: 1,
    name: 'nWOption',
    defaultValue: 'New',
    choices: [
      { name: 'New', value: 'New' },
      { name: 'Re-conditioned', value: 'Re-conditioned' },
    ],
  },

  {
    colSpan: 1,
    name: 'fMNoOfMachine',
    type: 'text',
    label: 'No. of Folding Machine',
  },
  {
    colSpan: 1,
    name: 'fMCountryOrManufacturer',
    type: 'text',
    label: 'Country/ Manufacturer',
  },
  {
    colSpan: 1,
    name: 'fMYearOfManufacture',
    type: 'text',
    label: 'Year of Manufacture',
  },
  {
    fieldType: 'radio',
    colSpan: 1,
    name: 'fMOption',
    defaultValue: 'New',
    choices: [
      { name: 'New', value: 'New' },
      { name: 'Re-conditioned', value: 'Re-conditioned' },
    ],
  },

  {
    colSpan: 1,
    name: 'oMNoOfMachine',
    type: 'text',
    label: 'No. of Others Machine',
  },
  {
    colSpan: 1,
    name: 'oMCountryOrManufacturer',
    type: 'text',
    label: 'Country/ Manufacturer',
  },
  {
    colSpan: 1,
    name: 'oMYearOfManufacture',
    type: 'text',
    label: 'Year of Manufacture',
  },
  {
    fieldType: 'radio',
    colSpan: 1,
    name: 'oMOption',
    defaultValue: 'New',
    choices: [
      { name: 'New', value: 'New' },
      { name: 'Re-conditioned', value: 'Re-conditioned' },
    ],
  },
];

export const WeavMachineriesFieldsValidation = {
  [fields[0].name]: yup.string(),
  [fields[1].name]: yup.string(),
  [fields[2].name]: yup.string(),
  [fields[3].name]: yup.string(),

  [fields[4].name]: yup.string(),
  [fields[5].name]: yup.string(),
  [fields[6].name]: yup.string(),
  [fields[7].name]: yup.string(),

  [fields[8].name]: yup.string(),
  [fields[9].name]: yup.string(),
  [fields[10].name]: yup.string(),
  [fields[11].name]: yup.string(),

  [fields[12].name]: yup.string(),
  [fields[13].name]: yup.string(),
  [fields[14].name]: yup.string(),
  [fields[15].name]: yup.string(),

  [fields[16].name]: yup.string(),
  [fields[17].name]: yup.string(),
  [fields[18].name]: yup.string(),
  [fields[19].name]: yup.string(),

  [fields[20].name]: yup.string(),
  [fields[21].name]: yup.string(),
  [fields[22].name]: yup.string(),
  [fields[23].name]: yup.string(),

  [fields[24].name]: yup.string(),
  [fields[25].name]: yup.string(),
  [fields[26].name]: yup.string(),
  [fields[27].name]: yup.string(),

  [fields[28].name]: yup.string(),
  [fields[29].name]: yup.string(),
  [fields[30].name]: yup.string(),
  [fields[31].name]: yup.string(),

  [fields[32].name]: yup.string(),
  [fields[33].name]: yup.string(),
  [fields[34].name]: yup.string(),
  [fields[35].name]: yup.string(),
};

const WeavMachineriesSection = (props) => {
  return <FormSection sectionName="Machineries" sectionColumn={4} formFields={fields} {...props} />;
};

export default WeavMachineriesSection;
