//modules
import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, GridItem, Box, Divider, Button, useDisclosure } from '@chakra-ui/react';

//common
import Rework from '../Modals/Rework';
import RenderModal from '../RenderModal';
import Loader from '../../../snippets/Loader';
import FileUploader from '../../../common/FileUploader';
import Footer from '../../../common/membershipFormComponents/Footer';
import AltFooter from '../../../common/membershipFormComponents/AltFooter';
import GallerySection from '../../../common/membershipFormComponents/GallerySection';
import MembershipFormWrapper from '../../../common/membershipFormComponents/MembershipFormWrapper';

//form sections
import NoteSection, { noteFieldValidation } from '../NoteSection';

//api and actions
import { patchMembershipData, patchMembershipDocs } from '../../../../api/membership';
import { notify } from '../../../../store/actions/globalActions';

//utils
import _ from 'lodash';
import { fileValidation, useYupValidationResolver } from '../../../../util/yupUtils';

//client form sections
import {
  HumanResources,
  FactoryOperation,
  HumanResourcesFieldsValidation,
  FactoryOperationFieldsValidation,
} from './clientSections';

const OtherInfo = ({
  refresh,
  nextStep,
  prevStep,
  currStep,
  stepList,
  infoView,
  backToTable,
  defaultFields,
}) => {
  const { uid } = useParams();
  const dispatch = useDispatch();
  const fileUploaderRef = useRef();
  const gallerySectionRef = useRef();
  const [modal, setModal] = useState();
  const [updateData, setUpdateData] = useState();
  const [modalComponent, setModalComponent] = useState();
  const [submittingDoc, setSubmittingDoc] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure(); //control hook for modal
  const data = useSelector((state) => state.membership.currentRequest);

  const documentsInfo = [
    {
      header: 'Fire License',
      link: data?.fireLicense,
    },
    {
      header: 'Tin Certificate',
      link: data?.tinCert,
    },
    {
      header: 'Vat Registration',
      link: data?.vatRegistration,
    },
    {
      header: 'Valid Trade License',
      link: data?.tradeLicense,
    },
    {
      header: 'Group Insurance Policy',
      link: data?.groupInsurancePolicy,
    },
    {
      header: 'Registration Certificate',
      link: data?.registrationCert,
    },
    {
      header: 'Incorporation Certificate',
      link: data?.incorporationCert,
    },
    {
      header: 'Memorandum of Association',
      link: data?.moa,
    },
    {
      header: 'Lease Documents (If Rented)',
      link: data?.leaseDocument,
    },
    {
      header: 'Electricity/Gas Bill For Last 3 Months',
      link: data?.utilityBill,
    },
    {
      header: 'Environment Certificate (related government organization)',
      link: data?.environmentCert,
    },
    {
      header: 'Machinery Procurement Proof Documents (LC, Invoice, Bill of Entry)',
      link: data?.machineryProcurement,
    },
    {
      header: 'Certificate of the Department of Inspection for Factories and Establishments (DIFE)',
      link: data?.difeCert,
    },
    {
      header: 'Colored Image of The Managing Director',
      link: data?.managingDirector,
    },
    {
      header: 'Additional Document (If any)',
      link: data?.additional,
    },
  ];

  const defaultDocs = {
    fireLicense: data?.fireLicense,
    tinCert: data?.tinCert,
    vatRegistration: data?.vatRegistration,
    tradeLicense: data?.tradeLicense,
    groupInsurancePolicy: data?.groupInsurancePolicy,
    registrationCert: data?.registrationCert,
    incorporationCert: data?.incorporationCert,
    moa: data?.moa,
    leaseDocument: data?.leaseDocument,
    utilityBill: data?.utilityBill,
    environmentCert: data?.environmentCert,
    machineryProcurement: data?.machineryProcurement,
    difeCert: data?.difeCert,
    managingDirector: data?.managingDirector,
    additional: data?.additional,
  };

  const resolver = useYupValidationResolver(
    yup.object().shape({
      ...noteFieldValidation,
      ...HumanResourcesFieldsValidation,
      ...FactoryOperationFieldsValidation,
    }),
  );
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: {
      ...defaultFields,
    },
  });

  const handleQuery = async (queryData, changeStep, flag) => {
    if (flag) {
      const res = await patchMembershipData({
        formData: {
          humanResources: {
            ...queryData,
          },
        },
        step: currStep,
        param: uid,
      });
      if (res.data?.status == 200) {
        dispatch(
          notify({
            title: 'Success',
            description: res.data.msg,
            status: 'success',
            duration: 5000,
          }),
        );
        changeStep();
      }
    } else {
      const cleanQueryData = _.pickBy(queryData, _.identity);
      const cleanDefaultFields = _.pickBy(defaultFields, _.identity);
      if (_.isEqual(cleanQueryData, cleanDefaultFields) === false) {
        const res = await patchMembershipData({
          formData: {
            humanResources: {
              ...queryData,
            },
          },
          step: currStep,
          param: uid,
        });
        if (res.data?.status == 200) {
          dispatch(
            notify({
              title: 'Success',
              description: res.data.msg,
              status: 'success',
              duration: 5000,
            }),
          );
          changeStep();
        }
      } else {
        changeStep();
      }
    }
  };

  const onSubmit = (formdata) => {
    const cleanQueryData = _.pickBy(formdata, _.identity);
    const cleanDefaultFields = _.pickBy(defaultFields, _.identity);
    if (_.isEqual(cleanQueryData, cleanDefaultFields) === false) {
      setUpdateData(formdata);
      setModal('UpdateAlert');
      onOpen();
    } else {
      setModal('NoUpdate');
      onOpen();
    }
  };

  const onForwardSubmit = (formData) => {
    handleQuery(formData, nextStep);
  };

  const onBackwardSubmit = (formData) => {
    handleQuery(formData, prevStep);
  };

  const handleBackToTable = () => {
    setModal('BackToTableAlert');
    onOpen();
  };

  const handleRework = () => {
    setModal('ReworkModal');
    setModalComponent(<Rework close={onClose} backToTable={backToTable} />);
    onOpen();
  };

  const handleReject = () => {
    setModal('RejectModal');
    onOpen();
  };

  const rejectMembershipRequest = async () => {
    const res = await patchMembershipData({ param: uid, reject: true });
    if (res.data?.status == 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      onClose();
      backToTable();
    }
  };

  /********** Doc Uploader **********/
  const fields = [
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'fireLicense',
      type: 'file',
      label: 'Fire License',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'tinCert',
      type: 'file',
      label: 'Tin Certificate',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'vatRegistration',
      type: 'file',
      label: 'Vat Registration',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'tradeLicense',
      type: 'file',
      label: 'Valid Trade License',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'groupInsurancePolicy',
      type: 'file',
      label: 'Group Insurance Policy',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'registrationCert',
      type: 'file',
      label: 'Registration Certificate',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'incorporationCert',
      type: 'file',
      label: 'Incorporation Certificate',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'moa',
      type: 'file',
      label: 'Memorandum of Association',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'leaseDocument',
      type: 'file',
      label: 'Lease Documents (If Rented)',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'utilityBill',
      type: 'file',
      label: 'Electricity/Gas Bill For Last 3 Months',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'environmentCert',
      type: 'file',
      label: 'Environment Certificate (related government organization)',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'machineryProcurement',
      type: 'file',
      label: 'Machinery Procurement Proof Documents (LC, Invoice, Bill of Entry)',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'difeCert',
      type: 'file',
      label: 'Certificate of the Department of Inspection for Factories and Establishments (DIFE)',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'managingDirector',
      type: 'file',
      label: 'Colored Image of The Managing Director',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'additional',
      type: 'file',
      label: 'Additional Document (If any)',
    },
  ];

  const fieldsValidation = {
    [fields[0].name]: fileValidation.optional,
    [fields[1].name]: fileValidation.optional,
    [fields[2].name]: fileValidation.optional,
    [fields[3].name]: fileValidation.optional,
    [fields[4].name]: fileValidation.optional,
    [fields[5].name]: fileValidation.optional,
    [fields[6].name]: fileValidation.optional,
    [fields[7].name]: fileValidation.optional,
    [fields[8].name]: fileValidation.optional,
    [fields[9].name]: fileValidation.optional,
    [fields[10].name]: fileValidation.optional,
    [fields[11].name]: fileValidation.optional,
    [fields[12].name]: fileValidation.optional,
    [fields[13].name]: fileValidation.optional,
    [fields[14].name]: fileValidation.optional,
  };

  const handleFileQuery = async (docData) => {
    let formData = new FormData();

    const {
      moa,
      tinCert,
      difeCert,
      additional,
      fireLicense,
      utilityBill,
      tradeLicense,
      leaseDocument,
      vatRegistration,
      environmentCert,
      registrationCert,
      managingDirector,
      incorporationCert,
      groupInsurancePolicy,
      machineryProcurement,
    } = docData;

    const sendDocData = {
      moa,
      tinCert,
      difeCert,
      additional,
      fireLicense,
      utilityBill,
      tradeLicense,
      leaseDocument,
      vatRegistration,
      environmentCert,
      registrationCert,
      managingDirector,
      incorporationCert,
      groupInsurancePolicy,
      machineryProcurement,
    };

    const isFileUpdated = () => {
      let fileUpdateFlag = false;
      for (const key in sendDocData) {
        if (sendDocData?.[key]?.length > 0) {
          fileUpdateFlag = true;
        }
      }
      return fileUpdateFlag;
    };

    if (isFileUpdated()) {
      for (const key in sendDocData) {
        if (sendDocData?.[key]?.length > 0) {
          formData.append(key, sendDocData[key][0]);
        }
      }

      const res = await patchMembershipDocs(formData, data?.id);
      if (res.data?.status === 200) {
        gallerySectionRef?.current?.closeUploader();
        dispatch(
          notify({
            title: 'Success',
            description: res.data.msg,
            status: 'success',
            duration: 5000,
          }),
        );
        refresh();
      }
    } else {
      gallerySectionRef?.current?.closeUploader();
    }
  };
  /********** Doc Uploader **********/

  return data ? (
    <Box>
      <MembershipFormWrapper formName="Other Information">
        <Grid templateColumns="repeat(2, 1fr)" bg="white" gap={3} p={3}>
          <GridItem colSpan={2}>
            <HumanResources register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
          <GridItem colSpan={2}>
            <FactoryOperation register={register} control={control} errors={errors} />
          </GridItem>
        </Grid>
        <Box mt={3} p={3} bg="white">
          <NoteSection register={register} control={control} errors={errors} />
        </Box>
        <Divider my={4} />
        {/* docGallery */}
        <Grid templateColumns="repeat(2, 1fr)" gap={3}>
          <GridItem colSpan={2}>
            <GallerySection
              update={infoView}
              sectionName="Documents"
              ref={gallerySectionRef}
              isLoading={submittingDoc}
              galleryFields={documentsInfo}
              handleDocUpdate={() => fileUploaderRef.current.submit()}
              uploaderComponent={
                <FileUploader
                  fields={fields}
                  ref={fileUploaderRef}
                  documents={defaultDocs}
                  handleQuery={handleFileQuery}
                  submitting={setSubmittingDoc}
                  fieldsValidation={fieldsValidation}
                />
              }
            />
          </GridItem>
        </Grid>
      </MembershipFormWrapper>
      {infoView ? (
        <AltFooter back={backToTable} buttonLoading={isSubmitting}>
          <Button
            type="submit"
            variant="footerUpdate"
            isLoading={isSubmitting}
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onSubmit)();
            }}>
            Update
          </Button>
        </AltFooter>
      ) : (
        <Footer
          currStep={currStep}
          stepList={stepList}
          rework={handleRework}
          reject={handleReject}
          back={handleBackToTable}
          buttonLoading={isSubmitting}
          next={(event) => {
            event.preventDefault();
            handleSubmit(onForwardSubmit)();
          }}
          prev={(event) => {
            event.preventDefault();
            handleSubmit(onBackwardSubmit)();
          }}
        />
      )}
      <RenderModal
        modal={modal}
        step={currStep}
        isOpen={isOpen}
        onClose={onClose}
        backToTable={backToTable}
        modalComponent={modalComponent}
        confirmReject={rejectMembershipRequest}
        confirmSubmit={() => handleQuery(updateData, refresh, true)}
      />
    </Box>
  ) : (
    <Loader />
  );
};

export default OtherInfo;
