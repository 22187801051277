import { useCallback } from 'react';
import * as yup from 'yup';
import {
  FILE_SIZE_LIMIT_IN_BYTES,
  IMAGE_SIZE_LIMIT_IN_BYTES,
  SUPPORTED_FILE_FORMATS,
  SUPPORTED_IMAGE_FORMATS,
} from '../constants/fileConstant';
import { PHONE_NO_REGEX, WEBSITE_REGEX, ZIP_CODE_REGEX } from '../constants/formValidationRegex';

export const websiteValidation = yup
  .string()
  .matches(WEBSITE_REGEX, 'Enter correct website url!')
  .transform((cv, ov) => {
    return ov === '' ? undefined : cv;
  });
export const dateValidation = yup
  .date()
  .nullable()
  .transform((cv, ov) => (ov === '' ? null : cv));

export const mobileNoValidation = yup
  .string()
  .matches(
    PHONE_NO_REGEX,
    'Not a valid mobile no. Try using (+8801XXXXXXXXX/01XXXXXXXXX/0XXXXXXXX)',
  )
  .transform((cv, ov) => {
    return ov === '' ? undefined : cv;
  });

export const zipCodeValidation = yup
  .string()
  .matches(ZIP_CODE_REGEX, 'Not a valid Postal Code, enter a four digit code.')
  .transform((cv, ov) => {
    return ov === '' ? undefined : cv;
  });

export const imageValidation = {
  optional: yup
    .mixed()
    .notRequired()
    .default(null)
    .test('fileSize', 'The file is too large, keep it within 2MB', (file) =>
      file.length ? file[0].size <= IMAGE_SIZE_LIMIT_IN_BYTES : true,
    )
    .test(
      'fileType',
      'This file type is not allowed, use a jpg, jpeg or png file instead.',
      (file) => (file.length ? SUPPORTED_IMAGE_FORMATS.includes(file[0].type) : true),
    ),
  required: yup
    .mixed()
    .test('file', 'This file is required', (file) => (file.length ? file.length > 0 : false))
    .test('fileSize', 'The file is too large, keep it within 2MB', (file) =>
      file.length ? file[0].size <= IMAGE_SIZE_LIMIT_IN_BYTES : true,
    )
    .test(
      'fileType',
      'This file type is not allowed, use a jpg, jpeg or png file instead.',
      (file) => (file.length ? SUPPORTED_IMAGE_FORMATS.includes(file[0].type) : true),
    ),
};

export const fileValidation = {
  optional: yup
    .mixed()
    .notRequired()
    .default(null)
    .test('fileSize', 'The file is too large, keep it within 5MB', (file) =>
      file.length ? file[0].size <= FILE_SIZE_LIMIT_IN_BYTES : true,
    )
    .test(
      'fileType',
      'This file type is not allowed, use a .pdf/.jpg/.jpeg/.png file instead.',
      (file) => (file.length ? SUPPORTED_FILE_FORMATS.includes(file[0].type) : true),
    ),
  required: yup
    .mixed()
    .test('file', 'This file is required', (file) => (file.length ? file.length > 0 : false))
    .test('fileSize', 'The file is too large, keep it within 5MB', (file) =>
      file.length ? file[0].size <= FILE_SIZE_LIMIT_IN_BYTES : true,
    )
    .test(
      'fileType',
      'This file type is not allowed, use a .pdf/.jpg/.jpeg/.png file instead.',
      (file) => (file.length ? SUPPORTED_FILE_FORMATS.includes(file[0].type) : true),
    ),
};

export const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {},
          ),
        };
      }
    },
    [validationSchema],
  );
