import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, HStack, Box, Text, Grid, GridItem, useDisclosure } from '@chakra-ui/react';

//common components
import Loader from '../../../snippets/Loader';
import InfoTiles from '../../../common/InfoTiles';
import FormButton from '../../../common/FormButton';
import DialogueBox from '../../../common/DialogueBox';
import InfoSection from '../../../common/InfoSection';
import ReworkMessageModal from '../../../common/ReworkMessageModal';
import DocumentGallery from '../../../common/membershipFormComponents/GallerySection';
import MembershipFormWrapper from '../../../common/membershipFormComponents/MembershipFormWrapper';
import SelectCertificateSection from '../../commonSections/SelectCertificateSection';

//form sections
import RequiredInfoSection from './RequiredInfoSection';
import DutyIssueDateSection, { dutyIssueDateValidation } from './DutyIssueDateSection';

//actions & api
import {
  getAllApplications,
  getApplicationInfo,
  getUnusedCertificates,
  patchApplicationInfo,
  updateApplicationStatus,
} from '../../../../api/certificates';
import {
  FETCH_DUTY_APPLICATIONS,
  FETCH_APPLICATION_INFO,
} from '../../../../store/actions/actionTypes';
import { notify } from '../../../../store/actions/globalActions';

//utils & constants
import _ from 'lodash';
import { DUTY_CERT_URL } from '../../../../constants/routerUrl';
import { formatDate, renderDate } from '../../../../util/formatUtils';
import { getConversionRate, getConversionRates } from '../../../../api/currency';
import { useYupValidationResolver } from '../../../../util/yupUtils';

const DutyExemptionVer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [modal, setModal] = useState();
  const [formData, setFormData] = useState();
  const [loader, setLoader] = useState(false);
  const [certificate, setCertificate] = useState();
  const [certErrorMsg, setCertErrorMsg] = useState();
  const [millUnusedCerts, setMillUnusedCerts] = useState();
  const [conversions, setConversions] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const data = useSelector((state) => state.certificate.currentData);
  const { applicationId } = history.location.state;

  const fetchUnusedCertificates = async (millId) => {
    setMillUnusedCerts();
    const res = await getUnusedCertificates({ type: 'duty', millId });
    if (res.data?.status === 200) {
      setMillUnusedCerts(res.data?.certificates);
    }
    setCertificate();
  };

  const fetchCertificateData = async () => {
    const res = await getApplicationInfo({ type: 'duty', id: applicationId });
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_APPLICATION_INFO, payload: res.data });
      fetchUnusedCertificates(res.data?.millInfo?.millId);
    }
  };

  useEffect(() => {
    fetchCertificateData();

    (async () => {
      const response = await getConversionRates();
      setConversions(response?.data);
    })();
  }, []);

  const millInfo = [
    { header: 'Mill Name', value: data?.millInfo?.millName },
    { header: 'Membership No', value: data?.millInfo?.membershipNo },
    { header: 'Membership Type', value: data?.millInfo?.membershipType },
    { header: 'Contact', value: data?.millInfo?.phoneNo },
  ];

  const availableInfoFields = [
    { header: 'Category', value: data?.category },
    { header: 'Item Name', value: data?.itemName },
    { header: 'L/C No.', value: data?.masterLc },
    { header: 'L/C Date', value: renderDate(data?.dateOfLc) },
    { header: 'L/C Amount', value: data?.value },
    { header: 'Quantity in KG', value: data?.quantity },
    { header: 'Currency', value: data?.currency },
    { header: 'Invoice No.', value: data?.invoice },
    { header: 'Invoice Date', value: renderDate(data?.invoiceDate) },
    { header: 'BL No.', value: data?.blNo },
    { header: 'BL Date', value: renderDate(data?.blDate) },
  ];

  const resolver = useYupValidationResolver(
    yup.object().shape({
      ...dutyIssueDateValidation,
    }),
  );

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: { issueDate: new Date() },
  });

  const value = useWatch({ control, name: 'value' });
  const currency = useWatch({ control, name: 'currency' });
  const conversionRate = useWatch({ control, name: 'conversionRate' });

  useEffect(() => {
    if (data && conversions) {
      setValue('value', data?.value);
      setValue('itemName', data?.itemName);
      setValue('currency', data?.currency);
      setValue('quantity', data?.quantity);
      setValue('conversionRate', getConversionRate(currency, conversions));
      setValue(
        'total',
        parseFloat(
          parseFloat(data?.value || 0) * parseFloat(getConversionRate(currency, conversions) || 0),
        ).toFixed(2),
      );
    }
  }, [data, conversions]);

  useEffect(() => {
    setValue('conversionRate', getConversionRate(currency, conversions));
  }, [currency]);

  useEffect(() => {
    if (value && currency && conversionRate) {
      setValue(
        'total',
        Math.ceil(parseFloat(parseFloat(value || 0) * parseFloat(conversionRate)).toFixed(2)),
      );
    } else {
      setValue('total', parseFloat(value || 0).toFixed(2));
    }
  }, [conversionRate, value]);

  useEffect(() => {
    if (certificate?.invoiceId) {
      setCertErrorMsg('');
    } else {
      setCertErrorMsg('Please Select an Invoice');
    }
  }, [certificate]);

  const onSubmit = (formdata) => {
    let normalizedFormData = _.mapKeys(formdata, (value, key) => {
      if (value) {
        return key;
      }
    });
    setFormData({
      ...normalizedFormData,
      category: data?.category,
      invoiceId: certificate?.invoiceId,
      issueDate: formatDate(normalizedFormData?.issueDate),
    });
    setModal('SUBMIT');
    onOpen();
  };

  const confirmSubmit = async () => {
    const res = await patchApplicationInfo({ type: 'duty', id: data?.id, formData });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      const response = await getAllApplications({
        type: 'duty',
        params: { page: 1, pageSize: 10 },
      });
      if (response.data?.status === 200) {
        dispatch({ type: FETCH_DUTY_APPLICATIONS, payload: response.data });
      }
      history.push(DUTY_CERT_URL);
    }
  };

  const handleRework = async (reworkData) => {
    setLoader(true);
    const res = await updateApplicationStatus({
      id: data?.id,
      formData: { status: 'REWORK', remarks: reworkData?.reason },
    });
    if (res.data?.status === 200) {
      const res = await getAllApplications({
        type: 'duty',
        params: { page: 1, pageSize: 10 },
      });
      if (res.data?.status === 200) {
        dispatch({ type: FETCH_DUTY_APPLICATIONS, payload: res.data });
      }
      history.push(DUTY_CERT_URL);
    }
    setLoader(false);
  };

  const confirmReject = async () => {
    setLoader(true);
    const res = await updateApplicationStatus({
      id: data?.id,
      formData: { status: 'REJECTED', remarks: 'This application has been rejected!' },
    });
    if (res.data?.status === 200) {
      const res = await getAllApplications({
        type: 'duty',
        params: { page: 1, pageSize: 10 },
      });
      if (res.data?.status === 200) {
        dispatch({ type: FETCH_DUTY_APPLICATIONS, payload: res.data });
      }
      history.push(DUTY_CERT_URL);
    }
    setLoader(false);
  };

  const renderModal = () => {
    switch (modal) {
      case 'REWORK':
        return (
          <ReworkMessageModal mountControl={isOpen} close={onClose} performAction={handleRework} />
        );
      case 'REJECT':
        return (
          <DialogueBox
            close={onClose}
            mountControl={isOpen}
            performAction={confirmReject}
            alertTitle="REJECT Application"
            alertMessage="Are you sure you want to reject  this application?"
          />
        );
      case 'SUBMIT':
        return (
          <DialogueBox
            close={onClose}
            mountControl={isOpen}
            performAction={confirmSubmit}
            alertTitle="Verify Application"
            alertMessage="Are you sure you want to submit these information and verify this application?"
          />
        );
      default:
        return null;
    }
  };

  const getBadgeColorScheme = () => {
    switch (data?.applicationStatus) {
      case 'APPLIED':
        return 'gray';
      case 'IN REVIEW':
        return 'yellow';
      case 'REWORK':
        return 'blue';
      case 'APPROVED':
        return 'green';
      case 'REJECTED':
        return 'red';
      default:
        return null;
    }
  };

  const defaultFiles = [
    {
      header: 'Additional Documents',
      link: data?.additionalDocuments,
    },
  ];

  return data && !loader ? (
    <Box>
      <Box p={4}>
        <InfoTiles sectionName="Mill Information" sectionColumn={4} infoFields={millInfo} />
      </Box>

      <Box bg="white" m={4} p={4} borderRadius="4px" border="1px solid #3e1cfd">
        {data?.certificateNum ? (
          <HStack>
            <Text fontSize="20px" fontWeight="bold" color="textSecondary">
              Certificate No:
            </Text>
            <Text fontSize="16px" fontWeight="bold">
              {data?.certificateNum || 'N/A'}
            </Text>
          </HStack>
        ) : null}
        <HStack>
          <Text fontSize="20px" fontWeight="bold" color="textSecondary">
            Application Status:
          </Text>
          <Badge fontSize="16px" colorScheme={getBadgeColorScheme()}>
            {data?.applicationStatus}
          </Badge>
        </HStack>
        <HStack>
          <Text fontSize="20px" fontWeight="bold" color="textSecondary">
            Remarks:
          </Text>
          <Text fontSize="16px">{data?.remarks || 'N/A'}</Text>
        </HStack>
      </Box>

      <Box bg="white" m={4} p={4} borderRadius="4px">
        {millUnusedCerts?.length > 0 ? (
          <SelectCertificateSection
            type="DUTY"
            error={certErrorMsg}
            certificates={millUnusedCerts}
            setCertificate={setCertificate}
            defaultValue={certificate?.certID}
          />
        ) : (
          <Text fontSize="md" color="invalid">
            No unused invoice found for this mill! The member needs to pay the application fee for a
            certificate.
          </Text>
        )}
      </Box>

      {certificate && (
        <MembershipFormWrapper formName="Duty Exemption">
          <Grid templateColumns="repeat(5, 1fr)" gap={3}>
            <GridItem colSpan={2}>
              <Box mb={3}>
                <InfoSection sectionName="Available Information" infoFields={availableInfoFields} />
              </Box>
              <Box mb={3}>
                <DocumentGallery sectionName="Additional Documents" galleryFields={defaultFiles} />
              </Box>
            </GridItem>
            <GridItem colSpan={3}>
              <Box bg="white" borderRadius="4px">
                <Text px={4} pt={4} pb={1} fontSize="20px" fontWeight="bold">
                  Required Information
                </Text>
                <Box p={4}>
                  <Box bg="blackAlpha.200">
                    <Box border="1px solid red" borderRadius="4px">
                      <DutyIssueDateSection control={control} register={register} errors={errors} />
                    </Box>
                    <RequiredInfoSection
                      errors={errors}
                      control={control}
                      register={register}
                      sectionName="Required Information"
                    />
                  </Box>
                </Box>
              </Box>
            </GridItem>
          </Grid>
          <HStack justifyContent="space-between" alignItems="center" mt={4}>
            <FormButton
              mt={0}
              bg="red.500"
              color="white"
              variant="rework"
              isLoading={isSubmitting}
              _hover={{ bg: 'red.400' }}
              isDisabled={
                data?.applicationStatus === 'REWORK' || data?.applicationStatus === 'REJECTED'
              }
              onClick={() => {
                setModal('REJECT');
                onOpen();
              }}>
              REJECT
            </FormButton>
            <HStack>
              <FormButton
                mt={0}
                variant="rework"
                isLoading={isSubmitting}
                isDisabled={data?.applicationStatus === 'REWORK'}
                onClick={() => {
                  setModal('REWORK');
                  onOpen();
                }}>
                REWORK
              </FormButton>
              <FormButton
                mt={0}
                isLoading={isSubmitting}
                onClick={(event) => {
                  event.preventDefault();
                  handleSubmit(onSubmit)();
                }}>
                SUBMIT
              </FormButton>
            </HStack>
          </HStack>
        </MembershipFormWrapper>
      )}
      {renderModal()}
    </Box>
  ) : (
    <Loader />
  );
};

export default DutyExemptionVer;
