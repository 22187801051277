import React from 'react';
import * as yup from 'yup';
import FieldArrayFormSection from '../../../../common/hookFormComponents/FieldArrayFormSection';

const fields = [
  {
    colSpan: 1,
    name: 'machineName',
    type: 'text',
    label: 'Machine Name',
  },
  {
    colSpan: 1,
    name: 'machineQuantity',
    type: 'text',
    label: 'Quantity',
  },
];

export const defaultOtherInstalledCapacityFieldValues = fields.reduce(
  (obj, item) => [{ ...obj, [item['name']]: '' }],
  [{}],
);

export const OtherInstalledCapacityFieldsValidation = {
  otherInstalledCapacity: yup.array().of(
    yup.object().shape({
      [fields[0].name]: yup.string().label(fields[0].label),
      [fields[1].name]: yup.string().label(fields[1].label),
    }),
  ),
};

const OtherInstalledCapacitySection = (props) => {
  return (
    <FieldArrayFormSection
      sectionColumn={2}
      sectionName="Other Installed Capacity"
      name="otherInstalledCapacity"
      formFields={fields}
      {...props}
    />
  );
};

export default OtherInstalledCapacitySection;
