import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  HStack,
  Modal,
  ModalCloseButton,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { hsCodes } from '../../../../constants/hsCodes';

const AddMultipleHsCodeModal = ({
  mountControl,
  close,
  modalTitle,
  performAction,
  selectedHsCodesInComma,
}) => {
  const [selectedHSCodes, setSelectedHSCodes] = useState([]);

  useEffect(() => {
    if (mountControl) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [mountControl]);

  //mount list from already added hscodes
  useEffect(() => {
    if (selectedHsCodesInComma != null && selectedHsCodesInComma !== '') {
      //now split the comma separated hscodes
      const splitHsCodes = selectedHsCodesInComma.split(',');
      setSelectedHSCodes(splitHsCodes);
    } else {
      setSelectedHSCodes([]);
    }
  }, [selectedHsCodesInComma]);

  const isOpen = mountControl;
  const cancelRef = useRef();

  return (
    <Modal isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={close}>
      <ModalOverlay>
        <Box position="absolute" w="100vw" h="100vh" onClick={close} />
        <Box
          p={4}
          top="50%"
          left="50%"
          maxW="680px"
          maxH="600px"
          minW="400px"
          minH="240px"
          bgColor="white"
          overflowY="auto"
          borderRadius={10}
          overflowX="hidden"
          position="absolute"
          transform="translate(-50%, -50%)"
          boxShadow="0px 5px 10px rgba(0,0,0,0.3)">
          <ModalCloseButton />
          <Text fontSize="lg" fontWeight="bold" textTransform="uppercase" mb={1}>
            {modalTitle || 'Modal'}
          </Text>
          <Divider />
          <HStack justify={selectedHSCodes.length === 0 ? 'space-between' : 'flex-end'} mt={4}>
            {selectedHSCodes.length === 0 && (
              <Text color="red">***Please select H.S. Codes to save</Text>
            )}
            <Button
              isDisabled={selectedHSCodes.length === 0}
              size="md"
              onClick={() => {
                performAction(selectedHSCodes);
                close();
              }}>
              Save
            </Button>
          </HStack>

          <Box mt={4}>
            <CheckboxGroup colorScheme="green" defaultValue={selectedHSCodes}>
              <Stack spacing={[1, 5]} direction={['column']}>
                {hsCodes.map((item, index) => (
                  <Checkbox
                    key={index}
                    value={item.value}
                    onChange={() => {
                      if (selectedHSCodes.includes(item.value)) {
                        setSelectedHSCodes(
                          selectedHSCodes.filter((hsCode) => hsCode !== item.value),
                        );
                      } else {
                        setSelectedHSCodes([...selectedHSCodes, item.value]);
                      }
                    }}>
                    {item.name}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          </Box>
        </Box>
      </ModalOverlay>
    </Modal>
  );
};

export default AddMultipleHsCodeModal;
