import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../../common/hookFormComponents/FormSection';
import { dateValidation } from '../../../../../../util/yupUtils';

const fields = [
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'dateOfEstablishment',
    label: 'Establishment Date',
  },
  {
    colSpan: 1,
    name: 'yearOfOperation',
    type: 'text',
    label: 'Year of Operation',
  },
  {
    colSpan: 1,
    name: 'totalCostOfProject',
    type: 'text',
    label: 'Cost of Project',
  },
  {
    colSpan: 1,
    name: 'sponsors',
    type: 'text',
    label: 'Equity/Sponsors',
  },
  {
    colSpan: 1,
    name: 'natureOfCompany',
    type: 'text',
    label: 'Nature of Company',
  },
  {
    colSpan: 1,
    name: 'mainManufactureActivity',
    type: 'text',
    label: 'Main Manufacturing Activity',
  },
  {
    colSpan: 1,
    name: 'publicIssue',
    type: 'text',
    label: 'Public Issue',
  },
  {
    colSpan: 1,
    name: 'paidupCapitalInTK',
    type: 'text',
    label: 'Bank Finance (Paid-up Capital in TK)',
  },
  {
    colSpan: 1,
    name: 'etp',
    type: 'text',
    label: 'ETP',
  },
  {
    colSpan: 1,
    name: 'licenseNo',
    type: 'text',
    label: 'Bonded Warehouse License No',
  },
];

export const otherInfoFieldsValidation = {
  [fields[0].name]: dateValidation.label(fields[0].label),
  [fields[1].name]: yup.string().label(fields[1].label),
  [fields[2].name]: yup.string().label(fields[2].label),
  [fields[3].name]: yup.string().label(fields[3].label),
  [fields[4].name]: yup.string().label(fields[4].label),
  [fields[5].name]: yup.string().label(fields[5].label),
  [fields[6].name]: yup.string().label(fields[6].label),
  [fields[7].name]: yup.string().label(fields[7].label),
  [fields[8].name]: yup.string().label(fields[8].label),
  [fields[9].name]: yup.string().label(fields[9].label),
};

const OthersInfo = (props) => {
  return (
    <FormSection
      sectionName="Others Information"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default OthersInfo;
