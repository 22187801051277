import React from 'react';
import * as yup from 'yup';
import FieldArrayFormSection from '../../../../common/hookFormComponents/FieldArrayFormSection';

const fields = [
  {
    colSpan: 1,
    name: 'typeOfFabric',
    type: 'text',
    label: 'Type of Fabric',
  },
  {
    colSpan: 1,
    name: 'maximumWidth',
    type: 'text',
    label: 'Maximum Width',
  },
  {
    colSpan: 1,
    fieldType: 'select',
    name: 'unit',
    label: 'Unit',
    choices: [
      { name: 'cm', value: 'cm' },
      { name: 'inch', value: 'in' },
      { name: 'foot', value: 'ft' },
      { name: 'yard', value: 'yd' },
      { name: 'meter', value: 'm' },
      { name: 'other', value: '(unit)' },
    ],
  },
];

export const defaultTypeAndConstructionFieldValues = fields.reduce(
  (obj, item) => ({ ...obj, [item['name']]: '' }),
  {},
);

export const TypeAndConstructionOfFabricFieldsValidation = {
  typeAndConstructionOfFabric: yup.array().of(
    yup.object().shape({
      [fields[0].name]: yup.string(),
      [fields[1].name]: yup.string(),
    }),
  ),
};

const TypeAndConstructionOfFabricSection = (props) => {
  return (
    <FieldArrayFormSection
      sectionName="Type and construction of Fabric"
      sectionColumn={3}
      name="typeAndConstructionOfFabric"
      formFields={fields}
      {...props}
    />
  );
};

export default TypeAndConstructionOfFabricSection;
