import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const getYearList = (year) => {
  let years = [];
  let currYear = year ? parseInt(year) : parseInt(new Date().getFullYear());
  for (let i = 1; i < 15; i++) {
    years.push({ name: `${currYear + i}`, value: `${currYear + i}` });
  }

  return years;
};

const fields = [
  {
    colSpan: 1,
    fieldType: 'select',
    name: 'upto',
    label: 'Extend Upto',
    isRequired: true,
    choices: getYearList(),
  },
];

const yearRestrictedFields = (expYear) => {
  return fields.map((item) => {
    if (item.name === 'upto') {
      return {
        ...item,
        choices: getYearList(expYear),
      };
    } else {
      return item;
    }
  });
};

export const memUptoFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
};

const MemUptoSection = (props) => {
  return (
    <FormSection
      secondary
      sectionColumn={1}
      formFields={yearRestrictedFields(props.year)}
      {...props}
    />
  );
};

export default MemUptoSection;
