export const actionbasedColors = {
  ADD: '#38a169',
  UPDATE: '#dd6b20',
  DELETE: '#e53e3e',
};

export const actiontypes = [
  {
    label: 'Add',
    value: 'ADD',
  },
  {
    label: 'Update',
    value: 'UPDATE',
  },
  {
    label: 'Delete',
    value: 'DELETE',
  },
];

export const sectionModuleTree = [
  {
    label: 'Certificates',
    value: 'certificates',
    modules: [
      {
        label: 'GSP',
        value: 'certificates-gsp',
      },
      {
        label: 'Processing',
        value: 'certificates-processing',
      },
      {
        label: 'Cash Incentive',
        value: 'certificates-cash-incentive',
      },
      {
        label: 'Duty Exemption',
        value: 'certificates-duty-exemption',
      },
    ],
  },
  {
    label: 'Accounts',
    value: 'accounts',
    modules: [
      {
        label: 'Membership',
        value: 'accounts-membership',
      },
      {
        label: 'Certificate',
        value: 'accounts-certificate',
      },
    ],
  },
  {
    label: 'Membership',
    value: 'membership',
    modules: [
      {
        label: 'Add New Member',
        value: 'add-new-member',
      },
    ],
  },
];

export const moduleContextTree = [
  //certificates section modules
  {
    label: 'GSP',
    value: 'certificates-gsp',
    contexts: [
      {
        label: 'Pre Sold',
        value: 'pre-sold',
      },
      {
        label: 'Status Change',
        value: 'status_change',
      },
      {
        label: 'Certificate Issued',
        value: 'certificate_issued',
      },
      {
        label: 'Delete Certificate',
        value: 'delete-certificate',
      },
      {
        label: 'Update Certificate',
        value: 'update-certificate',
      },
    ],
  },
  {
    label: 'Processing',
    value: 'certificates-processing',
    contexts: [
      {
        label: 'Pre Sold',
        value: 'pre-sold',
      },
      {
        label: 'Status Change',
        value: 'status_change',
      },
      {
        label: 'Certificate Issued',
        value: 'certificate_issued',
      },
      {
        label: 'Delete Certificate',
        value: 'delete-certificate',
      },
      {
        label: 'Update Certificate',
        value: 'update-certificate',
      },
    ],
  },
  {
    label: 'Cash Incentive',
    value: 'certificates-cash-incentive',
    contexts: [
      {
        label: 'Pre Sold',
        value: 'pre-sold',
      },
      {
        label: 'Status Change',
        value: 'status_change',
      },
      {
        label: 'Certificate Issued',
        value: 'certificate_issued',
      },
      {
        label: 'Delete Certificate',
        value: 'delete-certificate',
      },
      {
        label: 'Update Certificate',
        value: 'update-certificate',
      },
    ],
  },
  {
    label: 'Duty Exemption',
    value: 'certificates-duty-exemption',
    contexts: [
      {
        label: 'Pre Sold',
        value: 'pre-sold',
      },
      {
        label: 'Status Change',
        value: 'status_change',
      },
      {
        label: 'Certificate Issued',
        value: 'certificate_issued',
      },
      {
        label: 'Delete Certificate',
        value: 'delete-certificate',
      },
      {
        label: 'Update Certificate',
        value: 'update-certificate',
      },
    ],
  },
  //certificates section module ends
  //accounts section module starts
  {
    label: 'Membership',
    value: 'accounts-membership',
    contexts: [
      {
        label: 'New Membership Request',
        value: 'new-request',
      },
      {
        label: 'Renewal Requests',
        value: 'renewal-request',
      },
      {
        label: 'Transactions',
        value: 'transactions',
      },
      {
        label: 'Delete Membership',
        value: 'delete-member',
      },
    ],
  },
  {
    label: 'Certificate',
    value: 'accounts-certificate',
    contexts: [
      {
        label: 'Blank Certificate (Sold)',
        value: 'blank-certificate-sold',
      },
      {
        label: 'Production (Sold)',
        value: 'production-certificate-sold',
      },
      {
        label: 'Processing (Sold)',
        value: 'processing-certificate-sold',
      },
      {
        label: 'Duty Exemption (Sold)',
        value: 'duty-exemption-certificate-sold',
      },
    ],
  },
  //accounts section moudule ends
  //membership section module
  {
    label: 'Add New Member',
    value: 'add-new-member',
    contexts: [
      {
        label: 'Add New Member',
        value: 'add-new-member',
      },
    ],
  },
  //membership section module ends
];

export const fixedContext = [
  {
    label: 'Certificate Issued',
    value: 'certificate_issued',
  },
  {
    label: 'Blank Certificate (Sold)',
    value: 'blank-certificate-sold',
  },
  {
    label: 'Production (Sold)',
    value: 'production-certificate-sold',
  },
  {
    label: 'Processing (Sold)',
    value: 'processing-certificate-sold',
  },
  {
    label: 'Duty Exemption (Sold)',
    value: 'duty-exemption-certificate-sold',
  },
  {
    label: 'Delete Certificate',
    value: 'delete-certificate',
  },
  {
    label: 'Update Certificate',
    value: 'update-certificate',
  },
  {
    label: 'Pre Sold',
    value: 'pre-sold',
  },
  {
    label: 'Status Change',
    value: 'status_change',
  },
  {
    label: 'New Membership Request',
    value: 'new-request',
  },
  {
    label: 'Renewal Requests',
    value: 'renewal-request',
  },
  {
    label: 'Transactions',
    value: 'transactions',
  },
  {
    label: 'Add New Member',
    value: 'add-new-member',
  },
  {
    label: 'Delete Membership',
    value: 'delete-member',
  },
];
