export const PRE_LOG_IN = 'PRE_LOG_IN';
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const SET_STATS = 'SET_STATS';

//membership
export const FETCH_MEMBERSHIP_REQUESTS = 'FETCH_MEMBERSHIP_REQUESTS';
export const FETCH_MEMBERSHIP_REQUEST = 'FETCH_MEMBERSHIP_REQUEST';
export const FETCH_INFO_UPDATE_REQUESTS = 'FETCH_INFO_UPDATE_REQUESTS';
export const FETCH_MEMBERS = 'FETCH_MEMBERS';
export const REVOKE_MEMBERS = 'REVOKE_MEMBERS';

//certificates
export const FETCH_GSP_APPLICATIONS = 'FETCH_GSP_APPLICATIONS';
export const FETCH_PROCESSING_APPLICATIONS = 'FETCH_PROCESSING_APPLICATIONS';
export const FETCH_CASH_APPLICATIONS = 'FETCH_CASH_APPLICATIONS';
export const FETCH_DUTY_APPLICATIONS = 'FETCH_DUTY_APPLICATIONS';
export const FETCH_NEW_MARKET_EXPANSION_APPLICATIONS = 'FETCH_NEW_MARKET_EXPANSION_APPLICATIONS';
export const FETCH_APPLICATION_INFO = 'FETCH_APPLICATION_INFO';
export const FETCH_REJECTED_CERTIFICATES = 'FETCH_REJECTED_CERTIFICATES';

//accounts
export const FETCH_MEMBERSHIP_REQUESTS_ACC = 'FETCH_MEMBERSHIP_REQUESTS_ACC';
export const FETCH_MEMBERSHIP_REQUEST_ACC = 'FETCH_MEMBERSHIP_REQUEST_ACC';
export const FETCH_TRANSACTION_HISTORY = 'FETCH_TRANSACTION_HISTORY';
export const FETCH_RENEWAL_REQUESTS_ACC = 'FETCH_RENEWAL_REQUESTS_ACC';
export const FETCH_RENEWAL_REQUEST_ACC = 'FETCH_RENEWAL_REQUEST_ACC';
export const FETCH_PROD_CERT_PURCHASES = 'FETCH_PROD_CERT_PURCHASES';
export const FETCH_PROC_CERT_PURCHASES = 'FETCH_PROC_CERT_PURCHASES';
export const FETCH_DUTY_CERT_PURCHASES = 'FETCH_DUTY_CERT_PURCHASES';
export const FETCH_DUTY_CERT_PURCHASE_REQUESTS = 'FETCH_DUTY_CERT_PURCHASE_REQUESTS';
export const FETCH_CERT_PURCHASE_INVOICE = 'FETCH_CERT_PURCHASE_INVOICE';
export const FETCH_NEW_MARKET_EXPANSION_PURCHASE_REQUESTS =
  'FETCH_NEW_MARKET_EXPANSION_PURCHASE_REQUESTS';

//certificates-generation
export const FETCH_GSP_APPLICATIONS_FOR_GEN = 'FETCH_GSP_APPLICATIONS_FOR_GEN';
export const FETCH_PROCESSING_APPLICATIONS_FOR_GEN = 'FETCH_PROCESSING_APPLICATIONS_FOR_GEN';
export const FETCH_CASH_APPLICATIONS_FOR_GEN = 'FETCH_CASH_APPLICATIONS_FOR_GEN';
export const FETCH_DUTY_APPLICATIONS_FOR_GEN = 'FETCH_DUTY_APPLICATIONS_FOR_GEN';

//labour-database
export const FETCH_LABOUR_DATA = 'FETCH_LABOUR_DATA';
export const FETCH_CURRENT_LABOUR = 'FETCH_CURRENT_LABOUR';

//admin-users
export const FETCH_ADMIN_USERS = 'FETCH_ADMIN_USERS';
export const FETCH_EXTERNAL_AGENTS = 'FETCH_EXTERNAL_AGENTS';
export const FETCH_VERIFICATION_REQUESTS = 'FETCH_VERIFICATION_REQUESTS';

//mill-users
export const FETCH_MILL_USERS = 'FETCH_MILL_USERS';
export const FETCH_MILL_REPRESENTATIVE = 'FETCH_MILL_REPRESENTATIVE';

export const NOTIFY = 'NOTIFY';

//action-logs
export const FETCH_ACTION_LOGS = 'FETCH_ACTION_LOGS';
export const FETCH_ACTION_LOGS_DETAILS = 'FETCH_ACTION_LOGS_DETAILS';
