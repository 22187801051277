import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import Loader from '../../snippets/Loader';
import { useHistory } from 'react-router-dom';
import LabourPatchForm from './LabourPatchForm';
import { getLabourDetails } from '../../../api/labour';
import { FETCH_CURRENT_LABOUR } from '../../../store/actions/actionTypes';

const LabourDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [defaultFields, setDefaultFields] = useState();

  useEffect(() => {
    dispatch({ type: FETCH_CURRENT_LABOUR, payload: null });
    let urlParts = history.location.pathname.split('/');
    let lastPart = urlParts[urlParts.length - 1];
    let labourId = parseInt(lastPart.slice(0, lastPart?.length - 7));
    (async () => {
      const res = await getLabourDetails(labourId);
      if (res.data?.status === 200) {
        // eslint-disable-next-line no-unused-vars
        const { id, millId, millUid, status, employeeImage, ...rest } = res.data.labourData;
        setDefaultFields(rest);
        setData({ id, millId, millUid, employeeImage });
      }
    })();
  }, []);
  return (
    <Box p={4}>
      {defaultFields ? <LabourPatchForm defaultFields={defaultFields} data={data} /> : <Loader />}
    </Box>
  );
};

export default LabourDetails;
