import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/membershipFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'countryOfOriginOtherFuel',
    type: 'text',
    label: 'Country of Origin',
  },
  {
    colSpan: 1,
    name: 'capacityOtherFuel',
    type: 'text',
    label: 'Capacity (KW/Hour)',
  },
];

export const otherFuelFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label).nullable(),
  [fields[1].name]: yup.string().label(fields[1].label).nullable(),
};

const OtherFuel = (props) => {
  return <FormSection sectionName="Other Fuel" sectionColumn={2} formFields={fields} {...props} />;
};

export default OtherFuel;
