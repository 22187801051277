import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const PrintPreview = React.forwardRef(({ data }, ref) => (
  <Box w="100%" h="100%" m={0} p={0} ref={ref} position="relative">
    <Text position="absolute" top="410px" left="310px" fontSize="md" fontWeight="bold">
      {data?.millName}
    </Text>
    <Text
      position="absolute"
      top="448px"
      left="250px"
      fontSize="md"
      pr="65px"
      fontWeight="bold"
      lineHeight={1.2}>
      ## {data?.millAddress}
    </Text>
    <Text position="absolute" top="490px" left="125px" fontSize="md" pr="65px" fontWeight="bold">
      Factory: {data?.factoryAddress}
    </Text>
    <Text position="absolute" top="565px" left="170px" fontSize="sm" fontWeight="bold">
      {data?.masterLc}
    </Text>
    <Text position="absolute" top="565px" left="575px" fontSize="sm" fontWeight="bold">
      {data?.dateOfLc}
    </Text>
    <Text position="absolute" top="600px" left="170px" fontSize="sm" fontWeight="bold">
      {data?.invoice}
    </Text>
    <Text position="absolute" top="600px" left="575px" fontSize="sm" fontWeight="bold">
      {data?.invoiceDate}
    </Text>
    <Text position="absolute" top="640px" left="305px" fontSize="sm" fontWeight="bold">
      {data?.blNo}
    </Text>
    <Text position="absolute" top="675px" left="150px" fontSize="sm" fontWeight="bold">
      {data?.blDate}
    </Text>
  </Box>
));

PrintPreview.displayName = 'PrintPreview';

export default PrintPreview;
