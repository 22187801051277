import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'noOfAutoconers',
    type: 'text',
    label: 'No. of Autoconers',
  },
  {
    colSpan: 1,
    name: 'model',
    type: 'text',
    label: 'Model',
  },
  {
    colSpan: 1,
    name: 'aCountryOrManufacturer',
    type: 'text',
    label: 'Country/ Manufacturer',
  },
  {
    colSpan: 1,
    name: 'aYearOfManufacture',
    type: 'text',
    label: 'Year of Manufacture',
  },
];

export const AutoconeFieldsValidation = {
  [fields[0].name]: yup.string(),
  [fields[1].name]: yup.string(),
  [fields[2].name]: yup.string(),
  [fields[3].name]: yup.string(),
};

const AutoconeSection = (props) => {
  return <FormSection sectionName="Autocone" sectionColumn={4} formFields={fields} {...props} />;
};

export default AutoconeSection;
