import React from 'react';
import * as commaNumber from 'comma-number';
import { convertNumberToWords } from '../../../util/formatUtils';
import { Box, HStack, Table, Thead, Tbody, Th, Tr, Td, Text } from '@chakra-ui/react';

const CostBreakDown = ({ data, billFor, transaction }) => {
  const getTotalPayable = () => {
    if (transaction) {
      let total = 0;
      total += parseInt(data?.billInfo?.['JAN-JUN']) || 0;
      total += parseInt(data?.billInfo?.['JUL-DEC']) || 0;
      total += parseInt(data?.billInfo?.['JAN-DEC']) || 0;
      total += parseInt(data?.billInfo?.arrear) || 0;
      total += parseInt(data?.billInfo?.other) || 0;
      return commaNumber(total);
    } else {
      if (data?.membershipType === 'GENERAL') {
        if (new Date().getMonth() > 5) {
          if (data?.extendFor === 18) {
            const total =
              (parseInt(data?.costs?.total) || 0) / 2 +
              (parseInt(data?.costs?.total) || 0) +
              (parseInt(data?.billInfo?.arrear) || 0) +
              (parseInt(data?.billInfo?.other) || 0);
            return commaNumber(total);
          } else {
            const total =
              (parseInt(data?.costs?.total) || 0) / 2 +
              (parseInt(data?.billInfo?.arrear) || 0) +
              (parseInt(data?.billInfo?.other) || 0);
            return commaNumber(total);
          }
        } else {
          const total =
            (parseInt(data?.costs?.total) || 0) +
            (parseInt(data?.billInfo?.arrear) || 0) +
            (parseInt(data?.billInfo?.other) || 0);
          return commaNumber(total);
        }
      } else {
        const total =
          (parseInt(data?.costs?.total) || 0) +
          (parseInt(data?.billInfo?.arrear) || 0) +
          (parseInt(data?.billInfo?.other) || 0);
        return commaNumber(total);
      }
    }
  };

  return (
    <Box bg="white" p={4}>
      {billFor ? (
        <Text fontSize="2xl" fontWeight="bold" mb={2} width="100%" textAlign="center">
          {billFor} Bill
        </Text>
      ) : (
        <Text fontSize="20px" fontWeight="bold" mb={2}>
          Subscription Cost-Breakdown
        </Text>
      )}

      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Particulars</Th>
            <Th textAlign="right">Quantity</Th>
            <Th textAlign="right">Cost(৳)</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              <Text fontSize="18px" fontWeight="bold" mb={1}>
                Yarn Manufacturer:
              </Text>
              <Text>a) Spindles</Text>
              <Text>b) Rotors</Text>
              {data?.costs?.costYarnProd > 0 ? <Text>c) Production Capacity</Text> : null}
            </Td>
            <Td textAlign="right">
              <Text h="18px" fontWeight="bold" mb={1}></Text>
              <Text>{commaNumber(data?.summary?.yarnInfo?.spindlesInsCap) || 0}</Text>
              <Text>{commaNumber(data?.summary?.yarnInfo?.rotorsInsCap) || 0}</Text>
              {data?.costs?.costYarnProd > 0 ? (
                <Text>{commaNumber(data?.summary?.yarnInfo?.yarnProdCap) || 0}</Text>
              ) : null}
            </Td>
            <Td textAlign="right">
              <Text h="18px" fontWeight="bold" mb={1}></Text>
              <Text>{commaNumber(data?.costs?.costSpindle)}</Text>
              <Text>{commaNumber(data?.costs?.costRotor)}</Text>
              {data?.costs?.costYarnProd > 0 ? (
                <Text>{commaNumber(data?.costs?.costYarnProd)}</Text>
              ) : null}
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="18px" fontWeight="bold" mb={1}>
                Fabric Manufacturer:
              </Text>
              <Text>a) Shuttleless Looms</Text>
              <Text>b) Shuttle Looms</Text>
              <Text>c) Knit Fabrics Capacity</Text>
            </Td>
            <Td textAlign="right">
              <Text h="18px" fontWeight="bold" mb={1}></Text>
              <Text>{commaNumber(data?.summary?.fabricInfo?.shuttleLessInsCap) || 0}</Text>
              <Text>{commaNumber(data?.summary?.fabricInfo?.shuttleInsCap) || 0}</Text>
              <Text>{commaNumber(data?.summary?.fabricInfo?.fabricKnitProdCap) || 0}</Text>
            </Td>
            <Td textAlign="right">
              <Text h="18px" fontWeight="bold" mb={1}></Text>
              <Text>{commaNumber(data?.costs?.costShuttleLess)}</Text>
              <Text>{commaNumber(data?.costs?.costShuttle)}</Text>
              <Text>{commaNumber(data?.costs?.costKnit)}</Text>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="18px" fontWeight="bold" mb={1}>
                Textile Product Processor:
              </Text>
              <Text>a) Woven Fabric Capacity</Text>
              <Text>b) Knit Fabric Capacity</Text>
              <Text>c) Yarn Capacity</Text>
            </Td>
            <Td textAlign="right">
              <Text h="18px" fontWeight="bold" mb={1}></Text>
              <Text>{commaNumber(data?.summary?.textileInfo?.texFabricWovenProcCap) || 0}</Text>
              <Text>{commaNumber(data?.summary?.textileInfo?.texFabricKnitProcCap) || 0}</Text>
              <Text>{commaNumber(data?.summary?.textileInfo?.texYarnProcCap) || 0}</Text>
            </Td>
            <Td textAlign="right">
              <Text h="18px" fontWeight="bold" mb={1}></Text>
              <Text>{commaNumber(data?.costs?.costWoven)}</Text>
              <Text>{commaNumber(data?.costs?.costTKnit)}</Text>
              <Text>{commaNumber(data?.costs?.costYarn)}</Text>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="18px" fontWeight="bold">
                Total Cost:
              </Text>
            </Td>
            <Td></Td>
            <Td textAlign="right">
              <Text fontSize="18px" fontWeight="bold">
                Tk. {commaNumber(data?.costs?.total || 0)}
              </Text>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="17px" fontWeight="bold" mb={1}>
                Segments (
                {transaction
                  ? parseInt(data?.expiryYear) > parseInt(new Date().getFullYear())
                    ? data?.expiryYear
                    : new Date().getFullYear()
                  : data?.membershipType === 'GENERAL' &&
                    new Date().getMonth() > 5 &&
                    data?.extendFor === 18
                  ? new Date().getFullYear() + 1
                  : new Date().getFullYear()}
                ):
              </Text>
              <Text pl={4}>January - June :</Text>
              <Text pl={4}>July - December :</Text>
              <Text pl={4}>January - December :</Text>
            </Td>
            <Td>
              <Text fontSize="17px" fontWeight="bold"></Text>
              <Text></Text>
              <Text></Text>
              <Text></Text>
            </Td>
            {transaction ? (
              <Td>
                <Text fontSize="17px" fontWeight="bold" mb={1}></Text>
                <Text textAlign="right">{data?.billInfo?.['JAN-JUN'] || 0}</Text>
                <Text textAlign="right">{data?.billInfo?.['JUL-DEC'] || 0}</Text>
                <Text textAlign="right">{data?.billInfo?.['JAN-DEC'] || 0}</Text>
              </Td>
            ) : (
              <Td>
                <Text fontSize="17px" fontWeight="bold" mb={1}></Text>
                <Text textAlign="right">
                  {data?.membershipType === 'GENERAL'
                    ? new Date().getMonth() > 5
                      ? data?.extendFor === 18
                        ? commaNumber((data?.costs?.total || 0) / 2)
                        : 0
                      : commaNumber((data?.costs?.total || 0) / 2)
                    : 0}
                </Text>
                <Text textAlign="right">
                  {data?.membershipType === 'GENERAL'
                    ? commaNumber((data?.costs?.total || 0) / 2)
                    : 0}
                </Text>
                <Text textAlign="right">
                  {data?.membershipType === 'GENERAL' ? 0 : commaNumber(data?.costs?.total || 0)}
                </Text>
              </Td>
            )}
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="17px" fontWeight="bold">
                Arrears upto (
                {transaction
                  ? parseInt(data?.expiryYear) > parseInt(new Date().getFullYear())
                    ? parseInt(data?.expiryYear) - 1
                    : new Date().getFullYear() - 1
                  : data?.membershipType === 'GENERAL' &&
                    new Date().getMonth() > 5 &&
                    data?.extendFor === 18
                  ? new Date().getFullYear()
                  : new Date().getFullYear() - 1}
                ) :
              </Text>
            </Td>
            <Td></Td>
            <Td textAlign="right">
              <Text>
                {data?.membershipType === 'GENERAL' &&
                new Date().getMonth() > 5 &&
                data?.extendFor === 18
                  ? commaNumber((data?.costs?.total || 0) / 2)
                  : commaNumber(data?.billInfo?.arrear || 0)}
              </Text>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="18px" fontWeight="bold">
                Total Payable:
              </Text>
            </Td>
            <Td></Td>
            <Td textAlign="right">
              <Text fontSize="18px" fontWeight="bold">
                Tk. {getTotalPayable()}
              </Text>
            </Td>
          </Tr>
        </Tbody>
      </Table>
      {billFor ? (
        <HStack w="100%" borderTop="1px solid black" borderStyle="dotted" mt={1}>
          <Text fontSize="sm" fontWeight="bold">
            In words:{' '}
          </Text>
          <Text fontSize="sm">{convertNumberToWords(getTotalPayable())} Taka Only</Text>
        </HStack>
      ) : null}
    </Box>
  );
};

export default CostBreakDown;
