import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';

import GeneralInfo from '../FormSteps/GeneralInfo/GeneralInfo';
import ProductInfo from '../FormSteps/ProductInfo/ProductInfo';
import OrganizInfo from '../FormSteps/OrganizationalInfo/OrganizInfo';
import PowerManagement from '../FormSteps/PowerManagement/PowerManagement';

import { getFormData } from '../../../../util/localStorageUtils';

const StepsSection = ({ backToTable }) => {
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });

  const isDataStored = () => {
    let formDataA = getFormData('addMemberStepA');
    let formDataB = getFormData('addMemberStepB');
    let formDataC = getFormData('addMemberStepC');
    let formDataD = getFormData('addMemberStepD');
    if (formDataA || formDataB || formDataC || formDataD) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'unset';
    window.scrollTo(0, 0);
  }, [activeStep]);

  const stepList = ['General Info', 'Production Info', 'Organizational Info', 'Power Management'];

  const contentList = [
    <GeneralInfo
      key={0}
      stepList={stepList}
      nextStep={nextStep}
      currStep={activeStep}
      backToTable={backToTable}
      isDataStored={isDataStored}
    />,
    <ProductInfo
      key={1}
      stepList={stepList}
      nextStep={nextStep}
      prevStep={prevStep}
      currStep={activeStep}
      backToTable={backToTable}
      isDataStored={isDataStored}
    />,
    <OrganizInfo
      key={2}
      stepList={stepList}
      nextStep={nextStep}
      prevStep={prevStep}
      currStep={activeStep}
      backToTable={backToTable}
      isDataStored={isDataStored}
    />,
    <PowerManagement
      key={3}
      stepList={stepList}
      prevStep={prevStep}
      currStep={activeStep}
      backToTable={backToTable}
      isDataStored={isDataStored}
    />,
  ];

  const steps = [
    { label: stepList[0] },
    { label: stepList[1] },
    { label: stepList[2] },
    { label: stepList[3] },
  ];

  const renderContent = () => {
    switch (activeStep) {
      case 0:
        return contentList[0];
      case 1:
        return contentList[1];
      case 2:
        return contentList[2];
      case 3:
        return contentList[3];
      default:
        return contentList[0];
    }
  };

  return (
    <Box>
      <Box
        p={4}
        zIndex={4}
        bg="white"
        right="0px"
        id="navWrapper"
        position="sticky"
        top={`${document?.getElementById('headerWrapper')?.offsetHeight}px`}>
        <Steps colorScheme="primary" activeStep={activeStep} size="sm">
          {steps.map(({ label }) => (
            <Step label={label} key={label} />
          ))}
        </Steps>
      </Box>
      {renderContent()}
    </Box>
  );
};

export default StepsSection;
