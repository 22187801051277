import React from 'react';
import { Stack, Grid, GridItem, Text, Stat, StatLabel, StatNumber, HStack } from '@chakra-ui/react';

const InfoTiles = ({
  highlight,
  sectionName,
  sectionColumn = 4,
  infoFields,
  highlightAmountBorder,
}) => {
  return (
    <Stack spacing={2} p={4} backgroundColor="white" borderRadius="6px">
      <Text fontSize="20px" fontWeight="bold" mb={2}>
        {sectionName}
      </Text>
      <Grid templateColumns={`repeat(${sectionColumn}, 1fr)`} gap={4}>
        {infoFields?.map((field, index) =>
          highlight ? (
            <GridItem key={index} colSpan={field.colSpan || 1} overflowX="hidden">
              <Stat
                py={4}
                px={2}
                border="1px"
                borderColor="primary.300"
                borderRadius="md"
                bg="secondary">
                <HStack w="100%" justifyContent="space-between">
                  <StatLabel fontWeight="bold" fontSize="sm" color="textSecondary">
                    {field.header}
                  </StatLabel>

                  <StatNumber fontSize="xl">{field.value || 'N/A'}</StatNumber>
                </HStack>
              </Stat>
            </GridItem>
          ) : highlightAmountBorder ? (
            <GridItem
              p="3"
              w="100%"
              key={index}
              bg="secondary"
              border="1px"
              borderColor="primary.500"
              borderStyle="dashed"
              borderRadius="md"
              overflowX="hidden"
              colSpan={field.colSpan || 1}>
              <Text fontSize="small" fontWeight={600} color="textSecondary">
                {field.header}
              </Text>
              <Text fontSize="md" fontWeight={600}>
                {field.value ? `${field.value}` : 'N/A'}
              </Text>
            </GridItem>
          ) : (
            <GridItem
              p="3"
              w="100%"
              key={index}
              bg="secondary"
              borderRadius="sm"
              overflowX="hidden"
              colSpan={field.colSpan || 1}>
              <Text fontSize="small" color="textSecondary">
                {field.header}
              </Text>
              <Text fontSize="md">{field.value || 'N/A'}</Text>
            </GridItem>
          ),
        )}
      </Grid>
    </Stack>
  );
};

export default InfoTiles;
