import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';

import LabourTable from './LabourTable';
import InfoTiles from '../common/InfoTiles';
import { getLabourStats } from '../../api/labour';

const LabourDatabase = () => {
  const [stats, setStats] = useState();

  useEffect(() => {
    document.title = 'BTMA - Labour Database';
  }, []);

  useEffect(() => {
    (async () => {
      const res = await getLabourStats();
      if (res.data?.status === 200) {
        const { maleLabour, femaleLabour, otherLabour, totalLabour } = res.data.stats;
        setStats([
          { header: 'Total Labours', value: totalLabour },
          { header: 'Male', value: maleLabour },
          { header: 'Female', value: femaleLabour },
          { header: 'Other', value: otherLabour },
        ]);
      }
    })();
  }, []);

  return (
    <Box p={4}>
      <Box mb={4}>
        <InfoTiles highlight sectionName="Basic Statistics" sectionColumn={4} infoFields={stats} />
      </Box>
      <LabourTable />
    </Box>
  );
};

export default LabourDatabase;
