import React from 'react';
import { Stack, Table, Thead, Tbody, Th, Tr, TableCaption, Td, Text, Icon } from '@chakra-ui/react';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { renderDate } from '../../util/formatUtils';

const MinimalTable = ({ columns, data, caption, scrollable, tableHeight }) => {
  const renderCellData = (rowItem, rowIndex, columnItem, colIndex) => {
    return columnItem.increments ? (
      <Td key={colIndex} textAlign="center">
        {rowIndex + 1}
      </Td>
    ) : columnItem.isDate ? (
      <Td key={colIndex} textAlign="center">
        {renderDate(rowItem[columnItem.accessor]) || 'N/A'}
      </Td>
    ) : (
      <Td key={colIndex} textAlign="center">
        {rowItem[columnItem.accessor] || 'N/A'}
      </Td>
    );
  };

  return (
    <Stack
      bg="secondary"
      maxH={scrollable ? (tableHeight ? tableHeight : '40vh') : 'unset'}
      overflow="auto">
      <Table size="sm" variant="striped">
        {caption ? (
          <TableCaption placement="Top" bg="white" mt={0} pt={0} px={0} pb={2}>
            <Text fontSize="lg" fontWeight="bold" color="black" textAlign="left">
              {caption}
            </Text>
          </TableCaption>
        ) : null}
        {columns ? (
          <>
            <Thead fontSize="xl" fontWeight="bold" color="black">
              <Tr position="sticky" top="0px" bg="secondary" shadow="sm">
                {columns.map((columnItem, index) => (
                  <Th key={index} textAlign="center" fontWeight="bold" fontSize="sm" color="Black">
                    {columnItem.header}
                  </Th>
                ))}
              </Tr>
            </Thead>
            {data?.length > 0 ? (
              <Tbody bg="secondary">
                {data.map((rowItem, idx) => (
                  <Tr key={idx}>
                    {columns.map((columnItem) => renderCellData(rowItem, idx, columnItem))}
                  </Tr>
                ))}
              </Tbody>
            ) : (
              <Tbody position="relative" height="120px">
                <Tr
                  fontSize="md"
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%,-50%)">
                  <Text color="textSecondary" fontSize="lg">
                    <Icon as={AiOutlineFileSearch} w={6} h={6} /> No entries found!
                  </Text>
                </Tr>
              </Tbody>
            )}
          </>
        ) : null}
      </Table>
    </Stack>
  );
};

export default MinimalTable;
