export const NEW_REQUEST_VERIFICATION_TABS = [
  'General Info',
  'Yarn Info',
  'Fabric Info',
  'Textile Info',
  'Other Info',
  'Cost Summary',
];

export const membershipRequestStatus = {
  PENDING: 'PENDING',
  APPLIED: 'APPLIED',
  INREVIEW: 'IN REVIEW',
  REWORK: 'REWORK',
  REJECTED: 'REJECTED',
  PAYMENTPENDING: 'PAYMENT PENDING',
  APPROVED: 'APPROVED',
  PROVISIONALMEMBER: 'PROVISIONAL MEMBER',
};

export const membershipCategoriesList = [
  {
    name: 'Permanent Membership',
    value: 'FULL_MEMBERSHIP',
  },
  {
    name: 'Provisional Membership',
    value: 'PROVISIONAL_MEMBERSHIP',
  },
];
