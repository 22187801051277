import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, HStack, Box, Text, Grid, GridItem, useDisclosure } from '@chakra-ui/react';

//common components
import RenderModal from '../../RenderModal';
import Loader from '../../../snippets/Loader';
import InfoTiles from '../../../common/InfoTiles';
import FormButton from '../../../common/FormButton';
import DataTable from '../../../common/MinimalTable';
import InfoSection from '../../../common/InfoSection';
import DocumentGallery from '../../../common/membershipFormComponents/GallerySection';
import MembershipFormWrapper from '../../../common/membershipFormComponents/MembershipFormWrapper';

//form sections
import YarnInfoSection, { yarnInfoFieldsValidation } from './YarnInfoSection';
import KRmgInfoSection, { kRmgInfoFieldsValidation } from './KRmgInfoSection';
import WRmgInfoSection, { wRmgInfoFieldsValidation } from './WRmgInfoSection';
import FabricsInfoSection, { fabricsInfoFieldsValidation } from './FabricsInfoSection';
import IssueDateSection, { issueDateValidation } from '../../commonSections/IssueDateSection';
import TowelOthersInfoSection, { towelOthersInfoFieldsValidation } from './TowelOthersInfoSection';

//actions & api
import {
  getAllApplications,
  getApplicationInfo,
  patchApplicationInfo,
  updateApplicationStatus,
} from '../../../../api/certificates';
import {
  FETCH_CASH_APPLICATIONS,
  FETCH_APPLICATION_INFO,
} from '../../../../store/actions/actionTypes';
import { notify } from '../../../../store/actions/globalActions';
import { getConversionRate, getConversionRates } from '../../../../api/currency';

//utils & constants
import _ from 'lodash';
import { CASH_CERT_URL } from '../../../../constants/routerUrl';
import { useYupValidationResolver } from '../../../../util/yupUtils';
import { formatDate, renderDate } from '../../../../util/formatUtils';
import { BADGE_STATE_COLORS } from '../../../../constants/dataTableConstants';

const CashIncentiveVer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [modal, setModal] = useState();
  const [formData, setFormData] = useState();
  const [loader, setLoader] = useState(false);
  const [conversions, setConversions] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const data = useSelector((state) => state.certificate.currentData);
  const cash = data?.cashAssitanceYarnExport || data?.cashAssitanceFabricExport;
  const { applicationId } = history.location.state;

  useEffect(() => {
    (async () => {
      const res = await getApplicationInfo({ type: 'production', id: applicationId });
      if (res.data?.status === 200) {
        dispatch({ type: FETCH_APPLICATION_INFO, payload: res.data.certificate });
      }
    })();

    (async () => {
      const res = await getConversionRates();
      setConversions(res?.data);
    })();
  }, []);

  const millInfo = [
    { header: 'Mill Name', value: data?.millInfo?.millName },
    { header: 'Membership No', value: data?.millInfo?.membershipNo },
    { header: 'Membership Type', value: data?.millInfo?.membershipType },
    { header: 'Contact', value: data?.millInfo?.phoneNo },
  ];

  const cashDocumentsInfo = [
    {
      header: 'Declaration -1',
      link: cash?.declarationOne,
    },
    {
      header: 'Declaration -3',
      link: cash?.declarationThree,
    },
    {
      header: 'Proforma Invoice',
      link: cash?.proformaInvoice,
    },
    {
      header: 'LC Documents from Bank',
      link: cash?.lcBankDocuments,
    },
  ];

  const generalInfoFields = [
    { header: 'Export L/C No', value: cash?.masterLc },
    { header: 'Date of L/C', value: renderDate(cash?.dateOfLc) },
    { header: 'Total Quantity & Items', value: cash?.totalQuantity },
    { header: 'Mushak No', value: cash?.mushakNum },
    { header: 'Mushak Date', value: renderDate(cash?.mushakDate) },
    { header: 'Total Value (In US$)', value: `$${cash?.totalValueInUsd}` },
  ];

  const docColInfoFields = [
    { header: 'Back to Back L/C No', value: cash?.btbLc },
    { header: 'Date of Back to Back L/C', value: renderDate(cash?.btbLcDate) },
    { header: 'Expiry Date of Back to Back L/C', value: renderDate(cash?.expiryDateOfLc) },
    { header: 'Value (In US$)', value: `$${cash?.valueInUsd}` },
    { header: 'L/C Opening Bank', value: `${cash?.bank}` },
  ];

  const otherInfoFields = [
    { header: 'Name of Mill & Address', value: cash?.nameOfMillAndAddress },
    { header: 'Annual Capacity', value: cash?.annualProdCapacity },
    { header: 'Supplied To', value: cash?.suppliedTo },
  ];

  const productTableColumns = [
    {
      header: 'Sl. No',
      increments: true,
    },
    { header: 'Description', accessor: 'product' },
    { header: 'Quantity', accessor: 'productQuantity' },
    { header: 'Com. Invoice No', accessor: 'invoice' },
    { header: 'Com. Invoice Date', accessor: 'invoiceDate', isDate: true },
    { header: 'Delivery Date', accessor: 'deliveryDate', isDate: true },
  ];

  const resolver = useYupValidationResolver(
    yup.object().shape({
      ...issueDateValidation,
      ...yarnInfoFieldsValidation,
      ...wRmgInfoFieldsValidation,
      ...kRmgInfoFieldsValidation,
      ...fabricsInfoFieldsValidation,
      ...towelOthersInfoFieldsValidation,
    }),
  );

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: { issueDate: new Date() },
  });

  /* currency conversion codes */
  const yarnCurrency = useWatch({ control, name: 'yarnCurrency' });
  const fabricCurrency = useWatch({ control, name: 'fabricCurrency' });
  const wrmgCurrency = useWatch({ control, name: 'wrmgCurrency' });
  const krmgCurrency = useWatch({ control, name: 'krmgCurrency' });
  const towelCurrency = useWatch({ control, name: 'towelCurrency' });

  const yarnConversionRate = useWatch({ control, name: 'yarnConversionRate' });
  const fabricConversionRate = useWatch({ control, name: 'fabricConversionRate' });
  const wrmgConversionRate = useWatch({ control, name: 'wrmgConversionRate' });
  const krmgConversionRate = useWatch({ control, name: 'krmgConversionRate' });
  const towelConversionRate = useWatch({ control, name: 'towelConversionRate' });

  const yarnValue = useWatch({ control, name: 'yarnValue' });
  const fabricValue = useWatch({ control, name: 'fabricValue' });
  const wrmgValue = useWatch({ control, name: 'wrmgValue' });
  const krmgValue = useWatch({ control, name: 'krmgValue' });
  const towelValue = useWatch({ control, name: 'towelValue' });

  useEffect(() => {
    setValue('yarnConversionRate', getConversionRate(yarnCurrency, conversions));
  }, [yarnCurrency]);
  useEffect(() => {
    setValue('fabricConversionRate', getConversionRate(fabricCurrency, conversions));
  }, [fabricCurrency]);
  useEffect(() => {
    setValue('wrmgConversionRate', getConversionRate(wrmgCurrency, conversions));
  }, [wrmgCurrency]);
  useEffect(() => {
    setValue('krmgConversionRate', getConversionRate(krmgCurrency, conversions));
  }, [krmgCurrency]);
  useEffect(() => {
    setValue('towelConversionRate', getConversionRate(towelCurrency, conversions));
  }, [towelCurrency]);

  useEffect(() => {
    if (yarnValue && yarnCurrency && yarnConversionRate) {
      setValue(
        'yarnValueInTk',
        Math.ceil(
          parseFloat(parseFloat(yarnValue || 0) * parseFloat(yarnConversionRate)).toFixed(2),
        ),
      );
    } else {
      setValue('yarnValueInTk', parseFloat(yarnValue || 0).toFixed(2));
    }
  }, [yarnConversionRate, yarnValue]);
  useEffect(() => {
    if (fabricValue && fabricCurrency && fabricConversionRate) {
      setValue(
        'fabricValueInTk',
        Math.ceil(
          parseFloat(parseFloat(fabricValue || 0) * parseFloat(fabricConversionRate)).toFixed(2),
        ),
      );
    } else {
      setValue('fabricValueInTk', parseFloat(fabricValue || 0).toFixed(2));
    }
  }, [fabricConversionRate, fabricValue]);
  useEffect(() => {
    if (wrmgValue && wrmgCurrency && wrmgConversionRate) {
      setValue(
        'wrmgValueInTk',
        Math.ceil(
          parseFloat(parseFloat(wrmgValue || 0) * parseFloat(wrmgConversionRate)).toFixed(2),
        ),
      );
    } else {
      setValue('wrmgValueInTk', parseFloat(wrmgValue || 0).toFixed(2));
    }
  }, [wrmgConversionRate, wrmgValue]);
  useEffect(() => {
    if (krmgValue && krmgCurrency && krmgConversionRate) {
      setValue(
        'krmgValueInTk',
        Math.ceil(
          parseFloat(parseFloat(krmgValue || 0) * parseFloat(krmgConversionRate)).toFixed(2),
        ),
      );
    } else {
      setValue('krmgValueInTk', parseFloat(krmgValue || 0).toFixed(2));
    }
  }, [krmgConversionRate, krmgValue]);
  useEffect(() => {
    if (towelValue && towelCurrency && towelConversionRate) {
      setValue(
        'towelValueInTk',
        Math.ceil(
          parseFloat(parseFloat(towelValue || 0) * parseFloat(towelConversionRate)).toFixed(2),
        ),
      );
    } else {
      setValue('towelValueInTk', parseFloat(towelValue || 0).toFixed(2));
    }
  }, [towelConversionRate, towelValue]);
  /* currency conversion codes */

  const onSubmit = (formdata) => {
    let normalizedFormData = _.mapKeys(formdata, (value, key) => {
      if (value) {
        return key;
      }
    });
    setFormData({ ...normalizedFormData, issueDate: formatDate(normalizedFormData?.issueDate) });
    setModal('SUBMIT');
    onOpen();
  };

  const confirmSubmit = async () => {
    const res = await patchApplicationInfo({ type: 'production', id: data?.id, formData });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      const response = await getAllApplications({
        type: 'production',
        params: { page: 1, pageSize: 10, type: 'CASH' },
      });
      if (response.data?.status === 200) {
        dispatch({ type: FETCH_CASH_APPLICATIONS, payload: response.data });
      }
      history.push(CASH_CERT_URL);
    }
  };

  const handleRework = async (reworkData) => {
    setLoader(true);
    const res = await updateApplicationStatus({
      id: data?.id,
      formData: { status: 'REWORK', remarks: reworkData?.reason },
    });
    if (res.data?.status === 200) {
      const res = await getAllApplications({
        type: 'production',
        params: { page: 1, pageSize: 10, type: 'CASH' },
      });
      if (res.data?.status === 200) {
        dispatch({ type: FETCH_CASH_APPLICATIONS, payload: res.data });
      }
      history.push(CASH_CERT_URL);
    }
    setLoader(false);
  };

  const confirmReject = async () => {
    setLoader(true);
    const res = await updateApplicationStatus({
      id: data?.id,
      formData: { status: 'REJECTED', remarks: 'This application has been rejected!' },
    });
    if (res.data?.status === 200) {
      const res = await getAllApplications({
        type: 'production',
        params: { page: 1, pageSize: 10, type: 'CASH' },
      });
      if (res.data?.status === 200) {
        dispatch({ type: FETCH_CASH_APPLICATIONS, payload: res.data });
      }
      history.push(CASH_CERT_URL);
    }
    setLoader(false);
  };

  const getBadgeColorScheme = () => {
    return BADGE_STATE_COLORS.filter((item) => item.group === data?.applicationStatus)?.[0]?.color;
  };

  return data && !loader ? (
    <Box>
      <Box p={4}>
        <InfoTiles sectionName="Mill Information" sectionColumn={4} infoFields={millInfo} />
      </Box>
      <Box bg="white" m={4} p={4} borderRadius="4px" border="1px solid #3e1cfd">
        <HStack>
          <Text fontSize="20px" fontWeight="bold" color="textSecondary">
            Certificate No:
          </Text>
          <Text fontSize="16px">{data?.certificateNum || 'N/A'}</Text>
        </HStack>
        <HStack>
          <Text fontSize="20px" fontWeight="bold" color="textSecondary">
            Application Status:
          </Text>
          <Badge fontSize="16px" colorScheme={getBadgeColorScheme()}>
            {data?.applicationStatus}
          </Badge>
        </HStack>
        <HStack>
          <Text fontSize="20px" fontWeight="bold" color="textSecondary">
            Remarks:
          </Text>
          <Text fontSize="16px">{data?.remarks || 'N/A'}</Text>
        </HStack>
      </Box>
      <MembershipFormWrapper
        formName={
          data?.cashAssitanceYarnExport
            ? 'Yarn Export Management (Cash-Incentive)'
            : 'Fabric Export Management (Cash-Incentive)'
        }>
        <Grid templateColumns="repeat(2, 1fr)" gap={3}>
          <GridItem bg="white" borderRadius="4px">
            <InfoSection sectionName="General Information" infoFields={generalInfoFields} />
          </GridItem>
          <GridItem bg="white" borderRadius="4px">
            <InfoSection
              sectionName="Documentary Collectors Information"
              infoFields={docColInfoFields}
            />
          </GridItem>
          <GridItem bg="white" borderRadius="4px">
            <InfoSection sectionName="Other Information" infoFields={otherInfoFields} />
          </GridItem>
          <GridItem bg="white" borderRadius="4px">
            <DocumentGallery galleryFields={cashDocumentsInfo} sectionName="Additional Documents" />
          </GridItem>
          <GridItem colSpan={2} bg="white" borderRadius="4px">
            <Box bg="white" borderRadius="4px">
              <Text px={4} pt={4} fontSize="20px" fontWeight="bold">
                Product Info
              </Text>
              <Box p={4}>
                <DataTable scrollable columns={productTableColumns} data={cash?.productDetails} />
              </Box>
            </Box>
          </GridItem>
          <GridItem colSpan={2} bg="white" borderRadius="4px">
            <Box p={1}>
              <IssueDateSection control={control} register={register} errors={errors} />
              <YarnInfoSection control={control} register={register} errors={errors} />
              <FabricsInfoSection control={control} register={register} errors={errors} />
              <TowelOthersInfoSection control={control} register={register} errors={errors} />
              <WRmgInfoSection control={control} register={register} errors={errors} />
              <KRmgInfoSection control={control} register={register} errors={errors} />
            </Box>
          </GridItem>
        </Grid>
        <HStack justifyContent="space-between" alignItems="center" mt={4}>
          <FormButton
            mt={0}
            bg="red.500"
            color="white"
            variant="rework"
            isLoading={isSubmitting}
            _hover={{ bg: 'red.400' }}
            isDisabled={
              data?.applicationStatus === 'REWORK' || data?.applicationStatus === 'REJECTED'
            }
            onClick={() => {
              setModal('REJECT');
              onOpen();
            }}>
            REJECT
          </FormButton>
          <HStack>
            <FormButton
              mt={0}
              variant="rework"
              isLoading={isSubmitting}
              isDisabled={data?.applicationStatus === 'REWORK'}
              onClick={() => {
                setModal('REWORK');
                onOpen();
              }}>
              REWORK
            </FormButton>
            <FormButton
              mt={0}
              isLoading={isSubmitting}
              onClick={(event) => {
                event.preventDefault();
                handleSubmit(onSubmit)();
              }}>
              SUBMIT
            </FormButton>
          </HStack>
        </HStack>
      </MembershipFormWrapper>
      <RenderModal
        modal={modal}
        isOpen={isOpen}
        onClose={onClose}
        handleRework={handleRework}
        confirmSubmit={confirmSubmit}
        confirmReject={confirmReject}
      />
    </Box>
  ) : (
    <Loader />
  );
};

export default CashIncentiveVer;
