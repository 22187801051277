import React from 'react';
import { Box, FormControl, FormLabel, HStack, Select, Text } from '@chakra-ui/react';

const SelectReport = ({ label, setValue, choices, placeholder, error, ...props }) => {
  return (
    <Box {...props}>
      <HStack>
        <FormControl isInvalid={error}>
          <FormLabel mb={1.5} fontSize="md" color="textSecondary">
            {label}
          </FormLabel>
          <Select
            colorScheme="primary"
            focusBorderColor="primary.300"
            h="50px"
            w="sm"
            borderRadius="2px"
            bg="formInputBG"
            placeholder={placeholder}
            onChange={(e) => setValue(e.target.value)}>
            {choices?.map((item) => (
              <option key={item?.value} value={item?.value}>
                {item?.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </HStack>
      <Text color="invalid" fontSize="sm" mt="6px">
        {error}
      </Text>
    </Box>
  );
};

export default SelectReport;
