import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'sbTypeAndNoOfMachine',
    type: 'text',
    label: 'Type & No. of Machine',
  },
  {
    colSpan: 1,
    name: 'sbCountryOrManufacturer',
    type: 'text',
    label: 'Country/ Manufacturer',
  },
  {
    colSpan: 1,
    name: 'sbYearOfManufacture',
    type: 'text',
    label: 'Year of Manufacture',
  },
  {
    fieldType: 'radio',
    colSpan: 1,
    name: 'sbOption',
    defaultValue: 'New',
    choices: [
      { name: 'New', value: 'New' },
      { name: 'Re-conditioned', value: 'Re-conditioned' },
    ],
  },
];

export const ScouringAndBleachingFieldsValidation = {
  [fields[0].name]: yup.string(),
  [fields[1].name]: yup.string(),
  [fields[2].name]: yup.string(),
  [fields[3].name]: yup.string(),
};

const MachineriesSection = (props) => {
  return (
    <FormSection
      sectionName="Scouring and Bleaching"
      sectionColumn={4}
      formFields={fields}
      {...props}
    />
  );
};

export default MachineriesSection;
