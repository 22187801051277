//modules
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Grid,
  GridItem,
  Box,
  Text,
  Checkbox,
  Divider,
  Button,
  useDisclosure,
} from '@chakra-ui/react';

//common components
import Rework from '../Modals/Rework';
import RenderModal from '../RenderModal';
import Footer from '../../../common/membershipFormComponents/Footer';
import AltFooter from '../../../common/membershipFormComponents/AltFooter';
import MembershipFormWrapper from '../../../common/membershipFormComponents/MembershipFormWrapper';

//form sections
import NoteSection, { noteFieldValidation } from '../NoteSection';
import InOperation, { inOprFieldsValidation } from './sections/InOperation';
import InsCapSection, { insCapFieldsValidation } from './sections/InsCapSection';
import YarnRawMatReq, { yarnRawMatReqFieldsValidation } from './sections/YarnRawMatReq';
import YarnRawMatCons, { yarnRawMatConsFieldsValidation } from './sections/YarnRawMatCons';
import YarnProdExpoCapacity, { capacityFieldsValidation } from './sections/YarnProdExpoCapacity';

//api and actions
import { patchMembershipData } from '../../../../api/membership';
import { notify } from '../../../../store/actions/globalActions';

//utils
import _ from 'lodash';
import { useYupValidationResolver } from '../../../../util/yupUtils';

//client form sections
import {
  InstalledCapacitySection,
  InstalledCapacityFieldsValidation,
  OtherInstalledCapacitySection,
  defaultOtherInstalledCapacityFieldValues,
  OtherInstalledCapacityFieldsValidation,
  BlowRoomSection,
  BlowRoomFieldsValidation,
  CardingSection,
  CardingFieldsValidation,
  DrawFrameSection,
  DrawFrameFieldsValidation,
  FlyFrameSection,
  FlyFrameFieldsValidation,
  RingFrameSection,
  RingFrameFieldsValidation,
  ConeWindingSection,
  ConeWindingFieldsValidation,
  ComberSection,
  ComberFieldsValidation,
  LapFormerSection,
  LapFormerFieldsValidation,
  AutoconeSection,
  AutoconeFieldsValidation,
  AnnualCapacitySection,
  AnnualCapacityFieldsValidation,
  RawMaterialRequirementInfoSection,
  RawMaterialRequirementInfoFieldsValidation,
  YarnTypeAndCountSection,
  YarnTypeAndCountFieldsValidation,
  defaultYarnTypeAndCountFieldValues,
  OtherRawMaterialInfoSection,
  defaultOtherRawMaterialFieldValues,
  OtherRawMaterialFieldsValidation,
} from './clientSections';

const YarnInfo = ({
  backToTable,
  nextStep,
  prevStep,
  currStep,
  defaultFields,
  infoView,
  refresh,
}) => {
  const { uid } = useParams();
  const dispatch = useDispatch();
  const [modal, setModal] = useState();
  const [updateData, setUpdateData] = useState();
  const [yarnInfo, setYarnInfo] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalComponent, setModalComponent] = useState();
  const [rawMaterialInfo, setRawMaterialInfo] = useState(false);

  const yarnInfoDefaults = [
    'otherInOp',
    'rotorsInOp',
    'otherInsCap',
    'yarnProdCap',
    'yarnExpoCap',
    'spindlesInOp',
    'autoConeInOp',
    'rotorsInsCap',
    'autoConeInsCap',
    'spindlesInsCap',
  ];

  const rawMaterialInfoDefaults = [
    'psfCon',
    'psfReq',
    'chipsReq',
    'chipsCon',
    'viscoseCon',
    'acrylicCon',
    'viscoseReq',
    'acrylicReq',
    'petChipsCon',
    'cotWasteReq',
    'petChipsReq',
    'cotWasteCon',
    'rawCottonReq',
    'rawCottonCon',
  ];

  const clientSectionValidation = {
    ...ComberFieldsValidation,
    ...CardingFieldsValidation,
    ...BlowRoomFieldsValidation,
    ...FlyFrameFieldsValidation,
    ...AutoconeFieldsValidation,
    ...DrawFrameFieldsValidation,
    ...RingFrameFieldsValidation,
    ...LapFormerFieldsValidation,
    ...ConeWindingFieldsValidation,
    ...AnnualCapacityFieldsValidation,
    ...YarnTypeAndCountFieldsValidation,
    ...OtherRawMaterialFieldsValidation,
    ...InstalledCapacityFieldsValidation,
    ...OtherInstalledCapacityFieldsValidation,
    ...RawMaterialRequirementInfoFieldsValidation,
  };

  const getFormValidationSchema = () => {
    if (yarnInfo && rawMaterialInfo) {
      return yup.object().shape({
        ...noteFieldValidation,
        ...inOprFieldsValidation,
        ...insCapFieldsValidation,
        ...clientSectionValidation,
        ...capacityFieldsValidation,
        ...yarnRawMatReqFieldsValidation,
        ...yarnRawMatConsFieldsValidation,
      });
    } else if (yarnInfo) {
      return yup.object().shape({
        ...noteFieldValidation,
        ...inOprFieldsValidation,
        ...insCapFieldsValidation,
        ...clientSectionValidation,
        ...capacityFieldsValidation,
      });
    } else if (rawMaterialInfo) {
      return yup.object().shape({
        ...noteFieldValidation,
        ...clientSectionValidation,
        ...yarnRawMatReqFieldsValidation,
        ...yarnRawMatConsFieldsValidation,
      });
    } else {
      return yup.object().shape({ ...noteFieldValidation, ...clientSectionValidation });
    }
  };

  useEffect(() => {
    for (let key of yarnInfoDefaults) {
      if (defaultFields?.[key]) {
        setYarnInfo(true);
      }
    }

    for (let key of rawMaterialInfoDefaults) {
      if (defaultFields?.[key]) {
        setRawMaterialInfo(true);
      }
    }
  }, []);

  const resolver = useYupValidationResolver(getFormValidationSchema());
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: {
      ...defaultFields,
      yarnTypeAndCount: defaultFields?.yarnTypeAndCount
        ? [...defaultFields?.yarnTypeAndCount]
        : [{ ...defaultYarnTypeAndCountFieldValues }],
      otherRawMaterials: defaultFields?.otherRawMaterials
        ? [...defaultFields?.otherRawMaterials]
        : [{ ...defaultOtherRawMaterialFieldValues }],
      otherInstalledCapacity: defaultFields?.otherInstalledCapacity
        ? [...defaultFields?.otherInstalledCapacity]
        : [{ ...defaultOtherInstalledCapacityFieldValues }],
    },
  });

  useEffect(() => {
    if (!yarnInfo) {
      yarnInfoDefaults.map((item) => setValue(item, defaultFields[item]));
    }
  }, [yarnInfo]);

  useEffect(() => {
    if (!rawMaterialInfo) {
      rawMaterialInfoDefaults.map((item) => setValue(item, defaultFields[item]));
    }
  }, [rawMaterialInfo]);

  const prepareData = (formdata) => {
    const {
      otherInOp,
      rotorsInOp,
      otherInsCap,
      yarnProdCap,
      yarnExpoCap,
      spindlesInOp,
      autoConeInOp,
      rotorsInsCap,
      spindlesInsCap,
      autoConeInsCap,

      psfCon,
      psfReq,
      chipsCon,
      chipsReq,
      viscoseReq,
      viscoseCon,
      acrylicCon,
      acrylicReq,
      cotWasteCon,
      cotWasteReq,
      petChipsCon,
      petChipsReq,
      rawCottonCon,
      rawCottonReq,
      ...rest
    } = formdata;

    const sendData = {
      yarnManufacturer: {
        ...rest,
      },
      yarnManufacturerSummary: {
        psfCon,
        psfReq,
        chipsCon,
        chipsReq,
        otherInOp,
        rotorsInOp,
        viscoseCon,
        acrylicCon,
        viscoseReq,
        acrylicReq,
        cotWasteCon,
        otherInsCap,
        yarnProdCap,
        yarnExpoCap,
        petChipsCon,
        cotWasteReq,
        petChipsReq,
        rawCottonCon,
        rotorsInsCap,
        spindlesInOp,
        autoConeInOp,
        rawCottonReq,
        spindlesInsCap,
        autoConeInsCap,
      },
    };

    return sendData;
  };

  const handleQuery = async (queryData, changeStep, flag) => {
    if (flag) {
      const res = await patchMembershipData({
        formData: prepareData(queryData),
        step: currStep,
        param: uid,
      });
      if (res.data?.status == 200) {
        dispatch(
          notify({
            title: 'Success',
            description: res.data.msg,
            status: 'success',
            duration: 5000,
          }),
        );
        changeStep();
      }
    } else {
      const cleanQueryData = _.pickBy(queryData, _.identity);
      const cleanDefaultFields = _.pickBy(defaultFields, _.identity);
      if (_.isEqual(cleanQueryData, cleanDefaultFields) === false) {
        const res = await patchMembershipData({
          formData: prepareData(queryData),
          step: currStep,
          param: uid,
        });
        if (res.data?.status == 200) {
          dispatch(
            notify({
              title: 'Success',
              description: res.data.msg,
              status: 'success',
              duration: 5000,
            }),
          );
          changeStep();
        }
      } else {
        changeStep();
      }
    }
  };

  const onSubmit = (formdata) => {
    const cleanQueryData = _.pickBy(formdata, _.identity);
    const cleanDefaultFields = _.pickBy(defaultFields, _.identity);
    if (_.isEqual(cleanQueryData, cleanDefaultFields) === false) {
      setUpdateData(formdata);
      setModal('UpdateAlert');
      onOpen();
    } else {
      setModal('NoUpdate');
      onOpen();
    }
  };

  const onForwardSubmit = (formData) => {
    handleQuery(formData, nextStep);
  };

  const onBackwardSubmit = (formData) => {
    handleQuery(formData, prevStep);
  };

  const handleBackToTable = () => {
    setModal('BackToTableAlert');
    onOpen();
  };

  const handleRework = () => {
    setModal('ReworkModal');
    setModalComponent(<Rework close={onClose} backToTable={backToTable} />);
    onOpen();
  };

  const handleReject = () => {
    setModal('RejectModal');
    onOpen();
  };

  const rejectMembershipRequest = async () => {
    const res = await patchMembershipData({ param: uid, reject: true });
    if (res.data?.status == 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      onClose();
      backToTable();
    }
  };

  return (
    <Box>
      <MembershipFormWrapper formName="Yarn Manufacturer Information">
        {/* client data */}
        <Grid templateColumns="repeat(2, 1fr)" bg="white" gap={3} p={3}>
          <GridItem>
            <InstalledCapacitySection register={register} control={control} errors={errors} />
            <OtherInstalledCapacitySection register={register} control={control} errors={errors} />
          </GridItem>
        </Grid>
        <Text mt={4} mb={2} fontSize="xl" fontWeight="bold">
          Machineries
        </Text>
        <Divider mb={2} />
        <Grid templateColumns="repeat(2, 1fr)" bg="white" gap={3} p={3}>
          <GridItem colSpan={2}>
            <BlowRoomSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <CardingSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <DrawFrameSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <FlyFrameSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <RingFrameSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <ConeWindingSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <ComberSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <LapFormerSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <AutoconeSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <AnnualCapacitySection register={register} control={control} errors={errors} />
          </GridItem>
        </Grid>
        <Text mt={4} mb={2} fontSize="xl" fontWeight="bold">
          Main Product
        </Text>
        <Divider mb={2} />
        <Grid templateColumns="repeat(2, 1fr)" bg="white" gap={3} p={3}>
          <GridItem colSpan={2}>
            <YarnTypeAndCountSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
          <GridItem colSpan={2}>
            <RawMaterialRequirementInfoSection
              register={register}
              control={control}
              errors={errors}
            />
            <OtherRawMaterialInfoSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
        </Grid>

        {/* admin inputs */}
        <Text
          mb={2}
          pb={2}
          mt={10}
          fontSize="xl"
          fontWeight="bold"
          textAlign="center"
          color="textSecondary"
          borderStyle="dashed"
          borderBottom="2px solid #6B7982">
          Additional Information
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" bg="white" gap={3} p={3}>
          <GridItem>
            <Checkbox
              px={4}
              pt={4}
              pb={1}
              isChecked={yarnInfo}
              onChange={yarnInfo ? () => setYarnInfo(false) : () => setYarnInfo(true)}>
              <Text fontSize="20px" fontWeight="bold">
                Add Yarn Manufacturer Information
              </Text>
            </Checkbox>
            <Box p={1} pointerEvents={yarnInfo ? 'all' : 'none'} opacity={yarnInfo ? 1 : 0.3}>
              <Grid templateColumns="repeat(2, 1fr)" gap={0}>
                <GridItem>
                  <InsCapSection register={register} control={control} errors={errors} />
                </GridItem>
                <GridItem>
                  <InOperation register={register} control={control} errors={errors} />
                </GridItem>
                <GridItem colSpan={2}>
                  <YarnProdExpoCapacity register={register} control={control} errors={errors} />
                </GridItem>
              </Grid>
            </Box>
          </GridItem>
          <GridItem>
            <Checkbox
              px={4}
              pt={4}
              pb={1}
              isChecked={rawMaterialInfo}
              onChange={
                rawMaterialInfo ? () => setRawMaterialInfo(false) : () => setRawMaterialInfo(true)
              }>
              <Text fontSize="20px" fontWeight="bold">
                Add Raw Material Information
              </Text>
            </Checkbox>
            <Box
              p={1}
              pointerEvents={rawMaterialInfo ? 'all' : 'none'}
              opacity={rawMaterialInfo ? 1 : 0.3}>
              <Grid templateColumns="repeat(2, 1fr)" gap={0}>
                <GridItem>
                  <YarnRawMatReq register={register} control={control} errors={errors} />
                </GridItem>
                <GridItem>
                  <YarnRawMatCons register={register} control={control} errors={errors} />
                </GridItem>
              </Grid>
            </Box>
          </GridItem>
        </Grid>
        <Box mt={3} p={3} bg="white">
          <NoteSection register={register} control={control} errors={errors} />
        </Box>
      </MembershipFormWrapper>
      {infoView ? (
        <AltFooter back={backToTable} buttonLoading={isSubmitting}>
          <Button
            type="submit"
            variant="footerUpdate"
            isLoading={isSubmitting}
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onSubmit)();
            }}>
            Update
          </Button>
        </AltFooter>
      ) : (
        <Footer
          currStep={currStep}
          rework={handleRework}
          reject={handleReject}
          back={handleBackToTable}
          buttonLoading={isSubmitting}
          next={(event) => {
            event.preventDefault();
            handleSubmit(onForwardSubmit)();
          }}
          prev={(event) => {
            event.preventDefault();
            handleSubmit(onBackwardSubmit)();
          }}
        />
      )}
      <RenderModal
        modal={modal}
        step={currStep}
        isOpen={isOpen}
        onClose={onClose}
        backToTable={backToTable}
        modalComponent={modalComponent}
        confirmReject={rejectMembershipRequest}
        confirmSubmit={() => handleQuery(updateData, refresh, true)}
      />
    </Box>
  );
};

export default YarnInfo;
