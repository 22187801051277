import React from 'react';
import * as yup from 'yup';
import FormFullSection from '../../../../common/membershipFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'yarnProdCap',
    type: 'text',
    isRequired: true,
    label: 'Yarn Production Capacity',
  },
  {
    colSpan: 1,
    name: 'yarnExpoCap',
    type: 'text',
    label: 'Yarn Exportable Capacity',
  },
];

export const capacityFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup.string().label(fields[1].label).nullable(),
};

const YarnProdCapacity = (props) => {
  return (
    <FormFullSection sectionName="Capacity" sectionColumn={2} formFields={fields} {...props} />
  );
};

export default YarnProdCapacity;
