import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import PrintPreview from './PrintPreview';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../common/dataTable/DataTable';
import { getAllCertForGen } from '../../../api/certificates';
import { FETCH_PROCESSING_APPLICATIONS_FOR_GEN } from '../../../store/actions/actionTypes';
import { LOGS_TABLE_DISPLAY_SIZE, BADGE_STATE_COLORS } from '../../../constants/dataTableConstants';

const ProcessingGeneration = () => {
  const dispatch = useDispatch();
  const processingCertPrintRef = useRef();
  const [printData, setPrintData] = useState();
  const data = useSelector((state) => state.certificate.processingApplicationsGen);

  useEffect(() => {
    document.title = 'BTMA - Certificate Generation';
  }, []);

  const printProcessingCertificate = useReactToPrint({
    documentTitle: `BTMA-Processing-Certificate-${printData?.millName}-${printData?.certData?.certificateNum}`,
    content: () => processingCertPrintRef.current,
  });

  const columns = [
    { header: 'Certificate No', accessor: 'certificateNum' },
    { header: 'Date', accessor: 'createdAt', isDate: true },
    { header: 'Mill Name', accessor: 'millName' },
    {
      header: 'Status',
      accessor: 'status',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
      hoverInfo: ['remarks'],
    },
    {
      header: 'Action',
      accessor: 'status',
      isButton: true,
      actionScheme: [{ group: 'APPROVED', variant: 'print', action: 'PRINT' }],
    },
  ];

  const handlePagination = async ({ page, pageSize, searchKey, startDate, endDate }) => {
    dispatch({ type: FETCH_PROCESSING_APPLICATIONS_FOR_GEN, payload: null });
    const params = {
      page,
      endDate,
      pageSize,
      searchKey,
      startDate,
    };
    const res = await getAllCertForGen({ type: 'processing', params });
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_PROCESSING_APPLICATIONS_FOR_GEN, payload: res.data });
    } else {
      dispatch({ type: FETCH_PROCESSING_APPLICATIONS_FOR_GEN, payload: { certificates: [] } });
    }
  };

  const handleSearch = async ({ searchKey, startDate, endDate }) => {
    dispatch({ type: FETCH_PROCESSING_APPLICATIONS_FOR_GEN, payload: null });
    const params = {
      endDate,
      searchKey,
      startDate,
    };
    const res = await getAllCertForGen({ type: 'processing', params });
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_PROCESSING_APPLICATIONS_FOR_GEN, payload: res.data });
    } else {
      dispatch({ type: FETCH_PROCESSING_APPLICATIONS_FOR_GEN, payload: { certificates: [] } });
    }
  };

  const handlePrint = ({ rowObject }) => {
    const { hoName, millName, msAddress, processingFabric, processingTextile } = rowObject;
    setPrintData({
      millName,
      millAddress: hoName,
      factoryAddress: msAddress,
      certData: processingFabric || processingTextile,
      type: processingFabric ? 'FABRIC' : processingTextile ? 'TEXTILE' : null,
    });
    setTimeout(() => {
      printProcessingCertificate();
    }, 500);
  };

  return (
    <Box p={4}>
      <DataTable
        actions={{
          PRINT: handlePrint,
        }}
        topPanel
        keySearch
        dateRange
        isPaginated
        columns={columns}
        search={handleSearch}
        currentPage={data?.page}
        data={data?.certificates}
        paginate={handlePagination}
        entries={data?.totalEntries}
        currentPageSize={data?.pageSize}
        caption="Processing Certificates"
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
      />
      <Box display="none">
        <PrintPreview ref={processingCertPrintRef} data={printData} />
      </Box>
    </Box>
  );
};

export default ProcessingGeneration;
