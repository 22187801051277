//modules
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  Grid,
  GridItem,
  Box,
  Text,
  Checkbox,
  Divider,
  Button,
  useDisclosure,
} from '@chakra-ui/react';

//common components
import Rework from '../Modals/Rework';
import RenderModal from '../RenderModal';
import Footer from '../../../common/membershipFormComponents/Footer';
import AltFooter from '../../../common/membershipFormComponents/AltFooter';
import MembershipFormWrapper from '../../../common/membershipFormComponents/MembershipFormWrapper';

//form sections
import NoteSection, { noteFieldValidation } from '../NoteSection';
import FabProdCap, { fabProdCapFieldsValidation } from './sections/FabProdCap';
import FabExpoCap, { fabExpoCapFieldsValidation } from './sections/FabExpoCap';
import FabRawMaterial, { fabRawMatFieldsValidation } from './sections/FabRawMaterial';
import FabInsCapSection, { fabInsCapFieldsValidation } from './sections/FabInsCapSection';

//api and actions
import { patchMembershipData } from '../../../../api/membership';
import { notify } from '../../../../store/actions/globalActions';

//utils
import _ from 'lodash';
import { useYupValidationResolver } from '../../../../util/yupUtils';

//client form sections
import {
  WeavMachineriesSection,
  WeavMachineriesFieldsValidation,
  WeavRawMatSection,
  WeavRawMatFieldsValidation,
  WeavOtherRawMatSection,
  WeavOtherRawMatFieldsValidation,
  defaultWeavOtherRawMatFieldValues,
  WeavAnnualCapSection,
  WeavAnnualCapFieldsValidation,
  TypeAndConstructionOfFabricSection,
  TypeAndConstructionOfFabricFieldsValidation,
  defaultTypeAndConstructionFieldValues,
  KnitMachineriesSection,
  defaultKnitMachineriesFieldValues,
  KnitMachineriesFieldsValidation,
  TypesOfFabricSection,
  TypesOfFabricFieldsValidation,
  defaultTypesOfFabricFieldValues,
  KnitRawMatSection,
  KnitRawMatFieldsValidation,
  KnitOtherRawMatSection,
  KnitOtherRawMatFieldsValidation,
  defaultKnitOtherRawMatFieldValues,
  KnitAnnualCapSection,
  KnitAnnualCapFieldsValidation,
} from './clientSections';

const FabricInfo = ({
  backToTable,
  nextStep,
  prevStep,
  currStep,
  defaultFields,
  infoView,
  refresh,
}) => {
  const { uid } = useParams();
  const dispatch = useDispatch();
  const [modal, setModal] = useState();
  const [updateData, setUpdateData] = useState();
  const [fabricInfo, setFabricInfo] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalComponent, setModalComponent] = useState();
  const [rawMaterialInfo, setRawMaterialInfo] = useState(false);

  const fabricInfoDefaults = [
    'knitExpCap',
    'wovenExpCap',
    'knitProdCap',
    'airjetInsCap',
    'repairInsCap',
    'othersInsCap',
    'wovenProdCap',
    'shuttleInsCap',
    'knittingInsCap',
    'shuttleLessInsCap',
  ];

  const rawMaterialInfoDefaults = ['cotYarn', 'synthYarn', 'otherYarn'];

  const clientSectionValidation = {
    ...WeavRawMatFieldsValidation,
    ...KnitRawMatFieldsValidation,
    ...WeavAnnualCapFieldsValidation,
    ...TypesOfFabricFieldsValidation,
    ...KnitAnnualCapFieldsValidation,
    ...WeavMachineriesFieldsValidation,
    ...WeavOtherRawMatFieldsValidation,
    ...KnitOtherRawMatFieldsValidation,
    ...KnitMachineriesFieldsValidation,
    ...TypeAndConstructionOfFabricFieldsValidation,
  };

  const getFormValidationSchema = () => {
    if (fabricInfo && rawMaterialInfo) {
      return yup.object().shape({
        ...noteFieldValidation,
        ...clientSectionValidation,
        ...fabInsCapFieldsValidation,
        ...fabRawMatFieldsValidation,
        ...fabProdCapFieldsValidation,
        ...fabExpoCapFieldsValidation,
      });
    } else if (fabricInfo) {
      return yup.object().shape({
        ...noteFieldValidation,
        ...clientSectionValidation,
        ...fabInsCapFieldsValidation,
        ...fabProdCapFieldsValidation,
        ...fabExpoCapFieldsValidation,
      });
    } else if (rawMaterialInfo) {
      return yup.object().shape({
        ...noteFieldValidation,
        ...clientSectionValidation,
        ...fabRawMatFieldsValidation,
      });
    } else {
      return yup.object().shape({ ...noteFieldValidation, ...clientSectionValidation });
    }
  };

  useEffect(() => {
    for (let key of fabricInfoDefaults) {
      if (defaultFields?.[key]) {
        setFabricInfo(true);
      }
    }
    for (let key of rawMaterialInfoDefaults) {
      if (defaultFields?.[key]) {
        setRawMaterialInfo(true);
      }
    }
  }, []);

  const resolver = useYupValidationResolver(getFormValidationSchema());

  // eslint-disable-next-line no-unused-vars
  const { otherRawMaterials, ...restDefaultFields } = defaultFields;

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: {
      ...restDefaultFields,
      machineInfo: defaultFields.machineInfo
        ? [...defaultFields.machineInfo]
        : [{ ...defaultKnitMachineriesFieldValues }],
      typesOfFabric: defaultFields.typesOfFabric
        ? [...defaultFields.typesOfFabric]
        : [{ ...defaultTypesOfFabricFieldValues }],
      knitOtherRawMaterials: defaultFields.knitOtherRawMaterials
        ? [...defaultFields.knitOtherRawMaterials]
        : [{ ...defaultKnitOtherRawMatFieldValues }],
      weavOtherRawMaterials: defaultFields?.weavOtherRawMaterials
        ? [...defaultFields.weavOtherRawMaterials]
        : [{ ...defaultWeavOtherRawMatFieldValues }],
      typeAndConstructionOfFabric: defaultFields?.typeAndConstructionOfFabric
        ? [...defaultFields.typeAndConstructionOfFabric]
        : [{ ...defaultTypeAndConstructionFieldValues }],
    },
  });

  const prepareData = (formdata) => {
    const {
      machineInfo,
      flaxYarnKnit,
      typesOfFabric,
      cottonYarnKnit,
      tencelYarnKnit,
      viscousYarnKnit,
      syntheticYarnKnit,
      polyesterYarnKnit,
      knitOtherRawMaterials,
      mainItemsManufactured,
      annualRequirementOfYarn,
      annualProductionCapacity,

      cotYarn,
      synthYarn,
      otherYarn,
      airjetInsCap,
      repairInsCap,
      othersInsCap,
      shuttleInsCap,
      knittingInsCap,
      shuttleLessInsCap,
      fabricKnitProdCap,
      fabricKnitExpoCap,
      fabricWovenProdCap,
      fabricWovenExpoCap,

      weavOtherRawMaterials,
      ...rest
    } = formdata;

    const sendData = {
      fabricsManufactuer: {
        ...rest,
        otherRawMaterials: weavOtherRawMaterials,
      },
      knitFabric: {
        machineInfo,
        typesOfFabric,
        mainItemsManufactured,
        flaxYarn: flaxYarnKnit,
        annualRequirementOfYarn,
        annualProductionCapacity,
        cottonYarn: cottonYarnKnit,
        tencelYarn: tencelYarnKnit,
        viscousYarn: viscousYarnKnit,
        polyesterYarn: polyesterYarnKnit,
        syntheticYarn: syntheticYarnKnit,
        otherRawMaterials: knitOtherRawMaterials,
      },
      fabricsManufacturerSummary: {
        cotYarn,
        synthYarn,
        otherYarn,
        airjetInsCap,
        repairInsCap,
        othersInsCap,
        shuttleInsCap,
        knittingInsCap,
        shuttleLessInsCap,
        fabricKnitProdCap,
        fabricKnitExpoCap,
        fabricWovenProdCap,
        fabricWovenExpoCap,
      },
    };

    return sendData;
  };

  const handleQuery = async (queryData, changeStep, flag) => {
    if (flag) {
      const res = await patchMembershipData({
        formData: prepareData(queryData),
        step: currStep,
        param: uid,
      });
      if (res.data?.status == 200) {
        dispatch(
          notify({
            title: 'Success',
            description: res.data.msg,
            status: 'success',
            duration: 5000,
          }),
        );
        changeStep();
      }
    } else {
      const cleanQueryData = _.pickBy(queryData, _.identity);
      const cleanDefaultFields = _.pickBy(restDefaultFields, _.identity);
      if (_.isEqual(cleanQueryData, cleanDefaultFields) === false) {
        const res = await patchMembershipData({
          formData: prepareData(queryData),
          step: currStep,
          param: uid,
        });
        if (res.data?.status == 200) {
          dispatch(
            notify({
              title: 'Success',
              description: res.data.msg,
              status: 'success',
              duration: 5000,
            }),
          );
          changeStep();
        }
      } else {
        changeStep();
      }
    }
  };

  const onSubmit = (formdata) => {
    const cleanQueryData = _.pickBy(formdata, _.identity);
    const cleanDefaultFields = _.pickBy(restDefaultFields, _.identity);
    if (_.isEqual(cleanQueryData, cleanDefaultFields) === false) {
      setUpdateData(formdata);
      setModal('UpdateAlert');
      onOpen();
    } else {
      setModal('NoUpdate');
      onOpen();
    }
  };

  const onForwardSubmit = (formData) => {
    handleQuery(formData, nextStep);
  };

  const onBackwardSubmit = (formData) => {
    handleQuery(formData, prevStep);
  };

  const handleBackToTable = () => {
    setModal('BackToTableAlert');
    onOpen();
  };

  const handleRework = () => {
    setModal('ReworkModal');
    setModalComponent(<Rework close={onClose} backToTable={backToTable} />);
    onOpen();
  };

  const handleReject = () => {
    setModal('RejectModal');
    onOpen();
  };

  const rejectMembershipRequest = async () => {
    const res = await patchMembershipData({ param: uid, reject: true });
    if (res.data?.status == 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      onClose();
      backToTable();
    }
  };

  return (
    <Box>
      <MembershipFormWrapper formName="Weaving Fabric Manufacturer Information">
        {/* client data */}
        <Grid templateColumns="repeat(2, 1fr)" bg="white" gap={3} p={3}>
          <GridItem colSpan={2}>
            <WeavMachineriesSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <WeavAnnualCapSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
          <GridItem colSpan={2}>
            <TypeAndConstructionOfFabricSection
              register={register}
              control={control}
              errors={errors}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
          <GridItem colSpan={2}>
            <WeavRawMatSection register={register} control={control} errors={errors} />
            <WeavOtherRawMatSection register={register} control={control} errors={errors} />
          </GridItem>
        </Grid>

        <Text mt={4} mb={2} fontSize="xl" fontWeight="bold">
          Knit Fabric Manufacturer Information
        </Text>
        <Divider mb={2} />
        <Grid templateColumns="repeat(2, 1fr)" bg="white" gap={3} p={3}>
          <GridItem colSpan={2}>
            <KnitMachineriesSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <KnitAnnualCapSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
          <GridItem colSpan={2}>
            <TypesOfFabricSection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
          <GridItem colSpan={2}>
            <KnitRawMatSection register={register} control={control} errors={errors} />
            <KnitOtherRawMatSection register={register} control={control} errors={errors} />
          </GridItem>
        </Grid>

        {/* admin inputs */}
        <Text
          mb={2}
          pb={2}
          mt={10}
          fontSize="xl"
          fontWeight="bold"
          textAlign="center"
          color="textSecondary"
          borderStyle="dashed"
          borderBottom="2px solid #6B7982">
          Additional Information
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" bg="white" gap={3} p={3}>
          <GridItem colSpan={2}>
            <Checkbox
              px={4}
              pt={4}
              pb={1}
              isChecked={fabricInfo}
              onChange={fabricInfo ? () => setFabricInfo(false) : () => setFabricInfo(true)}>
              <Text fontSize="20px" fontWeight="bold">
                Add Fabric Manufacturer Information
              </Text>
            </Checkbox>
            <Box p={1} pointerEvents={fabricInfo ? 'all' : 'none'} opacity={fabricInfo ? 1 : 0.3}>
              <Grid templateColumns="repeat(2, 1fr)" gap={0}>
                <GridItem colSpan={2}>
                  <FabInsCapSection register={register} control={control} errors={errors} />
                </GridItem>
                <GridItem>
                  <FabProdCap register={register} control={control} errors={errors} />
                </GridItem>
                <GridItem>
                  <FabExpoCap register={register} control={control} errors={errors} />
                </GridItem>
              </Grid>
            </Box>
          </GridItem>
          <GridItem colSpan={2}>
            <Checkbox
              px={4}
              pt={4}
              pb={1}
              isChecked={rawMaterialInfo}
              onChange={
                rawMaterialInfo ? () => setRawMaterialInfo(false) : () => setRawMaterialInfo(true)
              }>
              <Text fontSize="20px" fontWeight="bold">
                Add Raw Material Information
              </Text>
            </Checkbox>
            <Box
              p={1}
              pointerEvents={rawMaterialInfo ? 'all' : 'none'}
              opacity={rawMaterialInfo ? 1 : 0.3}>
              <Grid templateColumns="repeat(2, 1fr)" gap={0}>
                <GridItem colSpan={2}>
                  <FabRawMaterial register={register} control={control} errors={errors} />
                </GridItem>
              </Grid>
            </Box>
          </GridItem>
        </Grid>
        <Box mt={3} p={3} bg="white">
          <NoteSection register={register} control={control} errors={errors} />
        </Box>
      </MembershipFormWrapper>
      {infoView ? (
        <AltFooter back={backToTable} buttonLoading={isSubmitting}>
          <Button
            type="submit"
            variant="footerUpdate"
            isLoading={isSubmitting}
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onSubmit)();
            }}>
            Update
          </Button>
        </AltFooter>
      ) : (
        <Footer
          currStep={currStep}
          rework={handleRework}
          reject={handleReject}
          back={handleBackToTable}
          buttonLoading={isSubmitting}
          next={(event) => {
            event.preventDefault();
            handleSubmit(onForwardSubmit)();
          }}
          prev={(event) => {
            event.preventDefault();
            handleSubmit(onBackwardSubmit)();
          }}
        />
      )}
      <RenderModal
        modal={modal}
        step={currStep}
        isOpen={isOpen}
        onClose={onClose}
        backToTable={backToTable}
        modalComponent={modalComponent}
        confirmReject={rejectMembershipRequest}
        confirmSubmit={() => handleQuery(updateData, refresh, true)}
      />
    </Box>
  );
};

export default FabricInfo;
