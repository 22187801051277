import React from 'react';
import * as yup from 'yup';
import { dateValidation } from '../../../util/yupUtils';
import FormSection from '../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'btbLc',
    type: 'text',
    label: 'Back to Back L/C No',
    isRequired: true,
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'btbLcDate',
    label: 'Date of Back to Back L/C',
    isRequired: true,
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'expiryDateOfLc',
    label: 'Expiry Date of Back to Back L/C',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'valueInUsd',
    type: 'text',
    label: 'Value (In US$)',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'bank',
    type: 'text',
    label: 'Name of L/C Opening Bank',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'bankBranch',
    type: 'text',
    label: 'Bank Branch',
    isRequired: true,
  },
];

export const documentInfoFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: dateValidation.required().label(fields[1].label),
  [fields[2].name]: dateValidation.required().label(fields[2].label),
  [fields[3].name]: yup
    .number()
    .typeError(`${fields[3].label} must be a number`)
    .required()
    .label(fields[3].label),
  [fields[4].name]: yup.string().required().label(fields[4].label),
  [fields[5].name]: yup.string().required().label(fields[5].label),
};

const processingFields = fields.filter((item) => item.name !== 'expiryDateOfLc');

// eslint-disable-next-line no-unused-vars
const { expiryDateOfLc, ...rest } = documentInfoFieldsValidation;

export const procDocumentInfoFieldsValidation = {
  ...rest,
};

const DocumentSection = (props) => {
  return (
    <FormSection
      sectionName="Documentary Collectors Information"
      sectionColumn={3}
      formFields={
        props.type === 'GSP'
          ? fields
          : props.type === 'CASH'
          ? fields
          : props.type === 'PROCESSING'
          ? processingFields
          : []
      }
      {...props}
    />
  );
};

export default DocumentSection;
