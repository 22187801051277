import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  Text,
  VStack,
  HStack,
  Divider,
  GridItem,
  useDisclosure,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CloseIcon } from '@chakra-ui/icons';
import { useForm, useWatch } from 'react-hook-form';

//common components
import FormButton from '../../../common/FormButton';
import DialogueBox from '../../../common/DialogueBox';
import SearchSection from '../../../common/SearchSection';
import AssignCertificate from '../../commonSections/AssignCertificate';
import MembershipFormWrapper from '../../../common/membershipFormComponents/MembershipFormWrapper';

//form sections
import FabricsInfoSection, {
  fabricsInfoFieldsValidation,
} from '../Verification/FabricsInfoSection';
import DocumentSection, {
  documentInfoFieldsValidation,
} from '../../commonSections/DocumentSection';
import TowelOthersInfoSection, {
  towelOthersInfoFieldsValidation,
} from '../Verification/TowelOthersInfoSection';
import ProductTypeSection, {
  productionProductTypeFieldsValidation,
} from '../../commonSections/ProductTypeSection';
import GeneralInfoSection, {
  cashGenInfoFieldsValidation,
} from '../../commonSections/GeneralInfoSection';
import ProductInformation, {
  productInfoArrayFieldsValidation,
} from '../../commonSections/ProductInformation';
import MillInformationSection, {
  millInfoFieldsValidation,
} from '../../gsp/NewApplication/MillInformationSection';
import SelectCertificateSection from '../../commonSections/SelectCertificateSection';
import YarnInfoSection, { yarnInfoFieldsValidation } from '../Verification/YarnInfoSection';
import WRmgInfoSection, { wRmgInfoFieldsValidation } from '../Verification/WRmgInfoSection';
import KRmgInfoSection, { kRmgInfoFieldsValidation } from '../Verification/KRmgInfoSection';
import IssueDateSection, { issueDateValidation } from '../../commonSections/IssueDateSection';
import CashAssistanceSection, { cashAssistanceFieldsValidation } from './CashAssistanceSection';

//actions & api
import {
  getAllApplications,
  applyForCertificate,
  getUnusedCertificates,
  assignPreSoldCertificate,
  getMillAnnualProductionCap,
} from '../../../../api/certificates';
import { notify } from '../../../../store/actions/globalActions';
import { FETCH_CASH_APPLICATIONS } from '../../../../store/actions/actionTypes';
import { getConversionRate, getConversionRates } from '../../../../api/currency';

//utils & constanst
import _ from 'lodash';
import { formatDate } from '../../../../util/formatUtils';
import { useYupValidationResolver } from '../../../../util/yupUtils';

const ApplyForCashIncentive = () => {
  const millErrorRef = useRef();
  const dispatch = useDispatch();
  const [modal, setModal] = useState();
  const assignCertificateRef = useRef();
  const [product, setProduct] = useState();
  const [formData, setFormData] = useState();
  const [capacity, setCapacity] = useState();
  const [certificate, setCertificate] = useState();
  const [conversions, setConversions] = useState();
  const [selectedMill, setSelectedMill] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [certErrorMsg, setCertErrorMsg] = useState('');
  const [millUnusedCerts, setMillUnusedCerts] = useState();
  const [certificateToggle, setCertificateToggle] = useState(false);
  const [modalButtonLoading, setModalButtonLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const fetchUnusedCertificates = async () => {
    let certificates = [];
    setMillUnusedCerts();
    const res = await getUnusedCertificates({ type: 'production', millId: selectedMill?.id });
    if (res.data?.status === 200) {
      res.data?.certificates?.map((cert) => {
        if (cert?.type === 'CASH') {
          certificates.push(cert);
        }
      });
      setMillUnusedCerts(certificates);
    }
  };

  const fetchProductionCapacity = async () => {
    const res = await getMillAnnualProductionCap(selectedMill?.id);
    if (res.data?.status === 200) {
      setCapacity(res.data.capacity);
    }
  };

  useEffect(() => {
    if (selectedMill?.id) {
      fetchUnusedCertificates();
      fetchProductionCapacity();
    }
    setCertificate();
    setValue('productType', 'YARN');
  }, [selectedMill?.id, certificateToggle]);

  useEffect(() => {
    if (certificate?.certNum) {
      setCertErrorMsg('');
      setValue('productType', 'YARN');
      if (selectedMill?.categoryId === 1) {
        setValue('productType', 'YARN');
      } else {
        setValue('productType', 'FABRIC');
      }
    } else {
      setCertErrorMsg('Please Select a Certificate');
    }
  }, [certificate]);

  useEffect(() => {
    (async () => {
      const res = await getConversionRates();
      setConversions(res?.data);
    })();
  }, []);

  const commonValidations = {
    ...issueDateValidation,
    ...yarnInfoFieldsValidation,
    ...wRmgInfoFieldsValidation,
    ...kRmgInfoFieldsValidation,
    ...millInfoFieldsValidation,
    ...fabricsInfoFieldsValidation,
    ...cashGenInfoFieldsValidation,
    ...documentInfoFieldsValidation,
    ...towelOthersInfoFieldsValidation,
    ...productInfoArrayFieldsValidation,
    ...productionProductTypeFieldsValidation,
  };

  const getValidationSchema = () => {
    switch (product) {
      case 'YARN':
        return yup.object().shape({
          ...commonValidations,
          ...cashAssistanceFieldsValidation,
        });
      case 'FABRIC':
        return yup.object().shape({
          ...commonValidations,
        });
      default:
        return yup.object().shape({
          ...commonValidations,
        });
    }
  };

  const resolver = useYupValidationResolver(getValidationSchema());

  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: { issueDate: new Date() },
  });

  const productType = useWatch({ control, name: 'productType' });

  useEffect(() => {
    setProduct(productType);
  }, [productType]);

  useEffect(() => {
    let capacityString = '';
    if (capacity?.yarnProdCap && capacity?.yarnProdCap !== '0') {
      capacityString += 'Yarn: ' + capacity?.yarnProdCap;
    }
    if (capacity?.knitProdCap && capacity?.knitProdCap !== '0') {
      capacityString += capacity?.yarnProdCap && capacity?.yarnProdCap !== '0' ? ', ' : '';
      capacityString += 'Knit: ' + capacity?.knitProdCap;
    }
    if (capacity?.wovenProdCap && capacity?.wovenProdCap !== '0') {
      capacityString +=
        (capacity?.yarnProdCap && capacity?.yarnProdCap !== '0') ||
        (capacity?.knitProdCap && capacity?.knitProdCap !== '0')
          ? ', '
          : '';
      capacityString += 'Woven: ' + capacity?.wovenProdCap;
    }

    setValue('annualProdCapacity', capacityString);
  }, [capacity]);

  /* currency conversion codes */
  const yarnCurrency = useWatch({ control, name: 'yarnCurrency' });
  const fabricCurrency = useWatch({ control, name: 'fabricCurrency' });
  const wrmgCurrency = useWatch({ control, name: 'wrmgCurrency' });
  const krmgCurrency = useWatch({ control, name: 'krmgCurrency' });
  const towelCurrency = useWatch({ control, name: 'towelCurrency' });

  const yarnConversionRate = useWatch({ control, name: 'yarnConversionRate' });
  const fabricConversionRate = useWatch({ control, name: 'fabricConversionRate' });
  const wrmgConversionRate = useWatch({ control, name: 'wrmgConversionRate' });
  const krmgConversionRate = useWatch({ control, name: 'krmgConversionRate' });
  const towelConversionRate = useWatch({ control, name: 'towelConversionRate' });

  const yarnValue = useWatch({ control, name: 'yarnValue' });
  const fabricValue = useWatch({ control, name: 'fabricValue' });
  const wrmgValue = useWatch({ control, name: 'wrmgValue' });
  const krmgValue = useWatch({ control, name: 'krmgValue' });
  const towelValue = useWatch({ control, name: 'towelValue' });

  useEffect(() => {
    setValue('yarnConversionRate', getConversionRate(yarnCurrency, conversions));
  }, [yarnCurrency]);
  useEffect(() => {
    setValue('fabricConversionRate', getConversionRate(fabricCurrency, conversions));
  }, [fabricCurrency]);
  useEffect(() => {
    setValue('wrmgConversionRate', getConversionRate(wrmgCurrency, conversions));
  }, [wrmgCurrency]);
  useEffect(() => {
    setValue('krmgConversionRate', getConversionRate(krmgCurrency, conversions));
  }, [krmgCurrency]);
  useEffect(() => {
    setValue('towelConversionRate', getConversionRate(towelCurrency, conversions));
  }, [towelCurrency]);

  useEffect(() => {
    if (yarnValue && yarnCurrency && yarnConversionRate) {
      setValue(
        'yarnValueInTk',
        Math.ceil(
          parseFloat(parseFloat(yarnValue || 0) * parseFloat(yarnConversionRate)).toFixed(2),
        ),
      );
    } else {
      setValue('yarnValueInTk', parseFloat(yarnValue || 0).toFixed(2));
    }
  }, [yarnConversionRate, yarnValue]);
  useEffect(() => {
    if (fabricValue && fabricCurrency && fabricConversionRate) {
      setValue(
        'fabricValueInTk',
        Math.ceil(
          parseFloat(parseFloat(fabricValue || 0) * parseFloat(fabricConversionRate)).toFixed(2),
        ),
      );
    } else {
      setValue('fabricValueInTk', parseFloat(fabricValue || 0).toFixed(2));
    }
  }, [fabricConversionRate, fabricValue]);
  useEffect(() => {
    if (wrmgValue && wrmgCurrency && wrmgConversionRate) {
      setValue(
        'wrmgValueInTk',
        Math.ceil(
          parseFloat(parseFloat(wrmgValue || 0) * parseFloat(wrmgConversionRate)).toFixed(2),
        ),
      );
    } else {
      setValue('wrmgValueInTk', parseFloat(wrmgValue || 0).toFixed(2));
    }
  }, [wrmgConversionRate, wrmgValue]);
  useEffect(() => {
    if (krmgValue && krmgCurrency && krmgConversionRate) {
      setValue(
        'krmgValueInTk',
        Math.ceil(
          parseFloat(parseFloat(krmgValue || 0) * parseFloat(krmgConversionRate)).toFixed(2),
        ),
      );
    } else {
      setValue('krmgValueInTk', parseFloat(krmgValue || 0).toFixed(2));
    }
  }, [krmgConversionRate, krmgValue]);
  useEffect(() => {
    if (towelValue && towelCurrency && towelConversionRate) {
      setValue(
        'towelValueInTk',
        Math.ceil(
          parseFloat(parseFloat(towelValue || 0) * parseFloat(towelConversionRate)).toFixed(2),
        ),
      );
    } else {
      setValue('towelValueInTk', parseFloat(towelValue || 0).toFixed(2));
    }
  }, [towelConversionRate, towelValue]);
  /* currency conversion codes */

  const formatProductDetails = (products) => {
    return products?.map((item) => ({
      ...item,
      invoiceDate: formatDate(item?.invoiceDate),
      deliveryDate: formatDate(item?.deliveryDate),
    }));
  };

  const onSubmit = (data) => {
    let formdata = {
      ...data,
      millId: selectedMill?.id,
      certId: certificate?.certID,
      dateOfLc: formatDate(data?.dateOfLc),
      issueDate: formatDate(data?.issueDate),
      btbLcDate: formatDate(data?.btbLcDate),
      mushakDate: formatDate(data?.mushakDate),
      bank: data?.bank + ',' + data?.bankBranch,
      expiryDateOfLc: formatDate(data?.expiryDateOfLc),
      productDetails: JSON.stringify(formatProductDetails(data?.productDetails)),
      btmaCashAssistanceCertForYarnDate: formatDate(data?.btmaCashAssistanceCertForYarnDate),
    };

    let normalizedFormData = _.mapKeys(formdata, (value, key) => {
      if (value) {
        return key;
      }
    });

    setFormData(normalizedFormData);
    setModal('CONFIRM_APPLICATION');
    onOpen();
  };

  const confirmSubmit = async () => {
    const res = await applyForCertificate({ type: 'production', formData });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      let resetObj = {};
      _.mapKeys(formData, (value, key) => (resetObj[key] = ''));
      reset({
        ...resetObj,
        productDetails: [],
        issueDate: new Date(),
        yarnConversionRate: 1,
        wrmgConversionRate: 1,
        krmgConversionRate: 1,
        towelConversionRate: 1,
        fabricConversionRate: 1,
        annualProdCapacity: formData?.annualProdCapacity,
      });
      const response = await getAllApplications({
        type: 'production',
        params: { page: 1, pageSize: 10, type: 'CASH' },
      });
      if (response.data?.status === 200) {
        dispatch({ type: FETCH_CASH_APPLICATIONS, payload: response.data });
      }
      setCertificateToggle(!certificateToggle);
    }
  };

  const handlePreSoldCert = () => {
    setModal('CERT_NUMBER_FORM');
    onOpen();
  };

  const handleAssignCert = async (data) => {
    const res = await assignPreSoldCertificate({
      millId: selectedMill?.id,
      certificateNum: data?.certificateNum,
      type: 'PRODUCTION_CASH',
    });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      fetchUnusedCertificates();
      onClose();
    }
  };

  const renderModal = () => {
    switch (modal) {
      case 'CONFIRM_APPLICATION':
        return (
          <DialogueBox
            close={onClose}
            alertTitle="Confirm"
            mountControl={isOpen}
            performAction={confirmSubmit}
            alertMessage="Are you sure you want to submit these information and verify this application?"
          />
        );
      case 'CERT_NUMBER_FORM':
        return (
          <DialogueBox
            close={onClose}
            onActionKeepOpen
            mountControl={isOpen}
            actionButtonText="Submit"
            cancelButtonText="Cancel"
            alertTitle="Assign Pre-sold Certificate"
            actionButtonLoading={modalButtonLoading}
            performAction={() => assignCertificateRef.current.submit()}>
            <AssignCertificate
              mill={selectedMill}
              certType="Cash-Incentive"
              ref={assignCertificateRef}
              handleQuery={handleAssignCert}
              submitting={setModalButtonLoading}
            />
          </DialogueBox>
        );
    }
  };

  return (
    <Box>
      <SearchSection setSelectedMill={setSelectedMill} />
      <Box mb={2} mt={5} px={4}>
        <Text fontSize="20px" fontWeight="bold" mb={2}>
          Select Mill & Certificate
        </Text>
        <Divider />
      </Box>
      <Grid templateColumns="repeat(5, 1fr)" gap={3} p={4} mx={4} bg="white" borderRadius="4px">
        <GridItem colSpan={2}>
          <Box
            p={4}
            zIndex={1}
            border="1px"
            bg="secondary"
            borderRadius="4px"
            position="relative"
            borderStyle="dashed"
            borderColor="primary.300">
            {selectedMill?.id ? (
              <CloseIcon
                right={3}
                cursor="pointer"
                position="absolute"
                onClick={() => setSelectedMill()}
              />
            ) : null}
            <Text fontSize="md" color="textSecondary">
              Selected Mill
            </Text>
            <Text fontSize="xl" mt={1.5}>
              {selectedMill?.millName || 'None'}
            </Text>
          </Box>
          {selectedMill ? null : (
            <Text fontSize="sm" color="invalid" ref={millErrorRef} mt={2}>
              Please Select a Mill
            </Text>
          )}
        </GridItem>
        {selectedMill && millUnusedCerts?.length > 0 ? (
          <GridItem colSpan={3}>
            <SelectCertificateSection
              error={certErrorMsg}
              certificates={millUnusedCerts}
              setCertificate={setCertificate}
              defaultValue={certificate}
            />
          </GridItem>
        ) : selectedMill ? (
          <GridItem
            colSpan={3}
            p={4}
            border="1px"
            bg="secondary"
            borderRadius="4px"
            borderColor="invalid">
            <VStack justifyContent="center" h="100%">
              <Text fontSize="md" color="invalid">
                No Unused Certificate Found for This Mill! The member needs to buy blank certificate
                or admin may assign unused blank certificate previously bought by the member.
              </Text>
            </VStack>
          </GridItem>
        ) : null}
      </Grid>

      {user.adminRole === 'SUPERADMIN' && (
        <>
          {selectedMill && !certificate ? (
            <FormButton ml={4} onClick={handlePreSoldCert}>
              Assign Pre-sold Certificate
            </FormButton>
          ) : null}
        </>
      )}

      {selectedMill && certificate ? (
        <MembershipFormWrapper formName="Cash-Incentive Application" mt={4}>
          <Grid templateColumns="repeat(2, 1fr)" gap={3}>
            <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
              <ProductTypeSection
                errors={errors}
                type="PRODUCTION"
                control={control}
                register={register}
              />
            </GridItem>

            {productType ? (
              <>
                <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
                  <IssueDateSection control={control} register={register} errors={errors} />
                  <GeneralInfoSection
                    type="CASH"
                    errors={errors}
                    control={control}
                    register={register}
                  />
                </GridItem>
                <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
                  <DocumentSection
                    type="CASH"
                    errors={errors}
                    control={control}
                    register={register}
                  />
                  {productType === 'YARN' ? (
                    <CashAssistanceSection control={control} register={register} errors={errors} />
                  ) : null}
                </GridItem>
                <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
                  <ProductInformation control={control} register={register} errors={errors} />
                </GridItem>
                <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
                  <MillInformationSection control={control} register={register} errors={errors} />
                </GridItem>
                <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
                  <YarnInfoSection control={control} register={register} errors={errors} />
                  <FabricsInfoSection control={control} register={register} errors={errors} />
                  <TowelOthersInfoSection control={control} register={register} errors={errors} />
                  <WRmgInfoSection control={control} register={register} errors={errors} />
                  <KRmgInfoSection control={control} register={register} errors={errors} />
                </GridItem>
              </>
            ) : null}
          </Grid>
          {productType ? (
            <HStack justifyContent="end" alignItems="center" mt={4}>
              <FormButton
                mt={0}
                isLoading={isSubmitting}
                onClick={(event) => {
                  event.preventDefault();
                  handleSubmit(onSubmit)();
                }}>
                SUBMIT
              </FormButton>
            </HStack>
          ) : null}
        </MembershipFormWrapper>
      ) : null}
      {renderModal()}
    </Box>
  );
};

export default ApplyForCashIncentive;
