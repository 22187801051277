import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { RadioGroup, Radio, Text, HStack, Box, Tooltip } from '@chakra-ui/react';

const RadioField = ({ control, name, label, choices, errorMessage, isRequired, defaultValue }) => {
  const [labelTooltip, setLabelTooltip] = useState(false);
  const [errorTooltip, setErrorTooltip] = useState(false);

  useEffect(() => {
    setLabelTooltip(
      document?.getElementById(name)?.offsetWidth < document?.getElementById(name)?.scrollWidth,
    );
    setErrorTooltip(
      document?.getElementById(`${name}err`)?.offsetWidth <
        document?.getElementById(`${name}err`)?.scrollWidth,
    );
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, name, ref } }) => (
        <RadioGroup
          ref={ref}
          onChange={onChange}
          value={value}
          name={name}
          defaultValue={defaultValue}>
          <Box display="flex" flexDir="column" alignItems="flex-start" alignSelf="flex-start">
            <Tooltip label={labelTooltip ? label : ''} area-label="A Tooltip" placement="top-start">
              <Text
                mb={1.5}
                id={name}
                fontSize="sm"
                overflow="hidden"
                whiteSpace="nowrap"
                color="textSecondary"
                textOverflow="ellipsis">
                {isRequired ? <b style={{ color: '#E53E3E' }}>* </b> : null}
                {label}
              </Text>
            </Tooltip>
            <HStack h={['40px', '40px', '40px', '45px', '50px']} px={1} mt={label ? null : '22px'}>
              {choices.map((c, index) => (
                <Radio
                  key={index}
                  fontSize="md"
                  value={c.value}
                  colorScheme="primary"
                  isChecked={c.value === defaultValue}>
                  {c.name}
                </Radio>
              ))}
            </HStack>
          </Box>
          <Tooltip
            label={errorTooltip ? errorMessage : ''}
            area-label="A Tooltip"
            placement="bottom-start">
            <Text
              id={`${name}err`}
              mb={1.5}
              fontSize="xs"
              color="invalid"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis">
              {errorMessage}
            </Text>
          </Tooltip>
        </RadioGroup>
      )}
    />
  );
};
export default RadioField;
