import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'annualWeavingCapacity',
    type: 'text',
    label: 'Capacity (In Tons)',
  },
];

export const WeavAnnualCapFieldsValidation = {
  [fields[0].name]: yup.string(),
};

const WeavAnnualCapSection = (props) => {
  return (
    <FormSection
      sectionName="Annual Weaving Capacity"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default WeavAnnualCapSection;
