import React, { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Icon, useDisclosure } from '@chakra-ui/react';

//common components
import Loader from '../../snippets/Loader';
import FormButton from '../../common/FormButton';
import DialogueBox from '../../common/DialogueBox';
import DataTable from '../../common/dataTable/DataTable';

//actions & api
import { FETCH_CASH_APPLICATIONS } from '../../../store/actions/actionTypes';
import { getAllApplications, updateApplicationStatus } from '../../../api/certificates';

//utils & constants
import {
  CASH_CERT_APPLY_URL,
  CASH_CERT_DETAILS_URL,
  CASH_CERT_VERIFICATION_URL,
} from '../../../constants/routerUrl';
import { LOGS_TABLE_DISPLAY_SIZE, BADGE_STATE_COLORS } from '../../../constants/dataTableConstants';

const CashIncentive = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState();
  const [loader, setLoader] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const data = useSelector((state) => state.certificate.cashApplications);

  useEffect(() => {
    document.title = 'BTMA - Certificates';
  }, []);

  const columns = [
    { header: 'Certificate No', accessor: 'certificateNum' },
    { header: 'Date', accessor: 'createdAt', isDate: true },
    { header: 'Mill Name', accessor: 'millName' },
    { header: 'Contact', accessor: 'phoneNo' },
    {
      header: 'Status',
      accessor: 'status',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
      hoverInfo: ['remarks'],
    },
    {
      header: 'Action',
      accessor: 'status',
      isButton: true,
      actionScheme: [
        { group: 'APPLIED', variant: 'read', action: 'VIEW' },
        { group: 'IN REVIEW', variant: 'read', action: 'VIEW' },
        { group: 'REWORK', variant: 'read', action: 'VIEW' },
        { group: 'APPROVED', variant: 'read', action: 'VIEW' },
        { group: 'REJECTED', variant: 'read', action: 'VIEW' },
      ],
    },
  ];

  const handleView = ({ rowObject }) => {
    rowObject?.status === 'APPROVED'
      ? history.push({ pathname: CASH_CERT_DETAILS_URL, state: { applicationId: rowObject?.id } })
      : rowObject?.status === 'APPLIED'
      ? (() => {
          onOpen();
          setRowData(rowObject);
        })()
      : history.push({
          pathname: CASH_CERT_VERIFICATION_URL,
          state: { applicationId: rowObject?.id },
        });
  };

  const updateStatus = async () => {
    setLoader(true);
    const res = await updateApplicationStatus({
      id: rowData?.id,
      formData: { status: 'IN REVIEW' },
    });
    if (res.data?.status === 200) {
      handleSearch({ page: 1, pageSize: 10 });
      setLoader(false);
      history.push({
        pathname: CASH_CERT_VERIFICATION_URL,
        state: { applicationId: rowData?.id },
      });
    }
  };

  const actionObject = {
    VIEW: handleView,
  };

  const applicationStatus = {
    name: 'Application Status',
    choices: [
      { name: 'Applied', value: 'APPLIED' },
      { name: 'In Review', value: 'IN REVIEW' },
      { name: 'Rework', value: 'REWORK' },
      { name: 'Approved', value: 'APPROVED' },
      { name: 'Rejected', value: 'REJECTED' },
    ],
  };

  const handlePagination = async ({ page, pageSize, searchKey, startDate, endDate, selected }) => {
    dispatch({ type: FETCH_CASH_APPLICATIONS, payload: null });
    const params = {
      page,
      endDate,
      pageSize,
      startDate,
      searchKey,
      type: 'CASH',
      status: selected?.['Application Status'],
    };
    const res = await getAllApplications({
      type: 'production',
      params,
    });
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_CASH_APPLICATIONS, payload: res.data });
    } else {
      dispatch({ type: FETCH_CASH_APPLICATIONS, payload: { certificates: [] } });
    }
  };

  const handleSearch = async ({ searchKey, startDate, endDate, selected }) => {
    dispatch({ type: FETCH_CASH_APPLICATIONS, payload: null });
    const params = {
      endDate,
      startDate,
      searchKey,
      type: 'CASH',
      status: selected?.['Application Status'],
    };
    const res = await getAllApplications({
      type: 'production',
      params,
    });
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_CASH_APPLICATIONS, payload: res.data });
    } else {
      dispatch({ type: FETCH_CASH_APPLICATIONS, payload: { certificates: [] } });
    }
  };

  return !loader ? (
    <Box p={4}>
      <FormButton
        mt={0}
        mb={4}
        rightIcon={<Icon as={MdAdd} w={5} h={5} />}
        onClick={() => history.push(CASH_CERT_APPLY_URL)}>
        Apply For Cash-Incentive
      </FormButton>
      <DataTable
        topPanel
        keySearch
        dateRange
        isPaginated
        columns={columns}
        search={handleSearch}
        actions={actionObject}
        currentPage={data?.page}
        data={data?.certificates}
        paginate={handlePagination}
        entries={data?.totalEntries}
        currentPageSize={data?.pageSize}
        selectOptions={[applicationStatus]}
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
        caption="Cash-Incentive Applications"
      />
      <DialogueBox
        close={onClose}
        mountControl={isOpen}
        alertTitle="Start Review"
        performAction={updateStatus}
        alertMessage="Are you sure you want to start reviewing this certificate application? Beginning review will update the application status to ‘IN REVIEW’"
      />
    </Box>
  ) : (
    <Loader />
  );
};

export default CashIncentive;
