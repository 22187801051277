import React, { useEffect, useRef } from 'react';
import { Modal, ModalOverlay, ModalCloseButton, Box, Text, Divider } from '@chakra-ui/react';

const MillRepresentativeCreateModal = ({ mountControl, close, modalTitle, children }) => {
  useEffect(() => {
    if (mountControl) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [mountControl]);

  const isOpen = mountControl;
  const cancelRef = useRef();

  return (
    <Modal isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={close}>
      <ModalOverlay>
        <Box position="absolute" w="100vw" h="100vh" onClick={close} />
        <Box
          p={4}
          top="50%"
          left="50%"
          maxW="680px"
          maxH="600px"
          minW="400px"
          minH="240px"
          bgColor="white"
          overflowY="auto"
          borderRadius={10}
          overflowX="hidden"
          position="absolute"
          transform="translate(-50%, -50%)"
          boxShadow="0px 5px 10px rgba(0,0,0,0.3)">
          <ModalCloseButton />
          <Text fontSize="lg" fontWeight="bold" textTransform="uppercase" mb={1}>
            {modalTitle || 'Modal'}
          </Text>
          <Divider />
          {children}
        </Box>
      </ModalOverlay>
    </Modal>
  );
};

export default MillRepresentativeCreateModal;
