import React from 'react';
import { Grid, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const NavPanel = ({ navState, setNavState, navList, gap = 1, linkForDuty = false }) => {
  return (
    <Grid templateColumns={`repeat(${navList.length}, 1fr)`} gap={gap}>
      {navList.map((nav, index) => (
        <Button
          key={index}
          isActive={navState === index}
          variant="tabBarTab"
          onClick={() => {
            setNavState(index);
          }}>
          <Link to={!linkForDuty ? `?tab=${index}` : `?tab=3&dutyTab=${index}`} key={index}>
            {nav}
          </Link>
        </Button>
      ))}
    </Grid>
  );
};

export default NavPanel;
