import React from 'react';
import * as yup from 'yup';
import { dateValidation } from '../../../util/yupUtils';
import FieldArrayFormSection from '../../common/hookFormComponents/FieldArrayFormSection';

const fields = [
  {
    colSpan: 1,
    name: 'product',
    type: 'text',
    label: 'Description of Product',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'productQuantity',
    type: 'text',
    label: 'Quantity or Weight',
    isRequired: true,
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'deliveryDate',
    label: 'Date of Delivery',
  },
  {
    colSpan: 1,
    name: 'invoice',
    type: 'text',
    label: 'Com. Invoice No',
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'invoiceDate',
    label: 'Com. Invoice Date',
  },
];

export const defaultProductArrayFieldValues = fields.reduce(
  (obj, item) => [{ ...obj, [item['name']]: '' }],
  [{}],
);

export const productInfoArrayFieldsValidation = {
  productDetails: yup.array().of(
    yup.object().shape({
      [fields[0].name]: yup
        .string()
        .required()
        // .max(20, `${fields[0].label} can not be more than 20 characters`)
        .label(fields[0].label),
      [fields[1].name]: yup
        .string()
        // .max(20, `${fields[1].label} can not be more than 20 characters`)
        .required()
        .label(fields[1].label),
      [fields[2].name]: dateValidation.label(fields[2].label),
      [fields[3].name]: yup.string().label(fields[3].label),
      [fields[4].name]: dateValidation.label(fields[4].label),
    }),
  ),
};

const ProductInformation = (props) => {
  return (
    <FieldArrayFormSection
      sectionName="Product Information"
      sectionColumn={5}
      name="productDetails"
      formFields={fields}
      {...props}
    />
  );
};

export default ProductInformation;
