import React, { useEffect, useRef, useState, memo, useCallback } from 'react';
import { FaReceipt } from 'react-icons/fa';
import {
  RiDatabaseFill,
  RiDraftFill,
  RiFileShield2Line,
  RiFileWarningLine,
  RiMoneyDollarCircleFill,
  RiUser2Line,
  RiUserFollowLine,
  RiUserSettingsLine,
  RiUserStarLine,
} from 'react-icons/ri';

import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Stack,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import ExcelExport from 'export-xlsx';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { TiExport } from 'react-icons/ti';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { getEodLogs, getEodStatements } from '../../api/auth';
import FabricIcon from '../../assets/FabricIcon.png';
import PendingIcon from '../../assets/PendingIcon.png';
import TextileIcon from '../../assets/TextileIcon.png';
import YarnIcon from '../../assets/YarnIcon.png';
import { REPORT_DATE_RANGE } from '../../constants/reportConstant';
import * as routerUrl from '../../constants/routerUrl';
import { formatDate, formatDateSimple, renderDate, renderMonth } from '../../util/formatUtils';
import DialogueBox from '../common/DialogueBox';
import FormButton from '../common/FormButton';
import DateRangePicker from '../common/dataTable/DateRangePicker';
import Loader from '../snippets/Loader';
import EodLogs from './EodLogs';
import EodStatement from './EodStatement';

const Statistics = ({ data }) => {
  const history = useHistory();
  const eodStatementRef = useRef();
  const eodLogRef = useRef();
  const [modal, setModal] = useState();
  const [endDate, setEndDate] = useState();
  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState();
  const [printData, setPrintData] = useState();
  const [paymentSum, setPaymentSum] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dateErrorMsg, setDateErrorMsg] = useState('');
  const [membershipTrx, setMembershipTrx] = useState(false);
  const [clearDateSelect, setClearDateSelect] = useState(false);
  const [issueCheckedItems, setIssueCheckedItems] = useState([]);
  const [salesCheckedItems, setSalesCheckedItems] = useState([]);
  const [dateRangeValidation, setDateRangeValidation] = useState(true);
  const [selections, setSelections] = useState({
    issueCheckedItems,
    salesCheckedItems,
    membershipTrx,
    paymentSum,
  });

  const isIssueIndeterminate = issueCheckedItems.length > 0 && issueCheckedItems.length !== 4;
  const isSalesIndeterminate = salesCheckedItems.length > 0 && salesCheckedItems.length !== 5;

  const [isAllChecked, setIsAllChecked] = useState(false);

  useEffect(() => {
    if (
      issueCheckedItems.length === 6 &&
      salesCheckedItems.length === 7 &&
      membershipTrx &&
      paymentSum
    ) {
      setIsAllChecked(true);
    }
  }, [issueCheckedItems, salesCheckedItems, membershipTrx, paymentSum]);

  const handleSelectAll = useCallback(() => {
    setIsAllChecked(!isAllChecked);

    if (isAllChecked) {
      setSelections({
        paymentSum: false,
        membershipTrx: false,
        salesCheckedItems: [],
        issueCheckedItems: [],
      });
      setIssueCheckedItems([]);
      setSalesCheckedItems([]);
      setMembershipTrx(false);
      setPaymentSum(false);
    } else {
      setSelections({
        paymentSum: true,
        membershipTrx: true,
        salesCheckedItems: printData?.result?.map((item) => item.title),
        issueCheckedItems: printData?.result
          ?.filter((item) => item.title !== 'Duty-Exemption (Application Fee)')
          ?.map((item) => item.title),
      });
      setIssueCheckedItems(
        printData?.result
          ?.filter((item) => item.title !== 'Duty-Exemption (Application Fee)')
          ?.map((item) => item.title),
      );
      setSalesCheckedItems(printData?.result?.map((item) => item.title));
      setMembershipTrx(true);
      setPaymentSum(true);
    }
  });

  const handleIssueCheck = (title) => {
    let issueArray = issueCheckedItems;
    if (title === 'ALL') {
      if (issueArray.length === 5) {
        issueArray = [];
      } else {
        issueArray = printData?.result
          ?.filter((item) => item.title !== 'Duty-Exemption (Application Fee)')
          ?.map((item) => item.title);
      }
    } else {
      if (issueCheckedItems.includes(title)) {
        issueArray.splice(issueArray.indexOf(title), 1);
      } else {
        issueArray.push(title);
      }
    }
    setIssueCheckedItems(issueArray);
    setSelections({ ...selections, issueCheckedItems: issueArray });
  };

  const handleSalesCheck = (title) => {
    let salesArray = salesCheckedItems;
    if (title === 'ALL') {
      if (salesArray.length === 6) {
        salesArray = [];
      } else {
        salesArray = printData?.result?.map((item) => item.title);
      }
    } else {
      if (salesCheckedItems.includes(title)) {
        salesArray.splice(salesArray.indexOf(title), 1);
      } else {
        salesArray.push(title);
      }
    }
    setSalesCheckedItems(salesArray);
    setSelections({ ...selections, salesCheckedItems: salesArray });
  };

  const getTotalMill = () => {
    return (
      parseInt(data?.membershipStats?.yarnManufacturers) +
      parseInt(data?.membershipStats?.fabricsManufactuers) +
      parseInt(data?.membershipStats?.textileManufactuers)
    );
  };

  const getTotalCertIssue = () => {
    return (
      parseInt(data?.certificateStats?.cashCertificateIssuedForCurrentMonth) +
      parseInt(data?.certificateStats?.gspCertificateIssuedForCurrentMonth) +
      parseInt(data?.certificateStats?.processingCertificateIssuedForCurrentMonth) +
      parseInt(data?.certificateStats?.dutyCertificateIssuedForCurrentMonth)
    );
  };

  const getTotalCertReq = () => {
    return (
      parseInt(data?.certificateStats?.cashCertificateRequests) +
      parseInt(data?.certificateStats?.gspCertificateRequests) +
      parseInt(data?.certificateStats?.processingCertificateRequests) +
      parseInt(data?.certificateStats?.dutyCertificateRequests)
    );
  };

  const getHeight = (value) => {
    let val = parseInt(value);
    let total = getTotalCertIssue();
    let height = 180 * (val / total);
    return `${height}px`;
  };

  const renderUserIcon = (role) => {
    switch (role) {
      case 'SUPERADMIN':
        return RiUserStarLine;
      case 'ADMIN':
        return RiUserSettingsLine;
      case 'ACCOUNTS':
        return RiUserFollowLine;
      case 'OPERATIONS':
      case 'FEE_COLLECTOR':
        return RiUser2Line;
      default:
        return null;
    }
  };

  const membershipStatsFields = [
    {
      graphic: YarnIcon,
      caption: 'Category-A',
      accentColor: '#94702B',
      primaryColor: '#E8E5E2',
      label: 'Yarn Manufacturers',
      link: routerUrl.ACTIVE_MEMBERS,
      info: data?.membershipStats?.yarnManufacturers || 0,
    },
    {
      graphic: FabricIcon,
      caption: 'Category-B',
      accentColor: '#150381',
      primaryColor: '#CFCCE6',
      label: 'Fabric Manufacturers',
      link: routerUrl.ACTIVE_MEMBERS,
      info: data?.membershipStats?.fabricsManufactuers || 0,
    },
    {
      graphic: TextileIcon,
      caption: 'Category-C',
      accentColor: '#276749',
      primaryColor: '#C1ECD2',
      label: 'Product Processors',
      link: routerUrl.ACTIVE_MEMBERS,
      info: data?.membershipStats?.textileManufactuers || 0,
    },
    {
      graphic: PendingIcon,
      caption: 'Pending',
      accentColor: '#9B4236',
      primaryColor: '#ECDBD9',
      label: 'Membership Requests',
      link: routerUrl.NEW_REQUEST_URL,
      info: data?.membershipStats?.membershipRequests || 0,
    },
  ];

  const certIssuanceStatsFields = [
    {
      caption: 'Cash',
      accentColor: '#94702B',
      primaryColor: '#E8E5E2',
      info: data?.certificateStats?.cashCertificateIssuedForCurrentMonth || 0,
      height: getHeight(data?.certificateStats?.cashCertificateIssuedForCurrentMonth || 0),
    },
    {
      caption: 'GSP',
      accentColor: '#150381',
      primaryColor: '#CFCCE6',
      info: data?.certificateStats?.gspCertificateIssuedForCurrentMonth || 0,
      height: getHeight(data?.certificateStats?.gspCertificateIssuedForCurrentMonth || 0),
    },
    {
      caption: 'Processing',
      accentColor: '#276749',
      primaryColor: '#C1ECD2',
      info: data?.certificateStats?.processingCertificateIssuedForCurrentMonth || 0,
      height: getHeight(data?.certificateStats?.processingCertificateIssuedForCurrentMonth || 0),
    },
    {
      caption: 'Duty',
      accentColor: '#9B4236',
      primaryColor: '#ECDBD9',
      info: data?.certificateStats?.dutyCertificateIssuedForCurrentMonth || 0,
      height: getHeight(data?.certificateStats?.dutyCertificateIssuedForCurrentMonth || 0),
    },
  ];

  const certReqStatsFields = [
    {
      accentColor: '#94702B',
      primaryColor: '#E8E5E2',
      link: routerUrl.CASH_CERT_URL,
      caption: 'Cash-Incentive Certificate',
      info: data?.certificateStats?.cashCertificateRequests || 0,
    },
    {
      accentColor: '#150381',
      primaryColor: '#CFCCE6',
      link: routerUrl.GSP_CERT_URL,
      caption: 'GSP Certificate',
      info: data?.certificateStats?.gspCertificateRequests || 0,
    },
    {
      accentColor: '#276749',
      primaryColor: '#C1ECD2',
      link: routerUrl.PROCESSING_CERT_URL,
      caption: 'Processing Certificate',
      info: data?.certificateStats?.processingCertificateRequests || 0,
    },
    {
      accentColor: '#9B4236',
      primaryColor: '#ECDBD9',
      link: routerUrl.DUTY_CERT_URL,
      caption: 'Duty-Exemption Certificate',
      info: data?.certificateStats?.dutyCertificateRequests || 0,
    },
  ];

  const printStatement = useReactToPrint({
    documentTitle: `BTMA-Statement-${renderDate(new Date())}`,
    content: () => eodStatementRef.current,
  });

  const printLogStatement = useReactToPrint({
    documentTitle: `BTMA-Logs-${renderDate(new Date())}`,
    content: () => eodLogRef.current,
  });

  const handlePrint = async () => {
    setLoader(true);
    const res = await getEodStatements({ startDate, endDate });

    if (res.data?.status === 200) {
      let issueData = res.data.certificatesIssued;
      let saleData = res.data.certificatesPurchased;
      let memTrxData = res.data.membershipTransactions;

      let result = [
        {
          title: 'GSP',
          issueDetails: issueData.gspCertificatesIssued,
          saleDetails: saleData.gspCertificatesSold,
          issued: issueData.gspCertificatesIssuedCount,
          sold: saleData.gspCertificatesSoldCount,
          revenue: saleData.gspTotalRevenue,
        },
        {
          title: 'Cash-Incentive',
          issueDetails: issueData.cashCertificatesIssued,
          saleDetails: saleData.cashCertificatesSold,
          issued: issueData.cashCertificatesIssuedCount,
          sold: saleData.cashCertificatesSoldCount,
          revenue: saleData.cashTotalRevenue,
        },
        {
          title: 'Processing',
          issueDetails: issueData.processingCertificatesIssued,
          saleDetails: saleData.processingCertificatesSold,
          issued: issueData.processingCertificatesIssuedCount,
          sold: saleData.processingCertificatesSoldCount,
          revenue: saleData.processingTotalRevenue,
        },
        {
          title: 'Duty-Exemption',
          issueDetails: issueData.dutyCertificatesIssued,
          saleDetails: saleData.dutyCertificateSold,
          issued: issueData.dutyCertificatesIssuedCount,
          sold: saleData.dutyCertificatesSoldCount,
          revenue: saleData.dutyTotalRevenue,
        },
        {
          title: 'Duty-Exemption (Application Fee)',
          saleDetails: saleData.dutyBlankCertificatesSold,
          sold: saleData.dutyBlankCertificatesSoldCount,
          revenue: saleData.dutyBlankTotalRevenue,
        },
        {
          title: 'Extra Collection (Duty)',
          saleDetails: saleData?.extraCollectionForDuty,
          sold: saleData?.extraCollectionForDutyCount,
          revenue: saleData?.extraCollectionForDutyTotalRevenue,
        },
        {
          title: 'New Market Expansion',
          issueDetails: issueData.marketExpansionCertificatesIssued,
          saleDetails: saleData?.marketExpansionCertificatesSold,
          issued: issueData.marketExpansionCertificatesIssuedCount,
          sold: saleData?.marketExpansionCertificatesSoldCount,
          revenue: saleData?.marketExpansionTotalRevenue,
        },
      ];

      setPrintData({
        result,
        membershipTransaction: {
          title: 'Membership-Transactions',
          details: memTrxData,
        },
        date:
          startDate && endDate
            ? startDate !== endDate
              ? `${renderDate(startDate)} to ${renderDate(endDate)}`
              : renderDate(startDate)
            : renderDate(new Date()),
      });
      setModal('EOD_SELECTION');
      onOpen();
    }
    setLoader(false);
  };

  const handleLogsPrint = async () => {
    setLoader(true);
    const res = await getEodLogs({ startDate, endDate });

    if (res.data?.status === 200) {
      let issueData = res.data.certificatesIssued;
      let saleData = res.data.certificatesPurchased;

      let result = [
        {
          title: 'GSP',
          issueDetails: issueData.gspCertificatesIssued,
          saleDetails: saleData.gspCertificatesSold,
          issued: issueData.gspCertificatesIssuedCount,
          sold: saleData.gspCertificatesSoldCount,
          revenue: saleData.gspTotalRevenue,
        },
        {
          title: 'Cash-Incentive',
          issueDetails: issueData.cashCertificatesIssued,
          saleDetails: saleData.cashCertificatesSold,
          issued: issueData.cashCertificatesIssuedCount,
          sold: saleData.cashCertificatesSoldCount,
          revenue: saleData.cashTotalRevenue,
        },
        {
          title: 'Processing',
          issueDetails: issueData.processingCertificatesIssued,
          saleDetails: saleData.processingCertificatesSold,
          issued: issueData.processingCertificatesIssuedCount,
          sold: saleData.processingCertificatesSoldCount,
          revenue: saleData.processingTotalRevenue,
        },
        {
          title: 'Duty-Exemption',
          issueDetails: issueData.dutyCertificatesIssued,
          saleDetails: saleData.dutyCertificateSold,
          issued: issueData.dutyCertificatesIssuedCount,
          sold: saleData.dutyCertificateSoldCount,
          revenue: saleData.dutyTotalRevenue,
        },
        {
          title: 'Duty-Exemption (Application Fee)',
          saleDetails: saleData.dutyBlankCertificatesSold || [],
          sold: saleData.dutyBlankCertificatesSoldCount || [],
          revenue: saleData.dutyBlankTotalRevenue || [],
        },
        {
          title: 'New Market Expansion',
          issueDetails: issueData.marketExpansionCertificatesIssued,
          saleDetails: saleData.marketExpansionCertificatesSold || [],
          issued: issueData.marketExpansionCertificatesIssuedCount,
          sold: saleData.marketExpansionCertificatesSoldCount || [],
          revenue: saleData.marketExpansionTotalRevenue || [],
        },
      ];

      setPrintData({
        result,
        date:
          startDate && endDate
            ? startDate !== endDate
              ? `${renderDate(startDate)} to ${renderDate(endDate)}`
              : renderDate(startDate)
            : renderDate(new Date()),
      });
      setModal('EOD_LOG_SELECTION');
      onOpen();
    }
    setLoader(false);
  };

  const handleDailyStat = () => {
    setModal('EOD_FORM');
    onOpen();
  };

  const handleDailyLogs = () => {
    setModal('EOD_LOG_FORM');
    onOpen();
  };

  const handleDateRange = (data) => {
    setStartDate(formatDate(data.startDate));
    setEndDate(formatDate(data.endDate));
  };

  const checkDateRangeLength = () => {
    if (
      (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24) >
      REPORT_DATE_RANGE
    ) {
      setDateRangeValidation(false);
      setDateErrorMsg('Maximum one year range is recommended for date');
    } else {
      setDateRangeValidation(true);
      setDateErrorMsg('');
    }
  };

  useEffect(() => {
    checkDateRangeLength();
  }, [startDate, endDate]);

  const sortDataByPaymentMethod = (unsortedData, sortKey, title) => {
    const amountKey =
      title === 'Duty-Exemption' || title === 'Membership' || title === 'Other'
        ? 'paidAmount'
        : 'total';
    let cash = [];
    let cheque = [];
    let eft = [];
    let mfs = [];

    let cashTotal = 0;
    let chequeTotal = 0;
    let eftTotal = 0;
    let mfsTotal = 0;

    if (title == 'Duty-Exemption') {
      unsortedData.forEach((element) => {
        element?.paymentHistory?.forEach((payment) => {
          switch (payment?.paymentMethod) {
            case 'CASH':
              cash.push({ ...element, ...payment });
              break;
            case 'CHEQUE':
              cheque.push({ ...element, ...payment });
              break;
            case 'EFT':
              eft.push({ ...element, ...payment });
              break;
            case 'MFS':
              mfs.push({ ...element, ...payment });
              break;
            default:
              break;
          }
        });
      });
    } else {
      cash = unsortedData?.filter((item) => item?.[sortKey] === 'CASH');
      cheque = unsortedData?.filter((item) => item?.[sortKey] === 'CHEQUE');
      eft = unsortedData?.filter((item) => item?.[sortKey] === 'EFT');
      mfs = unsortedData?.filter((item) => item?.[sortKey] === 'MFS');
    }

    cash?.forEach((element) => {
      cashTotal += parseInt(element?.[amountKey]) || 0;
    });
    cheque?.forEach((element) => {
      chequeTotal += parseInt(element?.[amountKey]) || 0;
    });
    eft?.forEach((element) => {
      eftTotal += parseInt(element?.[amountKey]) || 0;
    });
    mfs?.forEach((element) => {
      mfsTotal += parseInt(element?.[amountKey]) || 0;
    });

    return {
      sorted: [
        { data: cash, total: cashTotal },
        { data: cheque, total: chequeTotal },
        { data: eft, total: eftTotal },
        { data: mfs, total: mfsTotal },
      ],
      grandTotal: cashTotal + chequeTotal + eftTotal + mfsTotal,
    };
  };

  const getHeaders = (type, title) => {
    if (type === 'ISSUE') {
      return [
        { name: 'Mill Name', key: 'millName' },
        { name: 'Batch Id', key: 'batchId' },
        { name: 'Certificate No', key: 'certificateNum' },
      ];
    } else if (type === 'SALES') {
      if (title === 'Duty-Exemption') {
        return [
          { name: 'Date', key: 'payDay' },
          { name: 'Mill Name', key: 'millName' },
          { name: 'Serial', key: 'serial' },
          { name: 'Total (৳)', key: 'total' },
          { name: 'Discount (৳)', key: 'discount' },
          { name: 'Adjustments (৳)', key: 'adjustments' },
          { name: 'Dues (৳)', key: 'dues' },
          { name: 'Money Receipt No.', key: 'moneyReceiptNo' },
          { name: 'Payment Method', key: 'paymentMethod' },
          { name: 'Transaction ID', key: 'chequeNo' },
          { name: 'Cheque Date', key: 'cheqDate' },
          { name: 'Paid Amount (৳)', key: 'paidAmount' },
        ];
      } else if (title === 'Extra Collection (Duty)') {
        return [
          { name: 'Date', key: 'createdAt' },
          { name: 'Mill Name', key: 'millName' },
          { name: 'Money Reciept No', key: 'moneyRecieptNo' },
          { name: 'Paid Amount', key: 'paidAmount' },
          { name: 'Payment Method', key: 'paymentMethod' },
          { name: 'Cheque No.', key: 'chequeNo' },
          { name: 'Extra Collection', key: 'amount' },
        ];
      } else {
        return [
          { name: 'Mill Name', key: 'millName' },
          { name: 'Money Receipt No.', key: 'uid' },
          { name: 'Payment Method', key: 'paymentMethod' },
          { name: 'Transaction Id', key: 'transactionId' },
          { name: 'Cheque Date', key: 'paymentDate' },
          { name: 'Range', key: 'range' },
          { name: 'Copies', key: 'noOfCopies' },
          { name: 'Unit cost (৳)', key: 'unitCost' },
          { name: 'Sub-total (৳)', key: 'total' },
        ];
      }
    } else if (type === 'MEMBERSHIP') {
      return [
        { name: 'Date', key: 'date' },
        { name: 'Trx Id', key: 'trxId' },
        { name: 'Mill Name', key: 'millName' },
        { name: 'PM', key: 'paymentMethod' },
        { name: 'PM Date', key: 'cheqDate' },
        { name: 'Transaction ID', key: 'chequeNo' },
        { name: 'Period', key: 'period' },
        { name: 'Payment For', key: 'remarks' },
        { name: 'Paid Amount', key: 'paidAmount' },
      ];
    } else if (type === 'LOG_ISSUE') {
      return [
        { name: 'Issuer', key: 'fullName' },
        { name: 'Role', key: 'adminRole' },
        { name: 'Quantity', key: 'count' },
      ];
    } else if (type === 'LOG_SALES') {
      return [
        { name: 'Issuer', key: 'fullName' },
        { name: 'Role', key: 'adminRole' },
        { name: 'Quantity', key: 'count' },
        { name: 'Total', key: 'subtotal' },
      ];
    } else {
      return [
        { name: 'Category', key: 'category' },
        { name: 'Service', key: 'service' },
        { name: 'Payment Method', key: 'paymentMethod' },
        { name: 'Collected', key: 'collected' },
        { name: 'Sub Total For', key: 'subTotal' },
      ];
    }
  };

  const prepareWorksheets = () => {
    let sheets = [];

    issueCheckedItems?.forEach((item) => {
      sheets.push({
        type: 'ISSUE',
        title: item,
        sheetName: `${item}-Issue-Report`,
        startingRowNumber: 1,
        tableSettings: {
          data: {
            importable: true,
            headerDefinition: getHeaders('ISSUE', item),
          },
        },
      });
    });

    salesCheckedItems?.forEach((item) => {
      sheets.push({
        type: 'SALES',
        title: item,
        sheetName: `${item}-Sales-Report`,
        startingRowNumber: 1,
        tableSettings: {
          data: {
            importable: true,
            headerDefinition: getHeaders('SALES', item),
          },
        },
      });
    });

    if (membershipTrx) {
      sheets.push({
        type: 'MEMBERSHIP',
        title: 'Membership-Transactions',
        sheetName: 'Membership-Transaction-Report',
        startingRowNumber: 1,
        tableSettings: {
          data: {
            importable: true,
            headerDefinition: getHeaders('MEMBERSHIP'),
          },
        },
      });
      sheets.push({
        type: 'MEMBERSHIP',
        title: 'Other-Transactions',
        sheetName: 'Other-Transaction-Report',
        startingRowNumber: 1,
        tableSettings: {
          data: {
            importable: true,
            headerDefinition: getHeaders('MEMBERSHIP'),
          },
        },
      });
    }

    if (paymentSum) {
      sheets.push({
        type: 'PAYMENT',
        title: 'Payment-Summary',
        sheetName: 'Payment-Summary-Report',
        startingRowNumber: 1,
        tableSettings: {
          data: {
            importable: true,
            headerDefinition: getHeaders('PAYMENT'),
          },
        },
      });
    }

    return sheets;
  };

  const prepareWorksheetsForLogReport = () => {
    let sheets = [];

    issueCheckedItems?.forEach((item) => {
      sheets.push({
        type: 'ISSUE',
        title: item,
        sheetName: `${item}-Issue-Report`,
        startingRowNumber: 1,
        tableSettings: {
          data: {
            importable: true,
            headerDefinition: getHeaders('LOG_ISSUE', item),
          },
        },
      });
    });

    salesCheckedItems?.forEach((item) => {
      sheets.push({
        type: 'SALES',
        title: item,
        sheetName: `${item}-Sales-Report`,
        startingRowNumber: 1,
        tableSettings: {
          data: {
            importable: true,
            headerDefinition: getHeaders('LOG_SALES', item),
          },
        },
      });
    });

    return sheets;
  };

  const SETTINGS_FOR_EXPORT = {
    fileName: `BTMA-Statement-${renderDate(new Date())}`,
    workSheets: prepareWorksheets(),
  };

  const SETTINGS_FOR_EXPORT_LOGS = {
    fileName: `BTMA-Logs-${renderDate(new Date())}`,
    workSheets: prepareWorksheetsForLogReport(),
  };

  const prepareSheetWiseData = () => {
    let wsData = [];
    let rawData = printData?.result;
    let rawTransactionData = printData?.membershipTransaction?.details;
    let workSheets = SETTINGS_FOR_EXPORT.workSheets;
    //console.log(workSheets);

    workSheets?.forEach((item) => {
      if (item?.sheetName === 'Membership-Transaction-Report') {
        wsData.push({
          data: rawTransactionData?.membershipPayments?.map((item) => ({
            date: renderDate(item?.issueDate, 'numeric'),
            trxId: item?.trxId,
            millName: item?.millInfo?.millName,
            paymentMethod: item?.paymentMethod,
            chequeNo: item?.chequeNo,
            cheqDate: renderDate(item?.chequeDate, 'numeric'),
            period: item?.period,
            remarks: item?.remarks,
            paidAmount: item?.paidAmount,
          })),
        });
      } else if (item?.sheetName === 'Other-Transaction-Report') {
        wsData.push({
          data: rawTransactionData?.otherPayments?.map((item) => ({
            date: renderDate(item?.issueDate, 'numeric'),
            trxId: item?.trxId,
            millName: item?.millInfo?.millName,
            paymentMethod: item?.paymentMethod,
            chequeNo: item?.chequeNo,
            cheqDate: renderDate(item?.chequeDate, 'numeric'),
            period: item?.period,
            remarks: item?.remarks,
            paidAmount: item?.paidAmount,
          })),
        });
      } else if (item?.sheetName === 'Payment-Summary-Report') {
        let count = 0;
        const categories = { 1: 'Certificate Sales', 17: 'Membership Subscription' };
        const services = {
          1: 'GSP',
          5: 'Cash-Incentive',
          9: 'Processing',
          13: 'Duty-Exemption',
          17: 'Membership',
          21: 'Other',
        };
        const paymentMethods = {
          0: 'MFS',
          1: 'Cash',
          2: 'Cheque',
          3: 'EFT',
        };
        const collections = {
          0: 'GSP',
          1: 'Cash-Incentive',
          2: 'Processing',
          3: 'Duty-Exemption',
          4: 'Membership',
          5: 'Other',
        };
        wsData.push({
          data: [...Array(24)].map(() => {
            count += 1;
            let row = {};
            const formattedData = [
              ...rawData,
              {
                title: 'Membership',
                saleDetails: printData?.membershipTransaction?.details?.membershipPayments,
              },
              {
                title: 'Other',
                saleDetails: printData?.membershipTransaction?.details?.otherPayments,
              },
            ];
            const subTotalOption = formattedData.find(
              (item) => item.title === collections[(count - 1) / 4],
            );
            const collectedOptions = formattedData.find(
              (item) => item.title === collections[Math.floor((count - 1) / 4)],
            );
            row['category'] = categories[count] || '';
            row['service'] = services[count] || '';
            row['paymentMethod'] = paymentMethods[count % 4] || '';
            row['collected'] = collectedOptions
              ? sortDataByPaymentMethod(
                  collectedOptions.saleDetails,
                  'paymentMethod',
                  collectedOptions.title,
                ).sorted[(count - 1) % 4].total
              : 0;
            row['subTotal'] = subTotalOption
              ? sortDataByPaymentMethod(
                  subTotalOption.saleDetails,
                  'paymentMethod',
                  subTotalOption.title,
                ).grandTotal
              : '';

            return row;
          }),
        });
      } else {
        rawData?.forEach((obj) => {
          if (item?.title === obj?.title) {
            item?.type === 'ISSUE'
              ? wsData.push({
                  data: obj?.issueDetails?.map((item) => ({
                    millName: item?.millInfo?.millName,
                    batchId: item?.batchId,
                    certificateNum: item?.certificateNum,
                  })),
                })
              : obj.title === 'Duty-Exemption'
              ? wsData.push({
                  data: obj?.saleDetails?.map((item) => ({
                    payDay: item?.payDay,
                    millName: item?.millInfo?.millName,
                    serial: item?.certificateInfo?.certificateNum,
                    total: item?.total,
                    discount: item?.discount ? item?.discount : 0,
                    adjustments: item?.adjustments ? item?.adjustments : 0,
                    dues: item?.dues ? item?.dues : 0,
                    moneyReceiptNo: item?.paymentHistory[0]?.uid,
                    paymentMethod: item?.paymentHistory[0]?.paymentMethod,
                    cheqDate: item?.paymentHistory[0]?.chequeDated
                      ? renderDate(item?.paymentHistory[0]?.chequeDated)
                      : null,
                    chequeNo: item?.paymentHistory[0]?.chequeNo,
                    paidAmount: item?.paymentHistory[0]?.paidAmount,
                  })),
                })
              : obj.title === 'Extra Collection (Duty)'
              ? wsData.push({
                  data: obj?.saleDetails?.map((item) => ({
                    createdAt: item?.createdAt ? formatDateSimple(item?.createdAt) : 'N/A',
                    millName: item?.millInfo?.millName,
                    moneyRecieptNo: item?.moneyRecieptNo,
                    paidAmount: item?.purchaseInfo?.paidAmount || 'N/A',
                    paymentMethod: item?.purchaseInfo?.paymentMethod || 'N/A',
                    chequeNo: item?.purchaseInfo?.chequeNo || 'N/A',
                    amount: item?.amount,
                  })),
                })
              : obj.title === 'New Market Expansion'
              ? wsData.push({
                  data: obj?.saleDetails?.map((item) => ({
                    millName: item?.millInfo?.millName,
                    uid: item?.uid,
                    paymentMethod: item?.paymentMethod || 'N/A',
                    transactionId: item?.chequeNo || null,
                    paymentDate: item?.chequeDated ? renderDate(item?.chequeDated) : null,
                    noOfCopies: item?.noOfCopies,
                    unitCost: item?.unitCost,
                    total: item?.total,
                  })),
                })
              : wsData.push({
                  data: obj?.saleDetails?.map((item) => {
                    return {
                      millName: item?.millInfo?.millName,
                      uid: item?.uid,
                      paymentMethod: item?.paymentMethod || 'N/A',
                      transactionId: item?.chequeNo || null,
                      paymentDate: item?.chequeDated ? renderDate(item?.chequeDated) : null,
                      range: `${item?.startRange || 'N/A'}${
                        item?.endRange ? ` - ${item?.endRange}` : ''
                      }`,
                      noOfCopies: item?.noOfCopies,
                      unitCost: item?.unitCost,
                      total: item?.total,
                    };
                  }),
                });
          }
        });
      }
    });

    return wsData;
  };

  const prepareSheetWiseDataForLog = () => {
    let wsData = [];
    let rawData = printData?.result;
    let workSheets = SETTINGS_FOR_EXPORT_LOGS.workSheets;

    workSheets?.forEach((item) => {
      rawData?.forEach((obj) => {
        if (item?.title === obj?.title) {
          item?.type === 'ISSUE'
            ? wsData.push({
                data: obj?.issueDetails?.map((item) => ({
                  fullName: item?.fullName,
                  adminRole: item?.adminRole,
                  count: item?.count,
                })),
              })
            : wsData.push({
                data: obj?.saleDetails?.map((item) => ({
                  fullName: item?.fullName,
                  adminRole: item?.adminRole,
                  count: item?.count,
                  subtotal: item?.subtotal,
                })),
              });
        }
      });
    });

    return wsData;
  };

  const handleExcelExport = () => {
    new ExcelExport().downloadExcel(SETTINGS_FOR_EXPORT, prepareSheetWiseData());
  };

  const handleExcelExportForLogs = () => {
    new ExcelExport().downloadExcel(SETTINGS_FOR_EXPORT_LOGS, prepareSheetWiseDataForLog());
  };

  const renderModal = () => {
    switch (modal) {
      case 'EOD_FORM':
        return (
          <DialogueBox
            close={() => {
              onClose();
              setEndDate();
              setStartDate();
            }}
            mountControl={isOpen}
            alertTitle="DATE RANGE"
            actionButtonText="Submit"
            cancelButtonText="Cancel"
            performAction={handlePrint}
            actionDisabled={!dateRangeValidation}>
            <Box>
              <Text fontSize="lg" color="textSecondary">
                Select Date Range
              </Text>
              <Box py={2}>
                <DateRangePicker
                  data={handleDateRange}
                  error={setDateErrorMsg}
                  clearSelection={clearDateSelect}
                  validation={setDateRangeValidation}
                  cleared={() => setClearDateSelect(false)}
                />
                <Text fontSize="xs" color="invalid">
                  {dateErrorMsg}
                </Text>
              </Box>
            </Box>
          </DialogueBox>
        );
      case 'EOD_SELECTION':
        return (
          <DialogueBox
            variant="lg"
            close={() => {
              onClose();
              setIssueCheckedItems([]);
              setSalesCheckedItems([]);
              setMembershipTrx(false);
              setPaymentSum(false);
              setTimeout(() => {
                setSelections({
                  issueCheckedItems: [],
                  salesCheckedItems: [],
                  membershipTrx: false,
                  paymentSum: false,
                });
              }, 1000);
            }}
            alertTitle="Daily Statements"
            mountControl={isOpen}
            actionButtonText="Print"
            cancelButtonText="Cancel"
            performAction={printStatement}
            actionDisabled={
              !paymentSum &&
              !membershipTrx &&
              issueCheckedItems?.length === 0 &&
              salesCheckedItems?.length === 0
            }>
            <Box>
              <HStack w="100%" justifyContent="space-between" alignItems="flex-end">
                <Text fontSize="lg" color="textSecondary">
                  Choose Statement Sections
                </Text>
                <Button
                  minW="120px"
                  bg="#C1ECD2"
                  height="40px"
                  color="#276749"
                  borderRadius="0px"
                  onClick={handleExcelExport}
                  _focus={{ boxShadow: 'none' }}
                  rightIcon={<Icon as={TiExport} w={5} h={5} />}
                  isDisabled={
                    !paymentSum &&
                    !membershipTrx &&
                    issueCheckedItems?.length === 0 &&
                    salesCheckedItems?.length === 0
                  }
                  _hover={{
                    borderBottom: '3px solid #276749',
                  }}>
                  Export as .xlsx
                </Button>
              </HStack>
              <Box mt={4}>
                <Checkbox isChecked={isAllChecked} onChange={handleSelectAll}>
                  Select All
                </Checkbox>
              </Box>
              <Box bg="secondary" mt={2} p={4} borderRadius="4px">
                <HStack spacing={8} alignItems="top">
                  <Box>
                    <Checkbox
                      isIndeterminate={isIssueIndeterminate}
                      isChecked={issueCheckedItems.length === 4}
                      onChange={() => {
                        handleIssueCheck('ALL');
                      }}>
                      Certificate Issuance Summary
                    </Checkbox>
                    <Stack pl={6} mt={1} spacing={1}>
                      {printData?.result
                        ?.filter((item) => item.title !== 'Duty-Exemption (Application Fee)')
                        ?.map((item, index) => (
                          <Checkbox
                            key={`issue${index}`}
                            isChecked={issueCheckedItems.includes(item.title)}
                            onChange={() => {
                              handleIssueCheck(item.title);
                            }}>
                            {item.title}
                          </Checkbox>
                        ))}
                    </Stack>
                  </Box>

                  <Box>
                    <Checkbox
                      isIndeterminate={isSalesIndeterminate}
                      isChecked={salesCheckedItems.length === 5}
                      onChange={() => {
                        handleSalesCheck('ALL');
                      }}>
                      Certificate Sales Summary
                    </Checkbox>
                    <Stack pl={6} mt={1} spacing={1}>
                      {printData?.result?.map((item, index) => (
                        <Checkbox
                          key={`sales${index}`}
                          isChecked={salesCheckedItems.includes(item.title)}
                          onChange={() => {
                            handleSalesCheck(item.title);
                          }}>
                          {item.title}
                        </Checkbox>
                      ))}
                    </Stack>
                  </Box>
                </HStack>
                <Divider mt={4} />
                <Checkbox
                  mt={4}
                  isChecked={membershipTrx}
                  onChange={() => {
                    setSelections({ ...selections, membershipTrx: !membershipTrx });
                    setMembershipTrx(!membershipTrx);
                  }}>
                  Membership Transaction Summary
                </Checkbox>
                <Divider mt={4} />
                <Checkbox
                  mt={4}
                  isChecked={paymentSum}
                  onChange={() => {
                    setSelections({ ...selections, paymentSum: !paymentSum });
                    setPaymentSum(!paymentSum);
                  }}>
                  Payment Summary
                </Checkbox>
              </Box>
            </Box>
          </DialogueBox>
        );
      case 'EOD_LOG_FORM':
        return (
          <DialogueBox
            close={() => {
              onClose();
              setEndDate();
              setStartDate();
            }}
            mountControl={isOpen}
            alertTitle="DATE RANGE"
            actionButtonText="Submit"
            cancelButtonText="Cancel"
            performAction={handleLogsPrint}
            actionDisabled={!dateRangeValidation}>
            <Box>
              <Text fontSize="lg" color="textSecondary">
                Select Date Range for logs
              </Text>
              <Box py={2}>
                <DateRangePicker
                  data={handleDateRange}
                  error={setDateErrorMsg}
                  clearSelection={clearDateSelect}
                  validation={setDateRangeValidation}
                  cleared={() => setClearDateSelect(false)}
                />
                <Text fontSize="xs" color="invalid">
                  {dateErrorMsg}
                </Text>
              </Box>
            </Box>
          </DialogueBox>
        );
      case 'EOD_LOG_SELECTION':
        return (
          <DialogueBox
            variant="lg"
            close={() => {
              onClose();
              setIssueCheckedItems([]);
              setSalesCheckedItems([]);
              setTimeout(() => {
                setSelections({
                  issueCheckedItems: [],
                  salesCheckedItems: [],
                });
              }, 1000);
            }}
            alertTitle="Daily Logs"
            mountControl={isOpen}
            actionButtonText="Print"
            cancelButtonText="Cancel"
            performAction={printLogStatement}
            actionDisabled={issueCheckedItems?.length === 0 && salesCheckedItems?.length === 0}>
            <Box>
              <HStack w="100%" justifyContent="space-between" alignItems="flex-end">
                <Text fontSize="lg" color="textSecondary">
                  Choose Statement Sections
                </Text>
                <Button
                  minW="120px"
                  bg="#C1ECD2"
                  height="40px"
                  color="#276749"
                  borderRadius="0px"
                  onClick={handleExcelExportForLogs}
                  _focus={{ boxShadow: 'none' }}
                  rightIcon={<Icon as={TiExport} w={5} h={5} />}
                  isDisabled={issueCheckedItems?.length === 0 && salesCheckedItems?.length === 0}
                  _hover={{
                    borderBottom: '3px solid #276749',
                  }}>
                  Export as .xlsx
                </Button>
              </HStack>
              <Box mt={4}>
                <Checkbox isChecked={isAllChecked} onChange={handleSelectAll}>
                  Select All
                </Checkbox>
              </Box>
              <Box bg="secondary" mt={2} p={4} borderRadius="4px">
                <HStack spacing={8} alignItems="top">
                  <Box>
                    <Checkbox
                      isIndeterminate={isIssueIndeterminate}
                      isChecked={issueCheckedItems.length === 4}
                      onChange={() => {
                        handleIssueCheck('ALL');
                      }}>
                      Certificate Issuance Summary
                    </Checkbox>
                    <Stack pl={6} mt={1} spacing={1}>
                      {printData?.result
                        ?.filter((item) => item.title !== 'Duty-Exemption (Application Fee)')
                        ?.map((item, index) => (
                          <Checkbox
                            key={`issue${index}`}
                            isChecked={issueCheckedItems.includes(item.title)}
                            onChange={() => {
                              handleIssueCheck(item.title);
                            }}>
                            {item.title}
                          </Checkbox>
                        ))}
                    </Stack>
                  </Box>

                  <Box>
                    <Checkbox
                      isIndeterminate={isSalesIndeterminate}
                      isChecked={salesCheckedItems.length === 5}
                      onChange={() => {
                        handleSalesCheck('ALL');
                      }}>
                      Certificate Sales Summary
                    </Checkbox>
                    <Stack pl={6} mt={1} spacing={1}>
                      {printData?.result?.map((item, index) => (
                        <Checkbox
                          key={`sales${index}`}
                          isChecked={salesCheckedItems.includes(item.title)}
                          onChange={() => {
                            handleSalesCheck(item.title);
                          }}>
                          {item.title}
                        </Checkbox>
                      ))}
                    </Stack>
                  </Box>
                </HStack>
              </Box>
            </Box>
          </DialogueBox>
        );
    }
  };

  return data ? (
    <Grid templateColumns="repeat(2,1fr)" gap={6}>
      <GridItem colSpan={2}>
        <HStack w="100%" justifyContent="space-between" alignItems="flex-start">
          <Box>
            <Text fontSize="3xl" lineHeight="1" fontWeight="extrabold">
              Hello {data?.user?.fullName}{' '}
              {
                <Icon
                  w={8}
                  h={8}
                  pb={1}
                  color="#276749"
                  as={renderUserIcon(data?.user?.adminRole)}
                />
              }
            </Text>
            <Text fontSize="md" lineHeight="1" color="textSecondary">
              Welcome to BTMA-admins dashboard!
            </Text>
          </Box>
          <Flex style={{ gap: '5px' }}>
            <FormButton
              colorVariant="formRed"
              isLoading={loader}
              onClick={handleDailyLogs}
              rightIcon={<Icon as={RiDraftFill} w="6" h="6" />}>
              Daily Logs
            </FormButton>
            <FormButton
              isLoading={loader}
              onClick={handleDailyStat}
              rightIcon={<Icon as={RiDatabaseFill} w="6" h="6" />}>
              Daily Statements
            </FormButton>
          </Flex>
          <Box display="none">
            <EodStatement
              ref={eodStatementRef}
              date={printData?.date}
              selections={selections}
              data={printData?.result}
              memTrxData={{
                details: printData?.membershipTransaction?.details?.membershipPayments || [],
              }}
              otherTrxData={{
                details: printData?.membershipTransaction?.details?.otherPayments || [],
              }}
            />
            <EodLogs
              ref={eodLogRef}
              date={printData?.date}
              selections={selections}
              data={printData?.result}
              memTrxData={printData?.membershipTransaction}
            />
          </Box>
        </HStack>
      </GridItem>

      {data?.user?.adminRole !== 'FEE_COLLECTOR' && (
        <GridItem
          p={4}
          bg="white"
          colSpan={2}
          borderRadius="md"
          boxShadow="2px 2px 5px rgba(0,0,0,0.2)">
          <Text color="primary.700" textTransform="uppercase" fontSize="xl" mb={4}>
            <Icon as={HiOutlineUserGroup} w={8} h={8} pb={1} mr={1} />
            Membership Statistics
          </Text>
          <Grid templateColumns="repeat(4,1fr)" gap={4}>
            {membershipStatsFields?.map((item, index) => (
              <GridItem
                key={index}
                colSpan={1}
                cursor="pointer"
                onClick={() => history.push(item.link)}>
                <VStack
                  minH="180px"
                  borderRadius="md"
                  overflow="hidden"
                  alignItems="flex-start"
                  bg={item?.primaryColor}
                  justifyContent="space-between"
                  boxShadow="2px 2px 5px rgba(0,0,0,0.2)">
                  <Text
                    pt={3}
                    px={3}
                    fontSize="md"
                    fontWeight="bold"
                    color="textSecondary"
                    textTransform="uppercase">
                    {item?.caption}
                  </Text>
                  <HStack w="100%" justifyContent="space-around">
                    <Text fontSize="4xl" fontWeight="bold" color={item?.accentColor}>
                      {item?.info}
                    </Text>
                    <img src={item?.graphic} style={{ width: '50px', height: '60px' }} />
                  </HStack>
                  <HStack w="100%" bg={item?.accentColor} justifyContent="center" p={2}>
                    <Text fontSize="sm" fontWeight="bold" color="white">
                      {item?.label}
                    </Text>
                  </HStack>
                </VStack>
              </GridItem>
            ))}
          </Grid>
          <Text color="textSecondary" textTransform="uppercase" mt={4} w="100%" textAlign="right">
            Total Members (As of {renderDate(new Date())}): <b>{getTotalMill()}</b>
          </Text>
        </GridItem>
      )}

      {data?.user?.adminRole !== 'FEE_COLLECTOR' && (
        <GridItem
          p={4}
          bg="white"
          colSpan={1}
          borderRadius="md"
          boxShadow="2px 2px 5px rgba(0,0,0,0.2)">
          <Text color="primary.700" textTransform="uppercase" fontSize="xl">
            <Icon as={RiFileShield2Line} w={8} h={8} pb={1} mr={1} />
            Certificates Issued
          </Text>
          <Text color="textSecondary" mb={4}>
            (for {renderMonth()})
          </Text>
          <VStack
            w="100%"
            h="220px"
            bg="secondary"
            justifyContent="space-evenly"
            position="relative">
            <Divider />
            <Divider />
            <Divider />
            <Divider />
            <Divider />
            <HStack w="100%" h="100%" position="absolute" top="0px" left="0px">
              {certIssuanceStatsFields?.map((item, index) => (
                <VStack h="100%" w="25%" key={index} justifyContent="end">
                  <Box
                    w="25%"
                    h={item?.height}
                    bg={item?.accentColor}
                    borderRadius="5px 5px 0px 0px"></Box>
                  <Tooltip
                    placement="top-start"
                    area-label="A Tooltip"
                    label={`${item?.caption}-${item?.info}`}>
                    <Box
                      p={1}
                      w="100%"
                      overflow="hidden"
                      bg={item?.primaryColor}
                      justifyContent="center">
                      <Text
                        fontSize="sm"
                        textAlign="center"
                        whiteSpace="nowrap"
                        color={item?.accentColor}
                        id={`${item?.caption}-issued`}>
                        {item?.caption} (<b>{item?.info}</b>)
                      </Text>
                    </Box>
                  </Tooltip>
                </VStack>
              ))}
            </HStack>
          </VStack>
          <Text color="textSecondary" textTransform="uppercase" mt={4} w="100%" textAlign="right">
            Total: <b>{getTotalCertIssue()}</b>
          </Text>
        </GridItem>
      )}

      {data?.user?.adminRole !== 'FEE_COLLECTOR' && (
        <GridItem
          colSpan={1}
          maxH="428.55px"
          p={4}
          bg="white"
          borderRadius="md"
          boxShadow="2px 2px 5px rgba(0,0,0,0.2)">
          <Text color="primary.700" textTransform="uppercase" fontSize="xl" mb={4}>
            <Icon as={RiFileWarningLine} w={8} h={8} pb={1} mr={1} />
            Certificates Requests
          </Text>
          <VStack w="100%">
            {certReqStatsFields?.map((item, index) => (
              <HStack
                key={index}
                w="100%"
                p={2}
                justifyContent="space-between"
                bg={item?.primaryColor}
                borderBottom={`4px solid ${item?.accentColor}`}
                cursor="pointer"
                onClick={() => history.push(item.link)}>
                <Text
                  fontSize="md"
                  fontWeight="bold"
                  color={item?.accentColor}
                  textTransform="uppercase">
                  {item?.caption}
                </Text>
                <Text fontSize="3xl" fontWeight="bold" color={item?.accentColor}>
                  {item?.info}
                </Text>
              </HStack>
            ))}
          </VStack>
          <Text color="textSecondary" textTransform="uppercase" mt={4} w="100%" textAlign="right">
            Total: <b>{getTotalCertReq()}</b>
          </Text>
        </GridItem>
      )}

      {data?.user?.adminRole === 'FEE_COLLECTOR' && (
        <Grid>
          <Grid templateColumns="repeat(3, 1fr)" gap={10}>
            <Box cursor="pointer" onClick={() => history.push(routerUrl.FEE_COLLECTION_URL)}>
              <VStack
                minH="180px"
                minW="25vw"
                borderRadius="md"
                overflow="hidden"
                alignItems="flex-start"
                bg={'#C1ECD2'}
                justifyContent="space-between"
                boxShadow="2px 2px 5px rgba(0,0,0,0.2)">
                <Text
                  pt={3}
                  px={3}
                  fontSize="md"
                  fontWeight="bold"
                  color="#276749"
                  textTransform="uppercase">
                  Collect Fees
                </Text>
                <HStack w="100%" justifyContent="space-around">
                  <Text fontSize="lg" fontWeight="bold" color={'#276749'} maxW="60%">
                    Collect Application Fees
                  </Text>

                  <RiMoneyDollarCircleFill
                    style={{ width: '65px', height: '70px', color: '#276749' }}
                  />
                </HStack>
                <HStack w="100%" bg={'#276749'} justifyContent="center" p={2}>
                  <Text fontSize="sm" fontWeight="bold" color="white">
                    Proceed To Collect Fees
                  </Text>
                </HStack>
              </VStack>
            </Box>
            <Box
              cursor="pointer"
              onClick={() => history.push(routerUrl.FEE_COLLECTION_VIEW_RECEIPT)}>
              <VStack
                minH="180px"
                minW="25vw"
                borderRadius="md"
                overflow="hidden"
                alignItems="flex-start"
                bg={'#CFCCE6'}
                justifyContent="space-between"
                boxShadow="2px 2px 5px rgba(0,0,0,0.2)">
                <Text
                  pt={3}
                  px={3}
                  fontSize="md"
                  fontWeight="bold"
                  color="#150381"
                  textTransform="uppercase">
                  View Receipts
                </Text>
                <HStack w="100%" justifyContent="space-around">
                  <Text fontSize="lg" fontWeight="bold" color={'#150381'} maxW="60%">
                    Duty Exepmtion - Money Receipts
                  </Text>

                  <FaReceipt style={{ width: '65px', height: '70px', color: '#150381' }} />
                </HStack>
                <HStack w="100%" bg={'#150381'} justifyContent="center" p={2}>
                  <Text fontSize="sm" fontWeight="bold" color="white">
                    Proceed To View Receipts
                  </Text>
                </HStack>
              </VStack>
            </Box>

            <Box
              cursor="pointer"
              onClick={() => history.push(routerUrl.FEE_COLLECTION_MILL_REPRESENTATIVE_URL)}>
              <VStack
                minH="180px"
                minW="25vw"
                borderRadius="md"
                overflow="hidden"
                alignItems="flex-start"
                bg={'#ECDBD9'}
                justifyContent="space-between"
                boxShadow="2px 2px 5px rgba(0,0,0,0.2)">
                <Text
                  pt={3}
                  px={3}
                  fontSize="md"
                  fontWeight="bold"
                  color="#9B4236"
                  textTransform="uppercase">
                  View Mill Representatives
                </Text>
                <HStack w="100%" justifyContent="space-around">
                  <Text fontSize="lg" fontWeight="bold" color={'#9B4236'} maxW="60%">
                    Mill Representatives
                  </Text>

                  <FaReceipt style={{ width: '65px', height: '70px', color: '#9B4236' }} />
                </HStack>
                <HStack w="100%" bg={'#9B4236'} justifyContent="center" p={2}>
                  <Text fontSize="sm" fontWeight="bold" color="white">
                    Proceed To View Mill Representatives
                  </Text>
                </HStack>
              </VStack>
            </Box>
          </Grid>

          <VStack alignItems="flex-start">
            <Text py={4} fontSize="lg" fontWeight="bold">
              New Market Expansion Certificate Section
            </Text>
            <Grid templateColumns="repeat(3, 1fr)" gap={10}>
              <Box
                cursor="pointer"
                onClick={() =>
                  history.push(routerUrl.NEW_MARKET_EXPANSION_CERT_URL_FOR_FEE_COLLECTOR)
                }>
                <VStack
                  minH="180px"
                  minW="25vw"
                  borderRadius="md"
                  overflow="hidden"
                  alignItems="flex-start"
                  bg={'#f1ee8e'}
                  justifyContent="space-between"
                  boxShadow="2px 2px 5px rgba(0,0,0,0.2)">
                  <Text
                    pt={3}
                    px={3}
                    fontSize="md"
                    fontWeight="bold"
                    color="#e47200"
                    textTransform="uppercase">
                    Assign certificate
                  </Text>
                  <HStack w="100%" justifyContent="space-around">
                    <Text fontSize="lg" fontWeight="bold" color={'#e47200'} maxW="60%">
                      New Market Expansion - Assign
                    </Text>

                    <FaReceipt style={{ width: '65px', height: '70px', color: '#e47200' }} />
                  </HStack>
                  <HStack w="100%" bg={'#e47200'} justifyContent="center" p={2}>
                    <Text fontSize="sm" fontWeight="bold" color="white">
                      Proceed To Assign Certificates
                    </Text>
                  </HStack>
                </VStack>
              </Box>
              <Box
                cursor="pointer"
                onClick={() =>
                  history.push(routerUrl.FEE_COLLECTION_VIEW_RECEIPT_FOR_NEW_MARKET_EXPANSION)
                }>
                <VStack
                  minH="180px"
                  minW="25vw"
                  borderRadius="md"
                  overflow="hidden"
                  alignItems="flex-start"
                  bg={'#ff9c8f'}
                  justifyContent="space-between"
                  boxShadow="2px 2px 5px rgba(0,0,0,0.2)">
                  <Text
                    pt={3}
                    px={3}
                    fontSize="md"
                    fontWeight="bold"
                    color="#9b4236"
                    textTransform="uppercase">
                    View Receipts
                  </Text>
                  <HStack w="100%" justifyContent="space-around">
                    <Text fontSize="lg" fontWeight="bold" color={'#9b4236'} maxW="60%">
                      New Market Expansion - Money Receipts
                    </Text>

                    <FaReceipt style={{ width: '65px', height: '70px', color: '#9b4236' }} />
                  </HStack>
                  <HStack w="100%" bg={'#9b4236'} justifyContent="center" p={2}>
                    <Text fontSize="sm" fontWeight="bold" color="white">
                      Proceed To View Receipts
                    </Text>
                  </HStack>
                </VStack>
              </Box>
            </Grid>
          </VStack>
        </Grid>
      )}
      {renderModal()}
    </Grid>
  ) : (
    <Loader />
  );
};

export default memo(Statistics);
