import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Grid, GridItem, VStack, useDisclosure } from '@chakra-ui/react';
import { useYupValidationResolver } from '../../../util/yupUtils';
import MembershipFormWrapper from '../../common/membershipFormComponents/MembershipFormWrapper';
import FactorsASection, { factorsAfieldsValidation } from './FactorsASection';
import FormButton from '../../common/FormButton';
import FactorsBSection, { factorsBfieldsValidation } from './FactorsBSection';
import FactorsCSection, { factorsCfieldsValidation } from './FactorsCSection';
import DutyCertSection from './DutyCertSection';
import DialogueBox from '../../common/DialogueBox';
import { updateAllPricing } from '../../../api/accounts';
import { useDispatch } from 'react-redux';
import { notify } from '../../../store/actions/globalActions';
import _ from 'lodash';

const PricingForm = ({ defaultFields, refresh }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const validationSchema = yup.object().shape({
    ...factorsAfieldsValidation,
    ...factorsBfieldsValidation,
    ...factorsCfieldsValidation,
  });

  const resolver = useYupValidationResolver(validationSchema);
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: defaultFields,
  });

  const watchAllFields = watch();

  const onSubmit = (data) => {
    const {
      rotorThreshold,
      unitPriceRotorX,
      unitPriceRotorY,
      spindleThreshold,
      unitPriceSpindleX,
      unitPriceSpindleY,
      yarnProdThreshold,
      unitPriceYarnProdX,
      unitPriceYarnProdY,
      unitFactorYarnProd,
      knitThreshold,
      unitFactorKnit,
      unitPriceKnitX,
      unitPriceKnitY,
      shuttleThreshold,
      unitPriceShuttleX,
      unitPriceShuttleY,
      shuttleLessThreshold,
      unitPriceShuttleLessX,
      unitPriceShuttleLessY,
      yarnThreshold,
      tKnitThreshold,
      unitFactorYarn,
      unitPriceYarnX,
      unitPriceYarnY,
      wovenThreshold,
      unitFactorTKnit,
      unitFactorWoven,
      unitPriceTKnitX,
      unitPriceTKnitY,
      unitPriceWovenX,
      unitPriceWovenY,
      dutyAX,
      dutyAY,
      dutyBX,
      dutyBY,
      dutyCX,
      dutyCY,
      dutyAFactor,
      dutyBFactor,
      dutyCFactor,
      dutyAThreshold,
      dutyBThreshold,
      dutyCThreshold,
    } = data;
    const factorsA = {
      rotorThreshold,
      unitPriceRotorX,
      unitPriceRotorY,
      spindleThreshold,
      unitPriceSpindleX,
      unitPriceSpindleY,
      yarnProdThreshold,
      unitPriceYarnProdX,
      unitPriceYarnProdY,
      unitFactorYarnProd,
    };
    const factorsB = {
      knitThreshold,
      unitFactorKnit,
      unitPriceKnitX,
      unitPriceKnitY,
      shuttleThreshold,
      unitPriceShuttleX,
      unitPriceShuttleY,
      shuttleLessThreshold,
      unitPriceShuttleLessX,
      unitPriceShuttleLessY,
    };
    const factorsC = {
      yarnThreshold,
      tKnitThreshold,
      unitFactorYarn,
      unitPriceYarnX,
      unitPriceYarnY,
      wovenThreshold,
      unitFactorTKnit,
      unitFactorWoven,
      unitPriceTKnitX,
      unitPriceTKnitY,
      unitPriceWovenX,
      unitPriceWovenY,
    };
    const factorsCertificate = {
      dutyAX,
      dutyAY,
      dutyBX,
      dutyBY,
      dutyCX,
      dutyCY,
      dutyAFactor,
      dutyBFactor,
      dutyCFactor,
      dutyAThreshold,
      dutyBThreshold,
      dutyCThreshold,
      processing: 100,
      production: 400,
    };
    setFormData({ factorsA, factorsB, factorsC, factorsCertificate });
    onOpen();
  };

  const confirmUpdate = async () => {
    const res = await updateAllPricing(formData);
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 1500,
        }),
      );
      refresh();
    }
  };

  return (
    <MembershipFormWrapper formName="Current Pricing">
      <Grid templateColumns="repeat(1,1fr)" gap={3}>
        <GridItem p={3} bg="white" borderRadius="4px">
          <FactorsASection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem p={3} bg="white" borderRadius="4px">
          <FactorsBSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem p={3} bg="white" borderRadius="4px">
          <FactorsCSection register={register} control={control} errors={errors} />
        </GridItem>
        <GridItem p={3} bg="white" borderRadius="4px">
          <DutyCertSection register={register} control={control} errors={errors} />
        </GridItem>
      </Grid>
      <VStack alignItems="flex-end">
        <FormButton
          onClick={() => handleSubmit(onSubmit)()}
          isLoading={isSubmitting}
          isDisabled={_.isEqual(defaultFields, watchAllFields)}>
          UPDATE
        </FormButton>
      </VStack>
      <DialogueBox
        mountControl={isOpen}
        alertTitle="Confirm"
        alertMessage="Are you sure you want to update current pricing?"
        close={onClose}
        performAction={confirmUpdate}
      />
    </MembershipFormWrapper>
  );
};

export default PricingForm;
