import React, { useEffect } from 'react';
import Steps from './Steps';
import { useHistory } from 'react-router';
import { ACTIVE_MEMBERS, NEW_REQUEST_URL } from '../../../../constants/routerUrl';

const StepsView = ({ infoView }) => {
  const history = useHistory();

  useEffect(() => {
    document.title = 'BTMA - Membership';
  }, []);

  const backToTable = () => {
    infoView ? history.push(ACTIVE_MEMBERS) : history.push(NEW_REQUEST_URL);
  };

  return <Steps backToTable={backToTable} infoView={infoView} />;
};

export default StepsView;
