import React from 'react';
import * as yup from 'yup';
import FormSection from '../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 4,
    name: 'spindleThreshold',
    type: 'text',
    label: 'Spindle Threshold',
    isRequired: true,
  },
  {
    colSpan: 4,
    name: 'unitPriceSpindleX',
    type: 'text',
    label: 'Spindle Cost (below threshold)',
    isRequired: true,
  },
  {
    colSpan: 4,
    name: 'unitPriceSpindleY',
    type: 'text',
    label: 'Spindle Cost (above threshold)',
    isRequired: true,
  },
  {
    colSpan: 4,
    name: 'rotorThreshold',
    type: 'text',
    label: 'Rotor Threshold',
    isRequired: true,
  },
  {
    colSpan: 4,
    name: 'unitPriceRotorX',
    type: 'text',
    label: 'Rotor Cost (below threshold)',
    isRequired: true,
  },
  {
    colSpan: 4,
    name: 'unitPriceRotorY',
    type: 'text',
    label: 'Rotor Cost (above threshold)',
    isRequired: true,
  },
  {
    colSpan: 3,
    name: 'yarnProdThreshold',
    type: 'text',
    label: 'Production Capacity Threshold',
    isRequired: true,
  },
  {
    colSpan: 3,
    name: 'unitPriceYarnProdX',
    type: 'text',
    label: 'Production Capacity Cost (below threshold)',
    isRequired: true,
  },
  {
    colSpan: 3,
    name: 'unitPriceYarnProdY',
    type: 'text',
    label: 'Production Capacity Cost (above threshold)',
    isRequired: true,
  },
  {
    colSpan: 3,
    name: 'unitFactorYarnProd',
    type: 'text',
    label: 'Production Capacity Factor (working days)',
    isRequired: true,
  },
];

export const factorsAfieldsValidation = {
  [fields[0].name]: yup.number().required().label(fields[0].label),
  [fields[1].name]: yup.number().required().label(fields[1].label),
  [fields[2].name]: yup.number().required().label(fields[2].label),
  [fields[3].name]: yup.number().required().label(fields[3].label),
  [fields[4].name]: yup.number().required().label(fields[4].label),
  [fields[5].name]: yup.number().required().label(fields[5].label),
};

const FactorsASection = (props) => {
  return <FormSection sectionName="Category-A" sectionColumn={12} formFields={fields} {...props} />;
};

export default FactorsASection;
