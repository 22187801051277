import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { renderDate } from '../../../util/formatUtils';

const PrintPreview = React.forwardRef(({ data }, ref) => (
  <Box p={6} ref={ref}>
    <Text fontSize="lg" fontWeight="bold" textAlign="center">
      Membership Information Update Request
    </Text>
    <Box mt={6}>
      <Text>
        <b>Request Id</b>: {data?.requestId}
      </Text>
      <Text>
        <b>Mill Name</b>: {data?.millName}
      </Text>
      <Text>
        <b>Membership No</b>: {data?.membershipNo}
      </Text>
      <Text>
        <b>Email</b>: {data?.millEmail}
      </Text>
      <Text>
        <b>Contact</b>: {data?.millPhoneNo}
      </Text>
      <Text fontSize="sm">
        <b>Date</b>: {renderDate(data?.createdAt)}
      </Text>
    </Box>

    <Text mt={6} textAlign="justify">
      {data?.message}
    </Text>
  </Box>
));

PrintPreview.displayName = 'PrintPreview';

export default PrintPreview;
