import React from 'react';
import { dateValidation } from '../../../../../util/yupUtils';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'expectedDate',
    label: 'Expected Date',
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'actualDate',
    label: 'Actual Date',
  },
];

export const dateOfCommencementFieldsValidation = {
  [fields[0].name]: dateValidation.label(fields[0].label),
  [fields[1].name]: dateValidation.label(fields[1].label),
};

const DateOfCommencementProductionSection = (props) => {
  return (
    <FormSection
      sectionName="Date of Commencement of Production"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default DateOfCommencementProductionSection;
