import React from 'react';
import * as yup from 'yup';
import FieldArrayFormSection from '../../../../common/hookFormComponents/FieldArrayFormSection';

const fields = [
  {
    colSpan: 1,
    name: 'typeOfYarn',
    type: 'text',
    label: 'Type of Yarn',
  },
  {
    colSpan: 1,
    name: 'yarnCount',
    type: 'text',
    label: 'Yarn Counts',
  },
];

export const defaultYarnTypeAndCountFieldValues = fields.reduce(
  (obj, item) => ({ ...obj, [item['name']]: '' }),
  {},
);

export const YarnTypeAndCountFieldsValidation = {
  yarnTypeAndCount: yup.array().of(
    yup.object().shape({
      [fields[0].name]: yup.string(),
      [fields[1].name]: yup.string(),
    }),
  ),
};

const YarnTypeAndCountSection = (props) => {
  return (
    <FieldArrayFormSection
      sectionName="Yarn Type and Count"
      sectionColumn={2}
      name="yarnTypeAndCount"
      formFields={fields}
      {...props}
    />
  );
};

export default YarnTypeAndCountSection;
