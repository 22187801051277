import axios from './axios';

export const login = async (data) => {
  return await axios.post('/auth/sign-in', data);
};

export const verifyLogin = async (data) => {
  return await axios.post(
    `/auth/login-verify/${data?.username}/${data?.phoneNo}/${data?.sessionId}`,
    { otpToken: data?.otpToken },
  );
};

export const logout = async () => {
  return await axios.post('/auth/logout');
};

export const triggerPasswordReset = async (data) => {
  return await axios.post('/auth/password-reset/trigger', data);
};

export const verifyPasswordReset = async (data) => {
  return await axios.post(`/auth/password-reset/verify-otp/${data?.username}/${data?.phoneNo}`, {
    otpToken: data?.otpToken,
  });
};

export const resetPassword = async (data) => {
  return await axios.post(`/auth/password-reset/update/${data?.username}/${data?.phoneNo}`, {
    password: data?.password,
    otpToken: data?.otpToken,
  });
};

export const getUserDashboard = async (data) => {
  try {
    const res = await axios.get('/stats/dashboard', { params: data });
    if (res.status === 200) return { data: res.data, error: null };
    if (res.status === 204) return { data: res, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export const getEodStatements = async (data) => {
  try {
    const res = await axios.get('/stats/eod-statement', { params: data });
    if (res.status === 200) return { data: res.data, error: null };
    if (res.status === 204) return { data: res, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export const getEodLogs = async (data) => {
  try {
    const res = await axios.get('/action-logs/certificate-action-stats', { params: data });
    if (res.status === 200) return { data: res.data, error: null };
    if (res.status === 204) return { data: res, error: null };
  } catch (error) {
    return { data: null, error };
  }
};
