import React from 'react';
import DialogueBox from '../../common/DialogueBox';
import ReworkMessageModal from './Modals/ReworkMessageModal';

const RenderModal = ({
  modal,
  step,
  isOpen,
  onClose,
  backToTable,
  confirmSubmit,
  confirmReject,
  modalComponent,
}) => {
  switch (modal) {
    case 'BackToTableAlert':
      return (
        <DialogueBox
          close={onClose}
          mountControl={isOpen}
          alertTitle="Leave Page"
          performAction={backToTable}
          alertMessage={`Are you sure you want to leave this page? Any unsaved changes will be lost! You can click ${
            step === 0 ? null : '‘PREVIOUS’ or'
          } ‘NEXT’ to save current changes.`}
        />
      );
    case 'SubmitAlert':
      return (
        <DialogueBox
          close={onClose}
          mountControl={isOpen}
          alertTitle="Verify Request"
          performAction={confirmSubmit}
          alertMessage="Are you sure you want to verify this membership request and proceed to payment options?"
        />
      );
    case 'UpdateAlert':
      return (
        <DialogueBox
          close={onClose}
          mountControl={isOpen}
          alertTitle="Confirm Update"
          performAction={confirmSubmit}
          alertMessage="Are you sure you want to update information for this member?"
        />
      );
    case 'NoUpdate':
      return (
        <DialogueBox
          noAction
          close={onClose}
          mountControl={isOpen}
          alertTitle="No Update"
          cancelButtonText="Close"
          alertMessage="There is nothing to update, you did not change any information!"
        />
      );
    case 'ReworkModal':
      return (
        <ReworkMessageModal mountControl={isOpen} close={onClose}>
          {modalComponent}
        </ReworkMessageModal>
      );
    case 'RejectModal':
      return (
        <DialogueBox
          close={onClose}
          mountControl={isOpen}
          alertTitle="Confirm Reject"
          performAction={confirmReject}
          alertMessage="Are you sure you want to reject this membership request?"
        />
      );
    default:
      return null;
  }
};

export default RenderModal;
