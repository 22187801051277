import React from 'react';
import { Flex, Stack, Spinner, Text } from '@chakra-ui/react';

const Loader = ({ internal, title }) => {
  return (
    <Flex
      flex={1}
      w="100%"
      alignSelf="center"
      alignItems="center"
      mt={internal ? 4 : 10}
      flexDirection="column"
      justifyContent="center"
      backgroundColor={internal ? 'transparent' : 'secondary'}>
      <Stack flexDir="column" justifyContent="center" alignItems="center">
        <Spinner
          color="primary.700"
          emptyColor="primary.50"
          size={internal ? 'md' : 'xl'}
          thickness={internal ? '2px' : '4px'}
        />
        <Text color="primary.700" fontSize={internal ? 'md' : 'lg'}>
          {title || 'Loading...'}
        </Text>
      </Stack>
    </Flex>
  );
};

export default Loader;
