import React from 'react';
import { Box, Button, HStack } from '@chakra-ui/react';

const Footer = ({ back, buttonLoading, children }) => {
  return (
    <Box
      px={2}
      h="6.5vh"
      bg="white"
      zIndex={99}
      maxH="80px"
      minH="50px"
      bottom="0px"
      position="sticky"
      id="footerWrapper"
      boxShadow="0px -2px 3px rgba(0,0,0,0.2)">
      <HStack h="100%" justifyContent="end" alignItems="center">
        <Button
          minH="35px"
          maxH="45px"
          height="70%"
          minW="120px"
          bg="#ECDBD9"
          onClick={back}
          color="#9B4236"
          borderRadius="0px"
          isLoading={buttonLoading}
          _focus={{ boxShadow: 'none' }}
          _hover={{ borderBottom: '3px solid #9B4236' }}>
          Quit
        </Button>
        {children}
      </HStack>
    </Box>
  );
};

export default Footer;
