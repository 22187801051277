import React, { useEffect, useRef, useState } from 'react';
import { CgPrinter } from 'react-icons/cg';
import { Box, Icon } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
  BADGE_STATE_COLORS,
  LOGS_TABLE_DISPLAY_SIZE,
  MEMBERSHIP_TYPE_SELECTION,
  MEMBERSHIP_CATEGORY_SELECTION,
  MEMBERSHIP_CATEGORY_TYPE_SELECTION,
} from '../../../../constants/dataTableConstants';
import FormButton from '../../../common/FormButton';
import { useDispatch, useSelector } from 'react-redux';
import { getMembers } from '../../../../api/membership';
import { renderDate } from '../../../../util/formatUtils';
import DataTable from '../../../common/dataTable/DataTable';
import { FETCH_MEMBERS } from '../../../../store/actions/actionTypes';
import {
  ACCOUNTS_MEMBERSHIP_MILL_URL,
  NEW_REQUEST_VERIFICATION_URL,
} from '../../../../constants/routerUrl';
import InactiveMembersReport from './InactiveMembersReport';

const InactiveMembers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const memberListRef = useRef();
  const [printData, setPrintData] = useState();
  const [loading, setLoading] = useState(false);
  const data = useSelector((state) => state.membership.members);

  useEffect(() => {
    document.title = 'BTMA - Membership';
  }, []);

  const printMemberList = useReactToPrint({
    documentTitle: `BTMA-Inactive-Members-${renderDate(new Date())}`,
    content: () => memberListRef.current,
  });

  const columns = [
    {
      header: 'ID',
      accessor: 'membershipNo',
    },
    {
      header: 'Mill Name',
      accessor: 'millName',
    },
    {
      header: 'Type',
      accessor: 'membershipType',
    },
    {
      header: 'Membership Category',
      accessor: 'membershipCategory',
    },
    {
      header: 'Category',
      accessor: 'category',
    },
    {
      header: 'Expiry Date',
      accessor: 'membershipExpiryDate',
      isDate: true,
    },
    {
      header: 'Email',
      accessor: 'millEmail',
    },
    {
      header: 'Contact',
      accessor: 'millPhoneNo',
    },
    {
      header: 'Status',
      accessor: 'membershipStatus',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
    },
    {
      header: 'Transactions',
      accessor: 'membershipStatus',
      isButton: true,
      actionScheme: [
        { group: 'PENDING', variant: 'read', action: 'VIEW' },
        { group: 'APPLIED', variant: 'read', action: 'VIEW' },
        { group: 'IN REVIEW', variant: 'read', action: 'VIEW' },
        { group: 'PAYMENT PENDING', variant: 'read', action: 'VIEW' },
        { group: 'REWORK', variant: 'read', action: 'VIEW' },
        { group: 'REJECTED', variant: 'read', action: 'VIEW' },
      ],
    },
    {
      header: 'Edit',
      accessor: 'membershipNo',
      isButton: true,
      actionScheme: [{ group: null, variant: 'write', action: 'EDIT' }],
    },
  ];

  const handlePagination = async ({ page, pageSize, searchKey, selected }) => {
    dispatch({ type: FETCH_MEMBERS, payload: null });
    const params = {
      page,
      pageSize,
      searchKey,
      type: selected?.['Membership Type'],
      category: selected?.['Mill Type'],
      membershipCategory: selected?.['Membership Category'],
    };
    const res = await getMembers(params, 'INACTIVE');
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_MEMBERS, payload: res.data });
    } else {
      dispatch({ type: FETCH_MEMBERS, payload: { members: [] } });
    }
  };

  const handleSearch = async ({ searchKey, selected }) => {
    dispatch({ type: FETCH_MEMBERS, payload: null });
    const params = {
      searchKey,
      type: selected?.['Membership Type'],
      category: selected?.['Mill Type'],
      membershipCategory: selected?.['Membership Category'],
    };
    const res = await getMembers(params, 'INACTIVE');
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_MEMBERS, payload: res.data });
    } else {
      dispatch({ type: FETCH_MEMBERS, payload: { members: [] } });
    }
  };

  const handleView = ({ rowObject }) => {
    history.push(`${ACCOUNTS_MEMBERSHIP_MILL_URL}/${rowObject?.uid}`);
  };

  const handleEdit = ({ rowObject }) => {
    history.push(`${NEW_REQUEST_VERIFICATION_URL}/${rowObject.uid}`);
  };

  const handlePrint = async () => {
    setLoading(true);
    const params = {};
    const res = await getMembers(params, 'PRINT');
    if (res.data?.status === 200) {
      setLoading(false);
      setPrintData(res.data?.members);
    }
    setLoading(false);
    setTimeout(() => {
      printMemberList();
    }, 500);
  };

  const actionObject = { VIEW: handleView, EDIT: handleEdit };

  const printColumns = columns.filter((item) => item.header !== 'Action');

  return (
    <Box p={4}>
      <FormButton
        mb={4}
        isLoading={loading}
        onClick={handlePrint}
        rightIcon={<Icon as={CgPrinter} w={5} h={5} />}>
        Print Inactive Member List
      </FormButton>
      <Box display="none">
        <InactiveMembersReport data={printData} ref={memberListRef} columns={printColumns} />
      </Box>
      <DataTable
        topPanel
        keySearch
        isPaginated
        columns={columns}
        data={data?.members}
        search={handleSearch}
        actions={actionObject}
        currentPage={data?.page}
        caption="Inactive Members"
        paginate={handlePagination}
        entries={data?.totalEntries}
        currentPageSize={data?.pageSize}
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
        selectOptions={[
          MEMBERSHIP_CATEGORY_TYPE_SELECTION,
          MEMBERSHIP_TYPE_SELECTION,
          MEMBERSHIP_CATEGORY_SELECTION,
        ]}
      />
    </Box>
  );
};

export default InactiveMembers;
