import React, { useEffect, useState } from 'react';
import { getApplicationInfo } from '../../../../api/certificates';
import { useHistory } from 'react-router';
import { renderDate } from '../../../../util/formatUtils';
import Loader from '../../../snippets/Loader';
import Record from './Record';

const NewMarketExpansionApplication = () => {
  const history = useHistory();
  const [millInfo, setMillInfo] = useState();
  const [defaultFields, setDefaultFields] = useState();
  const { applicationId } = history.location.state;

  const handleQuery = async () => {
    const res = await getApplicationInfo({ type: 'MARKET_EXPANSION', id: applicationId });
    if (res.data?.status === 200) {
      const data = res.data.data;

      setMillInfo({
        ...data?.millInfo,
        certId: data?.id,
        certificateNum: data?.certificateNumber,
        issueDate: renderDate(data?.issueDate),
      });

      let fields = {
        //gen fields
        invoiceNumber: data?.invoiceNumber,
        invoiceAmount: data?.invoiceAmount,
        invoiceCurrency: data?.invoiceCurrency,
        invoiceDate: data?.invoiceDate,

        //req fields
        exportItemDetails: data?.exportItemDetails,
        originalExportItemQuantity: data?.originalExportItemQuantity,
        originalExportItemQuantityUnit: data?.originalExportItemQuantityUnit,
        originalExportItemValue: data?.originalExportItemValue,
        originalExportItemValueCurrency: data?.originalExportItemValueCurrency,
        expNumber: data?.expNumber,
        expDate: data?.expDate,
        expValue: data?.expValue,
        totalRepatriatedForeignExportValue: data?.totalRepatriatedForeignExportValue,
        totalRepatriatedForeignExportValueCurrency:
          data?.totalRepatriatedForeignExportValueCurrency,
        repatriatedExportValueCertificateNumber: data?.repatriatedExportValueCertificateNumber,
        repatriatedExportValueCertificateDate: data?.repatriatedExportValueCertificateDate,
        netFovValue: data?.netFovValue,

        //application fields
        issueDate: data?.issueDate,
        certificateNumber: data?.certificateNumber,
        foreignBuyerNameAndAddress: data?.foreignBuyerNameAndAddress,
        salesContactNumber: data?.salesContactNumber,
        salesContactDate: data?.salesContactDate,
        buyerBankNameAndAddress: data?.buyerBankNameAndAddress,
      };

      setDefaultFields(fields);
    }
  };

  useEffect(() => {
    handleQuery();
  }, []);

  return defaultFields ? (
    <Record millData={millInfo} refresh={handleQuery} defaultFields={defaultFields} />
  ) : (
    <Loader />
  );
};

export default NewMarketExpansionApplication;
