import React from 'react';
import * as yup from 'yup';
import FormSection from '../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 4,
    name: 'shuttleThreshold',
    type: 'text',
    label: 'Shuttle Threshold',
    isRequired: true,
  },
  {
    colSpan: 4,
    name: 'unitPriceShuttleX',
    type: 'text',
    label: 'Shuttle Cost (below threshold)',
    isRequired: true,
  },
  {
    colSpan: 4,
    name: 'unitPriceShuttleY',
    type: 'text',
    label: 'Shuttle Cost (above threshold)',
    isRequired: true,
  },
  {
    colSpan: 4,
    name: 'shuttleLessThreshold',
    type: 'text',
    label: 'Shuttle Less Threshold',
    isRequired: true,
  },
  {
    colSpan: 4,
    name: 'unitPriceShuttleLessX',
    type: 'text',
    label: 'Shuttle Less Cost (below threshold)',
    isRequired: true,
  },
  {
    colSpan: 4,
    name: 'unitPriceShuttleLessY',
    type: 'text',
    label: 'Shuttle Less Cost (above threshold)',
    isRequired: true,
  },
  {
    colSpan: 3,
    name: 'knitThreshold',
    type: 'text',
    label: 'Knitting Threshold',
    isRequired: true,
  },
  {
    colSpan: 3,
    name: 'unitPriceKnitX',
    type: 'text',
    label: 'Knitting Cost (below threshold)',
    isRequired: true,
  },
  {
    colSpan: 3,
    name: 'unitPriceKnitY',
    type: 'text',
    label: 'Knitting Cost (above threshold)',
    isRequired: true,
  },
  {
    colSpan: 3,
    name: 'unitFactorKnit',
    type: 'text',
    label: 'Knitting Factor (per ? unit)',
    isRequired: true,
  },
];

export const factorsBfieldsValidation = {
  [fields[0].name]: yup.number().required().label(fields[0].label),
  [fields[1].name]: yup.number().required().label(fields[1].label),
  [fields[2].name]: yup.number().required().label(fields[2].label),
  [fields[3].name]: yup.number().required().label(fields[3].label),
  [fields[4].name]: yup.number().required().label(fields[4].label),
  [fields[5].name]: yup.number().required().label(fields[5].label),
  [fields[6].name]: yup.number().required().label(fields[6].label),
  [fields[7].name]: yup.number().required().label(fields[7].label),
  [fields[8].name]: yup.number().required().label(fields[8].label),
  [fields[9].name]: yup.number().required().label(fields[9].label),
};

const FactorsBSection = (props) => {
  return <FormSection sectionName="Category-B" sectionColumn={12} formFields={fields} {...props} />;
};

export default FactorsBSection;
