import React from 'react';
import * as yup from 'yup';
import FieldArrayFormSection from '../../../../common/hookFormComponents/FieldArrayFormSection';

const fields = [
  {
    colSpan: 1,
    name: 'nameOfMachine',
    type: 'text',
    label: 'Machine name',
  },
  {
    colSpan: 1,
    name: 'numberOfMachines',
    type: 'text',
    label: 'Numbers of Machines',
  },
  {
    colSpan: 1,
    name: 'model',
    type: 'text',
    label: 'Model',
  },
  {
    colSpan: 1,
    name: 'countryOrManufacturer',
    type: 'text',
    label: 'Country/Manufacturer',
  },
];

export const defaultMachineInformationFieldValues = fields.reduce(
  (obj, item) => [{ ...obj, [item['name']]: '' }],
  [{}],
);

export const MachineInformationFieldsValidation = {
  machineInfo: yup.array().of(
    yup.object().shape({
      [fields[0].name]: yup.string(),
      [fields[1].name]: yup.string(),
      [fields[2].name]: yup.string(),
      [fields[3].name]: yup.string(),
    }),
  ),
};

const MachineInformationSection = (props) => {
  return (
    <FieldArrayFormSection
      sectionName="Machine Information"
      name="machineInfo"
      sectionColumn={4}
      formFields={fields}
      {...props}
    />
  );
};

export default MachineInformationSection;
