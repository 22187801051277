import React from 'react';
import * as yup from 'yup';
import FormSection from '../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'note',
    label: 'Note',
    fieldType: 'textArea',
  },
];

export const noteFieldValidation = {
  [fields[0].name]: yup.string().nullable().label(fields[0].label),
};

const NoteSection = (props) => {
  return <FormSection sectionColumn={1} formFields={fields} {...props} />;
};

export default NoteSection;
