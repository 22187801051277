import React, { useEffect, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import NavPanel from '../../../common/NavPanel';
import DutyCertTable from '../../../accounts/certificate/TableView/DutyCertTable';
import { useHistory, useLocation } from 'react-router-dom';
import DutyPurchaseRequestTable from '../TableView/DutyPurchaseRequestTable';

const DutyExemptionTemplate = () => {
  const [selected, setSelected] = useState(0);
  const [scroll, setScroll] = useState(false);
  const navList = ['Application Fees', 'Processing Fees'];

  const history = useHistory();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const dutyTabValue = queryParams.get('dutyTab');

  const handleScroll = () => {
    window.scrollY > 10 ? setScroll(true) : setScroll(false);
  };

  window.addEventListener('scroll', handleScroll);

  useEffect(() => {
    if (!dutyTabValue) {
      setSelected(0);
    } else {
      setSelected(parseInt(dutyTabValue));
    }
  }, [dutyTabValue]);

  const updateTab = (tabState) => {
    history.push({
      search: `?tab=3&dutyTab=${tabState}`,
    });
  };

  const renderContent = () => {
    switch (selected) {
      case 0:
        return <DutyPurchaseRequestTable />;

      case 1:
        return <DutyCertTable />;

      default:
        return <DutyPurchaseRequestTable />;
    }
  };

  return (
    <Box bg="secondary">
      <Box
        p={4}
        right="0px"
        zIndex={98}
        bg="secondary"
        position="sticky"
        shadow={scroll ? 'sm' : 'none'}
        top={`${document?.getElementById('headerWrapper')?.offsetHeight}px`}>
        <Flex justifyContent="flex-end" alignItems="center">
          <NavPanel
            navState={selected}
            setNavState={updateTab}
            navList={navList}
            linkForDuty={true}
          />
        </Flex>
      </Box>
      <Box p={4}>{renderContent()}</Box>
    </Box>
  );
};

export default DutyExemptionTemplate;
