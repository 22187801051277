import React, { useEffect, useState } from 'react';
import Record from './Record';
import { useHistory } from 'react-router';
import Loader from '../../../snippets/Loader';
import { renderDate } from '../../../../util/formatUtils';
import { getConversionRates } from '../../../../api/currency';
import { getApplicationInfo } from '../../../../api/certificates';

const DutyExemptionApplication = () => {
  const history = useHistory();
  const [millInfo, setMillInfo] = useState();
  const [conversions, setConversions] = useState();
  const [defaultDocs, setDefaultDocs] = useState();
  const [defaultFiles, setDefaultFiles] = useState();
  const [defaultFields, setDefaultFields] = useState();
  const { applicationId } = history.location.state;

  const handleQuery = async () => {
    const res = await getApplicationInfo({ type: 'duty', id: applicationId });
    if (res.data?.status === 200) {
      const data = res.data;

      setMillInfo({
        ...data?.millInfo,
        certId: data?.id,
        certificateNum: data?.certificateNum,
        issueDate: renderDate(data?.issueDate),
      });

      let fields = {
        invoice: data?.invoice,
        invoiceDate: data?.invoiceDate,
        masterLc: data?.masterLc,
        dateOfLc: data?.dateOfLc,
        blNo: data?.blNo,
        blDate: data?.blDate,
        category: data?.category,
        itemName: data?.itemName,
        hsCode: data?.hsCode,
        vat: data?.vat,
        duty: data?.duty,
        expiryDate: data?.expiryDate,
        quantity: data?.quantity,
        importDuty: data?.importDuty,
        value: data?.value,
        currency: data?.currency,
        conversionRate: data?.conversionRate,
        asmtVal: data?.asmtVal,
        vat2: data?.vat2,
        total: data?.total,
        remarks: data?.dutyRemarks,
      };
      setDefaultFields(fields);
      setDefaultFiles([
        {
          header: 'Additional Documents',
          link: data?.additionalDocuments,
        },
      ]);
      setDefaultDocs({
        additionalDocuments: data?.additionalDocuments,
      });
    }

    const response = await getConversionRates();
    setConversions(response?.data);
  };

  useEffect(() => {
    handleQuery();
  }, []);

  return defaultFields && defaultFiles && defaultDocs && conversions ? (
    <Record
      millData={millInfo}
      refresh={handleQuery}
      conversions={conversions}
      defaultDocs={defaultDocs}
      defaultFiles={defaultFiles}
      defaultFields={defaultFields}
    />
  ) : (
    <Loader />
  );
};

export default DutyExemptionApplication;
