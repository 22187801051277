import React from 'react';
import commaNumber from 'comma-number';
import { convertNumberToWords } from '../../../util/formatUtils';
import { dutyCertCategories } from '../../../constants/certificateConstant';
import { Box, HStack, Text, Table, Thead, Tbody, Tr, Th, Td, VStack } from '@chakra-ui/react';

const CostBreakdown = ({ data, billFor }) => {
  console.log(data);
  return (
    <Box>
      {billFor ? (
        <Text fontSize="2xl" fontWeight="bold" mb={2} width="100%" textAlign="center">
          {billFor} Bill
        </Text>
      ) : (
        <Text fontSize="md" fontWeight="bold">
          Certificate Cost-Breakdown
        </Text>
      )}

      <Table mt={2}>
        <Thead>
          <Tr>
            <Th>Certificate For</Th>
            <Th>Category</Th>
            <Th textAlign="center">Serial</Th>
            <Th textAlign="center">Invoice value</Th>
            <Th textAlign="right">Amount(৳)</Th>
            <Th textAlign="right">Quantity(KG)</Th>
            <Th textAlign="right">Cost(৳)</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              <Text textTransform="uppercase">
                {data?.certFor?.toUpperCase() === 'DUTY'
                  ? data?.certFor + '-EXEMPTION'
                  : data?.certFor}
              </Text>
            </Td>
            <Td>
              <Text>{data?.dutyCertCost?.category || 'N/A'}</Text>
            </Td>
            <Td textAlign="center">
              <Text>{data?.serial || 'N/A'}</Text>
            </Td>
            <Td textAlign="right">
              <Text>
                {commaNumber(data?.lcValue || 0)} {data?.currency || ''}
              </Text>
            </Td>
            <Td textAlign="right">
              <Text>{commaNumber(data?.dutyCertCost?.lcAmount || 0)}</Text>
            </Td>
            <Td textAlign="right">
              <Text>{commaNumber(data?.dutyCertCost?.quantity || 0)}</Text>
            </Td>
            <Td textAlign="right">
              <Text>{commaNumber(data?.dutyCertCost?.total || 0)}</Text>
            </Td>
          </Tr>

          {data?.discount ? (
            <Tr>
              <Td>
                <Text fontSize="16px" fontWeight="bold">
                  Discount:
                </Text>
              </Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td textAlign="right">
                <Text fontSize="16px" fontWeight="bold">
                  - {commaNumber(data?.discount || 0)}
                </Text>
              </Td>
            </Tr>
          ) : (
            <Tr></Tr>
          )}

          <Tr>
            <Td>
              <Text fontSize="16px" fontWeight="bold">
                Adjustments:
              </Text>
            </Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td textAlign="right">
              <Text fontSize="16px" fontWeight="bold">
                - {commaNumber(data?.adjustments || 0)}
              </Text>
            </Td>
          </Tr>

          <Tr>
            <Td>
              <Text fontSize="18px" fontWeight="bold">
                Total Payable:
              </Text>
            </Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td textAlign="right">
              <Text fontSize="18px" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                Tk.{' '}
                {commaNumber(data?.dutyCertCost?.total - data?.discount - data?.adjustments || 0)}
              </Text>
            </Td>
          </Tr>
        </Tbody>
      </Table>
      {billFor ? (
        <VStack w="100%" mt={1} alignItems="flex-start">
          <VStack w="max-content" alignItems="flex-start">
            <Text fontSize="sm" fontWeight="bold">
              Processing fee on{' '}
              {data?.dutyCertCost?.category === dutyCertCategories.A ||
              data?.dutyCertCost?.category === dutyCertCategories.B
                ? 'Invoice Amount'
                : 'Quantity'}
              :{' '}
            </Text>
            {data?.dutyCertCost?.category !== dutyCertCategories.A &&
            data?.dutyCertCost?.category !== dutyCertCategories.B ? (
              <HStack w="100%" justifyContent="space-between">
                <Text fontSize="sm" pr={5}>
                  for Kg. {commaNumber(data?.dutyCertCost?.quantity || 0)}:
                </Text>
                <Text fontWeight="bold">
                  ৳{data?.dutyCertCost?.costX || data?.dutyCertCost?.costY}
                </Text>
              </HStack>
            ) : data?.dutyCertCost?.costX > 0 ? (
              <>
                <HStack w="100%" justifyContent="space-between">
                  <Text fontSize="sm" pr={5}>
                    up to Tk. {commaNumber(data?.dutyCertCost?.limitX || 0)}:
                  </Text>
                  <Text fontWeight="bold">৳{data?.dutyCertCost?.costX}</Text>
                </HStack>
                <HStack w="100%" justifyContent="space-between">
                  <Text fontSize="sm" pr={5}>
                    for additional Tk. {commaNumber(data?.dutyCertCost?.limitY || 0)}:
                  </Text>
                  <Text fontWeight="bold">৳{data?.dutyCertCost?.costY}</Text>
                </HStack>
              </>
            ) : (
              <HStack w="100%" justifyContent="space-between">
                <Text fontSize="sm" pr={5}>
                  for additional Tk. {commaNumber(data?.dutyCertCost?.limitY || 0)}:
                </Text>
                <Text fontWeight="bold">৳{data?.dutyCertCost?.costY}</Text>
              </HStack>
            )}
            <HStack w="100%" justifyContent="space-between">
              <Text fontSize="sm" pr={5}>
                discount:
              </Text>
              <Text fontWeight="bold">- ৳{data?.discount}</Text>
            </HStack>
            <HStack w="100%" justifyContent="space-between">
              <Text fontSize="sm" pr={5}>
                adjustments:
              </Text>
              <Text fontWeight="bold">- ৳{data?.adjustments}</Text>
            </HStack>
          </VStack>

          <HStack w="100%" borderTop="1px solid black" borderStyle="dotted">
            <Text fontSize="sm" fontWeight="bold">
              In words:{' '}
            </Text>
            <Text fontSize="sm">
              {convertNumberToWords(
                data?.dutyCertCost?.total - (data?.discount || 0) - (data?.adjustments || 0),
              )}{' '}
              Taka Only
            </Text>
          </HStack>
        </VStack>
      ) : null}
    </Box>
  );
};

export default CostBreakdown;
