import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../common/hookFormComponents/FormSection';
import { dateValidation } from '../../../../util/yupUtils';

const fields = [
  {
    colSpan: 1,
    name: 'invoiceNumber',
    type: 'text',
    label: 'Invoice No.',
    isRequired: true,
  },
  {
    colSpan: 1,
    fieldType: 'date',
    name: 'invoiceDate',
    label: 'Invoice Date',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'invoiceAmount',
    type: 'text',
    label: 'Invoice Amount',
    isRequired: true,
  },
  {
    colSpan: 1,
    fieldType: 'select',
    name: 'invoiceCurrency',
    label: 'Invoice Currency',
    isRequired: true,
    choices: [
      { name: 'BDT', value: 'BDT' },
      { name: 'USD', value: 'USD' },
      { name: 'EURO', value: 'EURO' },
      { name: 'POUND', value: 'POUND' },
      { name: 'RUPEE', value: 'RUPEE' },
      { name: 'YEN', value: 'YEN' },
      { name: 'YUAN', value: 'YUAN' },
      { name: 'CHF', value: 'CHF' },
    ],
  },
];

export const generalInfoFieldsForMarketExpansionValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: dateValidation.required().label(fields[1].label),
  [fields[2].name]: yup
    .number()
    .typeError(`${fields[2].label} must be a number`)
    .required()
    .label(fields[2].label),
  [fields[3].name]: yup.string().required().label(fields[3].label),
};

const GeneralInfoSectionForMarketExpansion = (props) => {
  return (
    <FormSection
      sectionName="General Information"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default GeneralInfoSectionForMarketExpansion;
