import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 2,
    name: 'mdName',
    type: 'text',
    label: 'Name of Managing Director',
  },
  {
    colSpan: 1,
    name: 'mdOfficeTelephone',
    type: 'text',
    label: 'Office Telephone',
  },
  {
    colSpan: 1,
    name: 'mdResidenceTelephone',
    type: 'text',
    label: 'Residence Telephone',
  },
  {
    colSpan: 1,
    name: 'mdMobileNo',
    type: 'text',
    label: 'Mobile Number',
  },
  {
    colSpan: 1,
    name: 'mdEmail',
    type: 'text',
    label: 'Email Address',
  },
];

export const managingDirectorFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label).nullable(),
  [fields[1].name]: yup.string().label(fields[1].label).nullable(),
  [fields[2].name]: yup.string().label(fields[2].label).nullable(),
  [fields[3].name]: yup.string().label(fields[3].label).nullable(),
  [fields[4].name]: yup.string().email().label(fields[4].label).nullable(),
};

const ManagingDirectorSection = (props) => {
  return (
    <FormSection
      sectionName="Managing Director Information"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default ManagingDirectorSection;
