import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { renderDate } from '../../../util/formatUtils';

const PrintPreview = React.forwardRef(({ data }, ref) => (
  <Box w="100%" h="100%" m={0} p={0} ref={ref} position="relative">
    <Text fontSize="md" position="absolute" top="165px" left="250px" fontWeight="bold">
      {data?.certData?.masterLc}
    </Text>
    <Text fontSize="md" position="absolute" top="165px" left="600px" fontWeight="bold">
      {renderDate(data?.certData?.dateOfLc, 'numeric')}
    </Text>
    <Text fontSize="md" position="absolute" top="192px" left="370px" fontWeight="bold">
      {data?.certData?.totalQuantity}
    </Text>
    <Text fontSize="md" position="absolute" top="192px" left="600px" fontWeight="bold">
      {data?.certData?.totalValueInUsd}
    </Text>
    {data?.type === 'YARN' ? (
      <>
        <Text fontSize="md" position="absolute" top="218px" left="460px" fontWeight="bold">
          {data?.certData?.btbLc}
        </Text>
        <Text fontSize="md" position="absolute" top="242px" left="160px" fontWeight="bold">
          {renderDate(data?.certData?.btbLcDate, 'numeric')}
        </Text>
        <Text fontSize="md" position="absolute" top="242px" left="500px" fontWeight="bold">
          {data?.certData?.valueInUsd}
        </Text>
        <Text fontSize="md" position="absolute" top="265px" left="210px" fontWeight="bold">
          {data?.certData?.bank?.split(',')?.[0]}
        </Text>
        <Text fontSize="md" position="absolute" top="265px" left="575px" fontWeight="bold">
          {data?.certData?.bank?.split(',')?.[1]}
        </Text>
      </>
    ) : data?.type === 'FABRIC' ? (
      <>
        <Text fontSize="md" position="absolute" top="292px" left="460px" fontWeight="bold">
          {data?.certData?.btbLc}
        </Text>
        <Text fontSize="md" position="absolute" top="315px" left="160px" fontWeight="bold">
          {renderDate(data?.certData?.btbLcDate, 'numeric')}
        </Text>
        <Text fontSize="md" position="absolute" top="315px" left="500px" fontWeight="bold">
          {data?.certData?.valueInUsd}
        </Text>
        <Text fontSize="md" position="absolute" top="340px" left="210px" fontWeight="bold">
          {data?.certData?.bank?.split(',')?.[0]}
        </Text>
        <Text fontSize="md" position="absolute" top="340px" left="575px" fontWeight="bold">
          {data?.certData?.bank?.split(',')?.[1]}
        </Text>
      </>
    ) : null}
    <Text fontSize="md" position="absolute" top="368px" left="210px" fontWeight="bold">
      {renderDate(data?.certData?.expiryDateOfLc, 'numeric')}
    </Text>
    <Text fontSize="md" position="absolute" top="368px" left="440px" fontWeight="bold">
      {data?.certData?.mushakNum}
    </Text>
    <Text fontSize="md" position="absolute" top="368px" left="600px" fontWeight="bold">
      {renderDate(data?.certData?.mushakDate, 'numeric')}
    </Text>
    {data?.type === 'YARN' ? (
      <Box position="absolute" top="448px" left="100px" pr="100px">
        {data?.certData?.productDetails?.length > 0 &&
          data?.certData?.productDetails?.map((item, index) => (
            <HStack key={index} w="610px" alignItems="flex-start">
              <Text w="27%" fontSize="11">
                {item?.product}
              </Text>
              <Text w="27%" fontSize="11">
                {item?.productQuantity}
              </Text>
              <Text w="17%" fontSize="11" textAlign="center">
                {renderDate(item?.deliveryDate, 'numeric')}
              </Text>
              <Text w="29%" fontSize="11">
                {item?.invoice}
                {item?.invoiceDate ? ', ' + renderDate(item?.invoiceDate, 'numeric') : null}
              </Text>
            </HStack>
          ))}
      </Box>
    ) : data?.type === 'FABRIC' ? (
      <Box position="absolute" top="585px" left="100px">
        {data?.certData?.productDetails?.length > 0 &&
          data?.certData?.productDetails?.map((item, index) => (
            <HStack key={index} w="600px" alignItems="flex-start">
              <Text w="25%" fontSize="11">
                {item?.product}
              </Text>
              <Text w="25%" fontSize="11">
                {item?.productQuantity}
              </Text>
              <Text w="21%" fontSize="11" textAlign="center">
                {renderDate(item?.deliveryDate, 'numeric')}
              </Text>
              <Text w="29%" fontSize="11">
                {item?.invoice}
                {item?.invoiceDate ? ', ' + renderDate(item?.invoiceDate, 'numeric') : null}
              </Text>
            </HStack>
          ))}
      </Box>
    ) : null}
    <Text
      px="90px"
      maxW="100%"
      top="668px"
      fontSize="xs"
      lineHeight="1.25"
      fontWeight="bold"
      position="absolute"
      style={{ textIndent: '475px' }}>
      {data?.millName}, {data?.millAddress}
    </Text>
    <Text fontSize="xs" position="absolute" top="735px" left="90px" fontWeight="bold">
      {data?.certData?.annualProdCapacity}
    </Text>
    <Text
      px="90px"
      top="760px"
      maxW="100%"
      fontSize="xs"
      lineHeight="1"
      fontWeight="bold"
      position="absolute"
      style={{ textIndent: '35px' }}>
      {data?.certData?.suppliedTo}
    </Text>
  </Box>
));

PrintPreview.displayName = 'PrintPreview';

export default PrintPreview;
