import React, { useState } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Box, VStack, useDisclosure } from '@chakra-ui/react';

import FormButton from '../../common/FormButton';
import DialogueBox from '../../common/DialogueBox';
import UserInfoSection, { allFieldsValidation } from './UserInfoSection';

import _ from 'lodash';
import { patchAdminUser } from '../../../api/users';
import { notify } from '../../../store/actions/globalActions';
import { useYupValidationResolver } from '../../../util/yupUtils';

const UserInfoForm = ({ data, closeModal, refresh }) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { username, phoneNo, email, fullName, adminRole, userStatus } = data;
  const defaultFields = {
    username,
    phoneNo,
    email,
    fullName,
    userStatus,
    adminRole: adminRole?.replace(/ /g, '_'),
  };

  const resolver = useYupValidationResolver(
    yup.object().shape({
      ...allFieldsValidation,
    }),
  );

  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: defaultFields,
  });

  const { password, rePassword, ...rest } = watch();

  const onSubmit = (data) => {
    setUserData(data);
    onOpen();
  };

  const confirmUpdate = async () => {
    const res = await patchAdminUser({ uid: data?.uId, userData });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 1500,
        }),
      );
      refresh();
      closeModal();
    }
  };

  return (
    <Box as="form" w="600px" onSubmit={handleSubmit(onSubmit)}>
      <UserInfoSection allFields register={register} control={control} errors={errors} />
      <VStack>
        <FormButton
          type="submit"
          isLoading={isSubmitting}
          isDisabled={
            (_.isEqual(defaultFields, rest) && !password) ||
            (password && rePassword && password !== rePassword) ||
            (password !== '' && password?.length < 6)
          }>
          UPDATE
        </FormButton>
      </VStack>
      <DialogueBox
        close={onClose}
        alertTitle="Confirm"
        mountControl={isOpen}
        performAction={confirmUpdate}
        alertMessage="Are you sure you want to update this user's information?"
      />
    </Box>
  );
};

export default UserInfoForm;
