import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'spindles',
    type: 'text',
    label: 'Spindles',
  },
  {
    colSpan: 1,
    name: 'rotors',
    type: 'text',
    label: 'Rotors',
  },
];

export const InstalledCapacityFieldsValidation = {
  [fields[0].name]: yup.string(),
  [fields[1].name]: yup.string(),
};

const InstalledCapacitySection = (props) => {
  return (
    <FormSection
      sectionName="Installed Capacity"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default InstalledCapacitySection;
