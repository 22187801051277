import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

const CertRangeError = ({ errorResponse, message, certFor, productionCertificateType }) => {
  return (
    <>
      <Text color="red">{message?.split(':')?.[0]}</Text>

      <Text fontWeight={600} textAlign="center" pt={4}>
        {certFor !== 'PRODUCTION' ? certFor : `${certFor}-${productionCertificateType}`}
      </Text>

      <Flex direction="column" mt="5">
        <Flex justifyContent="space-between" mb={2}>
          <Text fontWeight={600}>Certificate No</Text>
          <Text fontWeight={600}>Assigned Mill</Text>
        </Flex>

        {errorResponse?.data?.map((cert, index) => {
          return (
            <Flex justifyContent="space-between" my="0.5" key={index}>
              <Text>{cert?.certificateNum}</Text>
              <Text>{cert?.millInfo?.millName}</Text>
            </Flex>
          );
        })}
      </Flex>
    </>
  );
};

export default CertRangeError;
