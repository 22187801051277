import React from 'react';
import CostBreakDown from './CostBreakDown';
import { renderDate } from '../../../util/formatUtils';
import { Box, HStack, VStack, Text } from '@chakra-ui/react';
import PrintReceiptHeader from '../../snippets/PrintReceiptHeader';

const MemebershipBill = React.forwardRef(({ title, data, costBreakDownData }, ref) => (
  <Box p={6} ref={ref}>
    <PrintReceiptHeader />
    <HStack w="100%" justifyContent="space-between" alignItems="flex-start">
      <VStack spacing={0} alignItems="flex-start" w="35%">
        <Text fontSize="sm" fontWeight="bold">
          Managing Director
        </Text>
        <Text fontSize="sm">{data?.millName}</Text>
        <Text fontSize="sm">{data?.millAddress}</Text>
      </VStack>
      <VStack spacing={0} alignItems="flex-start">
        <Text fontSize="sm">Bill No: {data?.billNo}</Text>
        <Text fontSize="sm">Date: {renderDate(data?.billDate)}</Text>
      </VStack>
    </HStack>

    <CostBreakDown billFor={title} {...costBreakDownData} />

    <VStack w="100%" alignItems="flex-end" mt={10}>
      <VStack>
        <Text
          p={2}
          fontSize="lg"
          fontWeight="bold"
          width="300px"
          textAlign="center"
          borderBottom="2px solid black"></Text>
        <Text fontWeight="bold">Authorized Signature</Text>
      </VStack>
    </VStack>

    <VStack w="100%" alignItems="flex-Start" mt={5}>
      <Text fontWeight="bold" borderBottom="2px solid black">
        Yearly Subscription
      </Text>
      <Text fontStyle="italic" fontSize="sm" textAlign="justify">
        As per clause 1. of articles of Association of BTMA, subscription for each year is to be
        paid in two equal installments. Therefore, the 1st installment be paid within 15 days of the
        issuance of this bill.
      </Text>
    </VStack>
  </Box>
));

MemebershipBill.displayName = 'MemebershipBill';

export default MemebershipBill;
