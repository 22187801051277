import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'numberOfShiftsPerWorkingDay',
    type: 'text',
    label: 'Number of Shifts Per Working Day',
  },
  {
    colSpan: 1,
    name: 'hoursPerShift',
    type: 'text',
    label: 'Hours Per Shift',
  },
  {
    colSpan: 1,
    name: 'numberOfWorkingDaysInAYear',
    type: 'text',
    label: 'Number of Working Days in a Year',
  },
];

export const FactoryOperationFieldsValidation = {
  [fields[0].name]: yup.string(),
  [fields[1].name]: yup.string(),
  [fields[2].name]: yup.string(),
};

const FactoryOperation = (props) => {
  return (
    <FormSection
      sectionName="Factory Operation Related Information"
      sectionColumn={3}
      formFields={fields}
      {...props}
    />
  );
};

export default FactoryOperation;
