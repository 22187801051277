import axios from './axios';

export const getAllNotification = async (data) => {
  try {
    const res = await axios.get('/notifications', { params: data?.params });
    if (res.status === 200)
      return {
        data: {
          ...res.data,
          page: data?.params?.page || 1,
          pageSize: data?.params?.pageSize || 10,
        },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const retryNow = async (data) => {
  try {
    const res = await axios.post('/notifications/retry', data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const retrySchedule = async (data) => {
  try {
    const res = await axios.post('/notifications/requeue', data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};
