import React, { useEffect, useState } from 'react';
import {
  Tr,
  Th,
  Td,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  HStack,
  TableCaption,
  useDisclosure,
} from '@chakra-ui/react';
import Loader from '../../../snippets/Loader';
import DialogueBox from '../../../common/DialogueBox';
import {
  labourKeys,
  labourKeyList,
  labourKeyNames,
} from '../../../../constants/labourDatabaseConstant';

const ErrorView = ({ errors, data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errorDetails, setErrorDetails] = useState(false);

  useEffect(() => {
    errors ? onOpen() : null;
  }, [errors]);

  const checkError = (index, column) => {
    let rowErrors = errors?.filter((err) => err?.index === index);
    return rowErrors?.map((err) => {
      if (
        err?.colIndex === column ||
        ((colIndex = err?.colIndex) => {
          if (Array.isArray(colIndex) && colIndex?.includes(column)) {
            return true;
          } else {
            return false;
          }
        })()
      ) {
        return '1px solid red';
      }
    });
  };

  return errors ? (
    <>
      <DialogueBox
        mountControl={isOpen}
        alertTitle="Error!"
        cancelButtonText="Close"
        actionButtonText="View Details"
        performAction={() => setErrorDetails(true)}
        alertMessage="Your CSV file contains error. Please follow the instruction table to upload valid data."
        close={() => {
          onClose();
          setErrorDetails(true);
        }}
      />
      {errorDetails ? (
        <Box m={6}>
          <Text fontSize="md" fontWeight="bold" color="textSecondary">
            Following errors are found in your file.
          </Text>
          <Text fontSize="sm" color="textSecondary">
            (Using Google Sheets or Microsoft Excel is recomended to edit CSV file)
          </Text>
          <Box
            p={4}
            mt={2}
            bg="white"
            maxH="260px"
            overflow="auto"
            borderRadius="4px"
            boxShadow="inset 1px 1px 5px rgba(0,0,0,0.2)">
            {errors.map((item, index) => (
              <Text color="invalid" fontSize="sm" key={index}>
                {index + 1}: {item.msg}
              </Text>
            ))}
          </Box>

          <Box bg="white" p={4} borderRadius="4px" mt={6} overflowX="scroll">
            <Table size="sm" style={{ borderCollapse: 'separate' }}>
              <TableCaption placement="Top" mb={4} p={0}>
                <HStack>
                  <Text fontSize="lg" fontWeight="bold" color="invalid">
                    Error!
                  </Text>
                </HStack>
              </TableCaption>

              <Thead>
                <Tr bg="secondary">
                  {labourKeyNames().map((key, index) => (
                    <Th key={index} fontSize="14px" color="black" py={0}>
                      {key}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((tr, index) => {
                  return (
                    <Tr key={index}>
                      {labourKeyList().map((key, idx) => (
                        <Td
                          py={0}
                          key={idx}
                          border={checkError(index + 1, labourKeys[key].column) || 'none'}>
                          {tr?.[labourKeys[key].name] || ''}
                        </Td>
                      ))}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        </Box>
      ) : null}
    </>
  ) : (
    <Loader />
  );
};

export default ErrorView;
