import axios from './axios';

export const getUnusedCertificates = async (data) => {
  try {
    const res = await axios.get(`/certificates/unused/${data?.type}/${data?.millId}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getAllApplications = async (data) => {
  try {
    const res = await axios.get(`/certificates/${data?.type}`, { params: data?.params });
    if (res.status === 200)
      return {
        data: {
          ...res.data,
          page: data?.params?.page || 1,
          pageSize: data?.params?.pageSize || 10,
        },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const updateApplicationStatus = async (data) => {
  try {
    const res = await axios.patch(`/certificates/status/${data?.id}`, data?.formData);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getApplicationInfo = async (data) => {
  try {
    const res = await axios.get(`/certificates/${data?.type}/${data?.id}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const patchApplicationInfo = async (data) => {
  try {
    const res = await axios.patch(
      `/certificates/verification/${data?.type}/${data?.id}`,
      data?.formData,
    );
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const applyForCertificate = async (data) => {
  try {
    const res = await axios.post(`/certificates/${data?.type}`, data?.formData);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const updateCertificate = async (data) => {
  try {
    const res = await axios.patch(`/certificates/${data?.type}/${data?.id}`, data?.formdata);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const updateNewMarketExpansionCertificate = async (data) => {
  try {
    const res = await axios.patch(
      `/certificates/verification/${data?.type}/${data?.id}`,
      data?.formdata,
    );
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getAllCertForGen = async (data) => {
  try {
    const res = await axios.get(`/generate/certificates/${data?.type}`, { params: data?.params });
    if (res.status === 200)
      return {
        data: {
          ...res.data,
          page: data?.params?.page || 1,
          pageSize: data?.params?.pageSize || 10,
        },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getAllCertDocuments = async (data) => {
  try {
    const res = await axios.get(`/shared/zip-cert/${data?.certType}/${data?.applicationId}`);
    if (res.status === 200)
      return {
        data: { status: 200 },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const patchCertDocs = async (data) => {
  const { millId, certType, certId, productType, docData } = data;
  try {
    const res = await axios.patch(
      `/certificates/${certType}/documents/${millId}/${certId}/${productType}`,
      docData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const patchDutyCertDocs = async (data) => {
  const { millId, certId, docData } = data;
  try {
    const res = await axios.patch(`/certificates/duty-exemption/${millId}/${certId}`, docData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const assignDutyCertNumber = async (data) => {
  const { millId, certId, formdata } = data;
  try {
    const res = await axios.post(`/certificates/duty-exemption/${millId}/${certId}`, formdata);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const assignPreSoldCertificate = async (data) => {
  const { millId, type, certificateNum } = data;
  try {
    const res = await axios.post(`/certificates/pre-sold/${millId}`, { type, certificateNum });
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getMillAnnualProductionCap = async (millId) => {
  try {
    const res = await axios.get(`/certificates/capacity/${millId}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const findCertificateForRejection = async (data) => {
  try {
    const res = await axios.get(`/certificates/find/${data?.certType}/${data?.certificateNum}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const findCertificateReceipt = async (data) => {
  try {
    const res = await axios.get(`/accounts/find/${data?.certType}/${data?.trxId}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const updateCertificateReceipt = async (data) => {
  try {
    const res = await axios.patch(
      `/accounts/certificate/invoice/paid/${data.type}/${data.uid}`,
      data,
    );
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const rejectCertificate = async (data) => {
  try {
    const res = await axios.post('/certificates/reject', data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const unrejectCertificate = async (data) => {
  try {
    const res = await axios.post('/certificates/unreject', data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const reassignCertificate = async (data) => {
  try {
    const res = await axios.post('/certificates/reassign', data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const replaceCertificate = async (data) => {
  try {
    const res = await axios.post('/certificates/reject-reassign', data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getAllRejectedCerts = async (data) => {
  try {
    const res = await axios.get('/certificates/rejected', { params: data?.params });
    if (res.status === 200)
      return {
        data: {
          ...res.data,
          page: data?.params?.page || 1,
          pageSize: data?.params?.pageSize || 10,
        },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const deleteCertificate = async (data) => {
  try {
    const res = await axios.post('/certificates/delete', data);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};
