import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { renderDate } from '../../../util/formatUtils';

const PrintPreview = React.forwardRef(({ data }, ref) => (
  <Box w="100%" h="100%" m={0} p={0} ref={ref} position="relative">
    <Text fontSize="md" position="absolute" top="200px" left="250px" fontWeight="bold">
      {data?.certData?.masterLc}
    </Text>
    <Text fontSize="md" position="absolute" top="200px" left="590px" fontWeight="bold">
      {renderDate(data?.certData?.dateOfLc, 'numeric')}
    </Text>
    <Text fontSize="md" position="absolute" top="225px" left="370px" fontWeight="bold">
      {data?.certData?.totalQuantity}
    </Text>
    {data?.type === 'YARN' ? (
      <>
        <Text fontSize="md" position="absolute" top="250px" left="460px" fontWeight="bold">
          {data?.certData?.btbLc}
        </Text>
        <Text fontSize="md" position="absolute" top="275px" left="160px" fontWeight="bold">
          {renderDate(data?.certData?.btbLcDate, 'numeric')}
        </Text>
        <Text fontSize="md" position="absolute" top="275px" left="560px" fontWeight="bold">
          {data?.certData?.valueInUsd}
        </Text>
        <Text fontSize="md" position="absolute" top="300px" left="210px" fontWeight="bold">
          {data?.certData?.bank?.split(',')?.[0]}
        </Text>
        <Text fontSize="md" position="absolute" top="300px" left="555px" fontWeight="bold">
          {data?.certData?.bank?.split(',')?.[1]}
        </Text>
      </>
    ) : data?.type === 'FABRIC' ? (
      <>
        <Text fontSize="md" position="absolute" top="325px" left="460px" fontWeight="bold">
          {data?.certData?.btbLc}
        </Text>
        <Text fontSize="md" position="absolute" top="350px" left="160px" fontWeight="bold">
          {renderDate(data?.certData?.btbLcDate, 'numeric')}
        </Text>
        <Text fontSize="md" position="absolute" top="350px" left="560px" fontWeight="bold">
          {data?.certData?.valueInUsd}
        </Text>
        <Text fontSize="md" position="absolute" top="370px" left="210px" fontWeight="bold">
          {data?.certData?.bank?.split(',')?.[0]}
        </Text>
        <Text fontSize="md" position="absolute" top="370px" left="555px" fontWeight="bold">
          {data?.certData?.bank?.split(',')?.[1]}
        </Text>
      </>
    ) : null}

    <Text fontSize="md" position="absolute" top="398px" left="210px" fontWeight="bold">
      {renderDate(data?.certData?.expiryDateOfLc, 'numeric')}
    </Text>
    <Text fontSize="md" position="absolute" top="398px" left="450px" fontWeight="bold">
      {data?.certData?.exciseGatePassNum}
    </Text>
    <Text fontSize="md" position="absolute" top="398px" left="565px" fontWeight="bold">
      {renderDate(data?.certData?.exciseGatePassDate, 'numeric')}
    </Text>

    {data?.type === 'YARN' ? (
      <Box position="absolute" top="500px" left="100px" pr="100px">
        {data?.certData?.productDetails?.length > 0 &&
          data?.certData?.productDetails?.map((item, index) => (
            <HStack key={index} w="600px" spacing={0} alignItems="flex-start">
              <Text w="25%" fontSize="11px">
                {item?.product}
              </Text>
              <Text w="25%" fontSize="11px">
                {item?.productQuantity}
              </Text>
              <Text w="21%" fontSize="11px" textAlign="center">
                {renderDate(item?.deliveryDate, 'numeric')}
              </Text>
              <Text w="29%" fontSize="11px">
                {item?.invoice}
                {item?.invoiceDate ? ', ' + renderDate(item?.invoiceDate, 'numeric') : null}
              </Text>
            </HStack>
          ))}
      </Box>
    ) : data?.type === 'FABRIC' ? (
      <Box position="absolute" top="610px" left="100px" pr="100px">
        {data?.certData?.productDetails?.length > 0 &&
          data?.certData?.productDetails?.map((item, index) => (
            <HStack key={index} w="600px" alignItems="flex-start">
              <Text w="27%" fontSize="11px">
                {item?.product}
              </Text>
              <Text w="27%" fontSize="11px">
                {item?.productQuantity}
              </Text>
              <Text w="17%" fontSize="11px" textAlign="center">
                {renderDate(item?.deliveryDate, 'numeric')}
              </Text>
              <Text w="29%" fontSize="11px">
                {item?.invoice}
                {item?.invoiceDate ? ', ' + renderDate(item?.invoiceDate, 'numeric') : null}
              </Text>
            </HStack>
          ))}
      </Box>
    ) : null}

    <Text
      px="90px"
      maxW="100%"
      top="698px"
      fontSize="xs"
      lineHeight="1.25"
      fontWeight="bold"
      position="absolute"
      style={{ textIndent: '450px' }}>
      {data?.millName}, {data?.millAddress}
    </Text>
    <Text fontSize="xs" position="absolute" top="762px" left="90px" fontWeight="bold">
      {data?.certData?.annualProdCapacity}
    </Text>
    <Text
      px="90px"
      top="790px"
      maxW="100%"
      left="110px"
      fontSize="xs"
      lineHeight="1"
      fontWeight="bold"
      position="absolute"
      style={{ textIndent: '20px' }}>
      {data?.certData?.suppliedTo}
    </Text>
  </Box>
));

PrintPreview.displayName = 'PrintPreview';

export default PrintPreview;
