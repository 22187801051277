import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    id: 0,
    colSpan: 1,
    name: 'exportItemDetails',
    type: 'text',
    label: 'Export Item Details',
    isRequired: true,
  },
  {
    id: 1,
    colSpan: 1,
    name: 'originalExportItemQuantity',
    type: 'text',
    label: 'Original Export Item Quantity',
    isRequired: true,
  },
  {
    id: 2,
    colSpan: 1,
    name: 'originalExportItemQuantityUnit',
    type: 'text',
    label: 'Original Export Item Quantity Unit',
    isRequired: true,
  },
  {
    id: 3,
    colSpan: 1,
    name: 'originalExportItemValue',
    type: 'text',
    label: 'Original Export Item Value',
    isRequired: true,
  },
  {
    id: 4,
    colSpan: 1,
    fieldType: 'select',
    name: 'originalExportItemValueCurrency',
    label: 'Original Export Item Currency',
    isRequired: true,
    choices: [
      { name: 'BDT', value: 'BDT' },
      { name: 'USD', value: 'USD' },
      { name: 'EURO', value: 'EURO' },
      { name: 'POUND', value: 'POUND' },
      { name: 'RUPEE', value: 'RUPEE' },
      { name: 'YEN', value: 'YEN' },
      { name: 'YUAN', value: 'YUAN' },
      { name: 'CHF', value: 'CHF' },
    ],
  },
  {
    id: 5,
    colSpan: 1,
    name: 'expNumber',
    type: 'text',
    label: 'Exp Number',
    isRequired: true,
    isMultiple: true,
  },
  {
    id: 6,
    type: 'text',
    colSpan: 1,
    name: 'expDate',
    label: 'Exp Date',
    isRequired: true,
    isMultiple: true,
  },
  {
    id: 7,
    colSpan: 1,
    name: 'expValue',
    type: 'text',
    label: 'Exp Value',
    isRequired: true,
  },
  {
    id: 8,
    colSpan: 1,
    name: 'totalRepatriatedForeignExportValue',
    type: 'text',
    label: 'Total Repatriated Foreign Export Value',
    isRequired: true,
  },
  {
    id: 9,
    colSpan: 1,
    fieldType: 'select',
    name: 'totalRepatriatedForeignExportValueCurrency',
    label: 'Total Repatriated Foreign Export Value Currency',
    isRequired: true,
    choices: [
      { name: 'BDT', value: 'BDT' },
      { name: 'USD', value: 'USD' },
      { name: 'EURO', value: 'EURO' },
      { name: 'POUND', value: 'POUND' },
      { name: 'RUPEE', value: 'RUPEE' },
      { name: 'YEN', value: 'YEN' },
      { name: 'YUAN', value: 'YUAN' },
      { name: 'CHF', value: 'CHF' },
    ],
  },
  {
    id: 10,
    colSpan: 1,
    name: 'repatriatedExportValueCertificateNumber',
    type: 'text',
    label: 'Repatriated Export Value Certificate Number (PRC)',
    isRequired: true,
    isMultiple: true,
  },
  {
    id: 11,
    type: 'text',
    colSpan: 1,
    name: 'repatriatedExportValueCertificateDate',
    label: 'Repatriated Export Value Certificate Date',
    isRequired: true,
    isMultiple: true,
  },
  {
    id: 12,
    colSpan: 1,
    name: 'netFovValue',
    type: 'text',
    label: 'Net FOV Value',
    isRequired: true,
  },
];

export const requiredInfoFieldsForMarketExpansionValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup
    .number()
    .typeError(`${fields[1].label} must be a number`)
    .required()
    .label(fields[1].label),
  [fields[2].name]: yup.string().required().label(fields[2].label),
  [fields[3].name]: yup
    .number()
    .typeError(`${fields[3].label} must be a number`)
    .required()
    .label(fields[3].label),
  [fields[4].name]: yup.string().required().label(fields[4].label),
  [fields[5].name]: yup.string().required().label(fields[5].label),
  [fields[6].name]: yup.string().label(fields[6].label),
  [fields[7].name]: yup
    .number()
    .typeError(`${fields[7].label} must be a number`)
    .required()
    .label(fields[7].label),
  [fields[8].name]: yup
    .number()
    .typeError(`${fields[8].label} must be a number`)
    .required()
    .label(fields[8].label),
  [fields[9].name]: yup.string().required().label(fields[9].label),
  [fields[10].name]: yup.string().required().label(fields[10].label),
  [fields[11].name]: yup.string().required().label(fields[11].label),
  [fields[12].name]: yup
    .number()
    .typeError(`${fields[12].label} must be a number`)
    .required()
    .label(fields[12].label),
};

const RequiredInfoSectionForMarketExpansion = (props) => {
  return (
    <FormSection
      sectionName="Required Information"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default RequiredInfoSectionForMarketExpansion;
