import React, { useState } from 'react';
import {
  BADGE_STATE_COLORS,
  LOGS_TABLE_DISPLAY_SIZE,
} from '../../../../constants/dataTableConstants';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRequests, updateRenewalReqStatus } from '../../../../api/accounts';
import DataTable from '../../../common/dataTable/DataTable';
import { FETCH_RENEWAL_REQUESTS_ACC } from '../../../../store/actions/actionTypes';

import { useDisclosure } from '@chakra-ui/react';
import RenewalRequest from './RenewalRequest';
import DialogueBox from '../../../common/DialogueBox';
import { notify } from '../../../../store/actions/globalActions';

//configure columns
const RenewalsTable = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState();
  const [rowData, setRowData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalComponent, setModalComponent] = useState();
  const data = useSelector((state) => state.accounts.renewalRequests);

  const columns = [
    {
      header: 'ID',
      accessor: 'requestId',
    },
    {
      header: 'Date',
      accessor: 'createdAt',
      isDate: true,
    },
    {
      header: 'Mill Name',
      accessor: 'millName',
    },
    {
      header: 'Category',
      accessor: 'category',
    },
    {
      header: 'Email',
      accessor: 'millEmail',
    },
    {
      header: 'Contact',
      accessor: 'millPhoneNo',
    },
    {
      header: 'Status',
      accessor: 'renewalStatus',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
    },
    {
      header: 'Action',
      accessor: 'renewalStatus',
      isButton: true,
      actionScheme: [
        { group: 'PENDING', variant: 'read', action: 'VIEW' },
        { group: 'APPROVED', variant: 'read', action: 'VIEW' },
        { group: 'REJECTED', variant: 'read', action: 'VIEW' },
      ],
    },
  ];

  const handleView = async ({ rowObject }) => {
    setModal('DETAILS');
    setRowData(rowObject);
    setModalComponent(
      <RenewalRequest
        data={rowObject}
        closeModal={onClose}
        refresh={() => handleSearch({ page: 1, pageSize: 10 })}
      />,
    );
    onOpen();
  };

  //assign actions to each button
  const actionObject = {
    VIEW: handleView,
  };

  const handlePagination = async ({ page, pageSize, searchKey, startDate, endDate }) => {
    dispatch({ type: FETCH_RENEWAL_REQUESTS_ACC, payload: null });
    const params = {
      page,
      endDate,
      pageSize,
      searchKey,
      startDate,
    };
    const res = await getAllRequests({ flag: 'RENEWAL', params });
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_RENEWAL_REQUESTS_ACC, payload: res.data });
    } else {
      dispatch({ type: FETCH_RENEWAL_REQUESTS_ACC, payload: { renRequests: [] } });
    }
  };

  const handleSearch = async ({ searchKey, startDate, endDate }) => {
    dispatch({ type: FETCH_RENEWAL_REQUESTS_ACC, payload: null });
    const params = { searchKey, startDate, endDate };
    const res = await getAllRequests({ flag: 'RENEWAL', params });
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_RENEWAL_REQUESTS_ACC, payload: res.data });
    } else {
      dispatch({ type: FETCH_RENEWAL_REQUESTS_ACC, payload: { renRequests: [] } });
    }
  };

  const handleResolve = async () => {
    setModal('CONFIRM');
    onOpen();
  };

  const confirmResolve = async () => {
    const res = await updateRenewalReqStatus({ uid: rowData?.requestId, status: 'APPROVED' });
    if (res.data?.status === 200) {
      handleSearch({ page: 1, pageSize: 10 });
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
    }
  };

  const renderModal = () => {
    switch (modal) {
      case 'DETAILS':
        return (
          <DialogueBox
            variant="lg"
            close={onClose}
            mountControl={isOpen}
            cancelButtonText="Close"
            alertTitle="Request Details"
            performAction={handleResolve}
            actionButtonText="Mark As Resolved"
            actionDisabled={rowData?.renewalStatus === 'APPROVED'}>
            {modalComponent}
          </DialogueBox>
        );
      case 'CONFIRM':
        return (
          <DialogueBox
            close={onClose}
            alertTitle="Confirm"
            mountControl={isOpen}
            performAction={confirmResolve}
            alertMessage="Are you sure you want to mark this renewal request as resolved?"
          />
        );
    }
  };

  return (
    <>
      <DataTable
        topPanel
        keySearch
        dateRange
        isPaginated
        columns={columns}
        search={handleSearch}
        actions={actionObject}
        currentPage={data?.page}
        data={data?.renRequests}
        paginate={handlePagination}
        entries={data?.totalEntries}
        currentPageSize={data?.pageSize}
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
        caption="Membership Renewal Requests"
      />
      {renderModal()}
    </>
  );
};

export default RenewalsTable;
