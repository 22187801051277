import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Text } from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Link, useHistory } from 'react-router-dom';

import AuthForm from './AuthForm';
import AuthWrapper from './AuthWrapper';

import { login } from '../../api/auth';
import { useDispatch } from 'react-redux';
import { USERNAME_REGEX } from '../../constants/regex';
import { logIn } from '../../store/actions/authActions';
import { notify } from '../../store/actions/globalActions';
import { getCurrentUserData } from '../../util/localStorageUtils';
import { PASSWORD_MIN_LENGTH, USERNAME_MIN_LENGTH } from '../../constants/authConstant';
import {
  DASHBOARD_URL,
  PASSWORD_RESET_URL,
  ACCOUNT_VERIFICATION_URL,
} from '../../constants/routerUrl';

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    const { user, accessToken } = getCurrentUserData();
    if (user && accessToken) {
      dispatch(logIn(user));
      history.replace(DASHBOARD_URL);
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      const res = await login(data);
      if (res.status === 200) {
        dispatch(
          notify({
            title: 'Success',
            description: res.data.msg,
            status: 'success',
            duration: 5000,
          }),
        );
        const { username, phoneNo, sessionId } = res.data;
        history.replace({
          pathname: ACCOUNT_VERIFICATION_URL,
          state: { username, phoneNo, sessionId },
        });
      }
    } catch (error) {
      if (error?.response?.data?.shouldResetPassword) {
        history.replace(PASSWORD_RESET_URL);
      }
    }
  };

  const formFields = [
    {
      id: 'username',
      name: 'username',
      isRequired: true,
      type: 'text',
      label: 'Username',
      placeholder: 'Enter a username',
    },
    {
      id: 'password',
      name: 'password',
      isRequired: true,
      label: 'Password',
      placeholder: 'Enter your password',
      type: showPass ? 'text' : 'password',
      rightElement: showPass ? (
        <ViewIcon onClick={() => setShowPass(false)} cursor="pointer" mt={1.5} />
      ) : (
        <ViewOffIcon onClick={() => setShowPass(true)} cursor="pointer" mt={1.5} />
      ),
    },
  ];

  const LoginValidationSchema = yup.object().shape({
    username: yup
      .string()
      .matches(USERNAME_REGEX, 'Not a valid username')
      .min(USERNAME_MIN_LENGTH)
      .required(),
    password: yup.string().min(PASSWORD_MIN_LENGTH).required(),
  });

  return (
    <AuthWrapper>
      <AuthForm
        columns={1}
        formName="Login"
        onSubmit={onSubmit}
        formSubmitName="LOGIN"
        formFields={formFields}
        validationSchema={LoginValidationSchema}
        extraField={
          <Link to={PASSWORD_RESET_URL} style={{ maxWidth: 'fit-content' }}>
            <Text
              fontSize="sm"
              opacity={0.45}
              transition="0.5s"
              color="primary.700"
              _hover={{ opacity: 1 }}>
              Forgot password?
            </Text>
          </Link>
        }
      />
    </AuthWrapper>
  );
};

export default Login;
