import React from 'react';
import { Box, VStack, Flex } from '@chakra-ui/react';
import NavAccordion from './NavAccordion';
import { sideNavConfig } from '../../../constants/sideNavConfig';
import logo from '../../../assets/btma_logo.png';
import { Link } from 'react-router-dom';
import { DASHBOARD_URL } from '../../../constants/routerUrl';

const SideNav = () => {
  return (
    <Box p={2}>
      <Flex as="nav" p={3}>
        <Box color="white" ml={-2.5}>
          <Link to={DASHBOARD_URL}>
            <img src={logo} alt="btma-logo" width="100px" />
          </Link>
        </Box>
      </Flex>
      <VStack>
        <NavAccordion fields={sideNavConfig} />
      </VStack>
    </Box>
  );
};
export default SideNav;
