import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    fieldType: 'radio',
    colSpan: 4,
    name: 'applicable',
    label: 'Applicable?',
    choices: [
      { name: 'Yes', value: 'Yes' },
      { name: 'No', value: 'No' },
    ],
  },
];

export const bondedWarehouseApplicableFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label),
};

const BondedWarehouseApplicableSection = (props) => {
  return (
    <FormSection
      sectionName="Bonded Warehouse License"
      sectionColumn={4}
      formFields={fields}
      {...props}
    />
  );
};

export default BondedWarehouseApplicableSection;
