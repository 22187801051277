import axios from './axios';

export const getLabourData = async (data) => {
  try {
    const res = await axios.get('/labour/', { params: data });
    if (res.status === 200)
      return {
        data: {
          ...res.data,
          page: data?.page || 1,
          pageSize: data?.pageSize || 10,
        },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const downloadLabourData = async (data) => {
  try {
    const res = await axios.get(`/labour/download/${data}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getLabourDetails = async (labourId) => {
  try {
    const res = await axios.get(`/labour/${labourId}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const addLabourData = async (millId, data) => {
  try {
    const res = await axios.post('/labour/', { millId, labourData: data });
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const addNewLabour = async (data) => {
  try {
    const res = await axios.post('/labour/new', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res.status === 200) return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export const updateLabourData = async (data) => {
  try {
    const res = await axios.put(
      `/labour/${data?.labourId}/${data?.millId}/${data?.millUid}`,
      data?.applicationData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const deleteLabourData = async (data) => {
  try {
    const res = await axios.delete('/labour', { data });
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getLabourStats = async () => {
  try {
    const res = await axios.get('/labour/stats');
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};
