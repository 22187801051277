import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, Text, Select, Tooltip } from '@chakra-ui/react';

const SelectField = ({
  name,
  label,
  choices,
  control,
  onChange,
  isInvalid,
  isRequired,
  errorMessage,
}) => {
  const [labelTooltip, setLabelTooltip] = useState(false);
  const [errorTooltip, setErrorTooltip] = useState(false);

  useEffect(() => {
    setLabelTooltip(
      document?.getElementById(name)?.offsetWidth < document?.getElementById(name)?.scrollWidth,
    );
    setErrorTooltip(
      document?.getElementById(`${name}err`)?.offsetWidth <
        document?.getElementById(`${name}err`)?.scrollWidth,
    );
  });

  return (
    <Controller
      name={name}
      control={control}
      onChange={onChange}
      render={({ field: { onChange, value, name } }) => (
        <FormControl isInvalid={isInvalid}>
          <Tooltip label={labelTooltip ? label : ''} area-label="A Tooltip" placement="top-start">
            <Text
              mb={1.5}
              id={name}
              fontSize="sm"
              overflow="hidden"
              whiteSpace="nowrap"
              color="textSecondary"
              textOverflow="ellipsis">
              {isRequired ? <b style={{ color: '#E53E3E' }}>* </b> : null}
              {label}
            </Text>
          </Tooltip>
          <Select
            name={name}
            fontSize="md"
            value={value}
            bg="formInputBG"
            borderRadius="sm"
            onChange={onChange}
            colorScheme="primary"
            focusBorderColor="primary.300"
            placeholder="Select an option"
            h={['40px', '40px', '40px', '45px', '50px']}>
            {choices.map((c, index) => (
              <option
                style={{ backgroundColor: '#e9e4ff', fontSize: '18px' }}
                key={index}
                value={c.value}>
                {c.name}
              </option>
            ))}
          </Select>
          <Tooltip
            label={errorTooltip ? errorMessage : ''}
            area-label="A Tooltip"
            placement="bottom-start">
            <Text
              id={`${name}err`}
              mb={1.5}
              fontSize="xs"
              color="invalid"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis">
              {errorMessage}
            </Text>
          </Tooltip>
        </FormControl>
      )}
    />
  );
};
export default SelectField;
