import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'rawCotton',
    type: 'text',
    label: 'Raw Cotton',
  },
  {
    colSpan: 1,
    name: 'polyesterStapleFibre',
    type: 'text',
    label: 'Polyester Staple Fibre',
  },
  {
    colSpan: 1,
    name: 'viscoseStapleFibre',
    type: 'text',
    label: 'Viscose Staple Fibre',
  },
  {
    colSpan: 1,
    name: 'tencel',
    type: 'text',
    label: 'Tencel',
  },
  {
    colSpan: 1,
    name: 'flaxFibre',
    type: 'text',
    label: 'Flax Fibre',
  },
  {
    colSpan: 1,
    name: 'acrylicFibre',
    type: 'text',
    label: 'Acrylic Fibre',
  },
  {
    colSpan: 1,
    name: 'chips',
    type: 'text',
    label: 'Chips',
  },
  {
    colSpan: 1,
    name: 'petChips',
    type: 'text',
    label: 'Pet Chips',
  },
];

export const RawMaterialRequirementInfoFieldsValidation = {
  [fields[0].name]: yup.string(),
  [fields[1].name]: yup.string(),
  [fields[2].name]: yup.string(),
  [fields[3].name]: yup.string(),
  [fields[4].name]: yup.string(),
  [fields[5].name]: yup.string(),
  [fields[6].name]: yup.string(),
  [fields[7].name]: yup.string(),
};

const RawMaterialRequirementInfoSection = (props) => {
  return (
    <FormSection
      sectionName="Raw Material Requirement Information"
      sectionColumn={4}
      formFields={fields}
      {...props}
    />
  );
};

export default RawMaterialRequirementInfoSection;
