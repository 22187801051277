import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDisclosure } from '@chakra-ui/hooks';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../../snippets/Loader';
import DialogueBox from '../../../common/DialogueBox';
import DataTable from '../../../common/dataTable/DataTable';

import {
  MEMBERSHIP_APPLICATION_STATUS,
  LOGS_TABLE_DISPLAY_SIZE,
  BADGE_STATE_COLORS,
} from '../../../../constants/dataTableConstants';
import {
  FETCH_MEMBERSHIP_REQUEST,
  FETCH_MEMBERSHIP_REQUESTS,
} from '../../../../store/actions/actionTypes';
import { NEW_REQUEST_VERIFICATION_URL } from '../../../../constants/routerUrl';
import { membershipRequestStatus } from '../../../../constants/membershipConstants';
import { getMembershipRequests, patchMembershipData } from '../../../../api/membership';
import { Box } from '@chakra-ui/react';

const MembershipRequestTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState();
  const [loader, setLoader] = useState(false);
  const data = useSelector((state) => state.membership.membershipRequests);
  const { isOpen, onOpen, onClose } = useDisclosure(); //control hook for modal

  const columns = [
    {
      header: 'ID',
      accessor: 'applicationId',
    },
    {
      header: 'Date',
      accessor: 'createdAt',
      isDate: true,
    },
    {
      header: 'Mill Name',
      accessor: 'millName',
    },
    {
      header: 'Email',
      accessor: 'millEmail',
    },
    {
      header: 'Contact',
      accessor: 'millPhoneNo',
    },
    {
      header: 'Status',
      accessor: 'membershipStatus',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
    },
    {
      header: 'Action',
      accessor: 'membershipStatus',
      isButton: true,
      actionScheme: [
        { group: 'APPLIED', variant: 'read', action: 'VIEW' },
        { group: 'IN REVIEW', variant: 'read', action: 'VIEW' },
      ],
    },
  ];

  const handleView = ({ rowObject }) => {
    setRowData(rowObject);
    dispatch({ type: FETCH_MEMBERSHIP_REQUEST, payload: null });
    rowObject.membershipStatus !== membershipRequestStatus.INREVIEW
      ? onOpen()
      : history.push(`${NEW_REQUEST_VERIFICATION_URL}/${rowObject.applicationId}`);
  };

  const updateStatus = async () => {
    setLoader(true);
    const data = { param: rowData.id, status: membershipRequestStatus.INREVIEW };
    const response = await patchMembershipData(data);
    if (response.data?.status === 200) {
      setLoader(false);
      history.push(`${NEW_REQUEST_VERIFICATION_URL}/${rowData.applicationId}`);
    }
    setLoader(false);
  };

  const actionObject = {
    VIEW: handleView,
  };

  const handlePagination = async ({ page, pageSize, searchKey, selected, startDate, endDate }) => {
    dispatch({ type: FETCH_MEMBERSHIP_REQUESTS, payload: null });
    const params = {
      page,
      endDate,
      pageSize,
      searchKey,
      startDate,
      status: selected?.['Application Status'],
    };
    const res = await getMembershipRequests(params);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_MEMBERSHIP_REQUESTS, payload: res.data });
    } else {
      dispatch({ type: FETCH_MEMBERSHIP_REQUESTS, payload: { memRequests: [] } });
    }
  };

  const handleSearch = async ({ searchKey, selected, startDate, endDate }) => {
    dispatch({ type: FETCH_MEMBERSHIP_REQUESTS, payload: null });
    const params = { searchKey, status: selected?.['Application Status'], startDate, endDate };
    const res = await getMembershipRequests(params);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_MEMBERSHIP_REQUESTS, payload: res.data });
    } else {
      dispatch({ type: FETCH_MEMBERSHIP_REQUESTS, payload: { memRequests: [] } });
    }
  };

  return !loader ? (
    <Box>
      <DataTable
        topPanel
        dateRange
        keySearch
        isPaginated
        columns={columns}
        search={handleSearch}
        actions={actionObject}
        currentPage={data?.page}
        data={data?.memRequests}
        paginate={handlePagination}
        entries={data?.totalEntries}
        currentPageSize={data?.pageSize}
        caption="New Membership Requests"
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
        selectOptions={[MEMBERSHIP_APPLICATION_STATUS]}
      />
      <DialogueBox
        close={onClose}
        mountControl={isOpen}
        alertTitle="Start Review"
        performAction={updateStatus}
        alertMessage="Are you sure you want to start reviewing this membership request? Beginning review will update the application status to ‘IN REVIEW’"
      />
    </Box>
  ) : (
    <Loader />
  );
};

export default MembershipRequestTable;
