import React from 'react';
import ExcelExport from 'export-xlsx';
import { TiExport } from 'react-icons/ti';
import exportFromJSON from 'export-from-json';
import { AiOutlineFileDone } from 'react-icons/ai';
import { renderDate } from '../../../util/formatUtils';
import { Button, HStack, Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';

const FileExporter = ({ title, data }) => {
  const getHeaders = () => {
    let rawData = data;
    let headerArray = [];
    for (let key in rawData?.[0]) {
      headerArray.push({
        name: key?.toUpperCase(),
        key: key,
      });
    }
    return headerArray;
  };

  const SETTINGS_FOR_EXPORT = {
    fileName: `BTMA-${title}-Report-${renderDate(new Date())}`,
    workSheets: [
      {
        sheetName: 'Report',
        startingRowNumber: 1,
        tableSettings: {
          data: {
            importable: true,
            headerDefinition: getHeaders(),
          },
        },
      },
    ],
  };

  const handleExport = (format) => {
    switch (format) {
      case 'XLSX':
        new ExcelExport().downloadExcel(SETTINGS_FOR_EXPORT, [{ data }]);
        break;
      case 'CSV':
        exportFromJSON({
          data: data,
          fileName: `BTMA-${title}-Report-${renderDate(new Date())}`,
          exportType: exportFromJSON.types.csv,
        });
        break;
      case 'XLS':
        exportFromJSON({
          data: data,
          fileName: `BTMA-${title}-Report-${renderDate(new Date())}`,
          exportType: exportFromJSON.types.xls,
        });
        break;
      default:
        exportFromJSON({
          data: data,
          fileName: `BTMA-${title}-Report-${renderDate(new Date())}`,
          exportType: exportFromJSON.types.csv,
        });
        break;
    }
  };

  return (
    <HStack flex={1} justifyContent="flex-end">
      <Icon as={AiOutlineFileDone} w={10} h={10} color="#276749" />
      <Menu>
        <MenuButton as={Button} rightIcon={<Icon as={TiExport} w={5} h={5} />}>
          EXPORT as
        </MenuButton>
        <MenuList p={1} borderRadius="2px">
          <MenuItem isDisabled={data?.length < 1} onClick={() => handleExport('XLS')}>
            .xls
          </MenuItem>
          <MenuItem isDisabled={data?.length < 1} onClick={() => handleExport('XLSX')}>
            .xlsx
          </MenuItem>
          <MenuItem isDisabled={data?.length < 1} onClick={() => handleExport('CSV')}>
            .csv
          </MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  );
};

export default FileExporter;
