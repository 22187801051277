import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { renderDate } from '../../../util/formatUtils';

const PrintPreview = React.forwardRef(({ data }, ref) => (
  <Box w="100%" h="100%" m={0} p={0} ref={ref} position="relative">
    <Text fontSize="md" position="absolute" top="212px" left="170px" fontWeight="bold">
      {data?.certData?.masterLc}
    </Text>
    <Text fontSize="md" position="absolute" top="212px" left="570px" fontWeight="bold">
      {renderDate(data?.certData?.dateOfLc, 'numeric')}
    </Text>
    <Text fontSize="md" position="absolute" top="252px" left="350px" fontWeight="bold">
      {data?.certData?.totalQuantity}
    </Text>
    <Text fontSize="md" position="absolute" top="288px" left="230px" fontWeight="bold">
      {data?.certData?.btbLc}
    </Text>
    <Text fontSize="md" position="absolute" top="288px" left="580px" fontWeight="bold">
      {renderDate(data?.certData?.btbLcDate, 'numeric')}
    </Text>
    <Text fontSize="md" position="absolute" top="325px" left="270px" fontWeight="bold">
      {data?.certData?.bank}
    </Text>
    <Text fontSize="md" position="absolute" top="368px" left="270px" fontWeight="bold">
      {data?.certData?.exciseGatePassNum}
    </Text>
    <Text fontSize="md" position="absolute" top="368px" left="580px" fontWeight="bold">
      {renderDate(data?.certData?.exciseGatePassDate, 'numeric')}
    </Text>

    <Box position="absolute" top="445px" left="75px">
      {data?.certData?.productDetails?.length > 0 &&
        data?.certData?.productDetails?.map((item, index) => (
          <HStack key={index} w="620px" alignItems="flex-start">
            <Text w="26%" fontSize="xs">
              {item?.product}
            </Text>
            <Text w="26%" fontSize="xs">
              {item?.productQuantity}
            </Text>
            <Text w="19%" fontSize="11px" textAlign="center">
              {renderDate(item?.deliveryDate, 'numeric')}
            </Text>
            <Text w="29%" fontSize="11px">
              {item?.invoice}
              {item?.invoiceDate ? ', ' + renderDate(item?.invoiceDate, 'numeric') : null}
            </Text>
          </HStack>
        ))}
    </Box>
    <Text
      px="75px"
      maxW="100%"
      top="590px"
      fontSize="sm"
      lineHeight="1.25"
      fontWeight="bold"
      position="absolute">
      {data?.certData?.producedBy}
    </Text>
    <Text fontSize="sm" position="absolute" top="620px" left="300px" fontWeight="bold">
      {data?.certData?.videCertificateNo}
    </Text>
    <Text fontSize="sm" position="absolute" top="620px" left="600px" fontWeight="bold">
      {renderDate(data?.certData?.videCertificateDate, 'numeric')}
    </Text>
    <Text
      px="75px"
      maxW="100%"
      top="657px"
      fontSize="sm"
      lineHeight="1.25"
      fontWeight="bold"
      position="absolute"
      style={{ textIndent: '220px' }}>
      {data?.certData?.processedBy}
    </Text>
    <Text
      px="75px"
      maxW="100%"
      top="720px"
      fontSize="sm"
      lineHeight="1.25"
      fontWeight="bold"
      position="absolute"
      style={{ textIndent: '240px' }}>
      {data?.certData?.suppliedTo}
    </Text>
  </Box>
));

PrintPreview.displayName = 'PrintPreview';

export default PrintPreview;
