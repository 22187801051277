import React from 'react';
import { Stack, Text, UnorderedList, ListItem } from '@chakra-ui/react';

const InfoSection = ({ sectionName, infoFields }) => {
  return (
    <Stack p={4} backgroundColor="white" borderRadius="6px">
      <Text fontSize="20px" mb="3" fontWeight="bold">
        {sectionName}
      </Text>
      <UnorderedList pl={4}>
        {infoFields?.map((field, index) => (
          <ListItem key={index} fontSize="sm">
            <b>{field.header}:</b> {field?.value || 'N/A'}
          </ListItem>
        ))}
      </UnorderedList>
    </Stack>
  );
};

export default InfoSection;
