import React from 'react';
import FormSection from '../../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'proposerName',
    type: 'text',
    label: 'Name',
  },
  {
    colSpan: 1,
    name: 'proposerDesignation',
    type: 'text',
    label: 'Designation',
  },
  {
    colSpan: 1,
    name: 'proposerMillName',
    type: 'text',
    label: 'Mill Name',
  },
];

const ProposerInfo = (props) => {
  return (
    <FormSection
      sectionName="Proposer Information"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default ProposerInfo;
