import React from 'react';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'seconderName',
    type: 'text',
    label: 'Name',
  },
  {
    colSpan: 1,
    name: 'seconderDesignation',
    type: 'text',
    label: 'Designation',
  },
  {
    colSpan: 1,
    name: 'seconderMillName',
    type: 'text',
    label: 'Mill Name',
  },
];

const SeconderSection = (props) => {
  return (
    <FormSection sectionName="Seconder Info" sectionColumn={2} formFields={fields} {...props} />
  );
};

export default SeconderSection;
