import React, { useEffect } from 'react';
import StepsSection from '../StepsBar/Steps';
import { useHistory } from 'react-router-dom';
import { ACTIVE_MEMBERS } from '../../../../constants/routerUrl';

const AddNewMember = () => {
  const history = useHistory();

  useEffect(() => {
    document.title = 'BTMA - Membership';
  }, []);

  const backToTable = () => history.push(ACTIVE_MEMBERS);

  return <StepsSection backToTable={backToTable} />;
};

export default AddNewMember;
