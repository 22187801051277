import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    fieldType: 'select',
    name: 'extendFor',

    choices: [
      { name: `December ${new Date().getFullYear()}`, value: '6' },
      { name: `December ${new Date().getFullYear() + 1}`, value: '18' },
    ],
  },
];

export const membershipUptoFieldsValidation = {
  [fields[0].name]: yup.string().required().label('Upto'),
};

const MembershipUptoSection = (props) => {
  return (
    <FormSection secondary sectionName="Upto:" sectionColumn={1} formFields={fields} {...props} />
  );
};

export default MembershipUptoSection;
