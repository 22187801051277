import React from 'react';
import { Box, HStack, Button, Icon, useDisclosure, IconButton } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import DialogueBox from '../../common/DialogueBox';
import { logout } from '../../../api/auth';
import { logOut } from '../../../store/actions/authActions';
import { ROOT_URL } from '../../../constants/routerUrl';
import { SETTINGS_URL } from '../../../constants/routerUrl';
import { removeCurrentUserData } from '../../../util/localStorageUtils';
import { useDispatch, useSelector } from 'react-redux';
import { MdAccountCircle, MdSettings } from 'react-icons/md';

const LogoutAndSettings = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);

  const handleLogout = async () => {
    try {
      await logout();
      removeCurrentUserData();
      dispatch(logOut());
      history.push(ROOT_URL);
    } catch (error) {
      removeCurrentUserData();
      dispatch(logOut());
      history.push(ROOT_URL);
    }
  };

  const settings = async () => {
    history.push(SETTINGS_URL);
  };

  return (
    <Box h="100%">
      <HStack h="100%">
        <IconButton
          size="xs"
          onClick={settings}
          bg="white"
          color="textSecondary"
          isDisabled={
            user?.adminRole === 'ADMIN' ||
            user?.adminRole === 'OPERATIONS' ||
            user?.adminRole === 'FEE_COLLECTOR'
          }
          icon={<Icon as={MdSettings} w={5} h={5} />}
          _focus={{ boxShadow: 'none' }}
          _hover={{ color: 'black' }}
        />
        <Button
          leftIcon={<Icon as={MdAccountCircle} />}
          variant="logOut"
          h="80%"
          maxH="45px"
          minH="35px"
          onClick={onOpen}>
          Logout
        </Button>
      </HStack>
      <DialogueBox
        mountControl={isOpen}
        close={onClose}
        alertTitle="Logout"
        alertMessage="Are you sure you want to logout?"
        performAction={handleLogout}
      />
    </Box>
  );
};
export default LogoutAndSettings;
