import React from 'react';
import {
  Icon,
  Text,
  Image,
  Input,
  Button,
  HStack,
  Tooltip,
  FormControl,
  CloseButton,
  FormErrorMessage,
} from '@chakra-ui/react';
import { BiError } from 'react-icons/bi';
import { MdCloudUpload } from 'react-icons/md';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { SUPPORTED_FILE_FORMATS } from '../../../constants/fileConstant';

const FileInputField = ({
  type,
  name,
  label,
  watch,
  setValue,
  register,
  isInvalid,
  isRequired,
  defaultValue,
  errorMessage,
  defaultFiles,
}) => {
  const files = watch(name);

  return (
    <FormControl isInvalid={isInvalid}>
      <HStack>
        <Button
          as="label"
          minH="50px"
          maxW="460px"
          variant="ghost"
          cursor="pointer"
          borderRadius="sm"
          whiteSpace="normal"
          focusBorderColor="primary.300"
          leftIcon={<Icon as={MdCloudUpload} />}
          bg={(files && files[0]) || defaultFiles?.[name] ? 'formFilledBG' : 'formInputBG'}>
          <Input
            name={name}
            type={type}
            label={label}
            defaultValue={defaultValue}
            {...register(name)}
            hidden
          />
          {label}
          {isRequired ? <b style={{ color: '#E53E3E' }}> *</b> : null}
        </Button>

        {files && files[0] ? (
          <HStack>
            {files[0]?.type === 'application/pdf' ? (
              <HStack spacing={0}>
                <Icon as={AiOutlineFilePdf} w={7} h={7} color="invalid" pb={1} />
                <Text fontSize="xs" color="textSecondary" pr={2}>
                  {files[0]?.name}
                </Text>
                <CloseButton size="sm" onClick={() => setValue?.(name, null)} />
              </HStack>
            ) : SUPPORTED_FILE_FORMATS.includes(files[0]?.type) ? (
              <HStack spacing={0}>
                <Tooltip label={files[0]?.name} aria-label="A tooltip">
                  <Image h="50px" src={URL.createObjectURL(files[0] || files)} pr={2} />
                </Tooltip>
                <CloseButton size="sm" onClick={() => setValue?.(name, null)} />
              </HStack>
            ) : (
              <HStack spacing={0}>
                <Icon as={BiError} w={7} h={7} color="#F7B217" pb={1} />
                <Text fontSize="xs" color="textSecondary" pr={2}>
                  {files[0]?.name}
                </Text>
                <CloseButton size="sm" onClick={() => setValue?.(name, null)} />
              </HStack>
            )}
          </HStack>
        ) : defaultFiles ? (
          <HStack>
            {defaultFiles?.[name]?.split('.')?.[defaultFiles?.[name]?.split('.')?.length - 1] !==
            'pdf' ? (
              <Tooltip
                label={
                  decodeURIComponent(
                    defaultFiles?.[name]?.split('/')?.[
                      defaultFiles?.[name]?.split('/')?.length - 1
                    ],
                  ).split('-')?.[1]
                }
                aria-label="A tooltip">
                <Image h="50px" src={defaultFiles?.[name]} />
              </Tooltip>
            ) : (
              <HStack spacing={0}>
                <Icon as={AiOutlineFilePdf} w={7} h={7} color="invalid" pb={1} />
                <Text fontSize="xs" color="textSecondary">
                  {
                    decodeURIComponent(
                      defaultFiles?.[name]?.split('/')?.[
                        defaultFiles?.[name]?.split('/')?.length - 1
                      ],
                    ).split('-')?.[1]
                  }
                </Text>
              </HStack>
            )}
          </HStack>
        ) : null}
      </HStack>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
export default FileInputField;
