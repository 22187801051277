import { Box, Icon } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import FormButton from '../../common/FormButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  NEW_MARKET_EXPANSION_APPLY_URL,
  NEW_MARKET_EXPANSION_DETAILS_URL,
} from '../../../constants/routerUrl';
import { MdAdd } from 'react-icons/md';
import { BADGE_STATE_COLORS, LOGS_TABLE_DISPLAY_SIZE } from '../../../constants/dataTableConstants';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_NEW_MARKET_EXPANSION_APPLICATIONS } from '../../../store/actions/actionTypes';
import { getAllApplications } from '../../../api/certificates';
import DataTable from '../../common/dataTable/DataTable';

const NewMarketExpansion = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.certificate.newMarketExpansionApplications);

  useEffect(() => {
    document.title = 'BTMA - Certificates';
  }, []);

  const columns = [
    { header: 'Certificate No', accessor: 'certificateNum' },
    { header: 'Date', accessor: 'createdAt', isDate: true },
    { header: 'Mill Name', accessor: 'millName' },
    { header: 'Contact', accessor: 'phoneNo' },
    {
      header: 'Status',
      accessor: 'status',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
      hoverInfo: ['remarks'],
    },
    {
      header: 'Action',
      accessor: 'status',
      isButton: true,
      actionScheme: [{ group: 'APPROVED', variant: 'read', action: 'VIEW' }],
    },
  ];

  const handleView = ({ rowObject }) => {
    if (rowObject?.status === 'APPROVED') {
      history.push({
        pathname: NEW_MARKET_EXPANSION_DETAILS_URL,
        state: { applicationId: rowObject?.id },
      });
    }
  };

  const actionObject = {
    VIEW: handleView,
  };

  const applicationStatus = {
    name: 'Application Status',
    choices: [
      { name: 'Applied', value: 'APPLIED' },
      { name: 'Approved', value: 'APPROVED' },
    ],
  };

  const handlePagination = async ({ page, pageSize, searchKey, startDate, endDate, selected }) => {
    dispatch({ type: FETCH_NEW_MARKET_EXPANSION_APPLICATIONS, payload: null });
    const params = {
      page,
      endDate,
      pageSize,
      searchKey,
      startDate,
      status: selected?.['Application Status'],
    };
    const res = await getAllApplications({ type: 'MARKET_EXPANSION', params });
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_NEW_MARKET_EXPANSION_APPLICATIONS, payload: res.data });
    } else {
      dispatch({ type: FETCH_NEW_MARKET_EXPANSION_APPLICATIONS, payload: { certificates: [] } });
    }
  };

  const handleSearch = async ({ searchKey, startDate, endDate, selected }) => {
    dispatch({ type: FETCH_NEW_MARKET_EXPANSION_APPLICATIONS, payload: null });
    const params = {
      endDate,
      searchKey,
      startDate,
      status: selected?.['Application Status'],
    };
    const res = await getAllApplications({ type: 'MARKET_EXPANSION', params });
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_NEW_MARKET_EXPANSION_APPLICATIONS, payload: res.data });
    } else {
      dispatch({ type: FETCH_NEW_MARKET_EXPANSION_APPLICATIONS, payload: { certificates: [] } });
    }
  };

  return (
    <Box p={4}>
      <FormButton
        mt={0}
        mb={4}
        rightIcon={<Icon as={MdAdd} w={5} h={5} />}
        onClick={() => history.push(NEW_MARKET_EXPANSION_APPLY_URL)}>
        Assign New Market Expansion
      </FormButton>

      <DataTable
        topPanel
        keySearch
        dateRange
        isPaginated
        columns={columns}
        search={handleSearch}
        actions={actionObject}
        currentPage={data?.page}
        data={data?.certificates}
        paginate={handlePagination}
        entries={data?.totalEntries}
        currentPageSize={data?.pageSize}
        selectOptions={[applicationStatus]}
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
        caption="New Market Expansion Applications"
      />
    </Box>
  );
};

export default NewMarketExpansion;
