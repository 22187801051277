import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Box, HStack, Spinner, Text } from '@chakra-ui/react';
import {
  BADGE_STATE_COLORS,
  LOGS_TABLE_DISPLAY_SIZE,
} from '../../../../constants/dataTableConstants';
import { useDispatch, useSelector } from 'react-redux';
import { renderDate } from '../../../../util/formatUtils';
import DataTable from '../../../common/dataTable/DataTable';
import { getAllDutyCertPurchases } from '../../../../api/accounts';
import DutyExemptionDues from '../DutyExemptionDues/DutyExemptionDues';
import FileExporter from '../../../reports/commonSections/FileExporter';
import { FETCH_DUTY_CERT_PURCHASES } from '../../../../store/actions/actionTypes';
import { ACCOUNTS_CERTIFICATE_PURCHASE_URL } from '../../../../constants/routerUrl';

const DutyCertTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [reportData, setReportData] = useState();
  const data = useSelector((state) => state.accounts.dutyCertRequests);

  const columns = [
    { header: 'Invoice', accessor: 'orderId' },
    { header: 'Date', accessor: 'issueDate', isDate: true },
    { header: 'Certificate No', accessor: 'certificateNum' },
    { header: 'Mill Name', accessor: 'millName' },
    { header: 'Total', accessor: 'total', currency: '৳' },
    { header: 'Discount', accessor: 'discount', currency: '৳' },
    { header: 'Adjustments', accessor: 'adjustments', currency: '৳' },
    { header: 'Dues', accessor: 'dues', currency: '৳' },
    {
      header: 'Status',
      accessor: 'paymentStatus',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
    },
    // { header: 'Remarks', accessor: 'remarks' },
    {
      header: 'Action',
      accessor: 'paymentStatus',
      isButton: true,
      actionScheme: [
        { group: 'UNPAID', variant: 'read', action: 'VIEW' },
        { group: 'PAID', variant: 'read', action: 'VIEW' },
      ],
    },
  ];

  const handleView = ({ rowObject }) => {
    history.push({
      pathname: ACCOUNTS_CERTIFICATE_PURCHASE_URL,
      state: { orderId: rowObject?.orderId, certFor: 'duty' },
    });
  };

  const actionObject = {
    VIEW: handleView,
  };

  const PAYMENT_STATUS = {
    name: 'Payment Status',
    choices: [
      { name: 'PAID', value: 'PAID' },
      { name: 'UNPAID', value: 'UNPAID' },
    ],
  };

  const handlePagination = async ({ page, pageSize, searchKey, startDate, endDate, selected }) => {
    dispatch({ type: FETCH_DUTY_CERT_PURCHASES, payload: null });
    const params = {
      page,
      endDate,
      pageSize,
      searchKey,
      startDate,
      status: selected?.['Payment Status'],
    };
    const res = await getAllDutyCertPurchases(params);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_DUTY_CERT_PURCHASES, payload: res.data });
    } else {
      dispatch({ type: FETCH_DUTY_CERT_PURCHASES, payload: { purchases: [] } });
    }
  };

  const handleSearch = async ({ searchKey, startDate, endDate, selected }) => {
    dispatch({ type: FETCH_DUTY_CERT_PURCHASES, payload: null });
    setReportData();
    const params = {
      endDate,
      searchKey,
      startDate,
      status: selected?.['Payment Status'],
    };
    const res = await getAllDutyCertPurchases(params);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_DUTY_CERT_PURCHASES, payload: res.data });
      if (searchKey || startDate || endDate || selected?.['Payment Status']) {
        handleReportGeneration(params);
      } else {
        setReportData();
      }
    } else {
      dispatch({ type: FETCH_DUTY_CERT_PURCHASES, payload: { purchases: [] } });
      setReportData([]);
    }
  };

  const handleReportGeneration = async (params) => {
    setLoader(true);
    const res = await getAllDutyCertPurchases({ ...params, forReport: true });

    if (res.data?.status === 200) {
      const resultArray = res.data?.purchases || [];
      let convertedArray = resultArray?.map((item) => {
        const {
          orderId,
          issueDate,
          certificateNum,
          millName,
          total,
          discount,
          dues,
          paymentStatus,
          remarks,
        } = item;

        return {
          'invoice id': orderId || 'n/a',
          date: issueDate ? renderDate(issueDate, 'numeric') : 'n/a',
          'certificate no': certificateNum || 'n/a',
          'mill name': millName || 'n/a',
          total: total || 'n/a',
          discount: discount || 'n/a',
          dues: dues || 'n/a',
          status: paymentStatus || 'n/a',
          remarks: remarks || 'n/a',
        };
      });

      setReportData(convertedArray);
    }
    setLoader(false);
  };

  return (
    <Box>
      <DataTable
        topPanel
        keySearch
        dateRange
        isPaginated
        columns={columns}
        search={handleSearch}
        data={data?.purchases}
        actions={actionObject}
        currentPage={data?.page}
        paginate={handlePagination}
        entries={data?.totalEntries}
        selectOptions={[PAYMENT_STATUS]}
        currentPageSize={data?.pageSize}
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
        caption="Duty Exemption Certificates"
      />
      <HStack w="100%" mt={2} justifyContent="end">
        {loader && (
          <HStack>
            <Spinner size="md" thickness="2px" color="primary.700" emptyColor="primary.50" />
            <Text color="primary.700">Generating Report...</Text>
          </HStack>
        )}
        {reportData && reportData.length > 0 ? (
          <FileExporter title="Duty-Exemption-Certificates" data={reportData} />
        ) : null}
      </HStack>

      <DutyExemptionDues />
    </Box>
  );
};

export default DutyCertTable;
