import { Search2Icon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaUserClock } from 'react-icons/fa';
import { MdRefresh, MdSearch } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { getAllActionLogs } from '../../../api/actionLogs';
import { LOGS_TABLE_DISPLAY_SIZE } from '../../../constants/dataTableConstants';
import { FETCH_ACTION_LOGS } from '../../../store/actions/actionTypes';
import { formatDateSimple, isArrayAndHasContent } from '../../../util/formatUtils';
import DateRangePicker from '../../common/dataTable/DateRangePicker';
import Loader from '../../snippets/Loader';
import AccordionDetails from './AccordionDetails';
import CustomePagination from './CustomePagination';
import { actionbasedColors, fixedContext, moduleContextTree, sectionModuleTree } from './helpers';

const ActionLogs = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.settings.actionLogs);

  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedId, setSelectedId] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [action, setAction] = useState(null);
  const [section, setSection] = useState(null);
  const [module, setModule] = useState(null);
  const [context, setContext] = useState(null);
  const [dateRangeValidation, setDateRangeValidation] = useState(true);
  const [dateErrorMsg, setDateErrorMsg] = useState('');
  const [clearDateSelect, setClearDateSelect] = useState(false);

  /*temporary states*/
  const [tempAction, setTempAction] = useState(null);
  const [tempSection, setTempSection] = useState(null);
  const [tempModule, setTempModule] = useState(null);
  const [tempContext, setTempContext] = useState(null);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const [tempSearchKey, setTempSearchKey] = useState(null);

  const [moduleList, setModuleList] = useState([]);
  const [contextList, setContextList] = useState([]);

  useEffect(() => {
    if (!tempModule) {
      setContextList(fixedContext);
    }
  }, [tempModule]);

  const handleSectionChange = (e) => {
    if (e.target.value) {
      setTempModule(null);
      setTempContext(null);

      const selectedModules =
        sectionModuleTree.filter((section) => e.target.value == section.value)?.[0]?.modules || [];

      setModuleList(selectedModules);
      setTempSection(e.target.value);
    } else {
      setTempSection(null);
    }
  };

  const handleModuleChange = (e) => {
    if (e.target.value) {
      setTempContext(null);

      const selectedContexts =
        moduleContextTree.filter((module) => e.target.value == module.value)?.[0]?.contexts || [];

      setContextList(selectedContexts);
      setTempModule(e.target.value);
    } else {
      setTempModule(null);
    }
  };

  /* this formats the date to a format required for query (ex: 2021-03-21) */
  const formatDateForParams = (date) => {
    function join(t, a, s) {
      function format(m) {
        let f = new Intl.DateTimeFormat('en', m);
        return f.format(t);
      }
      return a.map(format).join(s);
    }

    let a = [{ year: 'numeric' }, { month: 'numeric' }, { day: 'numeric' }];
    return date ? join(new Date(date), a, '-') : '';
  };

  /* this performs search action upon date range validation */
  const handleSearch = (event) => {
    event?.preventDefault();
    setPage(1);
    if (dateRangeValidation) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
    }

    if (tempAction) {
      setAction(tempAction);
    }

    if (tempSection) {
      setSection(tempSection);
    }

    if (tempModule) {
      setModule(tempModule);
    }

    if (tempContext) {
      setContext(tempContext);
    }

    if (tempSearchKey) {
      setSearchKey(tempSearchKey);
    }
  };

  /* this formats the date range and assigns them to state */
  const handleDateRange = (data) => {
    setTempStartDate(formatDateForParams(data.startDate));
    setTempEndDate(formatDateForParams(data.endDate));
  };

  /* this resets all the search fields and sets table to default initial state */
  const clearSearchFields = () => {
    setPage(1);
    setTempSearchKey(null);
    setSearchKey('');
    setTempStartDate(null);
    setStartDate(null);
    setTempEndDate(null);
    setEndDate(null);
    setClearDateSelect(true);
    setTempAction(null);
    setAction(null);
    setTempSection(null);
    setSection(null);
    setTempModule(null);
    setModule(null);
    setTempContext(null);
    setContext(null);
    setDateErrorMsg('');
  };

  const fetchActionLogs = async ({ page, pageSize }) => {
    dispatch({ type: FETCH_ACTION_LOGS, payload: null });
    const params = {
      page,
      pageSize,
      searchKey,
      action,
      startDate,
      endDate,
      section,
      module,
      context,
    };
    setLoader(true);
    const res = await getAllActionLogs(params);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_ACTION_LOGS, payload: res?.data });
      setLoader(false);
    } else {
      dispatch({ type: FETCH_ACTION_LOGS, payload: { actionLogs: [] } });
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchActionLogs({ page, pageSize });
  }, [page, pageSize, searchKey, startDate, endDate, action, section, module, context]);

  if (loader) {
    return <Loader />;
  }

  return (
    <div>
      <Box bg="white" px={4} pt={2}>
        <HStack
          as="form"
          onSubmit={handleSearch}
          justifyContent="space-between"
          spacing={1}
          px={2}
          py={2}>
          <HStack spacing={1} justifyContent="flex-start">
            {/* <Tooltip label={'Select Action'} aria-label="A tooltip">
              <Select
                maxW="fit-content"
                variant="outline"
                borderRadius="2px"
                value={tempAction ? tempAction : ''}
                focusBorderColor="primary.300"
                placeholder={'Select Action'}
                onChange={(e) => {
                  if (e.target.value) {
                    setTempAction(e.target.value);
                  } else {
                    setTempAction(null);
                  }
                }}>
                {actiontypes?.map((choice) => (
                  <option key={choice?.value} value={choice?.value}>
                    {choice?.label}
                  </option>
                ))}
              </Select>
            </Tooltip> */}
            <Tooltip label={'Select Section'} aria-label="A tooltip">
              <Select
                maxW="fit-content"
                variant="outline"
                borderRadius="2px"
                value={tempSection ? tempSection : ''}
                focusBorderColor="primary.300"
                placeholder={'Select Section'}
                onChange={(e) => handleSectionChange(e)}>
                {isArrayAndHasContent(sectionModuleTree) ? (
                  sectionModuleTree?.map((choice) => (
                    <option key={choice?.value} value={choice?.value}>
                      {choice?.label}
                    </option>
                  ))
                ) : (
                  <option>No Sections</option>
                )}
              </Select>
            </Tooltip>
            <Tooltip label={'Select Module'} aria-label="A tooltip">
              <Select
                disabled={tempSection ? false : true}
                maxW="fit-content"
                variant="outline"
                borderRadius="2px"
                value={tempModule ? tempModule : ''}
                focusBorderColor="primary.300"
                placeholder={'Select Module'}
                onChange={(e) => handleModuleChange(e)}>
                {isArrayAndHasContent(moduleList)
                  ? moduleList?.map((choice) => (
                      <option key={choice?.value} value={choice?.value}>
                        {choice?.label}
                      </option>
                    ))
                  : null}
              </Select>
            </Tooltip>
            <Tooltip label={'Select Context'} aria-label="A tooltip">
              <Select
                //disabled={tempModule ? false : true}
                maxW="fit-content"
                variant="outline"
                borderRadius="2px"
                value={tempContext ? tempContext : ''}
                focusBorderColor="primary.300"
                placeholder={'Select Context'}
                onChange={(e) => setTempContext(e.target.value)}>
                {isArrayAndHasContent(contextList)
                  ? contextList?.map((choice) => (
                      <option key={choice?.value} value={choice?.value}>
                        {choice?.label}
                      </option>
                    ))
                  : null}
              </Select>
            </Tooltip>
          </HStack>

          <HStack spacing={1}>
            <InputGroup>
              <InputRightElement>
                <Icon as={MdSearch} w={6} h={6} />
              </InputRightElement>
              <Input
                variant="outline"
                borderRadius="2px"
                focusBorderColor="primary.300"
                value={tempSearchKey || ''}
                onChange={(e) => {
                  setTempSearchKey(e.target.value);
                }}
                placeholder="Search"
              />
            </InputGroup>
            <DateRangePicker
              spacing={1}
              data={(dateRange) => handleDateRange(dateRange)}
              validation={(isValid) => setDateRangeValidation(isValid)}
              error={(str) => setDateErrorMsg(str)}
              clearSelection={clearDateSelect}
              cleared={() => setClearDateSelect(false)}
            />

            <Tooltip label="Search" aria-label="A tooltip">
              <IconButton type="submit" variant="tableAction" icon={<Search2Icon size="24px" />} />
            </Tooltip>
            <Tooltip label="Reset table" aria-label="A tooltip">
              <IconButton
                variant="tableAction"
                icon={<MdRefresh size="24px" />}
                onClick={clearSearchFields}
              />
            </Tooltip>
          </HStack>
        </HStack>

        {dateErrorMsg ||
        action ||
        (startDate && endDate) ||
        section ||
        module ||
        context ||
        searchKey ? (
          <HStack justifyContent="space-between" alignItems="center" px={4}>
            <HStack space={2}>
              <Text color="GrayText" fontSize="12px" fontWeight={600}>
                Showing data for
              </Text>

              {searchKey ? (
                <Badge colorScheme={'purple'} fontSize="x-small" variant="subtle" p="1">
                  Search:{searchKey}
                </Badge>
              ) : null}

              {action ? (
                <Badge
                  colorScheme={
                    action == 'ADD'
                      ? 'green'
                      : action == 'UPDATE'
                      ? 'orange'
                      : action == 'DELETE'
                      ? 'red'
                      : 'blue'
                  }
                  fontSize="x-small"
                  variant="subtle"
                  p="1">
                  Action:{action}
                </Badge>
              ) : null}

              {section ? (
                <Badge colorScheme={'purple'} fontSize="x-small" variant="subtle" p="1">
                  section:{section}
                </Badge>
              ) : null}

              {module ? (
                <Badge colorScheme={'cyan'} fontSize="x-small" variant="subtle" p="1">
                  module:{module}
                </Badge>
              ) : null}

              {context ? (
                <Badge colorScheme={'orange'} fontSize="x-small" variant="subtle" p="1">
                  context:{context}
                </Badge>
              ) : null}

              {startDate && endDate ? (
                <Badge colorScheme="blue" variant="outline" fontSize="x-small">
                  From {startDate} to {endDate}
                </Badge>
              ) : null}
            </HStack>

            <Text color="invalid" fontSize="15px">
              {dateErrorMsg}
            </Text>
          </HStack>
        ) : (
          <></>
        )}
      </Box>
      {isArrayAndHasContent(data?.actionLogs) ? (
        <>
          <Box p="5">
            <div style={{ height: '65vh', overflowY: 'scroll' }}>
              <Accordion>
                {data?.actionLogs.map((log, index) => (
                  <AccordionItem key={index}>
                    <AccordionButton onClick={() => setSelectedId(log?.id)}>
                      <Box flex="1" textAlign="left" py={3}>
                        <Stack direction={['column', 'row']} spacing="24px">
                          <Box w="12%">
                            <Flex
                              justifyContent="flex-start"
                              alignItems="center"
                              style={{ gap: '2px' }}>
                              <div
                                style={{
                                  backgroundColor: actionbasedColors[log?.action],
                                  width: '10px',
                                  height: '10px',
                                  borderRadius: '50%',
                                }}></div>
                              <Text fontSize="xs" fontWeight={600} color="gray.500" px="2">
                                {formatDateSimple(log?.createdAt)}
                              </Text>
                            </Flex>
                          </Box>
                          <Box w="78%">
                            <Flex alignItems="center" style={{ gap: '10px' }}>
                              <Text fontSize="sm" fontWeight={600}>
                                {log?.message}
                              </Text>
                              {log?.certificateCount && (
                                <Badge variant="solid" colorScheme="green" px="2" size="sm">
                                  {log?.certificateCount}
                                </Badge>
                              )}
                            </Flex>
                          </Box>
                          <Box w="10%">
                            <Flex
                              justifyContent="flex-start"
                              alignItems="center"
                              style={{ gap: '10px' }}>
                              <FaUserClock />
                              <Text fontSize="xs" fontWeight={600}>
                                {log?.user?.username}
                              </Text>
                            </Flex>
                          </Box>
                        </Stack>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    {selectedId && (
                      <AccordionPanel pb={4} borderWidth="1px">
                        <AccordionDetails logId={selectedId} />
                      </AccordionPanel>
                    )}
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          </Box>
          <div>
            <CustomePagination
              page={page}
              setPage={setPage}
              entries={data?.totalEntries}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageSizes={LOGS_TABLE_DISPLAY_SIZE}
            />
          </div>
        </>
      ) : (
        <Flex justifyContent="center" alignItems="center" style={{ height: '70vh' }}>
          <Text fontWeight={600} fontSize="xl">
            {' '}
            No Logs Found
          </Text>
        </Flex>
      )}
    </div>
  );
};

export default ActionLogs;
