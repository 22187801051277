import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { renderDate } from '../../../../util/formatUtils';
import { notify } from '../../../../store/actions/globalActions';
import { updateRenewalReqStatus } from '../../../../api/accounts';
import { Box, Text, Button, HStack, Badge } from '@chakra-ui/react';
import { ACCOUNTS_MEMBERSHIP_MILL_URL } from '../../../../constants/routerUrl';
import { BADGE_STATE_COLORS } from '../../../../constants/dataTableConstants';

const RenewalRequest = ({ data, refresh, closeModal }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleReject = async () => {
    const res = await updateRenewalReqStatus({ uid: data?.requestId, status: 'REJECTED' });
    if (res.data?.status === 200) {
      refresh();
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      closeModal();
    }
  };

  const getBadgeColor = () => {
    return BADGE_STATE_COLORS.find((item) => item.group === data?.renewalStatus).color;
  };

  return (
    <Box my={2}>
      <HStack>
        <Button
          minW="120px"
          bg="#CFCCE6"
          height="40px"
          color="#150381"
          borderRadius="0px"
          _hover={{
            bg: '#CFCCE6',
            color: '#150381',
            borderBottom: '3px solid #150381',
          }}
          onClick={() => history.push(`${ACCOUNTS_MEMBERSHIP_MILL_URL}/${data?.millUid}`)}>
          Go to Transactions Page
        </Button>
        {data?.renewalStatus === 'PENDING' ? (
          <Button
            minW="120px"
            bg="#ECDBD9"
            height="40px"
            color="#9B4236"
            borderRadius="0px"
            onClick={handleReject}
            _focus={{ boxShadow: 'none' }}
            _hover={{ borderBottom: '3px solid #9B4236' }}>
            Reject Request
          </Button>
        ) : null}
      </HStack>
      <Box bg="secondary" mt={2} p={4} shadow="xs" overflow="auto">
        <Text fontSize="lg" fontWeight="bold">
          {data?.millName} has requested for membership renewal.
        </Text>
        <HStack>
          <Text fontWeight="bold">Requested on:</Text>
          <Text>{renderDate(data?.createdAt)}</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Request Id:</Text>
          <Text>{data?.requestId}</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Status:</Text>
          <Badge colorScheme={getBadgeColor()}>{data?.renewalStatus}</Badge>
        </HStack>
      </Box>
    </Box>
  );
};

export default RenewalRequest;
