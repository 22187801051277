import React, { useState } from 'react';
import _ from 'lodash';
import {
  Box,
  Icon,
  Stack,
  Image,
  HStack,
  CloseButton,
  useDisclosure,
  Text,
  VStack,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { MdEdit } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Loader from '../../snippets/Loader';
import { useHistory } from 'react-router-dom';
import Avatar from '../../../assets/avatar.png';
import FormButton from '../../common/FormButton';
import DialogueBox from '../../common/DialogueBox';
import LabourerInfoSection, {
  labourerInfoValidation,
} from '../addNewData/singleUploader/LabourerInfoSection';
import { notify } from '../../../store/actions/globalActions';
import { useYupValidationResolver } from '../../../util/yupUtils';
import { LABOUR_DATABASE_URL } from '../../../constants/routerUrl';
import { getLabourData, updateLabourData } from '../../../api/labour';
import { FETCH_LABOUR_DATA } from '../../../store/actions/actionTypes';
import LabourerImage, { imageFieldValidation } from '../addNewData/singleUploader/LabourerImage';
import { labourKeys } from '../../../constants/labourDatabaseConstant';
import { formatDate } from '../../../util/formatUtils';

const LabourPatchForm = ({ data, defaultFields }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [updateImage, setUpdateImage] = useState(false);
  const [applicationData, setApplicationData] = useState();

  const getValidationSchema = () => {
    return updateImage
      ? yup.object().shape(
          {
            ...imageFieldValidation,
            ...labourerInfoValidation,
          },
          ['nid', 'birthCertNum'],
        )
      : yup.object().shape(
          {
            ...labourerInfoValidation,
          },
          ['nid', 'birthCertNum'],
        );
  };

  const resolver = useYupValidationResolver(getValidationSchema());
  const {
    watch,
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: {
      ...defaultFields,
    },
  });

  const { employeeImage, ...rest } = watch();

  const onSubmit = (formData) => {
    let formdata = new FormData();
    for (let key in labourKeys) {
      labourKeys[key].dbKey === 'status'
        ? null
        : formdata.append(
            labourKeys[key].dbKey,
            labourKeys[key].isDate
              ? formatDate(formData?.[labourKeys[key].dbKey])
              : formData?.[labourKeys[key].dbKey],
          );
    }
    formdata.append('employeeImage', formData?.employeeImage?.[0]);
    setApplicationData(formdata);
    onOpen();
  };

  const confirmSubmit = async () => {
    const res = await updateLabourData({
      applicationData,
      labourId: data?.id,
      millId: data?.millId,
      millUid: data?.millUid,
    });
    if (res.data?.status == 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      const response = await getLabourData({ page: 1, pageSize: 10 });
      if (response.data) {
        dispatch({ type: FETCH_LABOUR_DATA, payload: response.data });
      }
      history.push(LABOUR_DATABASE_URL);
    }
  };

  return defaultFields ? (
    <>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <VStack bg="white" width="100%" p={4} borderRadius="sm">
          <Text mb={4} fontSize="1.4rem" color="gray.500">
            {defaultFields?.millName}
          </Text>
          {!updateImage ? (
            <Box
              shadow="md"
              w="fit-content"
              overflow="hidden"
              borderRadius="4px"
              position="relative">
              <Icon
                w={7}
                h={7}
                top="5px"
                right="5px"
                bg="white"
                shadow="xs"
                as={MdEdit}
                color="invalid"
                cursor="pointer"
                borderRadius="2px"
                position="absolute"
                onClick={() => setUpdateImage(true)}
              />
              <Image src={data?.employeeImage} fallbackSrc={Avatar} boxSize="160px" />
            </Box>
          ) : (
            <HStack w="fit-content" alignItems="flex-end" position="relative">
              <LabourerImage
                watch={watch}
                errors={errors}
                control={control}
                register={register}
                sectionName="Update Image"
              />
              <Box position="absolute" top="53px" right="-28px">
                <CloseButton
                  onClick={() => {
                    setUpdateImage(false);
                    reset({ employeeImage: undefined, ...rest });
                  }}
                />
              </Box>
            </HStack>
          )}

          <LabourerInfoSection register={register} control={control} errors={errors} />
        </VStack>

        <Stack bg="white" flexDir="column" alignItems="center" p={4}>
          <FormButton
            type="submit"
            isDisabled={
              _.isEqual(defaultFields, rest) && (!employeeImage || employeeImage?.length === 0)
            }
            isLoading={isSubmitting}>
            UPDATE
          </FormButton>
        </Stack>
      </Box>

      <DialogueBox
        close={onClose}
        alertTitle="Confirm"
        mountControl={isOpen}
        performAction={confirmSubmit}
        alertMessage="Are you sure you want to update information for this employee?"
      />
    </>
  ) : (
    <Loader />
  );
};

export default LabourPatchForm;
