import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, VStack, Text } from '@chakra-ui/react';
import NavPanel from '../common/NavPanel';
import AdminUsers from './adminUsers/AdminUsers';
import Pricing from './pricing/Pricing';
import { useSelector } from 'react-redux';
import RejectedCertificates from './rejectedCertificates/RejectedCertificates';
import ActionLogs from './actionLogs/ActionLogs';
import Notifications from './notifications/Notifications';

const Settings = () => {
  const [selected, setSelected] = useState(0);
  const [scroll, setScroll] = useState(false);
  const navList = ['Admin Users', 'Certificate Panel', 'Pricing', 'Action Logs', 'Notifications'];
  const user = useSelector((state) => state.auth.user);

  const history = useHistory();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const tabValue = queryParams.get('tab');

  const handleScroll = () => {
    window.scrollY > 10 ? setScroll(true) : setScroll(false);
  };

  window.addEventListener('scroll', handleScroll);

  // useEffect(() => {
  //   let currState = parseInt(localStorage.getItem('SETTINGS_TAB'));
  //   if (currState) {
  //     setSelected(currState);
  //   }
  //   return () => {
  //     setSelected(0);
  //   };
  // }, []);

  // const updateTab = (tabState) => {
  //   setSelected(tabState);
  //   localStorage.setItem('SETTINGS_TAB', tabState);
  // };

  useEffect(() => {
    if (!tabValue) {
      setSelected(0);
    } else {
      setSelected(parseInt(tabValue));
    }
  }, [tabValue]);

  const updateTab = (tabState) => {
    history.push({
      search: `?tab=${tabState}`,
    });
  };

  const renderContent = () => {
    switch (selected) {
      case 0:
        return (
          <Box p={4}>
            {user?.adminRole === 'SUPERADMIN' ? (
              <AdminUsers />
            ) : (
              <VStack w="100%" py={20}>
                <Text color="invalid" fontSize="lg">
                  You are not authorized to access this section!
                </Text>
              </VStack>
            )}
          </Box>
        );
      case 1:
        return (
          <Box p={4}>
            {user?.adminRole === 'SUPERADMIN' ? (
              <RejectedCertificates />
            ) : (
              <VStack w="100%" py={20}>
                <Text color="invalid" fontSize="lg">
                  You are not authorized to access this section!
                </Text>
              </VStack>
            )}
          </Box>
        );
      case 2:
        return <Pricing />;
      case 3:
        return <ActionLogs />;

      case 4:
        return (
          <Box p={4}>
            {user?.adminRole === 'SUPERADMIN' ? (
              <Notifications />
            ) : (
              <VStack w="100%" py={20}>
                <Text color="invalid" fontSize="lg">
                  You are not authorized to access this section!
                </Text>
              </VStack>
            )}
          </Box>
        );

      default:
        return (
          <Box p={4}>
            <AdminUsers />
          </Box>
        );
    }
  };

  return (
    <Box bg="secondary">
      <Box
        position="sticky"
        top={`${document?.getElementById('headerWrapper')?.offsetHeight}px`}
        right="0px"
        p={4}
        bg="secondary"
        zIndex={98}
        shadow={scroll ? 'sm' : 'none'}>
        <NavPanel navState={selected} setNavState={updateTab} navList={navList} />
      </Box>
      {renderContent()}
    </Box>
  );
};

export default Settings;
