const colors = {
  primary: {
    50: '#e9e4ff',
    100: '#beb3ff',
    200: '#9280ff',
    300: '#674dfe',
    400: '#3e1cfd',
    500: '#2503e4',
    600: '#1b01b2',
    700: '#130080',
    800: '#09004f',
    900: '#04001f',
  },
  secondary: '#F6F7FC',
  another: '#FF7400',
  text: '#000',
  textSecondary: '#6B7982',
  formInputBG: '#F8F8F8',
  formFilledBG: '#F3F1FE',
  formFocusBG: '#F7F6FF',
  invalid: '#E53E3E',
};

export default colors;
