import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Box, HStack, Grid, GridItem, Text, Button, useDisclosure } from '@chakra-ui/react';

//common components
import RenderModal from '../../RenderModal';
import InfoTiles from '../../../common/InfoTiles';
import FormButton from '../../../common/FormButton';
import FileUploader from '../../../common/FileUploader';
import DocumentGallery from '../../../common/membershipFormComponents/GallerySection';
import MembershipFormWrapper from '../../../common/membershipFormComponents/MembershipFormWrapper';

//form sections
import FabricsInfoSection, {
  fabricsInfoFieldsValidation,
} from '../Verification/FabricsInfoSection';
import DocumentSection, {
  documentInfoFieldsValidation,
} from '../../commonSections/DocumentSection';
import TowelOthersInfoSection, {
  towelOthersInfoFieldsValidation,
} from '../Verification/TowelOthersInfoSection';
import GeneralInfoSection, {
  cashGenInfoFieldsValidation,
} from '../../commonSections/GeneralInfoSection';
import CashAssistanceSection, {
  cashAssistanceFieldsValidation,
} from '../NewApplication/CashAssistanceSection';
import ProductInformation, {
  productInfoArrayFieldsValidation,
} from '../../commonSections/ProductInformation';
import MillInformationSection, {
  millInfoFieldsValidation,
} from '../../gsp/NewApplication/MillInformationSection';
import YarnInfoSection, { yarnInfoFieldsValidation } from '../Verification/YarnInfoSection';
import WRmgInfoSection, { wRmgInfoFieldsValidation } from '../Verification/WRmgInfoSection';
import KRmgInfoSection, { kRmgInfoFieldsValidation } from '../Verification/KRmgInfoSection';

//actions & api
import { notify } from '../../../../store/actions/globalActions';
import { getConversionRate, getConversionRates } from '../../../../api/currency';
import { patchCertDocs, updateCertificate, deleteCertificate } from '../../../../api/certificates';

//utils & constants
import _ from 'lodash';
import { formatDate } from '../../../../util/formatUtils';
import { CASH_CERT_URL } from '../../../../constants/routerUrl';
import { fileValidation, useYupValidationResolver } from '../../../../util/yupUtils';

const CashRecord = ({ defaultFields, defaultFiles, defaultDocs, millData, refresh }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const fileUploaderRef = useRef();
  const gallerySectionRef = useRef();
  const [modal, setModal] = useState();
  const [formData, setFormData] = useState();
  const [conversions, setConversions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [docSubmitting, setDocSubmitting] = useState(false);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    (async () => {
      const res = await getConversionRates();
      setConversions(res?.data);
    })();
  }, []);

  const millInfo = [
    { header: 'Mill Name', value: millData?.millName },
    { header: 'Membership No', value: millData?.membershipNo },
    { header: 'Membership Type', value: millData?.membershipType },
    { header: 'Contact', value: millData?.phoneNo },
  ];

  const commonValidations = {
    ...yarnInfoFieldsValidation,
    ...wRmgInfoFieldsValidation,
    ...kRmgInfoFieldsValidation,
    ...millInfoFieldsValidation,
    ...cashGenInfoFieldsValidation,
    ...fabricsInfoFieldsValidation,
    ...documentInfoFieldsValidation,
    ...towelOthersInfoFieldsValidation,
    ...productInfoArrayFieldsValidation,
  };

  const getValidationSchema = () => {
    switch (millData?.product) {
      case 'Yarn':
        return yup.object().shape({
          ...commonValidations,
          ...cashAssistanceFieldsValidation,
        });
      case 'Fabric':
        return yup.object().shape({
          ...commonValidations,
        });
      default:
        return yup.object().shape({
          ...commonValidations,
        });
    }
  };

  const resolver = useYupValidationResolver(getValidationSchema());

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: { ...defaultFields },
  });

  /* currency conversion codes */
  const yarnCurrency = useWatch({ control, name: 'yarnCurrency' });
  const fabricCurrency = useWatch({ control, name: 'fabricCurrency' });
  const wrmgCurrency = useWatch({ control, name: 'wrmgCurrency' });
  const krmgCurrency = useWatch({ control, name: 'krmgCurrency' });
  const towelCurrency = useWatch({ control, name: 'towelCurrency' });

  const yarnConversionRate = useWatch({ control, name: 'yarnConversionRate' });
  const fabricConversionRate = useWatch({ control, name: 'fabricConversionRate' });
  const wrmgConversionRate = useWatch({ control, name: 'wrmgConversionRate' });
  const krmgConversionRate = useWatch({ control, name: 'krmgConversionRate' });
  const towelConversionRate = useWatch({ control, name: 'towelConversionRate' });

  const yarnValue = useWatch({ control, name: 'yarnValue' });
  const fabricValue = useWatch({ control, name: 'fabricValue' });
  const wrmgValue = useWatch({ control, name: 'wrmgValue' });
  const krmgValue = useWatch({ control, name: 'krmgValue' });
  const towelValue = useWatch({ control, name: 'towelValue' });

  useEffect(() => {
    setValue('yarnConversionRate', getConversionRate(yarnCurrency, conversions));
  }, [yarnCurrency]);
  useEffect(() => {
    setValue('fabricConversionRate', getConversionRate(fabricCurrency, conversions));
  }, [fabricCurrency]);
  useEffect(() => {
    setValue('wrmgConversionRate', getConversionRate(wrmgCurrency, conversions));
  }, [wrmgCurrency]);
  useEffect(() => {
    setValue('krmgConversionRate', getConversionRate(krmgCurrency, conversions));
  }, [krmgCurrency]);
  useEffect(() => {
    setValue('towelConversionRate', getConversionRate(towelCurrency, conversions));
  }, [towelCurrency]);

  useEffect(() => {
    if (yarnValue && yarnCurrency && yarnConversionRate) {
      setValue(
        'yarnValueInTk',
        Math.ceil(
          parseFloat(parseFloat(yarnValue || 0) * parseFloat(yarnConversionRate)).toFixed(2),
        ),
      );
    } else {
      setValue('yarnValueInTk', parseFloat(yarnValue || 0).toFixed(2));
    }
  }, [yarnConversionRate, yarnValue]);
  useEffect(() => {
    if (fabricValue && fabricCurrency && fabricConversionRate) {
      setValue(
        'fabricValueInTk',
        Math.ceil(
          parseFloat(parseFloat(fabricValue || 0) * parseFloat(fabricConversionRate)).toFixed(2),
        ),
      );
    } else {
      setValue('fabricValueInTk', parseFloat(fabricValue || 0).toFixed(2));
    }
  }, [fabricConversionRate, fabricValue]);
  useEffect(() => {
    if (wrmgValue && wrmgCurrency && wrmgConversionRate) {
      setValue(
        'wrmgValueInTk',
        Math.ceil(
          parseFloat(parseFloat(wrmgValue || 0) * parseFloat(wrmgConversionRate)).toFixed(2),
        ),
      );
    } else {
      setValue('wrmgValueInTk', parseFloat(wrmgValue || 0).toFixed(2));
    }
  }, [wrmgConversionRate, wrmgValue]);
  useEffect(() => {
    if (krmgValue && krmgCurrency && krmgConversionRate) {
      setValue(
        'krmgValueInTk',
        Math.ceil(
          parseFloat(parseFloat(krmgValue || 0) * parseFloat(krmgConversionRate)).toFixed(2),
        ),
      );
    } else {
      setValue('krmgValueInTk', parseFloat(krmgValue || 0).toFixed(2));
    }
  }, [krmgConversionRate, krmgValue]);
  useEffect(() => {
    if (towelValue && towelCurrency && towelConversionRate) {
      setValue(
        'towelValueInTk',
        Math.ceil(
          parseFloat(parseFloat(towelValue || 0) * parseFloat(towelConversionRate)).toFixed(2),
        ),
      );
    } else {
      setValue('towelValueInTk', parseFloat(towelValue || 0).toFixed(2));
    }
  }, [towelConversionRate, towelValue]);
  /* currency conversion codes */

  useEffect(() => {
    setValue('yarnConversionRate', defaultFields?.yarnConversionRate);
    setValue('fabricConversionRate', defaultFields?.fabricConversionRate);
    setValue('wrmgConversionRate', defaultFields?.wrmgConversionRate);
    setValue('krmgConversionRate', defaultFields?.krmgConversionRate);
    setValue('towelConversionRate', defaultFields?.towelConversionRate);
  }, []);

  const formatProductDetails = (products) => {
    return products?.map((item) => ({
      ...item,
      deliveryDate: formatDate(item?.deliveryDate),
      invoiceDate: formatDate(item?.invoiceDate),
    }));
  };

  const onSubmit = (data) => {
    const { btbLcDate, dateOfLc, expiryDateOfLc, mushakDate, productDetails } = defaultFields;
    const cleanDefaultFields = {
      ...defaultFields,
      dateOfLc: new Date(dateOfLc),
      btbLcDate: new Date(btbLcDate),
      mushakDate: new Date(mushakDate),
      expiryDateOfLc: new Date(expiryDateOfLc),
      productDetails: productDetails.map((item) => ({
        ...item,
        invoiceDate: item?.invoiceDate ? new Date(item?.invoiceDate) : null,
        deliveryDate: item?.deliveryDate ? new Date(item?.deliveryDate) : null,
      })),
    };

    if (_.isEqual(data, cleanDefaultFields) === false) {
      let formdata = {
        ...data,
        dateOfLc: formatDate(data?.dateOfLc),
        btbLcDate: formatDate(data?.btbLcDate),
        issueDate: formatDate(data?.issueDate),
        mushakDate: formatDate(data?.mushakDate),
        bank: data?.bank + ',' + data?.bankBranch,
        expiryDateOfLc: formatDate(data?.expiryDateOfLc),
        productDetails: JSON.stringify(formatProductDetails(data?.productDetails)),
        btmaCashAssistanceCertForYarnDate: formatDate(data?.btmaCashAssistanceCertForYarnDate),
      };

      let normalizedFormData = _.mapKeys(formdata, (value, key) => {
        if (value) {
          return key;
        }
      });

      setFormData(normalizedFormData);
      setModal('UpdateAlert');
      onOpen();
    } else {
      setModal('NoUpdate');
      onOpen();
    }
  };

  const confirmUpdate = async () => {
    const res = await updateCertificate({
      type: 'production',
      id: millData?.certId,
      formdata: { ...formData, productType: millData?.product },
    });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      refresh();
    }
  };

  const handleDelete = () => {
    setModal('DELETE');
    onOpen();
  };

  const confirmDelete = () => {
    setModal('PASSWORD');
    onOpen();
  };

  const confirmPassword = async (password) => {
    setIsLoading(true);
    const res = await deleteCertificate({
      userId: user?.userId,
      password: password,
      certType: 'PRODUCTION_CASH',
      certNum: millData?.certificateNum,
    });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      history.push(CASH_CERT_URL);
    }
    setIsLoading(false);
  };

  /********** Doc Uploader **********/
  const fields = [
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'declarationOne',
      type: 'file',
      label: 'Declaration -1 (PDF/JPG/JPEG-Max 5mb)',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'declarationThree',
      type: 'file',
      label: 'Declaration -3 (PDF/JPG/JPEG-Max 5mb)',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'proformaInvoice',
      type: 'file',
      label: 'Proforma Invoice (PDF/JPG/JPEG-Max 5mb)',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'lcBankDocuments',
      type: 'file',
      label: 'L/C Bank Documents (PDF/JPG/JPEG-Max 5mb)',
    },
  ];

  const fieldsValidation = {
    [fields[0].name]: fileValidation.optional,
    [fields[1].name]: fileValidation.optional,
    [fields[2].name]: fileValidation.optional,
    [fields[3].name]: fileValidation.optional,
  };

  const handleFileQuery = async (docData) => {
    let formData = new FormData();

    const isFileUpdated = () => {
      let fileUpdateFlag = false;
      for (const key in docData) {
        if (docData?.[key]?.length > 0) {
          fileUpdateFlag = true;
        }
      }
      return fileUpdateFlag;
    };

    if (isFileUpdated()) {
      for (const key in docData) {
        if (docData?.[key]?.length > 0) {
          formData.append(key, docData[key][0]);
        }
      }

      const queryData = {
        docData: formData,
        certType: 'production',
        millId: millData?.millId,
        certId: millData?.certId,
        productType: millData?.product,
      };

      const res = await patchCertDocs(queryData);
      if (res.data?.status === 200) {
        gallerySectionRef.current.closeUploader();
        dispatch(
          notify({
            title: 'Success',
            description: res.data.msg,
            status: 'success',
            duration: 5000,
          }),
        );
        refresh();
      }
    } else {
      gallerySectionRef.current.closeUploader();
    }
  };
  /********** Doc Uploader **********/

  return (
    <Box>
      <Box p={4}>
        <InfoTiles sectionName="Mill Information" sectionColumn={4} infoFields={millInfo} />
      </Box>
      <MembershipFormWrapper
        formName={`${
          millData?.product === 'Yarn' ? 'Yarn' : millData?.product === 'Fabric' ? 'Fabric' : null
        } Export Management (Cash-Incentive)`}>
        <Box bg="white" p={4} borderRadius="4px" border="1px solid #3e1cfd">
          <HStack spacing={0}>
            <Text fontSize="20px" pr={1} fontWeight="bold" color="textSecondary">
              Certificate No:
            </Text>
            <Text fontSize="20px" color="primary.400">
              {millData?.certificateNum}
            </Text>
            <Text fontSize="20px" pr={1} pl={5} fontWeight="bold" color="textSecondary">
              Issue Date:
            </Text>
            <Text fontSize="20px" color="primary.400">
              {millData?.issueDate}
            </Text>
          </HStack>
        </Box>
        <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2}>
          <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
            <GeneralInfoSection type="CASH" control={control} register={register} errors={errors} />
          </GridItem>
          <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
            <DocumentSection type="CASH" control={control} register={register} errors={errors} />
            {millData?.product === 'Yarn' ? (
              <CashAssistanceSection control={control} register={register} errors={errors} />
            ) : null}
          </GridItem>
          <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
            <ProductInformation control={control} register={register} errors={errors} />
          </GridItem>
          <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
            <MillInformationSection control={control} register={register} errors={errors} />
          </GridItem>
          <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
            <YarnInfoSection control={control} register={register} errors={errors} />
            <FabricsInfoSection control={control} register={register} errors={errors} />
            <TowelOthersInfoSection control={control} register={register} errors={errors} />
            <WRmgInfoSection control={control} register={register} errors={errors} />
            <KRmgInfoSection control={control} register={register} errors={errors} />
          </GridItem>
          <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
            <DocumentGallery
              update
              ref={gallerySectionRef}
              isLoading={docSubmitting}
              galleryFields={defaultFiles}
              sectionName="Additional Documents"
              handleDocUpdate={() => fileUploaderRef.current.submit()}
              uploaderComponent={
                <FileUploader
                  fields={fields}
                  ref={fileUploaderRef}
                  documents={defaultDocs}
                  handleQuery={handleFileQuery}
                  submitting={setDocSubmitting}
                  fieldsValidation={fieldsValidation}
                />
              }
            />
          </GridItem>
        </Grid>
        <HStack justifyContent="end" alignItems="center" mt={4}>
          <Button
            bg="red.500"
            minW="200px"
            onClick={handleDelete}
            _hover={{ bg: 'red.400' }}
            _focus={{ boxShadow: 'none' }}
            h={['45px', '50px', '50px', '55px', '55px']}>
            DELETE
          </Button>
          <FormButton
            mt={0}
            isLoading={isSubmitting}
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onSubmit)();
            }}>
            UPDATE
          </FormButton>
        </HStack>
      </MembershipFormWrapper>
      <RenderModal
        modal={modal}
        isOpen={isOpen}
        onClose={onClose}
        isSubmitting={isLoading}
        confirmDelete={confirmDelete}
        confirmUpdate={confirmUpdate}
        confirmPassword={confirmPassword}
      />
    </Box>
  );
};

export default CashRecord;
