import React from 'react';

import { Input, FormControl, FormErrorMessage, Text } from '@chakra-ui/react';

const CertificateNum = ({ label, setValue, placeholder, error }) => {
  return (
    <FormControl isInvalid={error}>
      <Text mb="5px" fontSize="md" color="textSecondary">
        {label}
      </Text>
      <Input
        mt="1px"
        fontSize="lg"
        variant="outline"
        bg="white"
        h="40px"
        focusBorderColor="primary.300"
        borderRadius="2px"
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default CertificateNum;
