import React from 'react';
import { Box } from '@chakra-ui/react';
import DataTable from '../../../common/dataTable/DataTable';

const GuideLinesTable = () => {
  const columns = [
    {
      header: 'S/L No',
      accessor: 'serial',
    },
    {
      header: 'Field',
      accessor: 'fieldName',
    },
    {
      header: 'Type',
      accessor: 'fieldType',
    },
    {
      header: 'Validation',
      accessor: 'validation',
    },
    {
      header: 'Mandatory?',
      accessor: 'required',
    },
  ];

  const data = [
    {
      serial: 1,
      required: 'NO',
      fieldType: 'TEXT',
      fieldName: 'Employee-ID',
      validation: 'should be an unique ID',
    },
    {
      serial: 2,
      required: 'YES',
      fieldType: 'TEXT',
      fieldName: 'Name',
      validation: 'should use at least 3 characters',
    },
    {
      serial: 3,
      fieldName: 'NID',
      fieldType: 'NUMBER',
      validation: 'must be a valid number',
      required: 'YES (if birth certificate is not available)',
    },
    {
      serial: 4,
      fieldType: 'NUMBER',
      fieldName: 'Birth-Certificate',
      required: 'YES (if NID is not available)',
      validation: 'must be a valid number',
    },
    {
      serial: 5,
      required: 'YES',
      fieldType: 'DATE',
      fieldName: 'Date-of-Birth',
      validation: 'must be in DD/MM/YYYY or DD-MM-YYYY or DD.MM.YYYY format',
    },
    {
      serial: 6,
      required: 'YES',
      fieldType: 'TEXT',
      fieldName: 'Gender',
      validation: 'must be one of (male, female, other)',
    },
    {
      serial: 7,
      required: 'NO',
      fieldType: 'TEXT',
      fieldName: 'Father-Name',
    },
    {
      serial: 8,
      required: 'NO',
      fieldType: 'TEXT',
      fieldName: 'Mother-Name',
    },
    {
      serial: 9,
      required: 'NO',
      fieldType: 'TEXT',
      fieldName: 'Department',
    },
    {
      serial: 10,
      required: 'NO',
      fieldType: 'TEXT',
      fieldName: 'Designation',
    },
    {
      serial: 11,
      required: 'NO',
      fieldType: 'DATE',
      fieldName: 'Joining-Date',
      validation: 'must be in DD/MM/YYYY or DD-MM-YYYY or DD.MM.YYYY format',
    },
    {
      serial: 12,
      required: 'NO',
      fieldType: 'DATE',
      fieldName: 'Departure-Date',
      validation: 'must be in DD/MM/YYYY or DD-MM-YYYY or DD.MM.YYYY format',
    },
    {
      serial: 13,
      required: 'YES',
      fieldType: 'NUMBER',
      fieldName: 'Contact-Number',
      validation: 'must be a valid phone number',
    },
    {
      serial: 14,
      required: 'NO',
      fieldType: 'TEXT',
      fieldName: 'Present-Address',
    },
    {
      serial: 15,
      required: 'NO',
      fieldType: 'TEXT',
      fieldName: 'Insurance-Coverage',
      validation: '',
    },
    { serial: 16, fieldName: 'Name-of-Nominee', fieldType: 'TEXT', required: 'NO' },
    { serial: 17, fieldName: 'Relationship-With-Employee', fieldType: 'TEXT', required: 'NO' },
    {
      serial: 18,
      required: 'NO',
      fieldType: 'TEXT',
      fieldName: 'Status',
      validation: 'must be one of (active, inactive)',
    },
  ];

  return (
    <Box mt={4}>
      <DataTable caption="Guidelines for uploading CSV file" columns={columns} data={data} />
    </Box>
  );
};

export default GuideLinesTable;
