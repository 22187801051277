import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Grid, GridItem, Box, VStack, useDisclosure } from '@chakra-ui/react';

import Password from './Password';
import FormButton from '../../common/FormButton';
import DialogueBox from '../../common/DialogueBox';
import UserInfoSection, { fieldsValidation } from './UserInfoSection';

import { createAdminUser } from '../../../api/users';
import { notify } from '../../../store/actions/globalActions';
import { useYupValidationResolver } from '../../../util/yupUtils';
import { PASSWORD_REGEX } from '../../../constants/regex';
import { minPassLengthMap } from '../../../constants/authConstant';

const AddUserForm = ({ closeModal, refresh }) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const resolver = useYupValidationResolver(
    yup.object().shape({
      ...fieldsValidation,
    }),
  );

  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
  });

  const { adminRole, password, rePassword } = watch();

  const onSubmit = (data) => {
    // eslint-disable-next-line no-unused-vars
    const { rePassword, ...rest } = data;
    setUserData(rest);
    onOpen();
  };

  const confirmSubmit = async () => {
    const res = await createAdminUser(userData);
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 1500,
        }),
      );
      refresh();
      closeModal();
    }
  };

  return (
    <Box as="form" w="500px" onSubmit={handleSubmit(onSubmit)}>
      <UserInfoSection register={register} control={control} errors={errors} />
      <Grid templateColumns="repeat(2,1fr)" gap={3} mx={3}>
        <GridItem>
          <Password
            isRequired
            name="password"
            label="Password"
            errors={errors}
            control={control}
            register={register}
            placeholder="Enter password"
            customError={
              password && PASSWORD_REGEX(minPassLengthMap[adminRole] || 12).test(password)
                ? ''
                : `Password must be ${
                    minPassLengthMap[adminRole] || 12
                  } to 64 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&)`
            }
          />
        </GridItem>
        <GridItem>
          <Password
            isRequired
            errors={errors}
            name="rePassword"
            control={control}
            register={register}
            label="Confirm Password"
            placeholder="Re-enter password"
            customError={
              password && rePassword && password !== rePassword ? 'passwords do not match!' : ''
            }
          />
        </GridItem>
      </Grid>

      <VStack>
        <FormButton
          type="submit"
          isLoading={isSubmitting}
          isDisabled={
            (password && rePassword && password !== rePassword) ||
            (password !== '' && password?.length < 6)
          }>
          SUBMIT
        </FormButton>
      </VStack>
      <DialogueBox
        close={onClose}
        alertTitle="Confirm"
        mountControl={isOpen}
        performAction={confirmSubmit}
        alertMessage="Are you sure you want to create this user?"
      />
    </Box>
  );
};

export default AddUserForm;
