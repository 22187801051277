import React from 'react';
import { Box } from '@chakra-ui/react';
import SearchSection from './Sections/SearchSection';
import MillArrearsSection from './Sections/MillArrearsSection';

const Transaction = () => {
  return (
    <Box>
      <SearchSection />
      <MillArrearsSection />
    </Box>
  );
};

export default Transaction;
