import {
  FETCH_MEMBERSHIP_REQUESTS,
  FETCH_MEMBERSHIP_REQUEST,
  FETCH_MEMBERS,
  REVOKE_MEMBERS,
  FETCH_INFO_UPDATE_REQUESTS,
  FETCH_MILL_USERS,
  FETCH_MILL_REPRESENTATIVE,
} from '../actions/actionTypes';

const initialState = {
  membershipRequests: null,
  currentRequest: null,
  members: null,
  millUsers: null,
  millRepresentatives: null,
  revokeList: null,
  updateRequests: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_MEMBERSHIP_REQUESTS:
      return {
        ...state,
        membershipRequests: action.payload,
      };
    case FETCH_MEMBERSHIP_REQUEST:
      return {
        ...state,
        currentRequest: action.payload,
      };
    case FETCH_MEMBERS:
      return {
        ...state,
        members: action.payload,
      };
    case FETCH_MILL_USERS:
      return {
        ...state,
        millUsers: action.payload,
      };
    case FETCH_MILL_REPRESENTATIVE:
      return {
        ...state,
        millRepresentatives: action.payload,
      };
    case REVOKE_MEMBERS:
      return {
        ...state,
        revokeList: action.payload,
      };
    case FETCH_INFO_UPDATE_REQUESTS:
      return {
        ...state,
        updateRequests: action.payload,
      };
    default:
      return state;
  }
}
