import { Badge, Box, Flex, Grid, Text } from '@chakra-ui/react';
import React from 'react';
import { formatDateSimple } from '../../../../util/formatUtils';

import { MdEmail, MdPhone } from 'react-icons/md';
import { FaBusinessTime, FaUser } from 'react-icons/fa';

const getActionTypeBadgeColor = {
  ADD: 'green',
  DELETE: 'red',
  UPDATE: 'orange',
};

const ActionDetailsBox = ({ logDetails }) => {
  return (
    <Box minH="100%" border="1px solid #e1e1e1" p="1em">
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        {logDetails?.action && (
          <Flex style={{ width: '100%', gap: '10px' }}>
            <Text fontSize="xs" fontWeight={600}>
              Action Type:
            </Text>
            <Badge colorScheme={getActionTypeBadgeColor[logDetails?.action]} variant="solid">
              {logDetails?.action}
            </Badge>
          </Flex>
        )}
        {logDetails?.adminInfo?.adminRole && (
          <Flex style={{ width: '100%', gap: '10px' }}>
            <Badge variant="solid" colorScheme="blue">
              {logDetails?.adminInfo?.adminRole?.replace(/_/g, ' ')}
            </Badge>
          </Flex>
        )}

        {logDetails?.section && (
          <Flex style={{ width: '100%', gap: '10px' }}>
            <Text fontSize="xs" fontWeight={600}>
              Section:
            </Text>
            <Text fontSize="xs" fontWeight={600}>
              {logDetails?.section?.toUpperCase()}
            </Text>
          </Flex>
        )}

        {logDetails?.adminInfo?.fullName && (
          <Flex style={{ width: '100%', gap: '10px' }}>
            <FaUser />
            <Text fontSize="xs" fontWeight={600}>
              {logDetails?.adminInfo?.fullName}
            </Text>
          </Flex>
        )}

        {logDetails?.module && (
          <Flex style={{ width: '100%', gap: '10px' }}>
            <Text fontSize="xs" fontWeight={600}>
              Module:
            </Text>
            <Text fontSize="xs" fontWeight={600}>
              {logDetails?.module?.toUpperCase()}
            </Text>
          </Flex>
        )}

        {logDetails?.user?.phoneNo && (
          <Flex style={{ width: '100%', gap: '10px' }}>
            <MdPhone />
            <Text fontSize="xs" fontWeight={600}>
              {logDetails?.user?.phoneNo}
            </Text>
          </Flex>
        )}

        {logDetails?.context && (
          <Flex style={{ width: '100%', gap: '10px' }}>
            <Text fontSize="xs" fontWeight={600}>
              Context:
            </Text>
            <Text fontSize="xs" fontWeight={600}>
              {logDetails?.context}
            </Text>
          </Flex>
        )}

        {logDetails?.user?.email && (
          <Flex style={{ width: '100%', gap: '10px' }}>
            <MdEmail />
            <Text fontSize="xs" fontWeight={600}>
              {logDetails?.user?.email}
            </Text>
          </Flex>
        )}

        {logDetails?.actionDescription && (
          <Flex style={{ width: '100%', gap: '10px' }}>
            <Text fontSize="xs" fontWeight={600}>
              Action:
            </Text>
            <Text fontSize="xs" fontWeight={600}>
              {logDetails?.actionDescription}
            </Text>
          </Flex>
        )}

        {logDetails?.createdAt && (
          <Flex style={{ width: '100%', gap: '10px' }}>
            <FaBusinessTime />
            <Text fontSize="xs" fontWeight={600} color="whatsapp.600">
              {formatDateSimple(logDetails?.createdAt)}
            </Text>
          </Flex>
        )}

        {logDetails?.remarks && (
          <Flex style={{ width: '100%', gap: '10px' }}>
            <Text fontSize="xs" fontWeight={600}>
              Remarks:
            </Text>
            <Text fontSize="xs" fontWeight={600}>
              {logDetails?.remarks}
            </Text>
          </Flex>
        )}
      </Grid>
    </Box>
  );
};

export default ActionDetailsBox;
