import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { dateValidation } from '../../../../util/yupUtils';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    fieldType: 'date',
    name: 'issueDate',
    label: 'Issue Date',
    isRequired: true,
  },
  {
    colSpan: 1,
    type: 'text',
    name: 'certificateNumber',
    label: 'Certificate No',
    isRequired: true,
  },
  {
    colSpan: 1,
    type: 'text',
    name: 'salesContactNumber',
    label: 'Sales Contact No',
    isRequired: true,
  },
  {
    colSpan: 1,
    fieldType: 'date',
    name: 'salesContactDate',
    label: 'Sales Contact Date',
    isRequired: true,
  },
  {
    colSpan: 1,
    type: 'text',
    name: 'foreignBuyerNameAndAddress',
    label: 'Foreign Buyer Name & Address',
    isRequired: true,
  },
  {
    colSpan: 1,
    type: 'text',
    name: 'buyerBankNameAndAddress',
    label: 'Buyer Bank Name & Address',
    isRequired: true,
  },
];

export const applicationInfoFieldsForMarketExpansionValidation = {
  [fields[0].name]: dateValidation.required().label(fields[0].label),
  [fields[1].name]: yup.string().required().label(fields[1].label),
  [fields[2].name]: yup.string().required().label(fields[2].label),
  [fields[3].name]: dateValidation.required().label(fields[3].label),
  [fields[4].name]: yup.string().required().label(fields[4].label),
  [fields[5].name]: yup.string().required().label(fields[5].label),
};

const ApplicationInfoSectionForMarketExpansion = (props) => {
  const [selectedFields, setSelectedFileds] = useState(fields);
  const { isCertificateDisabled } = props;

  useEffect(() => {
    //here if the isCertificateDisabled is true then we need to disable the certificate number field
    if (isCertificateDisabled === true) {
      setSelectedFileds((prev) => {
        return prev.map((item) => {
          if (item.name === 'certificateNumber') {
            item.isDisabled = true;
          }
          return item;
        });
      });
    } else {
      //go to the item and remove item.isDisabled
      setSelectedFileds((prev) => {
        return prev.map((item) => {
          if (item.name === 'certificateNumber') {
            item.isDisabled = false;
          }
          return item;
        });
      });
    }
  }, [props]);

  return (
    <FormSection
      sectionName="Application Information"
      sectionColumn={2}
      formFields={selectedFields}
      {...props}
    />
  );
};

export default ApplicationInfoSectionForMarketExpansion;
