// axios
import axios from 'axios';

// local storage utils
import { removeCurrentUserData, getAccessToken, setAccessToken } from '../util/localStorageUtils';

// redux
import store from '../store/store';
import { logOut } from '../store/actions/authActions';
import { ERROR } from '../constants/toastConstants';
import { notify } from '../store/actions/globalActions';

// get base url from environtment
const baseURL = process.env.REACT_APP_API_BASEURL;

// create axios instance
const instance = axios.create({
  baseURL,
});

//request interceptor to add the auth token header to requests
instance.interceptors.request.use(
  (config) => {
    // checking if the access token exist in the localStorage
    const accessToken = getAccessToken();
    config.headers = accessToken
      ? {
          'Content-Type': config.headers['Content-Type']
            ? config.headers['Content-Type']
            : 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'ngrok-skip-browser-warning': 'true',
        }
      : {
          'Content-Type': config.headers['Content-Type']
            ? config.headers['Content-Type']
            : 'application/json',
          'ngrok-skip-browser-warning': 'true',
        };
    config.withCredentials = true;
    return config;
  },
  (error) => {
    if (error.response) {
      store.dispatch(
        notify({
          title: 'Error!',
          status: ERROR,
          description: error.response.data?.msg || 'Something went wrong, please contact support!',
        }),
      );
      console.error(error.response);
    } else if (error.request) {
      store.dispatch(
        notify({
          title: 'Error!',
          status: ERROR,
          description: 'Could not fetch data from server! Please check your internet connection.',
        }),
      );
    } else {
      store.dispatch(
        notify({
          title: 'Error!',
          status: ERROR,
          description: 'Unknown error occured!',
        }),
      );
    }
    return Promise.reject(error);
  },
);

//response interceptor to refresh token on receiving token expired error
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // https://github.com/axios/axios#handling-errors
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true; // making sure that the refresh api gets called just once

      return instance.get('/auth/refresh').then((res) => {
        if (res.status === 200) {
          // setting the new accessToken found to local storage
          const { accessToken } = res.data;
          setAccessToken(accessToken);
          // returning the original request
          return instance(originalRequest);
        }
      });
    }
    if (error.response.status === 401) {
      // logging user out
      removeCurrentUserData();
      store.dispatch(logOut());
    }
    if (error.response) {
      store.dispatch(
        notify({
          title: 'Error!',
          status: ERROR,
          description: error.response.data?.msg || 'Something went wrong, please contact support!',
        }),
      );
    } else if (error.request) {
      store.dispatch(
        notify({
          title: 'Error!',
          status: ERROR,
          description: 'Could not fetch data from server! Please check your internet connection.',
        }),
      );
    } else {
      store.dispatch(
        notify({
          title: 'Error!',
          status: ERROR,
          description: 'Unknown error occured!',
        }),
      );
    }
    return Promise.reject(error);
  },
);

export default instance;
