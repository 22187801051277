export const PHONE_NO_REGEX = /^(?:\+880|880|0)((1[3-9]\d{8})|([2-9]\d{6,8}))$/;
export const USERNAME_REGEX = /^(?=.{8,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/;
export const FULL_NAME_REGEX = /^[a-z A-Z]+$/;
export const WEBSITE_REGEX =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
// export const NID_NO_REGEX = /^(\d{10}|\d{13}|\d{17})$/;
export const NID_NO_REGEX = /^\d+$/;
export const BIRTH_CERTIFICATE_REGEX = /^(\d{16}|\d{17})$/;

/* password: min 12 chars, max 64 chars, one lowercase letter, one uppercase letter, one number, special chars (optional) */
export const PASSWORD_REGEX = (len) => {
  switch (len) {
    case 12:
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{12,64}$/;
    default:
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{10,64}$/;
  }
};
