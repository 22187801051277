import React from 'react';
import * as yup from 'yup';
import FormSection from '../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'certType',
    fieldType: 'select',
    label: 'Certificate type',
    choices: [
      { name: 'Production', value: 'PRODUCTION' },
      { name: 'Processing', value: 'PROCESSING' },
      { name: 'Duty-Exemption', value: 'DUTY' },
    ],
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'trxId',
    type: 'text',
    label: 'Transaction Id',
    isRequired: true,
  },
];

export const receiptFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup.string().required().label(fields[1].label),
};

const ReceiptInfoSection = (props) => {
  return (
    <FormSection sectionName="Select Receipt" sectionColumn={2} formFields={fields} {...props} />
  );
};

export default ReceiptInfoSection;
