import { PRE_LOG_IN, LOG_IN, LOG_OUT, SET_STATS } from '../actions/actionTypes';

const initialState = {
  isLoggedIn: false,
  user: null,
  tempUser: null,
  stats: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PRE_LOG_IN: {
      const { isLoggedIn, user } = action.payload;
      return {
        isLoggedIn: isLoggedIn,
        tempUser: user,
      };
    }

    case LOG_IN: {
      const { isLoggedIn, user } = action.payload;
      return {
        ...state,
        tempUser: null,
        isLoggedIn: isLoggedIn,
        user: user,
      };
    }

    case LOG_OUT: {
      const { isLoggedIn } = action.payload;
      return {
        isLoggedIn: isLoggedIn,
        user: null,
        stats: null,
      };
    }

    case SET_STATS: {
      const { stats } = action.payload;
      return {
        ...state,
        stats: stats,
      };
    }

    default:
      return state;
  }
}
