import React from 'react';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'proposerName',
    type: 'text',
    label: 'Name',
  },
  {
    colSpan: 1,
    name: 'proposerDesignation',
    type: 'text',
    label: 'Designation',
  },
  {
    colSpan: 1,
    name: 'proposerMillName',
    type: 'text',
    label: 'Mill Name',
  },
];

const ProposerSection = (props) => {
  return (
    <FormSection sectionName="Proposer Info" sectionColumn={2} formFields={fields} {...props} />
  );
};

export default ProposerSection;
