import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'fabricQty',
    type: 'text',
    label: 'Quantity',
  },
  {
    colSpan: 1,
    fieldType: 'select',
    name: 'fabricUnit',
    label: 'Unit',
    choices: [
      { name: 'Kg', value: 'kg' },
      { name: 'Meter', unit: 'meter' },
      { name: 'Dozen', unit: 'dozen' },
      { name: 'Pcs', unit: 'pcs' },
    ],
  },
  {
    colSpan: 1,
    name: 'fabricValue',
    type: 'text',
    label: 'Value',
  },
  {
    colSpan: 1,
    fieldType: 'select',
    name: 'fabricCurrency',
    label: 'Currency',
    choices: [
      { name: 'BDT', value: 'BDT' },
      { name: 'USD', value: 'USD' },
      { name: 'EURO', value: 'EURO' },
      { name: 'POUND', value: 'POUND' },
      { name: 'RUPEE', value: 'RUPEE' },
      { name: 'YEN', value: 'YEN' },
      { name: 'YUAN', value: 'YUAN' },
      { name: 'CHF', value: 'CHF' },
    ],
  },
  {
    colSpan: 1,
    name: 'fabricConversionRate',
    type: 'text',
    label: 'Conversion Rate',
  },
  {
    colSpan: 1,
    name: 'fabricValueInTk',
    type: 'text',
    label: 'Value in BDT',
  },
];

export const fabricsInfoFieldsValidation = {
  [fields[0].name]: yup
    .number()
    .typeError(`${fields[0].label} must be a number`)
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    }),
  [fields[1].name]: yup.string().nullable().label(fields[1].label),
  [fields[2].name]: yup
    .number()
    .typeError(`${fields[2].label} must be a number`)
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    }),
  [fields[3].name]: yup.string().nullable().label(fields[3].label),
  [fields[4].name]: yup
    .number()
    .typeError(`${fields[4].label} must be a number`)
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    }),
  [fields[5].name]: yup
    .number()
    .typeError(`${fields[5].label} must be a number`)
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    }),
};

const FabricsInfoSection = (props) => {
  return (
    <FormSection
      sectionName="Fabrics (woven/knit)"
      sectionColumn={6}
      formFields={fields}
      {...props}
    />
  );
};

export default FabricsInfoSection;
