import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'spindlesInOp',
    type: 'text',
    label: 'Spindles',
  },
  {
    colSpan: 1,
    name: 'rotorsInOp',
    type: 'text',
    label: 'Rotors',
  },
  {
    colSpan: 1,
    name: 'autoConeInOp',
    type: 'text',
    label: 'Autocone',
  },
  {
    colSpan: 1,
    name: 'otherInOp',
    type: 'text',
    label: 'Other',
  },
];

export const yarnInOperationFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label),
  [fields[1].name]: yup.string().label(fields[1].label),
  [fields[2].name]: yup.string().label(fields[2].label),
  [fields[3].name]: yup.string().label(fields[3].label),
};

const YarnInOperation = (props) => {
  return (
    <FormSection sectionName="In Operation" sectionColumn={2} formFields={fields} {...props} />
  );
};

export default YarnInOperation;
