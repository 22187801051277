export const getCurrentUser = () => {
  const user = localStorage.getItem('user');
  if (user) {
    return JSON.parse(user);
  }
  return undefined;
};

export const setCurrentUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const setAccessToken = (accessToken) => {
  localStorage.setItem('accessToken', accessToken);
};

export const removeCurrentUserData = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('accessToken');
};

export const setCurrentUserData = (user, accessToken) => {
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('accessToken', accessToken);
};

export const getCurrentUserData = () => {
  const user = getCurrentUser();
  const accessToken = localStorage.getItem('accessToken');
  return { user, accessToken };
};

export const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

export const setFormData = (itemName, data) => {
  localStorage.setItem(`${itemName}`, JSON.stringify(data));
};

export const getFormData = (itemName) => {
  return JSON.parse(localStorage.getItem(`${itemName}`));
};

export const removeFormData = (itemName) => {
  localStorage.removeItem(`${itemName}`);
};

export const checkForDuplicatePendingRequests = (url) => {
  let pendingRequests = JSON.parse(localStorage.getItem('pendingRequests'));
  // console.log(pendingRequests);
  return pendingRequests?.includes(url);
};

export const addPendingRequests = (url) => {
  let pendingRequests = JSON.parse(localStorage.getItem('pendingRequests'));
  if (pendingRequests?.length > 0) {
    localStorage.setItem('pendingRequests', JSON.stringify([...pendingRequests, url]));
  } else {
    localStorage.setItem('pendingRequests', JSON.stringify([url]));
  }
  // console.log(JSON.parse(localStorage.getItem('pendingRequests')));
};

export const removePendingRequests = (url) => {
  let pendingRequests = JSON.parse(localStorage.getItem('pendingRequests'));
  if (pendingRequests?.length > 0) {
    pendingRequests.splice(pendingRequests.indexOf(url), 1);
    localStorage.setItem('pendingRequests', JSON.stringify(pendingRequests));
  }
  // console.log(JSON.parse(localStorage.getItem('pendingRequests')));
};
