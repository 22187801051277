import React, { useEffect, useRef, useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import * as yup from 'yup';
import { Box, Grid, GridItem, HStack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import {
  applyForCertificate,
  getAllApplications,
  getUnusedCertificates,
} from '../../../../api/certificates';
import SearchSection from '../../../common/SearchSection';
import SelectCertificateSection from '../../commonSections/SelectCertificateSection';
import MembershipFormWrapper from '../../../common/membershipFormComponents/MembershipFormWrapper';
import ApplicationInfoSectionForMarketExpansion, {
  applicationInfoFieldsForMarketExpansionValidation,
} from './ApplicationInfoSectionForMarketExpansion';
import GeneralInfoSectionForMarketExpansion, {
  generalInfoFieldsForMarketExpansionValidation,
} from './GeneralInfoSectionForMarketExpansion';
import RequiredInfoSectionForMarketExpansion, {
  requiredInfoFieldsForMarketExpansionValidation,
} from './RequiredInfoSectionForMarketExpansion';
import FormButton from '../../../common/FormButton';
import { useYupValidationResolver } from '../../../../util/yupUtils';
import { formatDate } from '../../../../util/formatUtils';
import { useDispatch } from 'react-redux';
import DialogueBox from '../../../common/DialogueBox';
import { notify } from '../../../../store/actions/globalActions';
//utils & constants
import _ from 'lodash';
import { FETCH_NEW_MARKET_EXPANSION_APPLICATIONS } from '../../../../store/actions/actionTypes';

const ApplyForNewMarketExpansion = () => {
  const millErrorRef = useRef();
  const dispatch = useDispatch();
  const [modal, setModal] = useState();
  const [selectedMill, setSelectedMill] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [certificate, setCertificate] = useState();
  const [millUnusedCerts, setMillUnusedCerts] = useState();

  const [certificateToggle, setCertificateToggle] = useState(false);
  const [certErrorMsg, setCertErrorMsg] = useState('');
  const [formData, setFormData] = useState();

  const fetchUnusedCertificates = async () => {
    setMillUnusedCerts();
    const res = await getUnusedCertificates({ type: 'market_expansion', millId: selectedMill?.id });
    if (res.data?.status === 200) {
      setMillUnusedCerts(res.data?.certificates);
    }
  };

  useEffect(() => {
    if (selectedMill?.id) {
      fetchUnusedCertificates();
      setValue('processedBy', `${selectedMill?.millName}, ${selectedMill?.millAddress}`);
    }
    setCertificate();
  }, [selectedMill?.id, certificateToggle]);

  useEffect(() => {
    if (certificate?.invoiceId) {
      setCertErrorMsg('');
    } else {
      setCertErrorMsg('Please Select an Invoice');
    }
  }, [certificate]);

  const resolver = useYupValidationResolver(
    yup.object().shape({
      ...generalInfoFieldsForMarketExpansionValidation,
      ...requiredInfoFieldsForMarketExpansionValidation,
      ...applicationInfoFieldsForMarketExpansionValidation,
    }),
  );

  //form values
  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: { issueDate: new Date() },
  });

  const onSubmit = (data) => {
    let formdata = {
      ...data,
      millId: selectedMill?.id,
      invoiceId: certificate?.invoiceId,
      // expDate: formatDate(data?.expDate),
      // repatriatedExportValueCertificateDate: formatDate(
      //   data?.repatriatedExportValueCertificateDate,
      // ),
      issueDate: formatDate(data?.issueDate),
      salesContactDate: formatDate(data?.salesContactDate),
      invoiceDate: formatDate(data?.invoiceDate),
    };

    let normalizedFormData = _.mapKeys(formdata, (value, key) => {
      if (value) {
        return key;
      }
    });

    setFormData(normalizedFormData);
    setModal('CONFIRM_APPLICATION');
    onOpen();
  };

  const confirmSubmit = async () => {
    const res = await applyForCertificate({ type: 'MARKET_EXPANSION', formData });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      let resetObj = {};
      _.mapKeys(formData, (value, key) => (resetObj[key] = ''));
      reset({ ...resetObj, issueDate: new Date() });
      const response = await getAllApplications({
        type: 'MARKET_EXPANSION',
        params: { page: 1, pageSize: 10 },
      });
      if (response.data?.status === 200) {
        dispatch({ type: FETCH_NEW_MARKET_EXPANSION_APPLICATIONS, payload: response.data });
      }
      setCertificateToggle(!certificateToggle);
    }
  };

  const renderModal = () => {
    switch (modal) {
      case 'CONFIRM_APPLICATION':
        return (
          <DialogueBox
            close={onClose}
            alertTitle="Confirm"
            mountControl={isOpen}
            performAction={confirmSubmit}
            alertMessage="Are you sure you want to submit these information and verify this application?"
          />
        );
    }
  };

  return (
    <Box>
      <SearchSection setSelectedMill={setSelectedMill} showProvisionalsAlso={true} />

      <Grid
        p={4}
        mt={5}
        mx={4}
        gap={3}
        bg="white"
        borderRadius="4px"
        templateColumns="repeat(4, 1fr)">
        <GridItem colSpan={4}>
          <Text fontSize="20px" fontWeight="bold" mb={1}>
            Select Mill & Certificate
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Box
            p={4}
            zIndex={1}
            border="1px"
            bg="secondary"
            borderRadius="4px"
            position="relative"
            borderStyle="dashed"
            borderColor="primary.300">
            {selectedMill?.id ? (
              <CloseIcon
                right={3}
                cursor="pointer"
                position="absolute"
                onClick={() => setSelectedMill()}
              />
            ) : null}
            <Text fontSize="md" color="textSecondary">
              Selected Mill
            </Text>
            <Text fontSize="xl" mt={1.5}>
              {selectedMill?.millName || 'None'}
            </Text>
          </Box>
          {selectedMill ? null : (
            <Text fontSize="sm" color="invalid" ref={millErrorRef} mt={2}>
              Please Select a Mill
            </Text>
          )}
        </GridItem>
        {selectedMill && millUnusedCerts?.length > 0 ? (
          <GridItem colSpan={3}>
            <SelectCertificateSection
              type="NEW_MARKET_EXPANSION"
              error={certErrorMsg}
              certificates={millUnusedCerts}
              setCertificate={setCertificate}
              defaultValue={certificate?.certID}
            />
          </GridItem>
        ) : selectedMill ? (
          <GridItem
            p={4}
            colSpan={3}
            border="1px"
            bg="secondary"
            borderRadius="4px"
            borderColor="invalid">
            <VStack justifyContent="center" h="100%">
              <Text fontSize="md" color="invalid">
                No unused invoice found for this mill! The member needs to pay the application fee
                for a certificate.
              </Text>
            </VStack>
          </GridItem>
        ) : null}
      </Grid>

      {/* form section */}
      {selectedMill && certificate ? (
        <MembershipFormWrapper formName="New Market Expansion Application" mt={4}>
          <Grid templateColumns="repeat(2, 1fr)" gap={3}>
            <GridItem colSpan={1} bg="white" borderRadius="4px" p={4}>
              <Box borderRadius="4px" w="100%">
                <ApplicationInfoSectionForMarketExpansion
                  errors={errors}
                  control={control}
                  sectionColumn={2}
                  register={register}
                />
              </Box>
              <GeneralInfoSectionForMarketExpansion
                control={control}
                register={register}
                errors={errors}
                selectedMill={selectedMill}
              />
            </GridItem>
            <GridItem colSpan={1} bg="white" borderRadius="4px" p={4}>
              <RequiredInfoSectionForMarketExpansion
                errors={errors}
                control={control}
                register={register}
                sectionName="Required Information"
              />
            </GridItem>
          </Grid>
          <HStack justifyContent="end" alignItems="center" mt={4}>
            <FormButton
              mt={0}
              isLoading={isSubmitting}
              onClick={(event) => {
                event.preventDefault();
                handleSubmit(onSubmit)();
              }}>
              SUBMIT
            </FormButton>
          </HStack>
        </MembershipFormWrapper>
      ) : null}

      {renderModal()}
    </Box>
  );
};

export default ApplyForNewMarketExpansion;
