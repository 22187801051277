import React from 'react';
import * as yup from 'yup';
import { FULL_NAME_REGEX } from '../../../constants/regex';
import { mobileNoValidation } from '../../../util/yupUtils';
import FormSection from '../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'name',
    type: 'text',
    label: 'Full Name',
    isRequired: true,
    placeholder: 'Enter full name',
  },
  {
    colSpan: 1,
    name: 'email',
    type: 'text',
    label: 'Email',
    isRequired: true,
    placeholder: 'Enter email address',
  },
  {
    colSpan: 1,
    name: 'phone',
    type: 'text',
    label: 'Phone No',
    isRequired: true,
    placeholder: 'Enter phone number',
  },
];

export const fieldsValidation = {
  [fields[0].name]: yup
    .string()
    .matches(FULL_NAME_REGEX, 'Not a valid name')
    .required()
    .label(fields[0].label),
  [fields[1].name]: yup.string().email().required().label(fields[1].label),
  [fields[2].name]: mobileNoValidation.required().label(fields[2].label),
};

const NewRepresentativeInfoSection = (props) => {
  return <FormSection sectionColumn={2} formFields={fields} {...props} />;
};

export default NewRepresentativeInfoSection;
