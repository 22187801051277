import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/membershipFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'spindlesInOp',
    type: 'text',
    label: 'Spindles',
  },
  {
    colSpan: 1,
    name: 'rotorsInOp',
    type: 'text',
    label: 'Rotors',
  },
  {
    colSpan: 1,
    name: 'autoConeInOp',
    type: 'text',
    label: 'AutoCone',
  },
  {
    colSpan: 1,
    name: 'otherInOp',
    type: 'text',
    label: 'Other',
  },
];

export const inOprFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label).nullable(),
  [fields[1].name]: yup.string().label(fields[1].label).nullable(),
  [fields[2].name]: yup.string().label(fields[2].label).nullable(),
  [fields[3].name]: yup.string().label(fields[3].label).nullable(),
};

const InOperation = (props) => {
  return (
    <FormSection sectionName="In Operation" sectionColumn={1} formFields={fields} {...props} />
  );
};

export default InOperation;
