import React, { useRef, useState } from 'react';
import { MdAdd, MdPrint } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { Box, VStack, Text, Icon, useDisclosure, HStack } from '@chakra-ui/react';

import AdminList from './AdminList';
import AddUserForm from './AddUserForm';
import UserInfoForm from './UserInfoForm';
import ReactToPrint from 'react-to-print';
import Loader from '../../snippets/Loader';
import UserInfoModal from './UserInfoModal';
import OtpVerification from './OtpVerification';
import FormButton from '../../common/FormButton';
import DataTable from '../../common/dataTable/DataTable';
import { notify } from '../../../store/actions/globalActions';

import { generateOtp, getAllAdminUsers } from '../../../api/users';
import { FETCH_ADMIN_USERS } from '../../../store/actions/actionTypes';
import { BADGE_STATE_COLORS, LOGS_TABLE_DISPLAY_SIZE } from '../../../constants/dataTableConstants';

const AdminUsers = () => {
  const adminListRef = useRef();
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [loader, setLoader] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalComponenet, setModalComponent] = useState();
  const data = useSelector((state) => state.settings.users);

  const columns = [
    { header: 'Id', accessor: 'uId' },
    { header: 'Username', accessor: 'username' },
    { header: 'Role', accessor: 'adminRole' },
    { header: 'Contact', accessor: 'phoneNo' },
    { isBadge: true, header: 'Status', accessor: 'userStatus', colorScheme: BADGE_STATE_COLORS },
    {
      header: 'Verified?',
      accessor: 'isVerified',
      isIcon: true,
      iconScheme: [
        {
          group: 'boolean',
          trueIcon: AiFillCheckCircle,
          falseIcon: AiFillCloseCircle,
          trueColor: 'green.700',
          falseColor: 'invalid',
          size: 5,
        },
      ],
    },
    {
      header: 'Action',
      accessor: 'isVerified',
      isButton: true,
      actionScheme: [
        {
          group: 'boolean',
          trueVariant: 'write',
          falseVariant: 'write',
          trueAction: 'EDIT',
          falseAction: 'EDIT',
        },
      ],
    },
  ];

  const adminListColumns = [
    { header: 'Id', accessor: 'uId' },
    { header: 'Username', accessor: 'username' },
    { header: 'Role', accessor: 'adminRole' },
    { header: 'Contact', accessor: 'phoneNo' },
    { header: 'Status', accessor: 'userStatus' },
  ];

  const userStatus = {
    name: 'User Status',
    choices: [
      { name: 'Active', value: 'ACTIVE' },
      { name: 'Disabled', value: 'DISABLED' },
    ],
  };

  const userRole = {
    name: 'User Role',
    choices: [
      { name: 'Super Admin', value: 'SUPERADMIN' },
      { name: 'Admin', value: 'ADMIN' },
      { name: 'Accounts', value: 'ACCOUNTS' },
      { name: 'Operations', value: 'OPERATIONS' },
      { name: 'Fee Collector', value: 'FEE_COLLECTOR' },
    ],
  };

  const handleLateVerification = async (userData) => {
    setLoader(true);
    const res = await generateOtp(userData?.uId);
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 1500,
        }),
      );
      setTitle('Verify User');
      setLoader(false);
      userData?.resend ? setModalComponent(<Loader internal />) : null;
      setModalComponent(
        <OtpVerification
          isLoading={loader}
          closeModal={onClose}
          resendOtp={handleLateVerification}
          data={{ ...userData, sessionId: res.data?.sessionId }}
          refresh={() => handlePagination({ page: 1, pageSize: 10 })}
        />,
      );
    }
    setLoader(false);
  };

  const handleVerification = (userData) => {
    setTitle('Verify User');
    setModalComponent(
      <OtpVerification
        data={userData}
        isLoading={loader}
        closeModal={onClose}
        resendOtp={handleLateVerification}
        refresh={() => handlePagination({ page: 1, pageSize: 10 })}
      />,
    );
    onOpen();
  };

  const handleEdit = ({ rowObject }) => {
    if (rowObject?.isVerified) {
      setTitle('User Info');
      setModalComponent(
        <UserInfoForm
          data={rowObject}
          closeModal={onClose}
          refresh={() => handlePagination({ page: 1, pageSize: 10 })}
        />,
      );
    } else {
      setTitle('Verify User');
      setModalComponent(
        <VStack pt={10}>
          <Text color="textSecondary" mb={4} fontSize="lg" textAlign="center" width="100%">
            This user is not verified! Generate OTP to verify the user.
          </Text>
          <FormButton isLoading={loader} onClick={() => handleLateVerification(rowObject)}>
            GENERATE OTP
          </FormButton>
        </VStack>,
      );
    }
    onOpen();
  };

  const handleCreate = () => {
    setTitle('Create New User');
    setModalComponent(
      <AddUserForm
        closeModal={onClose}
        handleVerification={handleVerification}
        refresh={() => handlePagination({ page: 1, pageSize: 10 })}
      />,
    );
    onOpen();
  };

  const handlePagination = async ({ page, pageSize, searchKey, selected }) => {
    dispatch({ type: FETCH_ADMIN_USERS, payload: null });
    const params = {
      page,
      pageSize,
      searchKey,
      adminRole: selected?.['User Role'],
      userStatus: selected?.['User Status'],
    };
    const res = await getAllAdminUsers(params);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_ADMIN_USERS, payload: res.data });
    } else {
      dispatch({ type: FETCH_ADMIN_USERS, payload: { users: [] } });
    }
  };

  const handleSearch = async ({ searchKey, selected }) => {
    dispatch({ type: FETCH_ADMIN_USERS, payload: null });
    const params = {
      searchKey,
      adminRole: selected?.['User Role'],
      userStatus: selected?.['User Status'],
    };
    const res = await getAllAdminUsers(params);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_ADMIN_USERS, payload: res.data });
    } else {
      dispatch({ type: FETCH_ADMIN_USERS, payload: { users: [] } });
    }
  };

  return (
    <Box>
      <HStack mb={4} p={0}>
        <FormButton mt={0} rightIcon={<Icon as={MdAdd} w={5} h={5} />} onClick={handleCreate}>
          Create New User
        </FormButton>
        <ReactToPrint
          documentTitle="BTMA-Admin-List"
          trigger={() => (
            <FormButton mt={0} rightIcon={<Icon as={MdPrint} w={5} h={5} />}>
              Print User List
            </FormButton>
          )}
          content={() => adminListRef.current}
        />
      </HStack>
      <Box display="none">
        <AdminList ref={adminListRef} data={data?.users} columns={adminListColumns} />
      </Box>
      <DataTable
        topPanel
        keySearch
        isPaginated
        caption="Users"
        columns={columns}
        data={data?.users}
        search={handleSearch}
        currentPage={data?.page}
        paginate={handlePagination}
        entries={data?.totalEntries}
        actions={{ EDIT: handleEdit }}
        currentPageSize={data?.pageSize}
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
        selectOptions={[userStatus, userRole]}
      />
      <UserInfoModal modalTitle={title} mountControl={isOpen} close={onClose}>
        {modalComponenet}
      </UserInfoModal>
    </Box>
  );
};

export default AdminUsers;
