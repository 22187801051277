import { FETCH_CURRENT_LABOUR, FETCH_LABOUR_DATA } from '../actions/actionTypes';

const initialState = {
  allLabour: null,
  current: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_LABOUR_DATA:
      return {
        ...state,
        allLabour: action.payload,
      };
    case FETCH_CURRENT_LABOUR:
      return {
        ...state,
        current: action.payload,
      };
    default:
      return state;
  }
}
