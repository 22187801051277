import React from 'react';
import * as yup from 'yup';
import { dateValidation } from '../../../util/yupUtils';
import FormSection from '../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'masterLc',
    type: 'text',
    label: 'Export L/C No',
    isRequired: true,
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'dateOfLc',
    label: 'Date of L/C',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'totalQuantity',
    type: 'text',
    placeholder: 'As per LC/Contract',
    label: 'Total Quantity & Items',
    isRequired: true,
  },
];

const gspCertFields = [
  ...fields,
  {
    colSpan: 1,
    name: 'exciseGatePassNum',
    type: 'text',
    label: 'Vat Challan No.',
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'exciseGatePassDate',
    label: 'Vat Challan Date',
  },
];

const cashCertfields = [
  ...fields,
  {
    colSpan: 1,
    name: 'mushakNum',
    type: 'text',
    label: 'Mushak No.',
    isRequired: true,
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'mushakDate',
    label: 'Mushak Date',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'totalValueInUsd',
    type: 'text',
    label: 'Total Value (In US$)',
    isRequired: true,
  },
];

export const gspGenInfoFieldsValidation = {
  [gspCertFields[0].name]: yup.string().required().label(gspCertFields[0].label),
  [gspCertFields[1].name]: dateValidation.required().label(gspCertFields[1].label),
  [gspCertFields[2].name]: yup.string().required().label(gspCertFields[2].label),
  [gspCertFields[3].name]: yup.string().label(gspCertFields[3].label),
  [gspCertFields[4].name]: dateValidation.label(gspCertFields[4].label),
};

// eslint-disable-next-line no-unused-vars
const { exciseGatePassNum, exciseGatePassDate, ...rest } = gspGenInfoFieldsValidation;

export const cashGenInfoFieldsValidation = {
  ...rest,
  [cashCertfields[3].name]: yup.string().required().label(cashCertfields[3].label),
  [cashCertfields[4].name]: dateValidation.required().label(cashCertfields[4].label),
  [cashCertfields[5].name]: yup.string().required().label(cashCertfields[5].label),
};

const GeneralInfoSection = (props) => {
  return (
    <FormSection
      sectionName="General Information"
      sectionColumn={3}
      formFields={
        props.type === 'CASH'
          ? cashCertfields
          : props.type === 'GSP'
          ? gspCertFields
          : props.type === 'PROCESSING'
          ? gspCertFields
          : []
      }
      {...props}
    />
  );
};

export default GeneralInfoSection;
