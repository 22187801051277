import React from 'react';
import commaNumber from 'comma-number';
import { Box, Text } from '@chakra-ui/react';
import PrintReceiptHeader from '../../../snippets/PrintReceiptHeader';

const styles = {
  tableStyles: { border: '1px solid black', width: '100%' },
  cellStyles: {
    fontSize: '14px',
    padding: '5px 5px',
    textAlign: 'center',
    border: '1px solid black',
  },
};

const calculateTotalDues = (certs) => {
  let totalDue = 0;
  certs?.map((cert) => (totalDue += cert?.dues));
  return totalDue;
};

const DutyExemptionDuesReport = React.forwardRef(({ data, columns, columns2 }, ref) => {
  return (
    <Box px={6} pb={6} ref={ref} data={data}>
      <Box pt={6}>
        <PrintReceiptHeader />
      </Box>
      <Text w="100%" textAlign="center" fontSize="2xl" fontWeight="bold" pb={4}>
        Duty Exemption Dues
      </Text>

      <Box>
        <Box my={2}>
          <table style={styles.tableStyles}>
            <thead>
              <tr>
                <th style={{ ...styles.cellStyles, width: '4%' }}>Sl No.</th>
                <th style={{ ...styles.cellStyles, width: '13%' }}>Mill Name</th>
                <th style={{ ...styles.cellStyles, width: '8%' }}>Membership No.</th>
                <th style={{ ...styles.cellStyles, width: '5%' }}>Contact</th>
                <th style={styles.cellStyles}>Dues</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((rowItem, rowIndex) => (
                <tr key={rowIndex}>
                  <td style={styles.cellStyles}>{rowIndex + 1}</td>
                  {columns?.map((columnItem, colIndex) => (
                    <td key={colIndex} style={styles.cellStyles}>
                      {rowItem?.[columnItem?.accessor]}
                    </td>
                  ))}
                  <td style={{ ...styles.cellStyles, padding: '0px' }}>
                    <table style={{ ...styles.tableStyles, border: 'none' }}>
                      <thead>
                        <tr>
                          <th
                            style={{
                              ...styles.cellStyles,
                              borderLeft: 'none',
                              borderTop: 'none',
                              width: '15%',
                            }}>
                            Order Id
                          </th>
                          <th style={{ ...styles.cellStyles, borderTop: 'none' }}>
                            Certificate Num
                          </th>
                          <th style={{ ...styles.cellStyles, borderTop: 'none' }}>Total (৳)</th>
                          <th style={{ ...styles.cellStyles, borderTop: 'none' }}>Discount (৳)</th>
                          <th style={{ ...styles.cellStyles, borderTop: 'none' }}>Paid (৳)</th>
                          <th
                            style={{
                              ...styles.cellStyles,
                              borderTop: 'none',
                              borderRight: 'none',
                            }}>
                            Due (৳)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rowItem?.certificates?.map((item, index) => (
                          <tr key={index}>
                            {columns2?.map((column2Item, col2Index) => (
                              <td
                                key={col2Index}
                                style={
                                  col2Index === columns2.length - 1
                                    ? { ...styles.cellStyles, borderRight: 'none' }
                                    : styles.cellStyles
                                }>
                                {col2Index >= 2
                                  ? commaNumber(item?.[column2Item?.accessor])
                                  : item?.[column2Item?.accessor]}
                              </td>
                            ))}
                          </tr>
                        ))}
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td
                            style={{
                              ...styles.cellStyles,
                              borderBottom: 'none',
                              fontWeight: 'bold',
                            }}>
                            Total:
                          </td>
                          <td
                            style={{
                              ...styles.cellStyles,
                              borderBottom: 'none',
                              borderRight: 'none',
                              fontWeight: 'bold',
                            }}>
                            {commaNumber(calculateTotalDues(rowItem?.certificates))}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
  );
});

DutyExemptionDuesReport.displayName = 'DutyExemptionDuesReport';

export default DutyExemptionDuesReport;
