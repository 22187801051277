import React, { useEffect } from 'react';
import {
  Text,
  Grid,
  Stack,
  Button,
  HStack,
  Tooltip,
  GridItem,
  CloseButton,
} from '@chakra-ui/react';
import { useFieldArray } from 'react-hook-form';

import InputField from './InputField';
import RadioField from './RadioField';
import SelectField from './SelectField';
import DatePickerField from './DatePickerField';

const renderField = ({
  name,
  type,
  label,
  choices,
  control,
  register,
  isInvalid,
  fieldType,
  isRequired,
  errorMessage,
  defaultValue,
  datePickerWidth,
}) => {
  switch (fieldType) {
    case 'select':
      return (
        <SelectField
          name={name}
          label={label}
          control={control}
          choices={choices}
          isInvalid={isInvalid}
          isRequired={isRequired}
          errorMessage={errorMessage}
          defaultValue={defaultValue}
        />
      );
    case 'radio':
      return (
        <RadioField
          name={name}
          label={label}
          control={control}
          choices={choices}
          isInvalid={isInvalid}
          isRequired={isRequired}
          errorMessage={errorMessage}
          defaultValue={defaultValue}
        />
      );
    case 'date':
      return (
        <DatePickerField
          name={name}
          label={label}
          control={control}
          isInvalid={isInvalid}
          isRequired={isRequired}
          width={datePickerWidth}
          errorMessage={errorMessage}
        />
      );
    default:
      return (
        <InputField
          name={name}
          type={type}
          label={label}
          register={register}
          isInvalid={isInvalid}
          isRequired={isRequired}
          errorMessage={errorMessage}
          defaultValue={defaultValue}
        />
      );
  }
};

const FieldArrayFormSection = ({
  name,
  errors,
  control,
  register,
  formFields,
  sectionName,
  sectionColumn,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: name,
  });

  const defaultFields = formFields.reduce((obj, item) => ({ ...obj, [item['name']]: '' }), {});

  //keeps atleast one required row in the fields array
  useEffect(() => {
    if (fields.length < 1) {
      append({ ...defaultFields });
    }
  }, []);

  return (
    <Stack spacing={2} p={3} backgroundColor="white" borderRadius="6px">
      <Text fontSize="1xl" fontWeight="bold">
        {sectionName}
      </Text>

      {fields.map((row, index) => (
        <HStack key={index} alignItems="center">
          <Grid templateColumns={`repeat(${sectionColumn * 5 + 1}, 1fr)`} gap={3} key={index}>
            {formFields.map((field, idx) => (
              <GridItem
                key={idx}
                p="1px"
                display="flex"
                alignItems="flex-start"
                colSpan={field.colSpan * 5}
                overflow={field.fieldType === 'date' ? 'visible' : 'hidden'}>
                {renderField({
                  control,
                  register,
                  type: field.type,
                  label: field.label,
                  choices: field.choices,
                  fieldType: field.fieldType,
                  isRequired: field.isRequired,
                  defaultValue: row[field.name],
                  name: `${name}[${index}].${field.name}`,
                  datePickerWidth: Math.ceil(window.innerWidth * 0.667) / sectionColumn,
                  isInvalid: errors ? errors[`${name}[${index}].${field.name}`] : false,
                  errorMessage: errors
                    ? errors[`${name}[${index}].${field.name}`]?.message
                    : undefined,
                })}
              </GridItem>
            ))}
            {index == 0 ? (
              <GridItem key={index} colSpan={1} display="flex" alignItems="flex-end" pb={2}>
                <CloseButton pointerEvents="none" opacity="0" />
              </GridItem>
            ) : (
              <GridItem key={index} colSpan={1} display="flex" alignItems="flex-end" pb="1.8vh">
                <Tooltip label="Drop Fields" aria-label="A tooltip">
                  <CloseButton onClick={() => remove(index)} />
                </Tooltip>
              </GridItem>
            )}
          </Grid>
        </HStack>
      ))}
      <Button
        onClick={() => {
          append({ ...defaultFields });
        }}
        mt={2}
        variant="subtle"
        maxW="fit-content">
        Add {fields?.length > 0 ? 'More' : null} Fields
      </Button>
    </Stack>
  );
};

export default FieldArrayFormSection;
