import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Grid,
  Icon,
  VStack,
  HStack,
  Tooltip,
  Spinner,
  Divider,
  GridItem,
  IconButton,
  CloseButton,
} from '@chakra-ui/react';
import FormButton from '../../common/FormButton';
import SearchSection from '../../common/SearchSection';
import SelectReport from '../commonSections/SelectReport';
import FileExporter from '../commonSections/FileExporter';
import { getAllMoneyReceiptReports } from '../../../api/reports';
import { formatDate, renderDate } from '../../../util/formatUtils';
import DateRangePicker from '../../common/dataTable/DateRangePicker';
import { REPORT_DATE_RANGE } from '../../../constants/reportConstant';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineFileSearch } from 'react-icons/ai';

const MoneyReceiptReportWizard = () => {
  const [mill, setMill] = useState();
  const [table, setTable] = useState();
  const [results, setResults] = useState();
  const [endDate, setEndDate] = useState('');
  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [dateErrorMsg, setDateErrorMsg] = useState('');
  const [selectedFields, setSelectedFields] = useState([]);
  const [clearDateSelect, setClearDateSelect] = useState(false);
  const [dateRangeValidation, setDateRangeValidation] = useState(true);

  useEffect(() => {
    document.title = 'BTMA - Reports';
  }, []);

  useEffect(() => {
    setSelectAll(false);
    setSelectedFields([]);
  }, [table]);

  useEffect(() => {
    setResults();
  }, [selectedFields]);

  const handleDateRange = (data) => {
    setStartDate(formatDate(data.startDate));
    setEndDate(formatDate(data.endDate));
  };

  const checkDateRangeLength = () => {
    if (
      (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24) >
      REPORT_DATE_RANGE
    ) {
      setDateRangeValidation(false);
      setDateErrorMsg('Maximum one year range is recommended for date');
    } else {
      setDateRangeValidation(true);
      setDateErrorMsg('');
    }
  };

  useEffect(() => {
    checkDateRangeLength();
  }, [startDate, endDate]);

  useEffect(() => {
    handleDateRange({
      startDate: new Date(`${new Date().getFullYear()}-01-01`),
      endDate: new Date(),
    });
  }, []);

  const dateFields = ['date', 'date sold', 'entry date', 'cheque dated'];

  const membershipFields = [
    { name: 'TRX Id', key: 'trxId as trx id' },
    { name: 'Date', key: 'membershipSubscriptions.createdAt as date', displayName: 'date' },
    { name: 'Mill Name', key: 'millInfo.millName as mill name' },
    { name: 'Amount', key: 'paidAmount as amount' },
    { name: 'Payment Method', key: 'paymentMethod as payment method' },
    { name: 'Cheque No.', key: 'chequeNo as cheque no' },
    { name: 'Cheque Dated', key: 'chequeDate as cheque dated', displayName: 'cheque dated' },
    { name: 'Period', key: 'period' },
  ];

  const productionCertFields = [
    { name: 'TRX Id', key: 'certificateOfProductionPurchases.uid as trx id' },
    {
      name: 'Entry Date',
      key: 'certificateOfProductionPurchases.createdAt as entry date',
      displayName: 'entry date',
    },
    { name: 'Date Sold', key: 'dateSold as date sold', displayName: 'date sold' },
    { name: 'Mill Name', key: 'millInfo.millName as mill name' },
    { name: 'Payment Status', key: 'status as payment status' },
    { name: 'Certificate Type', key: 'type as certificate type' },
    { name: 'Start Range', key: 'startRange as start range' },
    { name: 'End Range', key: 'endRange as end range' },
    { name: 'No. of copies', key: 'noOfCopies as no. of copies' },
    { name: 'Amount', key: 'total as amount' },
    { name: 'Payment Method', key: 'paymentMethod as payment method' },
    { name: 'Cheque No.', key: 'chequeNo as cheque no' },
    { name: 'Cheque Dated', key: 'chequeDated as cheque dated', displayName: 'cheque dated' },
  ];

  const processingCertFields = [
    { name: 'TRX Id', key: 'certificateOfProcessingPurchases.uid as trx id' },
    {
      name: 'Entry Date',
      key: 'certificateOfProcessingPurchases.createdAt as entry date',
      displayName: 'entry date',
    },
    { name: 'Date Sold', key: 'dateSold as date sold', displayName: 'date sold' },
    { name: 'Mill Name', key: 'millInfo.millName as mill name' },
    { name: 'Payment Status', key: 'status as payment status' },
    { name: 'Start Range', key: 'startRange as start range' },
    { name: 'End Range', key: 'endRange as end range' },
    { name: 'No. of copies', key: 'noOfCopies as no. of copies' },
    { name: 'Amount', key: 'total as amount' },
    { name: 'Payment Method', key: 'paymentMethod as payment method' },
    { name: 'Cheque No.', key: 'chequeNo as cheque no' },
    { name: 'Cheque Dated', key: 'chequeDated as cheque dated', displayName: 'cheque dated' },
  ];

  const dutyCertFields = [
    { name: 'TRX Id', key: 'dutyExemptionPurchases.uid as trx id' },
    { name: 'Certificate No', key: 'certificateInfo.certificate_num as certificate no' },
    {
      name: 'Entry Date',
      key: 'dutyExemptionPurchases.createdAt as entry date',
      displayName: 'entry date',
    },
    { name: 'Date Sold', key: 'dateSold as date sold', displayName: 'date sold' },
    { name: 'Mill Name', key: 'millInfo.millName as mill name' },
    { name: 'Payment Status', key: 'dutyExemptionPurchases.status as payment status' },
    { name: 'Amount', key: 'dutyExemptionPurchases.total as amount' },
    { name: 'Cash Discount', key: 'discount as cash discount' },
    { name: 'Paid Amount', key: 'paidAmount as paid amount' },
    { name: 'Dues', key: 'dues' },
    { name: 'Payment Method', key: 'paymentMethod as payment method' },
    { name: 'Cheque No.', key: 'chequeNo as cheque no' },
    { name: 'Cheque Dated', key: 'chequeDated as cheque dated', displayName: 'cheque dated' },
  ];

  const fieldsForTable = {
    MEMBERSHIP: membershipFields,
    PRODUCTION: productionCertFields,
    PROCESSING: processingCertFields,
    DUTY: dutyCertFields,
  };

  const getUnselectedFields = (fields) => {
    return fields?.filter((item) => !selectedFields?.some((obj) => item.key === obj.key));
  };

  const getKeys = () => {
    let keys = [];

    if (selectedFields?.length > 0) {
      for (let item of selectedFields) {
        keys.push(item.key);
      }
    } else {
      for (let item of fieldsForTable[table]) {
        keys.push(item.key);
      }
    }

    return keys;
  };

  const handleSubmit = async () => {
    setLoader(true);
    let formData = { millId: mill?.id, startDate, endDate, fields: getKeys() };
    const res = await getAllMoneyReceiptReports({ table, formData });

    if (res.data?.status === 200) {
      const resultArray = res.data?.result;

      let availableDateFields =
        selectedFields?.length > 0
          ? dateFields?.filter((date) => selectedFields?.some((obj) => date === obj.displayName))
          : dateFields?.filter((date) =>
              fieldsForTable[table]?.some((obj) => date === obj.displayName),
            );

      let convertedArray = resultArray?.map((item) => {
        function convertFalseyValues() {
          let currItem = item;
          for (let key in currItem) {
            if (!currItem[key]) currItem[key] = 'n/a';
          }
          return currItem;
        }

        function convertDates() {
          let currItem = item;
          availableDateFields?.map((date) =>
            currItem[date] && currItem[date] !== 'n/a'
              ? (currItem[date] = renderDate(item[date], 'numeric'))
              : currItem[date],
          );
          return currItem;
        }

        return { ...convertFalseyValues(), ...convertDates() };
      });

      setResults(convertedArray);
    }
    setLoader(false);
  };

  return (
    <Box p={4}>
      <Text fontSize="2xl" fontWeight="bold">
        Money Receipt Report Wizard
      </Text>

      <Divider mb={3} />
      <Grid templateColumns="repeat(2, 1fr)" gap={3}>
        <GridItem colSpan={1}>
          <Box p={4} bg="white" borderRadius="4px">
            <Box position="relative" p={4} border="1px solid #6B7982" borderRadius="4px">
              <Text
                px={1}
                bg="white"
                top="-12px"
                left="10px"
                fontSize="sm"
                position="absolute"
                color="textSecondary">
                Select Options
              </Text>
              <SelectReport
                setValue={setTable}
                label="Select Report"
                placeholder="Select an option"
                choices={[
                  { name: 'Membership Subscription', value: 'MEMBERSHIP' },
                  { name: 'Production Certificate', value: 'PRODUCTION' },
                  { name: 'Processing Certificate', value: 'PROCESSING' },
                  { name: 'Duty Exemption Certificate', value: 'DUTY' },
                ]}
              />

              {table ? (
                <>
                  <Text color="textSecondary" mt={4} mb={1.5}>
                    Select Columns
                  </Text>
                  <HStack>
                    <Box
                      p={4}
                      w="45%"
                      h="450px"
                      bg="secondary"
                      overflow="auto"
                      boxShadow="inset 0px 0px 5px rgba(0,0,0,0.2)">
                      {selectAll
                        ? null
                        : getUnselectedFields(fieldsForTable[table])?.map((item, index) => (
                            <Text
                              key={index}
                              cursor="pointer"
                              whiteSpace="nowrap"
                              onClick={() => setSelectedFields([...selectedFields, item])}>
                              {item?.name}
                            </Text>
                          ))}
                    </Box>
                    <VStack w="10%" h="450px" justifyContent="center">
                      <Tooltip label="Select all" aria-label="A tooltip">
                        <IconButton
                          icon={<AiOutlineDoubleRight />}
                          onClick={() => {
                            setSelectedFields(fieldsForTable[table]);
                            setSelectAll(true);
                          }}
                        />
                      </Tooltip>
                      <Tooltip label="Deselect all" aria-label="A tooltip">
                        <IconButton
                          icon={<AiOutlineDoubleLeft />}
                          onClick={() => {
                            setSelectedFields([]);
                            setSelectAll(false);
                          }}
                        />
                      </Tooltip>
                    </VStack>
                    <Box
                      p={4}
                      w="45%"
                      h="450px"
                      bg="secondary"
                      overflow="auto"
                      boxShadow="inset 0px 0px 5px rgba(0,0,0,0.2)">
                      {selectedFields?.map((item, index) => (
                        <Text
                          key={index}
                          cursor="pointer"
                          whiteSpace="nowrap"
                          onClick={() => {
                            setSelectAll(false);
                            setSelectedFields(
                              selectedFields?.filter((obj) => obj.key !== item.key),
                            );
                          }}>
                          {item?.name}
                        </Text>
                      ))}
                    </Box>
                  </HStack>
                </>
              ) : null}
            </Box>
          </Box>
        </GridItem>

        <GridItem colSpan={1}>
          <Box p={4} bg="white" borderRadius="4px">
            <Box position="relative" p={4} border="1px solid #6B7982" borderRadius="4px">
              <Text
                px={1}
                bg="white"
                top="-12px"
                left="10px"
                fontSize="sm"
                position="absolute"
                color="textSecondary">
                Search Information
              </Text>
              <SearchSection forceSearch minimal setSelectedMill={setMill} />
              <HStack>
                <Text mt={1} color="primary.400" mb={1.5}>
                  {mill?.millName}
                </Text>
                {mill?.id ? (
                  <CloseButton
                    size="sm"
                    _focus={{ boxShadow: '0 0 0 3px #9280ff' }}
                    onClick={() => {
                      setMill();
                    }}
                  />
                ) : null}
              </HStack>
              <Box mt={4}>
                <Text color="textSecondary" mb={1.5}>
                  Date Range
                </Text>
                <DateRangePicker
                  setDefaultDate
                  data={handleDateRange}
                  error={setDateErrorMsg}
                  clearSelection={clearDateSelect}
                  validation={setDateRangeValidation}
                  cleared={() => setClearDateSelect(false)}
                />
                {!dateErrorMsg ? (
                  <HStack>
                    <Text mt={1} color="primary.400" mb={1.5}>
                      {startDate === endDate
                        ? renderDate(startDate)
                        : `from ${renderDate(startDate)} to ${renderDate(endDate)}`}
                    </Text>
                    {startDate || endDate ? (
                      <CloseButton
                        size="sm"
                        _focus={{ boxShadow: '0 0 0 3px #9280ff' }}
                        onClick={() => {
                          setStartDate('');
                          setEndDate('');
                          setClearDateSelect(true);
                        }}
                      />
                    ) : null}
                  </HStack>
                ) : null}
                <Text fontSize="xs" color="invalid">
                  {dateErrorMsg}
                </Text>
              </Box>

              <HStack w="100%" mt={4} spacing={4}>
                <FormButton
                  mt={0}
                  isLoading={loader}
                  onClick={handleSubmit}
                  isDisabled={!table || !dateRangeValidation}>
                  GENERATE
                </FormButton>
                {loader ? (
                  <HStack
                    flex={1}
                    h="45px"
                    bg="secondary"
                    borderRadius="4px"
                    justifyContent="center">
                    <Spinner
                      size="md"
                      thickness="2px"
                      color="primary.700"
                      emptyColor="primary.50"
                    />
                    <Text color="primary.700">Generating Report...</Text>
                  </HStack>
                ) : results ? (
                  results?.length > 0 ? (
                    <FileExporter title="Money-Receipt" data={results} />
                  ) : (
                    <Text color="textSecondary" fontSize="xl" w="100%" textAlign="center">
                      <Icon as={AiOutlineFileSearch} w={10} h={10} /> No entries found!
                    </Text>
                  )
                ) : null}
              </HStack>
            </Box>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default MoneyReceiptReportWizard;
