import {
  Alert,
  AlertIcon,
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import * as commaNumber from 'comma-number';
import React, { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import * as yup from 'yup';

//common components
import DialogueBox from '../../../common/DialogueBox';
import FormButton from '../../../common/FormButton';
import InfoTiles from '../../../common/InfoTiles';
import Loader from '../../../snippets/Loader';
import BillDate from '../../BillDate';
import CostBreakDown from '../CostBreakDown';
import MembershipBill from '../MembershipBill';
import MoneyReceipt from '../MoneyReceipt';

//form sections
import ChequeInfoSection, { chequeInfoFieldsValidation } from './ChequeInfoSection';
import MembershipUptoSection, { membershipUptoFieldsValidation } from './MembershipUptoSection';
import PaymentSection, { paymentFieldsValidation } from './PaymentSection';

//actions & api
import { getMembershipCost, payMembershipDues } from '../../../../api/accounts';
import { FETCH_MEMBERSHIP_REQUEST_ACC } from '../../../../store/actions/actionTypes';
import { notify } from '../../../../store/actions/globalActions';

//utils & constants
import _ from 'lodash';
import { convertNumberToWords, formatDate, renderDate } from '../../../../util/formatUtils';
import { useYupValidationResolver } from '../../../../util/yupUtils';
import DatePickerField from '../../../common/hookFormComponents/DatePickerField';

const NewRequestAcc = ({ summary }) => {
  const date = new Date();
  const { uid } = useParams();
  const dispatch = useDispatch();
  const membershipBillRef = useRef();
  const memMoneyReceiptRef = useRef();
  const [modal, setModal] = useState();
  const [maxVal, setMaxVal] = useState();
  const [print, setPrint] = useState(false);
  const [formData, setFormData] = useState();
  const [method, setMethod] = useState('CASH');
  const [isGeneral, setIsGeneral] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [billDate, setBillDate] = useState(new Date());
  const [chequePayment, setChequePayment] = useState(false);
  const [moneyReceiptData, setMoneyReceiptData] = useState();
  const data = useSelector((state) => state.accounts.currentData);

  useEffect(() => {
    dispatch({ type: FETCH_MEMBERSHIP_REQUEST_ACC, payload: null });

    (async () => {
      const res = await getMembershipCost({ uid });
      if (res.data?.status === 200) {
        dispatch({ type: FETCH_MEMBERSHIP_REQUEST_ACC, payload: res.data });
      }
    })();

    setMaxVal(calculateMembershipTotal());
  }, []);

  const millInformationFields = [
    { header: 'Mill Name', value: data?.summary?.millName },
    { header: 'Category', value: data?.summary?.category },
    { header: 'Email', value: data?.summary?.millEmail },
    { header: 'Contact', value: data?.summary?.millPhoneNo },
  ];

  const getValidationSchema = () => {
    if (chequePayment) {
      if (isGeneral && date.getMonth() > 5) {
        return yup.object().shape({
          ...paymentFieldsValidation(maxVal),
          ...chequeInfoFieldsValidation(method),
          ...membershipUptoFieldsValidation,
        });
      } else {
        return yup.object().shape({
          ...paymentFieldsValidation(maxVal),
          ...chequeInfoFieldsValidation(method),
        });
      }
    } else {
      if (isGeneral && date.getMonth() > 5) {
        return yup.object().shape({
          ...paymentFieldsValidation(maxVal),
          ...membershipUptoFieldsValidation,
        });
      } else {
        return yup.object().shape({
          ...paymentFieldsValidation(maxVal),
        });
      }
    }
  };

  const resolver = useYupValidationResolver(getValidationSchema());

  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver });

  useEffect(() => {
    if (data) {
      let memType = 'ASSOCIATE';
      const { yarnInfo, fabricInfo, textileInfo } = data?.summary;

      if (yarnInfo?.spindlesInsCap >= 12500) {
        memType = 'GENERAL';
      }
      if (yarnInfo?.rotorsInsCap >= 3000) {
        memType = 'GENERAL';
      }
      if (fabricInfo?.shuttleInsCap >= 100) {
        memType = 'GENERAL';
      }
      if (fabricInfo?.shuttleLessInsCap >= 40) {
        memType = 'GENERAL';
      }
      if (textileInfo?.texFabricKnitProcCap >= 1000000) {
        memType = 'GENERAL';
      }
      if (textileInfo?.texFabricWovenProcCap >= 3000000) {
        memType = 'GENERAL';
      }
      if (textileInfo?.texYarnProcCap >= 1000000) {
        memType = 'GENERAL';
      }

      setValue('membershipType', memType);
      setValue('extendFor', 6);
      if (data?.summary?.membershipCategory === 'PROVISIONAL_MEMBERSHIP') {
        setValue('provisionalExpiryDate', new Date().setFullYear(new Date().getFullYear() + 1));
      }
    }
  }, [data]);

  const paidAmount = useWatch({ control, name: 'paidAmount' });
  const paymentMethod = useWatch({ control, name: 'paymentMethod' });
  const membershipType = useWatch({ control, name: 'membershipType' });
  const extendFor = parseInt(useWatch({ control, name: 'extendFor' }));

  useEffect(() => {
    setMethod(paymentMethod);

    paymentMethod === 'CASH'
      ? setChequePayment(false)
      : paymentMethod
      ? setChequePayment(true)
      : setChequePayment(false);
  }, [paymentMethod]);

  useEffect(() => {
    membershipType === 'GENERAL' ? setIsGeneral(true) : setIsGeneral(false);
  }, [membershipType]);

  useEffect(() => {
    setMaxVal(calculateMembershipTotal());
  }, [membershipType, extendFor, paidAmount]);

  const calculateMembershipTotal = () => {
    if (isGeneral) {
      if (date.getMonth() > 5) {
        if (extendFor === 18) {
          return data?.costs?.total / 2 + data?.costs?.total;
        } else {
          return data?.costs?.total / 2;
        }
      } else {
        return data?.costs?.total;
      }
    } else {
      return data?.costs?.total;
    }
  };

  const getPeriod = (paidAmount) => {
    let period = '';
    const segmentAmount = data?.costs?.total / 2;

    if (isGeneral) {
      if (date.getMonth() > 5) {
        if (extendFor === 18) {
          const arrearAdjusted = segmentAmount - paidAmount > 0 ? false : true;
          const janToJuneAdjusted = arrearAdjusted
            ? 2 * segmentAmount - paidAmount > 0
              ? false
              : true
            : false;
          const julToDecAdjusted = janToJuneAdjusted
            ? 3 * segmentAmount - paidAmount > 0
              ? false
              : true
            : false;
          period = arrearAdjusted
            ? janToJuneAdjusted
              ? julToDecAdjusted
                ? `Arrear upto ${date.getFullYear()}, Jan${date.getFullYear() + 1} - Dec${
                    date.getFullYear() + 1
                  }`
                : 3 * segmentAmount - paidAmount === segmentAmount
                ? `Arrear upto ${date.getFullYear()}, Jan${date.getFullYear() + 1} - Jun${
                    date.getFullYear() + 1
                  }`
                : `Arrear upto ${date.getFullYear()}, Jan${date.getFullYear() + 1} - Jun${
                    date.getFullYear() + 1
                  }, Jul${date.getFullYear() + 1} - Dec${date.getFullYear() + 1} (partial)`
              : 3 * segmentAmount - paidAmount === 2 * segmentAmount
              ? `Arrear upto ${date.getFullYear()}`
              : `Arrear upto ${date.getFullYear()}, Jan${date.getFullYear() + 1} - Jun${
                  date.getFullYear() + 1
                } (partial)`
            : segmentAmount - paidAmount === segmentAmount
            ? 'N/A'
            : `Arrear upto ${date.getFullYear()} (partial)`;
        } else {
          period =
            segmentAmount - paidAmount > 0
              ? segmentAmount - paidAmount === segmentAmount
                ? 'N/A'
                : `Jul${date.getFullYear()} - Dec${date.getFullYear()} (partial)`
              : `Jul${date.getFullYear()} - Dec${date.getFullYear()}`;
        }
      } else {
        const janToJuneAdjusted = segmentAmount - paidAmount > 0 ? false : true;
        const julToDecAdjusted = calculateMembershipTotal() - paidAmount > 0 ? false : true;
        period = janToJuneAdjusted
          ? julToDecAdjusted
            ? `Jan${date.getFullYear()} - Dec${date.getFullYear()}`
            : calculateMembershipTotal() - paidAmount === segmentAmount
            ? `Jan${date.getFullYear()} - Jun${date.getFullYear()}`
            : `Jan${date.getFullYear()} - Jun${date.getFullYear()}, Jul${date.getFullYear()} - Dec${date.getFullYear()} (partial)`
          : `Jan${date.getFullYear()} - Jun${date.getFullYear()} (partial)`;
      }
    } else {
      period =
        calculateMembershipTotal() - paidAmount > 0
          ? calculateMembershipTotal() - paidAmount === calculateMembershipTotal()
            ? 'N/A'
            : `Jan${date.getFullYear()} - Dec${date.getFullYear()} (partial)`
          : `Jan${date.getFullYear()} - Dec${date.getFullYear()}`;
    }

    return period;
  };

  const onSubmit = (formdata) => {
    setModal('CONFIRM');
    setFormData({
      ...formdata,
      issueDate: formatDate(formdata?.issueDate),
      chequeDate: formatDate(formdata?.chequeDate),
      provisionalExpiryDate:
        data?.summary?.membershipCategory === 'PROVISIONAL_MEMBERSHIP'
          ? formatDate(formdata?.provisionalExpiryDate)
          : null,
      currentAmount: calculateMembershipTotal(),
      period: getPeriod(formdata.paidAmount),
      extendFor:
        formdata?.membershipType === 'GENERAL' && date.getMonth() > 5
          ? parseInt(formdata?.extendFor)
          : 12,
    });
    onOpen();
  };

  const confirmSubmit = async () => {
    const res = await payMembershipDues({ uid, formData });
    if (res.data?.status === 200) {
      let resetObj = {};
      _.mapKeys(formData, (value, key) => (resetObj[key] = ''));
      reset(resetObj);
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      setMoneyReceiptData({
        slNo: res.data.invoice?.trxId,
        mrDate: res.data.invoice?.paymentDate,
        receivedFrom: res.data.invoice?.millName,
        amount: res.data.invoice?.total,
        paymentMethod: res.data.invoice?.paymentMethod,
        paymentFor: 'Membership Subscription',
        chequeNo: res.data.invoice?.chequeNo,
        chequeDated: res.data.invoice?.chequeDated,
        total: res.data.invoice?.total,
        period: res.data.invoice?.period,
        due: res.data.invoice?.currentDue,
        paymentChoices: [
          'Membership Subscription',
          'Membership Renewal',
          'Arrear Adjustment',
          'Advertisement',
          'Installment',
        ],
      });
      setPrint(true);
    }
  };

  const handleBillPrint = () => {
    setBillDate(data?.summary?.membershipDate || new Date());
    setModal('BILL_DATE');
    onOpen();
  };

  const printMembershipBill = useReactToPrint({
    documentTitle: `BTMA-Bill-Membership-Subscription-${data?.summary?.uid}-${renderDate(
      billDate,
    )}`,
    content: () => membershipBillRef.current,
  });

  const renderModal = () => {
    switch (modal) {
      case 'CONFIRM':
        return (
          <DialogueBox
            close={onClose}
            alertTitle="Confirm"
            mountControl={isOpen}
            performAction={confirmSubmit}
            alertMessage="Do you want confirm this payment?">
            <Box bg="secondary" border="2px solid #6B7982" borderStyle="dotted" p={4} mt={4}>
              <Text>Total Payable: ৳{calculateMembershipTotal()}</Text>
              <Text>Paid Amount: ৳{formData?.paidAmount}</Text>
              <Text>
                Due: ৳
                {calculateMembershipTotal() - formData?.paidAmount < 0
                  ? 0
                  : calculateMembershipTotal() - formData?.paidAmount}
              </Text>
              <Text color="invalid">Period: {formData?.period}</Text>
              {data?.summary?.membershipCategory === 'PROVISIONAL_MEMBERSHIP' && (
                <Text>Provisional Expiry Date: {formData?.provisionalExpiryDate}</Text>
              )}
            </Box>
          </DialogueBox>
        );
      case 'BILL_DATE':
        return (
          <DialogueBox
            noCancel
            close={onClose}
            mountControl={isOpen}
            actionButtonText="Okay"
            alertTitle="Issue Date"
            performAction={printMembershipBill}>
            <BillDate setDate={setBillDate} date={billDate} />
          </DialogueBox>
        );
      default:
        return null;
    }
  };

  return data ? (
    <Box>
      {data?.summary?.membershipCategory != null &&
      data?.summary?.membershipCategory === 'PROVISIONAL_MEMBERSHIP' ? (
        <Alert status="warning" mb={4}>
          <AlertIcon />
          This member&apos;s membership category is currenty in provisional state.
        </Alert>
      ) : null}
      <Box as="form" onSubmit={handleSubmit(onSubmit)} p={4}>
        <Grid templateColumns="repeat(2, 1fr)" gap={3}>
          {!summary && (
            <GridItem colSpan={2} bg="white" borderRadius="4px">
              <InfoTiles
                sectionColumn={4}
                sectionName="Mill Information"
                infoFields={millInformationFields}
              />
            </GridItem>
          )}
          {summary ? (
            <GridItem colSpan={2} bg="white" borderRadius="4px">
              <CostBreakDown data={{ ...data, membershipType }} />
              <Stack flexDir="column" alignItems="flex-end" px={4} pb={4}>
                <FormButton onClick={handleBillPrint}>PRINT BILL</FormButton>
                <Box display="none">
                  <MembershipBill
                    title="Membership Subscription"
                    ref={membershipBillRef}
                    data={{
                      billDate,
                      billNo: data?.summary?.uid,
                      millName: data?.summary?.millName,
                      millAddress: data?.summary?.millAddress,
                    }}
                    costBreakDownData={{ data: { ...data, membershipType } }}
                  />
                </Box>
              </Stack>
            </GridItem>
          ) : data?.summary?.membershipStatus !== 'PAYMENT PENDING' ? (
            <GridItem colSpan={2} bg="white" borderRadius="4px">
              <VStack w="100%" spacing={0} py={20}>
                <Text fontSize="2xl" fontWeight="bold" color="textSecondary">
                  No pending request found for this membership subscription!
                </Text>
                <Text ontSize="xl" fontWeight="bold" color="textSecondary">
                  Please check membership status and transactions for this mill.
                </Text>
              </VStack>
            </GridItem>
          ) : (
            <>
              <GridItem colSpan={1} bg="white" borderRadius="4px">
                <CostBreakDown data={{ ...data, membershipType, extendFor }} />
                <Stack flexDir="column" alignItems="flex-end" px={4} pb={4}>
                  <FormButton onClick={handleBillPrint}>PRINT BILL</FormButton>
                  <Box display="none">
                    <MembershipBill
                      title="Membership Subscription"
                      ref={membershipBillRef}
                      data={{
                        billDate,
                        billNo: data?.summary?.uid,
                        millName: data?.summary?.millName,
                        millAddress: data?.summary?.millAddress,
                      }}
                      costBreakDownData={{ data: { ...data, membershipType } }}
                    />
                  </Box>
                </Stack>
              </GridItem>
              <GridItem colSpan={1} bg="white" borderRadius="4px">
                <Box p={1}>
                  <Text px={3} pt={3} mb={4} fontSize="20px" fontWeight="bold">
                    Payment Information
                  </Text>
                  <Box p={3} mx={3} mb="2px" border="2px solid #6B7982" borderStyle="dotted">
                    <HStack mb={4} w="100%" justifyContent="space-between">
                      <Text color="textSecondary">Membership Fee</Text>
                      <Text>৳{commaNumber(calculateMembershipTotal())}</Text>
                    </HStack>
                    <Divider />
                    <HStack mb={4} mt={1} w="100%" justifyContent="space-between">
                      <Text color="textSecondary">Total Payable:</Text>
                      <Text>৳{commaNumber(calculateMembershipTotal())}</Text>
                    </HStack>
                    <HStack mb={4} alignItems="flex-start">
                      <Text color="textSecondary" whiteSpace="nowrap">
                        In Words:
                      </Text>
                      <Text>{convertNumberToWords(calculateMembershipTotal())} Taka Only</Text>
                    </HStack>
                  </Box>

                  <Stack
                    px={4}
                    pb={4}
                    flexDir="column"
                    alignItems="flex-end"
                    display={print ? 'flex' : 'none'}>
                    <ReactToPrint
                      documentTitle={`BTMA-Money-Receipt-Membership-Subscription-${
                        moneyReceiptData?.slNo
                      }-${renderDate(moneyReceiptData?.mrDate)}`}
                      trigger={() => <FormButton>PRINT RECEIPT</FormButton>}
                      content={() => memMoneyReceiptRef.current}
                    />
                    <Box display="none">
                      <MoneyReceipt ref={memMoneyReceiptRef} data={moneyReceiptData} />
                    </Box>
                  </Stack>

                  {!print && (
                    <>
                      <PaymentSection register={register} control={control} errors={errors} />

                      {chequePayment ? (
                        <ChequeInfoSection
                          errors={errors}
                          control={control}
                          register={register}
                          method={paymentMethod}
                        />
                      ) : null}

                      <Text px={3} pt={3} mb={4} fontSize="20px" fontWeight="bold" color="invalid">
                        Membership Period
                      </Text>

                      <Grid templateColumns="repeat(4,1fr)">
                        <GridItem>
                          <VStack ml={3} h="100%" spacing={4} alignItems="flex-start">
                            <Text fontSize="1xl" fontWeight="bold">
                              From:
                            </Text>
                            <HStack
                              w="100%"
                              bg="secondary"
                              justifyContent="center"
                              h={['40px', '40px', '40px', '45px', '50px']}>
                              <Text fontSize="1xl" color="textSecondary">
                                {isGeneral
                                  ? `${
                                      date.getMonth() < 6 ? 'January' : 'July'
                                    } ${date.getFullYear()}`
                                  : `January ${date.getFullYear()}`}
                              </Text>
                            </HStack>
                          </VStack>
                        </GridItem>

                        {isGeneral && date.getMonth() > 5 ? (
                          <GridItem colSpan={2}>
                            <MembershipUptoSection
                              errors={errors}
                              control={control}
                              register={register}
                            />
                          </GridItem>
                        ) : (
                          <GridItem>
                            <VStack ml={3} h="100%" spacing={4} alignItems="flex-start">
                              <Text fontSize="1xl" fontWeight="bold">
                                Upto:
                              </Text>
                              <HStack
                                w="100%"
                                bg="secondary"
                                justifyContent="center"
                                h={['40px', '40px', '40px', '45px', '50px']}>
                                <Text fontSize="1xl" color="textSecondary">
                                  {`December ${date.getFullYear()}`}
                                </Text>
                              </HStack>
                            </VStack>
                          </GridItem>
                        )}
                      </Grid>
                      {data?.summary?.membershipCategory === 'PROVISIONAL_MEMBERSHIP' && (
                        <Flex mt={4}>
                          <VStack ml={3} h="100%" spacing={4} alignItems="flex-start">
                            <Text fontSize="1xl" fontWeight="bold">
                              Provisional Expiry Date:
                            </Text>
                            <HStack
                              w="100%"
                              bg="secondary"
                              justifyContent="center"
                              h={['40px', '40px', '40px', '45px', '50px']}>
                              <DatePickerField
                                control={control}
                                name={'provisionalExpiryDate'}
                                isInvalid={errors['provisionalExpiryDate']}
                                errorMessage={errors['provisionalExpiryDate']?.message}
                                isClearable={false}
                              />
                            </HStack>
                          </VStack>
                        </Flex>
                      )}

                      <Stack flexDir="column" alignItems="flex-end" p={3}>
                        <FormButton type="submit" isLoading={isSubmitting}>
                          SUBMIT
                        </FormButton>
                      </Stack>
                    </>
                  )}
                </Box>
              </GridItem>
            </>
          )}
        </Grid>
      </Box>
      {renderModal()}
    </Box>
  ) : (
    <Loader />
  );
};

export default NewRequestAcc;
