import React from 'react';
import { useHistory } from 'react-router';
import {
  BADGE_STATE_COLORS,
  LOGS_TABLE_DISPLAY_SIZE,
} from '../../../../constants/dataTableConstants';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRequests } from '../../../../api/accounts';
import DataTable from '../../../common/dataTable/DataTable';
import { FETCH_MEMBERSHIP_REQUESTS_ACC } from '../../../../store/actions/actionTypes';
import { ACCOUNTS_MEMBERSHIP_PAYMENTS_NEW_URL } from '../../../../constants/routerUrl';

//configure columns
const NewRequestsTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.accounts.membershipRequests);

  const columns = [
    {
      header: 'ID',
      accessor: 'applicationId',
    },
    {
      header: 'Date',
      accessor: 'createdAt',
      isDate: true,
    },
    {
      header: 'Mill Name',
      accessor: 'millName',
    },
    {
      header: 'Category',
      accessor: 'category',
    },
    {
      header: 'Email',
      accessor: 'millEmail',
    },
    {
      header: 'Contact',
      accessor: 'millPhoneNo',
    },
    {
      header: 'Status',
      accessor: 'membershipStatus',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
    },
    {
      header: 'Action',
      accessor: 'membershipStatus',
      isButton: true,
      actionScheme: [{ group: 'PAYMENT PENDING', variant: 'read', action: 'VIEW' }],
    },
  ];

  const handleView = ({ rowObject }) => {
    history.push(`${ACCOUNTS_MEMBERSHIP_PAYMENTS_NEW_URL}/${rowObject.applicationId}`);
  };

  //assign actions to each button
  const actionObject = {
    VIEW: handleView,
  };

  const handlePagination = async ({ page, pageSize, searchKey, startDate, endDate }) => {
    dispatch({ type: FETCH_MEMBERSHIP_REQUESTS_ACC, payload: null });
    const params = {
      page,
      endDate,
      pageSize,
      searchKey,
      startDate,
    };
    const res = await getAllRequests({ params });
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_MEMBERSHIP_REQUESTS_ACC, payload: res.data });
    } else {
      dispatch({ type: FETCH_MEMBERSHIP_REQUESTS_ACC, payload: { memRequests: [] } });
    }
  };

  const handleSearch = async ({ searchKey, startDate, endDate }) => {
    dispatch({ type: FETCH_MEMBERSHIP_REQUESTS_ACC, payload: null });
    const params = { searchKey, startDate, endDate };
    const res = await getAllRequests({ params });
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_MEMBERSHIP_REQUESTS_ACC, payload: res.data });
    } else {
      dispatch({ type: FETCH_MEMBERSHIP_REQUESTS_ACC, payload: { memRequests: [] } });
    }
  };

  return (
    <DataTable
      topPanel
      keySearch
      dateRange
      isPaginated
      columns={columns}
      search={handleSearch}
      actions={actionObject}
      currentPage={data?.page}
      data={data?.memRequests}
      paginate={handlePagination}
      entries={data?.totalEntries}
      currentPageSize={data?.pageSize}
      caption="New Membership Requests"
      pageSizes={LOGS_TABLE_DISPLAY_SIZE}
    />
  );
};

export default NewRequestsTable;
