import React from 'react';
import * as yup from 'yup';
import { FULL_NAME_REGEX, USERNAME_REGEX } from '../../../../constants/regex';
import { mobileNoValidation } from '../../../../util/yupUtils';
import { PASSWORD_MIN_LENGTH } from '../../../../constants/authConstant';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'fullName',
    type: 'text',
    label: 'Full Name',
    isRequired: true,
    placeholder: 'Enter user full name',
  },
  {
    colSpan: 1,
    name: 'username',
    type: 'text',
    label: 'Username',
    isRequired: true,
    placeholder: 'Enter a username',
  },
  {
    colSpan: 1,
    name: 'phoneNo',
    type: 'text',
    label: 'Phone No',
    isRequired: true,
    placeholder: 'Enter user phone number',
  },
  {
    colSpan: 1,
    name: 'email',
    type: 'text',
    label: 'Email',
    isRequired: true,
    placeholder: 'Enter user email address',
  },
  {
    colSpan: 1,
    name: 'password',
    isRequired: true,
    type: 'password',
    label: 'Password',
    placeholder: 'Enter user password',
  },
  {
    colSpan: 1,
    name: 'confirm_password',
    isRequired: true,
    type: 'password',
    label: 'Confirm Password',
    placeholder: 'Confim user password',
  },
];

export const fieldsValidation = {
  [fields[0].name]: yup
    .string()
    .matches(FULL_NAME_REGEX, 'Not a valid name')
    .required()
    .label(fields[0].label),
  [fields[1].name]: yup
    .string()
    .matches(USERNAME_REGEX, 'Not a valid username')
    .required()
    .label(fields[1].label),
  [fields[2].name]: mobileNoValidation.required().label(fields[2].label),
  [fields[3].name]: yup.string().email().required().label(fields[3].label),
  [fields[4].name]: yup.string().min(PASSWORD_MIN_LENGTH).required().label([fields[4].name]),
  [fields[5].name]: yup
    .string()
    .min(PASSWORD_MIN_LENGTH)
    .oneOf([yup.ref(fields[4].name), null], 'Passwords must match')
    .required()
    .label([fields[5].name]),
};

const UserInfoSection = (props) => {
  return <FormSection sectionColumn={2} formFields={fields} {...props} />;
};

export default UserInfoSection;
