import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { dateValidation } from '../../../../util/yupUtils';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    fieldType: 'select',
    name: 'category',
    label: 'Category',
    isRequired: true,
    choices: [
      { name: 'Textile Spare Parts For Machineries', value: 'Textile Spare Parts For Machineries' },
      { name: 'Textile Spare Parts', value: 'Textile Spare Parts' },
      { name: 'Dyes Chemicals', value: 'Dyes Chemicals' },
      { name: 'Polyester Staple Fiber', value: 'Polyester Staple Fiber' },
      { name: 'Viscose Staple Fiber', value: 'Viscose Staple Fiber' },
      { name: 'Tencel & Flax Fiber', value: 'Tencel & Flax Fiber' },
      { name: 'Others Fiber', value: 'Others Fiber' },
    ],
  },
  {
    colSpan: 1,
    name: 'invoice',
    type: 'text',
    label: 'Invoice No.',
    isRequired: true,
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'invoiceDate',
    label: 'Invoice Date',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'masterLc',
    type: 'text',
    label: 'L/C No.',
    isRequired: true,
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'dateOfLc',
    label: 'L/C Date',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'blNo',
    type: 'text',
    label: 'BL No.',
    isRequired: true,
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'blDate',
    label: 'BL Date',
    isRequired: true,
  },
];

export const generalInfoFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup.string().required().label(fields[1].label),
  [fields[2].name]: dateValidation.required().label(fields[2].label),
  [fields[3].name]: yup.string().required().label(fields[3].label),
  [fields[4].name]: dateValidation.required().label(fields[4].label),
  [fields[5].name]: yup.string().required().label(fields[5].label),
  [fields[6].name]: dateValidation.required().label(fields[6].label),
};

const GeneralInfoSection = (props) => {
  const [fieldsList, setFieldsList] = useState([]);

  useEffect(() => {
    // creating deep copy
    let initialList = JSON.parse(JSON.stringify(fields));

    // Check the membership status
    if (
      props?.selectedMill != null &&
      props?.selectedMill?.membershipStatus === 'PROVISIONAL MEMBER'
    ) {
      // Filter the choices only for 'Textile Spare Parts'
      initialList[0].choices = initialList[0].choices.filter(
        (choice) => choice.value === 'Textile Spare Parts For Machineries',
      );
    }

    // Set the updated list to the state
    setFieldsList(initialList);
  }, [props.selectedMill]);

  return (
    <FormSection
      sectionName="General Information"
      sectionColumn={2}
      formFields={fieldsList}
      {...props}
    />
  );
};

export default GeneralInfoSection;
