import { PRE_LOG_IN, LOG_IN, LOG_OUT, SET_STATS } from './actionTypes';

export const preLogIn = (user) => ({
  type: PRE_LOG_IN,
  payload: {
    isLoggedIn: false,
    user,
  },
});

export const logIn = (user) => ({
  type: LOG_IN,
  payload: {
    isLoggedIn: true,
    user,
  },
});

export const logOut = () => ({
  type: LOG_OUT,
  payload: {
    isLoggedIn: false,
  },
});

export const setStats = (data) => ({
  type: SET_STATS,
  payload: {
    stats: data,
  },
});
