import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/membershipFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'rawCottonCon',
    type: 'text',
    label: 'Raw Cotton(bales)',
    placeholder: '0',
  },
  {
    colSpan: 1,
    name: 'psfCon',
    type: 'text',
    label: 'PSF(kg)',
    placeholder: '0',
  },
  {
    colSpan: 1,
    name: 'viscoseCon',
    type: 'text',
    label: 'Viscose(kg)',
    placeholder: '0',
  },
  {
    colSpan: 1,
    name: 'cotWasteCon',
    type: 'text',
    label: 'Cotton Waste(kg)',
    placeholder: '0',
  },
  {
    colSpan: 1,
    name: 'acrylicCon',
    type: 'text',
    label: 'Acrylic(kg)',
    placeholder: '0',
  },
  {
    colSpan: 1,
    name: 'chipsCon',
    type: 'text',
    label: 'Chips',
    placeholder: '0',
  },
  {
    colSpan: 1,
    name: 'petChipsCon',
    type: 'text',
    label: 'Pet-chips(kg)',
    placeholder: '0',
  },
];

export const yarnRawMatConsFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label).nullable(),
  [fields[1].name]: yup.string().label(fields[1].label).nullable(),
  [fields[2].name]: yup.string().label(fields[2].label).nullable(),
  [fields[3].name]: yup.string().label(fields[3].label).nullable(),
  [fields[4].name]: yup.string().label(fields[4].label).nullable(),
  [fields[5].name]: yup.string().label(fields[5].label).nullable(),
  [fields[6].name]: yup.string().label(fields[6].label).nullable(),
};

const YarnRawMatCons = (props) => {
  return <FormSection sectionName="Consumption" sectionColumn={1} formFields={fields} {...props} />;
};

export default YarnRawMatCons;
