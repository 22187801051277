import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Icon,
  Grid,
  VStack,
  HStack,
  Tooltip,
  Spinner,
  Divider,
  GridItem,
  IconButton,
  CloseButton,
} from '@chakra-ui/react';
import FormButton from '../../common/FormButton';
import SearchSection from '../../common/SearchSection';
import { getAllCertReports } from '../../../api/reports';
import SelectReport from '../commonSections/SelectReport';
import FileExporter from '../commonSections/FileExporter';
import CertificateNum from '../commonSections/CertificateNum';
import { formatDate, renderDate } from '../../../util/formatUtils';
import DateRangePicker from '../../common/dataTable/DateRangePicker';
import { REPORT_DATE_RANGE } from '../../../constants/reportConstant';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineFileSearch } from 'react-icons/ai';

const ProcessingReportWizard = () => {
  const [mill, setMill] = useState();
  const [table, setTable] = useState();
  const [certNum, setCertNum] = useState();
  const [results, setResults] = useState();
  const [endDate, setEndDate] = useState('');
  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [dateErrorMsg, setDateErrorMsg] = useState('');
  const [selectedFields, setSelectedFields] = useState([]);
  const [clearDateSelect, setClearDateSelect] = useState(false);
  const [dateRangeValidation, setDateRangeValidation] = useState(true);

  useEffect(() => {
    document.title = 'BTMA - Reports';
  }, []);

  useEffect(() => {
    setSelectAll(false);
    setSelectedFields([]);
  }, [table]);

  useEffect(() => {
    setResults();
  }, [selectedFields]);

  const handleDateRange = (data) => {
    setStartDate(formatDate(data.startDate));
    setEndDate(formatDate(data.endDate));
  };

  const checkDateRangeLength = () => {
    if (
      (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24) >
      REPORT_DATE_RANGE
    ) {
      setDateRangeValidation(false);
      setDateErrorMsg('Maximum one year range is recommended for date');
    } else {
      setDateRangeValidation(true);
      setDateErrorMsg('');
    }
  };

  useEffect(() => {
    checkDateRangeLength();
  }, [startDate, endDate]);

  useEffect(() => {
    handleDateRange({
      startDate: new Date(`${new Date().getFullYear()}-01-01`),
      endDate: new Date(),
    });
  }, []);

  const dateFields = [
    'issue date',
    'date of l/c',
    'date of back to back l/c',
    'expiry date of back to back l/c',
    'vat challan date',
    'vide certificate date',
  ];

  const jsonFields = ['product details'];

  const fabricFields = [
    /* previously used headers */
    { name: 'Mill Name', key: 'millInfo.millName as mill name' },
    { name: 'Membership No.', key: 'millInfo.membershipNo as membership no' },
    { name: 'Certificate Number', key: 'certificateInfo.certificateNum as certificate number' },
    { name: 'IssueDate', key: 'issueDate as issue date', displayName: 'issue date' },
    { name: 'Master L/C No.', key: 'masterLc as master l/c no' },
    { name: 'Back to back L/C No.', key: 'btbLc as back to back l/c no' },
    { name: 'Bank', key: 'bank' },
    { name: 'Vat Challan No.', key: 'exciseGatePassNum as vat challan no' },
    { name: 'Buyer', key: 'suppliedTo as buyer' },
    { name: 'Apparel', key: 'apparel' },
    { name: 'Home Furnishing', key: 'homeFurnishing as home furnishing' },
    { name: 'Quantity Others', key: 'quantityOthers as quantity others' },
    { name: 'Towels & Others', key: 'towelsOthers as towels & others' },
    { name: 'Fabric', key: 'fabric' },
    { name: 'Value (In US$)', key: 'valueInUsd as value (in US$)' },
    { name: 'Total Quantity & Items', key: 'totalQuantity as total quantity' },

    /* newly added headers */
    { name: 'Date of L/C', key: 'dateOfLc as date of l/c', displayName: 'date of l/c' },
    {
      name: 'Date of Back to back L/C',
      key: 'btbLcDate as date of back to back l/c',
      displayName: 'date of back to back l/c',
    },
    {
      name: 'Expiry Date of Back to Back L/C',
      key: 'expiryDateOfLc as expiry date of back to back l/c',
      displayName: 'expiry date of back to back l/c',
    },
    {
      name: 'Vat Challan Date',
      key: 'exciseGatePassDate as vat challan date',
      displayName: 'vat challan date',
    },
    { name: 'Produced By', key: 'producedBy as produced by' },
    { name: 'Processed By', key: 'processedBy as processed by' },
    { name: 'Vide Certificate No.', key: 'videCertificateNo as vide certificate no' },
    {
      name: 'Vide Certificate Date',
      key: 'videCertificateDate as vide certificate date',
      displayName: 'vide certificate date',
    },
    {
      name: 'Product Details',
      key: 'productDetails as product details',
      displayName: 'product details',
    },
  ];

  const genFabricFields = [
    { name: 'Mill Name', key: 'mill name' },
    { name: 'Membership No.', key: 'membership no' },
    { name: 'Apparel', key: 'apparel' },
    { name: 'Home Furnishing', key: 'home furnishing' },
    { name: 'Quantity Others', key: 'quantity others' },
    { name: 'Towels & Others', key: 'towels & others' },
    { name: 'Fabric', key: 'fabric' },
    { name: 'Value (In US$)', key: 'value (in US$)' },
  ];

  const textileFields = [
    /* previously used headers */
    { name: 'Mill Name', key: 'millInfo.millName as mill name' },
    { name: 'Membership No.', key: 'millInfo.membershipNo as membership no' },
    { name: 'Certificate Number', key: 'certificateInfo.certificateNum as certificate number' },
    { name: 'IssueDate', key: 'issueDate as issue date', displayName: 'issue date' },
    { name: 'Master L/C No.', key: 'masterLc as master l/c no' },
    { name: 'Back to back L/C No.', key: 'btbLc as back to back l/c no' },
    { name: 'Bank', key: 'bank' },
    { name: 'Vat Challan No.', key: 'exciseGatePassNum as vat challan no' },
    { name: 'Buyer', key: 'suppliedTo as buyer' },
    { name: 'Autoconed', key: 'autoconed as autoconed' },
    { name: 'Non-autoconed', key: 'nonAutoconed as non autoconed' },
    { name: 'Open End', key: 'openEnd as open end' },
    { name: 'Towels & Others', key: 'towelsOthers as towels & others' },
    { name: 'Fabric', key: 'fabric' },
    { name: 'Value (In US$)', key: 'valueInUsd as value (in US$)' },
    { name: 'Total Quantity & Items', key: 'totalQuantity as total quantity' },

    /* newly added headers */
    { name: 'Date of L/C', key: 'dateOfLc as date of l/c', displayName: 'date of l/c' },
    {
      name: 'Date of Back to back L/C',
      key: 'btbLcDate as date of back to back l/c',
      displayName: 'date of back to back l/c',
    },
    {
      name: 'Expiry Date of Back to Back L/C',
      key: 'expiryDateOfLc as expiry date of back to back l/c',
      displayName: 'expiry date of back to back l/c',
    },
    {
      name: 'Vat Challan Date',
      key: 'exciseGatePassDate as vat challan date',
      displayName: 'vat challan date',
    },
    { name: 'Produced By', key: 'producedBy as produced by' },
    { name: 'Processed By', key: 'processedBy as processed by' },
    { name: 'Vide Certificate No.', key: 'videCertificateNo as vide certificate no' },
    {
      name: 'Vide Certificate Date',
      key: 'videCertificateDate as vide certificate date',
      displayName: 'vide certificate date',
    },
    {
      name: 'Product Details',
      key: 'productDetails as product details',
      displayName: 'product details',
    },
  ];

  const genTextileFields = [
    { name: 'Mill Name', key: 'mill name' },
    { name: 'Membership No.', key: 'membership no' },
    { name: 'Autoconed', key: 'autoconed' },
    { name: 'Non-autoconed', key: 'non autoconed' },
    { name: 'Towels & Others', key: 'towels & others' },
    { name: 'Open End', key: 'open end' },
    { name: 'Fabric', key: 'fabric' },
    { name: 'Value (In US$)', key: 'value (in US$)' },
  ];

  const fieldsForTable = {
    PROCESSING_FABRIC: fabricFields,
    PROCESSING_TEXTILE: textileFields,
    GEN_PROCESSING_FABRIC: genFabricFields,
    GEN_PROCESSING_TEXTILE: genTextileFields,
  };

  const getUnselectedFields = (fields) => {
    return fields?.filter((item) => !selectedFields?.some((obj) => item.key === obj.key));
  };

  const getKeys = () => {
    let keys = [];

    if (selectedFields?.length > 0) {
      for (let item of selectedFields) {
        keys.push(item.key);
      }
    } else {
      for (let item of fieldsForTable[table]) {
        keys.push(item.key);
      }
    }

    return keys;
  };

  const handleSubmit = async () => {
    setLoader(true);
    let formData = { millId: mill?.id, certNum, startDate, endDate, table, fields: getKeys() };
    const res = await getAllCertReports({ type: 'processing', formData });

    if (res.data?.status === 200) {
      const resultArray = res.data?.result;

      let availableDateFields =
        selectedFields?.length > 0
          ? dateFields?.filter((date) => selectedFields?.some((obj) => date === obj.displayName))
          : dateFields?.filter((date) =>
              fieldsForTable[table]?.some((obj) => date === obj.displayName),
            );

      let availableJsonFields =
        selectedFields?.length > 0
          ? jsonFields?.filter((json) => selectedFields?.some((obj) => json === obj.displayName))
          : jsonFields?.filter((json) =>
              fieldsForTable[table]?.some((obj) => json === obj.displayName),
            );

      let convertedArray = resultArray?.map((item) => {
        function convertFalseyValues() {
          let currItem = item;
          for (let key in currItem) {
            if (!currItem[key]) currItem[key] = 'n/a';
          }
          return currItem;
        }

        function convertDates() {
          let currItem = item;
          availableDateFields?.map((date) =>
            currItem[date] && currItem[date] !== 'n/a'
              ? (currItem[date] = renderDate(item[date], 'numeric'))
              : currItem[date],
          );
          return currItem;
        }

        function convertJson() {
          let currItem = item;
          let prodStr = '';
          let yarnStr = '';
          availableJsonFields?.map((json) => {
            if (json === 'product details') {
              item?.[json] !== 'n/a' && item?.[json]?.length > 0
                ? item[json]?.map((obj, index) => {
                    prodStr += `product${index + 1}: ${obj.product || 'n/a'}, product${
                      index + 1
                    }-quantity: ${obj.productQuantity || 'n/a'}, product${
                      index + 1
                    }-delivery-date: ${obj.deliveryDate || 'n/a'}, product${index + 1}-invoice: ${
                      obj.invoice || 'n/a'
                    }, product${index + 1}-invoice-date: ${obj.invoiceDate || 'n/a'}${
                      index === item[json]?.length - 1 ? '.' : '; '
                    }`;
                  })
                : (prodStr = 'n/a');
              currItem[json] = prodStr;
            } else {
              item?.[json] !== 'n/a' && item?.[json]?.length > 0
                ? item[json]?.map((obj, index) => {
                    yarnStr += `name${index + 1}: ${obj.name || 'n/a'}, quantity${index + 1}: ${
                      obj.quantity || 'n/a'
                    }, unit-price${index + 1}: ${obj.unitPrice || 'n/a'}${
                      index === item[json]?.length - 1 ? '.' : '; '
                    }`;
                  })
                : (yarnStr = 'n/a');
              currItem[json] = yarnStr;
            }
          });
          return currItem;
        }

        return { ...convertFalseyValues(), ...convertDates(), ...convertJson() };
      });

      setResults(convertedArray);
    }
    setLoader(false);
  };

  return (
    <Box p={4}>
      <Text fontSize="2xl" fontWeight="bold">
        Product Processing Report Wizard
      </Text>

      <Divider mb={3} />
      <Grid templateColumns="repeat(2, 1fr)" gap={3}>
        <GridItem colSpan={1}>
          <Box p={4} bg="white" borderRadius="4px">
            <Box position="relative" p={4} border="1px solid #6B7982" borderRadius="4px">
              <Text
                px={1}
                bg="white"
                top="-12px"
                left="10px"
                fontSize="sm"
                position="absolute"
                color="textSecondary">
                Select Options
              </Text>
              <SelectReport
                setValue={setTable}
                label="Select Report"
                placeholder="Select an option"
                choices={[
                  { name: 'Fabric Product Processing', value: 'PROCESSING_FABRIC' },
                  { name: 'Yarn Product Processing', value: 'PROCESSING_TEXTILE' },
                  { name: 'General Fabric Report', value: 'GEN_PROCESSING_FABRIC' },
                  { name: 'General Yarn Report', value: 'GEN_PROCESSING_TEXTILE' },
                ]}
              />

              {table ? (
                <>
                  <Text color="textSecondary" mt={4} mb={1.5}>
                    Select Columns
                  </Text>
                  <HStack>
                    <Box
                      p={4}
                      w="45%"
                      h="450px"
                      bg="secondary"
                      overflow="auto"
                      boxShadow="inset 0px 0px 5px rgba(0,0,0,0.2)">
                      {selectAll
                        ? null
                        : getUnselectedFields(fieldsForTable[table])?.map((item, index) => (
                            <Text
                              key={index}
                              cursor="pointer"
                              whiteSpace="nowrap"
                              onClick={() => setSelectedFields([...selectedFields, item])}>
                              {item?.name}
                            </Text>
                          ))}
                    </Box>
                    <VStack w="10%" h="450px" justifyContent="center">
                      <Tooltip label="Select all" aria-label="A tooltip">
                        <IconButton
                          icon={<AiOutlineDoubleRight />}
                          onClick={() => {
                            setSelectedFields(fieldsForTable[table]);
                            setSelectAll(true);
                          }}
                        />
                      </Tooltip>
                      <Tooltip label="Deselect all" aria-label="A tooltip">
                        <IconButton
                          icon={<AiOutlineDoubleLeft />}
                          onClick={() => {
                            setSelectedFields([]);
                            setSelectAll(false);
                          }}
                        />
                      </Tooltip>
                    </VStack>
                    <Box
                      p={4}
                      w="45%"
                      h="450px"
                      bg="secondary"
                      overflow="auto"
                      boxShadow="inset 0px 0px 5px rgba(0,0,0,0.2)">
                      {selectedFields?.map((item, index) => (
                        <Text
                          key={index}
                          cursor="pointer"
                          whiteSpace="nowrap"
                          onClick={() => {
                            setSelectAll(false);
                            setSelectedFields(
                              selectedFields?.filter((obj) => obj.key !== item.key),
                            );
                          }}>
                          {item?.name}
                        </Text>
                      ))}
                    </Box>
                  </HStack>
                </>
              ) : null}
            </Box>
          </Box>
        </GridItem>

        <GridItem colSpan={1}>
          <Box p={4} bg="white" borderRadius="4px">
            <Box position="relative" p={4} border="1px solid #6B7982" borderRadius="4px">
              <Text
                px={1}
                bg="white"
                top="-12px"
                left="10px"
                fontSize="sm"
                position="absolute"
                color="textSecondary">
                Search Information
              </Text>
              <SearchSection forceSearch minimal setSelectedMill={setMill} />
              <HStack>
                <Text mt={1} color="primary.400" mb={1.5}>
                  {mill?.millName}
                </Text>
                {mill?.id ? (
                  <CloseButton
                    size="sm"
                    _focus={{ boxShadow: '0 0 0 3px #9280ff' }}
                    onClick={() => {
                      setMill();
                    }}
                  />
                ) : null}
              </HStack>
              <HStack mt={4} alignItems="flex-start">
                {table === 'GEN_PROCESSING_FABRIC' || table === 'GEN_PROCESSING_TEXTILE' ? null : (
                  <CertificateNum label="Certificate No." setValue={setCertNum} />
                )}

                <Box mt={4}>
                  <Text color="textSecondary" mb={1.5}>
                    Date Range
                  </Text>
                  <DateRangePicker
                    setDefaultDate
                    data={handleDateRange}
                    error={setDateErrorMsg}
                    clearSelection={clearDateSelect}
                    validation={setDateRangeValidation}
                    cleared={() => setClearDateSelect(false)}
                  />
                  {!dateErrorMsg ? (
                    <HStack>
                      <Text mt={1} color="primary.400" mb={1.5}>
                        {startDate === endDate
                          ? renderDate(startDate)
                          : `from ${renderDate(startDate)} to ${renderDate(endDate)}`}
                      </Text>
                      {startDate || endDate ? (
                        <CloseButton
                          size="sm"
                          _focus={{ boxShadow: '0 0 0 3px #9280ff' }}
                          onClick={() => {
                            setStartDate('');
                            setEndDate('');
                            setClearDateSelect(true);
                          }}
                        />
                      ) : null}
                    </HStack>
                  ) : null}
                  <Text fontSize="xs" color="invalid">
                    {dateErrorMsg}
                  </Text>
                </Box>
              </HStack>
              <HStack w="100%" mt={4} spacing={4}>
                <FormButton
                  mt={0}
                  isLoading={loader}
                  onClick={handleSubmit}
                  isDisabled={!table || !dateRangeValidation}>
                  GENERATE
                </FormButton>
                {loader ? (
                  <HStack
                    flex={1}
                    h="45px"
                    bg="secondary"
                    borderRadius="4px"
                    justifyContent="center">
                    <Spinner
                      size="md"
                      thickness="2px"
                      color="primary.700"
                      emptyColor="primary.50"
                    />
                    <Text color="primary.700">Generating Report...</Text>
                  </HStack>
                ) : results ? (
                  results?.length > 0 ? (
                    <FileExporter title="Processing" data={results} />
                  ) : (
                    <Text color="textSecondary" fontSize="xl" w="100%" textAlign="center">
                      <Icon as={AiOutlineFileSearch} w={10} h={10} /> No entries found!
                    </Text>
                  )
                ) : null}
              </HStack>
            </Box>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default ProcessingReportWizard;
