import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getAllDutyCertPurchaseRequests } from '../../../../api/accounts';
import {
  BADGE_STATE_COLORS,
  LOGS_TABLE_DISPLAY_SIZE,
} from '../../../../constants/dataTableConstants';
import { ACCOUNTS_CERTIFICATE_PURCHASE_URL_DUTY } from '../../../../constants/routerUrl';
import { FETCH_DUTY_CERT_PURCHASE_REQUESTS } from '../../../../store/actions/actionTypes';
import DataTable from '../../../common/dataTable/DataTable';

const DutyPurchaseRequestTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.accounts.dutyCertPurchaseRequests);

  const columns = [
    { header: 'Invoice', accessor: 'orderId' },
    { header: 'Date', accessor: 'createdAt', isDate: true },
    { header: 'Certificate No', accessor: 'certificateNum' },
    { header: 'Mill Name', accessor: 'millName' },
    { header: 'Quantity', accessor: 'noOfCopies' },
    { header: 'Total', accessor: 'total', currency: '৳' },
    {
      header: 'Status',
      accessor: 'status',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
    },
    {
      header: 'Action',
      accessor: 'status',
      isButton: true,
      actionScheme: [
        { group: 'UNPAID', variant: 'read', action: 'VIEW' },
        { group: 'PAID', variant: 'read', action: 'VIEW' },
      ],
    },
  ];

  const handleView = ({ rowObject }) => {
    history.push({
      pathname: ACCOUNTS_CERTIFICATE_PURCHASE_URL_DUTY,
      state: { orderId: rowObject?.orderId, certFor: 'duty_fee' },
    });
  };

  const actionObject = {
    VIEW: handleView,
  };

  const PAYMENT_STATUS = {
    name: 'Payment Status',
    choices: [
      { name: 'PAID', value: 'PAID' },
      { name: 'UNPAID', value: 'UNPAID' },
    ],
  };

  const handlePagination = async ({ page, pageSize, searchKey, startDate, endDate, selected }) => {
    dispatch({ type: FETCH_DUTY_CERT_PURCHASE_REQUESTS, payload: null });
    const params = {
      page,
      endDate,
      pageSize,
      searchKey,
      startDate,
      status: selected?.['Payment Status'],
    };
    const res = await getAllDutyCertPurchaseRequests(params);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_DUTY_CERT_PURCHASE_REQUESTS, payload: res.data });
    } else {
      dispatch({ type: FETCH_DUTY_CERT_PURCHASE_REQUESTS, payload: { purchases: [] } });
    }
  };

  const handleSearch = async ({ searchKey, startDate, endDate, selected }) => {
    dispatch({ type: FETCH_DUTY_CERT_PURCHASE_REQUESTS, payload: null });
    const params = {
      endDate,
      searchKey,
      startDate,
      status: selected?.['Payment Status'],
    };
    const res = await getAllDutyCertPurchaseRequests(params);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_DUTY_CERT_PURCHASE_REQUESTS, payload: res.data });
    } else {
      dispatch({ type: FETCH_DUTY_CERT_PURCHASE_REQUESTS, payload: { purchases: [] } });
    }
  };

  return (
    <DataTable
      topPanel
      keySearch
      dateRange
      isPaginated
      columns={columns}
      search={handleSearch}
      data={data?.purchases}
      actions={actionObject}
      currentPage={data?.page}
      paginate={handlePagination}
      entries={data?.totalEntries}
      currentPageSize={data?.pageSize}
      selectOptions={[PAYMENT_STATUS]}
      pageSizes={LOGS_TABLE_DISPLAY_SIZE}
      caption="Duty Exemption Application Fees"
    />
  );
};

export default DutyPurchaseRequestTable;
