import React from 'react';
import { useHistory } from 'react-router';
import {
  BADGE_STATE_COLORS,
  LOGS_TABLE_DISPLAY_SIZE,
} from '../../../../constants/dataTableConstants';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../common/dataTable/DataTable';
import { getAllProductionCertPurchases } from '../../../../api/accounts';
import { FETCH_PROD_CERT_PURCHASES } from '../../../../store/actions/actionTypes';
import { ACCOUNTS_CERTIFICATE_PURCHASE_URL } from '../../../../constants/routerUrl';

const ProductionCertTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.accounts.prodCertRequests);

  const columns = [
    { header: 'Invoice', accessor: 'orderId' },
    { header: 'Date', accessor: 'createdAt', isDate: true },
    { header: 'Range', accessor: 'certRange' },
    { header: 'Mill Name', accessor: 'millName' },
    { header: 'Type', accessor: 'type' },
    { header: 'Quantity', accessor: 'noOfCopies' },
    { header: 'Total', accessor: 'total', currency: '৳' },
    {
      header: 'Status',
      accessor: 'status',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
    },
    {
      header: 'Action',
      accessor: 'status',
      isButton: true,
      actionScheme: [
        { group: 'UNPAID', variant: 'read', action: 'VIEW' },
        { group: 'PAID', variant: 'read', action: 'VIEW' },
      ],
    },
  ];

  const handleView = ({ rowObject }) => {
    history.push({
      pathname: ACCOUNTS_CERTIFICATE_PURCHASE_URL,
      state: { orderId: rowObject?.orderId, certFor: 'production' },
    });
  };

  const actionObject = {
    VIEW: handleView,
  };

  const handlePagination = async ({ page, pageSize, searchKey, startDate, endDate, selected }) => {
    dispatch({ type: FETCH_PROD_CERT_PURCHASES, payload: null });
    const params = {
      page,
      endDate,
      pageSize,
      searchKey,
      startDate,
      status: selected?.['Payment Status'],
      type: selected?.['Certificate Type'],
    };
    const res = await getAllProductionCertPurchases(params);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_PROD_CERT_PURCHASES, payload: res.data });
    } else {
      dispatch({ type: FETCH_PROD_CERT_PURCHASES, payload: { purchases: [] } });
    }
  };

  const handleSearch = async ({ searchKey, startDate, endDate, selected }) => {
    dispatch({ type: FETCH_PROD_CERT_PURCHASES, payload: null });
    const params = {
      endDate,
      searchKey,
      startDate,
      status: selected?.['Payment Status'],
      type: selected?.['Certificate Type'],
    };
    const res = await getAllProductionCertPurchases(params);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_PROD_CERT_PURCHASES, payload: res.data });
    } else {
      dispatch({ type: FETCH_PROD_CERT_PURCHASES, payload: { purchases: [] } });
    }
  };

  const PAYMENT_STATUS = {
    name: 'Payment Status',
    choices: [
      { name: 'PAID', value: 'PAID' },
      { name: 'UNPAID', value: 'UNPAID' },
    ],
  };

  const CERT_TYPE = {
    name: 'Certificate Type',
    choices: [
      { name: 'CASH', value: 'CASH' },
      { name: 'GSP', value: 'GSP' },
    ],
  };

  return (
    <DataTable
      topPanel
      keySearch
      dateRange
      isPaginated
      columns={columns}
      search={handleSearch}
      actions={actionObject}
      data={data?.purchases}
      currentPage={data?.page}
      paginate={handlePagination}
      entries={data?.totalEntries}
      currentPageSize={data?.pageSize}
      caption="Production Certificates"
      pageSizes={LOGS_TABLE_DISPLAY_SIZE}
      selectOptions={[CERT_TYPE, PAYMENT_STATUS]}
    />
  );
};

export default ProductionCertTable;
