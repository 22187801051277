import React from 'react';
import * as yup from 'yup';
import { mobileNoValidation } from '../../../util/yupUtils';
import FormSection from '../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'username',
    type: 'text',
    label: 'Username',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'fullName',
    type: 'text',
    label: 'Full Name',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'email',
    type: 'text',
    label: 'Email',
    isRequired: true,
  },
  {
    colSpan: 1,
    name: 'phoneNo',
    type: 'text',
    label: 'Phone No',
    isRequired: true,
  },
  {
    colSpan: 2,
    fieldType: 'select',
    name: 'adminRole',
    label: 'Admin Role',
    isRequired: true,
    choices: [
      { name: 'Super-Admin', value: 'SUPERADMIN' },
      { name: 'Admin', value: 'ADMIN' },
      { name: 'Accounts', value: 'ACCOUNTS' },
      { name: 'Operations', value: 'OPERATIONS' },
      { name: 'Fee Collector', value: 'FEE_COLLECTOR' },
    ],
  },
];

const allFields = [
  fields[0],
  fields[1],
  fields[2],
  fields[3],
  {
    colSpan: 1,
    fieldType: 'select',
    name: 'adminRole',
    label: 'Admin Role',
    isRequired: true,
    choices: [
      { name: 'Super-Admin', value: 'SUPERADMIN' },
      { name: 'Admin', value: 'ADMIN' },
      { name: 'Accounts', value: 'ACCOUNTS' },
      { name: 'Operations', value: 'OPERATIONS' },
      { name: 'Fee Collector', value: 'FEE_COLLECTOR' },
    ],
  },
  {
    colSpan: 1,
    fieldType: 'select',
    name: 'userStatus',
    label: 'Admin Status',
    isRequired: true,
    choices: [
      { name: 'Active', value: 'ACTIVE' },
      { name: 'Disabled', value: 'DISABLED' },
    ],
  },
];

export const fieldsValidation = {
  [fields[0].name]: yup.string().required().min(8).label(fields[0].label),
  [fields[1].name]: yup.string().required().label(fields[1].label),
  [fields[2].name]: yup.string().email().required().label(fields[2].label),
  [fields[3].name]: mobileNoValidation.required().label(fields[3].label),
  [fields[4].name]: yup.string().required().label(fields[4].label),
};

export const allFieldsValidation = {
  [allFields[0].name]: yup.string().required().min(8).label(allFields[0].label),
  [allFields[1].name]: yup.string().required().label(allFields[1].label),
  [allFields[2].name]: yup.string().email().required().label(allFields[2].label),
  [allFields[3].name]: mobileNoValidation.required().label(allFields[3].label),
  [allFields[4].name]: yup.string().required().label(allFields[4].label),
  [allFields[5].name]: yup.string().required().label(allFields[5].label),
};

const UserInfoSection = (props) => {
  return (
    <FormSection sectionColumn={2} formFields={props.allFields ? allFields : fields} {...props} />
  );
};

export default UserInfoSection;
