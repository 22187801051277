//modules
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  Grid,
  GridItem,
  Box,
  Text,
  Checkbox,
  Divider,
  Button,
  useDisclosure,
} from '@chakra-ui/react';

//common
import Rework from '../Modals/Rework';
import RenderModal from '../RenderModal';
import Footer from '../../../common/membershipFormComponents/Footer';
import AltFooter from '../../../common/membershipFormComponents/AltFooter';
import MembershipFormWrapper from '../../../common/membershipFormComponents/MembershipFormWrapper';

//form sections
import NoteSection, { noteFieldValidation } from '../NoteSection';
import YarnExporCap, { yarnExpoCapFieldsValidation } from './sections/YarnExporCap';
import FabProcesCap, { fabProcesCapFieldsValidation } from './sections/FabProcesCap';
import FabRawMaterial, { fabRawMatFieldsValidation } from './sections/TexRawMaterial';
import YarnProcesCap, { yarnProcesCapFieldsValidation } from './sections/YarnProcesCap';
import FabricExpoTexCap, { fabExpoTexCapFieldsValidation } from './sections/FabricExpoTexCap';

//api and actions
import { patchMembershipData } from '../../../../api/membership';
import { notify } from '../../../../store/actions/globalActions';

//utils
import _ from 'lodash';
import { useYupValidationResolver } from '../../../../util/yupUtils';

//client from sections
import {
  SizingAndDesizing,
  SizingAndDesizingFieldsValidation,
  Shearing,
  ShearingFieldsValidation,
  ScouringAndBleaching,
  ScouringAndBleachingFieldsValidation,
  Drying,
  DryingFieldsValidation,
  Mercerizing,
  MercerizingFieldsValidation,
  Washing,
  WashingFieldsValidation,
  Stentering,
  StenteringFieldsValidation,
  Raising,
  RaisingFieldsValidation,
  Dyeing,
  DyeingFieldsValidation,
  Printing,
  PrintingFieldsValidation,
  Curing,
  CuringFieldsValidation,
  Steaming,
  SteamingFieldsValidation,
  JetDyeing,
  JetDyeingFieldsValidation,
  Jigger,
  JiggerFieldsValidation,
  Sanding,
  SandingFieldsValidation,
  Comfit,
  ComfitFieldsValidation,
  Sanforizing,
  SanforizingFieldsValidation,
  Decatizing,
  DecatizingFieldsValidation,
  Shrinkage,
  ShrinkageFieldsValidation,
  AnnualCapacitySection,
  AnnualCapacityFieldsValidation,
  RawMaterialRequirementSection,
  RawMaterialRequirementFieldsValidation,
  OtherRawMaterialInfoSection,
  OtherRawMaterialFieldsValidation,
  defaultOtherRawMaterialFieldValues,
  MachineInformationSection,
  defaultMachineInformationFieldValues,
  MachineInformationFieldsValidation,
  HosAnnualCapSection,
  HosAnnualCapFieldsValidation,
  TypeOfTestingEquipmentSection,
  TypeOfTestingEquipmentFieldsValidation,
  defaultTypeOfTestingEquipmentFieldValues,
} from './clientSections';

const TextileInfo = ({
  backToTable,
  nextStep,
  prevStep,
  currStep,
  defaultFields,
  infoView,
  refresh,
}) => {
  const { uid } = useParams();
  const dispatch = useDispatch();
  const [modal, setModal] = useState();
  const [updateData, setUpdateData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [textileInfo, setTextileInfo] = useState(false);
  const [modalComponent, setModalComponent] = useState();
  const [rawMaterialInfo, setRawMaterialInfo] = useState(false);

  const textileInfoDefaults = [
    'texYarnExpoCap',
    'texYarnProcCap',
    'texFabricKnitProcCap',
    'texFabricKnitExpoCap',
    'texFabricWovenExpoCap',
    'texFabricWovenProcCap',
  ];

  const rawMaterialInfoDefaults = ['fabWoven', 'fabKnit', 'yarns', 'dice', 'chemical'];

  const clientSectionValidation = {
    ...DryingFieldsValidation,
    ...DyeingFieldsValidation,
    ...CuringFieldsValidation,
    ...JiggerFieldsValidation,
    ...ComfitFieldsValidation,
    ...WashingFieldsValidation,
    ...RaisingFieldsValidation,
    ...SandingFieldsValidation,
    ...ShearingFieldsValidation,
    ...PrintingFieldsValidation,
    ...SteamingFieldsValidation,
    ...JetDyeingFieldsValidation,
    ...ShrinkageFieldsValidation,
    ...StenteringFieldsValidation,
    ...DecatizingFieldsValidation,
    ...MercerizingFieldsValidation,
    ...SanforizingFieldsValidation,
    ...HosAnnualCapFieldsValidation,
    ...AnnualCapacityFieldsValidation,
    ...OtherRawMaterialFieldsValidation,
    ...SizingAndDesizingFieldsValidation,
    ...MachineInformationFieldsValidation,
    ...ScouringAndBleachingFieldsValidation,
    ...RawMaterialRequirementFieldsValidation,
    ...TypeOfTestingEquipmentFieldsValidation,
  };

  const getFormValidationSchema = () => {
    if (textileInfo && rawMaterialInfo) {
      return yup.object().shape({
        ...noteFieldValidation,
        ...clientSectionValidation,
        ...fabRawMatFieldsValidation,
        ...yarnExpoCapFieldsValidation,
        ...fabProcesCapFieldsValidation,
        ...fabExpoTexCapFieldsValidation,
        ...yarnProcesCapFieldsValidation,
      });
    } else if (textileInfo) {
      return yup.object().shape({
        ...noteFieldValidation,
        ...clientSectionValidation,
        ...yarnExpoCapFieldsValidation,
        ...fabProcesCapFieldsValidation,
        ...fabExpoTexCapFieldsValidation,
        ...yarnProcesCapFieldsValidation,
      });
    } else if (rawMaterialInfo) {
      return yup.object().shape({
        ...noteFieldValidation,
        ...clientSectionValidation,
        ...fabRawMatFieldsValidation,
      });
    } else {
      return yup.object().shape({ ...noteFieldValidation, ...clientSectionValidation });
    }
  };

  useEffect(() => {
    for (let key of textileInfoDefaults) {
      if (defaultFields?.[key]) {
        setTextileInfo(true);
      }
    }
    for (let key of rawMaterialInfoDefaults) {
      if (defaultFields?.[key]) {
        setRawMaterialInfo(true);
      }
    }
  }, []);

  const resolver = useYupValidationResolver(getFormValidationSchema());
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: {
      ...defaultFields,
      machineInfo: defaultFields.machineInfo
        ? [...defaultFields.machineInfo]
        : [{ ...defaultMachineInformationFieldValues }],
      otherRawMaterials: defaultFields.otherRawMaterials
        ? [...defaultFields.otherRawMaterials]
        : [{ ...defaultOtherRawMaterialFieldValues }],
      typeOfTestingEquipment: defaultFields.typeOfTestingEquipment?.length
        ? [...defaultFields.typeOfTestingEquipment]
        : [{ ...defaultTypeOfTestingEquipmentFieldValues }],
    },
  });

  const prepareData = (formdata) => {
    const {
      machineInfo,
      mainItemsManufactured,
      typeOfTestingEquipment,
      annualRequirementOfYarn,
      annualProductionCapacity,

      dice,
      yarns,
      fabKnit,
      fabWoven,
      chemical,
      texYarnExpoCap,
      texYarnProcCap,
      texFabricKnitProcCap,
      texFabricKnitExpoCap,
      texFabricWovenProcCap,
      texFabricWovenExpoCap,

      ...rest
    } = formdata;

    const sendData = {
      textileProductProcessors: {
        ...rest,
      },

      hosieryKnittingMills: {
        machineInfo,
        mainItemsManufactured,
        typeOfTestingEquipment,
        annualRequirementOfYarn,
        annualProductionCapacity,
      },

      textileProductProcessorSummary: {
        dice,
        yarns,
        fabKnit,
        fabWoven,
        chemical,
        texYarnExpoCap,
        texYarnProcCap,
        texFabricKnitProcCap,
        texFabricKnitExpoCap,
        texFabricWovenProcCap,
        texFabricWovenExpoCap,
      },
    };

    return sendData;
  };

  const handleQuery = async (queryData, changeStep, flag) => {
    if (flag) {
      const res = await patchMembershipData({
        formData: prepareData(queryData),
        step: currStep,
        param: uid,
      });
      if (res.data?.status == 200) {
        dispatch(
          notify({
            title: 'Success',
            description: res.data.msg,
            status: 'success',
            duration: 5000,
          }),
        );
        changeStep();
      }
    } else {
      const cleanQueryData = _.pickBy(queryData, _.identity);
      const cleanDefaultFields = _.pickBy(defaultFields, _.identity);
      if (_.isEqual(cleanQueryData, cleanDefaultFields) === false) {
        const res = await patchMembershipData({
          formData: prepareData(queryData),
          step: currStep,
          param: uid,
        });
        if (res.data?.status == 200) {
          dispatch(
            notify({
              title: 'Success',
              description: res.data.msg,
              status: 'success',
              duration: 5000,
            }),
          );
          changeStep();
        }
      } else {
        changeStep();
      }
    }
  };

  const onSubmit = (formdata) => {
    const cleanQueryData = _.pickBy(formdata, _.identity);
    const cleanDefaultFields = _.pickBy(defaultFields, _.identity);
    if (_.isEqual(cleanQueryData, cleanDefaultFields) === false) {
      setUpdateData(formdata);
      setModal('UpdateAlert');
      onOpen();
    } else {
      setModal('NoUpdate');
      onOpen();
    }
  };

  const onForwardSubmit = (formData) => {
    handleQuery(formData, nextStep);
  };

  const onBackwardSubmit = (formData) => {
    handleQuery(formData, prevStep);
  };

  const handleBackToTable = () => {
    setModal('BackToTableAlert');
    onOpen();
  };

  const handleRework = () => {
    setModal('ReworkModal');
    setModalComponent(<Rework close={onClose} backToTable={backToTable} />);
    onOpen();
  };

  const handleReject = () => {
    setModal('RejectModal');
    onOpen();
  };

  const rejectMembershipRequest = async () => {
    const res = await patchMembershipData({ param: uid, reject: true });
    if (res.data?.status == 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      onClose();
      backToTable();
    }
  };

  return (
    <Box>
      <MembershipFormWrapper formName="Product Processing Information">
        {/* client data */}
        <Grid templateColumns="repeat(1, 1fr)" bg="white" gap={3} p={3}>
          <GridItem>
            <Text fontSize="xl" fontWeight="bold">
              Machineries
            </Text>
          </GridItem>
          <GridItem>
            <SizingAndDesizing register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <Shearing register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <ScouringAndBleaching register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <Drying register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <Mercerizing register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <Washing register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <Stentering register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <Raising register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <Dyeing register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <Printing register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <Curing register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <Steaming register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <JetDyeing register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <Jigger register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <Sanding register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <Comfit register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <Sanforizing register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <Decatizing register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <Shrinkage register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <Divider />
          </GridItem>
          <GridItem>
            <AnnualCapacitySection register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem>
            <Divider />
          </GridItem>
          <GridItem>
            <RawMaterialRequirementSection register={register} control={control} errors={errors} />
            <OtherRawMaterialInfoSection register={register} control={control} errors={errors} />
          </GridItem>
        </Grid>

        <Text mt={4} mb={2} fontSize="xl" fontWeight="bold">
          Hosiery Knitting Mill Information
        </Text>
        <Divider mb={2} />
        <Grid templateColumns="repeat(1, 1fr)" bg="white" gap={3} p={3}>
          <GridItem>
            <MachineInformationSection register={register} errors={errors} control={control} />
            <HosAnnualCapSection register={register} errors={errors} />
          </GridItem>
          <GridItem>
            <Divider />
          </GridItem>
          <GridItem>
            <TypeOfTestingEquipmentSection register={register} errors={errors} control={control} />
          </GridItem>
        </Grid>

        {/* admin inputs */}
        <Text
          mb={2}
          pb={2}
          mt={10}
          fontSize="xl"
          fontWeight="bold"
          textAlign="center"
          color="textSecondary"
          borderStyle="dashed"
          borderBottom="2px solid #6B7982">
          Additional Information
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" bg="white" gap={3} p={3}>
          <GridItem>
            <Checkbox
              px={4}
              pt={4}
              pb={1}
              isChecked={textileInfo}
              onChange={textileInfo ? () => setTextileInfo(false) : () => setTextileInfo(true)}>
              <Text fontSize="20px" fontWeight="bold">
                Add Product Processing Information
              </Text>
            </Checkbox>
            <Box p={1} pointerEvents={textileInfo ? 'all' : 'none'} opacity={textileInfo ? 1 : 0.3}>
              <Grid templateColumns="repeat(2, 1fr)" gap={0}>
                <GridItem>
                  <FabProcesCap register={register} control={control} errors={errors} />
                </GridItem>
                <GridItem>
                  <FabricExpoTexCap register={register} control={control} errors={errors} />
                </GridItem>
                <GridItem>
                  <YarnProcesCap register={register} control={control} errors={errors} />
                </GridItem>
                <GridItem>
                  <YarnExporCap register={register} control={control} errors={errors} />
                </GridItem>
              </Grid>
            </Box>
          </GridItem>
          <GridItem>
            <Checkbox
              px={4}
              pt={4}
              pb={1}
              isChecked={rawMaterialInfo}
              onChange={
                rawMaterialInfo ? () => setRawMaterialInfo(false) : () => setRawMaterialInfo(true)
              }>
              <Text fontSize="20px" fontWeight="bold">
                Add Raw Material Information
              </Text>
            </Checkbox>
            <Box
              p={1}
              pointerEvents={rawMaterialInfo ? 'all' : 'none'}
              opacity={rawMaterialInfo ? 1 : 0.3}>
              <Grid templateColumns="repeat(1, 1fr)" gap={0}>
                <GridItem>
                  <FabRawMaterial register={register} control={control} errors={errors} />
                </GridItem>
              </Grid>
            </Box>
          </GridItem>
        </Grid>
        <Box mt={3} p={3} bg="white">
          <NoteSection register={register} control={control} errors={errors} />
        </Box>
      </MembershipFormWrapper>
      {infoView ? (
        <AltFooter back={backToTable} buttonLoading={isSubmitting}>
          <Button
            type="submit"
            variant="footerUpdate"
            isLoading={isSubmitting}
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onSubmit)();
            }}>
            Update
          </Button>
        </AltFooter>
      ) : (
        <Footer
          currStep={currStep}
          rework={handleRework}
          reject={handleReject}
          back={handleBackToTable}
          buttonLoading={isSubmitting}
          next={(event) => {
            event.preventDefault();
            handleSubmit(onForwardSubmit)();
          }}
          prev={(event) => {
            event.preventDefault();
            handleSubmit(onBackwardSubmit)();
          }}
        />
      )}
      <RenderModal
        modal={modal}
        step={currStep}
        isOpen={isOpen}
        onClose={onClose}
        backToTable={backToTable}
        modalComponent={modalComponent}
        confirmReject={rejectMembershipRequest}
        confirmSubmit={() => handleQuery(updateData, refresh, true)}
      />
    </Box>
  );
};

export default TextileInfo;
