import { combineReducers } from 'redux';
import auth from './auth';
import membership from './membership';
import certificate from './certificate';
import accounts from './accounts';
import labour from './labour';
import settings from './settings';
import global from './global';

export default combineReducers({
  auth,
  global,
  membership,
  certificate,
  accounts,
  labour,
  settings,
});
