import React from 'react';
import * as yup from 'yup';
import { dateValidation } from '../../../../../util/yupUtils';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'chequeNo',
    type: 'text',
    label: 'Cheque Serial',
    isRequired: true,
  },
  {
    colSpan: 1,
    fieldType: 'date',
    name: 'chequeDate',
    label: 'Cheque Date',
    isRequired: true,
  },
];

const eftFields = fields.map((item, index) => ({
  ...item,
  label: index === 0 ? 'Transaction Id' : 'Transaction Date',
}));

const mfsFields = fields.map((item, index) => ({
  ...item,
  label: index === 0 ? 'Mobile No./Transaction Id' : 'Transaction Date',
}));

export const chequeInfoFieldsValidation = (method) => ({
  [fields[0].name]: yup
    .string()
    .nullable()
    .required()
    .label(
      method === 'EFT'
        ? eftFields[0].label
        : method === 'MFS'
        ? mfsFields[0].label
        : fields[0].label,
    ),
  [fields[1].name]: dateValidation
    .required()
    .label(
      method === 'EFT'
        ? eftFields[1].label
        : method === 'MFS'
        ? mfsFields[1].label
        : fields[1].label,
    ),
});

const ChequeInfoSection = (props) => {
  return (
    <FormSection
      secondary
      sectionColumn={2}
      formFields={
        props?.method === 'EFT' ? eftFields : props?.method === 'MFS' ? mfsFields : fields
      }
      {...props}
    />
  );
};

export default ChequeInfoSection;
