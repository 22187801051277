import {
  Box,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  FormControl,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdEventNote } from 'react-icons/md';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../hookFormComponents/DatePickerField.css';

const DateRangeSection = ({ spacing, setDefaultDate, data, clearSelection, fieldsCleared }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const dateRange = { startDate, endDate };

  useEffect(() => {
    if (clearSelection) {
      setStartDate();
      setEndDate();
      fieldsCleared();
    }
  }, [clearSelection]);

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      data(dateRange);
    } else if (!dateRange.startDate) {
      setStartDate(endDate);
    } else if (!dateRange.endDate) {
      setEndDate(startDate);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (setDefaultDate) {
      setStartDate(new Date(`${new Date().getFullYear()}-01-01`));
      setEndDate(new Date());
    }
  }, [setDefaultDate]);

  return (
    <HStack zIndex={2} spacing={spacing}>
      <Box>
        <FormControl>
          <InputGroup>
            <InputRightElement>
              <Icon as={MdEventNote} w={6} h={6} />
            </InputRightElement>
            <ReactDatePicker
              id="published-date"
              placeholderText="Start date"
              selected={startDate}
              onChange={(e) => setStartDate(e)}
              customInput={
                <Input
                  variant="outline"
                  minW="140px"
                  maxW="180px"
                  focusBorderColor="primary.300"
                  borderRadius="2px"
                />
              }
              dateFormat="dd/MM/yyyy"
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
            />
          </InputGroup>
        </FormControl>
      </Box>
      <Box>
        <FormControl>
          <InputGroup>
            <InputRightElement>
              <Icon as={MdEventNote} w={6} h={6} />
            </InputRightElement>
            <ReactDatePicker
              id="published-date"
              placeholderText="End date"
              selected={endDate}
              onChange={(e) => setEndDate(e)}
              customInput={
                <Input
                  variant="outline"
                  minW="140px"
                  maxW="180px"
                  focusBorderColor="primary.300"
                  borderRadius="2px"
                />
              }
              dateFormat="dd/MM/yyyy"
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
            />
          </InputGroup>
        </FormControl>
      </Box>
    </HStack>
  );
};

export default DateRangeSection;
