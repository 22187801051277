import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'numOfCert',
    label: 'No. of Certificates',
    isRequired: true,
  },
];

export const certQuantityFieldsValidation = {
  [fields[0].name]: yup
    .number()
    .typeError(`${fields[0].label} must be a number`)
    .required(`${fields[0].label} is a required field`),
};

const CertQuantity = (props) => {
  return (
    <FormSection
      sectionName="Certificate Quantity"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default CertQuantity;
