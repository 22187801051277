import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'cWCountryOrManufacturer',
    type: 'text',
    label: 'Country/ Manufacturer',
  },
  {
    colSpan: 1,
    name: 'cWYearOfManufacture',
    type: 'text',
    label: 'Year of Manufacture',
  },
  {
    fieldType: 'radio',
    colSpan: 1,
    name: 'cWOption',
    defaultValue: 'New',
    choices: [
      { name: 'New', value: 'New' },
      { name: 'Re-conditioned', value: 'Re-conditioned' },
    ],
  },
];

export const ConeWindingFieldsValidation = {
  [fields[0].name]: yup.string(),
  [fields[1].name]: yup.string(),
  [fields[2].name]: yup.string(),
};

const ConeWinding = (props) => {
  return (
    <FormSection sectionName="Cone Winding" sectionColumn={3} formFields={fields} {...props} />
  );
};

export default ConeWinding;
