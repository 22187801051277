import React, { useEffect, useRef } from 'react';
import { Modal, ModalOverlay, ModalCloseButton, Box } from '@chakra-ui/react';

const BasicModal = ({ mountControl, close, children }) => {
  useEffect(() => {
    if (mountControl) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [mountControl]);

  const isOpen = mountControl;
  const cancelRef = useRef();

  return (
    <Modal isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={close}>
      <ModalOverlay>
        <Box position="absolute" w="100vw" h="100vh" onClick={close} />
        <Box
          pb={2}
          w="480px"
          top="50%"
          left="50%"
          bg="white"
          overflow="auto"
          borderRadius={10}
          position="absolute"
          transform="translate(-50%, -50%)"
          boxShadow="0px 5px 10px rgba(0,0,0,0.3)">
          <ModalCloseButton top="10px" right="10px" zIndex="1000" />
          {children}
        </Box>
      </ModalOverlay>
    </Modal>
  );
};

export default BasicModal;
