import React, { useEffect, useState } from 'react';
import Record from './Record';
import { useHistory } from 'react-router';
import Loader from '../../../snippets/Loader';
import { renderDate } from '../../../../util/formatUtils';
import { getApplicationInfo } from '../../../../api/certificates';
import { defaultProductArrayFieldValues } from '../../commonSections/ProductInformation';

const ProcessingApplication = () => {
  const history = useHistory();
  const [millInfo, setMillInfo] = useState();
  const [defaultDocs, setDefaultDocs] = useState();
  const [defaultFiles, setDefaultFiles] = useState();
  const [defaultFields, setDefaultFields] = useState();
  const { applicationId } = history.location.state;

  const handleQuery = async () => {
    setDefaultFields();
    setDefaultFiles();
    const res = await getApplicationInfo({ type: 'processing', id: applicationId });
    if (res.data?.status === 200) {
      const data = res.data.certificate;
      const processing = data?.processingTextile || data?.processingFabric;

      setMillInfo({
        ...data?.millInfo,
        issueDate: renderDate(processing?.issueDate),
        certId: data?.id,
        certificateNum: data?.certificateNum,
        product: data?.processingTextile ? 'Textile' : 'Fabric',
      });
      let fields = {
        masterLc: processing?.masterLc,
        totalQuantity: processing?.totalQuantity,
        dateOfLc: processing?.dateOfLc,
        exciseGatePassNum: processing?.exciseGatePassNum,
        exciseGatePassDate: processing?.exciseGatePassDate,
        btbLc: processing?.btbLc,
        valueInUsd: processing?.valueInUsd,
        bank: processing?.bank?.split(',')?.[0],
        bankBranch: processing?.bank?.split(',')?.[1],
        btbLcDate: processing?.btbLcDate,
        productDetails: processing?.productDetails?.length
          ? [...processing.productDetails]
          : [...defaultProductArrayFieldValues],
        producedBy: processing?.producedBy,
        processedBy: processing?.processedBy,
        videCertificateNo: processing?.videCertificateNo,
        videCertificateDate: processing?.videCertificateDate,
        suppliedTo: processing?.suppliedTo,
        towelsOthers: processing?.towelsOthers,
        fabric: processing?.fabric,
      };
      data?.processingTextile
        ? setDefaultFields({
            ...fields,
            autoconed: processing?.autoconed,
            nonAutoconed: processing?.nonAutoconed,
            openEnd: processing?.openEnd,
          })
        : setDefaultFields({
            ...fields,
            apparel: processing?.apparel,
            homeFurnishing: processing?.homeFurnishing,
            quantityOthers: processing?.quantityOthers,
          });
      setDefaultFiles([
        {
          header: 'Amendments',
          link: processing?.amendments,
        },
        {
          header: 'Proforma Invoice',
          link: processing?.proformaInvoice,
        },
        {
          header: 'Commercial Invoice',
          link: processing?.commercialInvoice,
        },
        {
          header: 'L/C Bank Documents',
          link: processing?.lcBankDocuments,
        },
      ]);
      setDefaultDocs({
        amendments: processing?.amendments,
        proformaInvoice: processing?.proformaInvoice,
        commercialInvoice: processing?.commercialInvoice,
        lcBankDocuments: processing?.lcBankDocuments,
      });
    }
  };

  useEffect(() => {
    handleQuery();
  }, []);

  return defaultFields && defaultFiles && defaultDocs ? (
    <Record
      millData={millInfo}
      refresh={handleQuery}
      defaultDocs={defaultDocs}
      defaultFiles={defaultFiles}
      defaultFields={defaultFields}
    />
  ) : (
    <Loader />
  );
};

export default ProcessingApplication;
