import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Step, Steps, useSteps } from 'chakra-ui-steps';

import Summary from '../SummaryView/Summary';
import Loader from '../../../snippets/Loader';
import OtherInfo from '../OtherInfo/OtherInfo';
import NavPanel from '../../../common/NavPanel';
import YarnInfo from '../YarnInformation/YarnInfo';
import FabricInfo from '../FabricInformation/FabricInfo';
import GeneralInfo from '../GeneralInformation/GeneralInfo';
import TextileInfo from '../TextileInformation/TextileInfo';

import { getMembershipRequest } from '../../../../api/membership';
import { FETCH_MEMBERSHIP_REQUEST } from '../../../../store/actions/actionTypes';
import { NEW_REQUEST_VERIFICATION_TABS } from '../../../../constants/membershipConstants';
import UsersTab from '../../members/RegisteredMember/UsersTab';

const StepsSection = ({ backToTable, infoView }) => {
  const { uid } = useParams();
  const dispatch = useDispatch();
  const [scroll, setScroll] = useState(false);
  const [selected, setSelected] = useState(0);
  const [defaultState, setDefaultState] = useState(false);
  const data = useSelector((state) => state.membership.currentRequest);
  console.log(data);

  const handleScroll = () => {
    window.scrollY > 10 ? setScroll(true) : setScroll(false);
  };

  window.addEventListener('scroll', handleScroll);

  const { nextStep, prevStep, setStep, activeStep } = useSteps({
    initialStep: 0,
  });

  useEffect(() => {
    if (!infoView) {
      document.body.style.overflow = 'unset';
      window.scrollTo(0, 0);
      dispatch({ type: FETCH_MEMBERSHIP_REQUEST, payload: null });
      activeStep < 5
        ? (async () => {
            const res = await getMembershipRequest({ param1: uid, param2: activeStep });
            if (res.data?.status === 200) {
              dispatch({ type: FETCH_MEMBERSHIP_REQUEST, payload: res.data.membershipData });
            } else {
              dispatch({ type: FETCH_MEMBERSHIP_REQUEST, payload: null });
            }
          })()
        : dispatch({ type: FETCH_MEMBERSHIP_REQUEST, payload: null });
    }
  }, [activeStep]);

  useEffect(() => {
    document.body.style.overflow = 'unset';
    window.scrollTo(0, 0);
    dispatch({ type: FETCH_MEMBERSHIP_REQUEST, payload: null });
    selected < 5
      ? (async () => {
          const res = await getMembershipRequest({ param1: uid, param2: selected });
          if (res.data?.status === 200) {
            dispatch({ type: FETCH_MEMBERSHIP_REQUEST, payload: res.data.membershipData });
          } else {
            dispatch({ type: FETCH_MEMBERSHIP_REQUEST, payload: null });
          }
        })()
      : dispatch({ type: FETCH_MEMBERSHIP_REQUEST, payload: null });
  }, [selected]);

  useEffect(() => {
    if (!defaultState) {
      if (data?.verification?.step >= 0) {
        setDefaultState(true);
        let step = parseInt(data?.verification?.step);
        setStep(step + 1);
      }
    }
  }, [data?.verification?.step]);

  const handleRefresh = () => {
    document.body.style.overflow = 'unset';
    window.scrollTo(0, 0);
    dispatch({ type: FETCH_MEMBERSHIP_REQUEST, payload: null });
    selected < 5
      ? (async () => {
          const res = await getMembershipRequest({ param1: uid, param2: selected });
          if (res.data?.status === 200) {
            dispatch({ type: FETCH_MEMBERSHIP_REQUEST, payload: res.data.membershipData });
          } else {
            dispatch({ type: FETCH_MEMBERSHIP_REQUEST, payload: null });
          }
        })()
      : dispatch({ type: FETCH_MEMBERSHIP_REQUEST, payload: null });
  };

  const stepList = NEW_REQUEST_VERIFICATION_TABS;

  const navList = [
    'General Info',
    'Yarn Info',
    'Fabric Info',
    'Textile Info',
    'Other Info',
    'User Info',
  ];

  const contentList = [
    <GeneralInfo
      key={0}
      infoView={infoView}
      nextStep={nextStep}
      refresh={handleRefresh}
      backToTable={backToTable}
      currStep={infoView ? selected : activeStep}
      defaultFields={{
        website: data?.website,
        millName: data?.millName,
        yearOfOperation: data?.yearOfOperation,
        dateOfEstablishment: data?.dateOfEstablishment,
        membershipDate: data?.membershipDate,
        membershipCategory: data?.membershipCategory,
        boardApprovalDate: data?.boardApprovalDate,
        proposerName: data?.proposerName,
        proposerDesignation: data?.proposerDesignation,
        proposerMillName: data?.proposerMillName,
        seconderName: data?.seconderName,
        seconderDesignation: data?.seconderDesignation,
        seconderMillName: data?.seconderMillName,
        ...data?.hoInfo,
        ...data?.msInfo,
        ...data?.mdInfo,
        ...data?.otherInfo,
        ...data?.chairmanInfo,
        ...data?.sourceOfCapital,
        ...data?.powerManagement,
        ...data?.capitalStructure,
        ...data?.millPresentatorInfo,
        ...data?.powerManagementSummary,
        ...data?.bondedWarehouseLicenseNo,
        ...data?.marketingOfFinishedProducts,
        ...data?.dateOfCommencementOfProduction,
      }}
    />,
    <YarnInfo
      key={1}
      infoView={infoView}
      nextStep={nextStep}
      prevStep={prevStep}
      refresh={handleRefresh}
      backToTable={backToTable}
      currStep={infoView ? selected : activeStep}
      defaultFields={{ ...data?.yarnManufacturer, ...data?.yarnManufacturerSummary }}
    />,
    <FabricInfo
      key={2}
      infoView={infoView}
      nextStep={nextStep}
      prevStep={prevStep}
      refresh={handleRefresh}
      backToTable={backToTable}
      currStep={infoView ? selected : activeStep}
      defaultFields={{
        ...data?.knitFabric,
        ...data?.fabricsManufacturer,
        ...data?.fabricsManufacturerSummary,
        flaxYarnKnit: data?.knitFabric?.flaxYarn,
        tencelYarnKnit: data?.knitFabric?.tencelYarn,
        cottonYarnKnit: data?.knitFabric?.cottonYarn,
        viscousYarnKnit: data?.knitFabric?.viscousYarn,
        syntheticYarnKnit: data?.knitFabric?.syntheticYarn,
        polyesterYarnKnit: data?.knitFabric?.polyesterYarn,
        knitOtherRawMaterials: data?.knitFabric?.otherRawMaterials,
        weavOtherRawMaterials: data?.fabricsManufacturer?.otherRawMaterials,
      }}
    />,
    <TextileInfo
      key={3}
      infoView={infoView}
      nextStep={nextStep}
      prevStep={prevStep}
      refresh={handleRefresh}
      backToTable={backToTable}
      currStep={infoView ? selected : activeStep}
      defaultFields={{
        ...data?.textileProductProcessors,
        ...data?.hosieryKnittingMills,
        ...data?.textileProductProcessorSummary,
      }}
    />,
    <OtherInfo
      key={4}
      infoView={infoView}
      stepList={stepList}
      nextStep={nextStep}
      prevStep={prevStep}
      refresh={handleRefresh}
      backToTable={backToTable}
      currStep={infoView ? selected : activeStep}
      defaultFields={{ ...data?.humanResources }}
    />,
    <Summary
      key={5}
      infoView={infoView}
      prevStep={prevStep}
      stepList={stepList}
      currStep={activeStep}
      backToTable={backToTable}
    />,
    <UsersTab key={6} backToTable={backToTable} />,
  ];

  const steps = [
    { label: stepList[0] },
    { label: stepList[1] },
    { label: stepList[2] },
    { label: stepList[3] },
    { label: stepList[4] },
    { label: stepList[5] },
  ];

  const renderContent = () => {
    switch (activeStep) {
      case 0:
        return contentList[0];
      case 1:
        return contentList[1];
      case 2:
        return contentList[2];
      case 3:
        return contentList[3];
      case 4:
        return contentList[4];
      case 5:
        return contentList[5];
      default:
        return contentList[0];
    }
  };

  const renderTab = () => {
    switch (selected) {
      case 0:
        return contentList[0];
      case 1:
        return contentList[1];
      case 2:
        return contentList[2];
      case 3:
        return contentList[3];
      case 4:
        return contentList[4];
      case 5:
        return contentList[6];
      default:
        return contentList[0];
    }
  };

  return (
    <Box>
      <Box
        p={4}
        right="0px"
        zIndex={98}
        id="navWrapper"
        position="sticky"
        bg={infoView ? 'secondary' : 'white'}
        boxShadow={scroll ? '0px 1px 2px rgba(0,0,0,0.2)' : 'none'}
        top={`${document?.getElementById('headerWrapper')?.offsetHeight}px`}>
        {infoView ? (
          <NavPanel navState={selected} setNavState={setSelected} navList={navList} />
        ) : (
          <Steps colorScheme="primary" activeStep={activeStep} size="sm">
            {steps.map(({ label }) => (
              <Step label={label} key={label} />
            ))}
          </Steps>
        )}
      </Box>

      {infoView ? (
        selected == 5 ? (
          renderTab()
        ) : data ? (
          renderTab()
        ) : (
          <Loader />
        )
      ) : activeStep == 5 ? (
        renderContent()
      ) : data ? (
        renderContent()
      ) : (
        <Loader />
      )}
    </Box>
  );
};

export default StepsSection;
