//modules
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Grid, GridItem, Divider, Checkbox, useDisclosure, Box } from '@chakra-ui/react';

//common
import DialogueBox from '../../../../common/DialogueBox';
import Footer from '../../../../common/membershipFormComponents/Footer';
import MembershipFormWrapper from '../../../../common/membershipFormComponents/MembershipFormWrapper';

//form sections
import TextileFabricProcCap, {
  textileFabricProcCapFieldsValidation,
} from './sections/TextileFabricProcCap';
import TextileFabricExpoCap, {
  textileFabricExpoCapFieldsValidation,
} from './sections/TextileFabricExpoCap';
import YarnCap, { yarnCapFieldsValidation } from './sections/YarnCap';
import YarnInsCap, { yarnInsCapFieldsValidation } from './sections/YarnInsCap';
import FabricInsCap, { fabricInsCapFieldsValidation } from './sections/FabricInsCap';
import FabricProdCap, { fabricProdCapFieldsValidation } from './sections/FabricProdCap';
import FabricExpoCap, { fabricExpoCapFieldsValidation } from './sections/FabricExpoCap';
import TextileYarnCap, { textileYarnCapFieldsValidation } from './sections/TextileYarnCap';
import YarnInOperation, { yarnInOperationFieldsValidation } from './sections/YarnInOperation';

//utils
import _ from 'lodash';
import { useYupValidationResolver } from '../../../../../util/yupUtils';
import { getFormData, removeFormData, setFormData } from '../../../../../util/localStorageUtils';

//validation schema
const yarnValidatonSchema = yup.object().shape({
  ...yarnCapFieldsValidation,
  ...yarnInsCapFieldsValidation,
  ...yarnInOperationFieldsValidation,
});
const fabricValidatonSchema = yup.object().shape({
  ...fabricInsCapFieldsValidation,
  ...fabricProdCapFieldsValidation,
  ...fabricExpoCapFieldsValidation,
});
const textileValidatonSchema = yup.object().shape({
  ...textileYarnCapFieldsValidation,
  ...textileFabricProcCapFieldsValidation,
  ...textileFabricExpoCapFieldsValidation,
});
const yarnFabricValidatonSchema = yup.object().shape({
  ...yarnCapFieldsValidation,
  ...yarnInsCapFieldsValidation,
  ...fabricInsCapFieldsValidation,
  ...fabricProdCapFieldsValidation,
  ...fabricExpoCapFieldsValidation,
  ...yarnInOperationFieldsValidation,
});
const fabricTextileValidatonSchema = yup.object().shape({
  ...fabricInsCapFieldsValidation,
  ...fabricProdCapFieldsValidation,
  ...fabricExpoCapFieldsValidation,
});
const textileYarnValidatonSchema = yup.object().shape({
  ...yarnCapFieldsValidation,
  ...yarnInsCapFieldsValidation,
  ...yarnInOperationFieldsValidation,
});
const allInfoValidationSchema = yup.object().shape({
  ...yarnCapFieldsValidation,
  ...yarnInsCapFieldsValidation,
  ...fabricInsCapFieldsValidation,
  ...fabricProdCapFieldsValidation,
  ...fabricExpoCapFieldsValidation,
  ...textileYarnCapFieldsValidation,
  ...yarnInOperationFieldsValidation,
  ...textileFabricProcCapFieldsValidation,
  ...textileFabricExpoCapFieldsValidation,
});

const ProductInfo = ({ backToTable, prevStep, nextStep, currStep, stepList, isDataStored }) => {
  const [isYarn, setIsYarn] = useState(false);
  const [isFabric, setIsFabric] = useState(false);
  const [isTextile, setIsTextile] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formData = getFormData('addMemberStepB');

  const defaultFields = {
    ...formData?.yarnManu,
    ...formData?.fabricManu,
    ...formData?.textileManu,
  };

  useEffect(() => {
    for (let key in formData?.fabricManu) {
      if (defaultFields?.[key]) {
        setIsFabric(true);
      }
    }
    for (let key in formData?.textileManu) {
      if (defaultFields?.[key]) {
        setIsTextile(true);
      }
    }
  }, []);

  const getValidationSchema = () => {
    if (isYarn && !isFabric && !isTextile) {
      return yarnValidatonSchema;
    } else if (isFabric && !isYarn && !isTextile) {
      return fabricValidatonSchema;
    } else if (isTextile && !isFabric && !isYarn) {
      return textileValidatonSchema;
    } else if (isFabric && isYarn && !isTextile) {
      return yarnFabricValidatonSchema;
    } else if (isTextile && isFabric && !isYarn) {
      return fabricTextileValidatonSchema;
    } else if (isTextile && isYarn && !isFabric) {
      return textileYarnValidatonSchema;
    } else if (isFabric && isYarn && isTextile) {
      return allInfoValidationSchema;
    } else {
      return null;
    }
  };

  const resolver = useYupValidationResolver(getValidationSchema());

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: { ...defaultFields },
  });

  const handleBackToTable = () => {
    if (isDataStored()) {
      onOpen();
    } else {
      backToTable();
    }
  };

  const prepareData = (data) => {
    const {
      otherInOp,
      rotorsInOp,
      yarnProdCap,
      yarnExpoCap,
      otherInsCap,
      rotorsInsCap,
      autoConeInOp,
      spindlesInOp,
      spindlesInsCap,
      autoConeInsCap,

      airjetInsCap,
      repairInsCap,
      othersInsCap,
      shuttleInsCap,
      knittingInsCap,
      shuttleLessInsCap,
      fabricKnitExpoCap,
      fabricKnitProdCap,
      fabricWovenExpoCap,
      fabricWovenProdCap,

      texYarnExpoCap,
      texYarnProcCap,
      texFabricKnitExpoCap,
      texFabricKnitProcCap,
      texFabricWovenExpoCap,
      texFabricWovenProcCap,
    } = data;

    const saveData = {
      yarnManu: {
        otherInOp,
        rotorsInOp,
        yarnProdCap,
        yarnExpoCap,
        otherInsCap,
        rotorsInsCap,
        autoConeInOp,
        spindlesInOp,
        spindlesInsCap,
        autoConeInsCap,
      },
      fabricManu: {
        airjetInsCap,
        repairInsCap,
        othersInsCap,
        shuttleInsCap,
        knittingInsCap,
        shuttleLessInsCap,
        fabricKnitExpoCap,
        fabricKnitProdCap,
        fabricWovenExpoCap,
        fabricWovenProdCap,
      },
      textileManu: {
        texYarnExpoCap,
        texYarnProcCap,
        texFabricKnitExpoCap,
        texFabricKnitProcCap,
        texFabricWovenExpoCap,
        texFabricWovenProcCap,
      },
    };

    return saveData;
  };

  const onForwardSubmit = (data) => {
    if (_.isEqual(defaultFields, data) === false) {
      setFormData('addMemberStepB', prepareData(data));
      nextStep();
    } else {
      nextStep();
    }
  };

  const onBackwardSubmit = (data) => {
    if (_.isEqual(defaultFields, data) === false) {
      setFormData('addMemberStepB', prepareData(data));
      prevStep();
    } else {
      prevStep();
    }
  };

  const checkSubmission = (backFlag) => {
    if (!isYarn && !isFabric && !isTextile) {
      setIsYarn(true);
    }
    setTimeout(() => {
      backFlag ? handleSubmit(onBackwardSubmit)() : handleSubmit(onForwardSubmit)();
    }, 500);
  };

  return (
    <Box>
      <MembershipFormWrapper formName="Product Related Information">
        <Grid templateColumns="repeat(2, 1fr)" gap={3}>
          <GridItem colSpan={2}>
            <Checkbox
              name="Yarn"
              isChecked={isYarn}
              onChange={isYarn && !isFabric && !isTextile ? null : () => setIsYarn(!isYarn)}>
              Category ‘A’ Spinning (Yarn Manufacturer)
            </Checkbox>
          </GridItem>
          <GridItem
            p={2}
            bg="white"
            borderRadius="4px"
            opacity={isYarn ? '1' : '0.3'}
            pointerEvents={isYarn ? 'auto' : 'none'}>
            <YarnInsCap register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem
            p={2}
            bg="white"
            borderRadius="4px"
            opacity={isYarn ? '1' : '0.3'}
            pointerEvents={isYarn ? 'auto' : 'none'}>
            <YarnInOperation register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem
            p={2}
            bg="white"
            colSpan={2}
            borderRadius="4px"
            opacity={isYarn ? '1' : '0.3'}
            pointerEvents={isYarn ? 'auto' : 'none'}>
            <YarnCap register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem colSpan={2} mt={5}>
            <Divider />
          </GridItem>

          <GridItem colSpan={2}>
            <Checkbox
              name="Fabric"
              isChecked={isFabric}
              onChange={!isYarn && isFabric && !isTextile ? null : () => setIsFabric(!isFabric)}>
              Category ‘B’ Weaving (Fabric Manufacturer)
            </Checkbox>
          </GridItem>
          <GridItem
            p={2}
            bg="white"
            colSpan={2}
            borderRadius="4px"
            opacity={isFabric ? '1' : '0.3'}
            pointerEvents={isFabric ? 'auto' : 'none'}>
            <FabricInsCap register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem
            p={2}
            bg="white"
            borderRadius="4px"
            opacity={isFabric ? '1' : '0.3'}
            pointerEvents={isFabric ? 'auto' : 'none'}>
            <FabricProdCap register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem
            p={2}
            bg="white"
            borderRadius="4px"
            opacity={isFabric ? '1' : '0.3'}
            pointerEvents={isFabric ? 'auto' : 'none'}>
            <FabricExpoCap register={register} control={control} errors={errors} />
          </GridItem>

          <GridItem colSpan={2} mt={5}>
            <Divider />
          </GridItem>
          <GridItem colSpan={2}>
            <Checkbox
              name="Other"
              isChecked={isTextile}
              onChange={!isYarn && !isFabric && isTextile ? null : () => setIsTextile(!isTextile)}>
              Category ‘C’ Textile (Product Processor)
            </Checkbox>
          </GridItem>
          <GridItem
            p={2}
            bg="white"
            borderRadius="4px"
            opacity={isTextile ? null : '0.3'}
            pointerEvents={isTextile ? 'auto' : 'none'}>
            <TextileFabricProcCap register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem
            p={2}
            bg="white"
            borderRadius="4px"
            opacity={isTextile ? null : '0.3'}
            pointerEvents={isTextile ? 'auto' : 'none'}>
            <TextileFabricExpoCap register={register} control={control} errors={errors} />
          </GridItem>
          <GridItem
            p={2}
            bg="white"
            colSpan={2}
            borderRadius="4px"
            opacity={isTextile ? null : '0.3'}
            pointerEvents={isTextile ? 'auto' : 'none'}>
            <TextileYarnCap register={register} control={control} errors={errors} />
          </GridItem>
        </Grid>
      </MembershipFormWrapper>
      <Footer
        addNew
        currStep={currStep}
        stepList={stepList}
        back={handleBackToTable}
        buttonLoading={isSubmitting}
        prev={(event) => {
          event.preventDefault();
          checkSubmission(true);
        }}
        next={(event) => {
          event.preventDefault();
          checkSubmission(false);
        }}
      />
      <DialogueBox
        close={onClose}
        mountControl={isOpen}
        alertTitle="Unsaved Changes"
        alertMessage="You have unsaved changes! Are you sure you want to leave this page?"
        performAction={() => {
          removeFormData('addMemberStepA');
          removeFormData('addMemberStepB');
          removeFormData('addMemberStepC');
          removeFormData('addMemberStepD');
          backToTable();
        }}
      />
    </Box>
  );
};

export default ProductInfo;
