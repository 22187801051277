import React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Box, Stack, Button } from '@chakra-ui/react';
import { notify } from '../../../../store/actions/globalActions';
import { patchMembershipData } from '../../../../api/membership';
import { useYupValidationResolver } from '../../../../util/yupUtils';
import InputTextArea from '../../../common/hookFormComponents/InputTextArea';

const ReworkForm = ({ closeModal, backToTable }) => {
  const { uid } = useParams();
  const dispatch = useDispatch();

  const resolver = useYupValidationResolver(
    yup.object().shape({
      reason: yup.string().required().label('Reason for Rework'),
    }),
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
  });

  const onSubmit = async (formData) => {
    const res = await patchMembershipData({ formData, param: uid, rework: true });
    if (res.data?.status == 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      closeModal();
      backToTable();
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Box mt={3}>
        <InputTextArea
          type="text"
          name="reason"
          register={register}
          isInvalid={errors['reason']}
          errorMessage={errors['reason']?.message}
        />
      </Box>

      <Stack flexDir="column" alignItems="flex-end" mt={2}>
        <Button type="submit" variant="modalAction" isLoading={isSubmitting}>
          SUBMIT
        </Button>
      </Stack>
    </Box>
  );
};

export default ReworkForm;
