import React from 'react';
import * as yup from 'yup';
import { Box, Button, HStack } from '@chakra-ui/react';
import { useForm, useWatch } from 'react-hook-form';
import FormSection from '../../../common/hookFormComponents/FormSection';
import { useYupValidationResolver } from '../../../../util/yupUtils';

const fields = [
  {
    colSpan: 1,
    type: 'text',
    name: 'amount',
    label: 'Paid Amount',
    isRequired: true,
  },
  {
    colSpan: 1,
    type: 'text',
    name: 'narration',
    label: 'Enter Narration',
    isRequired: false,
  },
];

const EditReceipt = ({ data, onSubmitCallback }) => {
  const resolver = useYupValidationResolver(
    yup.object().shape({
      [fields[0].name]: yup
        .number()
        .typeError(`${fields[0].label} must be a number`)
        .required()
        .positive()
        .integer(`${fields[0].label} must be a full number`)
        .label(fields[0].label),
    }),
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver,
    defaultValues: { amount: data?.paidAmount, narration: data?.narration },
  });

  const amount = useWatch({ control, name: 'amount' });
  const narration = useWatch({ control, name: 'narration' });
  console.log(narration);

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmitCallback)}>
      <FormSection
        secondary
        errors={errors}
        sectionColumn={1}
        formFields={fields}
        register={register}
      />
      <HStack px={3}>
        <Button
          type="submit"
          variant="modalAction"
          isFullWidth
          isDisabled={data?.paidAmount === amount && data?.narration == narration}>
          UPDATE
        </Button>
      </HStack>
    </Box>
  );
};

export default EditReceipt;
