import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    fieldType: 'select',
    name: 'memPaymentType',
    label: 'Membership Payment Type',
    isRequired: true,
    choices: [
      { name: 'Current Year', value: 'CURRENT' },
      { name: 'Extension', value: 'EXTENSION' },
    ],
  },
];

export const memPaymentTypeFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
};

const MemPaymentTypeSection = (props) => {
  return <FormSection sectionColumn={1} formFields={fields} {...props} />;
};

export default MemPaymentTypeSection;
