import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import PrintPreview from './PrintPreview';
import { useReactToPrint } from 'react-to-print';
import DialogueBox from '../../common/DialogueBox';
import { useDispatch, useSelector } from 'react-redux';
import { renderDate } from '../../../util/formatUtils';
import DataTable from '../../common/dataTable/DataTable';
import { Box, useDisclosure } from '@chakra-ui/react';
import { notify } from '../../../store/actions/globalActions';
import { useYupValidationResolver } from '../../../util/yupUtils';
import { assignDutyCertNumber, getAllCertForGen } from '../../../api/certificates';
import CertNumberSection, { certNumberFieldsValidation } from './CertNumberSection';
import { FETCH_DUTY_APPLICATIONS_FOR_GEN } from '../../../store/actions/actionTypes';
import { LOGS_TABLE_DISPLAY_SIZE, BADGE_STATE_COLORS } from '../../../constants/dataTableConstants';

const DutyGeneration = () => {
  const dispatch = useDispatch();
  const dutyCertPrintRef = useRef();
  const [rowData, setRowData] = useState();
  const [printData, setPrintData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const data = useSelector((state) => state.certificate.dutyApplicationsGen);

  useEffect(() => {
    document.title = 'BTMA - Certificate Generation';
  }, []);

  const printDutyCertificate = useReactToPrint({
    documentTitle: 'BTMA-Certificate-Duty-Exemption',
    content: () => dutyCertPrintRef.current,
  });

  const columns = [
    { header: 'Certificate No', accessor: 'certificateNum' },
    { header: 'Date', accessor: 'createdAt', isDate: true },
    { header: 'Mill Name', accessor: 'millName' },
    {
      header: 'Payment',
      accessor: 'paymentStatus',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
      hoverInfo: ['remarks'],
    },
    {
      header: 'Status',
      accessor: 'status',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
      hoverInfo: ['remarks'],
    },
    {
      header: 'Action',
      accessor: 'status',
      isButton: true,
      actionScheme: [{ group: 'APPROVED', variant: 'print', action: 'PRINT' }],
    },
  ];

  const resolver = useYupValidationResolver(
    yup.object().shape({
      ...certNumberFieldsValidation,
    }),
  );

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver });

  const handlePagination = async ({ page, pageSize, searchKey, startDate, endDate }) => {
    dispatch({ type: FETCH_DUTY_APPLICATIONS_FOR_GEN, payload: null });
    const params = {
      page,
      endDate,
      pageSize,
      searchKey,
      startDate,
    };
    const res = await getAllCertForGen({ type: 'duty', params });
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_DUTY_APPLICATIONS_FOR_GEN, payload: res.data });
    } else {
      dispatch({ type: FETCH_DUTY_APPLICATIONS_FOR_GEN, payload: { certificates: [] } });
    }
  };

  const handleSearch = async ({ searchKey, startDate, endDate }) => {
    dispatch({ type: FETCH_DUTY_APPLICATIONS_FOR_GEN, payload: null });
    const params = {
      endDate,
      searchKey,
      startDate,
    };
    const res = await getAllCertForGen({ type: 'duty', params });
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_DUTY_APPLICATIONS_FOR_GEN, payload: res.data });
    } else {
      dispatch({ type: FETCH_DUTY_APPLICATIONS_FOR_GEN, payload: { certificates: [] } });
    }
  };

  const handlePrint = ({ rowObject }) => {
    if (parseInt(rowObject?.certificateNum) === 0) {
      setRowData(rowObject);
      onOpen();
    } else {
      const {
        blNo,
        blDate,
        hoName,
        invoice,
        millName,
        masterLc,
        dateOfLc,
        msAddress,
        invoiceDate,
      } = rowObject;
      setPrintData({
        blNo,
        invoice,
        millName,
        masterLc,
        millAddress: hoName,
        factoryAddress: msAddress,
        blDate: renderDate(blDate, 'numeric'),
        dateOfLc: renderDate(dateOfLc, 'numeric'),
        invoiceDate: renderDate(invoiceDate, 'numeric'),
      });
      setTimeout(() => {
        printDutyCertificate();
      }, 500);
    }
  };

  const onSubmit = async (formdata) => {
    const res = await assignDutyCertNumber({
      certId: rowData.id,
      millId: rowData.millId,
      formdata,
    });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      handleSearch({ page: 1, pageSize: 10 });
      onClose();
      reset({ certificateNum: '' });
      handlePrint({ rowObject: { ...rowData, certificateNum: formdata?.certificateNum } });
    }
  };

  const handleNumberAssignment = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <Box p={4}>
      <DataTable
        actions={{
          PRINT: handlePrint,
        }}
        topPanel
        keySearch
        dateRange
        isPaginated
        columns={columns}
        search={handleSearch}
        currentPage={data?.page}
        data={data?.certificates}
        paginate={handlePagination}
        entries={data?.totalEntries}
        currentPageSize={data?.pageSize}
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
        caption="Duty Exemption Certificates"
      />
      <Box display="none">
        <PrintPreview ref={dutyCertPrintRef} data={printData} />
      </Box>
      <DialogueBox
        close={() => {
          onClose();
          reset({ certificateNum: '' });
        }}
        onActionKeepOpen
        mountControl={isOpen}
        cancelButtonText="CANCEL"
        actionButtonText="SUBMIT"
        actionButtonLoading={isSubmitting}
        alertTitle="Assign Certificate Number"
        performAction={handleNumberAssignment}>
        <Box as="form" py={2}>
          <CertNumberSection register={register} control={control} errors={errors} />
        </Box>
      </DialogueBox>
    </Box>
  );
};

export default DutyGeneration;
