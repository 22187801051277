import React from 'react';
import * as yup from 'yup';
import { dateValidation } from '../../../../util/yupUtils';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'btmaCashAssistanceCertForYarn',
    type: 'text',
    label: 'BTMA Cash Assistance Certificate No. for Yarn',
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'btmaCashAssistanceCertForYarnDate',
    label: 'Date of Certification',
  },
];

export const cashAssistanceFieldsValidation = {
  [fields[0].name]: yup
    .string()
    .transform((cv, ov) => {
      return ov === '' ? null : cv;
    })
    .nullable()
    .label(fields[0].label),
  [fields[1].name]: dateValidation.label(fields[1].label),
};

const CashAssistanceSection = (props) => {
  return (
    <FormSection
      sectionName="Cash Assistance Information"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default CashAssistanceSection;
