import React, { useEffect, useState } from 'react';
import Record from './Record';
import { useHistory } from 'react-router';
import Loader from '../../../snippets/Loader';
import { renderDate } from '../../../../util/formatUtils';
import { getApplicationInfo } from '../../../../api/certificates';
import { defaultYarnArrayFieldValues } from '../Verification/YarnCountSection';
import { defaultProductArrayFieldValues } from '../../commonSections/ProductInformation';
import { defaultAdditionalInfoArrayFieldValues } from '../Verification/AdditionalInfoSection';

const GspApplication = () => {
  const history = useHistory();
  const [millInfo, setMillInfo] = useState();
  const [defaultDocs, setDefaultDocs] = useState();
  const [defaultFiles, setDefaultFiles] = useState();
  const [defaultFields, setDefaultFields] = useState();
  const { applicationId } = history.location.state;

  const handleQuery = async () => {
    setDefaultFields();
    setDefaultFiles();
    const res = await getApplicationInfo({ type: 'production', id: applicationId });
    if (res.data?.status === 200) {
      const data = res.data.certificate;
      const gsp = data?.gspYarnExport || data?.gspFabricExport;
      setMillInfo({
        ...data?.millInfo,
        issueDate: renderDate(gsp?.issueDate),
        certId: data?.id,
        certificateNum: data?.certificateNum,
        product: data?.gspYarnExport ? 'Yarn' : 'Fabric',
      });
      let fields = {
        masterLc: gsp?.masterLc,
        expiryDateOfLc: gsp?.expiryDateOfLc,
        totalQuantity: gsp?.totalQuantity,
        dateOfLc: gsp?.dateOfLc,
        exciseGatePassNum: gsp?.exciseGatePassNum,
        exciseGatePassDate: gsp?.exciseGatePassDate,
        btbLc: gsp?.btbLc,
        valueInUsd: gsp?.valueInUsd,
        bank: gsp?.bank?.split(',')?.[0],
        bankBranch: gsp?.bank?.split(',')?.[1],
        btbLcDate: gsp?.btbLcDate,
        polyester: gsp?.polyester,
        synthetic: gsp?.synthetic,
        productDetails: gsp?.productDetails?.length
          ? [...gsp.productDetails]
          : [...defaultProductArrayFieldValues],
        additionalInfo: gsp?.additionalInfo?.length
          ? [...gsp.additionalInfo]
          : [...defaultAdditionalInfoArrayFieldValues],
        annualProdCapacity: gsp?.annualProdCapacity,
        suppliedTo: gsp?.suppliedTo,
      };
      data?.gspYarnExport
        ? setDefaultFields({
            ...fields,
            autoconed: gsp?.autoconed,
            nonAutoconed: gsp?.nonAutoconed,
            towelsOthers: gsp?.towelsOthers,
            openEnd: gsp?.openEnd,
            fabric: gsp?.fabric,
            yarnCount: gsp?.yarnCount?.length
              ? [...gsp.yarnCount]
              : [...defaultYarnArrayFieldValues],
          })
        : setDefaultFields({
            ...fields,
            apparel: gsp?.apparel,
            homeFurnishing: gsp?.homeFurnishing,
            towelsOthers: gsp?.towelsOthers,
          });
      setDefaultFiles([
        {
          header: 'Vat Challan',
          link: gsp?.vatChallan,
        },
        {
          header: 'L/C Certificate',
          link: gsp?.lcCertificate,
        },
        {
          header: 'Purchase Order',
          link: gsp?.purchaseOrder,
        },
        {
          header: 'Delivery Challan',
          link: gsp?.deliveryChallan,
        },
        {
          header: 'Proforma Invoice',
          link: gsp?.proformaInvoice,
        },
        {
          header: 'Commercial Invoice',
          link: gsp?.commercialInvoice,
        },
        {
          header: 'L/C Bank Documents',
          link: gsp?.lcBankDocuments,
        },
      ]);
      setDefaultDocs({
        vatChallan: gsp?.vatChallan,
        lcCertificate: gsp?.lcCertificate,
        purchaseOrder: gsp?.purchaseOrder,
        deliveryChallan: gsp?.deliveryChallan,
        proformaInvoice: gsp?.proformaInvoice,
        lcBankDocuments: gsp?.lcBankDocuments,
        commercialInvoice: gsp?.commercialInvoice,
      });
    }
  };

  useEffect(() => {
    handleQuery();
  }, []);

  return defaultFields && defaultFiles && defaultDocs ? (
    <Record
      millData={millInfo}
      refresh={handleQuery}
      defaultDocs={defaultDocs}
      defaultFiles={defaultFiles}
      defaultFields={defaultFields}
    />
  ) : (
    <Loader />
  );
};

export default GspApplication;
