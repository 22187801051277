import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/membershipFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'texFabricWovenExpoCap',
    type: 'text',
    label: 'Woven (mtr)',
  },
  {
    colSpan: 1,
    name: 'texFabricKnitExpoCap',
    type: 'text',
    label: 'Knit (kg)',
  },
];

export const fabExpoTexCapFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label).nullable(),
  [fields[1].name]: yup.string().label(fields[1].label).nullable(),
};

const FabricExpoTexCap = (props) => {
  return (
    <FormSection
      sectionName="Fabric Exportable Capacity"
      sectionColumn={1}
      formFields={fields}
      {...props}
    />
  );
};

export default FabricExpoTexCap;
