//modules
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import { Grid, GridItem, Box, useDisclosure } from '@chakra-ui/react';

//common
import Loader from '../../../../snippets/Loader';
import DialogueBox from '../../../../common/DialogueBox';
import Footer from '../../../../common/membershipFormComponents/Footer';
import MembershipFormWrapper from '../../../../common/membershipFormComponents/MembershipFormWrapper';

//form sections
import BasicInfo from './sections/BasicInfo';
import OthersInfo, { otherInfoFieldsValidation } from './sections/OthersInfo';
import HeadOfficeInfo, { headInfoFieldsValidation } from './sections/HeadOfficeInfo';
import MillSiteInfo, { millSiteInfoFieldsValidation } from './sections/MillSiteInfo';
import ContPersonInfo, { conPersonInfoFieldsValidation } from './sections/ContPersonInfo';

//api & actions
import { getMillCategories } from '../../../../../api/membership';

//utils
import _ from 'lodash';
import { formatDate, renderDate } from '../../../../../util/formatUtils';
import { useYupValidationResolver } from '../../../../../util/yupUtils';
import { getFormData, removeFormData, setFormData } from '../../../../../util/localStorageUtils';
import ProposerInfo from './sections/ProposerInfo';
import SeconderInfo from './sections/SeconderInfo';

//validation schema
const GeneralInfoValidatonSchema = yup.object().shape({
  millName: yup.string().required().label('Mill Name'),
  categoryId: yup.string().required().label('Mill Type'),
  membershipDate: yup
    .date()
    .typeError('Please enter a valid date')
    .required()
    .label('Membership Date'),
  membershipCategory: yup.string().required().label('Membership Category'),
  boardApprovalDate: yup
    .date()
    .typeError('Please enter a valid date')
    .required()
    .label('Board Approval Date'),
  ...headInfoFieldsValidation,
  ...otherInfoFieldsValidation,
  ...millSiteInfoFieldsValidation,
  ...conPersonInfoFieldsValidation,
});

const GeneralInfo = ({ currStep, nextStep, stepList, backToTable, isDataStored }) => {
  const [isMsSame, setIsMsSame] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [categoryOptions, setCategoryOptions] = useState();
  const formData = getFormData('addMemberStepA');

  useEffect(() => {
    (async () => {
      const { categories } = await getMillCategories();
      if (categories) {
        const options = categories.map(({ id, category }) => ({ name: category, value: id }));
        setCategoryOptions(options);
      }
    })();
  }, []);

  const defaultFields = {
    website: formData?.website,
    millName: formData?.millName,
    categoryId: formData?.categoryId,
    yearOfOperation: formData?.yearOfOperation,
    dateOfEstablishment: formData?.dateOfEstablishment,
    membershipDate: formData?.membershipDate
      ? renderDate(formData?.membershipDate)
      : renderDate(new Date()),
    membershipCategory: formData?.membershipCategory,
    boardApprovalDate: formData?.boardApprovalDate
      ? renderDate(formData?.boardApprovalDate)
      : renderDate(new Date()),
    proposerName: formData?.proposerName,
    proposerDesignation: formData?.proposerDesignation,
    proposerMillName: formData?.proposerMillName,
    seconderName: formData?.seconderName,
    seconderDesignation: formData?.seconderDesignation,
    seconderMillName: formData?.seconderMillName,
    ...formData?.hoInfo,
    ...formData?.msInfo,
    ...formData?.mdInfo,
    ...formData?.otherInfo,
    ...formData?.chairmanInfo,
    ...formData?.sourceOfCapital,
    ...formData?.capitalStructure,
    ...formData?.millPresentatorInfo,
    ...formData?.bondedWarehouseLicenseNo,
  };

  const resolver = useYupValidationResolver(GeneralInfoValidatonSchema);
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: { ...defaultFields },
  });

  const watchFields = useWatch({
    control,
    name: ['hoName', 'millEmail', 'millPhoneNo'],
  });

  const hoName = watchFields[0];
  const millEmail = watchFields[1];
  const millPhoneNo = watchFields[2];

  const { msEmail, msAddress, msPhoneNo } = defaultFields;

  useEffect(() => {
    if (msAddress === hoName && msEmail === millEmail && msPhoneNo === millPhoneNo) {
      setIsMsSame(true);
    }
  }, []);

  useEffect(() => {
    if (isMsSame) {
      setValue('msAddress', hoName);
      setValue('msEmail', millEmail);
      setValue('msPhoneNo', millPhoneNo);
    } else {
      setValue('msEmail', msEmail);
      setValue('msAddress', msAddress);
      setValue('msPhoneNo', msPhoneNo);
    }
  }, [isMsSame, watchFields]);

  const handleBackToTable = () => {
    if (isDataStored()) {
      onOpen();
    } else {
      backToTable();
    }
  };

  const prepareData = (data) => {
    const {
      website,
      millName,
      categoryId,
      yearOfOperation,
      dateOfEstablishment,
      membershipDate,
      membershipCategory,
      boardApprovalDate,

      hoName,
      millEmail,
      millPhoneNo,

      msEmail,
      msPhoneNo,
      msAddress,
      msTotalArea,
      msTotalBuilding,

      mpName,
      mpMobileNo,

      mdName,
      mdOfficeTelephone,

      chairmanName,
      chairmanOfficeTelephone,

      etp,
      sponsors,
      publicIssue,
      totalCostOfProject,

      natureOfCompany,
      mainManufactureActivity,

      paidupCapitalInTK,

      licenseNo,

      proposerName,
      proposerDesignation,
      proposerMillName,

      seconderName,
      seconderDesignation,
      seconderMillName,
    } = data;

    const saveData = {
      website,
      millName,
      categoryId,
      dateOfEstablishment: formatDate(dateOfEstablishment),
      yearOfOperation,
      membershipDate: formatDate(membershipDate),
      membershipCategory,
      boardApprovalDate: formatDate(boardApprovalDate),

      proposerName,
      proposerDesignation,
      proposerMillName,

      seconderName,
      seconderDesignation,
      seconderMillName,

      hoInfo: {
        hoName,
        millEmail,
        millPhoneNo,
      },

      msInfo: { msEmail, msPhoneNo, msAddress, msTotalArea, msTotalBuilding },

      mdInfo: { mdName, mdOfficeTelephone },

      millPresentatorInfo: { mpName, mpMobileNo },

      chairmanInfo: { chairmanName, chairmanOfficeTelephone },

      otherInfo: {
        natureOfCompany,
        mainManufactureActivity,
      },

      sourceOfCapital: {
        etp,
        sponsors,
        publicIssue,
        totalCostOfProject,
      },
      capitalStructure: { paidupCapitalInTK },

      bondedWarehouseLicenseNo: { licenseNo },
    };

    return saveData;
  };

  const onSubmit = (data) => {
    if (_.isEqual(defaultFields, data) === false) {
      setFormData('addMemberStepA', prepareData(data));
      nextStep();
    } else {
      nextStep();
    }
  };

  return categoryOptions ? (
    <Box>
      <MembershipFormWrapper formName="General Information">
        <Grid templateColumns="repeat(2, 1fr)" gap={3}>
          {/* leftCol */}
          <GridItem colSpan={1}>
            <Box p={2} bg="white" borderRadius="4px" mb={3}>
              <BasicInfo
                register={register}
                errors={errors}
                control={control}
                categoryOptions={categoryOptions}
              />
            </Box>
            <Box p={2} bg="white" borderRadius="4px" mb={3}>
              <MillSiteInfo
                errors={errors}
                control={control}
                register={register}
                autoFillValue={isMsSame}
                setAutoFillValue={() => setIsMsSame(!isMsSame)}
              />
            </Box>
            <Box p={2} bg="white" borderRadius="4px">
              <ContPersonInfo register={register} errors={errors} control={control} />
            </Box>
          </GridItem>

          {/* rightCol */}
          <GridItem colSpan={1}>
            <Box p={2} bg="white" borderRadius="4px" mb={3}>
              <HeadOfficeInfo register={register} errors={errors} control={control} />
            </Box>
            <Box p={2} bg="white" borderRadius="4px">
              <OthersInfo register={register} errors={errors} control={control} />
            </Box>
            <Box p={2} bg="white" borderRadius="4px">
              <ProposerInfo register={register} errors={errors} control={control} />
            </Box>

            <Box p={2} bg="white" borderRadius="4px">
              <SeconderInfo register={register} errors={errors} control={control} />
            </Box>
          </GridItem>
        </Grid>
      </MembershipFormWrapper>
      <Footer
        addNew
        currStep={currStep}
        stepList={stepList}
        back={handleBackToTable}
        buttonLoading={isSubmitting}
        next={(event) => {
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      />
      <DialogueBox
        close={onClose}
        mountControl={isOpen}
        alertTitle="Unsaved Changes"
        alertMessage="You have unsaved changes! Are you sure you want to leave this page?"
        performAction={() => {
          removeFormData('addMemberStepA');
          removeFormData('addMemberStepB');
          removeFormData('addMemberStepC');
          removeFormData('addMemberStepD');
          backToTable();
        }}
      />
    </Box>
  ) : (
    <Loader />
  );
};

export default GeneralInfo;
