import {
  FETCH_ADMIN_USERS,
  FETCH_EXTERNAL_AGENTS,
  FETCH_VERIFICATION_REQUESTS,
  FETCH_ACTION_LOGS,
  FETCH_ACTION_LOGS_DETAILS,
} from '../actions/actionTypes';

const initialState = {
  users: null,
  agents: null,
  verificationRequests: null,
  actionLogs: null,
  actionLogDetails: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ADMIN_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case FETCH_EXTERNAL_AGENTS:
      return {
        ...state,
        agents: action.payload,
      };
    case FETCH_VERIFICATION_REQUESTS:
      return {
        ...state,
        verificationRequests: action.payload,
      };
    case FETCH_ACTION_LOGS:
      return {
        ...state,
        actionLogs: action.payload,
      };
    case FETCH_ACTION_LOGS_DETAILS:
      return {
        ...state,
        actionLogDetails: action.payload,
      };
    default:
      return state;
  }
}
