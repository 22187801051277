import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../common/hookFormComponents/FormSection';

const allFields = [
  {
    fieldType: 'select',
    colSpan: 1,
    label: 'Certificate For',
    name: 'certFor',
    isRequired: true,
    choices: [
      { name: 'Production', value: 'PRODUCTION' },
      { name: 'Processing', value: 'PROCESSING' },
      { name: 'Duty Exemption', value: 'DUTY' },
      { name: 'New Market Expansion', value: 'NEW_MARKET_EXPANSION' },
    ],
  },
];

const customFields = [
  {
    fieldType: 'select',
    colSpan: 1,
    label: 'Certificate For',
    name: 'certFor',
    isRequired: true,
    choices: [
      { name: 'Duty Exemption', value: 'DUTY' },
      { name: 'New Market Expansion', value: 'NEW_MARKET_EXPANSION' },
    ],
  },
];

export const certificateForFieldsValidation = {
  [allFields[0].name]: yup.string().required().label(allFields[0].label),
};

const CertificateForSection = (props) => {
  const { isFeeCollectionView } = props;
  return (
    <FormSection
      secondary
      sectionColumn={1}
      formFields={isFeeCollectionView ? customFields : allFields}
      {...props}
    />
  );
};

export default CertificateForSection;
