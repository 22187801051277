import React, { useState } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Box, HStack, useDisclosure, Button } from '@chakra-ui/react';

import FormButton from '../../../common/FormButton';
import DialogueBox from '../../../common/DialogueBox';
import UserInfoSection, { fieldsValidation } from './UserInfoSection';

import _ from 'lodash';
import { notify } from '../../../../store/actions/globalActions';
import { useYupValidationResolver } from '../../../../util/yupUtils';
import { patchMillUser, triggerPasswordReset } from '../../../../api/millUsers';

const UserInfoForm = ({ data, closeModal, refresh }) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { username, phoneNo, email, fullName, userType, userStatus } = data;
  const defaultFields = { username, phoneNo, email, fullName, userStatus };

  const resolver = useYupValidationResolver(
    yup.object().shape({
      ...fieldsValidation,
    }),
  );

  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: defaultFields,
  });

  const watchAllFields = watch();

  const onSubmit = (data) => {
    setUserData({ ...data, userType });
    onOpen();
  };

  const confirmUpdate = async () => {
    const res = await patchMillUser({ uid: data?.uid, userData });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 3000,
        }),
      );
      refresh();
      closeModal();
    }
  };

  const handleTrigger = async () => {
    const res = await triggerPasswordReset({ uid: data?.uid, userType });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 3000,
        }),
      );
      refresh();
      closeModal();
    }
  };

  return (
    <Box as="form" w="600px" onSubmit={handleSubmit(onSubmit)}>
      <UserInfoSection register={register} control={control} errors={errors} />
      <HStack w="100%" justifyContent="space-between" mt={6}>
        <Button
          minW="120px"
          bg="#ECDBD9"
          height="40px"
          color="#9B4236"
          borderRadius="0px"
          onClick={handleTrigger}
          _focus={{ boxShadow: 'none' }}
          _hover={{ borderBottom: '3px solid #9B4236' }}>
          Trigger Password Reset
        </Button>
        <FormButton
          mt={0}
          type="submit"
          isLoading={isSubmitting}
          isDisabled={_.isEqual(defaultFields, watchAllFields)}>
          UPDATE
        </FormButton>
      </HStack>
      <DialogueBox
        close={onClose}
        alertTitle="Confirm"
        mountControl={isOpen}
        performAction={confirmUpdate}
        alertMessage="Are you sure you want to update this user's information?"
      />
    </Box>
  );
};

export default UserInfoForm;
