import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import AppRouter from './router/AppRouter';
import theme from './theme';
import '@fontsource/inter/400.css';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AppRouter />
    </ChakraProvider>
  );
}

export default App;
