import React from 'react';
import DialogueBox from '../common/DialogueBox';
import PasswordForm from './commonSections/PasswordForm';
import ReworkMessageModal from '../common/ReworkMessageModal';

const RenderModal = ({
  modal,
  isOpen,
  onClose,
  isSubmitting,
  handleRework,
  confirmUpdate,
  confirmSubmit,
  confirmReject,
  confirmDelete,
  confirmPassword,
}) => {
  switch (modal) {
    case 'UpdateAlert':
      return (
        <DialogueBox
          close={onClose}
          mountControl={isOpen}
          alertTitle="Confirm Update"
          performAction={confirmUpdate}
          alertMessage="Are you sure you want to update information for this certificate application?"
        />
      );
    case 'NoUpdate':
      return (
        <DialogueBox
          noAction
          close={onClose}
          mountControl={isOpen}
          alertTitle="No Update"
          cancelButtonText="Close"
          alertMessage="There is nothing to update, you did not change any information!"
        />
      );
    case 'REWORK':
      return (
        <ReworkMessageModal mountControl={isOpen} close={onClose} performAction={handleRework} />
      );
    case 'REJECT':
      return (
        <DialogueBox
          close={onClose}
          mountControl={isOpen}
          performAction={confirmReject}
          alertTitle="REJECT Application"
          alertMessage="Are you sure you want to reject  this application?"
        />
      );
    case 'SUBMIT':
      return (
        <DialogueBox
          close={onClose}
          mountControl={isOpen}
          performAction={confirmSubmit}
          alertTitle="Verify Application"
          alertMessage="Are you sure you want to submit these information and verify this application?"
        />
      );
    case 'DELETE':
      return (
        <DialogueBox
          close={onClose}
          mountControl={isOpen}
          performAction={confirmDelete}
          alertTitle="Delete Certificate"
          alertMessage="Are you sure you want to delete this certificate? Once deleted, this certificate will no longer be associated with a Member Mill and all the data will be erased! However, it will remain as a sold certificate in the system records and can be re-assigned to any Member Mill."
        />
      );
    case 'PASSWORD':
      return (
        <DialogueBox
          noCancel
          noAction
          close={onClose}
          mountControl={isOpen}
          alertTitle="Delete Certificate">
          <PasswordForm executeAction={confirmPassword} passBtnLoading={isSubmitting} />
        </DialogueBox>
      );
    default:
      return null;
  }
};

export default RenderModal;
