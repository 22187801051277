import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, HStack, Icon, IconButton, Input, Text, chakra } from '@chakra-ui/react';
import React, { useState } from 'react';

const CustomePagination = ({
  page = 1,
  setPage,
  entries,
  pageSize = 10,
  setPageSize,
  pageSizes = [10, 20, 30],
}) => {
  const pageCount = entries ? Math.ceil(entries / pageSize) : 1;
  const [pageErrorMsg, setPageErrorMsg] = useState('');

  // pagination actions
  const incrementPage = () => {
    if (page < pageCount) {
      setPage(page + 1);
    }
  };
  const decrementPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const validatePageJump = (value) => {
    if (value) {
      if (1 <= value && value <= pageCount) {
        setPageErrorMsg('');
        setPage(Number(value));
      } else {
        setPageErrorMsg(`Please enter a value between 1 - ${pageCount}`);
      }
    } else {
      setPageErrorMsg('');
    }
  };

  return (
    <div>
      <HStack bg="white" p={4} borderRadius="0 0 4px 4px" justifyContent="space-between">
        <Text>
          Page{' '}
          <chakra.strong>
            {page} of {pageCount}
          </chakra.strong>
        </Text>
        <Text color="invalid" mt={2}>
          {pageErrorMsg}
        </Text>
        <Box>
          <IconButton
            mr={2}
            variant="tableAction"
            icon={<Icon as={ChevronLeftIcon} />}
            onClick={decrementPage}
            disabled={page === 1}
          />
          <IconButton
            mr={2}
            variant="tableAction"
            onClick={incrementPage}
            icon={<Icon as={ChevronRightIcon} />}
            disabled={page === pageCount}
          />
          <chakra.span>
            Go to page:{' '}
            <Input
              w="60px"
              bg="formInputBG"
              variant="outline"
              borderRadius="2px"
              focusBorderColor="primary.300"
              onChange={(e) => validatePageJump(e.target.value)}
            />
          </chakra.span>{' '}
          <chakra.select
            px={1}
            borderRadius="2px"
            h="40px"
            value={pageSize}
            onChange={(e) => {
              setPage(1);
              setPageSize(Number(e.target.value));
            }}>
            {pageSizes ? (
              pageSizes.map((size) => (
                <option key={size} value={size}>
                  Show {size}
                </option>
              ))
            ) : (
              <option value={10}>Show 10</option>
            )}
          </chakra.select>
        </Box>
      </HStack>
    </div>
  );
};

export default CustomePagination;
