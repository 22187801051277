import { Box } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getProvisionalMembers } from '../../../../api/membership';
import {
  LOGS_TABLE_DISPLAY_SIZE,
  MEMBERSHIP_CATEGORY_SELECTION,
  MEMBERSHIP_TYPE_SELECTION,
} from '../../../../constants/dataTableConstants';
import { MEMBER_INFO_URL_FOR_PROVISIONAL_MEMBERS } from '../../../../constants/routerUrl';
import { FETCH_MEMBERS, FETCH_MEMBERSHIP_REQUEST } from '../../../../store/actions/actionTypes';
import DataTable from '../../../common/dataTable/DataTable';

const ProvisionalMembers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  //   const memberListRef = useRef();
  //   const [printData, setPrintData] = useState();
  //const [loading, setLoading] = useState(false);
  const data = useSelector((state) => state.membership.members);

  useEffect(() => {
    document.title = 'BTMA - Membership';
  }, []);

  const columns = [
    {
      header: 'ID',
      accessor: 'membershipNo',
    },
    {
      header: 'Mill Name',
      accessor: 'millName',
    },
    {
      header: 'Type',
      accessor: 'membershipType',
    },

    {
      header: 'Category',
      accessor: 'category',
    },
    {
      header: 'Approve Date',
      accessor: 'boardApprovalDate',
      isDate: true,
    },
    {
      header: 'Expiry Date',
      accessor: 'provisionalExpiryDate',
      isDate: true,
    },

    {
      header: 'Action',
      isButton: true,
      actionScheme: [{ variant: 'read', action: 'VIEW' }],
    },
  ];

  const handleView = ({ rowObject }) => {
    dispatch({ type: FETCH_MEMBERSHIP_REQUEST, payload: null });
    history.push(`${MEMBER_INFO_URL_FOR_PROVISIONAL_MEMBERS}/${rowObject.uid}`);
  };

  const actionObject = {
    VIEW: handleView,
  };

  const handlePagination = async ({ page, pageSize, searchKey, selected }) => {
    dispatch({ type: FETCH_MEMBERS, payload: null });
    const params = {
      page,
      pageSize,
      searchKey,
      type: selected?.['Membership Type'],
      category: selected?.['Mill Type'],
      membershipCategory: 'PROVISIONAL_MEMBERSHIP',
    };
    const res = await getProvisionalMembers(params);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_MEMBERS, payload: res.data });
    } else {
      dispatch({ type: FETCH_MEMBERS, payload: { members: [] } });
    }
  };

  const handleSearch = async ({ searchKey, selected }) => {
    dispatch({ type: FETCH_MEMBERS, payload: null });
    const params = {
      searchKey,
      type: selected?.['Membership Type'],
      category: selected?.['Mill Type'],
      membershipCategory: 'PROVISIONAL_MEMBERSHIP',
    };
    const res = await getProvisionalMembers(params);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_MEMBERS, payload: res.data });
    } else {
      dispatch({ type: FETCH_MEMBERS, payload: { members: [] } });
    }
  };

  return (
    <Box p={4}>
      <DataTable
        topPanel
        keySearch
        isPaginated
        columns={columns}
        data={data?.members}
        search={handleSearch}
        actions={actionObject}
        currentPage={data?.page}
        caption="Provisional Members"
        paginate={handlePagination}
        entries={data?.totalEntries}
        currentPageSize={data?.pageSize}
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
        selectOptions={[
          //MEMBERSHIP_STATUS_SELECTION,
          MEMBERSHIP_TYPE_SELECTION,
          MEMBERSHIP_CATEGORY_SELECTION,
        ]}
      />
    </Box>
  );
};

export default ProvisionalMembers;
