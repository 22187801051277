import React from 'react';
import * as yup from 'yup';
import { dateValidation } from '../../../../../util/yupUtils';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 2,
    name: 'licenseNo',
    type: 'text',
    label: 'License No',
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'licenseDate',
    label: 'License Date',
  },
  {
    fieldType: 'date',
    colSpan: 1,
    name: 'renewDate',
    label: 'Renew Date',
  },
];

export const bondedWarehouseLicenseFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label),
  [fields[1].name]: dateValidation.label(fields[1].label),
  [fields[2].name]: dateValidation.label(fields[2].label),
};

const BondedWarehouseLicenseSection = (props) => {
  return <FormSection secondary sectionColumn={4} formFields={fields} {...props} />;
};

export default BondedWarehouseLicenseSection;
