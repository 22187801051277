import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import MillInformation from './MillInformation';
import { getMillMembershipHistory } from '../../../../../api/accounts';
import { FETCH_MEMBERSHIP_REQUEST } from '../../../../../store/actions/actionTypes';

const MillInformationAcc = () => {
  const { uid } = useParams();
  const dispatch = useDispatch();

  const handleQuery = async () => {
    dispatch({ type: FETCH_MEMBERSHIP_REQUEST, payload: null });
    const res = await getMillMembershipHistory(uid);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_MEMBERSHIP_REQUEST, payload: res.data.millInfo });
    }
  };

  useEffect(() => {
    handleQuery();
  }, []);

  return <MillInformation refresh={handleQuery} />;
};

export default MillInformationAcc;
