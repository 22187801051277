export const PHONE_NO_REGEX = /^(?:\+880|880|0)((1[3-9]\d{8})|([2-9]\d{6,8}))$/;
export const WEBSITE_REGEX =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
// export const NID_NO_REGEX = /^(\d{10}|\d{13}|\d{17})$/;
export const NID_NO_REGEX = /^\d+$/;
// export const BIRTH_CERTIFICATE_REGEX = /^(\d{15}|\d{16}|\d{17})$/;
export const BIRTH_CERTIFICATE_REGEX = /^\d+$/;
export const ZIP_CODE_REGEX = /^\d{4}$/;
export const DATE_VALIDATION_REGEX =
  /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
