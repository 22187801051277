import React from 'react';
import { Box, FormControl, FormLabel, HStack, Select } from '@chakra-ui/react';

const MembershipPeriodSelection = ({ setPrintPeriod }) => {
  const choices = [
    { name: 'January-June', value: 'January-June' },
    { name: 'July-December', value: 'July-December' },
    { name: 'January-December', value: 'January-December' },
    { name: 'July-June', value: 'July-June' },
  ];

  return (
    <Box bg="white" px={3} mt={2.5}>
      <HStack spacing={3} mb={4}>
        <FormControl>
          <FormLabel mb={1.5} fontSize="md">
            Period?
          </FormLabel>
          <Select
            h="50px"
            width="300px"
            bg="formInputBG"
            borderRadius="2px"
            colorScheme="primary"
            placeholder={'Select Period'}
            focusBorderColor="primary.300"
            onChange={(e) => setPrintPeriod(e.target.value)}>
            {choices?.map((item) => (
              <option key={item?.value} value={item?.value}>
                {item?.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </HStack>
    </Box>
  );
};

export default MembershipPeriodSelection;
