import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    fieldType: 'select',
    name: 'paymentFor',
    label: 'Payment For',
    isRequired: true,
    choices: [
      { name: 'Membership Subscription', value: 'Membership Subscription' },
      { name: 'Donation', value: 'Donation' },
      { name: 'Other', value: 'Other' },
    ],
  },
];

export const paymentForFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
};

const PaymentForSection = (props) => {
  return <FormSection sectionColumn={1} formFields={fields} {...props} />;
};

export default PaymentForSection;
