import React, { useState } from 'react';
import {
  Tr,
  Th,
  Td,
  Box,
  Text,
  Icon,
  Table,
  Thead,
  Tbody,
  HStack,
  VStack,
  Button,
  TableCaption,
  useDisclosure,
} from '@chakra-ui/react';
import { MdSave } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from '../../../snippets/Loader';
import DialogueBox from '../../../common/DialogueBox';
import {
  labourKeys,
  labourKeyList,
  labourKeyNames,
} from '../../../../constants/labourDatabaseConstant';
import { renderDate } from '../../../../util/formatUtils';
import { notify } from '../../../../store/actions/globalActions';
import { addLabourData, getLabourData } from '../../../../api/labour';
import { LABOUR_DATABASE_URL } from '../../../../constants/routerUrl';
import { FETCH_LABOUR_DATA } from '../../../../store/actions/actionTypes';

const PreviewTable = ({ data, mill }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [duplicates, setDuplicates] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSave = async () => {
    setLoading(true);
    const res = await addLabourData(mill.id, data);
    setLoading(false);
    if (res.data?.status == 200) {
      if (res.data?.hasDuplicates) {
        setDuplicates(res.data.duplicates);
        dispatch(
          notify({
            title: 'Error',
            description: res.data.msg,
            status: 'error',
            duration: 5000,
          }),
        );
      } else {
        setDuplicates();
        dispatch(
          notify({
            title: 'Success',
            description: res.data.msg,
            status: 'success',
            duration: 5000,
          }),
        );
        const response = await getLabourData({ page: 1, pageSize: 10 });
        if (response.data) {
          dispatch({ type: FETCH_LABOUR_DATA, payload: response.data });
        }
        history.push(LABOUR_DATABASE_URL);
      }
    }
  };

  return data ? (
    <Box mt={4}>
      <DialogueBox
        close={onClose}
        mountControl={isOpen}
        alertTitle="Confirm Save"
        performAction={handleSave}
        alertMessage={`Are you sure you want to save the data? You are about to upload ${data.length} entries for ${mill.millName}.`}
      />
      <Box bg="white" p={4} borderRadius="4px 4px 0 0" mt={6} overflowX="scroll">
        <Table size="sm">
          <TableCaption placement="Top" mb={4} p={0}>
            <HStack>
              <VStack alignItems="flex-start">
                <Text fontSize="2xl" fontWeight="bold" color="black">
                  Preview
                </Text>
                <Button
                  as="label"
                  onClick={onOpen}
                  borderRadius="2px"
                  isLoading={loading}
                  isDisabled={duplicates}
                  leftIcon={<Icon as={MdSave} w={7} h={7} />}>
                  Save Data
                </Button>
                {duplicates && (
                  <VStack alignItems="start" pb={4}>
                    <Text>Please remove the following duplicate entries and upload again.</Text>
                    <HStack alignItems="start" spacing={4}>
                      <VStack alignItems="start" spacing={0.5}>
                        <Text fontSize={16} mb={1}>
                          Duplicate NIDs
                        </Text>
                        {duplicates?.duplicateNids?.map((nid, index) => (
                          <Text key={index}>{nid}</Text>
                        ))}
                      </VStack>
                      <VStack alignItems="start" spacing={0.5}>
                        <Text fontSize={16} mb={1}>
                          Duplicate Birth Certificates
                        </Text>
                        {duplicates?.duplicateBirthCerts?.map((cert, index) => (
                          <Text key={index}>{cert}</Text>
                        ))}
                      </VStack>
                    </HStack>
                  </VStack>
                )}
              </VStack>
            </HStack>
          </TableCaption>

          <Thead>
            <Tr bg="secondary">
              {labourKeyNames().map((key, index) => (
                <Th key={index} fontSize="14px" color="black">
                  {key}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((tr, index) => {
              return index < 10 ? (
                <Tr key={index} opacity={(10 - index) * 0.1}>
                  {labourKeyList().map((key, idx) => (
                    <Td key={idx}>
                      {labourKeys[key].isDate
                        ? renderDate(tr?.[labourKeys[key].dbKey])
                        : tr?.[labourKeys[key].dbKey] || ''}
                    </Td>
                  ))}
                </Tr>
              ) : null;
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  ) : (
    <Loader />
  );
};

export default PreviewTable;
