import React, { useState, useEffect } from 'react';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { Box, Text, Stack, HStack, Button, PinInput, PinInputField } from '@chakra-ui/react';

import AuthWrapper from './AuthWrapper';

import { verifyPasswordReset } from '../../api/auth';
import { OTP_VALIDITY_TIME_IN_SECONDS } from '../../constants/authConstant';
import { LOG_IN_URL, PASSWORD_RESET_URL, RESET_PASSWORD_URL } from '../../constants/routerUrl';

const PasswordResetVerficationOTP = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!location.state) {
      history.replace(LOG_IN_URL);
    } else {
      const { username, phoneNo, minPassLength } = location.state;
      if (!username || !phoneNo || !minPassLength) {
        history.replace(LOG_IN_URL);
      }
    }
  }, []);

  const handleComplete = async (otp) => {
    const { username, phoneNo, minPassLength } = location.state;
    const data = { otpToken: otp, username, phoneNo };
    try {
      const res = await verifyPasswordReset(data);
      if (res.status === 200) {
        const { username, phoneNo } = res.data;
        history.replace({
          pathname: RESET_PASSWORD_URL,
          state: {
            otp,
            username,
            minPassLength,
            phoneNo: phoneNo[0] === '+' ? phoneNo.substring(3) : phoneNo,
          },
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // timer stuff start
  const [[minutes, seconds], setTime] = useState([
    parseInt(OTP_VALIDITY_TIME_IN_SECONDS / 60),
    OTP_VALIDITY_TIME_IN_SECONDS % 60,
  ]);
  const [otpValidity, setOtpValidity] = useState(true);

  const tickTimer = () => {
    if (minutes === 0 && seconds === 0) {
      setOtpValidity(false);
    } else if (seconds === 0) {
      setTime([minutes - 1, 59]);
    } else {
      setTime([minutes, seconds - 1]);
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => tickTimer(), 1000);
    return () => clearInterval(timerId);
  });
  // timer stuff end

  const handleRetry = () => {
    history.replace(PASSWORD_RESET_URL);
  };

  return (
    <AuthWrapper>
      <Stack spacing={4} p={10} backgroundColor="white" boxShadow="md" borderRadius="md">
        {otpValidity && (
          <Box>
            <Text fontSize="xl" color="textSecondary" textAlign="center">
              Enter the OTP sent to {location?.state?.phoneNo}
            </Text>
            <HStack mt={10} justify="center">
              <PinInput
                otp
                autoFocus
                onComplete={handleComplete}
                focusBorderColor="primary.300"
                type="alphanumeric"
                size="lg">
                <PinInputField bg="secondary" />
                <PinInputField bg="secondary" />
                <PinInputField bg="secondary" />
                <PinInputField bg="secondary" />
              </PinInput>
            </HStack>
          </Box>
        )}
        <HStack mt={18} mb={6} justify="center">
          {otpValidity ? (
            <Stack mt={1}>
              <Text fontSize="2xl">
                {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
              </Text>
            </Stack>
          ) : (
            <Stack spacing={4} px={16} py={6}>
              <Text fontSize="xl" color="invalid" textAlign="center">
                OTP Expired!
              </Text>
              <Button borderRadius="2px" onClick={handleRetry}>
                Try Again
              </Button>
            </Stack>
          )}
        </HStack>

        <HStack mt={18} mb={6} justify="center">
          <Link to={LOG_IN_URL} style={{ maxWidth: 'fit-content' }}>
            <Text
              fontSize="sm"
              opacity={0.45}
              transition="0.5s"
              color="primary.700"
              _hover={{ opacity: 1 }}>
              Back to Login?
            </Text>
          </Link>
        </HStack>
      </Stack>
    </AuthWrapper>
  );
};

export default PasswordResetVerficationOTP;
