import React from 'react';
import * as yup from 'yup';
import FormSection from '../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    type: 'text',
    name: 'certificateNum',
    label: 'Certificate No',
    isRequired: true,
  },
];

export const certNumberFieldsValidation = {
  [fields[0].name]: yup
    .number()
    .typeError(`${fields[0].label} must be a number`)
    .required()
    .label(fields[0].label),
};

const CertNumber = (props) => {
  return <FormSection secondary sectionColumn={1} formFields={fields} {...props} />;
};

export default CertNumber;
