import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../../common/membershipFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'fabricKnitExpoCap',
    type: 'text',
    label: 'Knit (kg)',
  },
  {
    colSpan: 1,
    name: 'fabricWovenExpoCap',
    type: 'text',
    label: 'Woven (mtr)',
  },
];

export const fabricExpoCapFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label),
  [fields[1].name]: yup.string().label(fields[1].label),
};

const FabricExpoCap = (props) => {
  return (
    <FormSection
      sectionName="Fabric Exportable Capacity"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default FabricExpoCap;
