import React, { useEffect, useState } from 'react';
import Record from './Record';
import { useHistory } from 'react-router';
import Loader from '../../../snippets/Loader';
import { renderDate } from '../../../../util/formatUtils';
import { getApplicationInfo } from '../../../../api/certificates';
import { defaultProductArrayFieldValues } from '../../commonSections/ProductInformation';

const CashIncentiveApplication = () => {
  const history = useHistory();
  const [millInfo, setMillInfo] = useState();
  const [defaultDocs, setDefaultDocs] = useState();
  const [defaultFiles, setDefaultFiles] = useState();
  const [defaultFields, setDefaultFields] = useState();
  const { applicationId } = history.location.state;

  const handleQuery = async () => {
    setDefaultFields();
    setDefaultFiles();
    const res = await getApplicationInfo({ type: 'production', id: applicationId });
    if (res.data?.status === 200) {
      const data = res.data.certificate;
      const cash = data?.cashAssitanceYarnExport || data?.cashAssitanceFabricExport;
      setMillInfo({
        certId: data?.id,
        ...data?.millInfo,
        certificateNum: data?.certificateNum,
        issueDate: renderDate(cash?.issueDate),
        product: data?.cashAssitanceYarnExport ? 'Yarn' : 'Fabric',
      });
      let fields = {
        masterLc: cash?.masterLc,
        expiryDateOfLc: cash?.expiryDateOfLc,
        totalQuantity: cash?.totalQuantity,
        dateOfLc: cash?.dateOfLc,
        mushakNum: cash?.mushakNum,
        mushakDate: cash?.mushakDate,
        totalValueInUsd: cash?.totalValueInUsd,
        btbLc: cash?.btbLc,
        valueInUsd: cash?.valueInUsd,
        bank: cash?.bank?.split(',')?.[0],
        bankBranch: cash?.bank?.split(',')?.[1],
        btbLcDate: cash?.btbLcDate,
        productDetails: cash?.productDetails?.length
          ? [...cash.productDetails]
          : [...defaultProductArrayFieldValues],
        annualProdCapacity: cash?.annualProdCapacity,
        suppliedTo: cash?.suppliedTo,
        nameAndDesignationOfMillRepresentative: cash?.nameAndDesginationOfMillRepresentative,
        yarnQty: cash?.yarnQty,
        yarnUnit: cash?.yarnUnit,
        yarnValue: cash?.yarnValue,
        yarnCurrency: cash?.yarnCurrency,
        yarnConversionRate: cash?.yarnConversionRate,
        yarnValueInTk: cash?.yarnValueInTk,
        fabricQty: cash?.fabricQty,
        fabricUnit: cash?.fabricUnit,
        fabricValue: cash?.fabricValue,
        fabricCurrency: cash?.fabricCurrency,
        fabricConversionRate: cash?.fabricConversionRate,
        fabricValueInTk: cash?.fabricValueInTk,
        towelQty: cash?.towelQty,
        towelUnit: cash?.towelUnit,
        towelValue: cash?.towelValue,
        towelCurrency: cash?.towelCurrency,
        towelConversionRate: cash?.towelConversionRate,
        towelValueInTk: cash?.towelValueInTk,
        wrmgQty: cash?.wrmgQty,
        wrmgUnit: cash?.wrmgUnit,
        wrmgValue: cash?.wrmgValue,
        wrmgCurrency: cash?.wrmgCurrency,
        wrmgConversionRate: cash?.wrmgConversionRate,
        wrmgValueInTk: cash?.wrmgValueInTk,
        krmgQty: cash?.krmgQty,
        krmgUnit: cash?.krmgUnit,
        krmgValue: cash?.krmgValue,
        krmgCurrency: cash?.krmgCurrency,
        krmgConversionRate: cash?.krmgConversionRate,
        krmgValueInTk: cash?.krmgValueInTk,
      };
      data?.cashAssitanceYarnExport
        ? setDefaultFields({
            btmaCashAssistanceCertForYarn: cash?.btmaCashAssistanceCertForYarn,
            btmaCashAssistanceCertForYarnDate: cash?.btmaCashAssistanceCertForYarnDate,
            ...fields,
          })
        : setDefaultFields(fields);

      setDefaultFiles([
        {
          header: 'Declaration -1',
          link: cash?.declarationOne,
        },
        {
          header: 'Declaration -3',
          link: cash?.declarationThree,
        },
        {
          header: 'Proforma Invoice',
          link: cash?.proformaInvoice,
        },
        {
          header: 'LC Documents from Bank',
          link: cash?.lcBankDocuments,
        },
      ]);

      setDefaultDocs({
        declarationOne: cash?.declarationOne,
        declarationThree: cash?.declarationThree,
        proformaInvoice: cash?.proformaInvoice,
        lcBankDocuments: cash?.lcBankDocuments,
      });
    }
  };

  useEffect(() => {
    handleQuery();
  }, []);

  return defaultFields && defaultFiles && defaultDocs ? (
    <Record
      millData={millInfo}
      refresh={handleQuery}
      defaultDocs={defaultDocs}
      defaultFiles={defaultFiles}
      defaultFields={defaultFields}
    />
  ) : (
    <Loader />
  );
};

export default CashIncentiveApplication;
