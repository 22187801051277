import React from 'react';
import * as yup from 'yup';
import { dateValidation } from '../../../../util/yupUtils';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 2,
    fieldType: 'date',
    name: 'issueDate',
    label: 'Issue Date',
    isRequired: true,
  },
  {
    colSpan: 2,
    fieldType: 'select',
    name: 'membershipType',
    label: 'Membership Type',
    isRequired: true,
    choices: [
      { name: 'Associate', value: 'ASSOCIATE' },
      { name: 'General', value: 'GENERAL' },
    ],
  },
  {
    colSpan: 2,
    type: 'text',
    name: 'paidAmount',
    label: 'Amount Received',
    isRequired: true,
  },
  {
    fieldType: 'select',
    colSpan: 2,
    label: 'Payment Method',
    isRequired: true,
    name: 'paymentMethod',
    choices: [
      { name: 'Cash', value: 'CASH' },
      { name: 'Cheque', value: 'CHEQUE' },
      { name: 'EFT', value: 'EFT' },
      { name: 'MFS', value: 'MFS' },
    ],
  },
];

export const paymentFieldsValidation = (maxVal) => {
  return {
    [fields[0].name]: dateValidation.required().label(fields[0].label),
    [fields[1].name]: yup.string().required().label(fields[1].label),
    [fields[2].name]: yup
      .number()
      .typeError(`${fields[2].label} must be a number`)
      .positive()
      .integer()
      .max(maxVal)
      .required()
      .label(fields[2].label),
    [fields[3].name]: yup.string().required().label(fields[3].label),
  };
};

const PaymentSection = (props) => {
  return <FormSection sectionColumn={4} formFields={fields} {...props} />;
};

export default PaymentSection;
