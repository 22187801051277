import React from 'react';
import RadioField from './RadioField';
import SelectField from './SelectField';
import InputField from '../hookFormComponents/InputField';
import InputTextArea from '../hookFormComponents/InputTextArea';
import DatePickerField from '../hookFormComponents/DatePickerField';
import { Text, Stack, Grid, GridItem } from '@chakra-ui/react';

const renderField = (index, control, register, errors, field) => {
  switch (field.fieldType) {
    case 'select':
      return (
        <SelectField
          key={index}
          control={control}
          name={field.name}
          label={field.label}
          choices={field.choices}
          isInvalid={errors[field.name]}
          errorMessage={errors[field.name]?.message}
        />
      );
    case 'radio':
      return (
        <RadioField
          key={index}
          control={control}
          name={field.name}
          label={field.label}
          choices={field.choices}
          isInvalid={errors[field.name]}
          errorMessage={errors[field.name]?.message}
        />
      );
    case 'date':
      return (
        <DatePickerField
          key={index}
          control={control}
          name={field.name}
          label={field.label}
          isInvalid={errors[field.name]}
          errorMessage={errors[field.name]?.message}
        />
      );
    case 'textArea':
      return (
        <InputTextArea
          key={index}
          name={field.name}
          label={field.label}
          type={field.type}
          isRequired={field.isRequired}
          register={register}
          isInvalid={errors[field.name]}
          errorMessage={errors[field.name]?.message}
        />
      );
    default:
      return (
        <InputField
          key={index}
          name={field.name}
          label={field.label}
          type={field.type}
          isRequired={field.isRequired}
          register={register}
          isInvalid={errors[field.name]}
          errorMessage={errors[field.name]?.message}
        />
      );
  }
};

const FormSection = ({
  secondary,
  sectionName,
  isRequired,
  sectionColumn,
  formFields,
  control,
  register,
  watch,
  errors,
}) => {
  return (
    <Stack
      spacing={2}
      p={secondary ? null : 3}
      mt={secondary ? -2 : null}
      pb={3}
      px={secondary ? 3 : null}>
      <Text fontSize="1xl" fontWeight="bold">
        {isRequired ? <b style={{ color: '#E53E3E' }}>* </b> : null} {sectionName}
      </Text>
      <Grid templateColumns={`repeat(${sectionColumn}, 1fr)`} gap={3}>
        {formFields.map((field, index) => (
          <GridItem
            key={index}
            colSpan={field.colSpan}
            p="1px"
            display="flex"
            alignItems="flex-start"
            overflow={field.fieldType === 'date' ? 'visible' : 'hidden'}>
            {renderField(index, control, register, errors, field, watch)}
          </GridItem>
        ))}
      </Grid>
    </Stack>
  );
};

export default FormSection;
