import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Box } from '@chakra-ui/react';
import logo from '../../assets/btma_logo.png';
import { ROOT_URL } from '../../constants/routerUrl';

const PublicNavbar = () => {
  return (
    <Flex
      px={2}
      h="7vh"
      as="nav"
      w="100%"
      bg="white"
      maxH="80px"
      minH="60px"
      align="center"
      justify="center">
      <Link to={ROOT_URL}>
        <Box textAlign="center" w="16vh" maxW="200px" minW="140px" color="white">
          <img src={logo} />
        </Box>
      </Link>
    </Flex>
  );
};

export default PublicNavbar;
