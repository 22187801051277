import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { MdAdd, MdCancel, MdDelete, MdSearch, MdFindReplace } from 'react-icons/md';
import {
  Box,
  Icon,
  Text,
  Alert,
  Button,
  VStack,
  HStack,
  Tooltip,
  AlertIcon,
  AlertTitle,
  useDisclosure,
} from '@chakra-ui/react';

//common components
import MillNameForm from './MillNameForm';
import PasswordForm from './PasswordForm';
import DialogueBox from '../../common/DialogueBox';
import DataTable from '../../common/dataTable/DataTable';
import RejectInfoSection, { rejectFieldsValidation } from './RejectInfoSection';
import ReceiptInfoSection, { receiptFieldsValidation } from './ReceiptInfoSection';
import ReceiptPaymentInfoSection, {
  receiptPaymentInfoFieldsValidation,
} from './ReceiptPaymentInfoSection';
import ReceiptChequeInfoSection, {
  receiptChequeInfoFieldsValidation,
} from './ReceiptChequeInfoSection';
import MinimalTable from '../../common/MinimalTable';

//actions & api
import {
  rejectCertificate,
  reassignCertificate,
  getAllRejectedCerts,
  findCertificateForRejection,
  findCertificateReceipt,
  updateCertificateReceipt,
  deleteCertificate,
  replaceCertificate,
  unrejectCertificate,
} from '../../../api/certificates';
import { notify } from '../../../store/actions/globalActions';
import { FETCH_REJECTED_CERTIFICATES } from '../../../store/actions/actionTypes';

//utils & constants
import { useYupValidationResolver } from '../../../util/yupUtils';
import { BADGE_STATE_COLORS, LOGS_TABLE_DISPLAY_SIZE } from '../../../constants/dataTableConstants';
import { formatDate } from '../../../util/formatUtils';
import ReplaceCertForm from './ReplaceCertForm';
import { deleteCertificateBatch } from '../../../api/accounts';

const RejectedCertificates = () => {
  const dispatch = useDispatch();
  const [cert, setCert] = useState();
  const [modal, setModal] = useState();
  const [action, setAction] = useState();
  const [rowData, setRowData] = useState();
  const [formData, setFormData] = useState();
  const [certData, setCertData] = useState();
  const [method, setMethod] = useState('CASH');
  const [receiptData, setReceiptData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useSelector((state) => state.auth.user);
  const [form, setForm] = useState('SEARCH_CERTIFICATE');
  const [dutyCertReceiptEditing, setDutyCertReceiptEditing] = useState(false);
  const data = useSelector((state) => state.certificate.rejectedCertificates);

  const columns = [
    { header: 'Certificate No', accessor: 'certificateNum' },
    { header: 'Certificate Type', accessor: 'type' },
    { header: 'Date', accessor: 'createdAt', isDate: true },
    { header: 'Mill Name', accessor: 'millName' },
    { header: 'Contact', accessor: 'phoneNo' },
    {
      header: 'Status',
      accessor: 'status',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
      hoverInfo: ['remarks'],
    },
    {
      header: 'Action',
      accessor: 'status',
      isButton: true,
      actionScheme: [{ group: 'REJECTED', variant: 'write', action: 'UNREJECT' }],
    },
  ];

  const certRangecolumns = [
    { header: 'Serial', accessor: 'certificateNum' },
    { header: 'Type', accessor: 'type' },
    { header: 'Status', accessor: 'status' },
    { header: 'Used On', accessor: 'usedOn', isDate: true },
    { header: 'Application Status', accessor: 'applicationStatus' },
  ];

  const getValidationSchema = () => {
    switch (form) {
      case 'SEARCH_CERTIFICATE':
        return rejectFieldsValidation;
      case 'SEARCH_RECEIPT':
        return receiptFieldsValidation;
      case 'EDIT_RECEIPT':
        return method !== 'CASH'
          ? {
              ...receiptPaymentInfoFieldsValidation,
              ...receiptChequeInfoFieldsValidation(method),
            }
          : receiptPaymentInfoFieldsValidation;
      default:
        return {};
    }
  };

  const resolver = useYupValidationResolver(yup.object().shape(getValidationSchema()));
  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver });

  const paymentMethod = useWatch({ name: 'paymentMethod', control });
  const certType = useWatch({ name: 'certType', control });

  useEffect(() => {
    setMethod(paymentMethod);
  }, [paymentMethod]);

  useEffect(() => {
    setCert(certType);
  }, [certType]);

  useEffect(() => {
    if (!isOpen) {
      setAction();
      setCertData();
      setReceiptData();
      setValue('dateSold', null);
      setValue('chequeNo', null);
      setValue('chequeDated', null);
      setValue('paymentMethod', null);
      setDutyCertReceiptEditing(false);
      setForm('SEARCH_CERTIFICATE');
      reset({ certType: '', certificateNum: '' });
    }
  }, [isOpen]);

  useEffect(() => {
    if (!dutyCertReceiptEditing) {
      setRowData();
      setValue('dateSold', null);
      setValue('chequeNo', null);
      setValue('chequeDated', null);
      setValue('paymentMethod', null);
    }
  }, [dutyCertReceiptEditing]);

  const onSubmit = async (data) => {
    if (form === 'SEARCH_CERTIFICATE') {
      const res = await findCertificateForRejection(data);
      if (res.data?.status === 200) {
        setCertData(res.data?.certInfo);
        setForm('PASSWORD');
      }
    } else if (form === 'SEARCH_RECEIPT') {
      const res = await findCertificateReceipt(data);
      if (res.data?.status === 200) {
        setReceiptData({ ...res.data, certFor: data.certType });
        if (cert !== 'DUTY') {
          setValue('dateSold', res.data.dateSold);
          setValue('chequeNo', res.data.chequeNo);
          setValue('chequeDated', res.data.chequeDated);
          setValue('paymentMethod', res.data.paymentMethod);
        }
        setForm('EDIT_RECEIPT');
      }
    } else if (form === 'EDIT_RECEIPT') {
      const res = await updateCertificateReceipt({
        ...data,
        type: cert,
        uid: receiptData.orderId,
        subUid: rowData?.uid || null,
        dateSold: formatDate(new Date(data.dateSold)),
        chequeDated: formatDate(new Date(data.chequeDated)),
      });
      if (res.data?.status === 200) {
        if (cert !== 'DUTY') {
          onClose();
        } else {
          const response = await findCertificateReceipt({
            certType: cert,
            trxId: receiptData.orderId,
          });
          if (response.data?.status === 200) {
            setReceiptData(response.data);
            setDutyCertReceiptEditing(false);
          } else {
            onClose();
          }
        }
        dispatch(
          notify({
            title: 'Success',
            description: res.data.msg,
            status: 'success',
            duration: 5000,
          }),
        );
      }
    }
  };

  const handleUnrejectButton = ({ rowObject }) => {
    setCertData(rowObject);
    setModal('UNREJECT_CERTIFICATE');
    onOpen();
  };

  const actionObject = {
    UNREJECT: handleUnrejectButton,
  };

  const handleSearchCertificateClick = () => {
    setModal('SEARCH_CERTIFICATE');
    setForm('SEARCH_CERTIFICATE');
    onOpen();
  };

  const handleSearchReceiptClick = () => {
    setModal('SEARCH_RECEIPT');
    setForm('SEARCH_RECEIPT');
    onOpen();
  };

  const handleRejection = (password) => {
    setFormData(password);
    setModal('REJECT');
    onOpen();
  };

  const handleDeletion = (password) => {
    setFormData(password);
    setModal('DELETE');
    onOpen();
  };

  const handleInvoiceDelete = () => {
    setModal('INVOICE_DELETE');
    onOpen();
  };

  const handleReplace = (replaceCertNumber, password) => {
    setFormData({
      replaceCertNumber: replaceCertNumber,
      password: password,
    });
    setModal('REPLACE');
    onOpen();
  };

  const handleUnejection = async (password) => {
    setIsLoading(true);
    const res = await unrejectCertificate({
      userId: user?.userId,
      password: password,
      certType: certData?.type,
      certId: certData?.id,
    });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      handlePagination({ page: 1, pageSize: 10 });
    }
    setIsLoading(false);
    onClose();
  };

  const confirmRejection = async () => {
    setIsLoading(true);
    const res = await rejectCertificate({
      userId: user?.userId,
      password: formData,
      certType: certData?.type,
      certNum: certData?.certificateNum,
    });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      handlePagination({ page: 1, pageSize: 10 });
    }
    setIsLoading(false);
    onClose();
  };

  const confirmDeletion = async () => {
    setIsLoading(true);
    const res = await deleteCertificate({
      userId: user?.userId,
      password: formData,
      certType: certData?.type,
      certNum: certData?.certificateNum,
    });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      handlePagination({ page: 1, pageSize: 10 });
    }
    setIsLoading(false);
    onClose();
  };

  const handleReassignment = (mill) => {
    setFormData(mill);
    setModal('REASSIGN');
    onOpen();
  };

  const confirmReassignment = async () => {
    setIsLoading(true);
    const res = await reassignCertificate({
      millId: formData?.id,
      certType: certData?.type,
      certNum: certData?.certificateNum,
    });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
    }
    setIsLoading(false);
    onClose();
  };

  const confirmReplacement = async () => {
    setIsLoading(true);
    const res = await replaceCertificate({
      userId: user?.userId,
      password: formData?.password,
      newCertNum: formData?.replaceCertNumber,
      certNum: certData?.certificateNum,
      certType: certData?.type,
    });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
    }
    setIsLoading(false);
    onClose();
  };

  const confirmInvoiceDelete = async () => {
    const batchData = {
      certType: receiptData?.certFor.toLowerCase(),
      batchId: receiptData?.orderId,
    };

    setIsLoading(true);
    const res = await deleteCertificateBatch(batchData);
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      setIsLoading(false);
      onClose();
    }
    setIsLoading(false);
    onClose();
  };

  const paymentHistoryColumns = [
    { header: 'Serial', accessor: 'uid' },
    { isDate: true, header: 'Payment Date', accessor: 'date' },
    { header: 'Amount', accessor: 'paidAmount', currency: '৳' },
    {
      header: 'Payment Method',
      accessor: 'paymentMethod',
      hoverInfo: ['chequeNo as ID', 'chequeDated as Date'],
    },
    {
      isButton: true,
      header: 'Action',
      accessor: 'id',
      actionScheme: [{ group: 'boolean', trueVariant: 'write', trueAction: 'EDIT' }],
    },
  ];

  const handleEdit = ({ rowObject }) => {
    setRowData(rowObject);
    setValue('dateSold', rowObject.date);
    setValue('chequeNo', rowObject.chequeNo);
    setValue('chequeDated', rowObject.chequeDated);
    setValue('paymentMethod', rowObject.paymentMethod);
    setDutyCertReceiptEditing(true);
  };

  const renderModal = () => {
    switch (modal) {
      case 'SEARCH_CERTIFICATE':
        return (
          <DialogueBox
            noCancel
            noAction
            close={onClose}
            mountControl={isOpen}
            alertTitle="Search Certificate">
            <Box as="form" onSubmit={handleSubmit(onSubmit)}>
              {form === 'SEARCH_CERTIFICATE' ? (
                <RejectInfoSection register={register} control={control} errors={errors} />
              ) : (
                <Box>
                  <Text fontSize="18px" fontWeight="bold">
                    Certificate Details
                  </Text>
                  <HStack>
                    <Text fontSize="sm" fontWeight="bold">
                      Certificate No:
                    </Text>
                    <Text fontSize="sm">{certData?.certificateNum}</Text>
                  </HStack>
                  <HStack>
                    <Text fontSize="sm" fontWeight="bold">
                      Batch ID:
                    </Text>
                    <Text fontSize="sm">{certData?.batchId}</Text>
                  </HStack>
                  <HStack>
                    <Text fontSize="sm" fontWeight="bold">
                      Certificate Type:
                    </Text>
                    <Text fontSize="sm">{certData?.type}</Text>
                  </HStack>
                  <HStack>
                    <Text fontSize="sm" fontWeight="bold">
                      Status:
                    </Text>
                    <Text fontSize="sm">{certData?.status}</Text>
                  </HStack>
                  <HStack>
                    <Text fontSize="sm" fontWeight="bold">
                      Application Status:
                    </Text>
                    <Text fontSize="sm">
                      {certData?.status === 'USED' ? certData?.applicationStatus : 'N/A'}
                    </Text>
                  </HStack>
                  <HStack>
                    <Text fontSize="sm" fontWeight="bold">
                      Mill Name:
                    </Text>
                    <Text fontSize="sm">{certData?.millName}</Text>
                  </HStack>
                  {!action ? (
                    <VStack mt={4} alignItems="stretch">
                      <HStack>
                        <Tooltip
                          label={
                            certData?.status === 'USED'
                              ? 'This certificate can not be re-assigned as it is already used!'
                              : ''
                          }
                          area-label="A Tooltip"
                          placement="top-start">
                          <Box>
                            <Button
                              isFullWidth
                              variant="modalAction"
                              isLoading={isSubmitting}
                              _focus={{ boxShadow: 'none' }}
                              onClick={() => setAction('REASSIGN')}
                              isDisabled={certData?.status === 'USED'}
                              rightIcon={<Icon as={MdAdd} w={5} h={5} />}>
                              Re-assign to different member
                            </Button>
                          </Box>
                        </Tooltip>
                        <Tooltip
                          label={
                            certData?.applicationStatus === 'REJECTED'
                              ? 'This certificate is already rejected!'
                              : ''
                          }
                          area-label="A Tooltip"
                          placement="top-start">
                          <Box>
                            <Button
                              bg="#fff"
                              color="red.500"
                              variant="modalAction"
                              isLoading={isSubmitting}
                              _hover={{ bg: 'red.100' }}
                              border="1px solid #F56565"
                              _focus={{ boxShadow: 'none' }}
                              onClick={() => setAction('REJECT')}
                              rightIcon={<Icon as={MdCancel} w={5} h={5} />}
                              isDisabled={certData?.applicationStatus === 'REJECTED'}>
                              Reject Certificate
                            </Button>
                          </Box>
                        </Tooltip>
                      </HStack>

                      <HStack>
                        <Tooltip
                          label={
                            certData?.applicationStatus !== 'APPROVED'
                              ? 'This certificate is not approved and cannot be replaced!'
                              : ''
                          }
                          area-label="A Tooltip"
                          placement="top-start">
                          <Box>
                            <Button
                              bg="blue.500"
                              variant="modalAction"
                              isLoading={isSubmitting}
                              _hover={{ bg: 'blue.400' }}
                              _focus={{ boxShadow: 'none' }}
                              onClick={() => setAction('REPLACE')}
                              rightIcon={<Icon as={MdFindReplace} w={5} h={5} />}
                              isDisabled={certData?.applicationStatus !== 'APPROVED'}>
                              Reject and Replace Certificate
                            </Button>
                          </Box>
                        </Tooltip>

                        <Tooltip
                          label={
                            certData?.status === 'USED'
                              ? 'This certificate is used and can not be deleted!'
                              : ''
                          }
                          area-label="A Tooltip"
                          placement="top-start">
                          <Box>
                            <Button
                              bg="red.500"
                              variant="modalAction"
                              isLoading={isSubmitting}
                              _hover={{ bg: 'red.400' }}
                              _focus={{ boxShadow: 'none' }}
                              onClick={() => setAction('DELETE')}
                              rightIcon={<Icon as={MdDelete} w={5} h={5} />}
                              isDisabled={certData?.status === 'USED'}>
                              Delete Certificate
                            </Button>
                          </Box>
                        </Tooltip>
                      </HStack>
                    </VStack>
                  ) : null}
                  {action === 'REASSIGN' ? (
                    <MillNameForm
                      current={certData?.millId}
                      cancel={() => setAction()}
                      executeAction={handleReassignment}
                    />
                  ) : action === 'REJECT' ? (
                    <PasswordForm executeAction={handleRejection} cancel={() => setAction()} />
                  ) : action === 'DELETE' ? (
                    <PasswordForm
                      action="delete"
                      executeAction={handleDeletion}
                      cancel={() => setAction()}
                    />
                  ) : action === 'REPLACE' ? (
                    <ReplaceCertForm
                      executeAction={handleReplace}
                      cancel={() => setAction()}
                      action="replace"
                    />
                  ) : null}
                </Box>
              )}
              <VStack px={3} alignItems="flex-end">
                {form === 'SEARCH_CERTIFICATE' ? (
                  <Button
                    type="submit"
                    variant="modalAction"
                    isLoading={isSubmitting}
                    rightIcon={<Icon as={MdSearch} w={5} h={5} />}>
                    Search
                  </Button>
                ) : null}
              </VStack>
            </Box>
          </DialogueBox>
        );
      case 'SEARCH_RECEIPT':
        return (
          <DialogueBox
            noCancel
            noAction
            scrollable
            close={onClose}
            mountControl={isOpen}
            variant={cert == 'DUTY' && receiptData ? 'lg' : ''}
            alertTitle="Search Receipt">
            <Box as="form" onSubmit={handleSubmit(onSubmit)}>
              {form === 'SEARCH_RECEIPT' ? (
                <ReceiptInfoSection register={register} control={control} errors={errors} />
              ) : (
                form === 'EDIT_RECEIPT' && (
                  <Box>
                    <Text fontSize="18px" fontWeight="bold">
                      Receipt Details
                    </Text>
                    <HStack>
                      <Text fontSize="sm" fontWeight="bold">
                        Transaction Id:
                      </Text>
                      <Text fontSize="sm">{receiptData?.orderId}</Text>
                    </HStack>
                    <HStack>
                      <Text fontSize="sm" fontWeight="bold">
                        Mill Name:
                      </Text>
                      <Text fontSize="sm">{receiptData?.millName}</Text>
                    </HStack>
                    <HStack>
                      <Text fontSize="sm" fontWeight="bold">
                        Type:
                      </Text>
                      <Text fontSize="sm">
                        {`${cert}${receiptData?.type ? `_${receiptData?.type}` : ''}`}
                      </Text>
                    </HStack>
                    {cert !== 'DUTY' && (
                      <HStack>
                        <Text fontSize="sm" fontWeight="bold">
                          Quantity:
                        </Text>
                        <Text fontSize="sm">{receiptData?.noOfCopies}</Text>
                      </HStack>
                    )}
                    <HStack>
                      <Text fontSize="sm" fontWeight="bold">
                        Total:
                      </Text>
                      <Text fontSize="sm">৳{receiptData?.total}</Text>
                    </HStack>
                    {cert === 'DUTY' && (
                      <HStack>
                        <Text fontSize="sm" fontWeight="bold">
                          Due:
                        </Text>
                        <Text fontSize="sm">৳{receiptData?.dues}</Text>
                      </HStack>
                    )}
                    <HStack>
                      <Text fontSize="sm" fontWeight="bold">
                        {cert !== 'DUTY' ? 'Range:' : 'Serial:'}
                      </Text>
                      <Text fontSize="sm">
                        {cert !== 'DUTY'
                          ? receiptData?.certRange
                          : receiptData?.certificateDetails?.[0]?.certificateNum}
                      </Text>
                    </HStack>
                    <HStack mb={4}>
                      <Text fontSize="sm" fontWeight="bold">
                        Payment Status:
                      </Text>
                      <Text fontSize="sm">{receiptData?.paymentStatus}</Text>
                    </HStack>
                    <Text fontSize="1xl" ml={3} mt={3} fontWeight="bold">
                      Certificates
                    </Text>
                    <Box p={3}>
                      <MinimalTable
                        scrollable
                        tableHeight="25vh"
                        columns={certRangecolumns}
                        data={receiptData?.certificateDetails}
                      />
                    </Box>
                    {cert !== 'DUTY' ? (
                      <Box>
                        {receiptData.paymentStatus === 'PAID' ? (
                          <>
                            <ReceiptPaymentInfoSection
                              register={register}
                              control={control}
                              errors={errors}
                            />
                            {paymentMethod !== 'CASH' && (
                              <ReceiptChequeInfoSection
                                method={paymentMethod}
                                register={register}
                                control={control}
                                errors={errors}
                              />
                            )}
                          </>
                        ) : (
                          <Alert status="error" mb={4}>
                            <AlertIcon />
                            <AlertTitle>
                              This certificate invoice is still <b>UNPAID</b>.
                            </AlertTitle>
                          </Alert>
                        )}
                      </Box>
                    ) : !dutyCertReceiptEditing ? (
                      <>
                        {receiptData.paymentStatus === 'PAID' ? (
                          <DataTable
                            caption="Payment History"
                            data={receiptData.paymentHistory || []}
                            columns={paymentHistoryColumns}
                            currentPageSize={data?.pageSize}
                            actions={{ EDIT: handleEdit }}
                          />
                        ) : (
                          <Alert status="error" mb={4}>
                            <AlertIcon />
                            <AlertTitle>
                              This certificate invoice is still <b>UNPAID</b>.
                            </AlertTitle>
                          </Alert>
                        )}
                      </>
                    ) : (
                      <Box mt={4}>
                        <ReceiptPaymentInfoSection
                          register={register}
                          control={control}
                          errors={errors}
                        />
                        {paymentMethod !== 'CASH' && (
                          <ReceiptChequeInfoSection
                            method={paymentMethod}
                            register={register}
                            control={control}
                            errors={errors}
                          />
                        )}
                        <HStack mt={3} px={3} justifyContent="end">
                          <Button
                            variant="modalAction"
                            onClick={() => setDutyCertReceiptEditing(false)}>
                            Cancel
                          </Button>
                          <Button type="submit" variant="modalAction" isLoading={isSubmitting}>
                            Update
                          </Button>
                        </HStack>
                      </Box>
                    )}
                  </Box>
                )
              )}

              <VStack px={3} alignItems="flex-end">
                {form === 'SEARCH_RECEIPT' ? (
                  <Button
                    type="submit"
                    variant="modalAction"
                    isLoading={isSubmitting}
                    rightIcon={<Icon as={MdSearch} w={5} h={5} />}>
                    Search
                  </Button>
                ) : (
                  <HStack>
                    <VStack alignItems="flex-end" pr={3}>
                      {cert !== 'DUTY' && user?.adminRole === 'SUPERADMIN' && (
                        <Button
                          bg="red.500"
                          onClick={handleInvoiceDelete}
                          _hover={{ bg: 'red.400' }}
                          _focus={{ boxShadow: 'none' }}
                          rightIcon={<Icon as={MdDelete} w={5} h={5} />}
                          isDisabled={
                            data?.certificateDetails?.filter((item) => item?.status === 'USED')
                              ?.length > 0
                              ? true
                              : false
                          }>
                          DELETE
                        </Button>
                      )}
                      {data?.certificateDetails?.filter((item) => item?.status === 'USED')?.length >
                      0 ? (
                        <Text fontSize="xs" color="invalid" textAlign="right">
                          *** You cannot delete this entry because some of the certificates of this
                          batch are already used!
                        </Text>
                      ) : null}
                    </VStack>
                    {cert !== 'DUTY' &&
                      form === 'EDIT_RECEIPT' &&
                      receiptData.paymentStatus === 'PAID' && (
                        <Button type="submit" variant="modalAction" isLoading={isSubmitting}>
                          Update
                        </Button>
                      )}
                  </HStack>
                )}
              </VStack>
            </Box>
          </DialogueBox>
        );

      case 'UNREJECT_CERTIFICATE':
        return (
          <DialogueBox
            noCancel
            noAction
            close={onClose}
            mountControl={isOpen}
            alertTitle="Unreject Certificate">
            <Box as="form" onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <Text fontSize="18px" fontWeight="bold">
                  Are you sure you want to unreject certicate number {certData?.certificateNum}?
                </Text>

                <PasswordForm
                  executeAction={handleUnejection}
                  cancel={() => {
                    setModal();
                    onClose();
                  }}
                  action="unreject"
                />
              </Box>
            </Box>
          </DialogueBox>
        );

      case 'REJECT':
        return (
          <DialogueBox
            close={onClose}
            onActionKeepOpen
            mountControl={isOpen}
            alertTitle="Confirm Rejection"
            actionButtonLoading={isLoading}
            performAction={confirmRejection}
            alertMessage={`Are you sure you want to reject ${certData?.type} certificate number ${certData?.certificateNum}? ${certData?.millName} will not be able to use this certificate anymore!`}
          />
        );
      case 'DELETE':
        return (
          <DialogueBox
            close={onClose}
            onActionKeepOpen
            mountControl={isOpen}
            alertTitle="Confirm Deletion"
            actionButtonLoading={isLoading}
            performAction={confirmDeletion}
            alertMessage={`Are you sure you want to delete ${certData?.type} certificate number ${certData?.certificateNum}? ${certData?.millName} will not be able to use this certificate anymore!`}
          />
        );
      case 'REASSIGN':
        return (
          <DialogueBox
            close={onClose}
            onActionKeepOpen
            mountControl={isOpen}
            alertTitle="Confirm Re-assignment"
            actionButtonLoading={isLoading}
            performAction={confirmReassignment}
            alertMessage={`Are you sure you want to re-assign ${certData?.type} certificate number ${certData?.certificateNum} to ${formData?.millName}? Current holder ${certData?.millName} will not be able to use this certificate anymore!`}
          />
        );
      case 'REPLACE':
        return (
          <DialogueBox
            close={onClose}
            onActionKeepOpen
            mountControl={isOpen}
            alertTitle="Confirm Replacement"
            actionButtonLoading={isLoading}
            performAction={confirmReplacement}
            alertMessage={`Are you sure you want to replace ${certData?.type} certificate number ${certData?.certificateNum} with ${formData.replaceCertNumber}?`}
          />
        );
      case 'INVOICE_DELETE':
        return (
          <DialogueBox
            close={onClose}
            onActionKeepOpen
            alertTitle="Confirm"
            mountControl={isOpen}
            performAction={confirmInvoiceDelete}
            actionButtonLoading={isLoading}
            alertMessage="Are you sure you want to delete this batch of certificate?"
          />
        );
    }
  };

  const certificateType = {
    name: 'Certificate Type',
    choices: [
      { name: 'GSP', value: 'PRODUCTION_GSP' },
      { name: 'Processing', value: 'PROCESSING' },
      { name: 'Cash-Incentive', value: 'PRODUCTION_CASH' },
      { name: 'Duty-Exemption', value: 'DUTY' },
    ],
  };

  const handlePagination = async ({ page, pageSize, searchKey, selected }) => {
    dispatch({ type: FETCH_REJECTED_CERTIFICATES, payload: null });
    const params = {
      page,
      pageSize,
      searchKey,
      type: selected?.['Certificate Type'],
    };
    const res = await getAllRejectedCerts({ params });
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_REJECTED_CERTIFICATES, payload: res.data });
    }
  };

  const handleSearch = async ({ searchKey, selected }) => {
    dispatch({ type: FETCH_REJECTED_CERTIFICATES, payload: null });
    const params = {
      searchKey,
      type: selected?.['Certificate Type'],
    };
    const res = await getAllRejectedCerts({ params });
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_REJECTED_CERTIFICATES, payload: res.data });
    }
  };

  return (
    <Box>
      <HStack mb={4}>
        <Button
          _focus={{ boxShadow: 'none' }}
          h={['45px', '50px', '50px', '55px', '55px']}
          rightIcon={<Icon as={MdSearch} w={5} h={5} />}
          onClick={handleSearchCertificateClick}>
          Search Certificate
        </Button>
        <Button
          _focus={{ boxShadow: 'none' }}
          h={['45px', '50px', '50px', '55px', '55px']}
          rightIcon={<Icon as={MdSearch} w={5} h={5} />}
          onClick={handleSearchReceiptClick}>
          Search Receipt
        </Button>
      </HStack>
      <DataTable
        topPanel
        keySearch
        isPaginated
        columns={columns}
        search={handleSearch}
        currentPage={data?.page}
        actions={actionObject}
        data={data?.certificates}
        paginate={handlePagination}
        entries={data?.totalEntries}
        caption="Rejected Certificates"
        currentPageSize={data?.pageSize}
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
        selectOptions={[certificateType]}
      />
      {renderModal()}
    </Box>
  );
};

export default RejectedCertificates;
