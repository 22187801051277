import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import FormButton from '../../common/FormButton';
import { verifyAdminUser } from '../../../api/users';
import { notify } from '../../../store/actions/globalActions';
import { OTP_VALIDITY_TIME_IN_SECONDS } from '../../../constants/authConstant';
import { Box, Text, Stack, HStack, Spacer, PinInput, PinInputField } from '@chakra-ui/react';

const OtpVerification = ({ data, isLoading, resendOtp, closeModal, refresh }) => {
  const dispatch = useDispatch();

  // timer stuff start
  const [[minutes, seconds], setTime] = useState([
    parseInt(OTP_VALIDITY_TIME_IN_SECONDS / 60),
    OTP_VALIDITY_TIME_IN_SECONDS % 60,
  ]);
  const [otpValidity, setOtpValidity] = useState(true);

  const tickTimer = () => {
    if (minutes === 0 && seconds === 0) {
      setOtpValidity(false);
    } else if (seconds === 0) {
      setTime([minutes - 1, 59]);
    } else {
      setTime([minutes, seconds - 1]);
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => tickTimer(), 1000);
    return () => clearInterval(timerId);
  });
  // timer stuff end

  const handleComplete = async (otpToken) => {
    const res = await verifyAdminUser({ sessionId: data?.sessionId, otpToken });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 1500,
        }),
      );
      refresh();
      closeModal();
    } else {
      closeModal();
    }
  };

  return (
    <Box as="form" w="500px">
      {otpValidity && (
        <Box pb={14} pt={8}>
          <Text pt={11} fontSize="xl" textAlign="center">
            Enter OTP
          </Text>
          <HStack mt={10} justify="center">
            <PinInput
              otp
              size="lg"
              autoFocus
              type="alphanumeric"
              onComplete={handleComplete}
              focusBorderColor="primary.300">
              <PinInputField bg="secondary" />
              <PinInputField bg="secondary" />
              <PinInputField bg="secondary" />
              <PinInputField bg="secondary" />
            </PinInput>
          </HStack>
        </Box>
      )}
      <HStack mt={18} mb={6} justify="center">
        {otpValidity ? (
          <Stack mt={1}>
            <Text fontSize="2xl">
              {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
            </Text>
          </Stack>
        ) : (
          <Stack mt={1}>
            <Text mt={1} fontSize="xl" textAlign="center">
              OTP Expired!
            </Text>
            <Spacer />
            <FormButton isLoading={isLoading} onClick={() => resendOtp({ ...data, resend: true })}>
              RESEND OTP
            </FormButton>
          </Stack>
        )}
      </HStack>
    </Box>
  );
};

export default OtpVerification;
