import { Box, Text, Divider } from '@chakra-ui/react';
import React from 'react';

const MembershipFormWrapper = (props) => {
  return (
    <Box as="form" p={4} minH="100vh">
      <Text mb={2} fontSize="xl" fontWeight="bold">
        {props.formName}
      </Text>
      <Divider mb={2} />
      {props.children}
    </Box>
  );
};

export default MembershipFormWrapper;
