import { Box, HStack, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import DialogueBox from '../../common/DialogueBox';
import FormButton from '../../common/FormButton';
import { fieldsValidation } from '../millRepresentative/NewRepresentativeInfoSection';

import _ from 'lodash';
import { patchMillRepresentative } from '../../../api/millUsers';
import { notify } from '../../../store/actions/globalActions';
import { useYupValidationResolver } from '../../../util/yupUtils';
import RepresentativeInfoSection from './RepresentativeInfoSection';

const RepresentativeInfoForm = ({ data, closeModal, refresh }) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { name, phone, email, status } = data;
  const defaultFields = { name, phone, email, status };

  console.log(data);
  const resolver = useYupValidationResolver(
    yup.object().shape({
      ...fieldsValidation,
    }),
  );

  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: defaultFields,
  });

  const watchAllFields = watch();

  const onSubmit = (data) => {
    setUserData({ ...data });
    onOpen();
  };

  const confirmUpdate = async () => {
    const res = await patchMillRepresentative({ uid: data?.uid, userData });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 3000,
        }),
      );
      refresh();
      closeModal();
    }
  };

  return (
    <Box as="form" w="600px" onSubmit={handleSubmit(onSubmit)}>
      <RepresentativeInfoSection register={register} control={control} errors={errors} />
      <HStack w="100%" justifyContent="end" mt={6}>
        <FormButton
          mt={0}
          type="submit"
          isLoading={isSubmitting}
          isDisabled={_.isEqual(defaultFields, watchAllFields)}>
          UPDATE
        </FormButton>
      </HStack>
      <DialogueBox
        close={onClose}
        alertTitle="Confirm"
        mountControl={isOpen}
        performAction={confirmUpdate}
        alertMessage="Are you sure you want to update this representative's information?"
      />
    </Box>
  );
};

export default RepresentativeInfoForm;
