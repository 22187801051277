import React, { useEffect, useState } from 'react';
import PricingForm from './pricingForm';
import Loader from '../../snippets/Loader';
import { getAllPricing } from '../../../api/accounts';

const Pricing = () => {
  const [data, setData] = useState();
  useEffect(() => {
    (async () => {
      const res = await getAllPricing();
      if (res.data?.status === 200) {
        let pricing = res.data.priceList?.[0];
        setData({
          ...pricing?.factorsA,
          ...pricing?.factorsB,
          ...pricing?.factorsC,
          ...pricing?.factorsCertificate,
        });
      }
    })();
  }, []);

  const updateData = async () => {
    setData();
    const res = await getAllPricing();
    if (res.data?.status === 200) {
      let pricing = res.data.priceList?.[0];
      setData({
        ...pricing?.factorsA,
        ...pricing?.factorsB,
        ...pricing?.factorsC,
        ...pricing?.factorsCertificate,
      });
    }
  };

  return data ? <PricingForm defaultFields={data} refresh={updateData} /> : <Loader />;
};

export default Pricing;
