import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import * as yup from 'yup';
import { Box } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import FormSection from './hookFormComponents/FormSection';
import { useYupValidationResolver } from '../../util/yupUtils';

const FileUploader = forwardRef(
  ({ documents, fields, submitting, fieldsValidation, handleQuery }, ref) => {
    const resolver = useYupValidationResolver(
      yup.object().shape({
        ...fieldsValidation,
      }),
    );

    const {
      watch,
      control,
      register,
      setValue,
      handleSubmit,
      formState: { errors, isSubmitting },
    } = useForm({ resolver });

    useEffect(() => {
      submitting(isSubmitting);
    }, [isSubmitting]);

    useImperativeHandle(ref, () => ({
      submit() {
        handleSubmit(onSubmit)();
      },
    }));

    const onSubmit = (data) => {
      handleQuery(data);
    };

    return (
      <Box
        as="form"
        maxH="65vh"
        minW="65vw"
        overflow="auto"
        borderRadius="4px"
        border="1px solid #F6F7FC">
        <FormSection
          watch={watch}
          errors={errors}
          sectionColumn={1}
          control={control}
          formFields={fields}
          setValue={setValue}
          register={register}
          defaultFiles={documents}
        />
      </Box>
    );
  },
);

FileUploader.displayName = 'FileUploader';

export default FileUploader;
