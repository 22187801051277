import React from 'react';
import ReworkForm from './ReworkForm';
import { ModalHeader, ModalBody, Text, Divider } from '@chakra-ui/react';

const MembershipRework = ({ close, updateTable, backToTable }) => {
  return (
    <>
      <ModalHeader>
        <Text fontSize="22px" width="100%">
          Reason For Rework
        </Text>
        <Divider />
      </ModalHeader>
      <ModalBody mt={-8}>
        <ReworkForm closeModal={close} refresh={updateTable} backToTable={backToTable} />
      </ModalBody>
    </>
  );
};

export default MembershipRework;
