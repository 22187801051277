import { CloseIcon } from '@chakra-ui/icons';
import { Box, Divider, Grid, GridItem, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react';
import * as commaNumber from 'comma-number';
import React, { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import * as yup from 'yup';

//common components
import DialogueBox from '../../../common/DialogueBox';
import FormButton from '../../../common/FormButton';
import SearchSection from '../../../common/SearchSection';
import Loader from '../../../snippets/Loader';
import BillDate from '../../BillDate';
import CertificateBill from '../CertificateBill';
import CostBreakdown from '../CostBreakdown';
import MoneyReceipt from '../MoneyReceipt';
import CertRangeError from './CertRangeError';

//form sections
import CertificateForSection, { certificateForFieldsValidation } from './CertificateForSection';
import CertificateType, { certificateTypeFieldsValidation } from './CertificateType';
import ChequeInfoSection, { chequeInfoFieldsValidation } from './ChequeInfoSection';
import PurchaseInfoSection, { purchaseInfoFieldsValidation } from './PurchaseInfoSection';

//actions & api
import { getLastAssignedCertificates, postNewCertificateRequest } from '../../../../api/accounts';

//utils & constants
import _ from 'lodash';
import { MAX_BLANK_CERTIFICATE_COUNT } from '../../../../constants/certificateConstant';
import { notify } from '../../../../store/actions/globalActions';
import { formatDate, renderDate } from '../../../../util/formatUtils';
import { useYupValidationResolver } from '../../../../util/yupUtils';
import CertRangeSection, { certRangeFieldsValidation } from './CertRangeSection';
import NotationSection from './NotationSection';

const NewCertificateForm = ({ isFeeCollectionView }) => {
  const millErrorRef = useRef();
  const rangeErrorRef = useRef();
  const dispatch = useDispatch();
  const certificateBillRef = useRef();
  const certMoneyReceiptRef = useRef();
  const [modal, setModal] = useState();
  const [resData, setResData] = useState();
  const [print, setPrint] = useState(false);
  const [formData, setFormData] = useState();
  const [method, setMethod] = useState('CASH');
  const [certificates, setCertificates] = useState();
  const [selectedMill, setSelectedMill] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [billDate, setBillDate] = useState(new Date());
  const [rangeErrorMsg, setRangeErrorMsg] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [certificateType, setCertificateType] = useState();
  const [chequePayment, setChequePayment] = useState(false);

  const [errorResponse, setErrorResponse] = useState(null);

  const moneyReceiptData = {
    slNo: resData?.invoice?.orderId,
    mrDate: resData?.invoice?.dateSold,
    receivedFrom: resData?.invoice?.millName,
    amount: resData?.invoice?.total,
    paymentMethod: resData?.invoice?.paymentMethod,
    paymentFor:
      resData?.invoice?.certificateType === 'CASH'
        ? 'Cash-Incentive Certificate'
        : resData?.invoice?.certificateType === 'GSP'
        ? 'GSP Certificate'
        : resData?.invoice?.certificateType === 'DUTY'
        ? 'Duty-Exemption (Application Fee)'
        : resData?.invoice?.certificateType === 'NEW_MARKET_EXPANSION'
        ? 'New Market Expansion'
        : 'Processing',
    chequeNo: resData?.invoice?.chequeNo,
    quantity: resData?.invoice?.noOfCopies,
    chequeDated: resData?.invoice?.chequeDated,
    range: `${resData?.invoice?.startRange ? resData?.invoice?.startRange : ''}${
      parseInt(resData?.invoice?.endRange) > parseInt(resData?.invoice?.startRange)
        ? ` - ${resData?.invoice?.endRange}`
        : ''
    }`,
    total: resData?.invoice?.total,
    narration: resData?.invoice?.narration ? resData?.invoice?.narration : null,
  };

  useEffect(() => {
    (async () => {
      const res = await getLastAssignedCertificates();
      if (res.data?.status === 200) {
        setCertificates(res.data);
      }
    })();

    return () => setCertificates();
  }, []);

  useEffect(() => {
    setResData();
    setPrint(false);
  }, [selectedMill]);

  const getValidationSchema = () => {
    if (certificateType === 'PRODUCTION') {
      if (chequePayment) {
        return yup.object().shape({
          ...chequeInfoFieldsValidation(method),
          ...certRangeFieldsValidation,
          ...purchaseInfoFieldsValidation,
          ...certificateForFieldsValidation,
          ...certificateTypeFieldsValidation,
        });
      } else {
        return yup.object().shape({
          ...certRangeFieldsValidation,
          ...purchaseInfoFieldsValidation,
          ...certificateForFieldsValidation,
          ...certificateTypeFieldsValidation,
        });
      }
    } else if (certificateType === 'PROCESSING') {
      if (chequePayment) {
        return yup.object().shape({
          ...chequeInfoFieldsValidation(method),
          ...certRangeFieldsValidation,
          ...purchaseInfoFieldsValidation,
          ...certificateForFieldsValidation,
        });
      } else {
        return yup.object().shape({
          ...certRangeFieldsValidation,
          ...purchaseInfoFieldsValidation,
          ...certificateForFieldsValidation,
        });
      }
    } else {
      if (chequePayment) {
        return yup.object().shape({
          ...chequeInfoFieldsValidation(method),
          ...purchaseInfoFieldsValidation,
          ...certificateForFieldsValidation,
        });
      } else {
        return yup.object().shape({
          ...purchaseInfoFieldsValidation,
          ...certificateForFieldsValidation,
        });
      }
    }
  };

  const resolver = useYupValidationResolver(getValidationSchema());

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver,
    defaultValues: { dateSold: isFeeCollectionView ? new Date() : null, endRange: 0 },
  });

  const certFor = useWatch({ control, name: 'certFor' });
  const endRange = useWatch({ control, name: 'endRange' });
  const startRange = useWatch({ control, name: 'startRange' });
  const paymentMethod = useWatch({ control, name: 'paymentMethod' });
  const productionCertificateType = useWatch({ control, name: 'productionCertificateType' });
  const narration = useWatch({ control, name: 'narration' });

  useEffect(() => {
    setResData();
    setPrint(false);
    setMethod(paymentMethod);
    setCertificateType(certFor);
    console.log(narration);

    paymentMethod === 'CASH'
      ? setChequePayment(false)
      : paymentMethod
      ? setChequePayment(true)
      : setChequePayment(false);

    if (parseInt(endRange) > 0 && parseInt(startRange) > parseInt(endRange)) {
      setRangeErrorMsg('End Range must be greater than Start Range');
    } else if (parseInt(startRange) <= 0 && parseInt(endRange) >= 0) {
      setRangeErrorMsg('Start Range must be a valid number');
    } else if (parseInt(endRange) < 0) {
      setRangeErrorMsg('End Range must be a valid number or equal to 0');
    } else {
      if (
        parseInt(endRange) > 0 &&
        parseInt(parseInt(endRange) - parseInt(startRange) + 1) > MAX_BLANK_CERTIFICATE_COUNT
      ) {
        setRangeErrorMsg(
          `You can assign a maximum of ${MAX_BLANK_CERTIFICATE_COUNT} certificates at a time`,
        );
      } else {
        setRangeErrorMsg();
      }
    }
  }, [paymentMethod, certFor, startRange, endRange]);

  const calculateQuantity = (start, end) => {
    if (end) {
      return parseInt(end) >= parseInt(start)
        ? parseInt(end) - parseInt(start) + 1
        : parseInt(end) == 0
        ? 1
        : 0;
    } else {
      return start ? 1 : 0;
    }
  };

  const onSubmit = (data) => {
    setModal('CONFIRM');
    setFormData(data);
    selectedMill?.id && !rangeErrorMsg
      ? onOpen()
      : !selectedMill?.id
      ? millErrorRef.current.scrollIntoView()
      : !rangeErrorMsg
      ? rangeErrorRef.current.scrollIntoView()
      : null;
  };

  const confirmSubmit = async () => {
    const { certFor, endRange, ...rest } = formData;
    setIsSubmitting(true);
    const res = await postNewCertificateRequest({
      type: certFor,
      certData: {
        ...rest,
        endRange: endRange || 0,
        millId: selectedMill?.id,
        dateSold: formatDate(formData?.dateSold),
        chequeDated: formatDate(formData?.chequeDated),
        unitCost: certFor === 'DUTY' ? 100 : certFor === 'NEW_MARKET_EXPANSION' ? 200 : 400,
      },
    });
    setIsSubmitting(false);
    if (res.data?.status === 200) {
      let resetObj = {};
      _.mapKeys(formData, (value, key) => (resetObj[key] = ''));

      if (isFeeCollectionView) {
        resetObj.dateSold = new Date();
      }

      reset(resetObj);
      setPrint(true);
      dispatch(
        notify({
          title: 'Success',
          description: 'Certificate assignment successful',
          status: 'success',
          duration: 5000,
        }),
      );
      (async () => {
        const res = await getLastAssignedCertificates();
        if (res.data?.status === 200) {
          setCertificates(res.data);
        }
      })();
      setResData(res.data);
      setModal('SUCCESS');
      onOpen();
    } else {
      setErrorResponse(res?.data?.data);
      setModal('RANGE_ERROR');
      onOpen();
    }
  };

  const certificateCostData = {
    certFor: certFor
      ? certFor
      : resData?.invoice
      ? resData?.invoice?.certificateType === 'CASH' || resData?.invoice?.certificateType === 'GSP'
        ? 'production'
        : resData?.invoice?.certificateType === 'PROCESSING'
        ? 'processing'
        : resData?.invoice?.certificateType === 'NEW_MARKET_EXPANSION'
        ? 'NEW_MARKET_EXPANSION'
        : 'duty'
      : null,

    type:
      certFor === 'PRODUCTION'
        ? productionCertificateType === 'CASH'
          ? 'Cash-Incentive'
          : 'GSP'
        : certFor === 'PROCESSING'
        ? 'N/A'
        : resData?.invoice
        ? resData?.invoice?.certificateType === 'CASH'
          ? 'Cash-Incentive'
          : resData?.invoice?.certificateType === 'GSP'
          ? 'GSP'
          : 'N/A'
        : 'N/A',

    noOfCopies:
      certFor === 'DUTY' || resData?.invoice?.certificateType === 'DUTY'
        ? 1
        : certFor === 'NEW_MARKET_EXPANSION' ||
          resData?.invoice?.certificateType === 'NEW_MARKET_EXPANSION'
        ? 1
        : commaNumber(
            calculateQuantity(startRange, endRange) ||
              calculateQuantity(resData?.invoice?.startRange, resData?.invoice?.endRange) ||
              0,
          ),

    unitCost: certFor
      ? commaNumber(certFor === 'DUTY' ? 100 : certFor === 'NEW_MARKET_EXPANSION' ? 200 : 400)
      : resData?.invoice?.unitCost || 0,

    total:
      certFor === 'DUTY' || resData?.invoice?.certificateType === 'DUTY'
        ? 100
        : certFor === 'NEW_MARKET_EXPANSION' ||
          resData?.invoice?.certificateType === 'NEW_MARKET_EXPANSION'
        ? 200
        : certFor
        ? commaNumber(400 * calculateQuantity(startRange, endRange) || 0)
        : resData?.invoice?.total || 0,

    range:
      certFor === 'DUTY' || resData?.invoice?.certificateType === 'DUTY'
        ? null
        : `${startRange ? startRange : ''}${
            startRange && +endRange > +startRange ? `-${endRange}` : ''
          }` || moneyReceiptData?.range,
  };

  const handleBillPrint = () => {
    setModal('BILL_DATE');
    onOpen();
  };

  const printCertificateBill = useReactToPrint({
    documentTitle: `BTMA-Bill-${certificateCostData?.type}-Certificate-${
      selectedMill?.uid
    }-${renderDate(billDate)}`,
    content: () => certificateBillRef.current,
  });

  const certTypeMap = {
    GSP: 'GSP',
    CASH: 'Cash-Incentive',
    PROCESSING: 'Processing',
    DUTY: 'Duty-Exemption',
    NEW_MARKET_EXPANSION: 'New Market Expansion',
  };

  const renderModal = () => {
    switch (modal) {
      case 'CONFIRM':
        return (
          <DialogueBox
            close={onClose}
            alertTitle="Confirm"
            mountControl={isOpen}
            performAction={confirmSubmit}
            alertMessage={
              certFor === 'DUTY'
                ? `Are you sure you want to assign 1 Duty Exemption certificate to ${selectedMill?.millName}?`
                : certFor === 'NEW_MARKET_EXPANSION'
                ? `Are you sure you want to assign 1 New Market Expansion certificate to ${selectedMill?.millName}?`
                : `Are you sure you want to assign ${certFor}${
                    certFor === 'PRODUCTION' ? `-${productionCertificateType}` : ''
                  } certificate (${startRange}${endRange > startRange ? `-${endRange}` : ''}) to ${
                    selectedMill?.millName
                  }?`
            }
          />
        );
      case 'RANGE_ERROR':
        return (
          <DialogueBox
            noCancel
            scrollable
            close={() => {
              setErrorResponse(null);
              onClose();
            }}
            alertTitle={'Certificate Range Error!'}
            mountControl={isOpen}
            performAction={() => {
              setErrorResponse(null);
              onClose();
            }}
            actionButtonText="Close">
            <CertRangeError
              certFor={certFor}
              message={errorResponse?.msg}
              errorResponse={errorResponse}
              productionCertificateType={productionCertificateType}
            />
          </DialogueBox>
        );
      case 'SUCCESS':
        return (
          <DialogueBox
            noCancel
            close={onClose}
            mountControl={isOpen}
            actionButtonText="Close"
            alertTitle="Success"
            performAction={onClose}>
            <Text fontSize="md">
              {resData?.invoice?.noOfCopies} {certTypeMap[resData?.invoice?.certificateType]}{' '}
              certificate(s) assigned to {resData?.invoice?.millName} successfully!
            </Text>
          </DialogueBox>
        );
      case 'BILL_DATE':
        return (
          <DialogueBox
            noCancel
            close={onClose}
            mountControl={isOpen}
            actionButtonText="Okay"
            alertTitle="Issue Date"
            performAction={printCertificateBill}>
            <BillDate setDate={setBillDate} date={billDate} />
          </DialogueBox>
        );

      default:
        return null;
    }
  };

  return (
    <Box>
      <SearchSection setSelectedMill={setSelectedMill} showProvisionalsAlso={true} />

      <Grid templateColumns="repeat(5, 1fr)" gap={3} p={4} mt={5} bg="white" borderRadius="4px">
        <GridItem colSpan={5}>
          <Text fontSize="20px" fontWeight="bold" mb={1}>
            Mill & Last Used Certificate Number
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Box
            p={4}
            zIndex={1}
            border="1px"
            bg="secondary"
            borderRadius="4px"
            position="relative"
            borderStyle="dashed"
            borderColor="primary.300">
            {selectedMill?.id ? (
              <CloseIcon
                right={3}
                cursor="pointer"
                position="absolute"
                onClick={() => setSelectedMill()}
              />
            ) : null}
            <Text fontSize="md" color="textSecondary">
              Selected Mill
            </Text>
            <Text fontSize="xl" mt={1.5}>
              {selectedMill?.millName || 'None'}
            </Text>
          </Box>
        </GridItem>
        {!isFeeCollectionView && (
          <GridItem
            colSpan={1}
            p={4}
            border="1px"
            bg="secondary"
            borderRadius="4px"
            borderColor="primary.300">
            {certificates ? (
              <>
                <Text fontSize="md" color="textSecondary">
                  Production-GSP
                </Text>
                <Text fontSize="xl" mt={1.5}>
                  {certificates?.prodGsp || 'N/A'}
                </Text>
              </>
            ) : (
              <Loader />
            )}
          </GridItem>
        )}
        {!isFeeCollectionView && (
          <GridItem
            p={4}
            colSpan={1}
            border="1px"
            bg="secondary"
            borderRadius="4px"
            borderColor="primary.300">
            {certificates ? (
              <>
                <Text fontSize="md" color="textSecondary">
                  Production-Cash
                </Text>
                <Text fontSize="xl" mt={1.5}>
                  {certificates?.prodCash || 'N/A'}
                </Text>
              </>
            ) : (
              <Loader />
            )}
          </GridItem>
        )}
        {!isFeeCollectionView && (
          <GridItem
            p={4}
            colSpan={1}
            border="1px"
            bg="secondary"
            borderRadius="4px"
            borderColor="primary.300">
            {certificates ? (
              <>
                <Text fontSize="md" color="textSecondary">
                  Processing
                </Text>
                <Text fontSize="xl" mt={1.5}>
                  {certificates?.proc || 'N/A'}
                </Text>
              </>
            ) : (
              <Loader />
            )}
          </GridItem>
        )}
        {selectedMill ? null : (
          <GridItem colSpan={4}>
            <Text fontSize="sm" color="invalid" ref={millErrorRef}>
              Please Select a Mill
            </Text>
          </GridItem>
        )}
      </Grid>

      {print && (
        <HStack mt={4}>
          <Text fontSize="xl" fontWeight="bold">
            Invoice:
          </Text>
          <Text fontSize="xl" fontWeight="bold" color="red">
            <b>{resData?.invoice?.orderId}</b>
          </Text>
        </HStack>
      )}

      {selectedMill ? (
        <Box mt={4}>
          <Text mb={2} fontSize="xl" fontWeight="bold">
            Assign Certificate
          </Text>
          <Divider mb={2} />

          <Grid templateColumns="repeat(5, 1fr)" gap={3}>
            <GridItem
              p={4}
              as="form"
              bg="white"
              colSpan={2}
              borderRadius="4px"
              onSubmit={handleSubmit(onSubmit)}>
              <Text pt={3} pl={3} fontSize="md" fontWeight="bold">
                Required Information
              </Text>
              {print && (
                <Text pt={1} pb={3} pl={3} fontSize="sm" fontWeight="bold" color="red">
                  Updating any information now will reset the bill and the receipt!
                </Text>
              )}
              <Grid templateColumns="repeat(2,1fr)">
                <GridItem>
                  <CertificateForSection
                    register={register}
                    errors={errors}
                    control={control}
                    isFeeCollectionView={
                      isFeeCollectionView
                        ? isFeeCollectionView
                        : selectedMill?.membershipStatus == 'PROVISIONAL MEMBER'
                        ? true
                        : null
                    }
                  />
                </GridItem>
                {certFor === 'PRODUCTION' ? (
                  <GridItem>
                    <CertificateType register={register} errors={errors} control={control} />
                  </GridItem>
                ) : null}
              </Grid>

              <PurchaseInfoSection
                register={register}
                errors={errors}
                control={control}
                disabledFields={isFeeCollectionView ? ['dateSold'] : []}
              />

              {certFor !== 'DUTY' && certFor !== 'NEW_MARKET_EXPANSION' && !isFeeCollectionView && (
                <CertRangeSection register={register} errors={errors} control={control} />
              )}

              <Text px={3} fontSize="xs" color="invalid" ref={rangeErrorRef}>
                {rangeErrorMsg}
              </Text>
              {chequePayment && (
                <ChequeInfoSection
                  method={paymentMethod}
                  register={register}
                  errors={errors}
                  control={control}
                />
              )}

              <NotationSection register={register} control={control} errors={errors} />

              <Stack px={3} pb={3} flexDir="column" alignItems="flex-end">
                <FormButton type="submit" isLoading={isSubmitting}>
                  CONFIRM
                </FormButton>
              </Stack>
            </GridItem>
            <GridItem colSpan={3} p={4} bg="white" borderRadius="4px" overflowX="auto">
              <Box p={3}>
                <CostBreakdown data={certificateCostData} isFromBlankCert={true} />

                <Stack flexDir="column" alignItems="flex-end" mt={4}>
                  <HStack>
                    <FormButton mt={0} onClick={handleBillPrint}>
                      PRINT BILL
                    </FormButton>
                    <Box display="none">
                      <CertificateBill
                        title="Certificate"
                        ref={certificateBillRef}
                        costs={certificateCostData}
                        data={{
                          billDate,
                          billNo: selectedMill?.uid,
                          millName: selectedMill?.millName,
                          millAddress: selectedMill?.millAddress,
                        }}
                        isFromBlankCert={true}
                      />
                    </Box>
                    <ReactToPrint
                      documentTitle={`BTMA-Money-Receipt-${
                        resData?.invoice?.certificateType === 'CASH'
                          ? 'Cash-Incentive'
                          : resData?.invoice?.certificateType === 'GSP'
                          ? 'GSP'
                          : 'Processing'
                      }-Certificate-${resData?.invoice?.orderId}-${renderDate(
                        resData?.invoice?.dateSold,
                      )}`}
                      trigger={() => <FormButton isDisabled={!print}>PRINT RECEIPT</FormButton>}
                      content={() => certMoneyReceiptRef.current}
                    />
                    <Box display="none">
                      <MoneyReceipt ref={certMoneyReceiptRef} data={moneyReceiptData} />
                    </Box>
                  </HStack>
                </Stack>
              </Box>
            </GridItem>
          </Grid>
        </Box>
      ) : null}
      {renderModal()}
    </Box>
  );
};

export default NewCertificateForm;
