import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'yarnProdCap',
    type: 'text',
    isRequired: true,
    label: 'Yarn Production Capacity',
  },
  {
    colSpan: 1,
    name: 'yarnExpoCap',
    type: 'text',
    label: 'Yarn Exportable Capacity',
  },
];

export const yarnCapFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup.string().label(fields[1].label),
};

const YarnCap = (props) => {
  return <FormSection sectionName="Capacity" sectionColumn={2} formFields={fields} {...props} />;
};

export default YarnCap;
