import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'dyeing',
    type: 'text',
    label: 'Dyeing (kg/meter)',
  },
  {
    colSpan: 1,
    name: 'finishing',
    type: 'text',
    label: 'Finishing (kg/meter)',
  },
  {
    colSpan: 1,
    name: 'printing',
    type: 'text',
    label: 'Printing',
  },
  {
    colSpan: 1,
    name: 'anyOther',
    type: 'text',
    label: 'Any Other (specify)',
  },
];

export const AnnualCapacityFieldsValidation = {
  [fields[0].name]: yup.string(),
  [fields[1].name]: yup.string(),
  [fields[2].name]: yup.string(),
  [fields[3].name]: yup.string(),
};

const AnnualCapacitySection = (props) => {
  return (
    <FormSection sectionName="Annual Capacity" sectionColumn={4} formFields={fields} {...props} />
  );
};

export default AnnualCapacitySection;
