import React from 'react';
import { Box, FormControl, FormLabel, Select, Text } from '@chakra-ui/react';

const SelectFilters = ({ label, setValue, choices, error }) => {
  return (
    <Box zIndex={1}>
      <FormControl isInvalid={error}>
        <FormLabel mb={1.5} fontSize="md">
          {label}
        </FormLabel>
        <Select
          w="sm"
          h="50px"
          bg="formInputBG"
          borderRadius="2px"
          colorScheme="primary"
          focusBorderColor="primary.300"
          onChange={(e) => setValue(e.target.value)}>
          {choices?.map((item) => (
            <option key={item?.value} value={item?.value}>
              {item?.name}
            </option>
          ))}
        </Select>
      </FormControl>

      <Text color="invalid" fontSize="sm" mt="6px">
        {error}
      </Text>
    </Box>
  );
};

export default SelectFilters;
