import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'natureOfCompany',
    type: 'text',
    label: 'Nature of Company',
  },
  {
    colSpan: 1,
    name: 'mainManufactureActivity',
    type: 'text',
    label: 'Main Manufacturing Activity',
  },
];

export const otherInformationFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label),
  [fields[1].name]: yup.string().label(fields[1].label),
};

const CapitalStructureSection = (props) => {
  return (
    <FormSection sectionName="Other Information" sectionColumn={2} formFields={fields} {...props} />
  );
};

export default CapitalStructureSection;
