import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import NavPanel from '../../common/NavPanel';
import Transaction from './Transactions/Transaction';
import RenewalsTable from './TableView/RenewalsTable';
import NewRequestsTable from './TableView/NewRequestsTable';

const AccountsMembership = () => {
  const [selected, setSelected] = useState(0);
  const [scroll, setScroll] = useState(false);
  const navList = ['New Requests', 'Renewal Requests', 'Transactions & Status'];

  const history = useHistory();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const tabValue = queryParams.get('tab');

  const handleScroll = () => {
    window.scrollY > 10 ? setScroll(true) : setScroll(false);
  };

  window.addEventListener('scroll', handleScroll);

  useEffect(() => {
    document.title = 'BTMA - Accounts';
  }, []);

  // useEffect(() => {
  //   let currState = parseInt(localStorage.getItem('ACC_MEM_TAB'));
  //   if (currState) {
  //     setSelected(currState);
  //   }
  //   return () => {
  //     setSelected(0);
  //   };
  // }, []);

  //  const updateTab = (tabState) => {

  //    setSelected(tabState);
  //    localStorage.setItem('ACC_MEM_TAB', tabState);
  //  };

  useEffect(() => {
    if (!tabValue) {
      setSelected(0);
    } else {
      setSelected(parseInt(tabValue));
    }
  }, [tabValue]);

  const updateTab = (tabState) => {
    history.push({
      search: `?tab=${tabState}`,
    });
  };

  const renderContent = () => {
    switch (selected) {
      case 0:
        return <NewRequestsTable />;
      case 1:
        return <RenewalsTable />;
      case 2:
        return <Transaction />;
      default:
        return <NewRequestsTable />;
    }
  };

  return (
    <Box bg="secondary">
      <Box
        position="sticky"
        top={`${document?.getElementById('headerWrapper')?.offsetHeight}px`}
        right="0px"
        p={4}
        bg="secondary"
        zIndex={98}
        shadow={scroll ? 'sm' : 'none'}>
        <NavPanel navState={selected} setNavState={updateTab} navList={navList} />
      </Box>
      <Box p={4}>{renderContent()}</Box>
    </Box>
  );
};

export default AccountsMembership;
