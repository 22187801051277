import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import MembershipRequestTable from './TableView/MembershipRequestTable';

const NewRequest = () => {
  useEffect(() => {
    document.title = 'BTMA - Membership';
  }, []);

  return (
    <Box p={4}>
      <MembershipRequestTable />
    </Box>
  );
};

export default NewRequest;
