import { Box, HStack, Text } from '@chakra-ui/react';
import commaNumber from 'comma-number';
import React from 'react';
import { renderDate } from '../../util/formatUtils';
import PrintReceiptHeader from '../snippets/PrintReceiptHeader';
import './printStyle.css';

const sortDataByPaymentMethod = (unsortedData, sortKey, title) => {
  const amountKey = title === 'Duty-Exemption' || title === 'Membership' ? 'paidAmount' : 'total';
  let cash = [];
  let cheque = [];
  let eft = [];
  let mfs = [];

  let cashTotal = 0;
  let chequeTotal = 0;
  let eftTotal = 0;
  let mfsTotal = 0;

  if (title == 'Duty-Exemption') {
    unsortedData.forEach((element) => {
      element?.paymentHistory?.forEach((payment) => {
        switch (payment?.paymentMethod) {
          case 'CASH':
            cash.push({ ...element, ...payment });
            break;
          case 'CHEQUE':
            cheque.push({ ...element, ...payment });
            break;
          case 'EFT':
            eft.push({ ...element, ...payment });
            break;
          case 'MFS':
            mfs.push({ ...element, ...payment });
            break;
          default:
            break;
        }
      });
    });
  } else {
    cash = unsortedData?.filter((item) => item?.[sortKey] === 'CASH');
    cheque = unsortedData?.filter((item) => item?.[sortKey] === 'CHEQUE');
    eft = unsortedData?.filter((item) => item?.[sortKey] === 'EFT');
    mfs = unsortedData?.filter((item) => item?.[sortKey] === 'MFS');
  }

  cash?.forEach((element) => {
    cashTotal += parseInt(element?.[amountKey]) || 0;
  });
  cheque?.forEach((element) => {
    chequeTotal += parseInt(element?.[amountKey]) || 0;
  });
  eft?.forEach((element) => {
    eftTotal += parseInt(element?.[amountKey]) || 0;
  });
  mfs?.forEach((element) => {
    mfsTotal += parseInt(element?.[amountKey]) || 0;
  });

  return {
    sorted: [
      { data: cash, total: cashTotal },
      { data: cheque, total: chequeTotal },
      { data: eft, total: eftTotal },
      { data: mfs, total: mfsTotal },
    ],
    grandTotal: cashTotal + chequeTotal + eftTotal + mfsTotal,
  };
};

const dutyCertPaymentMethodWiseSum = (data) => {
  let cashTotal = 0;
  let chequeTotal = 0;
  let eftTotal = 0;
  let mfsTotal = 0;

  data?.forEach((element) => {
    element?.paymentHistory?.forEach((payment) => {
      switch (payment?.paymentMethod) {
        case 'CASH':
          cashTotal += parseInt(payment?.paidAmount) || 0;
          break;
        case 'CHEQUE':
          chequeTotal += parseInt(payment?.paidAmount) || 0;
          break;
        case 'EFT':
          eftTotal += parseInt(payment?.paidAmount) || 0;
          break;
        case 'MFS':
          mfsTotal += parseInt(payment?.paidAmount) || 0;
          break;
        default:
          break;
      }
    });
  });

  return [
    { name: 'CASH', total: cashTotal },
    { name: 'CHEQUE', total: chequeTotal },
    { name: 'EFT', total: eftTotal },
    { name: 'MFS', total: mfsTotal },
  ];
};

const getTotalSold = (type, data) => {
  let total = 0;
  if (data?.length > 0) {
    if (type === 'Duty-Exemption') {
      total = data?.length || 0;
    } else if (type === 'Extra Collection (Duty)') {
      total = data?.length || 0;
    } else if (type === 'New Market Expansion') {
      total = data?.length || 0;
    } else {
      data.map((item) => {
        total += parseInt(item?.noOfCopies);
      });
    }
  }
  return total;
};

// const getGrandTotalIssue = (data) => {
//   let total = 0;
//   data?.map((item) => {
//     total += item.issued;
//   });
//   return total;
// };

// const getGrandTotalSold = (data) => {
//   let total = 0;
//   data?.map((item) => {
//     item?.title === 'Duty-Exemption'
//       ? (total += parseInt(item?.saleDetails?.length || 0))
//       : item?.saleDetails?.map((cert) => {
//           total += parseInt(cert?.noOfCopies);
//         });
//   });
//   return total;
// };

// const getGrandTotalRevenue = (data) => {
//   let total = 0;
//   data?.map((item) => {
//     total += item?.revenue;
//   });
//   return total;
// };

const isFirstElem = (selected, data, index) => {
  let flag = true;
  if (index > 0) {
    for (let i = 0; i < index; i++) {
      if (selected?.includes(data?.[i]?.title)) {
        flag = false;
      }
    }
  }

  return flag;
};

const styles = {
  headerStyle: {
    fontSize: '14px',
    fontWeight: 'bold',
    padding: '0px 5px',
    textAlign: 'center',
    border: '1px solid black',
    borderCollapse: 'collapse',
  },
  cellStyle: {
    fontSize: '14px',
    textAlign: 'center',
    padding: '0px 5px',
    border: '1px solid black',
    borderCollapse: 'collapse',
  },
};

const EodStatement = React.forwardRef(
  ({ data, memTrxData, otherTrxData, date, selections }, ref) => {
    //console.log(data);
    return (
      <Box w="100%" ref={ref}>
        <PrintReceiptHeader data={data} />

        <HStack spacing={0} w="100%" justifyContent="center">
          <Text fontSize="2xl" fontWeight="bold" pb={4}>
            BTMA Statement
          </Text>
        </HStack>
        <HStack spacing={0} w="100%" justifyContent="right">
          <Text fontWeight="bold">Date:</Text>
          <Text
            px={2}
            minW="140px"
            fontSize="lg"
            fontWeight="bold"
            textAlign="center"
            borderStyle="dotted"
            borderBottom="2px solid black">
            {date}
          </Text>
        </HStack>

        {selections?.issueCheckedItems?.length > 0 && (
          <Text fontSize="16px" fontWeight="bold" my={2}>
            #Certificate Issuance Summary:
          </Text>
        )}

        {data?.map(
          (item, index) =>
            selections?.issueCheckedItems?.includes(item.title) && (
              <>
                {!isFirstElem(selections?.issueCheckedItems, data, index) && item?.issued > 0 && (
                  <div className="page-break" />
                )}
                <Box key={index} p={4}>
                  <HStack
                    w="100%"
                    justifyContent="space-between"
                    borderBottom="2px solid black"
                    mb={1}>
                    <Text fontSize="16px" fontWeight="bold">
                      {item?.title}
                    </Text>
                    <Text fontSize="16px">
                      <b>Total Issued:</b> {item?.issued}
                    </Text>
                  </HStack>

                  {item?.issueDetails?.length > 0 ? (
                    <table
                      style={{
                        border: '1px solid black',
                        borderCollapse: 'collapse',
                        width: '100%',
                      }}>
                      <thead>
                        <tr>
                          <th style={styles.headerStyle}>Sl. No.</th>
                          <th style={styles.headerStyle}>Mill Name</th>
                          <th style={styles.headerStyle}>Batch Id.</th>
                          <th style={styles.headerStyle}>Certificate No.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.issueDetails.map((cert, rowIndex) => (
                          <tr key={rowIndex}>
                            <td style={styles.cellStyle}>{rowIndex + 1}</td>
                            <td style={{ ...styles.cellStyle, textAlign: 'left' }}>
                              {cert?.millInfo?.millName}
                            </td>
                            <td style={styles.cellStyle}>{cert?.batchId}</td>
                            <td style={styles.cellStyle}>{cert?.certificateNum}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : null}
                </Box>
              </>
            ),
        )}
        {/* {selections?.issueCheckedItems?.length > 0 ? (
      <Text fontSize="16px" fontWeight="bold" mb={4} textAlign="right">
        Total no. of certificates issued: {getGrandTotalIssue(data)}
      </Text>
    ) : null} */}

        {selections?.salesCheckedItems?.length > 0 ? (
          <>
            {selections?.issueCheckedItems?.length > 0 ? <div className="page-break" /> : null}
            <Text fontSize="16px" fontWeight="bold" my={6} mb={2}>
              #Certificate Sales Summary:
            </Text>
          </>
        ) : null}
        {data?.map((item, index) =>
          selections?.salesCheckedItems?.includes(item.title) ? (
            <>
              {isFirstElem(selections?.salesCheckedItems, data, index) ? null : getTotalSold(
                  item?.title,
                  item?.saleDetails,
                ) > 0 ? (
                <div className="page-break" />
              ) : null}
              <Box key={index} p={4}>
                <HStack
                  w="100%"
                  justifyContent="space-between"
                  borderBottom="2px solid black"
                  mb={1}>
                  <Text fontSize="16px" fontWeight="bold">
                    {item?.title}
                  </Text>
                  <Text fontSize="16px">
                    <b>
                      {item.title === 'Duty-Exemption'
                        ? 'Total'
                        : item.title === 'Extra Collection (Duty)'
                        ? 'Total Collection'
                        : 'Total Sold'}
                      :
                    </b>{' '}
                    {getTotalSold(item?.title, item?.saleDetails)}{' '}
                    {item.title === 'Duty-Exemption'
                      ? 'transactions'
                      : item.title === 'Extra Collection (Duty)'
                      ? 'times'
                      : 'copies'}
                  </Text>
                </HStack>

                {item?.saleDetails?.length > 0 ? (
                  <table
                    style={{
                      border: '1px solid black',
                      borderCollapse: 'collapse',
                      width: '100%',
                    }}>
                    <thead>
                      {item?.title === 'Duty-Exemption' ? (
                        <tr>
                          <th style={styles.headerStyle}>Sl. No.</th>
                          <th style={styles.headerStyle}>Date</th>
                          <th style={styles.headerStyle}>Mill Name</th>
                          <th style={styles.headerStyle}>Serial</th>
                          <th style={styles.headerStyle}>Total (৳)</th>
                          <th style={styles.headerStyle}>Discount (৳)</th>
                          <th style={styles.headerStyle}>Adjustments (৳)</th>
                          <th style={styles.headerStyle}>Dues (৳)</th>
                          <th style={styles.headerStyle}>Money Receipt No.</th>
                          <th style={styles.headerStyle}>Payment Method</th>
                          <th style={styles.headerStyle}>Cheque Date</th>
                          <th style={styles.headerStyle}>Paid Amount (৳)</th>
                        </tr>
                      ) : item?.title === 'New Market Expansion' ? (
                        <tr>
                          <th style={styles.headerStyle}>Sl. No.</th>
                          <th style={styles.headerStyle}>Mill Name</th>
                          <th style={styles.headerStyle}>Money Receipt No.</th>
                          <th style={styles.headerStyle}>Payment Method</th>
                          <th style={styles.headerStyle}>Cheque Date</th>
                          <th style={styles.headerStyle}>Copies</th>
                          <th style={styles.headerStyle}>Unit cost (৳)</th>
                          <th style={styles.headerStyle}>Sub-total (৳)</th>
                        </tr>
                      ) : item?.title !== 'Extra Collection (Duty)' ? (
                        <tr>
                          <th style={styles.headerStyle}>Sl. No.</th>
                          <th style={styles.headerStyle}>Mill Name</th>
                          <th style={styles.headerStyle}>Money Receipt No.</th>
                          <th style={styles.headerStyle}>Payment Method</th>
                          <th style={styles.headerStyle}>Cheque Date</th>
                          <th style={styles.headerStyle}>Range</th>
                          <th style={styles.headerStyle}>Copies</th>
                          <th style={styles.headerStyle}>Unit cost (৳)</th>
                          <th style={styles.headerStyle}>Sub-total (৳)</th>
                        </tr>
                      ) : (
                        <tr>
                          <th style={styles.headerStyle}>Sl. No.</th>
                          <th style={styles.headerStyle}>Date</th>
                          <th style={styles.headerStyle}>Mill Name</th>
                          <th style={styles.headerStyle}>Money Receipt No.</th>
                          <th style={styles.headerStyle}>Paid Amount (৳)</th>
                          <th style={styles.headerStyle}>Payment Method</th>
                          <th style={styles.headerStyle}>Cheque No.</th>
                          <th style={styles.headerStyle}>Extra Collection (৳)</th>
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {item?.title === 'Duty-Exemption' ? (
                        <>
                          {item?.saleDetails?.map((cert, rowIndex) =>
                            cert?.paymentHistory?.map((payment, pIndex) => (
                              <tr key={rowIndex}>
                                {pIndex === 0 ? (
                                  <>
                                    <td
                                      style={{
                                        ...styles.cellStyle,
                                        borderBottom: 0,
                                      }}>
                                      {rowIndex + 1}
                                    </td>
                                    <td
                                      style={{
                                        ...styles.cellStyle,
                                        borderBottom: 0,
                                      }}>
                                      {renderDate(cert?.payDay, 'numeric')}
                                    </td>
                                    <td
                                      style={{
                                        ...styles.cellStyle,
                                        borderBottom: 0,
                                        textAlign: 'left',
                                      }}>
                                      {cert?.millInfo?.millName}
                                    </td>
                                    <td
                                      style={{
                                        ...styles.cellStyle,
                                        whiteSpace: 'nowrap',
                                        borderBottom: 0,
                                      }}>
                                      {cert?.certificateInfo?.certificateNum}
                                    </td>
                                    <td
                                      style={{
                                        ...styles.cellStyle,
                                        textAlign: 'right',
                                        borderBottom: 0,
                                      }}>
                                      {commaNumber(cert?.total)}
                                    </td>
                                    <td
                                      style={{
                                        ...styles.cellStyle,
                                        textAlign: 'right',
                                        borderBottom: 0,
                                      }}>
                                      {commaNumber(cert?.discount)}
                                    </td>
                                    <td
                                      style={{
                                        ...styles.cellStyle,
                                        textAlign: 'right',
                                        borderBottom: 0,
                                      }}>
                                      {commaNumber(cert?.adjustments) || 0}
                                    </td>
                                    <td
                                      style={{
                                        ...styles.cellStyle,
                                        textAlign: 'right',
                                        borderBottom: 0,
                                      }}>
                                      {commaNumber(cert?.dues)}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    {[...Array(6)].map((_, i) => (
                                      <td
                                        key={i}
                                        style={{
                                          ...styles.cellStyle,
                                          borderTop: 0,
                                          borderBottom: 0,
                                        }}
                                      />
                                    ))}
                                  </>
                                )}
                                <td style={styles.cellStyle}>{payment?.uid}</td>
                                <td style={styles.cellStyle}>
                                  {payment?.paymentMethod}{' '}
                                  {payment?.paymentMethod !== 'CASH' && ` - ${payment?.chequeNo}`}
                                </td>
                                <td style={styles.cellStyle}>
                                  {renderDate(payment?.chequeDated, 'numeric')}
                                </td>
                                <td style={{ ...styles.cellStyle, textAlign: 'right' }}>
                                  {commaNumber(payment?.paidAmount)}
                                </td>
                              </tr>
                            )),
                          )}
                          {dutyCertPaymentMethodWiseSum(item?.saleDetails)?.map(
                            (method, mIndex) => (
                              <tr key={mIndex}>
                                <td style={styles.cellStyle} />
                                <td
                                  style={{
                                    ...styles.cellStyle,
                                    textAlign: 'left',
                                    fontWeight: '600',
                                  }}>
                                  Total ({method?.name}):
                                </td>
                                <td style={styles.cellStyle} />
                                <td style={styles.cellStyle} />
                                <td style={styles.cellStyle} />
                                <td style={styles.cellStyle} />
                                <td style={styles.cellStyle} />
                                <td style={styles.cellStyle} />
                                <td style={styles.cellStyle} />
                                <td style={styles.cellStyle} />
                                <td style={styles.cellStyle} />
                                <td
                                  style={{
                                    ...styles.cellStyle,
                                    textAlign: 'right',
                                    fontWeight: '600',
                                  }}>
                                  ৳{commaNumber(method?.total)}
                                </td>
                              </tr>
                            ),
                          )}
                        </>
                      ) : item?.title === 'Extra Collection (Duty)' ? (
                        item?.saleDetails?.map((row, index) => (
                          <tr key={index}>
                            <td style={styles.cellStyle}>{index + 1}</td>
                            <td style={{ ...styles.cellStyle, textAlign: 'left' }}>
                              {renderDate(row?.createdAt)}
                            </td>
                            <td style={{ ...styles.cellStyle, textAlign: 'left' }}>
                              {row?.millInfo?.millName}
                            </td>
                            <td style={{ ...styles.cellStyle, textAlign: 'center' }}>
                              {row?.purchaseInfo?.uid || 'N/A'}
                            </td>
                            <td style={{ ...styles.cellStyle, textAlign: 'center' }}>
                              {row?.purchaseInfo?.paidAmount || 0}
                            </td>
                            <td style={{ ...styles.cellStyle, textAlign: 'center' }}>
                              {row?.purchaseInfo?.paymentMethod || 'N/A'}
                            </td>
                            <td style={{ ...styles.cellStyle, textAlign: 'left' }}>
                              {row?.purchaseInfo?.chequeNo || 'N/A'}
                            </td>

                            <td style={{ ...styles.cellStyle, textAlign: 'center' }}>
                              {commaNumber(row?.amount)}
                            </td>
                          </tr>
                        ))
                      ) : item?.title === 'New Market Expansion' ? (
                        item?.saleDetails?.map((row, index) => (
                          <tr key={index}>
                            <td style={styles.cellStyle}>{index + 1}</td>
                            <td style={{ ...styles.cellStyle, textAlign: 'left' }}>
                              {row?.millInfo?.millName}
                            </td>
                            <td style={styles.cellStyle}>{row?.uid}</td>
                            <td style={styles.cellStyle}>
                              {row?.paymentMethod}
                              {row?.paymentMethod !== 'CASH' && ` - ${row?.chequeNo}`}
                            </td>
                            <td style={styles.cellStyle}>
                              {renderDate(row?.chequeDated, 'numeric')}
                            </td>
                            <td style={styles.cellStyle}> {row?.noOfCopies}</td>
                            <td style={styles.cellStyle}> {row?.unitCost}</td>
                            <td style={{ ...styles.cellStyle, textAlign: 'right' }}>
                              {commaNumber(row?.total)}
                            </td>
                          </tr>
                        ))
                      ) : (
                        sortDataByPaymentMethod(
                          item.saleDetails,
                          'paymentMethod',
                          item.title,
                        )?.sorted?.map((sortItem) => (
                          <>
                            {sortItem?.data?.map((cert, rowIndex) => (
                              <tr key={rowIndex}>
                                <td style={styles.cellStyle}>{rowIndex + 1}</td>
                                <td style={{ ...styles.cellStyle, textAlign: 'left' }}>
                                  {cert?.millInfo?.millName}
                                </td>
                                <td style={styles.cellStyle}>{cert?.uid}</td>
                                <td style={styles.cellStyle}>
                                  {cert?.paymentMethod}
                                  {cert?.paymentMethod !== 'CASH' && ` - ${cert?.chequeNo}`}
                                </td>
                                <td style={styles.cellStyle}>
                                  {renderDate(cert?.chequeDated, 'numeric')}
                                </td>
                                <td style={{ ...styles.cellStyle, whiteSpace: 'nowrap' }}>{`${
                                  cert?.startRange || 'N/A'
                                }${cert?.endRange ? ` - ${cert?.endRange}` : ''}`}</td>
                                <td style={styles.cellStyle}>{cert?.noOfCopies}</td>
                                <td style={styles.cellStyle}>{cert?.unitCost}</td>
                                <td style={{ ...styles.cellStyle, textAlign: 'right' }}>
                                  {commaNumber(cert?.total)}
                                </td>
                              </tr>
                            ))}
                            {sortItem?.data?.length > 0 ? (
                              <tr>
                                <td style={styles.cellStyle} />
                                <td
                                  style={{
                                    ...styles.cellStyle,
                                    textAlign: 'left',
                                    fontWeight: '600',
                                  }}>
                                  Total {` (${sortItem?.data?.[0]?.paymentMethod})` || ''}:
                                </td>
                                <td style={styles.cellStyle} />
                                <td style={styles.cellStyle} />
                                <td style={styles.cellStyle} />
                                <td style={styles.cellStyle} />
                                <td style={styles.cellStyle} />
                                <td style={styles.cellStyle} />
                                <td
                                  style={{
                                    ...styles.cellStyle,
                                    textAlign: 'right',
                                    fontWeight: '600',
                                  }}>
                                  ৳{commaNumber(sortItem?.total)}
                                </td>
                              </tr>
                            ) : null}
                            <></>
                          </>
                        ))
                      )}

                      {item?.title === 'Extra Collection (Duty)' ? (
                        <tr>
                          <td style={styles.cellStyle} />
                          <td
                            style={{
                              ...styles.cellStyle,
                              textAlign: 'left',
                              fontWeight: '600',
                              fontSize: '20px',
                            }}>
                            Total:
                          </td>
                          <td style={styles.cellStyle} />
                          <td style={styles.cellStyle} />
                          <td style={styles.cellStyle} />
                          <td style={styles.cellStyle} />
                          <td style={styles.cellStyle} />

                          <td
                            style={{
                              ...styles.cellStyle,
                              textAlign: 'center',
                              fontWeight: '600',
                              fontSize: '20px',
                            }}>
                            ৳{commaNumber(item?.revenue)}
                          </td>
                        </tr>
                      ) : item?.title === 'New Market Expansion' ? (
                        <tr>
                          <td style={styles.cellStyle} />
                          <td
                            style={{
                              ...styles.cellStyle,
                              textAlign: 'left',
                              fontWeight: '600',
                              fontSize: '20px',
                            }}>
                            Total:
                          </td>
                          <td style={styles.cellStyle} />
                          <td style={styles.cellStyle} />
                          <td style={styles.cellStyle} />
                          <td style={styles.cellStyle} />
                          <td style={styles.cellStyle} />

                          <td
                            style={{
                              ...styles.cellStyle,
                              textAlign: 'center',
                              fontWeight: '600',
                              fontSize: '20px',
                            }}>
                            ৳{commaNumber(item?.revenue)}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td style={styles.cellStyle} />
                          <td
                            style={{
                              ...styles.cellStyle,
                              textAlign: 'left',
                              fontWeight: '600',
                              fontSize: '20px',
                            }}>
                            Total:
                          </td>
                          <td style={styles.cellStyle} />
                          <td style={styles.cellStyle} />
                          <td style={styles.cellStyle} />
                          <td style={styles.cellStyle} />
                          <td style={styles.cellStyle} />
                          <td style={styles.cellStyle} />
                          {item?.title === 'Duty-Exemption' && (
                            <>
                              <td style={styles.cellStyle} />
                              <td style={styles.cellStyle} />
                              <td style={styles.cellStyle} />
                            </>
                          )}
                          <td
                            style={{
                              ...styles.cellStyle,
                              textAlign: 'right',
                              fontWeight: '600',
                              fontSize: '20px',
                            }}>
                            ৳{commaNumber(item?.revenue)}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                ) : null}
              </Box>
            </>
          ) : null,
        )}
        {/* {selections?.salesCheckedItems?.length > 0 ? (
      <>
        <Text fontSize="16px" fontWeight="bold" textAlign="right">
          Total no. of certificates sold: {getGrandTotalSold(data)}
        </Text>
        <Text fontSize="16px" fontWeight="bold" textAlign="right">
          Total revenue from certificates sold: ৳{getGrandTotalRevenue(data)}
        </Text>
      </>
    ) : null} */}

        {selections?.membershipTrx ? (
          <>
            {(selections?.issueCheckedItems?.length > 0 ||
              selections?.salesCheckedItems?.length > 0) &&
            memTrxData?.details?.length > 0 ? (
              <div className="page-break" />
            ) : null}
            <>
              <Text fontSize="16px" fontWeight="bold" my={6} mb={2}>
                #Membership Transactions Summary:
              </Text>
              <Text fontSize="14px" color="red">
                *<b>PM =</b> Payment Method
              </Text>
            </>
          </>
        ) : null}
        {selections?.membershipTrx ? (
          <Box p={4}>
            <HStack w="100%" justifyContent="space-between" borderBottom="2px solid black" mb={1}>
              <Text fontSize="16px" fontWeight="bold">
                Membership Payments
              </Text>
              <Text fontSize="16px">
                <b>Total Received:</b> ৳
                {commaNumber(
                  sortDataByPaymentMethod(memTrxData?.details, 'paymentMethod', 'Membership')
                    ?.grandTotal,
                )}
              </Text>
            </HStack>
            {memTrxData?.details?.length > 0 ? (
              <table
                style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                  <tr>
                    <th style={styles.headerStyle}>No.</th>
                    <th style={styles.headerStyle}>Date</th>
                    <th style={styles.headerStyle}>Trx Id</th>
                    <th style={styles.headerStyle}>Mill Name</th>
                    <th style={styles.headerStyle}>PM</th>
                    <th style={styles.headerStyle}>PM Date</th>
                    <th style={styles.headerStyle}>Period</th>
                    <th style={styles.headerStyle}>Payment For</th>
                    <th style={styles.headerStyle}>Paid Amount (৳)</th>
                  </tr>
                </thead>
                <tbody>
                  {sortDataByPaymentMethod(
                    memTrxData?.details,
                    'paymentMethod',
                    'Membership',
                  )?.sorted?.map((sortItem) => (
                    <>
                      {sortItem?.data?.length > 0 ? (
                        <>
                          {sortItem?.data?.map((trx, rowIndex) => (
                            <tr key={rowIndex}>
                              <td style={styles.cellStyle}>{rowIndex + 1}</td>
                              <td style={styles.cellStyle}>
                                {renderDate(trx.issueDate, 'numeric')}
                              </td>
                              <td style={styles.cellStyle}>{trx.trxId}</td>
                              <td style={{ ...styles.cellStyle, textAlign: 'left' }}>
                                {trx.millInfo.millName}
                              </td>
                              <td style={styles.cellStyle}>
                                {trx.paymentMethod}{' '}
                                {trx?.paymentMethod !== 'CASH' && ` - ${trx?.chequeNo}`}
                              </td>
                              <td style={styles.cellStyle}>
                                {renderDate(trx.chequeDate, 'numeric')}
                              </td>
                              <td style={styles.cellStyle}>{trx.period}</td>
                              <td style={styles.cellStyle}>{trx.remarks}</td>
                              <td style={{ ...styles.cellStyle, textAlign: 'right' }}>
                                {commaNumber(trx.paidAmount)}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td style={styles.cellStyle} />
                            <td
                              style={{
                                ...styles.cellStyle,
                                textAlign: 'left',
                                fontWeight: '600',
                              }}>
                              Total {` (${sortItem?.data?.[0]?.paymentMethod})` || ''}:
                            </td>
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td
                              style={{
                                ...styles.cellStyle,
                                textAlign: 'right',
                                fontWeight: '600',
                              }}>
                              ৳{commaNumber(sortItem?.total)}
                            </td>
                          </tr>
                          <tr>
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                          </tr>
                        </>
                      ) : null}
                    </>
                  ))}
                  <tr>
                    <td style={styles.cellStyle} />
                    <td
                      style={{
                        ...styles.cellStyle,
                        textAlign: 'left',
                        fontWeight: '600',
                        fontSize: '20px',
                      }}>
                      Total
                    </td>
                    <td style={styles.cellStyle} />
                    <td style={styles.cellStyle} />
                    <td style={styles.cellStyle} />
                    <td style={styles.cellStyle} />
                    <td style={styles.cellStyle} />
                    <td style={styles.cellStyle} />
                    <td
                      style={{
                        ...styles.cellStyle,
                        textAlign: 'right',
                        fontWeight: '600',
                        fontSize: '20px',
                      }}>
                      ৳
                      {commaNumber(
                        sortDataByPaymentMethod(memTrxData?.details, 'paymentMethod', 'Membership')
                          ?.grandTotal,
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : null}
          </Box>
        ) : null}

        {/* showing other trx data */}
        {selections?.membershipTrx ? (
          <Box p={4}>
            <HStack w="100%" justifyContent="space-between" borderBottom="2px solid black" mb={1}>
              <Text fontSize="16px" fontWeight="bold">
                Other Payments
              </Text>
              <Text fontSize="16px">
                <b>Total Received:</b> ৳
                {commaNumber(
                  sortDataByPaymentMethod(otherTrxData?.details, 'paymentMethod', 'Membership')
                    ?.grandTotal,
                )}
              </Text>
            </HStack>
            {otherTrxData?.details?.length > 0 ? (
              <table
                style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                  <tr>
                    <th style={styles.headerStyle}>No.</th>
                    <th style={styles.headerStyle}>Date</th>
                    <th style={styles.headerStyle}>Trx Id</th>
                    <th style={styles.headerStyle}>Mill Name</th>
                    <th style={styles.headerStyle}>PM</th>
                    <th style={styles.headerStyle}>PM Date</th>
                    <th style={styles.headerStyle}>Period</th>
                    <th style={styles.headerStyle}>Payment For</th>
                    <th style={styles.headerStyle}>Paid Amount (৳)</th>
                  </tr>
                </thead>
                <tbody>
                  {sortDataByPaymentMethod(
                    otherTrxData?.details,
                    'paymentMethod',
                    'Membership',
                  )?.sorted?.map((sortItem) => (
                    <>
                      {sortItem?.data?.length > 0 ? (
                        <>
                          {sortItem?.data?.map((trx, rowIndex) => (
                            <tr key={rowIndex}>
                              <td style={styles.cellStyle}>{rowIndex + 1}</td>
                              <td style={styles.cellStyle}>
                                {renderDate(trx.issueDate, 'numeric')}
                              </td>
                              <td style={styles.cellStyle}>{trx.trxId}</td>
                              <td style={{ ...styles.cellStyle, textAlign: 'left' }}>
                                {trx.millInfo.millName}
                              </td>
                              <td style={styles.cellStyle}>
                                {trx.paymentMethod}{' '}
                                {trx?.paymentMethod !== 'CASH' && ` - ${trx?.chequeNo}`}
                              </td>
                              <td style={styles.cellStyle}>
                                {renderDate(trx.chequeDate, 'numeric')}
                              </td>
                              <td style={styles.cellStyle}>{trx.period}</td>
                              <td style={styles.cellStyle}>{trx.remarks}</td>
                              <td style={{ ...styles.cellStyle, textAlign: 'right' }}>
                                {commaNumber(trx.paidAmount)}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td style={styles.cellStyle} />
                            <td
                              style={{
                                ...styles.cellStyle,
                                textAlign: 'left',
                                fontWeight: '600',
                              }}>
                              Total {` (${sortItem?.data?.[0]?.paymentMethod})` || ''}:
                            </td>
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td
                              style={{
                                ...styles.cellStyle,
                                textAlign: 'right',
                                fontWeight: '600',
                              }}>
                              ৳{commaNumber(sortItem?.total)}
                            </td>
                          </tr>
                          <tr>
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                            <td style={styles.cellStyle} />
                          </tr>
                        </>
                      ) : null}
                    </>
                  ))}
                  <tr>
                    <td style={styles.cellStyle} />
                    <td
                      style={{
                        ...styles.cellStyle,
                        textAlign: 'left',
                        fontWeight: '600',
                        fontSize: '20px',
                      }}>
                      Total
                    </td>
                    <td style={styles.cellStyle} />
                    <td style={styles.cellStyle} />
                    <td style={styles.cellStyle} />
                    <td style={styles.cellStyle} />
                    <td style={styles.cellStyle} />
                    <td style={styles.cellStyle} />
                    <td
                      style={{
                        ...styles.cellStyle,
                        textAlign: 'right',
                        fontWeight: '600',
                        fontSize: '20px',
                      }}>
                      ৳
                      {commaNumber(
                        sortDataByPaymentMethod(
                          otherTrxData?.details,
                          'paymentMethod',
                          'Membership',
                        )?.grandTotal,
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : null}
          </Box>
        ) : null}

        {selections?.paymentSum ? (
          <>
            {selections?.issueCheckedItems?.length > 0 ||
            selections?.salesCheckedItems?.length > 0 ||
            (selections?.membershipTrx && memTrxData?.details?.length > 0) ? (
              <div className="page-break" />
            ) : null}
            <Text fontSize="16px" fontWeight="bold" my={6} mb={2}>
              #Payment Summary:
            </Text>
          </>
        ) : null}
        {selections?.paymentSum ? (
          <Box p={4}>
            <HStack
              w="100%"
              justifyContent="space-between"
              borderBottom="2px solid black"
              mb={1}></HStack>
            <table style={{ margin: 'auto' }}>
              <thead>
                <tr>
                  <th style={styles.headerStyle}>Category</th>
                  <th style={styles.headerStyle}>Service</th>
                  <th style={styles.headerStyle}>Payment Method</th>
                  <th style={styles.headerStyle}>Collected (৳)</th>
                  <th style={styles.headerStyle}>Sub Total (৳)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ ...styles.cellStyle }}>Certificate Sales</td>
                  <td>
                    {data?.map((item) =>
                      ['Cash', 'Cheque', 'EFT', 'MFS'].map((_, idx) => (
                        <tr key={idx}>
                          <td
                            style={{
                              width: '200px',
                              fontSize: '14px',
                              padding: '0px 1px',
                              textAlign: 'center',
                              borderBottom: idx != 3 ? '1px solid transparent' : '1px solid black',
                              color: idx == 1 ? 'black' : 'transparent',
                            }}>
                            {item.title == 'Duty-Exemption (Application Fee)'
                              ? 'Duty-Exemption(Fee)'
                              : item.title}
                          </td>
                        </tr>
                      )),
                    )}
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                    }}>
                    {data?.map((index) =>
                      ['Cash', 'Cheque', 'EFT', 'MFS'].map((item, idx) => (
                        <tr key={index}>
                          <td
                            style={{
                              width: '200px',
                              fontSize: '14px',
                              padding: '0px 5px',
                              textAlign: 'center',
                              borderBottom:
                                index === 4 && idx === 3
                                  ? '1px solid transparent'
                                  : '1px solid black',
                            }}>
                            {item}
                          </td>
                        </tr>
                      )),
                    )}
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                    }}>
                    {data?.map((item, index) =>
                      sortDataByPaymentMethod(
                        item?.saleDetails,
                        'paymentMethod',
                        item.title,
                      )?.sorted?.map((sortItem) => (
                        <tr key={index}>
                          <td
                            style={{
                              width: '200px',
                              fontSize: '14px',
                              padding: '0px 5px',
                              textAlign: 'right',
                              borderBottom: '1px solid black',
                            }}>
                            {commaNumber(sortItem.total)}
                          </td>
                        </tr>
                      )),
                    )}
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                    }}>
                    {data?.map((item, index) =>
                      [1, 2, 3, 4].map((idx) => (
                        <tr key={index}>
                          <td
                            style={{
                              width: '200px',
                              fontSize: '14px',
                              padding: '0px 5px',
                              textAlign: 'right',
                              color: idx == 2 ? 'black' : 'transparent',
                              borderBottom: idx === 4 ? '1px solid black' : '1px solid transparent',
                            }}>
                            {commaNumber(
                              sortDataByPaymentMethod(
                                item?.saleDetails,
                                'paymentMethod',
                                item.title,
                              )?.grandTotal,
                            )}
                          </td>
                        </tr>
                      )),
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={styles.cellStyle}>Membership Subscription</td>
                  <td style={styles.cellStyle}>Membership</td>
                  <td
                    style={{
                      border: '1px solid black',
                    }}>
                    {['Cash', 'Cheque', 'EFT', 'MFS'].map((item, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            width: '200px',
                            fontSize: '14px',
                            padding: '0px 5px',
                            textAlign: 'center',
                            borderBottom: index < 3 ? '1px solid black' : 'none',
                          }}>
                          {item}
                        </td>
                      </tr>
                    ))}
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                    }}>
                    {sortDataByPaymentMethod(
                      memTrxData?.details,
                      'paymentMethod',
                      'Membership',
                    )?.sorted?.map((sortItem, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            width: '200px',
                            fontSize: '14px',
                            padding: '0px 5px',
                            textAlign: 'right',
                            borderBottom: index < 3 ? '1px solid black' : 'none',
                          }}>
                          {commaNumber(sortItem.total)}
                        </td>
                      </tr>
                    ))}
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                    }}>
                    <tr>
                      <td
                        style={{
                          width: '200px',
                          fontSize: '14px',
                          padding: '0px 5px',
                          textAlign: 'right',
                        }}>
                        {commaNumber(
                          sortDataByPaymentMethod(
                            memTrxData?.details,
                            'paymentMethod',
                            'Membership',
                          )?.grandTotal,
                        )}
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td style={styles.cellStyle}>Membership Subscription</td>
                  <td style={styles.cellStyle}>Other</td>
                  <td
                    style={{
                      border: '1px solid black',
                    }}>
                    {['Cash', 'Cheque', 'EFT', 'MFS'].map((item, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            width: '200px',
                            fontSize: '14px',
                            padding: '0px 5px',
                            textAlign: 'center',
                            borderBottom: index < 3 ? '1px solid black' : 'none',
                          }}>
                          {item}
                        </td>
                      </tr>
                    ))}
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                    }}>
                    {sortDataByPaymentMethod(
                      otherTrxData?.details,
                      'paymentMethod',
                      'Membership',
                    )?.sorted?.map((sortItem, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            width: '200px',
                            fontSize: '14px',
                            padding: '0px 5px',
                            textAlign: 'right',
                            borderBottom: index < 3 ? '1px solid black' : 'none',
                          }}>
                          {commaNumber(sortItem.total)}
                        </td>
                      </tr>
                    ))}
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                    }}>
                    <tr>
                      <td
                        style={{
                          width: '200px',
                          fontSize: '14px',
                          padding: '0px 5px',
                          textAlign: 'right',
                        }}>
                        {commaNumber(
                          sortDataByPaymentMethod(
                            otherTrxData?.details,
                            'paymentMethod',
                            'Membership',
                          )?.grandTotal,
                        )}
                      </td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td
                    style={{
                      ...styles.cellStyle,
                      textAlign: 'left',
                      fontWeight: '600',
                      fontSize: '20px',
                    }}>
                    Grand Total
                  </td>
                  <td
                    style={{
                      ...styles.cellStyle,
                      textAlign: 'right',
                      fontWeight: '600',
                      fontSize: '20px',
                    }}>
                    ৳
                    {commaNumber(
                      data
                        ?.map(
                          (item) =>
                            sortDataByPaymentMethod(item?.saleDetails, 'paymentMethod', item.title)
                              ?.grandTotal,
                        )
                        .reduce((accumulator, curr) => accumulator + curr) +
                        sortDataByPaymentMethod(memTrxData?.details, 'paymentMethod', 'Membership')
                          ?.grandTotal +
                        sortDataByPaymentMethod(
                          otherTrxData?.details,
                          'paymentMethod',
                          'Membership',
                        )?.grandTotal,
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        ) : null}

        <style type="text/css" media="print">
          {'\
          @page { size: landscape; }\
      '}
        </style>
      </Box>
    );
  },
);

EodStatement.displayName = 'EodStatement';

export default EodStatement;
