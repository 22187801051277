import axios from './axios';

export const getAllMillUsers = async (millId, data) => {
  try {
    const res = await axios.get(`/mill-users/${millId}`, { params: data });
    if (res.status === 200)
      return {
        data: {
          ...res.data,
          page: data?.page || 1,
          pageSize: data?.pageSize || 10,
        },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const createMillUser = async (data) => {
  try {
    const res = await axios.post(`/mill-users/create/${data?.uid}`, data?.userData);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const patchMillUser = async (data) => {
  try {
    const res = await axios.patch(`/mill-users/${data?.uid}`, data?.userData);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const triggerPasswordReset = async (data) => {
  try {
    const res = await axios.post(`/mill-users/${data?.uid}/${data?.userType}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getMillRepresentative = async (millId) => {
  try {
    const res = await axios.get(`/mill-users/representative/${millId}`);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const createMillRepresentative = async (data) => {
  try {
    const res = await axios.post(`/mill-users/representative/${data?.millUid}`, data?.userData);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const patchMillRepresentative = async (data) => {
  try {
    const res = await axios.patch(`/mill-users/representative/${data?.uid}`, data?.userData);
    if (res.status === 200)
      return {
        data: res.data,
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};
