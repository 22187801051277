import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActionLogDetails } from '../../../api/actionLogs';
import { FETCH_ACTION_LOGS_DETAILS } from '../../../store/actions/actionTypes';
import Loader from '../../snippets/Loader';
import LogDetailsTemplate from './LogDetailsBoxes/LogDetailsTemplate';

const AccordionDetails = ({ logId }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.settings.actionLogDetails);

  const [loader, setLoader] = useState(false);

  const fetchActionLogDetails = async (logId) => {
    dispatch({ type: FETCH_ACTION_LOGS_DETAILS, payload: null });
    setLoader(true);
    const res = await getActionLogDetails(logId);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_ACTION_LOGS_DETAILS, payload: res?.data });
      setLoader(false);
    } else {
      dispatch({ type: FETCH_ACTION_LOGS_DETAILS, payload: { actionLogDetails: null } });
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchActionLogDetails(logId);
  }, [logId]);

  if (loader) {
    return <Loader internal />;
  }

  return (
    <Box p={3}>
      <LogDetailsTemplate logDetails={data?.logDetails} />
      {/* <pre>{JSON.stringify(data, null, 2).replace(/"/g, '')}</pre> */}
    </Box>
  );
};

export default AccordionDetails;
