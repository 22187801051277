import axios from './axios';

export const getAllActionLogs = async (data) => {
  try {
    const res = await axios.get('/action-logs', { params: data });
    if (res.status === 200)
      return {
        data: {
          ...res.data,
          page: data?.page || 1,
          pageSize: data?.pageSize || 10,
          searchKey: data?.searchKey ? data?.searchKey : null,
          startDate: data?.startDate ? data?.startDate : null,
          endDate: data?.endDate ? data?.endDate : null,
          action: data?.action ? data?.action : null,
          section: data?.section ? data?.section : null,
          module: data?.module ? data?.module : null,
          context: data?.context ? data?.context : null,
        },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};

export const getActionLogDetails = async (id) => {
  try {
    const res = await axios.get(`/action-logs/details/${id}`);
    if (res.status === 200)
      return {
        data: {
          ...res.data,
        },
        error: null,
      };
  } catch (error) {
    return { data: null, error };
  }
};
