import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/membershipFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'texYarnProcCap',
    type: 'text',
    isRequired: true,
    label: 'Yarn (kg)',
  },
];

export const yarnProcesCapFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
};

const YarnProcesCap = (props) => {
  return (
    <FormSection
      sectionName="Yarn Processing Capacity"
      sectionColumn={1}
      formFields={fields}
      {...props}
    />
  );
};

export default YarnProcesCap;
