import React from 'react';
import Select from 'react-select';
import { Box, FormControl, FormLabel, HStack, Text } from '@chakra-ui/react';

const colourStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    boxShadow: 'none',
    backgroundColor: 'white',
    border: isFocused ? '1px solid #9280ff' : '',
  }),
  option: (styles, { data, isFocused, isSelected }) => {
    return {
      ...styles,
      color: isSelected ? 'white' : data.color,
      backgroundColor: isSelected ? '#9280ff' : isFocused ? '#e9e4ff' : undefined,
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
};

const SelectField = ({ type, certificates, defaultValue, setCertificate, error }) => {
  return (
    <Box bg="white" maxW="400px" px={3} mt={2.5}>
      <HStack spacing={3} mb={4}>
        <FormControl isInvalid={error}>
          <FormLabel mb={1.5} fontSize="md">
            <b style={{ color: '#E53E3E' }}>* </b> Select{' '}
            {type === 'DUTY' || type == 'NEW_MARKET_EXPANSION' ? 'Invoice' : 'Certificate'}
          </FormLabel>
          <Select
            isClearable
            isSearchable
            styles={colourStyles}
            options={certificates}
            onChange={(opt) => setCertificate(opt)}
            defaultValue={
              type === 'DUTY' || type == 'NEW_MARKET_EXPANSION'
                ? defaultValue?.invoiceId
                : defaultValue?.certId
            }
            getOptionLabel={(option) =>
              type === 'DUTY' || type == 'NEW_MARKET_EXPANSION' ? option.invoice : option.certNum
            }
            getOptionValue={(option) =>
              type === 'DUTY' || type == 'NEW_MARKET_EXPANSION' ? option.invoiceId : option.certId
            }
          />
          <Text color="invalid" fontSize="sm">
            {error}
          </Text>
        </FormControl>
      </HStack>
    </Box>
  );
};

export default SelectField;
