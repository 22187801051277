import React, { useEffect, useState } from 'react';
import { Input, FormControl, Text, Tooltip } from '@chakra-ui/react';

const InputField = ({
  id,
  name,
  type,
  label,
  register,
  isInvalid,
  isDisabled,
  isRequired,
  placeholder,
  errorMessage,
  defaultValue,
}) => {
  const [labelTooltip, setLabelTooltip] = useState(false);
  const [errorTooltip, setErrorTooltip] = useState(false);

  useEffect(() => {
    setLabelTooltip(
      document?.getElementById(name)?.offsetWidth < document?.getElementById(name)?.scrollWidth,
    );
    setErrorTooltip(
      document?.getElementById(`${name}err`)?.offsetWidth <
        document?.getElementById(`${name}err`)?.scrollWidth,
    );
  });

  return (
    <FormControl id={id} isInvalid={isInvalid}>
      <Tooltip label={labelTooltip ? label : ''} area-label="A Tooltip" placement="top-start">
        <Text
          mb={1.5}
          id={name}
          fontSize="sm"
          overflow="hidden"
          whiteSpace="nowrap"
          color="textSecondary"
          textOverflow="ellipsis">
          {isRequired ? <b style={{ color: '#E53E3E' }}>* </b> : null}
          {label}
        </Text>
      </Tooltip>
      <Input
        id={id}
        name={name}
        type={type}
        fontSize="md"
        bg="formInputBG"
        variant="outline"
        borderRadius="sm"
        isDisabled={isDisabled}
        placeholder={placeholder}
        defaultValue={defaultValue}
        focusBorderColor="primary.300"
        h={['40px', '40px', '40px', '45px', '50px']}
        {...register(name)}
      />
      {isDisabled ? (
        <Text fontSize="xs" color="textSecondary">
          *** You do not have permission to edit this!
        </Text>
      ) : null}
      <Tooltip
        label={errorTooltip ? errorMessage : ''}
        area-label="A Tooltip"
        placement="bottom-start">
        <Text
          id={`${name}err`}
          mb={1.5}
          fontSize="xs"
          color="invalid"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis">
          {errorMessage}
        </Text>
      </Tooltip>
    </FormControl>
  );
};

export default InputField;
