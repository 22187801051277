import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/membershipFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'fabricWovenProdCap',
    type: 'text',
    isRequired: true,
    label: 'Woven (mtr)',
  },
  {
    colSpan: 1,
    name: 'fabricKnitProdCap',
    type: 'text',
    isRequired: true,
    label: 'Knit (kg)',
  },
];

export const fabProdCapFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup.string().required().label(fields[1].label),
};

const FabProdCap = (props) => {
  return (
    <FormSection
      sectionName="Fabric Prodcution Capacity"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default FabProdCap;
