import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Box, HStack, Grid, GridItem, Text, Button, useDisclosure } from '@chakra-ui/react';

//common components
import RenderModal from '../../RenderModal';
import InfoTiles from '../../../common/InfoTiles';
import FormButton from '../../../common/FormButton';
import FileUploader from '../../../common/FileUploader';
import DocumentGallery from '../../../common/membershipFormComponents/GallerySection';
import MembershipFormWrapper from '../../../common/membershipFormComponents/MembershipFormWrapper';

//form sections
import DocumentSection, {
  procDocumentInfoFieldsValidation,
} from '../../commonSections/DocumentSection';
import FabricOtherInfoSection, {
  fabricOtherInfoFieldsValidation,
} from '../Verification/FabricOtherInfoSection';
import TextileOtherInfoSection, {
  textileOtherInfoFieldsValidation,
} from '../Verification/TextileOtherInfoSection';
import GeneralInfoSection, {
  gspGenInfoFieldsValidation,
} from '../../commonSections/GeneralInfoSection';
import ProductInformation, {
  productInfoArrayFieldsValidation,
} from '../../commonSections/ProductInformation';
import MillInformationSection, {
  millInfoFieldsValidation,
} from '../NewApplication/MillInformationSection';

//actions & api
import { notify } from '../../../../store/actions/globalActions';
import { patchCertDocs, updateCertificate, deleteCertificate } from '../../../../api/certificates';

//utils & constants
import _ from 'lodash';
import { formatDate } from '../../../../util/formatUtils';
import { PROCESSING_CERT_URL } from '../../../../constants/routerUrl';
import { fileValidation, useYupValidationResolver } from '../../../../util/yupUtils';

const ProcessingRecord = ({ defaultFields, defaultFiles, defaultDocs, millData, refresh }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const fileUploaderRef = useRef();
  const gallerySectionRef = useRef();
  const [modal, setModal] = useState();
  const [formData, setFormData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [docSubmitting, setDocSubmitting] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const millInfo = [
    { header: 'Mill Name', value: millData?.millName },
    { header: 'Membership No', value: millData?.membershipNo },
    { header: 'Membership Type', value: millData?.membershipType },
    { header: 'Contact', value: millData?.phoneNo },
  ];

  const commonValidationSchema = {
    ...millInfoFieldsValidation,
    ...gspGenInfoFieldsValidation,
    ...procDocumentInfoFieldsValidation,
    ...productInfoArrayFieldsValidation,
  };

  const getValidationSchema = () => {
    switch (millData?.product) {
      case 'Fabric':
        return yup.object().shape({
          ...commonValidationSchema,
          ...fabricOtherInfoFieldsValidation,
        });
      case 'Textile':
        return yup.object().shape({
          ...commonValidationSchema,
          ...textileOtherInfoFieldsValidation,
        });
      default:
        return yup.object().shape({
          ...commonValidationSchema,
        });
    }
  };

  const resolver = useYupValidationResolver(getValidationSchema());

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: { ...defaultFields },
  });

  const formatProductDetails = (products) => {
    return products?.map((item) => ({
      ...item,
      invoiceDate: formatDate(item?.invoiceDate),
      deliveryDate: formatDate(item?.deliveryDate),
    }));
  };

  const onSubmit = (data) => {
    const { btbLcDate, dateOfLc, exciseGatePassDate, videCertificateDate, productDetails } =
      defaultFields;
    const cleanDefaultFields = {
      ...defaultFields,
      dateOfLc: new Date(dateOfLc),
      btbLcDate: new Date(btbLcDate),
      exciseGatePassDate: new Date(exciseGatePassDate),
      videCertificateDate: new Date(videCertificateDate),
      productDetails: productDetails.map((item) => ({
        ...item,
        invoiceDate: item?.invoiceDate ? new Date(item?.invoiceDate) : null,
        deliveryDate: item?.deliveryDate ? new Date(item?.deliveryDate) : null,
      })),
    };

    if (_.isEqual(data, cleanDefaultFields) === false) {
      let formdata = {
        ...data,
        dateOfLc: formatDate(data?.dateOfLc),
        btbLcDate: formatDate(data?.btbLcDate),
        issueDate: formatDate(data?.issueDate),
        bank: data?.bank + ',' + data?.bankBranch,
        exciseGatePassDate: formatDate(data?.exciseGatePassDate),
        videCertificateDate: formatDate(data?.videCertificateDate),
        productDetails: JSON.stringify(formatProductDetails(data?.productDetails)),
      };

      let normalizedFormData = _.mapKeys(formdata, (value, key) => {
        if (value) {
          return key;
        }
      });

      setFormData(normalizedFormData);
      setModal('UpdateAlert');
      onOpen();
    } else {
      setModal('NoUpdate');
      onOpen();
    }
  };

  const confirmUpdate = async () => {
    const res = await updateCertificate({
      type: 'processing',
      id: millData?.certId,
      formdata: { ...formData, productType: millData?.product },
    });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      refresh();
    }
  };

  const handleDelete = () => {
    setModal('DELETE');
    onOpen();
  };

  const confirmDelete = () => {
    setModal('PASSWORD');
    onOpen();
  };

  const confirmPassword = async (password) => {
    setIsLoading(true);
    const res = await deleteCertificate({
      userId: user?.userId,
      password: password,
      certType: 'PROCESSING',
      certNum: millData?.certificateNum,
    });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      history.push(PROCESSING_CERT_URL);
    }
    setIsLoading(false);
  };

  /********** Doc Uploader **********/
  const fields = [
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'amendments',
      type: 'file',
      isRequired: true,
      label: 'Amendments (PDF/JPG/JPEG-Max 5mb)',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'proformaInvoice',
      type: 'file',
      isRequired: true,
      label: 'Proforma Invoice (PDF/JPG/JPEG-Max 5mb)',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'commercialInvoice',
      type: 'file',
      isRequired: true,
      label: 'Commercial Invoice (PDF/JPG/JPEG-Max 5mb)',
    },
    {
      fieldType: 'file',
      colSpan: 1,
      name: 'lcBankDocuments',
      type: 'file',
      isRequired: true,
      label: 'L/C Bank Documents (PDF/JPG/JPEG-Max 5mb)',
    },
  ];

  const fieldsValidation = {
    [fields[0].name]: fileValidation.optional,
    [fields[1].name]: fileValidation.optional,
    [fields[2].name]: fileValidation.optional,
    [fields[3].name]: fileValidation.optional,
  };

  const handleFileQuery = async (docData) => {
    let formData = new FormData();

    const isFileUpdated = () => {
      let fileUpdateFlag = false;
      for (const key in docData) {
        if (docData?.[key]?.length > 0) {
          fileUpdateFlag = true;
        }
      }
      return fileUpdateFlag;
    };

    if (isFileUpdated()) {
      for (const key in docData) {
        if (docData?.[key]?.length > 0) {
          formData.append(key, docData[key][0]);
        }
      }

      const queryData = {
        docData: formData,
        certType: 'processing',
        millId: millData?.millId,
        certId: millData?.certId,
        productType: millData?.product,
      };

      const res = await patchCertDocs(queryData);
      if (res.data?.status === 200) {
        gallerySectionRef.current.closeUploader();
        dispatch(
          notify({
            title: 'Success',
            description: res.data.msg,
            status: 'success',
            duration: 5000,
          }),
        );
        refresh();
      }
    } else {
      gallerySectionRef.current.closeUploader();
    }
  };
  /********** Doc Uploader **********/

  return (
    <Box>
      <Box p={4}>
        <InfoTiles sectionName="Mill Information" sectionColumn={4} infoFields={millInfo} />
      </Box>
      <MembershipFormWrapper
        formName={`${
          millData?.product === 'Textile'
            ? 'Yarn'
            : millData?.product === 'Fabric'
            ? 'Fabric'
            : null
        } Product Processing`}>
        <Box bg="white" p={4} borderRadius="4px" border="1px solid #3e1cfd">
          <HStack spacing={0}>
            <Text fontSize="20px" pr={1} fontWeight="bold" color="textSecondary">
              Certificate No:
            </Text>
            <Text fontSize="20px" fontWeight="bold" color="primary.400">
              {millData?.certificateNum}
            </Text>
            <Text fontSize="20px" pl={5} pr={1} fontWeight="bold" color="textSecondary">
              Issue Date:
            </Text>
            <Text fontSize="20px" fontWeight="bold" color="primary.400">
              {millData?.issueDate}
            </Text>
          </HStack>
        </Box>
        <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2}>
          <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
            <GeneralInfoSection
              errors={errors}
              type="PROCESSING"
              control={control}
              register={register}
            />
          </GridItem>
          <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
            <DocumentSection
              errors={errors}
              type="PROCESSING"
              control={control}
              register={register}
            />
          </GridItem>
          <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
            <ProductInformation control={control} register={register} errors={errors} />
          </GridItem>
          <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
            <MillInformationSection control={control} register={register} errors={errors} />
          </GridItem>
          <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
            {millData?.product === 'Textile' ? (
              <TextileOtherInfoSection register={register} control={control} errors={errors} />
            ) : (
              <FabricOtherInfoSection register={register} control={control} errors={errors} />
            )}
          </GridItem>
          <GridItem colSpan={2} bg="white" borderRadius="4px" p={4}>
            <DocumentGallery
              update
              ref={gallerySectionRef}
              isLoading={docSubmitting}
              galleryFields={defaultFiles}
              sectionName="Additional Documents"
              handleDocUpdate={() => fileUploaderRef.current.submit()}
              uploaderComponent={
                <FileUploader
                  fields={fields}
                  ref={fileUploaderRef}
                  documents={defaultDocs}
                  handleQuery={handleFileQuery}
                  submitting={setDocSubmitting}
                  fieldsValidation={fieldsValidation}
                />
              }
            />
          </GridItem>
        </Grid>
        <HStack justifyContent="end" alignItems="center" mt={4}>
          <Button
            bg="red.500"
            minW="200px"
            onClick={handleDelete}
            _hover={{ bg: 'red.400' }}
            _focus={{ boxShadow: 'none' }}
            h={['45px', '50px', '50px', '55px', '55px']}>
            DELETE
          </Button>
          <FormButton
            mt={0}
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onSubmit)();
            }}
            isLoading={isSubmitting}>
            UPDATE
          </FormButton>
        </HStack>
      </MembershipFormWrapper>
      <RenderModal
        modal={modal}
        isOpen={isOpen}
        onClose={onClose}
        isSubmitting={isLoading}
        confirmDelete={confirmDelete}
        confirmUpdate={confirmUpdate}
        confirmPassword={confirmPassword}
      />
    </Box>
  );
};

export default ProcessingRecord;
