import React from 'react';
import * as yup from 'yup';
import FormSection from '../../common/hookFormComponents/FormSection';

const fieldsProduction = [
  {
    colSpan: 1,
    fieldType: 'radio',
    name: 'productType',
    label: 'Choose a product type',
    choices: [
      { name: 'Yarn', value: 'YARN' },
      { name: 'Fabric', value: 'FABRIC' },
    ],
  },
];

const fieldsProcessing = [
  {
    colSpan: 1,
    fieldType: 'radio',
    name: 'productType',
    label: 'Choose a product type',
    choices: [
      { name: 'Yarn', value: 'TEXTILE' },
      { name: 'Fabric', value: 'FABRIC' },
    ],
  },
];

export const productionProductTypeFieldsValidation = {
  [fieldsProduction[0].name]: yup.string().required().label('Product Type'),
};

export const processingProductTypeFieldsValidation = {
  [fieldsProcessing[0].name]: yup.string().required().label('Product Type'),
};

const ProductTypeSection = (props) => {
  return (
    <FormSection
      sectionName="Product"
      isRequired
      sectionColumn={3}
      formFields={
        props.type === 'PRODUCTION'
          ? fieldsProduction
          : props.type === 'PROCESSING'
          ? fieldsProcessing
          : []
      }
      {...props}
    />
  );
};

export default ProductTypeSection;
