import React, { useEffect, useState } from 'react';
import { Box, Button, Input, Text, VStack } from '@chakra-ui/react';

const PasswordForm = ({ passBtnLoading, executeAction }) => {
  const [password, setPassword] = useState('');
  const [inputType, setInputType] = useState('text');

  useEffect(() => {
    if (password?.length > 0) {
      setInputType('password');
    } else {
      setInputType('text');
    }
  }, [password]);

  return (
    <Box>
      <Box border="1px solid #E53E3E" borderRadius="md" borderStyle="dashed" p={4}>
        <Text fontSize="sm" color="invalid" mb={1.5}>
          * Enter your password to confirm you want to delete this certificate
        </Text>
        <Input
          h="50px"
          isRequired
          bg="secondary"
          type={inputType}
          variant="outline"
          borderRadius="2px"
          autoComplete="off"
          focusBorderColor="primary.300"
          onChange={(e) => setPassword(e.target.value)}
        />
        <VStack mt={4}>
          <Button
            bg="red.500"
            _hover={{ bg: 'red.400' }}
            isLoading={passBtnLoading}
            isDisabled={password?.length < 6}
            onClick={() => {
              executeAction(password);
            }}>
            CONFIRM
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default PasswordForm;
