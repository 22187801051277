import React, { forwardRef, useImperativeHandle } from 'react';
import { downloadZip } from 'client-zip';
import { RiFolderZipLine, RiUploadCloud2Line } from 'react-icons/ri';
import { AiFillCloseCircle, AiFillCheckCircle } from 'react-icons/ai';
import {
  Text,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  HStack,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import DialogueBox from '../DialogueBox';

const GallerySection = forwardRef(
  (
    {
      update,
      download,
      isLoading,
      sectionName,
      zipFileName,
      galleryFields,
      handleDocUpdate,
      uploaderComponent,
    },
    ref,
  ) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    useImperativeHandle(ref, () => ({
      closeUploader() {
        onClose();
      },
    }));

    const downloadZipFile = async () => {
      let fileLinksArray = [];

      galleryFields?.forEach((file) => {
        if (file?.link) fileLinksArray.push(file.link);
      });

      const responses = await Promise.all(fileLinksArray);

      // async function* downloadGenerator(urls) {
      //   for (let url of urls) yield await fetch(url);
      // }

      // get the ZIP stream in a Blob
      // const blob = await downloadZip(downloadGenerator(fileLinksArray)).blob();
      const blob = await downloadZip(responses).blob();

      // make and click a temporary link to download the Blob
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${zipFileName || 'BTMA'}.zip`;
      link.click();
      link.remove();

      // in real life, don't forget to revoke your Blob URLs if you use them
    };

    return (
      <Box p={4} backgroundColor="white" borderRadius="4px">
        <HStack w="100%" alignItems="flex-end" justifyContent="space-between" mb={5}>
          <Text fontSize="20px" fontWeight="bold">
            {sectionName}
          </Text>
          <HStack minW="50%" alignItems="flex-end" justifyContent="end">
            {update ? (
              <Button
                onClick={onOpen}
                variant="subtle"
                maxW="fit-content"
                rightIcon={<Icon as={RiUploadCloud2Line} w={6} h={6} />}>
                Update
              </Button>
            ) : null}
            {download ? (
              <Button
                variant="subtle"
                maxW="fit-content"
                onClick={downloadZipFile}
                rightIcon={<Icon as={RiFolderZipLine} w={6} h={6} />}>
                Download
              </Button>
            ) : null}
          </HStack>
        </HStack>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>File Name</Th>
              <Th textAlign="center">Available?</Th>
              <Th>Link</Th>
            </Tr>
          </Thead>
          <Tbody>
            {galleryFields?.map((field, index) => (
              <Tr key={index}>
                <Td>{field?.header}</Td>
                <Td textAlign="center">
                  <Icon
                    as={field?.link ? AiFillCheckCircle : AiFillCloseCircle}
                    w={5}
                    h={5}
                    color={field?.link ? 'green.700' : 'invalid'}
                  />
                </Td>
                <Td color="primary.700">
                  {field?.link ? (
                    <a href={field?.link} target="_blank" rel="noreferrer" draggable>
                      click to open
                    </a>
                  ) : null}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <DialogueBox
          variant="lg"
          close={onClose}
          onActionKeepOpen
          mountControl={isOpen}
          actionButtonText="save"
          cancelButtonText="cancel"
          alertTitle="Update Documents"
          actionButtonLoading={isLoading}
          performAction={handleDocUpdate}>
          {uploaderComponent}
        </DialogueBox>
      </Box>
    );
  },
);

GallerySection.displayName = 'GallerySection';

export default GallerySection;
