import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 2,
    name: 'msAddress',
    type: 'text',
    isRequired: true,
    label: 'Factory Location',
  },
  {
    colSpan: 1,
    name: 'msTotalArea',
    type: 'text',
    placeholder: 'Area in katha',
    label: 'Total Factory Area',
  },
  {
    colSpan: 1,
    name: 'msTotalBuilding',
    type: 'text',
    placeholder: 'Area in sq. ft',
    label: 'Total Factory Buliding',
  },
  {
    colSpan: 1,
    name: 'msEmail',
    type: 'email',
    label: 'Email',
  },
  {
    colSpan: 1,
    name: 'msPhoneNo',
    type: 'text',
    label: 'Phone',
  },
  {
    colSpan: 1,
    name: 'msHoldingNo',
    type: 'text',
    label: 'Holding No.',
  },
  {
    colSpan: 1,
    name: 'msPoliceStation',
    type: 'text',
    label: 'Police Station',
  },

  {
    colSpan: 1,
    name: 'msDistrict',
    type: 'text',
    label: 'District',
  },
];

export const millSiteFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup.string().label(fields[1].label),
  [fields[2].name]: yup.string().label(fields[2].label),
  [fields[3].name]: yup.string().email().label(fields[3].label),
  [fields[4].name]: yup.string().label(fields[4].label),
  [fields[5].name]: yup.string().label(fields[5].label),
  [fields[6].name]: yup.string().label(fields[6].label),
  [fields[7].name]: yup.string().label(fields[7].label),
};

const MillSiteSection = (props) => {
  return <FormSection sectionName="Mill Site" sectionColumn={4} formFields={fields} {...props} />;
};

export default MillSiteSection;
