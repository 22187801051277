import React, { useEffect, useState } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import DialogueBox from '../../common/DialogueBox';
import DataTable from '../../common/dataTable/DataTable';
import UpdateRequestDetails from './UpdateRequestDetails';

import { notify } from '../../../store/actions/globalActions';
import { FETCH_INFO_UPDATE_REQUESTS } from '../../../store/actions/actionTypes';
import { getInfoUpdateRequests, resolveUpdateRequest } from '../../../api/membership';
import { BADGE_STATE_COLORS, LOGS_TABLE_DISPLAY_SIZE } from '../../../constants/dataTableConstants';

const UpdateRequests = () => {
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalComponent, setModalComponent] = useState();
  const data = useSelector((state) => state.membership.updateRequests);

  useEffect(() => {
    document.title = 'BTMA - Membership';
  }, []);

  const columns = [
    {
      isDate: true,
      header: 'Date',
      accessor: 'createdAt',
    },
    { header: 'Request Id', accessor: 'requestId' },
    {
      header: 'Member ID',
      accessor: 'membershipNo',
    },
    {
      header: 'Mill Name',
      accessor: 'millName',
    },
    {
      header: 'Email',
      accessor: 'millEmail',
    },
    {
      header: 'Contact',
      accessor: 'millPhoneNo',
    },
    {
      header: 'Status',
      accessor: 'status',
      isBadge: true,
      colorScheme: BADGE_STATE_COLORS,
    },
    {
      header: 'Action',
      accessor: 'status',
      isButton: true,
      actionScheme: [
        { group: 'PENDING', variant: 'read', action: 'VIEW' },
        { group: 'APPROVED', variant: 'read', action: 'VIEW' },
        { group: 'REJECTED', variant: 'read', action: 'VIEW' },
      ],
    },
  ];

  const requestStatus = {
    name: 'Request Status',
    choices: [
      { name: 'PENDING', value: 'PENDING' },
      { name: 'APPROVED', value: 'APPROVED' },
      { name: 'REJECTED', value: 'REJECTED' },
    ],
  };

  const handleView = async ({ rowObject }) => {
    setRowData(rowObject);
    setModalComponent(
      <UpdateRequestDetails
        data={rowObject}
        closeModal={onClose}
        refresh={() => handleSearch({ page: 1, pageSize: 10 })}
      />,
    );
    onOpen();
  };

  const actionObject = {
    VIEW: handleView,
  };

  const handlePagination = async ({ page, pageSize, searchKey, selected }) => {
    dispatch({ type: FETCH_INFO_UPDATE_REQUESTS, payload: null });
    const params = {
      page,
      pageSize,
      searchKey,
      status: selected?.['Request Status'],
    };
    const res = await getInfoUpdateRequests(params);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_INFO_UPDATE_REQUESTS, payload: res.data });
    } else {
      dispatch({ type: FETCH_INFO_UPDATE_REQUESTS, payload: { changeRequests: [] } });
    }
  };

  const handleSearch = async ({ searchKey, selected }) => {
    dispatch({ type: FETCH_INFO_UPDATE_REQUESTS, payload: null });
    const params = {
      searchKey,
      status: selected?.['Request Status'],
    };
    const res = await getInfoUpdateRequests(params);
    if (res.data?.status === 200) {
      dispatch({ type: FETCH_INFO_UPDATE_REQUESTS, payload: res.data });
    } else {
      dispatch({ type: FETCH_INFO_UPDATE_REQUESTS, payload: { changeRequests: [] } });
    }
  };

  const handleResolve = async () => {
    const res = await resolveUpdateRequest({ requestId: rowData?.id, status: 'APPROVED' });
    if (res.data?.status === 200) {
      handleSearch({ page: 1, pageSize: 10 });
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
    }
  };

  return (
    <Box p={4}>
      <DataTable
        topPanel
        keySearch
        isPaginated
        columns={columns}
        actions={actionObject}
        search={handleSearch}
        currentPage={data?.page}
        paginate={handlePagination}
        data={data?.changeRequests}
        entries={data?.totalEntries}
        selectOptions={[requestStatus]}
        currentPageSize={data?.pageSize}
        pageSizes={LOGS_TABLE_DISPLAY_SIZE}
        caption="Information Update Requests"
      />
      <DialogueBox
        variant="lg"
        close={onClose}
        mountControl={isOpen}
        cancelButtonText="Close"
        alertTitle="Request Details"
        performAction={handleResolve}
        actionButtonText="Mark As Resolved"
        actionDisabled={rowData?.status === 'APPROVED'}>
        {modalComponent}
      </DialogueBox>
    </Box>
  );
};

export default UpdateRequests;
