import { Box, Grid, GridItem, HStack, Text, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import InfoTiles from '../../../common/InfoTiles';
import MembershipFormWrapper from '../../../common/membershipFormComponents/MembershipFormWrapper';
import ApplicationInfoSectionForMarketExpansion, {
  applicationInfoFieldsForMarketExpansionValidation,
} from '../NewApplication/ApplicationInfoSectionForMarketExpansion';
import GeneralInfoSectionForMarketExpansion, {
  generalInfoFieldsForMarketExpansionValidation,
} from '../NewApplication/GeneralInfoSectionForMarketExpansion';
import RequiredInfoSectionForMarketExpansion, {
  requiredInfoFieldsForMarketExpansionValidation,
} from '../NewApplication/RequiredInfoSectionForMarketExpansion';
import { useForm } from 'react-hook-form';
import { useYupValidationResolver } from '../../../../util/yupUtils';
import { formatDate } from '../../../../util/formatUtils';
import * as yup from 'yup';
//utils & constants
import _ from 'lodash';
import DialogueBox from '../../../common/DialogueBox';
import FormButton from '../../../common/FormButton';
import { updateNewMarketExpansionCertificate } from '../../../../api/certificates';
import { useDispatch } from 'react-redux';
import { notify } from '../../../../store/actions/globalActions';

const Record = ({ refresh, millData, defaultFields }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState();
  const [modal, setModal] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const millInfo = [
    { header: 'Mill Name', value: millData?.millName },
    { header: 'Membership No', value: millData?.membershipNo },
    { header: 'Membership Type', value: millData?.membershipType },
    { header: 'Contact', value: millData?.phoneNo },
  ];

  const resolver = useYupValidationResolver(
    yup.object().shape({
      ...generalInfoFieldsForMarketExpansionValidation,
      ...requiredInfoFieldsForMarketExpansionValidation,
      ...applicationInfoFieldsForMarketExpansionValidation,
    }),
  );

  const {
    control,
    register,
    //setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: { ...defaultFields },
  });

  const onSubmit = (data) => {
    const { issueDate, salesContactDate, invoiceDate } = defaultFields;
    const cleanDefaultFields = {
      ...defaultFields,
      // expDate: new Date(expDate),
      // repatriatedExportValueCertificateDate: new Date(repatriatedExportValueCertificateDate),
      issueDate: new Date(issueDate),
      salesContactDate: new Date(salesContactDate),
      invoiceDate: new Date(invoiceDate),
    };

    if (_.isEqual(data, cleanDefaultFields) === false) {
      let formdata = {
        ...data,
        millId: millData?.millId,
        invoiceId: data?.id,
        //expDate: formatDate(data?.expDate),
        certificateNumber: data?.certificateNumber,
        // repatriatedExportValueCertificateDate: formatDate(
        //   data?.repatriatedExportValueCertificateDate,
        // ),
        issueDate: formatDate(data?.issueDate),
        salesContactDate: formatDate(data?.salesContactDate),
        invoiceDate: formatDate(data?.invoiceDate),
      };

      let normalizedFormData = _.mapKeys(formdata, (value, key) => {
        if (value) {
          return key;
        }
      });
      setFormData(normalizedFormData);
      setModal('UpdateAlert');
      onOpen();
    } else {
      setModal('NoUpdate');
      onOpen();
    }
  };

  const confirmUpdate = async () => {
    const res = await updateNewMarketExpansionCertificate({
      type: 'MARKET_EXPANSION',
      id: millData?.certId,
      formdata: formData,
    });
    if (res.data?.status === 200) {
      dispatch(
        notify({
          title: 'Success',
          description: res.data.msg,
          status: 'success',
          duration: 5000,
        }),
      );
      refresh();
    }

    refresh();
  };

  const renderModal = () => {
    switch (modal) {
      case 'UpdateAlert':
        return (
          <DialogueBox
            close={onClose}
            alertTitle="Confirm"
            mountControl={isOpen}
            performAction={confirmUpdate}
            alertMessage="Are you sure you want to submit these information and update this application?"
          />
        );
      case 'NoUpdate':
        return (
          <DialogueBox
            noAction
            close={onClose}
            mountControl={isOpen}
            alertTitle="No Update"
            cancelButtonText="Close"
            alertMessage="There is nothing to update, you did not change any information!"
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      <Box p={4}>
        <InfoTiles sectionName="Mill Information" sectionColumn={4} infoFields={millInfo} />
      </Box>
      <MembershipFormWrapper formName="New Market Expansion Certificate">
        <Box bg="white" p={4} borderRadius="4px" border="1px solid #3e1cfd">
          <HStack spacing={0}>
            <Text fontSize="20px" pr={1} fontWeight="bold" color="textSecondary">
              Certificate No:
            </Text>
            <Text fontSize="20px" color="primary.400">
              {millData?.certificateNum}
            </Text>
            <Text fontSize="20px" pr={1} pl={5} fontWeight="bold" color="textSecondary">
              Issue Date:
            </Text>
            <Text fontSize="20px" color="primary.400">
              {millData?.issueDate}
            </Text>
          </HStack>
        </Box>

        <Grid templateColumns="repeat(2, 1fr)" gap={3}>
          <GridItem colSpan={1} bg="white" borderRadius="4px" p={4}>
            <Box borderRadius="4px" w="100%">
              <ApplicationInfoSectionForMarketExpansion
                errors={errors}
                control={control}
                sectionColumn={2}
                register={register}
                isCertificateDisabled={true}
              />
            </Box>
            <GeneralInfoSectionForMarketExpansion
              control={control}
              register={register}
              errors={errors}
            />
          </GridItem>
          <GridItem colSpan={1} bg="white" borderRadius="4px" p={4}>
            <RequiredInfoSectionForMarketExpansion
              errors={errors}
              control={control}
              register={register}
              sectionName="Required Information"
            />
          </GridItem>
        </Grid>
        <HStack justifyContent="end" alignItems="center" mt={4}>
          <FormButton
            mt={0}
            isLoading={isSubmitting}
            onClick={(event) => {
              event.preventDefault();
              handleSubmit(onSubmit)();
            }}>
            SUBMIT
          </FormButton>
        </HStack>
      </MembershipFormWrapper>
      {renderModal()}
    </Box>
  );
};

export default Record;
