import React from 'react';
import { Button } from '@chakra-ui/react';

const FormButton = (props) => {
  return (
    <Button mt="4" variant={props.colorVariant ? props.colorVariant : 'formNext'} {...props}>
      {props.children}
    </Button>
  );
};

export default FormButton;
