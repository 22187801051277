export const ACCOUNTS_MEM_COMP = [
  'AccountsMembership',
  'NewRequestAcc',
  'RenewalRequestAcc',
  'MillInformationAcc',
];

export const ACCOUNTS_CERT_COMP = ['AccountsCertificate', 'CertRequestAcc'];

export const SETTINGS_COMP = ['AdminUsers', 'Pricing'];
