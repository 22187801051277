import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../../common/membershipFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    name: 'texYarnProcCap',
    type: 'text',
    isRequired: true,
    label: 'Processing Capacity(kg)',
  },
  {
    colSpan: 1,
    name: 'texYarnExpoCap',
    type: 'text',
    label: 'Exportable Capacity(kg)',
  },
];

export const textileYarnCapFieldsValidation = {
  [fields[0].name]: yup.string().required().label(fields[0].label),
  [fields[1].name]: yup.string().label(fields[1].label),
};

const TextileYarnCap = (props) => {
  return (
    <FormSection sectionName="Yarn Capacity" sectionColumn={2} formFields={fields} {...props} />
  );
};

export default TextileYarnCap;
