import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { CgPrinter } from 'react-icons/cg';
import SelectFilters from './SelectFilters';
import { AiOutlineFileSearch } from 'react-icons/ai';
import OutstandingDuesReport from './OutstandingDuesReport';
import { renderDate } from '../../../../../util/formatUtils';
import {
  // patchSubCost,
  getMillArrearReport,
  getSubscriptionCostReport,
} from '../../../../../api/reports';
import FileExporter from '../../../../reports/commonSections/FileExporter';
import {
  Box,
  Text,
  Divider,
  Spinner,
  HStack,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import SubscriptionCostReport from './SubscriptionCostReport';

const MillArrearsSection = () => {
  const subsCostReportRef = useRef();
  const millArrearReportRef = useRef();
  const [category, setCategory] = useState(0);
  const [loader, setLoader] = useState(false);
  const [conResults, setConResults] = useState();
  const [subsResults, setSubsResults] = useState();
  const [arrearResults, setArrearResults] = useState();
  const [membershipType, setMembershipType] = useState('undefined');

  const handleSubmit = async (report) => {
    setLoader(true);
    if (report === 'ARREAR') {
      const res = await getMillArrearReport({ category, membershipType });
      if (res.data?.status === 200) {
        setSubsResults();
        setArrearResults(res.data?.arrears);
        setLoader(false);
      }
    } else {
      const res = await getSubscriptionCostReport({ category, membershipType });
      if (res.data?.status === 200) {
        setArrearResults();
        setSubsResults(res.data?.result);
        setLoader(false);
      }
    }
    setLoader(false);
  };

  useEffect(() => {
    let alldata = arrearResults;

    let generalYarn = alldata?.filter(
      (item) => item.type === 'GENERAL' && item.category === 'Yarn Manufacture',
    );
    let generalFabric = alldata?.filter(
      (item) => item.type === 'GENERAL' && item.category === 'Fabric Manufacture',
    );
    let generalTextile = alldata?.filter(
      (item) => item.type === 'GENERAL' && item.category === 'Textile Product Processor',
    );
    let associateYarn = alldata?.filter(
      (item) => item.type === 'ASSOCIATE' && item.category === 'Yarn Manufacture',
    );
    let associateFabric = alldata?.filter(
      (item) => item.type === 'ASSOCIATE' && item.category === 'Fabric Manufacture',
    );
    let associateTextile = alldata?.filter(
      (item) => item.type === 'ASSOCIATE' && item.category === 'Textile Product Processor',
    );

    setConResults({
      generalYarn,
      generalFabric,
      generalTextile,
      associateYarn,
      associateTextile,
      associateFabric,
    });
  }, [arrearResults]);

  useEffect(() => {
    let alldata = subsResults;

    let generalYarn = alldata?.filter(
      (item) => item.type === 'GENERAL' && item.category === 'Yarn Manufacture',
    );
    let generalFabric = alldata?.filter(
      (item) => item.type === 'GENERAL' && item.category === 'Fabric Manufacture',
    );
    let generalTextile = alldata?.filter(
      (item) => item.type === 'GENERAL' && item.category === 'Textile Product Processor',
    );
    let associateYarn = alldata?.filter(
      (item) => item.type === 'ASSOCIATE' && item.category === 'Yarn Manufacture',
    );
    let associateFabric = alldata?.filter(
      (item) => item.type === 'ASSOCIATE' && item.category === 'Fabric Manufacture',
    );
    let associateTextile = alldata?.filter(
      (item) => item.type === 'ASSOCIATE' && item.category === 'Textile Product Processor',
    );

    setConResults({
      generalYarn,
      generalFabric,
      generalTextile,
      associateYarn,
      associateTextile,
      associateFabric,
    });
  }, [subsResults]);

  const getExportableResult = (report) => {
    let resArray;
    if (report === 'ARREAR') {
      resArray = arrearResults?.map((item) => {
        const { mill, type, category, bills } = item;
        const { forYear, arrear, segment, total } = bills;

        return {
          ['Mill Name']: mill,
          ['Membership Type']: type,
          ['Category']: category,
          ['Arrear']: arrear,
          ['Arrear Upto']: (forYear || new Date().getFullYear()) - 1,
          ['JAN-JUN']: segment?.['JAN-JUN'],
          ['JUL-DEC']: segment?.['JUL-DEC'],
          ['JAN-DEC']: segment?.['JAN-DEC'],
          ['For Year']: forYear || new Date().getFullYear(),
          // [`JUL ${new Date().getFullYear()}-JUN ${new Date().getFullYear() + 1}`]:
          //   segment?.['JUL-JUN'],
          ['Total']: total,
        };
      });
    } else {
      resArray = subsResults?.map((item) => {
        // eslint-disable-next-line no-unused-vars
        const { millId, mill, category, type, subCost } = item;
        return {
          ['Mill Name']: mill,
          ['Membership Type']: type,
          ['Category']: category,
          ['Subscription Cost']: subCost,
        };
      });
    }
    return resArray;
  };

  // const handlePatchScript = async () => {
  //   const res = await patchSubCost();
  // };

  return (
    <Box mt={20}>
      <Text fontSize="2xl" fontWeight="bold" borderBottom="1px solid #E9EDF4" mb={4}>
        Subscription Cost & Outstanding Dues of Members
      </Text>
      <Box bg="white" p={4} mb={4} borderRadius="4px" w="fit-content">
        <HStack>
          <SelectFilters
            label="Mill Category"
            choices={[
              { name: 'select an option', value: 0 },
              { name: 'Yarn Manufacturer', value: 1 },
              { name: 'Fabrics Manufacturer', value: 2 },
              { name: 'Textile Product Processor', value: 3 },
            ]}
            setValue={setCategory}
          />
          <SelectFilters
            label="Membership Type"
            choices={[
              { name: 'select an option', value: 'undefined' },
              { name: 'General', value: 'GENERAL' },
              { name: 'Associate', value: 'ASSOCIATE' },
            ]}
            setValue={setMembershipType}
          />
        </HStack>
        <HStack w="100%" mt={2} spacing={4}>
          <Menu>
            {/* <Button onClick={handlePatchScript}>RUN PATCH SCRIPT</Button> */}
            <MenuButton as={Button}>GENERATE REPORT</MenuButton>
            <MenuList p={1} borderRadius="2px">
              <MenuItem onClick={() => handleSubmit('ARREAR')}>Outstanding Dues</MenuItem>
              <MenuItem onClick={() => handleSubmit('SUBS')}>Subscription Costs</MenuItem>
            </MenuList>
          </Menu>

          {loader ? (
            <HStack h="45px" bg="secondary" flex={1} justifyContent="center" borderRadius="4px">
              <Spinner thickness="2px" emptyColor="primary.50" color="primary.700" size="md" />
              <Text color="primary.700">Generating Report...</Text>
            </HStack>
          ) : null}
        </HStack>
      </Box>

      {arrearResults ? (
        <>
          <HStack w="100%" justifyContent="space-between">
            <Text fontSize="lg" fontWeight="bold" mb={-4}>
              Report preview
            </Text>
            <HStack>
              <FileExporter
                title="Outstanding-Dues-of-Member-Mills"
                data={getExportableResult('ARREAR')}
              />
              <ReactToPrint
                documentTitle={`Outstanding-Dues-of-Member-Mills-${renderDate(new Date())}`}
                trigger={() => (
                  <Button
                    isDisabled={arrearResults?.length < 1}
                    rightIcon={<Icon as={CgPrinter} w={5} h={5} />}>
                    PRINT
                  </Button>
                )}
                content={() => millArrearReportRef.current}
              />
            </HStack>
          </HStack>
          <Divider mt={1} />
        </>
      ) : subsResults ? (
        <>
          <HStack w="100%" justifyContent="space-between">
            <Text fontSize="lg" fontWeight="bold" mb={-4}>
              Report preview
            </Text>
            <HStack>
              <FileExporter
                title="Subscription-Costs-of-Member-Mills"
                data={getExportableResult('SUBS')}
              />
              <ReactToPrint
                documentTitle={`Subscription-Costs-of-Member-Mills-${renderDate(new Date())}`}
                trigger={() => (
                  <Button
                    isDisabled={subsResults?.length < 1}
                    rightIcon={<Icon as={CgPrinter} w={5} h={5} />}>
                    PRINT
                  </Button>
                )}
                content={() => subsCostReportRef.current}
              />
            </HStack>
          </HStack>
          <Divider mt={1} />
        </>
      ) : null}

      {arrearResults ? (
        arrearResults?.length > 0 ? (
          <Box
            maxH="60vh"
            bg="white"
            overflow="auto"
            borderRadius="4px"
            boxShadow="inset 0px 0px 5px rgba(0,0,0,0.2)">
            <OutstandingDuesReport ref={millArrearReportRef} data={conResults} />
          </Box>
        ) : (
          <Box
            bg="white"
            overflowX="auto"
            borderRadius="4px"
            boxShadow="inset 0px 0px 5px rgba(0,0,0,0.2)">
            <Text color="textSecondary" fontSize="2xl" w="100%" textAlign="center" py={50}>
              <Icon as={AiOutlineFileSearch} w={10} h={10} /> No entries found!
            </Text>
          </Box>
        )
      ) : subsResults ? (
        subsResults?.length > 0 ? (
          <Box
            maxH="60vh"
            bg="white"
            overflow="auto"
            borderRadius="4px"
            boxShadow="inset 0px 0px 5px rgba(0,0,0,0.2)">
            <SubscriptionCostReport ref={subsCostReportRef} data={conResults} />
          </Box>
        ) : (
          <Box
            bg="white"
            overflowX="auto"
            borderRadius="4px"
            boxShadow="inset 0px 0px 5px rgba(0,0,0,0.2)">
            <Text color="textSecondary" fontSize="2xl" w="100%" textAlign="center" py={50}>
              <Icon as={AiOutlineFileSearch} w={10} h={10} /> No entries found!
            </Text>
          </Box>
        )
      ) : null}
    </Box>
  );
};

export default MillArrearsSection;
