import React from 'react';
import * as yup from 'yup';
import { dateValidation } from '../../../../util/yupUtils';
import FormSection from '../../../common/hookFormComponents/FormSection';

const fields = [
  {
    colSpan: 1,
    fieldType: 'date',
    name: 'issueDate',
    label: 'Issue Date',
    isRequired: true,
  },
  {
    colSpan: 1,
    type: 'text',
    name: 'certificateNum',
    label: 'Certificate No',
    isRequired: true,
  },
];

export const dutyIssueDateValidation = {
  [fields[0].name]: dateValidation.required().label(fields[0].label),
  [fields[1].name]: yup
    .number()
    .typeError(`${fields[1].label} must be a number`)
    .required()
    .label(fields[1].label),
};

const DutyIssueDateSection = (props) => {
  return <FormSection sectionName="Issue Date" sectionColumn={2} formFields={fields} {...props} />;
};

export default DutyIssueDateSection;
