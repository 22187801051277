import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Stack, Button, Input } from '@chakra-ui/react';

const PasswordForm = ({ executeAction, clearSelection }) => {
  const [password, setPassword] = useState('');
  const [inputType, setInputType] = useState('text');
  const list = useSelector((state) => state.membership.revokeList);

  useEffect(() => {
    if (password?.length > 0) {
      setInputType('password');
    } else {
      setInputType('text');
    }
  }, [password]);

  return (
    <Box
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
        executeAction(password, list);
        clearSelection([]);
      }}>
      <Stack flexDir="column" alignItems="flex-end" mt={2}>
        <Input
          mt={2}
          h="50px"
          isRequired
          bg="secondary"
          type={inputType}
          variant="outline"
          borderRadius="2px"
          autoComplete="off"
          focusBorderColor="primary.300"
          onChange={(e) => setPassword(e.target.value)}
        />

        <Button
          bg="red.500"
          type="submit"
          _hover={{ bg: 'red.400' }}
          isDisabled={password?.length < 6}>
          REVOKE
        </Button>
      </Stack>
    </Box>
  );
};

export default PasswordForm;
