import React from 'react';
import { Box, FormControl, FormLabel, HStack, Select } from '@chakra-ui/react';

const MembershipYearSelection = ({ printPeriod, setPrintYear }) => {
  const getYearList = (flag) => {
    let years = [];

    if (flag === 'A') {
      let currYear = parseInt(new Date().getFullYear());
      for (let i = -10; i < 10; i++) {
        years.push({ name: `${currYear + i}`, value: `${currYear + i}` });
      }
    } else {
      let currYear = parseInt(new Date().getFullYear());
      for (let i = -10; i < 10; i++) {
        years.push({
          name: `${currYear + i}-${currYear + i + 1}`,
          value: `${currYear + i}-${currYear + i + 1}`,
        });
      }
    }

    return years;
  };

  const choices = printPeriod === 'July-June' ? getYearList('B') : getYearList('A');

  return (
    <Box bg="white" px={3} mt={2.5}>
      <HStack spacing={3} mb={4}>
        <FormControl>
          <FormLabel mb={1.5} fontSize="md">
            Year?
          </FormLabel>
          <Select
            h="50px"
            width="300px"
            bg="formInputBG"
            borderRadius="2px"
            colorScheme="primary"
            placeholder={'Select Year'}
            focusBorderColor="primary.300"
            onChange={(e) => setPrintYear(e.target.value)}>
            {choices?.map((item) => (
              <option key={item?.value} value={item?.value}>
                {item?.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </HStack>
    </Box>
  );
};

export default MembershipYearSelection;
