import React from 'react';
import * as yup from 'yup';
import FormSection from '../../../../../common/hookFormComponents/FormSection';
import { mobileNoValidation } from '../../../../../../util/yupUtils';

const fields = [
  {
    colSpan: 1,
    name: 'mpName',
    type: 'text',
    label: 'Represented By',
  },
  {
    colSpan: 1,
    name: 'mpMobileNo',
    type: 'text',
    label: 'Mobile Number',
  },
  {
    colSpan: 1,
    name: 'mdName',
    type: 'text',
    label: 'Name of Managing Director',
  },
  {
    colSpan: 1,
    name: 'mdOfficeTelephone',
    type: 'text',
    label: 'Managing Director Phone Number',
  },
  {
    colSpan: 1,
    name: 'chairmanName',
    type: 'text',
    label: 'Name of Chairman',
  },
  {
    colSpan: 1,
    name: 'chairmanOfficeTelephone',
    type: 'text',
    label: 'Chairman Phone Number',
  },
];

export const conPersonInfoFieldsValidation = {
  [fields[0].name]: yup.string().label(fields[0].label),
  [fields[1].name]: mobileNoValidation.label(fields[1].label),
  [fields[2].name]: yup.string().label(fields[2].label),
  [fields[3].name]: mobileNoValidation.label(fields[3].label),
  [fields[4].name]: yup.string().label(fields[4].label),
  [fields[5].name]: mobileNoValidation.label(fields[5].label),
};

const ContPersonInfo = (props) => {
  return (
    <FormSection
      sectionName="Contact Person Information"
      sectionColumn={2}
      formFields={fields}
      {...props}
    />
  );
};

export default ContPersonInfo;
