import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { renderDate } from '../../../../../util/formatUtils';

const textStyles = {
  width: '100%',
  fontSize: '14px',
  position: 'absolute',
  transform: 'rotate(-90deg)',
  transformOrigin: 'left',
};

const MembershipCertPrintPreview = React.forwardRef(({ data }, ref) => (
  <Box w="100%" h="100%" m={0} p={0} ref={ref} position="relative">
    <Text style={{ ...textStyles, bottom: '230px', left: '210px' }}>
      <b>{data?.membershipNo}</b>
    </Text>
    <Text style={{ ...textStyles, bottom: '930px', left: '210px' }}>
      <b>{renderDate(new Date(), 'numeric')}</b>
    </Text>
    <Text style={{ ...textStyles, fontSize: '18px', bottom: '390px', left: '280px' }}>
      {data?.millName}
    </Text>
    <Text style={{ ...textStyles, bottom: '155px', left: '320px' }}>
      <b>{data?.millAddress}</b>
    </Text>
    <Text style={{ ...textStyles, bottom: '790px', left: '350px' }}>
      <b>{data?.category}</b>
    </Text>
    <Text style={{ ...textStyles, bottom: '322px', left: '390px' }}>
      <b>{data?.spindles}</b>
    </Text>
    <Text style={{ ...textStyles, bottom: '500px', left: '390px' }}>
      <b>{data?.rotors || 0}</b>
    </Text>
    <Text style={{ ...textStyles, bottom: '760px', left: '390px' }}>
      <b>{data?.shuttleLess || 0}</b>
    </Text>
    <Text style={{ ...textStyles, bottom: '150px', left: '425px' }}>
      <b>{data?.shuttle || 0}</b>
    </Text>
    <Text style={{ ...textStyles, bottom: '390px', left: '425px' }}>
      <b>{data?.knitting || 0}</b>
    </Text>
    <Text style={{ ...textStyles, bottom: '680px', left: '455px' }}>
      <b>{data?.yarnProdCap || 0}</b>
    </Text>
    <Text style={{ ...textStyles, bottom: '740px', left: '495px' }}>
      <b>{data?.fabricWovenProdCap || 0}</b>
    </Text>
    <Text style={{ ...textStyles, bottom: '170px', left: '530px' }}>
      <b>{data?.fabricKnitProdCap || 0}</b>
    </Text>
    <Text style={{ ...textStyles, bottom: '410px', left: '530px' }}>
      <b>{data?.otherFabricProdCap || 0}</b>
    </Text>
    <Text style={{ ...textStyles, bottom: '380px', left: '560px' }}>
      <b>{data?.texFabricWovenProcCap || 0}</b>
    </Text>
    <Text style={{ ...textStyles, bottom: '670px', left: '560px' }}>
      <b>
        {data?.texFabricKnitProcCap == 0 ? data?.texYarnProcCap || 0 : data?.texFabricKnitProcCap}
      </b>
    </Text>
    <Text style={{ ...textStyles, bottom: '430px', left: '605px' }}>
      <b>{data?.period}</b>
    </Text>
  </Box>
));

MembershipCertPrintPreview.displayName = 'MembershipCertPrintPreview';

export default MembershipCertPrintPreview;
